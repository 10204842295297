<template>
  <div class="vis-height-100">
    <div
      v-loading="isLoading"
      :class="[
        { 'ingest-choose-remote-file-container': true },
        { 'vis-pa--none': currentPreviewStep === previewSteps.Table },
      ]"
    >
      <div
        v-if="currentPreviewStep === previewSteps.Import"
        class="content-upload"
      >
        <el-row :gutter="60">
          <el-col :span="12">
            <el-row :gutter="20">
              <el-col :span="24">
                <div>
                  <span class="explanation-title"
                    >{{ $t("datamodel.File Location") }}
                  </span>

                  <div class="remoteFileLocationTester">
                    <input
                      class="vis-input"
                      :value="form.remoteFileLocation"
                      @keyup="
                        (e) => {
                          form.remoteFileLocation = e.target.value;
                          testRemoteFileLocationSuccess = false;
                        }
                      "
                    />
                    <el-button
                      size="small"
                      type="secondary"
                      class="remoteFileLocationTestButton"
                      @click="handleTestRemoteFilePath"
                      >{{ $t("generalPages.Test") }}</el-button
                    >
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row class="file-check-container">
              <el-col :span="24">
                <span class="explanation-title"
                  >{{ $t("datamodel.Selected File") }}
                </span></el-col
              >
              <el-col :span="24">
                <div class="file-check">
                  <i
                    :class="{
                      'file-check-success': testRemoteFileLocationSuccess,
                      [CustomIcon.DraftOutline]: true,
                      'file-check-icon': true,
                    }"
                    aria-hidden="true"
                  />
                  <span
                    v-if="testRemoteFileLocationSuccess"
                    class="file-check-success"
                    >{{ form.remoteFileLocation }}</span
                  >
                  <span v-else>
                    {{ $t("datamodel.No file selected") }}
                  </span>
                </div>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="12">
            <div>
              <!-- 1st row -->
              <el-row :gutter="20">
                <el-col :span="24">
                  <span class="explanation-title"
                    >{{ $t("datamodel.settings") }}
                  </span>
                  <span class="explanation">{{
                    $t("datamodel.Select file options")
                  }}</span>
                </el-col>

                <el-col v-if="isFileTypeCSV" :span="12">
                  <span class="explanation-title"
                    >{{ $t("datamodel.delimiter") }}
                  </span>
                  <el-select
                    class="explanation"
                    :value="form.delimiter"
                    :placeholder="$t('datamodel.delimiter')"
                    @change="(v) => (form.delimiter = v)"
                  >
                    <el-option
                      v-for="option in delimiterOptions"
                      :key="option.key"
                      :label="$t(`${option.label}`)"
                      :value="option.key"
                    >
                    </el-option> </el-select
                ></el-col>

                <el-col :span="12">
                  <span class="explanation-title"
                    >{{ $t("datamodel.First row has headers") }}
                  </span>
                  <el-select
                    class="explanation"
                    :value="form.firstRow"
                    :placeholder="$t('datamodel.First row has headers')"
                    @change="(v) => (form.firstRow = v)"
                  >
                    <el-option
                      v-for="option in firstRowOptions"
                      :key="option.key"
                      :label="$t(`${option.label}`)"
                      :value="option.key"
                    >
                    </el-option> </el-select
                ></el-col>

                <el-col v-if="isFileTypeCSV" :span="12">
                  <span class="explanation-title"
                    >{{ $t("datamodel.textQualifier") }}
                  </span>
                  <el-select
                    class="explanation"
                    :value="form.textQualifier"
                    :placeholder="$t('datamodel.textQualifier')"
                    @change="(v) => (form.textQualifier = v)"
                  >
                    <el-option
                      v-for="option in textQualifierOptions"
                      :key="option.key"
                      :label="$t(`${option.label}`)"
                      :value="option.key"
                    >
                    </el-option> </el-select
                ></el-col>

                <el-col v-else :span="12"></el-col>

                <el-col v-if="isFileTypeCSV" :span="12">
                  <span class="explanation-title"
                    >{{ $t("datamodel.dateFormat") }}
                  </span>
                  <el-select
                    class="explanation"
                    :value="form.dateFormat"
                    :placeholder="$t('datamodel.dateFormat')"
                    multiple
                    collapse-tags
                    @change="(v) => (form.dateFormat = v)"
                  >
                    <el-option
                      v-for="option in dateFormatOptions"
                      :key="option.key"
                      :label="$t(`${option.label}`)"
                      :value="option.key"
                    >
                    </el-option> </el-select
                ></el-col>

                <el-col v-else :span="12"></el-col>

                <el-col :span="12">
                  <span class="explanation-title"
                    >{{ $t("datamodel.Match Columns By") }}
                  </span>
                  <el-select
                    class="explanation"
                    :value="form.matchType"
                    :placeholder="$t('datamodel.Match Columns By')"
                    :disabled="form.firstRow === firstRowTypes.No"
                    @change="(v) => (form.matchType = v)"
                  >
                    <el-option
                      v-for="option in matchTypeOptions"
                      :key="option.key"
                      :label="$t(`${option.label}`)"
                      :value="option.key"
                    >
                    </el-option> </el-select
                ></el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- Preview begins -->
      <div
        v-if="currentPreviewStep === previewSteps.Table"
        class="content-preview-table"
      >
        <vue-good-table :columns="previewColumns" :rows="previewRows">
          <template slot="table-column" slot-scope="props">
            <span>
              <strong>{{ $t(`${props.column.className}`) }}</strong>
            </span>
            <br />
            <span style="font-size: 10px">
              {{ $t(`${props.column.classType}`) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <!-- Preview ends -->
      </div>
    </div>

    <div class="footer">
      <el-button
        v-if="currentPreviewStep === previewSteps.Table"
        size="small"
        type="secondary"
        class="vis-ml--auto"
        @click="handleBack"
        >{{ $t("generalPages.back") }}</el-button
      >
      <el-button
        size="small"
        type="primary"
        @click="handleNext"
        :disabled="!isNextButtonActive"
        >{{
          currentPreviewStep === previewSteps.Table
            ? $t("generalPages.done")
            : $t("generalPages.next")
        }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ACTION } from "../../store/modules/Visualize/File/types";
import {
  GETTER as GETTER_CONNECTIONS,
  ACTION as ACTION_CONNECTIONS,
} from "../../store/modules/Visualize/Connections/types";
import { Notify } from "../../commons/helper";
import { notificationType } from "../../commons/notificationTypes";
import { VueGoodTable } from "vue-good-table";
import { importTypes } from "../../commons/dataModelTypes";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { CustomIcon } from "../../assets/js/custom-icons";

const matchTypes = {
  ColumnOrder: "COLUMN_ORDER",
  ColumnName: "COLUMN_NAME",
};

const uploadModes = {
  Insert: "INSERT",
  Append: "APPEND",
};

const firstRowTypes = {
  Yes: "yes",
  No: "no",
};

export default {
  props: {
    connectionId: {
      type: String,
    },
    defaultFileName: {
      type: String,
    },
  },
  components: {
    VueGoodTable,
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      testRemoteFileLocationSuccess: false,
      firstRowTypes: firstRowTypes,
      delimiterOptions: [
        {
          key: ",",
          label: "Comma [ , ]",
        },
        {
          key: ";",
          label: "Semi colon [ ; ]",
        },
      ],
      textQualifierOptions: [
        {
          key: "'",
          label: "''",
        },
      ],
      firstRowOptions: [
        {
          key: firstRowTypes.Yes,
          label: "Yes",
        },
        {
          key: firstRowTypes.No,
          label: "No",
        },
      ],
      dateFormatOptions: [
        {
          key: "yyyy-MM-dd HH:mm:ss",
          label: "yyyy-MM-dd HH:mm:ss",
        },
        {
          key: "yyyy-MM-dd",
          label: "yyyy-MM-dd",
        },
        {
          key: "dd-MM-yyyy HH:mm:ss",
          label: "dd-MM-yyyy HH:mm:ss",
        },
        {
          key: "dd-MM-yyyy",
          label: "dd-MM-yyyy",
        },
        {
          key: "yyyy/MM/dd HH:mm:ss",
          label: "yyyy/MM/dd HH:mm:ss",
        },
        {
          key: "yyyy/MM/dd",
          label: "yyyy/MM/dd",
        },
        {
          key: "dd.MM.yyyy HH:mm:ss",
          label: "dd.MM.yyyy HH:mm:ss",
        },
        {
          key: "dd.MM.yyyy",
          label: "dd.MM.yyyy",
        },
        {
          key: "dd/MM/yyyy HH:mm:ss",
          label: "dd/MM/yyyy HH:mm:ss",
        },
        {
          key: "dd/MM/yyyy",
          label: "dd/MM/yyyy",
        },
        {
          key: "dd MM yyyy",
          label: "dd MM yyyy",
        },
        {
          key: "dd MMM yyyy",
          label: "dd MMM yyyy",
        },
        {
          key: "dd MMMM yyyy",
          label: "dd MMMM yyyy",
        },
      ],
      fileTypeIncludes: {
        CSV: ".csv",
        XLS: [".xls", ".xlsx"],
      },
      previewColumns: [],
      previewRows: [],
      previewResponse: null,
      previewSteps: {
        Import: "import",
        Table: "table",
      },
      currentPreviewStep: null,
      form: {
        remoteFileLocation: "",
        datasetName: "",
        delimiter: ",",
        textQualifier: "'",
        firstRow: firstRowTypes.Yes,
        dateFormat: [],
        matchType: matchTypes.ColumnOrder,
      },
    };
  },
  mounted() {
    this.currentPreviewStep = this.previewSteps.Import;
    this.fetchConnections();

    if (this.defaultFileName) {
      this.form.remoteFileLocation = this.defaultFileName;
    }
  },
  computed: {
    ...mapGetters({
      connections: GETTER_CONNECTIONS.GET_CONNECTIONS,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    matchTypeOptions() {
      return [
        {
          key: matchTypes.ColumnOrder,
          label: this.$t("datamodel.Column Order"),
        },
        {
          key: matchTypes.ColumnName,
          label: this.$t("datamodel.Column Name"),
        },
      ];
    },
    remoteFileLocationConnection() {
      return this.connections.find((c) => c.connectionId === this.connectionId);
    },
    isLoading() {
      return this.loading[LoadingComponent.CSVExcelUpload];
    },
    hasHeader() {
      return this.form.firstRow === firstRowTypes.Yes;
    },
    isNextButtonActive() {
      return !!this.form?.remoteFileLocation?.length;
    },
    isFileTypeCSV() {
      return this.form.remoteFileLocation?.endsWith(this.fileTypeIncludes.CSV);
    },
  },
  methods: {
    ...mapActions({
      postFileUpload: ACTION.POST_FILE_UPLOAD,
      fetchConnections: ACTION_CONNECTIONS.FETCH_CONNECTIONS,
      testRemoteFileLocation: ACTION.TEST_REMOTE_FILE_LOCATION,
      postPreviewRemoteFile: ACTION.POST_PREVIEW_REMOTE_FILE,
    }),
    handleBack() {
      if (this.currentPreviewStep === this.previewSteps.Table) {
        this.currentPreviewStep = this.previewSteps.Import;
      }
    },
    handleNext() {
      if (this.currentPreviewStep === this.previewSteps.Import) {
        this.submitRemoteFilePreview();
      } else if (this.currentPreviewStep === this.previewSteps.Table) {
        this.done();
      }
    },
    async handleTestRemoteFilePath() {
      const result = await this.testRemoteFileLocation({
        bodyParam: {
          remoteFileLocation: this.form.remoteFileLocation,
          transferMethod: this.remoteFileLocationConnection.type,
          connectionId: this.remoteFileLocationConnection.connectionId,
        },
        loadingComponent: LoadingComponent.CSVExcelUpload,
      });

      this.testRemoteFileLocationSuccess = result === true;

      if (!result) {
        Notify(
          this.$t("ingest.File location is not valid"),
          notificationType.ERROR
        );
      }
    },
    async done() {
      this.$emit("done", {
        rowCount: this.previewResponse.datasetAnalysis.rowCount,
        columns: this.previewResponse.datasetAnalysis.columns.map((c) => {
          return {
            ...c,
            classType: [c.classType],
          };
        }),
        fileRows: this.previewResponse.fileRows,
        fileSize: this.previewResponse.fileSize,
        settings: {
          ...this.previewResponse.settingsField,
        },
        fileType: this.isFileTypeCSV ? importTypes.CSV : importTypes.EXCEL,
        mode: uploadModes.Insert,
        localFileName: this.previewResponse.fileName,
        remoteFileLocation: this.form.remoteFileLocation,
      });
    },
    async submitRemoteFilePreview() {
      let settings = {
        hasHeader: this.hasHeader,
        matchType: this.form.matchType,
      };

      if (this.isFileTypeCSV) {
        settings = {
          ...settings,
          delimiter: this.form.delimiter,
          textQualifier: this.form.textQualifier,
          dateFormat: this.form.dateFormat,
        };
      }

      const repsonse = await this.postPreviewRemoteFile({
        bodyParam: {
          remoteFileLocation: this.form.remoteFileLocation,
          transferMethod: this.remoteFileLocationConnection.type,
          fileType: this.isFileTypeCSV ? importTypes.CSV : importTypes.EXCEL,
          connectionId: this.remoteFileLocationConnection.connectionId,
          mode: uploadModes.Insert,
          settings,
        },
        loadingComponent: LoadingComponent.CSVExcelUpload,
      });

      this.previewResponse = repsonse;

      this.createPreviewTableData();

      this.currentPreviewStep = this.previewSteps.Table;
    },
    createPreviewTableData() {
      this.previewColumns = this.previewResponse.datasetAnalysis.columns.map(
        (c) => {
          return { ...c, field: c.className, label: c.className };
        }
      );
      this.previewRows = [];

      this.previewResponse.fileRows.forEach((element, index) => {
        if (!(index === 0 && this.hasHeader)) {
          this.previewRows.push(
            Object.keys(element).reduce((accumulator, key) => {
              accumulator[this.previewColumns[key].className] = element[key];
              return accumulator;
            }, {})
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.ingest-choose-remote-file-container {
  height: 80%;
  padding: 50px 80px;
}
.content-preview-table {
  display: flex;
  height: 100%;
}
.footer {
  height: 20%;
  margin-top: 40px;
  float: right;
  margin-right: 40px;
  margin-bottom: 25px;
}
.explanation-title {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}
.explanation {
  margin-bottom: 20px;
  display: block;
}
::v-deep .vgt-wrap {
  width: 100%;
}
::v-deep .vgt-inner-wrap {
  height: 100%;
}
::v-deep .vgt-responsive {
  height: 100% !important;
}
.remoteFileLocationTester {
  display: flex;
  align-items: center;
  gap: 10px;
}
.remoteFileLocationTestButton {
  color: rgb(61, 98, 248) !important;
  font-weight: 600;
  font-size: 0.8125rem !important;
}
.file-check {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.file-check-success {
  color: #67ba6d !important;
}
.file-check-icon {
  color: #4e4e4e;
  font-size: 75px;
}
.file-check-container {
  margin-top: 40px;
}
</style>
