import { AXIS_TYPES_ENUM, LINE_CHART_PROPERTIES_ENUM, Orientation, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { LINE_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/lineChartDefaultProperties";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import { calculateGridDistances, formatValueAxisLabels, generateHTMLForTooltipWithSinglePoint, getLabelPositionByRotate, getSelectedColorPaletteByProperty, isAxisLocationDefault, isAxisNameActive, isNameLocationDefault, truncateText, setShowOnlyValue, checkLengthColorbySlot, checkLengthMeasuresSlot, aggregationDateFormatController, colorConverter, sliderPositionHandler, xAxisWidthHandler} from "../chartOptionsHelper";
import { getSeriesLabelPaddingByChartHeight, getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";
export const getLineChartOptions = (lineChartComputedValues, paramsFromSearch, yAxisName, xAxisName, panelData) => {
    const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
        yAxisPosition: lineChartComputedValues.LINE_CHART_Y_AXIS_POSITION,
        showYAxisLabels: lineChartComputedValues.LINE_CHART_SHOW_Y_AXIS_LABEL,
        showXAxisLabels: lineChartComputedValues.LINE_CHART_SHOW_X_AXIS_LABEL,
        showLegend: lineChartComputedValues.LINE_CHART_SHOW_LEGEND,
        yAxisNameLocation: lineChartComputedValues.LINE_CHART_Y_AXIS_NAME_LOCATION,
        labelRotate: lineChartComputedValues.LINE_CHART_VALUE_LABEL_ROTATE,
        showLabel: lineChartComputedValues.LINE_CHART_SHOW_VALUE_LABEL,
        legendPosition: lineChartComputedValues.LINE_CHART_LEGEND_POSITION,
        legendMaxCharacter: lineChartComputedValues.LINE_CHART_LEGEND_MAX_CHARACTER,
        showOnly: lineChartComputedValues.LINE_CHART_SHOW_ONLY,
        xAxisDataZoom: lineChartComputedValues.LINE_CHART_X_AXIS_SHOW_DATA_ZOOM,
        xAxisRotate: lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_ROTATE,
        yAxisDataZoom: lineChartComputedValues.LINE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
        panelData
    });
    
    const optionObject = {
        grid:
        {
            top: gridTop + 20,
            bottom: gridBottom,
            left: gridLeft,
            right: gridRight,
            containLabel: true
        },
        legend: {
            show: isChartWidthLimited(panelData.w) ? lineChartComputedValues.LINE_CHART_SHOW_LEGEND : false,
            orient: getOrientPositionByLegend(lineChartComputedValues.LINE_CHART_LEGEND_POSITION)?.toLowerCase(),
            left: getAlignPositionByLegend(lineChartComputedValues.LINE_CHART_LEGEND_POSITION),
            top: getOrientPositionByLegend(lineChartComputedValues.LINE_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "4%" : lineChartComputedValues.LINE_CHART_LEGEND_VERTICAL_ALIGN,
            textStyle: {
                fontWeight: lineChartComputedValues.LINE_CHART_LEGEND_FONT_WEIGHT,
                fontFamily: lineChartComputedValues.LINE_CHART_LEGEND_FONT_FAMILY,
                fontSize: lineChartComputedValues.LINE_CHART_LEGEND_FONT_SIZE,
                color: lineChartComputedValues.LINE_CHART_LEGEND_FONT_COLOR,
            },
            backgroundColor: 'transparent',
            formatter: function (value) {
                return truncateText(aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats), lineChartComputedValues.LINE_CHART_LEGEND_MAX_CHARACTER);
            },
            type: 'scroll',
        },
        tooltip: {
            show: true,
            trigger: checkLengthColorbySlot(panelData) || checkLengthMeasuresSlot(panelData) ? 'item' : 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: {
                fontFamily: 'Poppins',
                fontSize: 12
            },
            formatter: function (values) {
                //When you assign an 'axis' to the trigger value, the value appears as a single-element array. This is why value[0] is made.
                const value = checkLengthColorbySlot(panelData) || checkLengthMeasuresSlot(panelData) ? values : values[0];

                value.name =  aggregationDateFormatController(value?.name, paramsFromSearch?.aggregationFormats);

                return showOnlyTypesExceptOnlyItem.includes(lineChartComputedValues.LINE_CHART_SHOW_ONLY) ? 
                setShowOnlyValue(
                    value,
                    paramsFromSearch,
                    lineChartComputedValues,
                    LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_ONLY,
                    dashboardSPEnums.TOOLTIP,
                    panelData
                )
                :
                generateHTMLForTooltipWithSinglePoint(
                    value,
                    paramsFromSearch?.metricFormats?.[value.seriesIndex],
                    panelData
                )
            },
        },
        xAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartHeightLimited(panelData.h) ? lineChartComputedValues.LINE_CHART_SHOW_X_AXIS_LABEL : false,
                fontWeight: lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_FONT_FAMILY,
                fontSize: lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_FONT_SIZE,
                color: lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_FONT_COLOR,
                rotate: lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_ROTATE,
                overflow:  lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_WRAP ? 'break' : 'none',
                width: xAxisWidthHandler(lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_WRAP,
                    lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_MAX_CHARACTER),
                formatter: function (value) {
                    const formattedValue = aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats);
                    return truncateText(
                        formattedValue, 
                        lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_MAX_CHARACTER,
                        lineChartComputedValues.LINE_CHART_X_AXIS_LABEL_WRAP
                    );
                },
                hideOverlap: true,
            },
            type: 'category',
            axisTick: {
                show: false
            },
            data: paramsFromSearch?.dataAxis ?? [],
            axisLine: {
                show: lineChartComputedValues.LINE_CHART_SHOW_X_AXIS_LINE,
            },
        },
        yAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartWidthLimited(panelData.w) ? lineChartComputedValues.LINE_CHART_SHOW_Y_AXIS_LABEL : false,
                fontWeight: lineChartComputedValues.LINE_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: lineChartComputedValues.LINE_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                fontSize: lineChartComputedValues.LINE_CHART_Y_AXIS_LABEL_FONT_SIZE,
                color: lineChartComputedValues.LINE_CHART_Y_AXIS_LABEL_FONT_COLOR,
                formatter: function (value) {
                    return formatValueAxisLabels(paramsFromSearch?.metricFormats, value);
                },
                hideOverlap: true,
            },
            type: lineChartComputedValues.LINE_CHART_Y_AXIS_LOGARITHMIC_SCALE ? AXIS_TYPES_ENUM.LOG : AXIS_TYPES_ENUM.VALUE,
            min: lineChartComputedValues.LINE_CHART_Y_AXIS_MIN,
            position: lineChartComputedValues.LINE_CHART_Y_AXIS_POSITION,
            axisLine: {
                show: lineChartComputedValues.LINE_CHART_Y_AXIS_SHOW_LINE,
                lineStyle: {
                    color: lineChartComputedValues.LINE_CHART_Y_AXIS_LINE_STYLE_COLOR
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                lineStyle: {
                  color: lineChartComputedValues.LINE_CHART_Y_AXIS_GRID_LINE_COLOR,
                }
            },
        },
        dataZoom: Object.keys(paramsFromSearch).length
        ? [
            {
              type: 'slider',
              show: lineChartComputedValues.LINE_CHART_X_AXIS_SHOW_DATA_ZOOM,
              start: lineChartComputedValues.LINE_CHART_X_AXIS_DATA_ZOOM_START,
              end: lineChartComputedValues.LINE_CHART_X_AXIS_DATA_ZOOM_END,
              zoomLock: lineChartComputedValues.LINE_CHART_X_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !lineChartComputedValues.LINE_CHART_X_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(lineChartComputedValues.LINE_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
                LINE_CHART_DEFAULT_CONFIGURATIONS.LINE_CHART_X_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(lineChartComputedValues.LINE_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                LINE_CHART_DEFAULT_CONFIGURATIONS.LINE_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
                LINE_CHART_DEFAULT_CONFIGURATIONS.LINE_CHART_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: lineChartComputedValues.LINE_CHART_X_AXIS_DATA_ZOOM_PALETTE
                }
              },
              xAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !lineChartComputedValues.LINE_CHART_X_AXIS_SHOW_DATA_ZOOM,
              zoomLock: lineChartComputedValues.LINE_CHART_X_AXIS_LOCK_DATA_ZOOM,
              xAxisIndex: [0],
            },
            {
              type: 'slider',
              show: lineChartComputedValues.LINE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
              left:  sliderPositionHandler(lineChartComputedValues.LINE_CHART_SHOW_LEGEND,
                lineChartComputedValues.LINE_CHART_LEGEND_POSITION),
              start: lineChartComputedValues.LINE_CHART_Y_AXIS_DATA_ZOOM_START,
              end: lineChartComputedValues.LINE_CHART_Y_AXIS_DATA_ZOOM_END,
              zoomLock: lineChartComputedValues.LINE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !lineChartComputedValues.LINE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(lineChartComputedValues.LINE_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                LINE_CHART_DEFAULT_CONFIGURATIONS.LINE_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(lineChartComputedValues.LINE_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                LINE_CHART_DEFAULT_CONFIGURATIONS.LINE_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                LINE_CHART_DEFAULT_CONFIGURATIONS.LINE_CHART_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: lineChartComputedValues.LINE_CHART_Y_AXIS_DATA_ZOOM_PALETTE
                }
              },
              yAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !lineChartComputedValues.LINE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
              zoomLock: lineChartComputedValues.LINE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              yAxisIndex: [0],
            },
          ]
        : [],
        series: paramsFromSearch?.dataSeries?.length ? paramsFromSearch?.dataSeries?.map((s, i) => {
            return {
                data: s.map((a, index) => {
                    return {
                        value: a,
                        itemStyle: getCommonChartItemStyle(paramsFromSearch?.dataAxis[index], panelData)
                    }
                }),
                type: "line",
                smooth: lineChartComputedValues.LINE_CHART_SPLINE_MODE,
                name: paramsFromSearch?.dataSeriesNames?.[i],
                label: {
                    position: getLabelPositionByRotate(lineChartComputedValues.LINE_CHART_VALUE_LABEL_ROTATE),
                    padding: getSeriesLabelPaddingByChartHeight(panelData.h),
                    show: isChartWidthLimited(panelData.w) ? lineChartComputedValues.LINE_CHART_SHOW_VALUE_LABEL : false,
                    fontSize: lineChartComputedValues.LINE_CHART_VALUE_LABEL_FONT_SIZE,
                    fontFamily: lineChartComputedValues.LINE_CHART_VALUE_LABEL_FONT_FAMILY,
                    fontWeight: lineChartComputedValues.LINE_CHART_VALUE_LABEL_FONT_WEIGHT,
                    color: lineChartComputedValues.LINE_CHART_VALUE_LABEL_FONT_COLOR,
                    rotate: lineChartComputedValues.LINE_CHART_VALUE_LABEL_ROTATE,
                    formatter: function (value) {
                        value.name =  aggregationDateFormatController(value?.name, paramsFromSearch?.aggregationFormats);

                        return showOnlyTypesExceptOnlyItem.includes(lineChartComputedValues.LINE_CHART_SHOW_ONLY) ? 
                        setShowOnlyValue(
                            value,
                            paramsFromSearch,
                            lineChartComputedValues,
                            LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_ONLY,
                            dashboardSPEnums.LABEL,
                            panelData
                        )
                        :
                        value?.name
                    }
                },
                labelLayout: {
                    hideOverlap: lineChartComputedValues.LINE_CHART_HIDE_OVERLAPPED_VALUE_LABELS
                },
                areaStyle: { opacity: 0 }
            }
        }) : null,
        color: getSelectedColorPaletteByProperty(lineChartComputedValues.LINE_CHART_SELECTED_COLOR_PALETTE),
    }
    return {
        options: optionObject,
        yAxis: {
            name: isAxisNameActive(paramsFromSearch, yAxisName),
            show: isChartWidthLimited(panelData.w) ? lineChartComputedValues.LINE_CHART_SHOW_Y_AXIS_NAME : false,
            fontFamily: lineChartComputedValues.LINE_CHART_Y_AXIS_NAME_FONT_FAMILY,
            fontSize: lineChartComputedValues.LINE_CHART_Y_AXIS_NAME_FONT_SIZE,
            fontWeight: lineChartComputedValues.LINE_CHART_Y_AXIS_NAME_FONT_WEIGHT,
            fontColor: lineChartComputedValues.LINE_CHART_Y_AXIS_NAME_FONT_COLOR,
            isAxisLocationDefault: isAxisLocationDefault(lineChartComputedValues.LINE_CHART_Y_AXIS_POSITION),
            isNameLocationDefault: isNameLocationDefault(lineChartComputedValues.LINE_CHART_Y_AXIS_NAME_LOCATION)
        },
        xAxis: {
            name: isAxisNameActive(paramsFromSearch, xAxisName),
            show: isChartHeightLimited(panelData.h) ? lineChartComputedValues.LINE_CHART_SHOW_X_AXIS_NAME : false,
            fontFamily: lineChartComputedValues.LINE_CHART_X_AXIS_NAME_FONT_FAMILY,
            fontSize: lineChartComputedValues.LINE_CHART_X_AXIS_NAME_FONT_SIZE,
            fontWeight: lineChartComputedValues.LINE_CHART_X_AXIS_NAME_FONT_WEIGHT,
            fontColor: lineChartComputedValues.LINE_CHART_X_AXIS_NAME_FONT_COLOR,
        }
    }
}
