<template>
  <div class="vis-panel-data-box">
    <label>{{ boxName }}</label>
    <div class="vis-panel-data-card" :class="panelDataBoxEmptyClass">
      <draggable
        class="list-group"
        :class="activeFieldBoxClass"
        :list="draggableList"
        :group="{ name: 'people', pull: 'clone' }"
        @change="changeDraggableList($event)"
        @start="onDragStart"
        @end="onDragEnd"
      >
        <ul
          class="list-group-item"
          v-for="(item, index) in boxList"
          :key="item.fieldId + index"
        >
          <PanelCommonsBoxFieldItem
            :fieldItem="item"
            :fieldIndex="index"
            :contextMenuItems="contextMenuItems"
            :contextMenuId="contextMenuId"
            :selectedDataModel="selectedDataModel"
            :datasetFields="datasetFields"
            :datamodelDatasetFields="datamodelDatasetFields"
            @openContextMenu="openContextMenu"
            @setSelectType="setSelectType"
            @beforeContextItemHighlight="beforeContextItemHighlight"
            @onSortMethodSwitched="onSortMethodSwitched($event, item)"
            @onItemClicked="openColumnBasedFormattingProperties($event)"
          />
        </ul>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import PanelCommonsBoxFieldItem from "./PanelCommonsBoxFieldItem.vue";
import eventBus from "../../eventBus";

export default {
  components: {
    draggable,
    PanelCommonsBoxFieldItem,
  },
  props: {
    boxName: {
      type: String,
    },
    boxList: {
      type: Array,
    },
    contextMenuId: {
      type: String,
    },
    draggableList: {
      type: Array,
    },
    panelDataBoxEmptyClass: {
      type: String,
    },
    activeFieldBoxClass: {
      type: String,
    },
    contextMenuItems: {
      type: Array,
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
    datasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    datamodelDatasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    onDragStart() {
      eventBus.$emit("onDragStart");
    },
    onDragEnd() {
      eventBus.$emit("onDragEnd");
    },
    changeDraggableList(args) {
      if (args?.added && args?.added?.element?.isAuthorize === undefined) {
        args.added.element.isAuthorize = true;
      }
      //To prevent the user from drag-and-drop if they do not have permission to the field.
      if (args?.added && !args?.added?.element?.isAuthorize) return;

      if (args?.removed) {
        return;
      }

      if (args?.added || args?.moved) this.$emit("changeDraggableList", args);
    },
    openContextMenu(value, params) {
      this.$emit("openContextMenu", value, params);
    },
    setSelectType(args) {
      this.$emit("setSelectType", args);
    },
    beforeContextItemHighlight(args) {
      this.$emit("beforeContextItemHighlight", args);
    },
    onSortMethodSwitched(sortMethod, field) {
      this.$emit("onSortMethodSwitched", sortMethod, field);
    },
    openColumnBasedFormattingProperties(value) {
      this.$emit("onSelectColumnBasedPropertiesColumn", value);
    },
  },
};
</script>

<style scoped>
.remove-item-icon {
  font-size: 3rem;
  position: absolute;
  top: -40px;
  color: #00000060;
  right: 0;
}
.remove-item-icon.hovered {
  color: #000000bb;
}
</style>
