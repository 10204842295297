<template>
  <div class="vis-position-relative" :class="customComponentClass">
    <el-input
      v-model="searchDataValue"
      @keyup.native="$emit('keyupSearchData', searchDataValue)"
      @keyup.enter.native="$emit('handleEnterInput')"
      size="mini"
      :placeholder="$t('filterComponents.typeToSeach')"
    />
    <i 
      :class="customIcon.Search" 
      class="vis-input-icon" 
      aria-hidden="true"
      @click="$emit('handleClickIcon')"/>
  </div>
</template>
<script>
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  props: {
    customComponentClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      searchDataValue: "",
    };
  },
};
</script>
