export const GAUGE_CHART_DEFAULT_CONFIGURATIONS = {
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY: "Poppins, sans-serif",
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT: 500,
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE: 12,
  GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR: "#000",
  GAUGE_CHART_PROGRESS_BAR_WIDTH: 14,
  GAUGE_CHART_TITLE_FONT_FAMILY: "Poppins, sans-serif",
  GAUGE_CHART_TITLE_FONT_WEIGHT: 500,
  GAUGE_CHART_TITLE_FONT_SIZE: 16,
  GAUGE_CHART_TITLE_FONT_COLOR: "#000",
  GAUGE_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
  GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
  GAUGE_CHART_VALUE_LABEL_FONT_SIZE: 20,
  GAUGE_CHART_VALUE_LABEL_FONT_COLOR: "#000",
  GAUGE_CHART_SEPERATION_ENABLED: true,
  GAUGE_CHART_SEPERATION_VALUES: [
    { percentage: 25, color: "#00cc00" },
    { percentage: 50, color: "#e6e600" },
    { percentage: 75, color: "#ff9900" },
    { percentage: 100, color: "#ff3300" },
  ],
  GAUGE_CHART_AUTO_REFRESH: 0,
  GAUGE_CHART_AUTO_REFRESH_CUSTOM: null,
  GAUGE_CHART_DYNAMIC_TITLE_VALUE: "",
  GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
  GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
  GAUGE_CHART_SHOW_INSIGHT: true,
  GAUGE_CHART_SHOW_SQL: false,
  GAUGE_CHART_GROUP_MULTIPLE_VALUES: true,
  GAUGE_CHART_ALLOW_DRILL_THROUGH: true,
  GAUGE_CHART_DRILL_THROUGH_DASHBOARD_LIST: [],
  GAUGE_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK: false
};

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
  return typeof value != "undefined" ? value : computedValue;
};
