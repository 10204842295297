<template>
  <div
    class="environment-item-container"
    :class="{ 'environment-item-container-disabled': isDisabled }"
  >
    <div
      v-if="!isDisabled"
      class="environment-item-container-top-actions-wrapper"
    >
      <i
        v-if="!formItem.isThisEnvironment"
        :class="CustomIcon.TrashCanOutline"
        class="environment-item-container-top-actions-wrapper-action vis-cursor-pointer"
        aria-hidden="true"
        @click="$emit('deleteEnvironmentItem', formItem)"
      />
      <i
        :class="CustomIcon.SaveOutlined"
        class="environment-item-container-top-actions-wrapper-action vis-cursor-pointer"
        aria-hidden="true"
        @click="$emit('saveEnvironmentItem', formItem)"
      />
      <!--<i
        :class="CustomIcon.Pencil"
        class="environment-item-container-top-actions-wrapper-action vis-cursor-pointer"
        aria-hidden="true"
      />-->
    </div>

    <div class="environment-item-container-content-wrapper">
      <i
      :class = "CustomIcon.Storage"
        class="environment-item-container-left-icon"
        aria-hidden="true"
      ></i>
      <div class="environment-item-container-form-wrapper">
        <el-row :gutter="60">
          <el-col :span="isDisabled ? 24 : 12">
            <label v-if="formItem.isThisEnvironment">{{
              $t("admin.environment.Name (This environment)")
            }}</label>
            <label v-else>{{ $t("admin.environment.Name") }}</label>
            <el-input v-model="formItem.name" :readonly="isDisabled"
          /></el-col>
          <el-col v-if="!isDisabled" :span="12">
            <label>{{ $t("admin.environment.Authentication Address") }}</label>
            <el-input v-model="formItem.keycloakDomain"
          /></el-col>
        </el-row>
        <el-row :gutter="60">
          <el-col :span="isDisabled ? 24 : 12">
            <label>{{ $t("admin.environment.Web URL") }}</label>
            <el-input v-model="formItem.address" :readonly="isDisabled"
          /></el-col>
          <el-col v-if="!isDisabled" :span="12">
            <label>{{ $t("admin.environment.Authentication Realm") }}</label>
            <el-input v-model="formItem.keycloakRealm"
          /></el-col>
        </el-row>
        <el-row v-if="!isDisabled" :gutter="60">
          <el-col :span="12"> &nbsp; </el-col>
          <el-col :span="12">
            <el-row :gutter="30"
              ><el-col :span="12">
                <label>{{ $t("admin.environment.Service Username") }}</label>
                <el-input v-model="formItem.keycloakUserName"
              /></el-col>
              <el-col :span="12">
                <label>{{ $t("admin.environment.Password") }}</label>
                <el-input type="password" v-model="formItem.keycloakPassword"
              /></el-col> </el-row
          ></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  props: {
    item: {
      type: Object,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { formItem: {}, CustomIcon: CustomIcon };
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        this.formItem = cloneDeep(this.item);
      },
    },
  },
};
</script>

<style scoped>
.environment-item-container {
  width: 100%;
  min-height: 300px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 20px 35px;
}
.environment-item-container-disabled {
  min-height: 200px !important;
}
.environment-item-container-content-wrapper {
  display: flex;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.environment-item-container-form-wrapper {
  width: 100%;
}
.environment-item-container-left-icon {
  font-size: 40px;
  margin-right: 15px;
  color: #575757;
}
.environment-item-container-top-actions-wrapper {
  display: flex;
  height: 30px;
  align-items: center;
  column-gap: 10px;
  justify-content: end;
}
.environment-item-container-top-actions-wrapper-action {
  color: #333333;
  font-size: 20px;
}
</style>
