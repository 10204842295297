<template>
  <div class="mail-server">
    <div class="vis-row">
      <div class="vis-col vis-admin-settings-pages-title vis-mb--1">
        <h2>{{ $t("admin.settings.Mail Server") }}</h2>
        <p class="vis-font-medium">
          {{
            $t(
              "admin.mailServer.Enter SMTP Mail Server settings which will be used in report distribution"
            )
          }}
        </p>
      </div>
    </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <label>{{ $t("admin.mailServer.Mail From") }}</label>
            <el-input
              type="text"
              v-model="mailServerFormData.mailServerMailFrom"
              @change="
                handleInputChange(configurationNameTypes.MAIL_FROM, $event)
              "
            />
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <label>{{ $t("admin.mailServer.Mail User Name") }}</label>
            <el-input
              type="text"
              v-model="mailServerFormData.mailServerUsername"
              @change="
                handleInputChange(configurationNameTypes.MAIL_USERNAME, $event)
              "
            />
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <label>{{ $t("admin.mailServer.Mail Password") }}</label>
            <el-input
              type="password"
              v-model="mailServerFormData.mailServerPassword"
              @change="
                handleInputChange(configurationNameTypes.MAIL_PASSWORD, $event)
              "
              show-password
            />
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <label>{{ $t("admin.mailServer.Mail SMTP Host") }}</label>
            <el-input
              type="text"
              v-model="mailServerFormData.mailServerSMTPHost"
              @change="
                handleInputChange(configurationNameTypes.MAIL_SMTP_HOST, $event)
              "
            />
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <label>{{ $t("admin.mailServer.Mail SMTP Port") }}</label>
            <el-input
              type="text"
              v-model="mailServerFormData.mailServerSMTPPort"
              @change="
                handleInputChange(configurationNameTypes.MAIL_SMTP_PORT, $event)
              "
            />
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <label>{{ $t("admin.mailServer.Mail SMTP Auth") }}</label>
            <el-switch
              v-model="mailServerFormData.mailServerSMTPAuth"
              @change="
                handleInputChange(
                  configurationNameTypes.MAIL_SMTP_AUTH,
                  `${$event}`
                )
              "
              active-color="#3d62f8"
            >
            </el-switch>
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4">
            <label>{{
              $t("admin.mailServer.Mail SMTP StartTls Enable")
            }}</label>
            <el-switch
              v-model="mailServerFormData.mailServerStartTlsEnable"
              @change="
                handleInputChange(
                  configurationNameTypes.MAIL_SMTP_STARTTLS_ENABLE,
                  `${$event}`
                )
              "
              active-color="#3d62f8"
            >
            </el-switch>
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col-4 vis-text--right">
            <el-button
              @click="updateGeneralProperties"
              size="small"
              type="primary"
              >{{ $t("generalPages.save") }}</el-button
            >
          </div>
        </div>
    </div>
</template>
<script>
import cloneDeep from "clone-deep";
import { configurationNameTypes } from "./settings";
export default {
  props: {
    selectedMailServer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      mailServerFormData: {},
      configurationNameTypes: configurationNameTypes,
      changedFormData: [],
    };
  },
  async mounted() {},
  watch: {
    selectedMailServer: {
      handler(val) {
        this.mailServerFormData = cloneDeep(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    updateGeneralProperties() {
      this.changedFormData.forEach((prop) => {
        this.$emit("onMailServerValueChange", prop.fieldName, prop.value);
      });
      this.changedFormData = [];
    },
    handleInputChange(fieldName, value) {
      this.changedFormData.push({ fieldName, value });
    },
  },
};
</script>

<style scoped>
.mail-server {
  font-size: 0.75rem;
  color: #3e3e3e;
  font-weight: 500;
}
</style>
