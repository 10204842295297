<template>
  <div class="vis-common-popup-card" :style="{ width: width + 'px' }">
    <!--TITLE  START -->
    <div class="vis-popup-common-title vis-flex--spacebeetwen-Ycenter">
      <div class="ellipsisLabel">
        <span> {{ titleText }} {{ fieldText !== '' ? "-" : ""}} </span>
        <span class="vis-color-primary vis-font-bold">{{ fieldText }}</span>
      </div>
      <div class="vis-flex--Ycenter">
        <slot name="titleRight"> </slot>
        <i
          @click="$emit('onClose')"
          aria-hidden="true"
          :class="customIcon.Close"
          class="vis-cursor-pointer"
        ></i>
      </div>
    </div>
    <!--TITLE END -->
    <slot name="default"> </slot>
    <!-- FOOTER START -->
    <div :class="footerClass" :style="{ 'text-align': footerAlign }">
      <el-button
        v-if="isCancelButtonActive"
        @click="$emit('onCancel')"
        :class="cancelButtonClass"
        type="text"
        size="small"
        >{{ cancelButtonText }}</el-button
      >
      <el-button
        id="saveFieldPopup"
        v-if="isSubmitButtonActive"
        @click="$emit('onSubmit')"
        :class="submitButtonClass"
        type="primary"
        size="small"
        >{{ submitButtonText }}</el-button
      >
    </div>
    <!-- FOOTER END -->
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import i18n from "../../i18n";
export default {
  props: {
    width: {
      type: Number,
      default: 100,
    },
    titleText: {
      type: String,
      default: "",
    },
    fieldText: {
      type: String,
      default: "",
    },
    isCancelButtonActive: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: () => {
        return i18n.t("generalPages.cancel");
      },
    },
    isSubmitButtonActive: {
      type: Boolean,
      default: true,
    },
    submitButtonText: {
      type: String,
      default: () => {
        return i18n.t("generalPages.done");
      },
    },
    cancelButtonClass: {
      type: String,
      default: "",
    },
    submitButtonClass: {
      type: String,
      default: "",
    },
    footerClass: {
      type: String,
      default: "vis-common-popup-footer",
    },
    footerAlign: {
      type: String,
      default: "right",
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
};
</script>
<style scoped>
.vis-common-popup-footer {
  position: relative;
  width: 100%;
  padding: 30px;
}
.ellipsisLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
