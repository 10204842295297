<template>
  <ContextPopup :customClass="'vis-calculatePopup'">
    <template v-slot:title>
      <span>Hesaplamaya Göre Sırala</span>
      <i
        @click="$emit('closeCalculate', 0)"
        class="mdi mdi-close vis-cursor-pointer"
        aria-hidden="true"
      ></i>
    </template>
    <template v-slot:content>
      <div class="vis-row">
        <div class="vis-col vis-col-8">
          <label class="vis-mb--1" for="">Hesaplama Seçin</label>
          <ul class="vis-calculate-values">
            <li v-for="(item, index) in fieldList" :key="item.id">
              <div class="vis-calvulate-content">
                <span
                  ><i
                    @click="deleteItem(item, index)"
                    class="mdi mdi-close vis-cursor-pointer"
                    aria-hidden="true"
                  ></i>
                  {{ item.alias }}</span
                >
              </div>
              <div class="vis-ml--1">
                <i
                  @click="setOrder(item)"
                  v-if="item.orderBy == 'asc'"
                  class="mdi mdi-sort-ascending vis-cursor-pointer vis-color-primary"
                  aria-hidden="true"
                ></i>
                <i
                  v-if="item.orderBy == 'desc'"
                  @click="setOrder(item)"
                  class="mdi mdi-sort-descending vis-cursor-pointer vis-color-primary"
                  aria-hidden="true"
                ></i>
                <i
                  v-if="!item.orderBy"
                  @click="setOrder(item)"
                  class="mdi mdi-sort-ascending vis-cursor-pointer"
                  aria-hidden="true"
                ></i>
              </div>
            </li>
          </ul>
          <!-- UL -->
          <el-select
            v-model="field"
            placeholder="Select"
            filterable
            value-key="id"
            @change="setCalculate()"
          >
            <el-option
              v-for="item in datasetFields"
              :key="item.field"
              :label="item.alias"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- ROW -->
      <div class="vis-row">
        <div class="vis-col vis-text--right vis-mt--3">
          <el-button plain>Temizle</el-button>
          <el-button type="primary">Tamam</el-button>
        </div>
      </div>
    </template>
  </ContextPopup>
</template>
<script>
import ContextPopup from "../ContextPopup.vue";
export default {
  components: {
    ContextPopup,
  },
  props: {
    datasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      field: {},
      fieldList: [],
      iconClass: "mdi mdi-sort-descending",
      counter: 0,
    };
  },
  methods: {
    setCalculate() {
      this.fieldList.push(this.field);
      this.field = {};
    },
    setOrder(item) {
      const value = this.fieldList.find((x) => x.id == item.id);
      if (value.orderBy == "desc") {
        this.$set(value, "orderBy", "asc");
      } else if (value.orderBy == "asc") {
        this.$set(value, "orderBy", "desc");
      } else {
        this.$set(item, "orderBy", "asc");
      }
    },
    deleteItem(item, index) {
      this.fieldList.splice(index, 1);
      this.$emit("deleteCalculateItem", item);
      // var deleteItem = this.states.find((x) => x.field == item.field);
      // // delete deleteItem.orderBy;
      // this.$delete(deleteItem, "orderBy");
    },
  },
};
</script>
<style scoped>
.vis-panel-dropdown.vis-calculatePopup {
  width: 400px;
}
.vis-calculate-values {
  position: relative;
  width: 100%;
  margin: 5px 0;
}
.vis-calculate-values li {
  display: flex;
  margin-bottom: 24px;
  color: #3e3e3e;
  font-size: 0.75rem;
}
.vis-calvulate-content {
  position: relative;
  width: 206px;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  padding: 6px 13px;
  font-size: 12px;
  color: #3e3e3e;
}
.vis-calvulate-content i {
  font-size: 12px;
  color: #d2d2d2;
  margin-right: 12px;
}
</style>
