<template>
  <div class="gpt-provider">
    <div class="vis-row">
      <div class="vis-col vis-admin-settings-pages-title vis-mb--1">
        <h2>{{ $t("admin.settings.GPT Provider") }}</h2>
        <p class="vis-font-medium">
          {{
            $t(
              "admin.GptProvider.Select GPT provider This provider will be used to create smart analysis"
            )
          }}
        </p>
      </div>
    </div>
    <div class="vis-row">
      <div class="vis-col vis-col-4 vis-col-xxl6 vis-col-l12">
        <div
          v-for="provider in providers"
          :key="provider.value"
          class="gpt-card"
          :class="{
            'gpt-card-selected': selectChatbotConfiguration === provider.value,
          }"
        >
          <label
            class="gpt-card-inner"
            @click="$emit('onChatbotSourceChange', provider.value)"
          >
            <input
              type="radio"
              :id="provider.value"
              :value="provider.value"
              v-model="selectChatbotConfiguration"
              @click.stop
            />
            {{ provider.label }}
          </label>
          <div
            v-if="
              selectChatbotConfiguration === provider.value &&
              provider.value !== 'dontUseAny'
            "
            class="access-token vis-row"
          >
            <label for="accessToken">{{
              $t("admin.GptProvider.Access Token")
            }}</label>
            <div class="vis-col vis-col-9">
              <el-input
                type="password"
                id="accessToken"
                v-model="accessToken"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { chatbotProviders } from "./settings";
export default {
  props: {
    chatbotConfiguration: {
      type: String,
    },
  },
  data() {
    return {
      providers: chatbotProviders,
      accessToken: "",
      selectChatbotConfiguration: null,
    };
  },
  mounted() {
    this.selectChatbotConfiguration = this.chatbotConfiguration;
  },
  methods: {},
};
</script>

<style scoped>
.gpt-provider {
  font-size: 0.75rem;
  font-weight: 500;
}

.gpt-card {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 14px;
  background: #fafafa 0% 0% no-repeat padding-box;
}

.gpt-card:hover {
  background-color: #f0f0f0;
}

.gpt-card-selected {
  background-color: #f0f0f0;
}

.gpt-card-selected .gpt-card-inner {
  padding: 20px 10px 20px !important;
}

.gpt-card-inner {
  display: flex;
  align-items: center;
  padding: 30px 14px;
  cursor: pointer;
}

.access-token {
  padding-left: 45px;
}

.gpt-card input[type="radio"] {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}
</style>
