<template>
  <div id="app-layout" class="vbox viewport">
    <header v-if="showHeader">
      <slot name="header">Header</slot>
    </header>

    <section class="main hbox space-between">
      <splitpanes
        v-if="isShowPane"
        class="default-theme"
        @resized="handleResize"
      >
        <pane
          v-if="showNav"
          :size="splitNavMenuWidth"
          :min-size="splitNavMenuMinWidth"
          max-size="26"
        >
          <nav class="vis-height-100 vis-scroll-thin">
            <slot name="nav">Nav</slot>
          </nav>
        </pane>
        <pane class="vis-display-flex">
          <article class="vis-scroll-thin" :id="articleId">
            <slot name="article">Article</slot>
          </article>
          <aside v-if="showAside" class="vis-scroll-thin layout-aside">
            <slot name="aside">Aside</slot>
          </aside>
        </pane>
      </splitpanes>
      <nav
        v-if="showNav && !isShowPane"
        class="vis-height-100 vis-scroll-thin vis-nav-width"
      >
        <slot name="nav">Nav</slot>
      </nav>
      <article v-if="!isShowPane" class="vis-scroll-thin" :id="articleId">
        <slot name="article">Article</slot>
      </article>
      <aside
        v-if="showAside && !isShowPane"
        class="vis-scroll-thin layout-aside"
      >
        <slot name="aside">Aside</slot>
      </aside>
    </section>

    <!--<footer>
      <slot name="footer">Footer</slot>
    </footer>-->

    <slot name="modals">Modals</slot>
  </div>
</template>

<script>
export default {
  name: "layout",
  props: {
    showNav: {
      type: Boolean,
      default: true,
    },
    showAside: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    articleId: {
      type: String,
      default: "",
    },
    isShowPane: {
      type: Boolean,
      default: false,
    },
    selectedLeftSplitNavMenuWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      paneMinSize: 220,
      totalWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updatePaneSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updatePaneSize);
  },
  computed: {
    splitNavMenuWidth() {
      return this.selectedLeftSplitNavMenuWidth !== 0
        ? this.selectedLeftSplitNavMenuWidth
        : this.splitNavMenuMinWidth;
    },
    splitNavMenuMinWidth() {
      return (this.paneMinSize / this.totalWidth) * 100;
    },
  },
  methods: {
    handleResize(sizes) {
      this.$emit("leftSplitNavMenuWidth", sizes[0]?.size);
    },
    updatePaneSize() {
      this.totalWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
/*
  Original from John Schulz’s Blocks http://bl.ocks.org/jfsiii/5380802
*/

/* some basic styles. nothing to do with flexbox */
header,
footer,
nav,
article,
aside {
}
header {
  background-color: #333;
}

/*
  Force full width & height.
  If this block is removed, the layout height/length will be determined by
  the amount of content in the page. That might result in a page which has
  a footer only a few inches from the top of the viewport, or one which
  scrolls beyond the viewport.
  This forces the layout to always be full screen regardless of how much,
  or how little, content is in place. Neither is "right" or "wrong", there
  are valid cases for each. I just want to be clear what's controlling the
  page/viewport height.
*/
html,
body,
.viewport {
  width: 100%;
  height: 100%;
  margin: 0;
}

p {
  text-align: left;
}

/* encapsulate the various syntax in helper clases */
/* inspired by http://infrequently.org/2009/08/css-3-progress/ */

/* items flex/expand vertically */
.vbox {
  /* previous syntax */
  display: -webkit-box;
  display: -moz-box;
  display: box;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;

  /* current syntax */
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;

  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* items flex/expand horizontally */
.hbox {
  /* previous syntax */
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  display: box;

  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -ms-box-orient: horizontal;
  box-orient: horizontal;

  /* current syntax */
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;

  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.space-between {
  /* previous syntax */
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-box-pack: justify;
  box-pack: justify;

  /* current syntax */
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.h-100 {
  height: 100% !important;
}

/* I went with a fixed height header & footer because it's a common case.
  This could easily be altered to flex proportionally with the page.
*/
header,
footer {
  height: 3rem;
  padding: 0 !important;
}

.main {
  height: calc(100% - 48px);

  /* previous syntax */
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;

  /* current syntax */
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

article {
  /* previous syntax */
  -webkit-box-flex: 5;
  -moz-box-flex: 5;
  -ms-box-flex: 5;
  box-flex: 5;

  /* current syntax */
  -webkit-flex: 5;
  -moz-flex: 5;
  -ms-flex: 5;
  flex: 5;
  overflow: auto;
}

aside {
  width: 300px;
  overflow: auto;
  background-color: #ffffff;
  border-left: 1px solid #e4e3e8;
}

nav {
  width: auto;
  overflow: auto;
  background-color: #ffffff;
  border-right: 1px solid #e4e3e8;
}
.layout-aside {
  z-index: 1;
}
.vis-nav-width {
  width: 220px;
}
/* to get rid of the default transition */
.splitpanes .splitpanes__pane {
    transition: none;
}
</style>
