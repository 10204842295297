<template>
  <div
    v-bind:style="{
      left: obj.left + 'px',
      top: obj.top + 'px',
    }"
    class="flowchart-object flowchart-action ingest-shared-folder-node-container ingest-flowchart-node"
    data-jtk-target="true"
  >
    <div
      class="ingest-shared-folder-node"
      :class="{ 'ingest-shared-folder-node-selected': obj.nodeSelected }"
    >
      <div class="ingest-shared-folder-node-header">
        <div class="ingest-shared-folder-node-header-actions vis-flex--Ycenter">
          <el-dropdown
            placement="bottom-start"
            size="mini"
            @command="handleCommand"
          >
            <span class="el-dropdown-link"
              ><i
                :class="`${customIcon.VerticalThreeDot} ingest-dataset-node-actions`"
                aria-hidden="true"
              >
              </i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="actionCommands.Delete">{{
                $t("datamodel.deleteDataset")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="ingest-shared-folder-node-header-content">
          <span
            :class="`${customIcon.SharedFolder} ingest-shared-folder-node-type-shared-folder ingest-shared-folder-node-type-icon`"
            aria-hidden="true"
          ></span>
          <div class="ingest-shared-folder-node-name-and-type">
            <span
              class="ingest-shared-folder-node-name"
              :class="
                obj.name.length === 0
                  ? 'ingest-shared-folder-node-name-empty'
                  : ''
              "
              :title="obj.name"
              >{{
                obj.name.length === 0 ? $t("ingest.Not selected") : obj.name
              }}</span
            ><span
              class="ingest-shared-folder-node-type"
              :title="$t('ingest.Database')"
              >{{ $t("ingest.Shared Folder") }}</span
            >
          </div>
        </div>
      </div>
      <i
        v-if="obj.name.length !== 0"
        :class="`${customIcon.CircleOutline} ingest-shared-folder-node-container-add-join-action ingest-add-connection-indicator-start`"
        aria-hidden="true"
        data-jtk-source="true"
        data-jtk-port-type="source"
      ></i>
    </div>
  </div>
</template>

<script>
import JsplumbVue2BaseEditableNode from "./JsplumbVue2BaseEditableNode.vue";
import { CustomIcon } from "../../../../assets/js/custom-icons";

export default {
  mixins: [JsplumbVue2BaseEditableNode],
  data() {
    return {
      customIcon: CustomIcon,
      actionCommands: {
        Delete: "delete",
      },
    };
  },
  methods: {
    handleCommand(command) {
      if (command === this.actionCommands.Delete) {
        this.onDeleteClicked();
      }
    },
    onEditClicked() {
      this.$parent.$parent.onEditClicked(this.obj.id);
    },
    onDeleteClicked() {
      this.$parent.$parent.onDeleteClicked(this.obj.id);
    },
    onShowPreview() {
      this.$parent.$parent.onShowPreview(this.obj.id);
    },
  },
};
</script>

<style>
.ingest-add-connection-indicator-start {
  color: black;
  font-size: 1.25rem;
  line-height: 20px;
  position: absolute;
  bottom: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.ingest-shared-folder-node-container:hover
  .ingest-shared-folder-node-container-add-join-action {
  display: block;
}
.ingest-shared-folder-node-container-add-join-action {
  font-size: 1.25rem;
  cursor: pointer;
  color: black !important;
  display: none;
}
.ingest-shared-folder-node-container {
  width: 200px;
  height: 92px;
  padding: 8px;
}
.ingest-shared-folder-node {
  padding: 0px;
  position: absolute;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  cursor: move;
  color: white;
  font-size: 1rem;
  width: 200px;
  height: 92px;
  display: flex;
  flex-direction: column;
}
.ingest-shared-folder-node-selected {
  border: 3px solid #3d62f8 !important;
  box-sizing: border-box;
}
.ingest-dataset-node-actions {
  font-size: 1.125rem;
  font-weight: 400;
  color: #3d62f8 !important;
  cursor: pointer;
  display: none;
}
.ingest-shared-folder-node:hover .ingest-dataset-node-actions {
  display: block;
}
.ingest-shared-folder-node-header {
  height: 90px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  background: #e8e8e8;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px;
}
.ingest-shared-folder-node-body {
  height: 30px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  column-gap: 8px;
}
.ingest-shared-folder-node:hover .ingest-shared-folder-node-actions {
  display: block;
}
.ingest-shared-folder-node:hover .ingest-shared-folder-node-add-join-action {
  display: block;
}
.ingest-shared-folder-node-footer-bottom-text {
  font: normal normal 500 12px/18px Poppins;
  letter-spacing: 0px;
  color: black;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ingest-shared-folder-node-name {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 18px;
  color: #181818;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ingest-shared-folder-node-type {
  font-size: 0.6875rem;
  line-height: 18px;
  color: #5e5d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ingest-shared-folder-node-type::first-letter {
  text-transform: uppercase;
}

.ingest-shared-folder-node-status {
  font-size: 0.5rem;
  line-height: 10px;
  color: #5e5d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ingest-shared-folder-node-type-icon {
  font-size: 2rem !important;
}
.ingest-shared-folder-node-type-shared-folder {
  color: #0864d6 !important;
}
.ingest-shared-folder-node-actions {
  font-size: 1.125rem;
  font-weight: 400;
  color: #3d62f8 !important;
  cursor: pointer;
  display: none;
}
.ingest-shared-folder-node-add-join-action {
  font-size: 1.25rem;
  cursor: pointer;
  color: #3d62f8 !important;
  display: none;
}
.ingest-shared-folder-node-header-actions {
  gap: 10px;
  position: absolute;
  justify-content: end;
  width: 100%;
  right: 10px;
  margin-left: auto;
}
.ingest-shared-folder-node-header-content {
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 10px;
}
.ingest-shared-folder-node-name-and-type {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 40px);
}
.ingest-shared-folder-node-name-empty {
  color: #ff0f0f;
}
</style>
