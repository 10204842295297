var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vis-tools-box vis-left-border vis-page vis-bg-color-white"},[_c('div',{staticClass:"propertiesTopBar vis-display-flex"},[_c('span',{staticClass:"vis-font-bold"},[_vm._v(_vm._s(_vm.$t("generalPages.properties")))]),_c('i',{staticClass:"vis-cursor-pointer vis-ml--auto e-icons e-large",class:_vm.customIcon.Close,attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$emit('close')}}})]),_c('el-collapse',{attrs:{"value":_vm.activeCollapses},on:{"change":(e) => (_vm.activeCollapses = e)}},_vm._l((_vm.authorizationPropertiesCollapses),function(collapse){return _c('el-collapse-item',{key:collapse.key,staticClass:"vis-collapse-item",attrs:{"title":_vm.$t(`${collapse.title}`),"name":collapse.key,"disabled":collapse.disabled}},[(collapse.key === _vm.authorizationPropertiesCollapseTypes.General)?_c('div',_vm._l((_vm.generalDescriptionTable),function(row){return _c('div',{key:row.title,staticClass:"vis-mb--1"},[_c('div',{staticClass:"vis-display-flex"},[_c('span',{staticClass:"vis-font-medium"},[_vm._v(_vm._s(_vm.$t(`${row.title}`)))]),_c('span',{staticClass:"vis-ml--auto"},[_vm._v(_vm._s(row.value))])])])}),0):_vm._e(),(
          collapse.key ===
          _vm.authorizationPropertiesCollapseTypes.AccessPermissions
        )?_c('div',[_c('div',{staticClass:"vis-mb--1"},[_c('label',[_vm._v(_vm._s(_vm.$t("generalPages.searchUsersOrGroups")))]),_c('el-autocomplete',{staticClass:"vis-mb--1 vis-width-100",attrs:{"debounce":1000,"fetch-suggestions":_vm.querySearchAsync,"placeholder":_vm.$t('generalPages.searchInput')},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"value"},[_c('i',{staticClass:"vis-mr--1",class:item.type === _vm.userTypes.USER
                      ? 'el-icon-user'
                      : 'el-icon-office-building',attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"link"},[_vm._v(_vm._s(item.value))])])]}}],null,true),model:{value:(_vm.autocompleteValue),callback:function ($$v) {_vm.autocompleteValue=$$v},expression:"autocompleteValue"}},[_c('i',{staticClass:"el-input__icon addAction",class:_vm.customIcon.Plus,attrs:{"slot":"suffix","aria-hidden":"true"},on:{"click":_vm.handleCreateAuthorization},slot:"suffix"})]),_c('vue-good-table',{attrs:{"max-height":"400px","columns":_vm.accessPermissionsColumns,"rows":_vm.accessPermissionsRows,"fixed-header":true,"sort-options":{
              enabled: false,
            }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(`${props.column.label}`))+" ")])]}},{key:"table-row",fn:function(props){return [(
                  props.column.field === _vm.accessPermissionsColumnsFields.Name
                )?_c('div',[_c('span',{staticClass:"vis-float--left vis-field-item-span",attrs:{"title":props.row.username || props.row.name}},[_vm._v(_vm._s(props.row.username || props.row.name))])]):(
                  props.column.field ===
                  _vm.accessPermissionsColumnsFields.Authority
                )?_c('div',[_c('el-select',{attrs:{"value":props.row.authority,"placeholder":_vm.$t('generalPages.permission'),"size":"mini"},on:{"change":(val) => _vm.onAuthorityChanged(val, props.row)}},_vm._l((_vm.optionsAuthority(props.row)),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.label,"value":item.key}})}),1)],1):(
                  props.column.field ===
                  _vm.accessPermissionsColumnsFields.Actions
                )?_c('div',[_c('div',[_c('span',[_c('i',{staticClass:"vis-cursor-pointer",class:_vm.customIcon.TrashCircle,attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.callRemoveAuthorizationById(props.row.id)}}})])])]):_c('span',{staticClass:"vis-limit-long-words"},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,true)})],1)]):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }