<template>
  <div
    v-bind:style="{
      left: obj.left + 'px',
      top: obj.top + 'px',
    }"
    class="flowchart-object flowchart-action dataset-node-container"
    data-jtk-target="true"
  >
    <div v-if="!obj.datasetVerified" class="dataset-node-not-verified">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('datamodel.The dataset needs to be verified')"
        placement="top"
      >
        <i
          :class="`${customIcon.Information} dataset-node-not-verified-icon`"
          aria-hidden="true"
        />
      </el-tooltip>
    </div>
    <div
      class="dataset-node"
      :class="{ 'datamodel-dataset-node-selected': obj.nodeSelected }"
    >
      <div class="dataset-node-header">
        <div
          class="dataset-node-header-actions vis-flex--Ycenter"
          style="margin-left: auto"
        >
          <i
            :class="`${customIcon.VisibilityEyeMaterial} dataset-node-actions`"
            aria-hidden="true"
            @click="onShowPreview"
          >
          </i>
          <i
            :class="`${customIcon.PencilOutline} dataset-node-actions`"
            aria-hidden="true"
            @click="onEditClicked"
          >
          </i>
          <el-dropdown
            placement="bottom-start"
            size="mini"
            @command="handleCommand"
          >
            <span class="el-dropdown-link"
              ><i
                :class="`${customIcon.VerticalThreeDot} dataset-node-actions`"
                aria-hidden="true"
              >
              </i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="actionCommands.Delete">{{
                $t("datamodel.deleteDataset")
              }}</el-dropdown-item>
              <el-dropdown-item
                v-if="isImportTypeCsvOrExcel"
                :command="actionCommands.Append"
                divided
                >{{ $t("datamodel.appendDataset") }}</el-dropdown-item
              >
              <el-dropdown-item
                v-if="isImportTypeCsvOrExcel"
                :command="actionCommands.Replace"
                divided
                >{{ $t("datamodel.replaceDataset") }}</el-dropdown-item
              >
              <el-dropdown-item
                v-if="!isImportTypeCsvOrExcel"
                :command="actionCommands.Duplicate"
                divided
                >{{ $t("datamodel.duplicateDataset") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="dataset-node-header-content">
          <span
            :class="`${importTypeIcon} dataset-node-type-icon`"
            aria-hidden="true"
          ></span>
          <div class="dataset-node-name-and-type">
            <span class="dataset-node-name" :title="obj.name">{{
              obj.name
            }}</span
            ><span class="dataset-node-type" :title="obj.datasetType">{{
              obj.datasetType
            }}</span>
            <span class="dataset-node-status" :title="obj.status">{{
              obj.status
            }}</span>
          </div>
        </div>
      </div>
      <div class="dataset-node-body">
        <div class="dataset-node-footer-bottom-text">
          {{ obj.columns + " " + $t("datamodel.columns") }}
        </div>
      </div>
      <i
        :class="`${customIcon.Join} dataset-node-add-join-action visualize-add-connection-indicator`"
        aria-hidden="true"
        data-jtk-source="true"
        data-jtk-port-type="source"
      >
      </i>
    </div>
  </div>
</template>

<script>
import JsplumbVue2BaseEditableNode from "./JsplumbVue2BaseEditableNode.vue";
import { CustomIcon } from "../../../assets/js/custom-icons";
import { importTypes } from "../../../commons/dataModelTypes";

export default {
  mixins: [JsplumbVue2BaseEditableNode],
  data() {
    return {
      customIcon: CustomIcon,
      actionCommands: {
        Append: "append",
        Delete: "delete",
        Replace: "replace",
        Duplicate: "duplicate",
      },
    };
  },
  computed: {
    importTypeIcon() {
      return this.obj.datasetType === importTypes.DB
        ? `${CustomIcon.Database} dataset-node-type-database`
        : this.obj.datasetType === importTypes.CSV
        ? `${CustomIcon.Draft} dataset-node-type-database`
        : `${CustomIcon.Draft} dataset-node-type-database`;
    },
    isImportTypeCsvOrExcel() {
      return (
        this.obj.datasetType === importTypes.CSV ||
        this.obj.datasetType === importTypes.EXCEL
      );
    },
  },
  methods: {
    handleCommand(command) {
      if (command === this.actionCommands.Delete) {
        this.onDeleteClicked();
      } else if (command === this.actionCommands.Append) {
        this.onAppendClicked();
      } else if (command === this.actionCommands.Replace) {
        this.onReplaceClicked();
      } else if (command === this.actionCommands.Duplicate) {
        this.onDuplicateClicked();
      }
    },
    onEditClicked() {
      this.$parent.$parent.onEditClicked(this.obj.id);
    },
    onDeleteClicked() {
      this.$parent.$parent.onDeleteClicked(this.obj.id);
    },
    onAppendClicked() {
      this.$parent.$parent.onAppendClicked(this.obj.id);
    },
    onReplaceClicked() {
      this.$parent.$parent.onReplaceClicked(this.obj.id);
    },
    onDuplicateClicked() {
      this.$parent.$parent.onDuplicateClicked(this.obj.id);
    },
    onShowPreview() {
      this.$parent.$parent.onShowPreview(this.obj.id);
    },
  },
};
</script>

<style>
.datamodel-dataset-node-selected {
  border: 2px solid var(--primary-lighteen-1) !important;
  box-sizing: border-box;
}
.dataset-node-container {
  width: 200px;
  height: 132px;
  padding: 8px;
}
.dataset-node {
  padding: 0px;
  position: absolute;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  cursor: move;
  color: white;
  font-size: 16px;
  width: 200px;
  height: 132px;
  display: flex;
  flex-direction: column;
}
.dataset-node-header {
  height: 100px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  background: #e8e8e8;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px;
}
.dataset-node-body {
  height: 30px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  column-gap: 8px;
}
.visualize-add-connection-indicator {
  color: black;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  bottom: 3px;
  right: 6px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dataset-node:hover .dataset-node-actions {
  display: block;
}
.dataset-node:hover .dataset-node-add-join-action {
  display: block;
}
.dataset-node-footer-bottom-text {
  font: normal normal 500 12px/18px Poppins;
  letter-spacing: 0px;
  color: black;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dataset-node-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #181818;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataset-node-type {
  font-size: 11px;
  line-height: 18px;
  color: #5e5d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dataset-node-status {
  font-size: 8px;
  line-height: 10px;
  color: #5e5d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dataset-node-type-icon {
  font-size: 35px !important;
}
.dataset-node-type-database {
  color: #a2a2a2;
}
.dataset-node-actions {
  font-size: 18px;
  font-weight: 400;
  color: #3d62f8 !important;
  cursor: pointer;
  display: none;
}
.dataset-node-add-join-action {
  font-size: 20px;
  cursor: pointer;
  color: #3d62f8 !important;
  display: none;
}
.dataset-node-header-actions {
  gap: 10px;
  position: absolute;
  justify-content: end;
  width: 100%;
  right: 10px;
}
.dataset-node-header-content {
  display: flex;
  align-items: end;
  height: 100%;
  column-gap: 10px;
  margin-bottom: 10px;
}
.dataset-node-name-and-type {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 40px);
}
.dataset-node-not-verified {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}
.dataset-node-not-verified-icon {
  color: var(--danger) !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
}
</style>
