<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold"> {{ $t("ingest.Toolbox") }}</span>
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="collapse.label"
        :name="collapse.key"
        :disabled="collapse.disabled"
      >
        <div class="vis-row">
          <div
            v-for="item in collapse.items"
            :key="item.name"
            class="collapse-content-item-wrapper vis-col vis-col-6"
          >
            <div
              class="collapse-content-item"
              draggable
              @dragstart="startDrag($event, item.name)"
            >
              <span
                :class="`collapse-content-item-icon ${item.className}`"
              ></span>
            </div>
            <span class="collapse-content-item-name">{{ item.label }}</span>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { IngestNodeTypes } from "./enums.js";

export default {
  components: {},
  props: {},
  data() {
    return {
      collapseTypes: {
        sources1: "sources1",
        sources2: "sources2",
      },
      activeCollapses: ["sources1", "sources2"],
    };
  },
  mounted() {
    this.activeCollapses.push(this.collapseTypes.general);
  },
  computed: {
    allCollapses() {
      return [
        {
          key: this.collapseTypes.sources2,
          label: this.$t("ingest.Source"),
          items: [
            {
              name: IngestNodeTypes.Database,
              className: `${CustomIcon.Database} collapse-content-item-icon-database`,
              label: this.$t("ingest.Database"),
            },
            {
              name: IngestNodeTypes.FTP,
              className: `${CustomIcon.FolderData} collapse-content-item-icon-ftp`,
              label: this.$t("ingest.FTP"),
            },
            {
              name: IngestNodeTypes.SMB,
              className: `${CustomIcon.SharedFolder} collapse-content-item-icon-shared-folder`,
              label: this.$t("ingest.Shared Folder"),
            },
          ],
          isShow: true,
        },
        {
          key: this.collapseTypes.sources1,
          label: this.$t("ingest.Target"),
          items: [
            {
              name: IngestNodeTypes.Dataset,
              className: `${CustomIcon.Dataset} collapse-content-item-icon-dataset`,
              label: this.$t("ingest.Dataset"),
            },
          ],
          isShow: true,
        },
      ].filter((c) => c.isShow);
    },
  },
  methods: {
    startDrag(evt, itemName) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";

      // Set custom data using the 'setData' method
      evt.dataTransfer.setData(
        "text/plain",
        JSON.stringify({ dragItemName: itemName })
      );
    },
  },
};
</script>

<style scoped>
.collapse-content-item {
  width: 56px;
  height: 65px;
  background: #f4f7f8 0% 0% no-repeat padding-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collapse-content-item-icon {
  font-size: 40px;
}
.collapse-content-item-icon-database {
  color: #e8a309;
}
.collapse-content-item-icon-ftp {
  color: #67ba6d;
}
.collapse-content-item-icon-shared-folder {
  color: #0864d6;
}
.collapse-content-item-icon-dataset {
  color: #835dff;
}
.collapse-content-item-name {
  font-weight: 600;
  line-height: 25px;
  text-align: center;
}
.collapse-content-item-wrapper {
  margin-bottom: 20px;
  width: fit-content;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
