<template>
  <div
    class="vis-width-100 vis-height-100 vis-position-relative"
    id="scenario-toolkit-container"
  >
    <add-new-state-button />
    <jsplumb-toolkit
      ref="toolkitComponent"
      v-bind:render-params="renderParams"
      v-bind:view="view"
      id="toolkit"
      :surface-id="surfaceId"
    ></jsplumb-toolkit>
    <jsplumb-miniview :surface-id="surfaceId"></jsplumb-miniview>
  </div>
</template>

<script>
// jsplumb imports
import {
  ForceDirectedLayout,
  LassoPlugin,
  DrawingToolsPlugin,
  DotEndpoint,
  BezierConnector,
  EVENT_GRAPH_CLEARED,
  EVENT_CLICK,
  EVENT_EDGE_ADDED,
  EVENT_NODE_MOVE_END,
  EVENT_CANVAS_CLICK,
  ArrowOverlay,
  CustomOverlay,
} from "@jsplumbtoolkit/browser-ui";
import { getSurface } from "@jsplumbtoolkit/browser-ui-vue2";

// local imports
import JsplumbVue2StateNode from "./JsplumbVue2StateNode.vue";
import JsplumbVue2StartNode from "./JsplumbVue2StartNode.vue";
import JsplumbVue2FinishNode from "./JsplumbVue2FinishNode.vue";
import AddNewStateButton from "./AddNewStateButton.vue";
import TransitionOverlay from "./TransitionOverlay.vue";
import Vue from "vue";

let toolkitComponent;
let toolkit;

export default {
  components: { AddNewStateButton },
  name: "JsplumbVue2Flowchart",
  props: {
    surfaceId: {
      type: String,
      required: false,
    },
    editorData: {
      type: Object,
    },
  },
  data() {
    return {
      selectedConnections: [],
      renderParams: {
        enablePan: true,
        wheel: {
          zoom: true,
        },
        layout: {
          type: ForceDirectedLayout.type,
        },
        events: {
          [EVENT_CANVAS_CLICK]: () => {
            this.$emit("canvas-clicked");
          },
          [EVENT_NODE_MOVE_END]: () => {
            this.onPositionsAndLevelsUpdated();
          },
        },
        consumeRightClick: true,
        dragOptions: {
          filter: ".jtk-draw-handle, .node-action, .node-action i",
        },
        plugins: [
          DrawingToolsPlugin.type,
          {
            type: LassoPlugin.type,
            options: {
              invert: true,
            },
          },
        ],
        grid: {
          size: {
            w: 20,
            h: 20,
          },
        },
        magnetize: {
          afterDrag: false,
        },
      },
      view: {
        nodes: {
          State: {
            component: JsplumbVue2StateNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.$emit("state-clicked", e.obj.data.stateId);
              },
            },
          },
          Start: {
            component: JsplumbVue2StartNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.$emit("state-clicked", e.obj.data.stateId);
              },
            },
          },
          Finish: {
            component: JsplumbVue2FinishNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.$emit("state-clicked", e.obj.data.stateId);
              },
            },
          },
        },
        edges: {
          default: {
            anchor: "AutoDefault",
            endpoint: {
              type: DotEndpoint.type,
              options: {
                radius: 5,
              },
            },
            connector: {
              type: BezierConnector.type,
              options: {
                loopbackRadius: 75,
              },
            },
            // paint style for this edge type.
            paintStyle: {
              strokeWidth: 2,
              stroke: "#000",
              outlineWidth: 3,
              outlineStroke: "transparent",
              dashstyle: "2 4",
            },
            hoverPaintStyle: {
              strokeWidth: 4,
              stroke: "#000",
              outlineWidth: 5,
              outlineStroke: "transparent",
              dashstyle: "2 4",
            },
          },
          StateEdge: {
            parent: "default",
            overlays: [
              {
                type: ArrowOverlay.type,
                options: {
                  width: 10,
                  length: 15,
                  location: 1,
                },
              },
              {
                type: CustomOverlay.type,
                options: {
                  create: (n) => {
                    const nodeName = n.data.detail?.event;
                    const displayName = nodeName?.length
                      ? nodeName
                      : this.$t("Not selected");

                    // Create Vue component instance and return its root element
                    const component = Vue.extend(TransitionOverlay);
                    const instance = new component({
                      propsData: {
                        type: "Transition",
                        id: n.data.detail?.transitionId,
                        displayName,
                        isEdgeSelected: n.data.isEdgeSelected,
                        hasEvent: n.data.detail?.event?.length > 0,
                        hasEnrichment: n.data.detail?.enrichments?.length > 0,
                        hasRule: n.data.detail?.expression?.length > 0,
                        hasAction:
                          n.data.detail?.action?.actiondetails?.name?.length >
                          0,
                      },
                    }).$mount();
                    instance.$on("onEventIconClick", this.handleEventIconClick);
                    instance.$on(
                      "onEnrichmentIconClick",
                      this.handleEnrichmentIconClick
                    );
                    instance.$on("onRuleIconClick", this.handleRuleIconClick);
                    instance.$on(
                      "onActionIconClick",
                      this.handleActionIconClick
                    );
                    instance.$on(
                      "onTransitionDeleteClick",
                      this.handleTransitionDeleteClick
                    );

                    return instance.$el;
                  },
                  events: {
                    [EVENT_CLICK]: (overlayClickParams) => {
                      //deselect all kısmı
                      this._getSurface((s) => {
                        s.getConnectionsForEdges(toolkit.getAllEdges()).forEach(
                          (con) => {
                            con.connector.connection.setPaintStyle(
                              this.view.edges.default.paintStyle
                            );
                            con.connector.connection.setVisible(false);
                            con.connector.connection.setVisible(true);
                          }
                        );
                      });
                      toolkit.clearSelection();

                      this.$emit(
                        "transition-clicked",
                        overlayClickParams.connection.data.detail.transitionId
                      );
                    },
                  },
                },
              },
            ],
          },
        },

        ports: {
          start: {
            edgeType: "default",
          },
          source: {
            maxConnections: -1,
            edgeType: "StateEdge",
          },
          target: {
            maxConnections: -1,
            isTarget: true,
          },
        },
      },
    };
  },
  watch: {
    editorData: {
      deep: true,
      handler() {
        toolkit.clear();
      },
    },
  },
  mounted() {
    this.initializeToolkit();

    toolkit.bind(EVENT_EDGE_ADDED, (e) => {
      if (e.addedByMouse) {
        const alreadyAdded = this.isJoinAlreadyAdded(
          e.edge.source.id,
          e.edge.target.id
        );

        if (!alreadyAdded) {
          this.$emit("addNewTransition", {
            source: e.edge.source.id,
            target: e.edge.target.id,
          });
        }
      }
    });
  },

  methods: {
    handleEventIconClick(id) {
      this.$emit("onEventIconClick", id);
    },
    handleEnrichmentIconClick(id) {
      this.$emit("onEnrichmentIconClick", id);
    },
    handleRuleIconClick(id) {
      this.$emit("onRuleIconClick", id);
    },
    handleActionIconClick(id) {
      this.$emit("onActionIconClick", id);
    },
    handleTransitionDeleteClick(id) {
      this.$emit("onTransitionDeleteClick", id);
    },
    onDeleteClicked(id) {
      this.$emit("onDeleteClicked", id);
    },
    onConvertToFinishClicked(id) {
      this.$emit("onConvertToFinishClicked", id);
    },
    onConvertToStateClicked(id) {
      this.$emit("onConvertToStateClicked", id);
    },
    onPositionsAndLevelsUpdated() {
      let nodesCoords = [];
      const nodes = document.getElementsByClassName("scenario-flowchart-node");
      const convertCssTextToCssObject = (cssText) => {
        const result = {};
        // Split the CSS values by semicolon to get individual property declarations
        const declarations = cssText.split(";");

        // Loop through each declaration and extract property and value
        declarations.forEach(function (declaration) {
          // Split the declaration into property and value
          const parts = declaration.trim().split(":");

          // If the declaration has both property and value, add them to the JavaScript object
          if (parts.length === 2) {
            const property = parts[0].trim();
            const value = parseFloat(parts[1].trim()); // Parse the value as a float

            result[property] = value;
          }
        });

        return result;
      };

      for (let i = 0; i < nodes.length; i++) {
        nodesCoords.push({
          source_id: nodes[i].getAttribute("data-jtk-managed"),
          source_css: convertCssTextToCssObject(nodes[i].style.cssText),
        });
      }

      this.$emit("updatePositionsAndLevels", { nodesCoords });
    },
    // a wrapper around getSurface, which expects a callback, as the surface may or may not have been
    // initialised when calls are made to it.
    _getSurface(cb) {
      getSurface(this.surfaceId, cb);
    },
    initializeToolkit() {
      toolkitComponent = this.$refs.toolkitComponent;
      toolkit = toolkitComponent.toolkit;

      toolkit.bind(EVENT_GRAPH_CLEARED, () => {
        if (toolkit.getNodeCount() === 0) {
          this.appendToolkitData();
        }
      });

      this.loadToolkitData();
    },
    loadToolkitData() {
      try {
        if (this.editorData)
          toolkit.load({
            type: "json",
            data: this.editorData,
          });
      } catch (e) {
        console.log("Load error", e);
      }
    },
    appendToolkitData() {
      try {
        if (this.editorData)
          toolkit.append({
            type: "json",
            data: this.editorData,
          });
      } catch (e) {
        console.log("Append error", e);
      }
    },
    isJoinAlreadyAdded(sourceId, targetId) {
      let alreadyAdded = false;
      const allEdgesExceptLastAdded = toolkit
        .getAllEdges()
        .slice(0, toolkit.getAllEdges().length - 1);

      allEdgesExceptLastAdded?.forEach((e) => {
        if (
          (e.source.id === sourceId && e.target.id === targetId) ||
          (e.source.id === targetId && e.target.id === sourceId)
        ) {
          alreadyAdded = true;
        }
      });

      return alreadyAdded;
    },
  },
};
</script>

<style>
.connection-middle-icon {
  -webkit-transition: background-color 0.25s ease-in;
  -moz-transition: background-color 0.25s ease-in;
  transition: background-color 0.25s ease-in;
}
.connection-middle-icon {
  color: white;
  padding: 0.3em;
  cursor: pointer;
}
.dataset-node-connection-action-icon {
  color: #004de6;
  font-size: 40px !important;
  padding: 5px;
}
.connection-middle-container {
  display: flex;
  align-items: center;
}
.connection-middle-left {
  color: black;
  display: none;
  font-weight: 500;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75px;
}
.connection-middle-right {
  color: black;
  display: none;
  font-weight: 500;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75px;
}
.connection-middle-container:hover .connection-middle-left {
  display: block !important;
}
.connection-middle-container:hover .connection-middle-right {
  display: block !important;
}

.jtk-miniview-element {
  background-color: var(--primary-lighteen-1) !important;
}
#scenario-toolkit-container .jtk-surface .jtk-surface-canvas .jtk-overlay {
  background-color: transparent !important;
}
</style>
