<template>
  <vue-good-table
    max-height="300px"
    :columns="columns"
    :rows="rows"
    :fixed-header="true"
    :sort-options="{
      enabled: false,
    }"
  >
    <template slot="table-column" slot-scope="props">
      <span>
        {{ $t(`${props.column.label}`) }}
      </span>
    </template>
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'name'">
        <el-tooltip
          placement="bottom"
          popper-class="vis-bg-neon-blue"
          :disabled="isTooltipDisabled"
        >
          <template v-slot:content>
            <div class="value vis-line-height-normal">
              {{ props.row.name }}
            </div>
            <p>
              {{ props.row.nameSubtitle }}
            </p>
          </template>
          <div @mouseover="controlWidth($event)">
            <p class="vis-ellipsis">{{ props.row.name }}</p>
            <p class="nameSubtitle vis-ellipsis">
              {{ props.row.nameSubtitle }}
            </p>
          </div>
        </el-tooltip>
      </span>
      <span v-else-if="props.column.field == 'nameSubtitle'"> </span>
      <!-- START ACTIONS -->
      <span v-else-if="props.column.field == 'actions'">
        <i
          :class="customIcon.Trash"
          aria-hidden="true"
          class="vis-font-size-0875"
          @click="$emit('handleDeleteRow', props.row.id)"
        ></i>
      </span>
      <!-- END ACTIONS -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { CustomIcon } from "../../assets/js/custom-icons";
import { checkOffsetAndScrollWidth } from "../../util/utils";

export default {
  components: {
    VueGoodTable,
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      isTooltipDisabled: true,
    };
  },
  methods: {
    controlWidth(event) {
      this.isTooltipDisabled = checkOffsetAndScrollWidth(event);
    },
  },
};
</script>
<style scoped>
::v-deep .vgt-table.bordered th {
  padding: 5px 0px 5px 10px;
}
::v-deep .vgt-table.bordered td {
  padding: 5px 0px 5px 10px;
  text-align: left;
  vertical-align: middle;
}
::v-deep .vgt-table.bordered td:last-child {
  padding-right: 2px;
}
::v-deep .vgt-table thead tr {
  background: #f4f7fd;
}
.nameSubtitle {
  font-size: 12px;
  color: #a1a1a1;
  line-height: normal;
  transform: translateY(-4px);
}
.vis-ellipsis {
  width: 200px;
}
</style>
