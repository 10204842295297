//TODO revize edilecek
const generateNodes = (chartAggs, searchData) => {
  const nodes = [];

  searchData.aggregations.forEach((element) => {
    chartAggs.forEach((agg) => {
      const fieldName = element[agg.field];
      if (!nodes.some((node) => node.name === fieldName)) {
        nodes.push({ name: fieldName, field: agg.field });
      }
    });
  });

  return nodes;
};

const generateLinks = (chartAggs, chartMetrics, searchData) => {
  const links = [];
  const columnOrder = chartAggs.reduce((order, agg, index) => {
    order[agg.field] = index + 1;
    return order;
  }, {});

  searchData.aggregations.forEach((element) => {
    for (let i = 0; i < chartAggs.length - 1; i++) {
      const sourceField = chartAggs[i].field;

      if (!element[sourceField]) {
        continue;
      }

      let targetIndex = i + 1;
      
      while (targetIndex < chartAggs.length && !element[chartAggs[targetIndex].field]) {
        targetIndex++;
      }

      if (targetIndex < chartAggs.length) {
        const targetField = chartAggs[targetIndex].field;
        const metricField = chartMetrics[0].field;
        const metricType = chartMetrics[0].type;

        if (
          columnOrder[sourceField] < columnOrder[targetField]
        ) {
          const linkValue = element[metricField + metricType];
          const existingLink = links.find(
            (link) =>
              link.source === element[sourceField] &&
              link.target === element[targetField]
          );

          if (existingLink) {
            existingLink.value += linkValue;
          } else {
            links.push({
              source: element[sourceField],
              target: element[targetField],
              value: linkValue,
              targetField,
              sourceField
            });
          }
        }
      }
    }
  });

  return links;
};

export const getSankeyChartSearch = (params, searchData, chartI) => {
  const chartAggs = params.aggregation.map((agg) => ({
    field: agg.field,
  }));

  const chartMetrics = params.metric.map((metric) => ({
    field: metric.field,
    type: metric.type,
  }));
  const aggregationFormats = params?.aggregation?.map((a) => a.format);
  const nodes = generateNodes(chartAggs, searchData);
  const links = generateLinks(chartAggs, chartMetrics, searchData);

  
  const dataSeriesTotal = links.map(m=> m.value).reduce((a, b) => a + b)
  
  return {
    dataSeries:nodes,
    links,
    id: chartI,
    dataSeriesTotal,
    aggregationFormats
  };
};
