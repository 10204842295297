import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";
import { createMetricKey } from "../chartOptionsHelper";

export const richTextChartSearch = (params, searchData, chartI) => {

  const metric = params?.metric?.find(
    (x) => x.boxKey === BOX_KEY_ENUM.CONTENT.KEY
  );

  //target slotu tek başına çalışmayacağı için.
  if (!metric || !metric?.isAuthorize) return;

  const mappedResponseList = params?.metric
    ?.map((m) => {
      const fieldKey = createMetricKey(m);
      const value = searchData?.aggregations?.[0]?.[fieldKey];
      return {
        field: m?.field,
        alias: m?.alias,
        value,
        format: m?.format,
        boxKey: m?.boxKey,
        type: m?.type,
      };
    });

  return {
    id: chartI,
    response: mappedResponseList,
  };
};
