
export const SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS = {
    SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME: "",
    SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT: false,
    SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE: false,
    SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION: false,
    SELECTOR_DATA_ITEM_CHART_SHOW_LIST: false
};

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
  return typeof value != "undefined" ? value : computedValue;
};