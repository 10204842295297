<template>
  <div class="vis-chart-card-title" :class="[chartCardTitleAlignCenter]">
    <div class="vis-chart-card-input-box">
      <input
        v-show="hasTitle && chartName"
        type="text"
        :value="chartName"
        :placeholder="$t('dashboard.enterTitle')"
        :style="titleFontStyle"
        disabled
      />
      <br />
      <input
        v-show="hasSubTitle && chartDescription"
        type="text"
        :value="chartDescription"
        :placeholder="$t('dashboard.enterDesc')"
        disabled
      />
    </div>
    <div>
      <el-popover
        v-if="hasActiveFilterIcon"
        placement="top-start"
        trigger="hover"
      >
        <div
          class="vis-card-filter-info-item"
          v-html="panelDataFilterInfo"
        ></div>
        <i
          slot="reference"
          :class="CustomIcon.FilterAlt"
          class="vis-cursor-pointer vis-mr--1 vis-icon"
          aria-hidden="true"
        ></i>
      </el-popover>
      <i
        v-if="designMode && isChartAreaShow"
        @click="$emit('getViewChart')"
        :class="CustomIcon.Play"
        class="vis-cursor-pointer vis-mr--1"
        aria-hidden="true"
      ></i>
      <i
        v-if="isDisabledSortIcon"
        ref="externalIcon"
        @click="$emit('getOpenLogosSortMenu')"
        :class="CustomIcon.SortBy"
        class="vis-cursor-pointer vis-mr--1"
        aria-hidden="true"
      ></i>
      <i
        v-if="isChartAreaShow"
        @click="saveAndPreviousDashboard()"
        :class="CustomIcon.SaveOutlined"
        class="vis-cursor-pointer vis-mr--1"
        aria-hidden="true"
      ></i>
      <i
        @click="$emit('previousDashboard')"
        :class="CustomIcon.Close"
        class="vis-cursor-pointer vis-font-bold"
        aria-hidden="true"
      ></i>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { chartWithNotSeeFilterIcon } from "../../commons/dashboardAndPanel";
import { cardPropertiesEnum, dashboardCardTitlePropertiesCssKey, DefaultValue } from "../../commons/dashboardProperties";
import { Notify } from "../../commons/helper";
import { notificationType } from "../../commons/notificationTypes";
import panelDataPropertiesStyle from "../../mixins/panelDataPropertiesStyle";
import {
  getActiveFilterList,
  getFilterItemTextValue,
} from "../../util/filter/cardTitleHelper";

export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    panelData: {
      type: Object,
    },
    hasTitle: {
      type: Boolean,
    },
    hasSubTitle: {
      type: Boolean,
    },
    designMode: {
      type: Boolean,
      default: false,
    },
    isPanelPage: {
      type: Boolean,
    },
    isChartAreaShow: {
      type: Boolean,
    },
    isSaveAndPreviousDashboardActive: {
      type: Boolean,
      default: true
    },
    isDisabledSortIcon: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      CustomIcon: CustomIcon,
    };
  },
  computed: {
    chartName() {
      return this.panelData?.name;
    },
    chartDescription() {
      return this.panelData?.description;
    },
    panelDataAllFilters() {
      return [
        ...(this.panelDataDetailsFilters?.length
          ? this.panelDataDetailsFilters
          : []),
        ...(this.panelDataDetailsParams?.length
          ? this.panelDataDetailsParams
          : []),
      ];
    },
    hasActiveFilterIcon() {
      return (
        getActiveFilterList(this.panelDataAllFilters).length &&
        !chartWithNotSeeFilterIcon.includes(this.panelData.type)
      );
    },
    panelDataFilterInfo() {
      return getFilterItemTextValue(this.panelDataAllFilters, this.isPanelPage);
    },
    chartCardTitleAlignCenter() {
      return !this.panelData?.properties?.isLabel
        ? "vis-text--right"
        : "vis-flex--spacebeetwen";
    },
    isActiveDropdownFilterDefaultValue() {
      return this.dropdownFilterDefaultValue == DefaultValue.NONE;
    },
    titleFontStyle() {
      return {
        [dashboardCardTitlePropertiesCssKey.TITLE_FONT_COLOR]: this.getCardPropertiesValueByKey(cardPropertiesEnum.TITLE_FONT_COLOR),
        [dashboardCardTitlePropertiesCssKey.TITLE_FONT_WEIGHT]: this.getCardPropertiesValueByKey(cardPropertiesEnum.TITLE_FONT_WEIGHT) || 600,
        [dashboardCardTitlePropertiesCssKey.TITLE_FONT_FAMILY]: this.getCardPropertiesValueByKey(cardPropertiesEnum.TITLE_FONT_FAMILY),
        [dashboardCardTitlePropertiesCssKey.TITLE_ALIGN_ITEM]: this.getCardPropertiesValueByKey(cardPropertiesEnum.TITLE_ALIGN_ITEM),
        [dashboardCardTitlePropertiesCssKey.TITLE_FONT_SIZE]: this.getCardPropertiesValueByKey(cardPropertiesEnum.TITLE_FONT_SIZE)  + "px",
      };
    },
  },
  methods: {
    saveAndPreviousDashboard() {
      if (this.isSaveAndPreviousDashboardActive) {
        this.$emit("saveAndPreviousDashboard");
      } else {
        Notify(this.$t('notifyMessages.Select a default value'), notificationType.WARNING);
      }
    }, 
    getCardPropertiesValueByKey(key) {
      return this.panelData?.properties?.style?.[key];
    },
  },
};
</script>
