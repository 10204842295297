import {
  chartTypes,
  dashboardFilterTypes,
} from "../../commons/dashboardAndPanel";
import {
  AREA_CHART_PROPERTIES_ENUM,
  BAR_CHART_PROPERTIES_ENUM,
  BUBBLE_CHART_PROPERTIES_ENUM,
  COLUMN_CHART_PROPERTIES_ENUM,
  COMBINATION_CHART_PROPERTIES_ENUM,
  datatablePropertiesEnum,
  DATE_FILTER_PROPERTIES_ENUM,
  DROPDOWN_FILTER_PROPERTIES_ENUM,
  GAUGE_CHART_PROPERTIES_ENUM,
  GEO_MAP_CHART_PROPERTIES_ENUM,
  HEATMAP_PROPERTIES_ENUM,
  LABEL_CHART_PROPERTIES_ENUM,
  LINE_CHART_PROPERTIES_ENUM,
  PIE_CHART_PROPERTIES_ENUM,
  PIVOT_TABLE_PROPERTIES_ENUM,
  RADAR_CHART_PROPERTIES_ENUM,
  SCATTER_CHART_PROPERTIES_ENUM,
  SELECT_LIST_FILTER_PROPERTIES_ENUM,
  STACKED_BAR_CHART_PROPERTIES_ENUM,
  SANKEY_CHART_PROPERTIES_ENUM,
  STACKED_COLUMN_CHART_PROPERTIES_ENUM,
  SUMMARY_CHART_PROPERTIES_ENUM,
  TEXT_FILTER_PROPERTIES_ENUM,
  TREEMAP_PROPERTIES_ENUM,
} from "../../commons/dashboardProperties";
import { AREA_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/areaChartDefaultProperties";
import { BAR_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/barChartDefaultProperties"; 
import { BUBBLE_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/bubbleChartDefaultProperties";
import { COLUMN_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/columnChartDefaultProperties";
import { COMBINATION_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/combinationChartDefaultProperties";
import { datatableDefaultConfigurations } from "../../mocks/datatableDefaultProperties";
import { GAUGE_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/gaugeChartDefaultProperties";
import { GEO_MAP_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/geoMapDefaultProperties";
import { HEATMAP_DEFAULT_CONFIGURATIONS } from "../../mocks/heatmapDefaultProperties";
import { LABEL_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/labelChartDefaultProperties";
import { LINE_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/lineChartDefaultProperties";
import { PIE_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/pieChartDefaultProperties";
import { PIVOT_TABLE_DEFAULT_CONFIGURATIONS } from "../../mocks/pivotTableDefaultProperties";
import { RADAR_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/radarChartDefaultProperties";
import { SCATTER_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/scatterChartDefaultProperties";
import { STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/stackedBarChartDefaultProperties";
import { STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/stackedColumnChartDefaultProperties";
import { SANKEY_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/sankeyChartDefaultProperties";
import { SUMMARY_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/summaryChartDefaultProperties";
import { TREEMAP_DEFAULT_CONFIGURATIONS } from "../../mocks/treemapDefaultProperties";
import { DATE_FILTER_DEFAULT_CONFIGURATIONS } from "../../mocks/dateFilterDefaultProperties";
import { DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS } from "../../mocks/dropdownFilterDefaultProperties";
import { SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS } from "../../mocks/selectListFilterDefaultProperties";
import { TEXT_FILTER_DEFAULT_CONFIGURATIONS } from "../../mocks/textFilterDefaultProperties";

export const setDefaultProperties = (
  chartType,
  {
    checkHasDynamicTitleValue,
    checkHasGroupMultipleValues,
    checkHasUniqueName,
    setSelectedPanelStyleByNoKey,
  }
) => {
  return {
    [chartTypes.LABEL]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_GROUP_MULTIPLE_VALUES,
            LABEL_CHART_DEFAULT_CONFIGURATIONS.LABEL_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.TABLE]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            datatablePropertiesEnum.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES,
            datatableDefaultConfigurations.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.COLUMN_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_GROUP_MULTIPLE_VALUES,
            COLUMN_CHART_DEFAULT_CONFIGURATIONS.COLUMN_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.STACKED_COLUMN_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN__CHART_GROUP_MULTIPLE_VALUES,
            STACKED_COLUMN_CHART_DEFAULT_CONFIGURATIONS.STACKED_COLUMN__CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.BAR]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            BAR_CHART_PROPERTIES_ENUM.BAR_CHART_GROUP_MULTIPLE_VALUES,
            BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.STACKED_BAR_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_GROUP_MULTIPLE_VALUES,
            STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS.STACKED_BAR_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.SANKEY_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_DYNAMIC_TITLE_VALUE
        ),
        () =>
          checkHasGroupMultipleValues(
            SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_GROUP_MULTIPLE_VALUES,
            SANKEY_CHART_DEFAULT_CONFIGURATIONS.SANKEY_CHART_GROUP_MULTIPLE_VALUES
          )
      ]
    },
    [chartTypes.LINE_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            LINE_CHART_PROPERTIES_ENUM.LINE_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            LINE_CHART_PROPERTIES_ENUM.LINE_CHART_GROUP_MULTIPLE_VALUES,
            LINE_CHART_DEFAULT_CONFIGURATIONS.LINE_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.AREA_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            AREA_CHART_PROPERTIES_ENUM.AREA_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            AREA_CHART_PROPERTIES_ENUM.AREA_CHART_GROUP_MULTIPLE_VALUES,
            AREA_CHART_DEFAULT_CONFIGURATIONS.AREA_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.PIE]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            PIE_CHART_PROPERTIES_ENUM.PIE_CHART_GROUP_MULTIPLE_VALUES,
            PIE_CHART_DEFAULT_CONFIGURATIONS.PIE_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.TREEMAP_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            TREEMAP_PROPERTIES_ENUM.TREEMAP_GROUP_MULTIPLE_VALUES,
            TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.COMBINATION_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_GROUP_MULTIPLE_VALUES,
            COMBINATION_CHART_DEFAULT_CONFIGURATIONS.COMBINATION_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.HEATMAP_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            HEATMAP_PROPERTIES_ENUM.HEATMAP_GROUP_MULTIPLE_VALUES,
            HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.SCATTER_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_GROUP_MULTIPLE_VALUES,
            SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.BUBBLE_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_GROUP_MULTIPLE_VALUES,
            BUBBLE_CHART_DEFAULT_CONFIGURATIONS.BUBBLE_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.GAUGE_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_GROUP_MULTIPLE_VALUES,
            GAUGE_CHART_DEFAULT_CONFIGURATIONS.GAUGE_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.PIVOT_TABLE]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GROUP_MULTIPLE_VALUES,
            PIVOT_TABLE_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.SUMMARY]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_GROUP_MULTIPLE_VALUES,
            SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.RADAR_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_GROUP_MULTIPLE_VALUES,
            RADAR_CHART_DEFAULT_CONFIGURATIONS.RADAR_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.GEO_MAP_CHART]: {
      fn: [
        () =>
          checkHasDynamicTitleValue(
            GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_VALUE
          ),
        () =>
          checkHasGroupMultipleValues(
            GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES,
            GEO_MAP_CHART_DEFAULT_CONFIGURATIONS.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES
          )
      ],
    },
    [chartTypes.LOGO]: {
      fn: [() => {}],
    },
    [dashboardFilterTypes.FILTER_DATE]: {
      fn: [
        () =>
          checkHasUniqueName(
            DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MANDATORY_SELECTION,
            DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_MANDATORY_SELECTION
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT,
            DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_ALLOW_MULTISELECT
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION,
            DATE_FILTER_DEFAULT_CONFIGURATIONS.DATE_FILTER_DEFAULT_SELECTION
          ),
      ],
    },
    [dashboardFilterTypes.FILTER_TEXT]: {
      fn: [
        () =>
          checkHasUniqueName(
            TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_UNIQUE_NAME
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_MANDATORY_SELECTION,
            TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_MANDATORY_SELECTION
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION,
            TEXT_FILTER_DEFAULT_CONFIGURATIONS.TEXT_FILTER_DEFAULT_SELECTION
          ),
      ],
    },
    [dashboardFilterTypes.FILTER_DROPDOWN]: {
      fn: [
        () =>
          checkHasUniqueName(
            DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_UNIQUE_NAME
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_MANDATORY_SELECTION,
            DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_MANDATORY_SELECTION
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_ALLOW_MULTISELECT,
            DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_ALLOW_MULTISELECT
          ),
      ],
    },
    [dashboardFilterTypes.FILTER_SELECT_LIST]: {
      fn: [
        () =>
          checkHasUniqueName(
            SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_UNIQUE_NAME
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_MANDATORY_SELECTION,
            SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_MANDATORY_SELECTION
          ),
        () =>
          setSelectedPanelStyleByNoKey(
            SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_ALLOW_MULTISELECT,
            SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_ALLOW_MULTISELECT
          ),
      ],
    },
  }[chartType];
};
