export const validateInput = ({value, submit = true}) => {
  return submit && !value ? "vis-input-error" : "";
};

export const validateRequiredFields = (formValues, requiredFields) => {
  if (!Object.values(formValues).length) {
    return false;
  }

  return !requiredFields.some((field) => {
    return formValues[field] === null || formValues[field] === "" || formValues[field] === undefined;
  });
};