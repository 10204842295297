<template>
  <div>
    <!-- START MULTIPLE SELECT -->
    <el-select
      v-if="!isShowList"
      :value="selectorDataItemValue"
      :multiple="isMultipleProperty"
      size="mini"
      :placeholder="
        isMultipleProperty
          ? $t('Select Multiple Data')
          : $t('Select Single Data')
      "
      filterable
      clearable
      :disabled="!isDefaultValueActive && isDisabledSelect"
      @change="changeChartField($event)"
    >
      <el-option
        v-for="item in fieldList"
        :key="item.fieldId"
        :label="item.alias"
        :value="item.fieldId"
      >
      </el-option>
    </el-select>
    <!-- END MULTIPLE SELECT -->
    <ListView
      v-else
      :list="fieldListMapped"
      :value="selectorDataItemValue"
      :isDisabled="!isDefaultValueActive && isDisabledSelect"
      :isMultiple="isMultipleProperty"
      @handleChange="changeChartField"
    />
  </div>
</template>

<script>
import { detailsKeys } from "../../../commons/dashboardAndPanel";
import { SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM } from "../../../commons/dashboardProperties";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import ListView from "../../helper/ListView.vue";
export default {
  mixins: [panelDataPropertiesStyle],
  components: {
    ListView,
  },
  props: {
    panelData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isDisabledSelect: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isMandatorySelection: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal && !this.isDefaultValueActive) {
          this.$emit("setSelectedPanelStyle", {
            property:
              SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE,
            value: true,
          });
        }
      },
      deep: true,
    },
    uniqueName: {
      handler(newVal, oldVal) {
        if (!oldVal || oldVal === "") return;

        this.$emit("handleCheckNameUnique", newVal);
      },
    },
  },
  computed: {
    fieldListMapped() {
      return this.fieldList.map((m, index) => {
        return {
          label: m.alias,
          value: m.fieldId,
          id: m.fieldId + index,
        };
      });
    },
    isMultipleProperty() {
      return this.CHART_COMPUTED_VALUES?.[
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM
          .SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT
      ];
    },
    isDefaultValueActive() {
      return this.CHART_COMPUTED_VALUES?.[
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM
          .SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE
      ];
    },
    isMandatorySelection() {
      return this.CHART_COMPUTED_VALUES?.[
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM
          .SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION
      ];
    },
    uniqueName() {
      return this.CHART_COMPUTED_VALUES?.[
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM
          .SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME
      ];
    },
    isShowList() {
      return this.CHART_COMPUTED_VALUES?.[
        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_SHOW_LIST
      ];
    },
    fieldList() {
      //selector chartta, slot içindeki field'ların listesi
      return this.panelData?.details?.[detailsKeys.SELECTOR_PARAMETERS];
    },
    panelDataSelectorDataItemValue() {
      return this.panelData?.details?.[detailsKeys.selectorDataitemValue];
    },
    selectorDataItemValue() {
      return this.isMultipleProperty
        ? this.selectorDataitemValueMultiple
        : this.selectorDataitemValueSingle;
    },
    selectorDataitemValueSingle() {
      return Array.isArray(this.panelDataSelectorDataItemValue)
        ? this.panelDataSelectorDataItemValue[0]
        : this.panelDataSelectorDataItemValue;
    },
    selectorDataitemValueMultiple() {
      return Array.isArray(this.panelDataSelectorDataItemValue)
        ? this.panelDataSelectorDataItemValue.filter((x) => x)
        : [this.panelDataSelectorDataItemValue].filter((x) => x);
    },
  },
  methods: {
    changeChartField(value) {
      let selectedField;

      if (!this.isMultipleProperty) {
        selectedField = this.fieldList.find((x) => x.fieldId === value);
      } else {
        selectedField = this.fieldList.filter((x) =>
          value.some((s) => s === x.fieldId)
        ).reverse();
      }

      this.$emit("changeChartField", {
        field: selectedField,
        selectorChartI: this.panelData?.i,
        isMultiple: this.isMultipleProperty,
        isDefaultValueActive: this.isDefaultValueActive,
        isMandatorySelection: this.isMandatorySelection,
        [detailsKeys.selectorDataitemValue]: value,
      });
    },
  },
};
</script>