<template>
  <div class="license-info">
    <span class="license-info-title">{{ $t("admin.menuItem.License") }}</span>
    <div>
      <div class="vis-pt--1">
        <div class="vis-row">
          <div class="vis-col vis-col-4 vis-pl--none">
            <label>{{ $t("admin.License Expire Date") }}</label>
            <el-input type="text" :value="license.expireDate" readonly />
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4 vis-pl--none">
            <label>{{ $t("admin.Number of Users") }}</label>
            <el-input type="text" :value="license.numberOfUsers" readonly />
          </div>
        </div>
        <div class="vis-row">
          <div class="vis-col vis-col-4 vis-pl--none">
            <label>{{ $t("admin.Version") }}</label>
            <el-input type="text" :value="version" readonly />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ACTION, GETTER } from "../../store/modules/Visualize/About/types";

export default {
  mounted() {
    this.fetchLicense();
    this.fetchVersion();
  },
  computed: {
    ...mapGetters({
      license: GETTER.GET_LICENSE,
      version: GETTER.GET_VERSION,
    }),
  },
  methods: {
    ...mapActions({
      fetchLicense: ACTION.FETCH_LICENSE,
      fetchVersion: ACTION.FETCH_VERSION,
    }),
  },
};
</script>

<style scoped>
.license-info {
  padding: 20px 30px 0;
  height: 100%;
  background: white;
}
.license-info-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.625rem;
  font-weight: 600;
  color: #3e3e3e;
}
label {
  font-size: 0.8125rem;
  color: #3e3e3e;
  font-weight: 500;
  line-height: 1.5;
}
</style>
