
import {
    SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM,
} from "../../commons/dashboardProperties";
import { SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/selectorDataitemChartDefaultProperties";


export default {
    computed: {
        selectorDataitemChartComponentsMapping() {
            return {
                [SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT]: {
                    id: this.getPropertyId(
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.multiSelect",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT
                    ], SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS.SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT),
                    property: SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME]: {
                    id: this.getPropertyId(
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME,
                        this.collapse.CHART
                    ),
                    label: "generalPages.alias",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME
                    ], SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME),
                    property: SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE]: {
                    id: this.getPropertyId(
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.defaultValue",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE
                    ], SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE),
                    property: SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION]: {
                    id: this.getPropertyId(
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.mandatorySelection",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION
                    ], SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS.SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION),
                    property: SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_SHOW_LIST]: {
                    id: this.getPropertyId(
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_SHOW_LIST,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Show as List",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_SHOW_LIST
                    ], SELECTOR_DATA_ITEM_DEFAULT_CONFIGURATIONS.SELECTOR_DATA_ITEM_CHART_SHOW_LIST),
                    property: SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_SHOW_LIST,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
            }
        },
    },
};
