import { render, staticRenderFns } from "./PanelColumnsBox.vue?vue&type=template&id=a6b53d02&scoped=true"
import script from "./PanelColumnsBox.vue?vue&type=script&lang=js"
export * from "./PanelColumnsBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6b53d02",
  null
  
)

export default component.exports