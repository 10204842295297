import {
  AXIS_TYPES_ENUM,
  Orientation,
  STACKED_BAR_CHART_PROPERTIES_ENUM,
  dashboardSPEnums,
  percentageValueKeys,
  showOnlyTypesExceptOnlyItem,
} from "../../commons/dashboardProperties";
import { STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/stackedBarChartDefaultProperties";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import {
  calculateGridDistances,
  formatValueAxisLabels,
  getForScaleGroupingDataSeries,
  getGroupingDataSeriesTotal,
  getSelectedColorPaletteByProperty,
  isAxisLocationDefault,
  isAxisNameActive,
  isNameLocationDefault,
  setShowOnlyValue,
  truncateText,
  aggregationDateFormatController,
  colorConverter,
  sliderPositionHandler,
  xAxisWidthHandler,
} from "../chartOptionsHelper";
import { getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";
export const getStackedBarChartOptions = (
  stackedBarChartComputedValues,
  paramsFromSearch,
  yAxisName,
  xAxisName,
  panelData
) => {
  const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
    yAxisPosition:
      stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_POSITION,
    showYAxisLabels:
      stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_Y_AXIS_LABEL,
    showXAxisLabels:
      stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_X_AXIS_LABEL,
    showLegend: stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_LEGEND,
    legendMaxCharacter: stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_MAX_CHARACTER,
    legendPosition: stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_POSITION,
    yAxisNameLocation:
      stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_NAME_LOCATION,
    showOnly: stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_ONLY,
    xAxisDataZoom: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
    xAxisRotate: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_ROTATE,
    yAxisDataZoom: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
    panelData
  });

  const isShowHundredPercent =
    stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_HUNDRED_PERCENT;

  const isPercentTypeColumn =
    stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_PERCENT_TYPE ===
      percentageValueKeys.COLUMN || isShowHundredPercent;

  let groupingDataSeries = getForScaleGroupingDataSeries(
    isShowHundredPercent,
    paramsFromSearch
  );
  const optionObject = {
    grid: {
      top: gridTop,
      bottom: gridBottom,
      left: gridLeft,
      right: gridRight,
      containLabel: true,
    },
    title: {
      text: "",
    },
    legend: {
      show: isChartWidthLimited(panelData.w)
        ? stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_LEGEND
        : false,
      orient: getOrientPositionByLegend(stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_POSITION).toLowerCase(),
      left: getAlignPositionByLegend(stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_POSITION),
      top: getOrientPositionByLegend(stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "4%" : stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_VERTICAL_ALIGN,
      textStyle: {
        fontWeight:
          stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_FONT_WEIGHT,
        fontFamily:
          stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_FONT_FAMILY,
        fontSize:
          stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_FONT_SIZE,
        color:
          stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_FONT_COLOR,
      },
      backgroundColor: 'transparent',
      formatter: function (value) {
        return truncateText(aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats), stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_MAX_CHARACTER);
      },
      type: "scroll",
    },
    tooltip: {
      show: true,
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 12,
      },
      formatter: function (value) {
        value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);

        return showOnlyTypesExceptOnlyItem.includes(
          stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_ONLY
        )
          ? setShowOnlyValue(
              value,
              paramsFromSearch,
              stackedBarChartComputedValues,
              STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_ONLY,
              dashboardSPEnums.TOOLTIP,
              panelData,
              isPercentTypeColumn
            )
          : 
          value.name;
      },
    },
    yAxis: {
      show: paramsFromSearch?.dataAxis?.length,
      name: "",
      axisLabel: {
        show: isChartWidthLimited(panelData.w)
          ? stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_Y_AXIS_LABEL
          : false,
        fontWeight:
          stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
        fontFamily:
          stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY,
        fontSize:
          stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_SIZE,
        color:
          stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_COLOR,
        formatter: function (value) {
          const formattedValue = aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats);
          return truncateText(
            formattedValue,
            stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER
          );
        },
        hideOverlap: true,
      },
      type: "category",
      position: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_POSITION,
      axisLine: {
        show: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_SHOW_LINE,
        lineStyle: {
          color:
            stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LINE_STYLE_COLOR,
        },
      },
      data: paramsFromSearch?.dataAxis ?? [],
      axisTick: {
        show: false,
      },
    },
    xAxis: {
      show: paramsFromSearch?.dataSeries,
      name: "",
      axisLabel: {
        show: isChartHeightLimited(panelData.h)
          ? stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_X_AXIS_LABEL
          : false,
        align:
          stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
        verticalAlign:
          stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
        fontWeight:
          stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT,
        fontFamily:
          stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_FAMILY,
        fontSize:
          stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_SIZE,
        color:
          stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_COLOR,
        rotate:
          stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_ROTATE,
        overflow: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_WRAP ? 'break' : 'none',
        width: xAxisWidthHandler(stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LABEL_WRAP),
        formatter: function (value) {
          return formatValueAxisLabels(
            paramsFromSearch?.metricFormats,
            value,
            isShowHundredPercent
          );
        },
        hideOverlap: true,
      },
      axisTick: {
        show: false,
      },
      type: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LOGARITHMIC_SCALE
        ? AXIS_TYPES_ENUM.LOG
        : AXIS_TYPES_ENUM.VALUE,
      min: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_MIN,
      max: (value) => {
        return value.max;
      },
      axisLine: {
        show: true,
      },
      splitLine: {
        lineStyle: {
          color: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_GRID_LINE_COLOR,
        }
      },
    },
    dataZoom: Object.keys(paramsFromSearch).length
    ? [
        {
          type: 'slider',
          show: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
          start: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_START,
          end: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_END,
          zoomLock: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
          brushSelect: !stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
          backgroundColor: colorConverter(stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
            STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE),
          fillerColor: colorConverter(stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE,
          STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
          STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS.STACKED_BAR_CHART_DATA_ZOOM_FILLER_COLOR),
          dataBackground: {
            areaStyle: {
              color: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE
            }
          },
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          disabled: !stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
          zoomLock: stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
          xAxisIndex: [0],
        },
        {
          type: 'slider',
          show: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
          left:  sliderPositionHandler(stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_LEGEND,
            stackedBarChartComputedValues.STACKED_BAR_CHART_LEGEND_POSITION),
          start: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_START,
          end: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_END,
          zoomLock: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          brushSelect: !stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          backgroundColor: colorConverter(stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
            STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
          fillerColor: colorConverter(stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
          STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
          STACKED_BAR_CHART_DEFAULT_CONFIGURATIONS.STACKED_BAR_CHART_DATA_ZOOM_FILLER_COLOR),
          dataBackground: {
            areaStyle: {
              color: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE
            }
          },
          yAxisIndex: [0],
        },
        {
          type: 'inside',
          disabled: !stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
          zoomLock: stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          yAxisIndex: [0],
        },
      ]
    : [],
    series: paramsFromSearch?.dataSeries?.length
      ? paramsFromSearch?.dataSeries?.map((s, i) => {
          return {
            data:
              s.map((a, index) => {
                const total = getGroupingDataSeriesTotal(
                  isShowHundredPercent,
                  groupingDataSeries,
                  index
                );
                return {
                  value: isShowHundredPercent ? (a / total) * 100 : a,
                  itemStyle: getCommonChartItemStyle(
                    paramsFromSearch?.dataAxis[index],
                    panelData
                  ),
                  displayValue: a,
                };
              }) ?? [],
            name: paramsFromSearch?.dataSeriesNames?.[i],
            stack: "total",
            type: "bar",
            label: {
              show: isChartWidthLimited(panelData.w)
                ? stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_VALUE_LABEL
                : false,
              fontSize:
                stackedBarChartComputedValues.STACKED_BAR_CHART_VALUE_LABEL_FONT_SIZE,
              fontFamily:
                stackedBarChartComputedValues.STACKED_BAR_CHART_VALUE_LABEL_FONT_FAMILY,
              fontWeight:
                stackedBarChartComputedValues.STACKED_BAR_CHART_VALUE_LABEL_FONT_WEIGHT,
              color:
                stackedBarChartComputedValues.STACKED_BAR_CHART_VALUE_LABEL_FONT_COLOR,
                formatter: function (value) {
                  value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);

                  return showOnlyTypesExceptOnlyItem.includes(
                    stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_ONLY
                  )
                    ? setShowOnlyValue(
                        value,
                        paramsFromSearch,
                        stackedBarChartComputedValues,
                        STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_ONLY,
                        dashboardSPEnums.LABEL,
                        panelData,
                        isPercentTypeColumn
                      )
                    :
                    value.name;
                },
            },
            itemStyle: {
              borderRadius: [0, 3, 3, 0],
            },
            labelLayout: {
              hideOverlap:
                stackedBarChartComputedValues.STACKED_BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
            },
          };
        })
      : null,
    color: getSelectedColorPaletteByProperty(stackedBarChartComputedValues.STACKED_BAR_CHART_SELECTED_COLOR_PALETTE),
  };
  return {
    options: optionObject,
    yAxis: {
      name: isAxisNameActive(paramsFromSearch, xAxisName),
      show: isChartWidthLimited(panelData.w)
        ? stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_Y_AXIS_NAME
        : false,
      fontFamily:
        stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_FAMILY,
      fontSize:
        stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_SIZE,
      fontWeight:
        stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT,
      fontColor:
        stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_COLOR,
      isAxisLocationDefault: isAxisLocationDefault(
        stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_POSITION
      ),
      isNameLocationDefault: isNameLocationDefault(
        stackedBarChartComputedValues.STACKED_BAR_CHART_Y_AXIS_NAME_LOCATION
      ),
    },
    xAxis: {
      name: isAxisNameActive(paramsFromSearch, yAxisName),
      show: isChartHeightLimited(panelData.h)
        ? stackedBarChartComputedValues.STACKED_BAR_CHART_SHOW_X_AXIS_NAME
        : false,
      fontFamily:
        stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_NAME_FONT_FAMILY,
      fontSize:
        stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_NAME_FONT_SIZE,
      fontWeight:
        stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_NAME_FONT_WEIGHT,
      fontColor:
        stackedBarChartComputedValues.STACKED_BAR_CHART_X_AXIS_NAME_FONT_COLOR,
    },
  };
};
