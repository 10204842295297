export const CONNECTION_FORM = {
  description: "",
  dsn: "",
  port: "",
  host: "",
  jdbcURL: "",
  name: "",
  userName: "",
  password: "",
  type: "",
  createBy: "",
  isCustomURL: false,
};

export const DB_TYPE = {
  POSTGRESQL: "POSTGRESQL",
  CLICKHOUSE: "CLICKHOUSE",
  MYSQL: "MYSQL",
  MSSQL: "MSSQL",
  ORACLE: "ORACLE",
  SYBASE_ASE: "SYBASE_ASE",
  SYBASE_IQ: "SYBASE_IQ",
  ELASTICSEARCH: "ELASTICSEARCH",
  TRINO: "TRINO",
};

export const OTHER_CONNECTION_TYPES = {
    FTP: "FTP",
    SMB: "SMB",
};

export const SYBASE_ENUM = {
  SYBASE_ASE: "sybase:Tds"
};
