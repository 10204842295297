<template>
  <div class="vis-border-right">
    <DashboardDatasetFields
      ref="DashboardDatasetFields"
      :fields="fields"
      :selectedDataModel="selectedDataModel"
      :attributeFilterSearchParam="attributeFilterSearchParam"
      :isPageDashboard="isPageDashboard"
      :isPanelPage="isPanelPage"
      :showPopup="false"
      :isBeautifyLoading="isBeautifyLoading"
      :isOneDatasetExpandedActive="isOneDatasetExpandedActive"
      @saveField="(e) => $emit('saveField', e)"
      @openCreateCalculatedColumn="
        (p) => $emit('openCreateCalculatedColumn', p)
      "
      @openEditCalculatedColumn="(p) => $emit('openEditCalculatedColumn', p)"
      @openDeleteCalculatedColumn="
        (p) => $emit('openDeleteCalculatedColumn', p)
      "
      @addFunctionToCalculatedColumnEditor="
        (v) => $emit('addFunctionToCalculatedColumnEditor', v)
      "
      @getSelectedField="getSelectedField"
      @getFieldListById="$emit('getFieldListById')"
      @duplicateFieldById="(payload) => $emit('duplicateFieldById', payload)"
      @openColumnSecurity="(payload) => $emit('openColumnSecurity', payload)"
      @openColumnProperties="
        (payload) => $emit('openColumnProperties', payload)
      "
      @updateDatasetFieldsAlias="(payload) => $emit('updateDatasetFieldsAlias', payload)"
      @updateSortAndRenameSettings="(payload) => $emit('updateSortAndRenameSettings', payload)"
      @viewData="(payload) => $emit('viewData', payload)"
      @showOnDiagram="(payload) => $emit('showOnDiagram', payload)"
    />
  </div>
</template>

<script>
import DashboardDatasetFields from "../dashboard/DashboardDatasetFields.vue";

export default {
  components: {
    DashboardDatasetFields,
  },
  props: {
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
    },
    attributeFilterSearchParam: {
      type: Object,
    },
    isPageDashboard: {
      type: Boolean,
    },
    isPanelPage: {
      type: Boolean,
    },
    isBeautifyLoading: {
      type: Boolean,
      default: false,
    },
    isOneDatasetExpandedActive: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      tabKeys: {
        DatasetName: "DatasetName",
        FiltersAndOperators: "FiltersAndOperators",
      },
      activeTab: null,
      activeDatasetCollapseItem: [],
    };
  },
  methods: {
    getSelectedField(param) {
      this.$emit("getSelectedField", param);
    },
    updateActiveCollapseItem(datasetName) {
      this.$refs.DashboardDatasetFields.updateActiveCollapseItem(datasetName);
    },
    resetActiveCollapseItem() {
      this.$refs.DashboardDatasetFields.resetActiveCollapseItem();
    },
  },
};
</script>

<style scoped>
.truncate {
  overflow: hidden;
  width: 100%;
}
.dataset-field-action-container {
  margin-bottom: 15px;
}
</style>
