<template>
  <div class="vis-account-box">
    <div
      :class="
        isAvatarBoxAvailable
          ? 'vis-account-box-header'
          : 'vis-account-nonimage-header'
      "
    >
      <div
        v-if="isAvatarBoxAvailable"
        :class="['vis-account-image', 'vis-account-initials', accountBoxData.class]"
      > {{ usernameInitials }}</div>
      <h3 class="vis-account-username">{{ getterUserFullName }}</h3>
      <h3 class="vis-account-email">{{ userEmail }}</h3>
      <h3 class="vis-account-role">{{ accountBoxData.label }}</h3>
    </div>
    <ul class="vis-account-box-list">
      <li
        id="accountBoxAdministration"
        v-if="isAdministrateVisible"
        @click="settingsClicked"
      >
        <i aria-hidden="true" :class="customIcon.ShieldAccountOutline"></i>
        <span>{{ $t("generalPages.administration") }}</span>
      </li>
      <el-popover
        placement="right-start"
        trigger="click"
        popper-class="vis-dropdown-popper"
      >
        <ul class="vis-languages-dropdown">
          <li v-for="lang in userLanguages" :key="lang.id">
            <label @click="changeUserLanguage(lang)" :for="lang.value">
              {{ $t(`${lang.label}`) }}
              <i
                v-if="$i18n.locale == lang.value"
                class="mdi mdi-check"
                aria-hidden="true"
              ></i
            ></label>
            <input
              :id="lang.value"
              :value="lang.value"
              v-model="$i18n.locale"
              type="radio"
              @change="language = false"
            />
          </li>
        </ul>

        <li slot="reference">
          <i aria-hidden="true" :class="customIcon.Web"></i>
          <span>{{ $t("generalPages.languages") }}</span>
        </li>
      </el-popover>
      <el-popover
        placement="right-start"
        trigger="click"
        popper-class="vis-dropdown-popper"
      >
        <ul class="vis-languages-dropdown">
          <li v-for="m in modules" :key="m.value">
            <label @click="changeModule(m)" :for="m.value">
              {{ $t(`${m.label}`) }}
              <i
                v-if="selectedModule == m.value"
                class="mdi mdi-check"
                aria-hidden="true"
              ></i
            ></label>
            <input :id="m.value" :value="m.value" type="radio" />
          </li>
        </ul>

        <li slot="reference">
          <i aria-hidden="true" :class="customIcon.VectorArrangeBelow"></i>
          <span>{{ $t("generalPages.module") }}</span>
        </li>
      </el-popover>

      <div class="vis-account-box-border"></div>
      <li @click="openInfoPopup()">
        <i aria-hidden="true" :class="customIcon.InformationOutline"></i>
        <span>{{ $t("generalPages.aboutVisualize") }}</span>
      </li>
      <li>
        <a :href="helperUrl">
          <i aria-hidden="true" :class="customIcon.DocumentOutline"></i>
            <span class="user-manual-alignment">{{ $t("generalPages.User Manual") }}</span>
        </a>
      </li>
      <div class="vis-account-box-border"></div>
      <li @click="$emit('signOut')">
        <i aria-hidden="true" :class="customIcon.Logout"></i>
         <span class="signout-alignment">{{
          $t("generalPages.signOut")
        }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { routerEnums } from "../../commons/Enum";
import { COMPONENT_PRIVILEGES } from "../../util/authorization";
import componentAvailabilityCheck from "../../mixins/componentAvailabilityCheck";
import eventBus from "../../eventBus";
import { Modules } from "../../commons/modules";
import {
  GETTER as GETTER_SCENARIO,
} from "../../store/modules/Scenario/Main/types";
import { mapGetters } from 'vuex';
import { ROLES } from "../../util/authorization";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  mixins: [componentAvailabilityCheck],
  props: {
    userLanguages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isDarkMode: true,
      isNotification: false,
      language: false,
      dialogVisible: false,
      helperUrl: process.env.VUE_APP_HELPER_URL,
      selectedModule: null,
      customIcon: CustomIcon,
    };
  },
  computed: {
    ...mapGetters({
      isScenarioModuleAvailable: GETTER_SCENARIO.GET_IS_SCENARIO_MODULE_AVAILABLE,
      getterRoles:"Security/Get/Keycloak/Roles",
      userEmail: "Security/Get/Keycloak/Email",
      getterUserFullName:"Security/Get/Keycloak/UserFullName",
    }),
    accountBoxData() {
    const dataByRole = {
      [ROLES.ADMIN.key]: { class: "account-box-admin", label: "Administrator" },
      [ROLES.DATAMODEL_EDITOR.key]: { class: "account-box-editor", label: "Editor" },
      [ROLES.DASHBOARD_EDITOR.key]: { class: "account-box-editor", label: "Editor" },
      [ROLES.DASHBOARD_VIEWER.key]: { class: "account-box-viewer", label: "Viewer" },
      [ROLES.DATAMODEL_VIEWER.key]: { class: "account-box-viewer", label: "Viewer" },
    };

    for (const role in dataByRole) {
      if (this.getterRoles.includes(role)) {
        return dataByRole[role];
      }
    }

    return { class: "account-box-default"};
  },

    modules() {
      return [
        {
          label: "Visualize",
          value: Modules.VISUALIZE,
        },
        this.isScenarioModuleAvailable
          ? {
              label: "Scenario",
              value: Modules.SCENARIO,
            }
          : null,
      ].filter((m) => m);
    },
    username: {
      get() {
        return this.$store.getters["Security/Get/Keycloak/Username"];
      },
    },
    usernameInitials() {
      const names = this.getterUserFullName.split(" ");
      
      let firstLetter = "";

      names.forEach((element,index) => {
        if (index == 0 || index == names.length - 1) {
          firstLetter += element[0].toUpperCase();
        }
      });

      return firstLetter;
    },
    isAdministrateVisible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.TOP_BAR_SETTINGS_ADMINISTRATE
      );
    },
    isProfileAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION
      );
    },
    isAvatarBoxAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_AVATAR_SECTION
      );
    },
  },
  mounted() {
    this.selectedModule = localStorage.getItem("selectedModule");
  },
  methods: {
    changeModule(item) {
      localStorage.setItem("selectedModule", item.value);
      this.selectedModule = localStorage.getItem("selectedModule");

      eventBus.$emit("onSelectedModuleChanged");
    },
    changeUserLanguage(item) {
      this.$emit("updateUserPreferences", {
        queryParam: item.key,
        bodyParam: { value: item.value },
      });
    },
    settingsClicked() {
      this.$router.push({
        name: routerEnums.ADMINISTRATION,
      });
      this.closePopup();
    },
    closePopup() {
      this.$emit("closePopup", false);
    },
    openInfoPopup() {
      this.$emit("openInfoBox", true);
      this.closePopup();
    },
  },
};
</script>
<style scoped>
.vis-account-box {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 9999 !important;
  height: auto;
  width: 250px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 1px 2px #00000019;
}
.vis-account-box-border {
  position: relative;
  width: 88%;
  height: 1px;
  background-color: #e4e3e8;
  margin: 6px;
}
.vis-account-box-header {
  position: relative;
  width: 100%;
  height: 80px;
  background-color: #eaeef5;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.vis-account-box-header i {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}
.vis-account-image {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.vis-account-initials{
  text-align: center;
  align-content: center;
  font-size: 1.25rem;
  color: white;
}
.vis-account-box-title {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 10px;
  text-align: center;
}
.vis-account-box-title h3 {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vis-account-box-title h4 {
  font-size: 0.833rem;
  color: #404040;
  font-weight: normal;
}
.vis-account-box-list {
  position: relative;
  width: 100%;
  margin: 0;
  padding-bottom: 5px;
}
.vis-account-box-list li {
  position: relative;
  padding: 8px 24px 8px 15px;
  cursor: pointer;
  transition: ease all 0.3s;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  line-height: 1.3rem;
  font-weight: 500;
}
.vis-account-box-list > li:first-child {
  margin-top: 6px
}
.vis-account-box-list li i {
  font-size: 1.333rem;
  color: #494849;
  margin-right: 10px;
}

.vis-account-box-list li:hover {
  background: #f5f5f5;
}

.vis-languages-dropdown input {
  position: absolute;
  right: 0;
  opacity: 0;
}
.vis-languages-dropdown li label {
  padding: 10px 20px;
  cursor: pointer;
}
.vis-languages-dropdown li:hover {
  background-color: #f5f5f5;
}
.vis-account-nonimage-header {
  position: relative;
  width: 100%;
  height: 100px;
  background: #eaeef5;
}
/* TO DO revize edilecek */
.vis-account-username {
  position: relative;
  top: 1rem;
  min-height: 20px;
  font-size: 0.875rem;
  padding-left: 80px;
  font-weight: 500;
  max-width: 236px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vis-account-email {
  position: relative;
  top: 1rem;
  min-height: 18px;
  padding-left: 80px;
  font-size: 0.6rem;
  font-weight: 500;
  overflow: hidden; 
  max-width: 236px;
  text-overflow: ellipsis;
}
.vis-account-role {
  position: relative;
  top: 14px;
  min-height: 18px;
  padding-left: 80px;
  font-size: 0.6rem;
  font-weight: 500;
}
.account-box-admin {
  background-color: #403c3c;
}
.account-box-editor {
  background-color: #ff744a;
}
.account-box-viewer {
  background-color: #205cf4;
}
.account-box-default {
  background-color: white;
}
.user-manual-alignment {
  vertical-align: text-bottom;
}
.signout-alignment {
  align-self: flex-end;
}
</style>  