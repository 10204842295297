<template>
  <div>
    <div class="calculated-column-editor-header-upper">
      <span>{{ $t("generalPages.createCalculation") }}</span>
    </div>
    <div class="calculated-column-editor-header-lower">
      <div class="calculated-column-name-input-container">
        <label class="vis-font-bold">{{ $t("generalPages.labelName") }}</label>
        <input
          class="vis-input"
          :value="nameOfCalculatedColumn"
          @keyup="(e) => (nameOfCalculatedColumn = e.target.value)"
        />
      </div>

      <div class="calculated-column-editor-header-action-buttons">
        <i
          class="vis-cursor-pointer"
          :class="CustomIcon.Play"
          aria-hidden="true"
          @click="onValidate"
        />
        <i
          class="vis-cursor-pointer"
          :class="CustomIcon.SaveOutlined"
          aria-hidden="true"
          @click="onSave"
        />
        <i
          class="vis-cursor-pointer"
          :class="CustomIcon.Close"
          aria-hidden="true"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div>
      <el-tabs
        v-model="activeTab"
        tab-position="left"
        type="border-card"
        class="calculated-column-tabs"
        stretch
        @tab-click="onTabClick"
      >
        <el-tab-pane
          :label="$t('generalPages.Formula')"
          :name="tabNames.formula"
        >
          <CodeMirrorCalculatedColumn
            class="calculated-column-editor"
            :sql.sync="sql"
            :operators="hintsForFunctions"
            :columns="hints"
            :textToBeAddedToSql="processedTextToBeAddedToSql"
            @resetTextToBeAddedToSql="$emit('resetTextToBeAddedToSql')"
        /></el-tab-pane>
        <el-tab-pane
          :label="$t('filterComponents.filter')"
          :name="tabNames.filter"
        >
          <div class="data-items-and-filter-container-wrapper">
            <div class="data-items-and-filter-container">
              <label class="data-items-label">{{
                $t("panel.dataItems")
              }}</label>
              <DataModelCalculatedColumnFilterDataItems
                :items="filterItemsCloned"
                @updateItems="updateFilterItemsCloned"
                @clickItem="handleClickItem"
              />
            </div>
            <div class="filter-container">
              <!-- FIELD TYPE ATTRIBUTE -->
              <AttributeFilter
                v-if="filterTypeAttribute"
                :attributeFilterSearchParam="
                  selectedAttributeFilter.attributeFilterSearchParam
                "
                :selectedComponent="selectedAttributeFilter.popupTabName"
                :filterDetailData="selectedAttributeFilter.filterDetailData"
                :customFilterParams="selectedDatasetCustomFilterParams"
                :showFooterActions="false"
                @setTabValue="selectedAttributeFilter.popupTabName = $event"
                @saveFilterByAttribute="saveFilterByAttribute"
                @closeFilters="closeFilters"
              />
              <!-- FIELD TYPE ATTRIBUTE END -->

              <!-- FIELD TYPE MEASURE -->
              <MeasuresFilter
                v-if="filterTypeMeasure"
                :filterDetailData="selectedMeasureFilter.filterDetailData"
                :selectedComponent="selectedMeasureFilter.popupTabName"
                :showFooterActions="false"
                @saveFilterByMeasure="saveFilterByMeasure"
                @closeFilters="closeFilters"
              />
              <!-- FIELD TYPE MEASURE END -->

              <!-- FIELD TYPE DATE -->
              <DateFilter
                v-if="filterTypeDate"
                :filterDetailData="selectedDateFilter.filterDetailData"
                :selectedComponent="selectedDateFilter.popupTabName"
                :showFooterActions="false"
                @setTabValue="selectedDateFilter.popupTabName = $event"
                @saveFilterByDate="saveFilterByDate"
                @closeFilters="closeFilters"
              />
              <!-- FIELD TYPE DATE END -->
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../api/ApiConstants";
import { ServiceContainer } from "../../../api/ServiceContainer";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import { MUTATION as MUTATION_TEST_QUERY } from "../../../store/modules/Visualize/TestQuery/types";
import CodeMirrorCalculatedColumn from "../../helper/CodeMirrorCalculatedColumn";
import { getCalculatedColumnFunctions } from "../calculatedColumn/calculatedColumnTypes";
import { CustomIcon } from "../../../assets/js/custom-icons";
import DataModelCalculatedColumnFilterDataItems from "./DataModelCalculatedColumnFilterDataItems";
import AttributeFilter from "../../panel/filters/AttributeFilter.vue";
import MeasuresFilter from "../../panel/filters/MeasuresFilter.vue";
import DateFilter from "../../panel/filters/DateFilter.vue";
import { DatamodelContextDefaults } from "../../../commons/dataModelTypes";
import cloneDeep from "clone-deep";
import {
  dateOperator,
  filterPopupTabValue,
  objectsIncludesIn,
  operator,
} from "../../panel/filters/js/filters";
import { filterType } from "../../../commons/filterComponents";

export default {
  components: {
    CodeMirrorCalculatedColumn,
    DataModelCalculatedColumnFilterDataItems,
    AttributeFilter,
    MeasuresFilter,
    DateFilter,
  },
  props: {
    connectionType: {
      type: String,
      default: null,
    },
    textToBeAddedToSql: {
      type: String,
      default: "",
    },
    connection: {
      type: Object,
      default: null,
    },
    fields: {
      type: Object,
      default: () => {},
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    fieldId: {
      type: String,
      default: "",
    },
    selectedDatasetId: {
      type: String,
      default: "",
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
    levelItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabNames: {
        formula: "formula",
        filter: "filter",
      },
      activeTab: "",
      filterItemsCloned: [],
      levelItemsCloned: [],
      filterTypeAttribute: false,
      filterTypeMeasure: false,
      filterTypeDate: false,
      nameOfCalculatedColumn: "",
      sql: "",
      properties: [],
      CustomIcon: CustomIcon,
      processedTextToBeAddedToSql: "",
      connectionTypes: {
        ORACLE: "ORACLE",
        MSSQL: "MSSQL",
        CLICKHOUSE: "CLICKHOUSE",
        POSTGRESQL: "POSTGRESQL",
        SYBASE_ASE: "SYBASE_ASE",
        SYBASE_IQ: "SYBASE_IQ",
        TRINO: "TRINO",
      },
      selectedAttributeFilter: null,
      selectedMeasureFilter: null,
      selectedDateFilter: null,
      selectedDatasetCustomFilterParams: [],
    };
  },
  watch: {
    textToBeAddedToSql() {
      const functionToAddSql = this.hintsForFunctions?.find(
        (f) => `${f}()` === this.textToBeAddedToSql || f === this.textToBeAddedToSql
      );

      if (functionToAddSql) {
        this.processedTextToBeAddedToSql = this.textToBeAddedToSql;

        return;
      }

      const columnFound = this.datasetsAliasAndNames?.find(
        (d) =>
          `[${d.alias}]` === this.textToBeAddedToSql &&
          d.datasetId === this.selectedDatasetId
      );

      if (columnFound) {
        this.processedTextToBeAddedToSql = this.textToBeAddedToSql;

        return;
      }

      this.processedTextToBeAddedToSql = "";
    },
  },
  mounted() {
    this.activeTab = this.tabNames.formula;
    this.nameOfCalculatedColumn = this.name;
    setTimeout(() => {
      this.processedTextToBeAddedToSql = this.textToBeAddedToSql;
      this.filterItemsCloned = cloneDeep(this.filterItems);
      this.levelItemsCloned = cloneDeep(this.levelItems);
    }, 350);
    this.resetTestQuery();
    this.fetchProperties();
    this.setSelectedDatasetCustomFilterParams();
  },
  computed: {
    isOracleSelectable() {
      return this.connectionType === this.connectionTypes.ORACLE;
    },
    isMssqlSelectable() {
      return this.connectionType === this.connectionTypes.MSSQL;
    },
    isClickhouseSelectable() {
      return this.connectionType === this.connectionTypes.CLICKHOUSE;
    },
    isPostgresqlSelectable() {
      return this.connectionType === this.connectionTypes.POSTGRESQL;
    },
    isSybaseAseSelectable() {
      return this.connectionType === this.connectionTypes.SYBASE_ASE;
    },
    isSybaseIqSelectable() {
      return this.connectionType === this.connectionTypes.SYBASE_IQ;
    },
    isTrinoSelectable() {
      return this.connectionType === this.connectionTypes.TRINO;
    },
    calculatedColumnFunctions() {
      return getCalculatedColumnFunctions({
        isOracleSelectable: this.isOracleSelectable,
        isMssqlSelectable: this.isMssqlSelectable,
        isClickhouseSelectable: this.isClickhouseSelectable,
        isPostgresqlSelectable: this.isPostgresqlSelectable,
        isSybaseAseSelectable: this.isSybaseAseSelectable,
        isSybaseIqSelectable: this.isSybaseIqSelectable,
        isTrinoSelectable: this.isTrinoSelectable,
      });
    },
    datasetsAliasAndNames() {
      const datasets = [];

      Object.values(this.fields)?.forEach((f) =>
        f?.forEach?.((s) =>
          datasets.push({
            name: s.name,
            alias: s.alias,
            datasetId: s.datasetId,
          })
        )
      );

      return datasets;
    },
    hints() {
      return this.fields?.[this.selectedDatasetId]?.map?.((s) => s.alias);
    },
    hintsForFunctions() {
      let functionList = ["DISTINCT"]; // distinct function is common for all databases

      this.calculatedColumnFunctions.forEach((i) => {
        i.functions.forEach((j) => functionList.push(j));
      });

      return functionList;
    },
  },
  methods: {
    ...mapMutations({
      resetTestQuery: MUTATION_TEST_QUERY.RESET_TEST_QUERY,
    }),
    setSelectedDatasetCustomFilterParams() {
      const localStorageCustomFilterParams = localStorage.getItem(
        `customFilterParams.dataset-${this.selectedDatasetId}`
      );

      if (localStorageCustomFilterParams) {
        this.selectedDatasetCustomFilterParams = JSON.parse(
          localStorageCustomFilterParams
        );
      }
    },
    onTabClick(tab) {
      if (tab.paneName === this.tabNames.filter) {
        this.$emit("setIsCalculatedColumnFormulaTabOpen", false);
        this.$emit("filterTabOpened");
      } else if (tab.paneName === this.tabNames.formula) {
        this.$emit("setIsCalculatedColumnFormulaTabOpen", true);
      }
    },
    updateFilterItemsCloned(items) {
      this.filterItemsCloned = items;
      this.closeFilters();
    },
    saveFilterByAttribute(selectedComponent, selectionFilterList) {
      this.filterItemsCloned.forEach((element) => {
        if (
          this.selectedAttributeFilter.filterDetailData.fieldId ===
          element.fieldId
        ) {
          if (selectedComponent === filterPopupTabValue.RULE) {
            this.$set(element, "popupTabName", selectedComponent);
            this.$set(element, "operator", selectionFilterList.operator);

            let filterValue = [];

            if (
              Object.values(objectsIncludesIn).includes(
                selectionFilterList.operator
              )
            ) {
              filterValue = selectionFilterList.value;
            } else {
              filterValue.push(selectionFilterList.value);
            }

            this.$set(element, "value", filterValue);
            return;
          } else {
            this.$set(element, "popupTabName", selectedComponent);
            this.$set(element, "operator", operator.IN);
            this.$set(
              element,
              "value",
              selectionFilterList
                .filter((x) => x.checked)
                .map((item) => item.value)
            );
          }
        }
      });
    },
    saveFilterByMeasure({
      measureRuleFilterData,
      isCheckedLowestLevelRecords,
    }) {
      const measureRuleFilterDataParsed = {
        operator: measureRuleFilterData[0].operator,
        value: [
          measureRuleFilterData[0].value1,
          measureRuleFilterData[0].value2,
        ],
      };

      this.filterItemsCloned.forEach((element) => {
        if (
          this.selectedMeasureFilter.filterDetailData.fieldId ===
          element.fieldId
        ) {
          this.$set(element, "operator", measureRuleFilterDataParsed.operator);
          this.$set(element, "value", measureRuleFilterDataParsed.value);
          this.$set(element, "filterValues", measureRuleFilterData);
          this.$set(element, "isChecked", !!isCheckedLowestLevelRecords);
        }
      });
    },
    saveFilterByDate(filterFormData, selectedComponent) {
      this.filterItemsCloned.forEach((element) => {
        if (
          this.selectedDateFilter.filterDetailData.fieldId === element.fieldId
        ) {
          if (
            this.selectedDateFilter.popupTabName ===
            filterPopupTabValue.SELECTION
          ) {
            const filterValue = [];

            if (
              filterFormData.operator == operator.EQ ||
              filterFormData.operator == dateOperator.AFTER
            ) {
              filterValue.push(filterFormData.startDate);
            } else if (filterFormData.operator == dateOperator.BEFORE) {
              filterValue.push(filterFormData.endDate);
            } else {
              filterValue.push(filterFormData.startDate);
              filterValue.push(filterFormData.endDate);
            }

            this.$set(element, "popupTabName", selectedComponent);
            this.$set(element, "operator", filterFormData.operator);
            this.$set(element, "value", filterValue);
            this.$set(element, "filterValues", filterFormData);
          } else if (
            this.selectedDateFilter.popupTabName ===
            filterPopupTabValue.RELATIVE
          ) {
            const filterValue = [];

            filterValue.push(null); //Relative filtre tipinde value beklenmior fakat frontend tarafında kontrol sağlatmak için value null ekletiyoruz.

            this.$set(element, "popupTabName", selectedComponent);
            this.$set(element, "operator", operator.BETWEEN);
            this.$set(element, "value", filterValue);
            this.$set(element, "filterValues", filterFormData);
          }
        }
      });
    },
    closeFilters() {
      this.filterTypeAttribute = false;
      this.filterTypeMeasure = false;
      this.filterTypeDate = false;
      this.selectedAttributeFilter = null;
      this.selectedMeasureFilter = null;
      this.selectedDateFilter = null;
    },
    handleClickItem(item) {
      this.closeFilters();

      // wait 100 ms after closing filters
      setTimeout(() => {
        if (
          item.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
        ) {
          this.filterTypeAttribute = true;
          this.filterTypeMeasure = false;
          this.filterTypeDate = false;
          this.selectedAttributeFilter = {
            filterDetailData: item,
            popupTabName: item.popupTabName,
            attributeFilterSearchParam: {
              fieldList: [item],
              dataset: {
                id: item.datasetId,
                name: item.datasetName,
    // TODO
                filterProperty: { filterType: filterType.SECURITY },
              },
            },
          };
        } else if (
          item.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
        ) {
          this.filterTypeAttribute = false;
          this.filterTypeMeasure = true;
          this.filterTypeDate = false;
          this.selectedMeasureFilter = {
            filterDetailData: item,
            popupTabName: item.popupTabName,
          };
        } else if (
          item.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.DATE
        ) {
          this.filterTypeAttribute = false;
          this.filterTypeMeasure = false;
          this.filterTypeDate = true;

          // backend filterValues array uyumsuzluğu için kontrol
          if (
            item.filterValues &&
            Array.isArray(item.filterValues) &&
            item.filterValues.length > 0
          ) {
            this.selectedDateFilter = {
              filterDetailData: { ...item, filterValues: item.filterValues[0] },
              popupTabName: item.popupTabName,
            };
          } else {
            this.selectedDateFilter = {
              filterDetailData: item,
              popupTabName: item.popupTabName,
            };
          }
        }
      }, 100);
    },
    onDrop(event) {
      const itemData = JSON.parse(event.dataTransfer.getData("text/plain"));
      // Handle the dropped item data (e.g., emit an event or update local state)
      console.log("Received item:", itemData);
    },
    onValidate() {
      if (this.nameOfCalculatedColumn?.length < 1) {
        Notify(
          this.$t("datamodel.fillCalculatedColumnName"),
          notificationType.WARNING
        );

        return;
      }

      this.$emit("validate", {
        name: this.nameOfCalculatedColumn,
        userExpression: {
          formula: this.sql,
          filter: this.filterItemsCloned,
          level: [],
        },
        selectedDatasetId: this.selectedDatasetId,
      });
    },
    onSave() {
      if (this.nameOfCalculatedColumn?.length < 1) {
        Notify(
          this.$t("datamodel.fillCalculatedColumnName"),
          notificationType.WARNING
        );

        return;
      }

      if (this.isEditMode) {
        this.$emit("update", {
          name: this.nameOfCalculatedColumn,
          userExpression: {
            formula: this.sql,
            filter: this.filterItemsCloned,
            level: [],
          },
          selectedDatasetId: this.selectedDatasetId,
        });

        return;
      }

      this.$emit("save", {
        name: this.nameOfCalculatedColumn,
        userExpression: {
          formula: this.sql,
          filter: this.filterItemsCloned,
          level: [],
        },
        selectedDatasetId: this.selectedDatasetId,
      });
    },
    async fetchProperties() {
      const connectionId = this.connection?.connectionId;
      if (connectionId) {
        const propertyQueryResult = await ServiceContainer.getInstance()[
          serviceMethodParent.Connection
        ][serviceMethodSub.readPropertyQuery](connectionId);

        this.properties = propertyQueryResult?.data ?? [];
      }
    },
  },
};
</script>

<style scoped>
.calculated-column-name-input-container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.calculated-column-editor-header-input-container {
  padding: 10px;
}
.calculated-column-editor-header-upper {
  font-weight: 600;
  font-size: 12px;
  padding: 5px 0 6px 28px;
  display: flex;
  align-items: center;
  background: white;
  height: 39px;
}
.calculated-column-editor-header-lower {
  font-weight: 600;
  font-size: 12px;
  padding: 5px 0 5px 28px;
  display: flex;
  align-items: center;
  height: 40px;
  border-top: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid rgb(221, 221, 221);
}
.calculated-column-editor-header-action-buttons {
  margin-left: auto;
  display: flex;
  margin-right: 10px;
  align-items: center;
  column-gap: 10px;
}
.calculated-column-tabs ::v-deep .el-tabs__content {
  padding: 0;
}
.calculated-column-tabs ::v-deep .el-tabs__nav-prev {
  display: none !important;
}
.calculated-column-tabs ::v-deep .el-tabs__nav-next {
  display: none !important;
}
.calculated-column-tabs ::v-deep .el-tabs__nav-wrap {
  padding: 0 !important;
}
.calculated-column-tabs ::v-deep .el-tabs__header {
  margin: 0 !important;
  width: 160px !important;
  border: none;
}
.calculated-column-tabs ::v-deep .el-tabs__item.is-active {
  background: #e5ebf8 !important;
}
.calculated-column-tabs ::v-deep .el-tabs__item {
  color: black !important;
  background: white;
  text-align: left !important;
}
.calculated-column-tabs {
  border: none;
}
.data-items-label {
  font-size: 14px;
  font-weight: 500;
}
.data-items-and-filter-container {
  padding: 10px;
  min-width: 200px;
  border-left: 2px solid rgb(221, 221, 221);
  border-right: 2px solid rgb(221, 221, 221);
  overflow: auto;
}
.data-items-and-filter-container-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 300px;
}
.filter-container {
  width: 100%;
  padding: 15px 5px;
}
</style>
