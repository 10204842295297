import { getPanelDataPropertiesStyle } from "../panelDataPropertiesStyle";
import { datatablePropertiesEnum } from "../../commons/dashboardProperties";
import { chartTypes } from "../../commons/dashboardAndPanel";
import { DatamodelContextDefaults, DatasetFieldTypes } from "../../commons/dataModelTypes";
import { defaultNumberFormat } from "../../components/panel/measures/js/measures";
import { BOX_KEY_ENUM, getCommonsFieldProperty } from "../../commons/dashboardAndPanel";

export const updatePanelDataWithAggregationResult = (
  selectedPanelCloned,
  params
) => {
  if (
    getPanelDataPropertiesStyle(
      selectedPanelCloned,
      datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
    )
  ) {
    selectedPanelCloned.properties.vismetadata.vistype = chartTypes.OTHER;
    if (
      params.field.usageType == DatamodelContextDefaults.USAGE_TYPES.MEASURE
    ) {
      selectedPanelCloned.details.metrics.push(params.newField);
    } else {
      selectedPanelCloned.details.aggregation.push(params.newField);
    }
  } else {
    selectedPanelCloned.properties.vismetadata.vistype = chartTypes.TABLE;
    selectedPanelCloned.details.aggregation =
      selectedPanelCloned.details?.columns;
    selectedPanelCloned.details.metrics = [];
  }
  return selectedPanelCloned;
};

export const changeVisTypeForSearchParamByAggregateResult = (
  selectedPanelCloned
) => {
  if (
    getPanelDataPropertiesStyle(
      selectedPanelCloned,
      datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
    )
  ) {
    selectedPanelCloned.properties.vismetadata.vistype = chartTypes.OTHER;
  } else {
    selectedPanelCloned.properties.vismetadata.vistype = chartTypes.TABLE;
  }

  return selectedPanelCloned;
};

export const setSelectedPanelDetailAggregationAndMetrics = (
  selectedPanelCloned
) => {
  //IF AGGREGATE RESULT SWITCH ON
  if (
    getPanelDataPropertiesStyle(
      selectedPanelCloned,
      datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
    )
  ) {
    selectedPanelCloned.details.metrics =
      selectedPanelCloned?.details?.columns.filter(
        (x) => x.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
      );

    selectedPanelCloned.details.aggregation =
      selectedPanelCloned?.details?.columns.filter(
        (x) =>
          x.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE ||
          x.fieldUsageType == DatamodelContextDefaults.USAGE_TYPES.DATE
      );
  }
  //IF AGGREGATE RESULT SWITCH OFF
  else {
    selectedPanelCloned.details.aggregation =
      selectedPanelCloned?.details?.columns;
    selectedPanelCloned.details.metrics = [];
  }

  return selectedPanelCloned;
};
export const SLOT_FIELD_ID_PREFIX = {
  MEA: "_MEA",
  AGG: "_AGG",
  ORD: "_ORD",
};
export const setDefaultFormatValue = (fieldData, fieldUsageType) => {
  if (
    !fieldData?.format &&
    fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE && fieldData?.fieldType !== DatasetFieldTypes.TEXT
  ) {
    return defaultNumberFormat;
  } else return fieldData?.format || null;
};

export const setSlotFieldIdPrefix = ({ boxKey, fieldUsageType }) => {
  let slotFieldIdPrefix = "";
  if (boxKey === BOX_KEY_ENUM.COLUMN.KEY) {
    slotFieldIdPrefix =
      fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
        ? SLOT_FIELD_ID_PREFIX.MEA
        : SLOT_FIELD_ID_PREFIX.AGG;
  } else if (boxKey === BOX_KEY_ENUM.ORDERS.KEY) {
    slotFieldIdPrefix = SLOT_FIELD_ID_PREFIX.ORD;
  }
  return slotFieldIdPrefix;
};

export const getCommonsFieldColumnsAndSections = ({
  fieldData,
  boxKey,
  draggedAndDroppedFieldIndex,
}) => {
  const fieldUsageType = fieldData?.usageType || fieldData?.fieldUsageType;
  const format = setDefaultFormatValue(fieldData, fieldUsageType);
  const orderType =
    fieldData?.order && typeof fieldData.order === "string"
      ? fieldData.order
      : boxKey === BOX_KEY_ENUM.ORDERS.KEY
      ? "asc"
      : null;

  let slotFieldIdPrefix = setSlotFieldIdPrefix({ boxKey, fieldUsageType });
  
  return {
    ...getCommonsFieldProperty(fieldData),
    size: 5,
    orderType,
    fieldUsageType,
    type: fieldData?.defaultAggFunction || fieldData?.type,
    format,
    slotFieldId: `${slotFieldIdPrefix}${draggedAndDroppedFieldIndex}`,
    screenTip: fieldData?.screenTip,
    description: fieldData?.description,
    boxKey,
    rank: fieldData?.rank || null,
    selectorChartI: fieldData?.selectorChartI ?? null
  };
};
