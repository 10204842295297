import { getItemPercent, labelEnumsAlignTo, legendPositionEnums} from "../commons/dashboardProperties"
import { colorPalette, defaultColorPalette } from "../commons/chartOptions";
export const PIE_CHART_DEFAULT_CONFIGURATIONS = {
    PIE_CHART_SHOW_DOUGHNUT: false,
    PIE_CHART_SHOW_INSIGHT: true,
    PIE_CHART_SHOW_SQL: false,
    PIE_CHART_SHOW_VALUE_LABEL: true,
    PIE_CHART_SHOW_ONLY: getItemPercent(),
    PIE_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    PIE_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
    PIE_CHART_VALUE_LABEL_FONT_SIZE: 12,
    PIE_CHART_VALUE_LABEL_FONT_COLOR: "#000",
    PIE_CHART_SHOW_LEGEND: true,
    PIE_CHART_LEGEND_MAX_CHARACTER: 15,
    PIE_CHART_LEGEND_POSITION: legendPositionEnums.TOP_CENTER,
    PIE_CHART_LEGEND_VERTICAL_ALIGN: "top",
    PIE_CHART_LEGEND_FONT_FAMILY: "Poppins, sans-serif",
    PIE_CHART_LEGEND_FONT_WEIGHT: 500,
    PIE_CHART_LEGEND_FONT_SIZE: 12,
    PIE_CHART_LEGEND_FONT_COLOR: "#000",
    PIE_CHART_GENERAL_COLORS: colorPalette,
    PIE_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
    PIE_CHART_AUTO_REFRESH: 0,
    PIE_CHART_AUTO_REFRESH_CUSTOM: null,
    PIE_CHART_DYNAMIC_TITLE_VALUE: "",
    PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    PIE_CHART_GROUP_MULTIPLE_VALUES: true,
    PIE_CHART_VALUE_LABEL_ALIGNTO: labelEnumsAlignTo.OUTSIDE_ARROUND,
    PIE_CHART_ALLOW_DRILL_THROUGH: true,
    PIE_CHART_DRILL_THROUGH_DASHBOARD_LIST: [],
    PIE_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK: false
}