<template>
  <div
    v-if="isQueryParamsRight() && topbarQueryParams.topBar"
    class="vis-topbar"
    :class="{ 'vis-ma--none': $route.name == router.DASHBOARD }"
  >
    <div class="vis-topbar-row">
      <div class="vis-topbar-left">
        <div class="vis-flex--Ycenter" v-if="topbarQueryParams.appLogo">
          <router-link
            tabindex="-1"
            :to="{ name: router.HOME }"
            class="vis-flex--Ycenter"
          >
          <img
              v-if="!isHiddenTopbarLogo"
              :src="topbarLogo"
              alt="visualize-logo"
            />
            <span
              v-if="selectedModule !== Modules.SCENARIO"
              v-html="topbarLogoText"
              class="vis-topbar-logoName"
            >
            </span>
          </router-link>
        </div>
        <div
          v-if="isDatamodelNameShown"
          class="vis-topbar-dashboard-name-box"
          :class="{ 'vis-ma--none': !topbarQueryParams.appLogo }"
        >
          <div
            v-if="topbarQueryParams.appLogo"
            class="vis-topbar-vertical-line"
          ></div>
          <span
            v-if="selectedDatamodel.name"
            class="vis-topbar-dashboardName"
            :class="{ 'vis-ma--none': !topbarQueryParams.appLogo }"
          >
            {{ selectedDatamodel.name }}</span
          >
          <span v-else class="vis-topbar-dashboardName">{{
            $t("generalPages.newDataModel")
          }}</span>
        </div>
        <div
          v-if="isShowDashboardName && topbarQueryParams.dashboardName"
          class="vis-topbar-dashboard-name-box"
          :class="{ 'vis-ma--none': !topbarQueryParams.appLogo }"
        >
          <div
            v-if="topbarQueryParams.appLogo"
            class="vis-topbar-vertical-line"
          ></div>
          <span
            v-if="selectedDashboard.name"
            class="vis-topbar-dashboardName"
            :class="{ 'vis-ma--none': !topbarQueryParams.appLogo }"
          >
            {{ selectedDashboard.name }}</span
          >

          <span v-else class="vis-topbar-dashboardName">{{
            $t("generalPages.newDashboard")
          }}</span>
        </div>
        <div
          v-if="isIngestNameShown"
          class="vis-topbar-dashboard-name-box"
          :class="{ 'vis-ma--none': !topbarQueryParams.appLogo }"
        >
          <div
            v-if="topbarQueryParams.appLogo"
            class="vis-topbar-vertical-line"
          ></div>
          <span
            v-if="tempSelectedIngest.name"
            class="vis-topbar-dashboardName"
            :class="{ 'vis-ma--none': !topbarQueryParams.appLogo }"
          >
            {{ tempSelectedIngest.name }}</span
          >
          <span v-else class="vis-topbar-dashboardName">{{
            $t("generalPages.New Data Flow")
          }}</span>
        </div>
      </div>
      <div class="vis-topbar-right vis-account-box vis-display-flex">
        <el-popover
          :tabindex="-1"
          placement="bottom-end"
          width="1000"
          trigger="click"
          popper-class="vis-topbar-popup"
        >
          <TopBarNotification
            :notifications="notificationsPrepared"
            @onDownload="downloadExportMetadata"
            @onDelete="deleteNotificationConfirm"
          />
          <el-badge
            v-if="topbarQueryParams.notificationMenu"
            slot="reference"
            :max="10"
            :value="notificationCount"
            :hidden="!notificationCount"
            :class="{ 'vis-mr--2': topbarQueryParams.profileMenu }"
          >
            <i
              tabindex="-1"
              class="vis-icon icon-notification vis-cursor-pointer"
              aria-hidden="true"
            ></i>
          </el-badge>
        </el-popover>
        <div id="toggleAccountBox"
          v-if="topbarQueryParams.profileMenu"
          @click="toggleAccountBox = !toggleAccountBox"
          :class="['vis-cursor-pointer', 'vis-accountbox-circle', accountBoxClassByRole]"
        >
          {{ usernameInitials }}
        </div>
      </div>
      <ConfirmDialog
        v-if="deleteNotificationParams.status != exportStatus.FAILED"
        :title="$t('dialog.deleteNotificationTitle')"
        :message="$t('dialog.deleteNotificationMessage')"
        :dialogVisible="dialogVisible"
        @cancel="dialogVisible = $event"
        @confirm="deleteNotification(deleteNotificationParams.id)"
      />
      <AccountBox
        v-if="toggleAccountBox"
        :userLanguages="userLanguages"
        @signOut="logout"
        @closePopup="toggleAccountBox = $event"
        @openInfoBox="toggleInfoBox = $event"
        @updateUserPreferences="updateUserPreferences"
      />
      <Overlay
        :isShow="toggleAccountBox"
        :backgroundColor="'rgba(0, 0, 0, 0.0)'"
        @closePopup="toggleAccountBox = $event"
      />
    </div>
    <InfoBox
      :title="$t('infoBox.title')"
      :info="$t('infoBox.info') + applicationVersion"
      :image="infoLogo"
      :footerInfo="$t('infoBox.footerInfo', { year: new Date().getFullYear() })"
      :dialogVisible="toggleInfoBox"
      @cancel="toggleInfoBox = $event"
    />
  </div>
</template>

<script>
import { GETTER as GETTER_DASHBOARD } from "../../store/modules/Visualize/Dashboard/types";
import { GETTER as GETTER_DATAMODEL } from "../../store/modules/Visualize/DataModel/types";
import {
  GETTER as GETTER_EXPORT_METADATA,
  ACTION as ACTION_EXPORT_METADATA,
} from "../../store/modules/Visualize/ExportMetaData/types";
import {
  GETTER as GETTER_USER_PREFERENCES,
  ACTION as ACTION_USER_PREFERENCES,
} from "../../store/modules/Visualize/User/types";
import {
  GETTER as GETTER_GENERAL,
  MUTATION_GENERAL,
} from "../../store/modules/Visualize/General/types";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { routerEnums } from "../../commons/Enum";
import AccountBox from "./AccountBox.vue";
import Overlay from "../helper/Overlay.vue";
import TopBarNotification from "./TopBarNotification.vue";
import { formatISOToDateTime } from "../../util/moment";
import {
  getQueryStringParam,
  queryParamKeys,
} from "../../util/embeded/queryString";
import {
  EXPORT_STATUS,
  USER_LANGUAGES,
  USER_PREFERENCES_KEY,
} from "../../util/layout/layoutHelper";
import {
  checkDashboardName,
  isRouteDatamodel,
  isRouteIngest,
} from "../../commons/helper";
import ConfirmDialog from "../../components/helper/ConfirmDialog.vue";
import eventBus from "../../eventBus";
import { Modules } from "../../commons/modules";
import InfoBox from "../../components/helper/InfoBox.vue";
import { ServiceContainer } from "../../api/ServiceContainer";
import {
  TEMP_STORAGE_KEYS,
  GETTER as GETTER_TEMP_STORAGE,
} from "../../store/modules/temp-storage/types";
import DOMPurify from 'dompurify';
import { ROLES } from "../../util/authorization";
import { ACTION, GETTER } from "../../store/modules/Visualize/About/types";

export default {
  components: {
    AccountBox,
    Overlay,
    TopBarNotification,
    ConfirmDialog,
    InfoBox,
  },
  watch: {
    $route: {
      handler(to) {
        this.setDefaultDocumentTitle(to.name)
      },
      immediate: true,
    },
    routeName() {
      this.isQueryParamsRight();
    },
    selectedDashboard: {
      deep: true,
      handler() {
        if (this.$route.name === routerEnums.DASHBOARD) {
          document.title = `${this.documentTitle} - ${this.$t('generalPages.newDashboard')}`;
        } else if (this.editAndViewDashboardRoutes.includes(this.$route.name)) {
          document.title = `${this.documentTitle} - ${this.selectedDashboard.name}`;
        }
     }
    }
  },
  data() {
    return {
      Modules: Modules,
      selectedModule: null,
      router: routerEnums,
      toggleAccountBox: false,
      toggleInfoBox: false,
      userLanguages: USER_LANGUAGES,
      exportStatus: EXPORT_STATUS,
      topbarQueryParams: {
        [queryParamKeys.embeded]: null,
        [queryParamKeys.appLogo]: true,
        [queryParamKeys.dashboardName]: true,
        [queryParamKeys.dataModelName]: true,
        [queryParamKeys.notificationMenu]: true,
        [queryParamKeys.profileMenu]: true,
        [queryParamKeys.topBar]: true,
      },
      dialogVisible: false,
      beforeRouteLink: "",
      deleteNotificationParams: {
        id: null,
        status: "",
      },
      versionNumber: process.env.VUE_APP_VERSION,
      infoLogo: "info-logo",
      isRouteIngest: isRouteIngest,
      editAndViewDashboardRoutes: [routerEnums.DASHBOARD_EDIT, routerEnums.DASHBOARD_VIEW,  routerEnums.PANEL],
      visualizeTopBarLogo: process.env.VUE_APP_TOP_BAR_LOGO_NAME,
      documentTitle: process.env.VUE_APP_BROWSER_TAB_TITLE ?? "Visualyze",
    };
  },

  async mounted() {
    this.fetchVersion();

    this.fetchExportMetadataRecords();

    this.getExportMetaDataRecords();

    this.saveQueryParamAndSetTopBarQueryParam();

    this.getTopbarQueryParam();

    await this.fetchUserPreferences();

    this.setDefaultLocaleByUserLanguage();

    this.selectedModule = localStorage.getItem("selectedModule");

    eventBus.$on("onSelectedModuleChanged", () => {
      this.selectedModule = localStorage.getItem("selectedModule");

      ServiceContainer.resetInstance();
    });
  },
  computed: {
    ...mapGetters({
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
      selectedDashboard: GETTER_DASHBOARD.GET_DASHBOARD,
      selectedDatamodel: GETTER_DATAMODEL.GET_SELECTED_DATAMODEL,
      exportMetadataRecords: GETTER_EXPORT_METADATA.GET_EXPORT_METADATA_RECORDS,
      userPreferences: GETTER_USER_PREFERENCES.GET_USER_PREFERENCES,
      getterTopbarQueryParam: GETTER_GENERAL.GET_TOPBAR_QUERY_PARAM,
      applicationVersion: GETTER.GET_VERSION,
      getterUserFullName:"Security/Get/Keycloak/UserFullName",
      getterRoles:"Security/Get/Keycloak/Roles"
    }),
    isHiddenTopbarLogo() {
      const showLogo = process.env.VUE_APP_TOP_BAR_SHOW_LOGO;
      
      return showLogo === "NO";
    },
    topbarLogoText() {
      return process.env.VUE_APP_TOP_BAR_LOGO_TEXT
        ? DOMPurify.sanitize(process.env.VUE_APP_TOP_BAR_LOGO_TEXT)
        : DOMPurify.sanitize("Visualyze");
    },

    accountBoxClassByRole() {
      const classesByRole = {
        [ROLES.ADMIN.key]: "account-box-admin",
        [ROLES.DATAMODEL_EDITOR.key]: "account-box-editor",
        [ROLES.DASHBOARD_EDITOR.key]: "account-box-editor",
        [ROLES.DASHBOARD_VIEWER.key]: "account-box-viewer",
        [ROLES.DATAMODEL_VIEWER.key]: "account-box-viewer",
      };

      for (const role in classesByRole) {
        if (this.getterRoles.includes(role)) {
          return classesByRole[role];
        }
      }

      return "account-box-default";
    },
    usernameInitials() {
      const names = this.getterUserFullName.split(" ");
      let firstLetter = "";

      names.forEach((element,index) => {
        if (index == 0 || index == names.length - 1) {
          firstLetter += element[0].toUpperCase();
        }
      });

      return firstLetter;
    },

    topbarLogo() {
      let visualizeTopBarLogo = "topbar-logo.svg";
      
      if (
        this.visualizeTopBarLogo !== "" &&
        this.visualizeTopBarLogo !== undefined
      ) {
        visualizeTopBarLogo = this.visualizeTopBarLogo;
      }

      var scenarioImages = require.context(
        "../../assets/images/scenario/",
        false,
        /\.(svg|png)$/
      );
      var visualizeImages = require.context(
        "../../assets/images/",
        false,
        /\.(svg|png)$/
      );
      return this.selectedModule === Modules.SCENARIO
        ? scenarioImages("./scenario-logo.svg")
        : visualizeImages(`./${visualizeTopBarLogo}`);
    },
    notificationsPrepared() {
      return this.exportMetadataRecords?.map((emd) => ({
        ...emd,
        createdDate: formatISOToDateTime(emd.createdDate),
      }));
    },
    notificationCount() {
      return this.notificationsPrepared?.length || 0;
    },
    routeName() {
      return this.$route.name;
    },
    isShowDashboardName() {
      return (
        checkDashboardName(this.routeName) ||
        this.routeName == routerEnums.PANEL
      );
    },
    isDatamodelNameShown() {
      return isRouteDatamodel(this.routeName);
    },
    isIngestNameShown() {
      return isRouteIngest(this.routeName);
    },
    tempSelectedIngest() {
        return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_SELECTED_INGEST);
    }
  },
  methods: {
    ...mapActions({
      fetchExportMetadataRecords:
        ACTION_EXPORT_METADATA.FETCH_EXPORT_METADATA_RECORDS,
      fetchExportMetadataSharedUrl:
        ACTION_EXPORT_METADATA.FETCH_EXPORT_METADATA_SHARED_URL,
      fetchUserPreferences: ACTION_USER_PREFERENCES.FETCH_USER_PREFERENCES,
      updateUserPreferences: ACTION_USER_PREFERENCES.UPDATE_USER_PREFERENCES,
      deleteExportMetaData: ACTION_EXPORT_METADATA.DELETE_EXPORT_METADATA,
      fetchVersion: ACTION.FETCH_VERSION,
    }),
    ...mapMutations({
      setTopbarQuerParam: MUTATION_GENERAL.SET_TOPBAR_QUERY_PARAM,
    }),
    setDefaultDocumentTitle(routeName){
      if (![...this.editAndViewDashboardRoutes, routerEnums.DASHBOARD].includes(routeName)) {
          document.title = this.documentTitle;
        }
    },
    setDefaultLocaleByUserLanguage() {
      const userLanguage = this.userPreferences.find(
        (x) => x.key == USER_PREFERENCES_KEY.LANGUAGE
      );

      this.$i18n.locale = userLanguage?.value ?? window.navigator.language;
    },

    getExportMetaDataRecords() {
      setInterval(() => {
        this.exportMetadataRecords.forEach((element) => {
          if (
            element.status == EXPORT_STATUS.IN_PROGRESS ||
            element.status == EXPORT_STATUS.WAITING
          ) {
            this.fetchExportMetadataRecords();
          }
        });
      }, 5000);
    },

    isQueryParamsRight() {
      const filteredTopbarQueryParams = Object.keys(
        this.topbarQueryParams
      ).filter(
        (f) => f !== queryParamKeys.embeded && this.topbarQueryParams[f]
      );
      return this.routeName == routerEnums.HOME
        ? filteredTopbarQueryParams.filter(
            (f) =>
              f !== queryParamKeys.dashboardName || queryParamKeys.dataModelName
          ).length
        : filteredTopbarQueryParams.length;
    },

    saveQueryParamAndSetTopBarQueryParam() {
      this.$root.$on("setTopbarQuery", (param) => {
        this.topbarQueryParams = { ...this.topbarQueryParams, ...param };

        this.setTopbarQuerParam(param);
        //TODO detaylı test sonrası kaldırılacak
        // localStorage.setItem("topbarQueryParam", JSON.stringify(param));
      });
    },

    getTopbarQueryParam() {
      this.topbarQueryParams = getQueryStringParam(
        this.topbarQueryParams,
        this.getterTopbarQueryParam
      );
    },
    logout() {
      this.toggleAccountBox = false;
      
      setTimeout(() => {
        const redirectUri = this.checkQueryTicket();

        const logoutFunc =
          this.$store.getters["Security/Get/Keycloak/LogoutFunc"];

        logoutFunc(redirectUri);

        this.$store.dispatch("Security/Set/Keycloak/Reset");
      }, 500);
    },
    checkQueryTicket() {
      return { redirectUri: window.location.origin };
    },
    async downloadExportMetadata(row) {
      await this.fetchExportMetadataSharedUrl(row.id);

      await this.fetchExportMetadataRecords();
    },
    deleteNotificationConfirm(row) {
      this.deleteNotificationParams.id = row.id;
      if (row.status == EXPORT_STATUS.FAILED) {
        this.dialogVisible = false;
        this.deleteNotification(row.id);
      } else {
        this.dialogVisible = true;
      }
    },
    async deleteNotification(id) {
      this.dialogVisible = false;
      await this.deleteExportMetaData(id);
    },
  },
};
</script>
<style scoped>
.vis-topbar-left {
  position: relative;
  display: flex;
  align-items: center;
}
.vis-topbar-left img {
  height: 32px;
}
.vis-topbar-dashboard-name-box {
  display: flex;
  align-items: center;
  margin-left: 14px;
}
.vis-topbar-logoName {
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: 8px;
}
.vis-topbar-dashboardName {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 24px;
}
.vis-topbar-vertical-line {
  position: relative;
  width: 1px;
  height: 48px;
  background-color: #e4e3e8;
}
.vis-account-box {
  align-items: baseline;
}
.vis-accountbox-circle {
  border-radius:50%;
  background:rgba(0,0,0,0);
  color: white;
  width: 29px;
  height: 29px;
  text-align: center;
  align-content: center;
}
.account-box-admin {
  background-color: #403c3c;
}
.account-box-editor {
  background-color: #ff744a;
}
.account-box-viewer {
  background-color: #205cf4;
}
.account-box-default {
  background-color: white;
}
::v-deep .vis-dyis-topbar-logo {
  letter-spacing: 4px;
  font-weight: 700;
  font-size: 1.25rem;
}
</style>
