var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-admin-settings-pages-title vis-mb--1"},[_c('h2',[_vm._v(_vm._s(_vm.$t("admin.settings.Data Cache")))]),_c('p',{staticClass:"vis-font-medium"},[_vm._v(" "+_vm._s(_vm.$t( "admin.dataCache.Enter cache usage setting and expiration policy" ))+" ")])])]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Use Cache")))]),_c('el-switch',{attrs:{"value":_vm.selectedCacheData.IS_CACHE_CONNECTION_ACTIVE},on:{"change":(value) =>
            _vm.changeValue(
              value,
              _vm.configurationNameTypes.IS_CACHE_CONNECTION_ACTIVE
            )}})],1)]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Default Cache Duration")))]),_c('el-input',{attrs:{"type":"number","value":_vm.selectedCacheData.DEFAULT_CACHE_DURATION_MIN},on:{"input":(value) =>
            _vm.changeValue(
              value,
              _vm.configurationNameTypes.DEFAULT_CACHE_DURATION_MIN
            )}})],1)]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Max Cache Size")))]),_c('el-input',{attrs:{"type":"number","value":_vm.selectedCacheData.CACHE_MAX_MEMORY_SIZE},on:{"input":(value) =>
            _vm.changeValue(value, _vm.configurationNameTypes.CACHE_MAX_MEMORY_SIZE)}})],1)]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Cache Expiration Policy")))]),_c('el-select',{attrs:{"value":_vm.selectedCacheData.CACHE_MAX_MEMORY_POLICY,"placeholder":"Select"},on:{"change":(value) =>
            _vm.changeValue(value, _vm.configurationNameTypes.CACHE_MAX_MEMORY_POLICY)}},_vm._l((_vm.cachePolicyOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value,"disabled":item.disabled}})}),1)],1)]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('div',{staticClass:"vis-flex--spacebeetwen-Ycenter"},[_c('label',{attrs:{"for":"deleteCacheOnSchedule"}},[_vm._v(_vm._s(_vm.$t("admin.dataCache.Delete Cache On Schedule")))]),_c('el-switch',{attrs:{"id":"deleteCacheOnSchedule","value":_vm.selectedCacheData.IS_CACHE_CLEANUP_JOB_ENABLED},on:{"change":(value) =>
              _vm.changeValue(
                value,
                _vm.configurationNameTypes.IS_CACHE_CLEANUP_JOB_ENABLED
              )}})],1)])]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('el-input',{attrs:{"type":"text","placeholder":"Everyday at 00:00 AM","disabled":true}})],1)]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Usage Statistics")))]),_c('div',{staticClass:"vis-flex--spacebeetwen"},[_c('div',{staticClass:"vis-statistics-card"},[_c('p',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Current Memory Usage")))]),_c('p',[_vm._v(_vm._s(_vm.currentMemoryUsage))])]),_c('div',{staticClass:"vis-statistics-card"},[_c('p',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Peak Memory Usage")))]),_c('p',[_vm._v(_vm._s(_vm.peakMemoryUsage))])])])])]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12"},[_c('label',[_vm._v(_vm._s(_vm.$t("admin.dataCache.Delete All Cache")))]),_c('el-button',{attrs:{"loading":_vm.isDeleteCacheAllLoading,"size":"small"},on:{"click":function($event){return _vm.$emit('deleteCacheAll')}}},[_vm._v(_vm._s(_vm.$t("admin.dataCache.Delete Cache Immediately")))])],1)]),_c('div',{staticClass:"vis-row"},[_c('div',{staticClass:"vis-col vis-col-4 vis-col-xxl6 vis-col-l12 vis-text--right"},[_c('el-button',{attrs:{"loading":_vm.isSaveLoading,"size":"small","type":"primary","disabled":!_vm.willUpdateDataCacheKeys.length},on:{"click":_vm.saveCacheConfiguration}},[_vm._v(_vm._s(_vm.$t("generalPages.save")))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }