import { CustomIcon } from "../assets/js/custom-icons";

export const ObjectType = {
  DASHBOARD: "DASHBOARD",
  DATAMODEL: "DATAMODEL",
  FOLDER: "FOLDER",
  DATAFLOW: "DATAFLOW",
};

export const CardType = {
  DASHBOARD: "Dashboard",
  DATAMODEL: "Datamodel",
  FOLDER: "Folder",
  DATAFLOW: "Dataflow",
};

export const ScenarioCardType = {
  SCENARIO: "scenario",
  // ... other types
};

export const MainCardAction = {
  INFORMATION: "INFORMATION",
  STAR: "STAR",
  TOGGLE: "TOGGLE",
};

export const CardDropdownAction = {
  DELETE: "DELETE",
  EDIT: "EDIT",
  PROPERTIES: "PROPERTIES",
  MOVE: "MOVE",
  SHARE: "SHARE",
  DUPLICATE: "DUPLICATE",
  DEPLOY: "DEPLOY",
  UNDEPLOY: "UNDEPLOY",
  NEW_DASHBOARD: "newDashboard"
};

export const CardFieldsTypeEnum = {
  TYPEICON: "typeIcon",
  NAME: "name",
  DESCRIPTION: "description",
  DIRECTORY: "folderNames",
  CREATED_BY: "created_by",
  UPDATED_BY: "updated_by",
  TYPE: "type",
  CREATE_DATE: "createDate",
  UPDATED_DATE: "updatedDate",
  ACTIONS: "actions",
};
// TO DO bu kısım geçici olarak eklendi
export const CardSaveFieldsTypeEnums = {
  NAME: "name",
  CREATED_BY: "createdBy",
  UPDATED_BY: "updatedBy",
}

export const AuthorizationGeneralEnum = {
  EDIT: "EDIT",
  READ: "READ",
  RUN: "RUN",
  WRITE: "WRITE",
  OWNER: "OWNER",
  DENY: "DENY",
};

export const AuthorizationDashboardEnum = {
  EDIT: "EDIT",
  READ: "READ",
  WRITE: "WRITE",
  OWNER: "OWNER",
  DENY: "DENY",
};

export const AuthorizationDatamodelEnum = {
  RUN: "RUN",
  READ: "READ",
  WRITE: "WRITE",
  OWNER: "OWNER",
  DENY: "DENY",
};

export const AuthorizationFolderEnum = {
  READ: "READ",
  OWNER: "OWNER",
};

export const AuthorizationDataFlowEnum = {
  OWNER: "OWNER",
  DENY: "DENY",
  EDIT: "EDIT",
};

export const ScenarioStatusEnum = {
  DEPLOYED: "DEPLOYED",
  UNDEPLOY: "UNDEPLOY",
};

const mainActionDefault = (item) => {
  return [{
    localizationKey: "",
    iconClassName: `${CustomIcon.Information} vis-font-size-1`,
    actionKey: MainCardAction.INFORMATION,
    isTooltip: true,
  },
  {
    localizationKey: "",
    iconClassName: item?.favorite ? 
    `${CustomIcon.StarInFull} vis-font-size-1-25 vis-color-orange vis-cursor-pointer`:
    `${ CustomIcon.StarOutlined } vis-font-size-1-25 vis-cursor-pointer`,
    actionKey: MainCardAction.STAR,
  },
  {
    localizationKey: "",
    iconClassName: `${CustomIcon.VerticalThreeDot} vis-cursor-pointer`,
    actionKey: MainCardAction.TOGGLE,
  }]
};

export const mapDatamodelToHomePageProjectItem = (
  datamodels,
  { editHidden, authorizationToMove }
) => {
  return datamodels.map((item) => {
    const userCanEdit = item?.userCanEdit;

    return {
      iconClassName: `${CustomIcon.DatamodelIcon} vis-color-success`,
      mainActions: mainActionDefault(item),
      dropdowns: [
        userCanEdit
          ? {
              localizationKey: "generalPages.edit",
              iconClassName: CustomIcon.Pencil,
              liClassName: "no-border",
              actionKey: CardDropdownAction.EDIT,
              hidden: editHidden,
            }
          : null,
          {
            localizationKey: "generalPages.share",
            iconClassName: CustomIcon.Share,
            liClassName: "",
            actionKey: CardDropdownAction.SHARE,
          },
          {
            localizationKey: "generalPages.newDashboard",
            iconClassName: CustomIcon.Dashboard,
            liClassName: "no-border",
            actionKey: CardDropdownAction.NEW_DASHBOARD,
          },
          {
            localizationKey: "generalPages.properties",
            iconClassName: CustomIcon.Description,
            liClassName: "no-border",
            actionKey: CardDropdownAction.PROPERTIES,
          },
          {
            localizationKey: "generalPages.move",
            iconClassName: CustomIcon.MoveFolder,
            liClassName: "",
            actionKey: CardDropdownAction.MOVE,
            hidden: !authorizationToMove,
          },
          {
            localizationKey: "generalPages.duplicate",
            iconClassName: CustomIcon.Copy,
            liClassName: "no-border",
            actionKey: CardDropdownAction.DUPLICATE,
          },
          {
            localizationKey: "generalPages.delete",
            iconClassName: CustomIcon.Trash,
            liClassName: "",
            actionKey: CardDropdownAction.DELETE,
          },
      ].filter((x) => x),
      // TO DO buradaki alanlar item içerisinde mevcut, refactor edilip tek bir alandan çekilmeli
      type: CardType.DATAMODEL,
      name: item.name,
      description: item.description,
      createDate: item.createdDate,
      updatedDate: item.updatedDate,
      id: item.id,
      folderId: item.folderId,
      createBy: item.ownerName,
      item: {...item, type: CardType.DATAMODEL},
    };
  });
};

export const mapFolderToHomePageProjectItem = (
  folders,
  { editHidden, authorizationToMove }
) => {
  return folders.map((item) => {
    return {
      iconClassName: `${CustomIcon.Folder} vis-color-folder`,
      mainActions: mainActionDefault(item),
      dropdowns: [
        {
          localizationKey: "generalPages.properties",
          iconClassName: CustomIcon.Description,
          liClassName: "no-border",
          actionKey: CardDropdownAction.PROPERTIES,
          hidden: editHidden,
        },
        {
          localizationKey: "generalPages.share",
          iconClassName: CustomIcon.Share,
          liClassName: "",
          actionKey: CardDropdownAction.SHARE,
        },
        {
          localizationKey: "generalPages.move",
          iconClassName: CustomIcon.MoveFolder,
          liClassName: "no-border",
          actionKey: CardDropdownAction.MOVE,
          hidden: !authorizationToMove,
        },
        {
          localizationKey: "generalPages.delete",
          iconClassName: CustomIcon.Trash,
          liClassName: "",
          actionKey: CardDropdownAction.DELETE,
        },
      ].filter((x) => x),
      type: CardType.FOLDER,
      name: item.name,
      description: item.description,
      createDate: item.createdDate,
      updatedDate: item.updatedDate,
      id: item.id,
      parentFolderId: item.folderId,
      createBy: item.ownerName,
      item: {...item, type: CardType.FOLDER},
    };
  });
};

export const mapDashboardToHomePageProjectItem = (
  dashboards,
  { editHidden, authorizationToMove }
) => {
  return dashboards.map((item) => {
    const userCanEdit = item?.userCanEdit;
    
    return {
      iconClassName: `${CustomIcon.Dashboard} vis-color-primary`,
      mainActions: mainActionDefault(item),
      dropdowns: [
        userCanEdit
          ? {
              localizationKey: "generalPages.edit",
              iconClassName: CustomIcon.Pencil,
              liClassName: "no-border",
              actionKey: CardDropdownAction.EDIT,
              hidden: editHidden,
            }
          : null,
          {
            localizationKey: "generalPages.share",
            iconClassName: CustomIcon.Share,
            liClassName: "",
            actionKey: CardDropdownAction.SHARE,
          },
          {
            localizationKey: "generalPages.properties",
            iconClassName: CustomIcon.Description,
            liClassName: "no-border",
            actionKey: CardDropdownAction.PROPERTIES,
          },
          {
            localizationKey: "generalPages.move",
            iconClassName: CustomIcon.MoveFolder,
            liClassName: "",
            actionKey: CardDropdownAction.MOVE,
            hidden: !authorizationToMove,
          },
          {
            localizationKey: "generalPages.duplicate",
            iconClassName: CustomIcon.Copy,
            liClassName: "no-border",
            actionKey: CardDropdownAction.DUPLICATE,
          },
          {
            localizationKey: "generalPages.delete",
            iconClassName: CustomIcon.Trash,
            liClassName: "",
            actionKey: CardDropdownAction.DELETE,
          },
      ].filter((x) => x),
      type: CardType.DASHBOARD,
      name: item.name,
      description: item.description,
      createDate: item.createdDate,
      updatedDate: item.updatedDate,
      id: item.id,
      folderId: item.folderId,
      createBy: item.ownerName,
      item: {...item, type: CardType.DASHBOARD},
    };
  });
};

export const mapDataFlowToHomePageProjectItem = (
  dataFlows,
  { editHidden, authorizationToMove }
) => {
  return dataFlows.map((item) => {
    const userCanEdit = item?.userCanEdit;

    return {
      iconClassName: CustomIcon.Dataflow,
      mainActions: mainActionDefault(item),
      dropdowns: [
        userCanEdit
          ? {
              localizationKey: "generalPages.edit",
              iconClassName: CustomIcon.Pencil,
              liClassName: "no-border",
              actionKey: CardDropdownAction.EDIT,
              hidden: editHidden,
            }
          : null,
        {
          localizationKey: "generalPages.share",
          iconClassName: CustomIcon.Share,
          liClassName: "",
          actionKey: CardDropdownAction.SHARE,
        },
        {
          localizationKey: "generalPages.properties",
          iconClassName: CustomIcon.Description,
          liClassName: "no-border",
          actionKey: CardDropdownAction.PROPERTIES,
        },
        {
          localizationKey: "generalPages.move",
          iconClassName: CustomIcon.MoveFolder,
          liClassName: "",
          actionKey: CardDropdownAction.MOVE,
          hidden: !authorizationToMove,
        },
        {
          localizationKey: "generalPages.delete",
          iconClassName: CustomIcon.Trash,
          liClassName: "",
          actionKey: CardDropdownAction.DELETE,
        },
      ].filter((x) => x),
      type: CardType.DATAFLOW,
      name: item.name,
      description: item.description,
      createDate: item.createdDate,
      updatedDate: item.updatedDate,
      id: item.id,
      folderId: item.folderId,
      createBy: item.ownerName,
      item: {...item, type: CardType.DATAFLOW},
    };
  });
};

export const mapScenarioItemToHomePageProjectItem = (scenarioItem) => {
  return scenarioItem.map((item) => {
    const isScenarioAndUndeployed =
      item.type === ScenarioCardType.SCENARIO &&
      item.status !== ScenarioStatusEnum.DEPLOYED;

    return {
      isIconImage: true, //use image or svg
      iconClassName: "type-" + item.type,
      mainActions: [
        {
          localizationKey: "",
          iconClassName: `${CustomIcon.VerticalThreeDot} vis-cursor-pointer`,
          actionKey: MainCardAction.TOGGLE,
        },
      ].filter((x) => x),
      // TODO backend status ekleyince düzelt
      dropdowns: [
        isScenarioAndUndeployed
          ? {
              localizationKey: "Deploy",
              iconClassName: CustomIcon.DownloadUp,
              liClassName: "",
              actionKey: CardDropdownAction.DEPLOY,
            }
          : null,
        item.type === ScenarioCardType.SCENARIO &&
        item.status === ScenarioStatusEnum.DEPLOYED
          ? {
              localizationKey: "Undeploy",
              iconClassName: CustomIcon.DownloadUp,
              liClassName: "",
              actionKey: CardDropdownAction.UNDEPLOY,
            }
          : null,
        (item.type === ScenarioCardType.SCENARIO && isScenarioAndUndeployed) ||
        item.type !== ScenarioCardType.SCENARIO
          ? {
              localizationKey: "Delete",
              iconClassName: CustomIcon.Trash,
              liClassName: "",
              actionKey: CardDropdownAction.DELETE,
            }
          : null,
      ].filter((x) => x),
      type: item.type,
      name: item.name,
      id: item.id,
      folderId: null,
      createBy: item.createdBy,
      item: item,
    };
  });
};
