export const DATAMODEL_DEFAULT_PROPERTIES_CONFIGURATIONS = {
  allowAiActions: false,
  isTimeoutActive: false,
  timeoutInputValue: "",
  KEEP_ONE_DATASET_EXPANDED: true,
  DATAMODEL_USE_CACHE: false,
  DATAMODEL_CACHE_DURATION: 60,
  DATAMODEL_USE_DEFAULT_CACHE_DURATION: true,
  DATAMODEL_FORCE_CACHE_DURATION: false
};
