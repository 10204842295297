<template>
  <div>
    <el-dialog
      :title="$t('datamodel.selectTheFileYouWantToUpload')"
      :visible="visible"
      :before-close="handleClose"
      width="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
    >
      <div class="file-popup-body">
        <IngestChooseRemoteFile
          :connectionId="connectionId"
          :defaultFileName="defaultFileName"
          @done="$emit('done', $event)"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import IngestChooseRemoteFile from "./IngestChooseRemoteFile.vue";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    connectionId: {
      type: String,
    },
    defaultFileName: {
      type: String,
    },
  },
  components: {
    IngestChooseRemoteFile,
  },
  methods: {
    handleClose(done) {
      this.$emit("close", done);
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
  border-bottom: 1px solid #dadcdd;
  padding: 15px 20px;
}
::v-deep .el-dialog__body {
  padding: 0 !important;
}
.file-popup-body {
  height: 500px;
}
::v-deep .el-dialog__headerbtn {
  display: block !important;
}
::v-deep .el-dialog__close {
  font-size: 1.25rem;
}
</style>
