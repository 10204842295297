import { COMPONENT_PRIVILEGES } from "./src/util/authorization"

export const Environments = {
    DEV: "DEV",
    PROD: "PROD",
}

export const HiddenComponentsByEnv = {
    [Environments.DEV]: [],
    [Environments.PROD]: [
        COMPONENT_PRIVILEGES.EXAMPLE_COMPONENT_PRIVILEGE_HIDDEN_ON_PROD,
        COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION,
        COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION,
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION,
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION,
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION,
        COMPONENT_PRIVILEGES.HOME_PAGE_ACCOUNT_BOX_PROFILE_SECTION,
    ],
}