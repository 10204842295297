const visIcon = "vis-icon";

export const CustomIcon = {
    GroupWork: `${visIcon} icon-group_work`,
    Lan: `${visIcon} icon-lan`,
    TipsAndUpdated: `${visIcon} icon-tips_and_updates`,
    Title: `${visIcon} icon-title`,
    VisibilityOff: `${visIcon} icon-visibility_off`,
    VisibilityOffBlack: `${visIcon} icon-visibility_off_black`,
    VisibilityEye: `${visIcon} icon-visibility_eye`,
    Abc: `${visIcon} icon-Attribute`,
    Clock_Time: `${visIcon} icon-clock_time`,
    ContentCopy: `${visIcon} icon-content_copy`,
    Description: `${visIcon} icon-description`,
    FilterAltOutlined: `${visIcon} icon-filter_alt_outlined`,
    FilterAlt: `${visIcon} icon-filter_alt`,
    Filter: `${visIcon} icon-filter`,
    FormatPaint: `${visIcon} icon-format_paint`,
    Functions: `${visIcon} icon-functions`,
    SortBy: `${visIcon} icon-sort_by vis-icon-size`,
    SortThin: `${visIcon} icon-short-thin`,
    TrashCircle: `${visIcon} icon-trash-circle`,
    AddPlusCircle: `${visIcon} icon-add_plus_circle`,
    UpLineArrow: `${visIcon} icon-up-line-arrow`,
    Grid: `${visIcon} icon-icon-grid vis-icon-size`,
    Hamburger: `${visIcon} icon-hamburger`,
    OpenInFull: `${visIcon} icon-expand vis-icon-size`,
    CloseFullScreen: `${visIcon} icon-close_fullscreen`,
    ArrowRight: `${visIcon} icon-arrow_forward_icon`,
    Copy: `${visIcon} icon-copy`,
    Trash: `${visIcon} icon-trash-icon`,
    DownloadUp: `${visIcon} icon-download_up`,
    Plus: `${visIcon} icon-plus`,
    Close: `${visIcon} icon-close vis-icon-size`,
    Pencil: `${visIcon} icon-pencil`,
    Attribute: `${visIcon} icon-Attribute`,
    Measure: `${visIcon} icon-Measure`,
    Lock: `${visIcon} icon-lock`,
    ResetRefresh: `${visIcon} icon-reset`,
    Save: `${visIcon} icon-save`,
    UnpinFilterPane: `${visIcon} icon-unPinFilterPane`,
    PinFilterPane: `${visIcon} icon-pinFilterPane`,
    ClearAllFilters: `${visIcon} icon-clearAllFilters`,
    VerticalThreeDot: `${visIcon} icon-vertical_three_dot`,
    CalculateAlt: `${visIcon} icon-calculate_alt`,
    Dashboard: `${visIcon} icon-dashboard`,
    Dataflow: `${visIcon} icon-data_flow`,
    ExpandUp: `${visIcon} icon-expand_up`,
    ExpandDown: `${visIcon} icon-expand_down`,
    ExpandRight: `${visIcon} icon-expand_right`,
    Play: `${visIcon} icon-play vis-icon-size`,
    ExportXLS: `${visIcon} icon-export_xls`,
    ExportCSV: `${visIcon} icon-export_csv`,
    ExportPDF: `${visIcon} icon-export_pdf`,
    Document: `${visIcon} icon-document`,
    Download: `${visIcon} icon-download_icon`,
    DeleteCalculation: `${visIcon} icon-delete_calculation`,
    Refresh: `${visIcon} icon-refresh`,
    Search: `${visIcon} icon-search vis-icon-size`,
    Information: `${visIcon} icon-information`,
    StarInFull: `${visIcon} icon-star_fill`,
    StarOutlined: `${visIcon} icon-star`,
    AddFolder: `${visIcon} icon-create_new_folder vis-icon-size`,
    Folder: `${visIcon} icon-folder`,
    MoveFolder: `${visIcon} icon-move_folder`,
    JoinFull: `${visIcon} icon-join_full`,
    JoinInner: `${visIcon} icon-join_inner`,
    JoinLeft: `${visIcon} icon-join_left`,
    JoinRight: `${visIcon} icon-join_right`,
    Database: `${visIcon} icon-database`,
    PencilOutline: `${visIcon} icon-pencil_outline vis-icon-size`,
    TrashCanOutline: `mdi mdi-trash-can-outline`,
    Join: `${visIcon} icon-join`,
    AddBox: `${visIcon} icon-add_box`,
    ZoomIn: `${visIcon} icon-zoom_in`,
    ZoomOut: `${visIcon} icon-zoom_out`,
    CenterFocusStrong: `${visIcon} icon-center_focus_strong`,
    CenterFocusWeak: `${visIcon} icon-center_focus_weak`,
    Undo: `${visIcon} icon-undo`,
    Redo: `${visIcon} icon-redo`,
    VisibilityEyeMaterial: `${visIcon} icon-visibility_eye_material`,
    Draft: `${visIcon} icon-draft`,
    DraftOutline: `${visIcon} icon-draft_outline`,
    Delete: `${visIcon} icon-delete_material`,
    Circle: `mdi mdi-circle`,
    ShieldAccountOutline: `mdi mdi-shield-account-variant-outline`,
    Web: `mdi mdi-web`,
    VectorArrangeBelow: `mdi mdi-vector-arrange-below`,
    InformationOutline: `mdi mdi-information-outline`,
    DocumentOutline: `mdi mdi-file-document-outline`,
    Logout: `mdi mdi-logout`,
    CircleOutline: `mdi mdi-circle-outline`,
    ArrowDown: `mdi mdi-arrow-down`,
    ArrowUp: `mdi mdi-arrow-up`,
    CropSquare: `mdi mdi-crop-square`,
    Square: `mdi mdi-square`,
    Minus: `${visIcon} icon-minus_icon`,
    Preview: `${visIcon} icon-preview`,
    Settings: `${visIcon} icon-settings1`,
    SwapHoriz: `${visIcon} icon-swap_horiz`,
    Storage: `${visIcon} icon-storage`,
    Group: `${visIcon} icon-group`,
    DatabaseMaterial: `${visIcon} icon-database_material`,
    Share: `${visIcon} icon-share_line`,
    Dataset: `${visIcon} icon-dataset`,
    Autorenew: `${visIcon} icon-autorenew`,
    Checked: `mdi mdi-check`,
    AdminPanelSettings: `${visIcon} icon-admin_panel_settings`,
    DatamodelIcon: `${visIcon} icon-account_tree`,
    LockOpen:  `${visIcon} icon-lock_open_fill`,
    LockClose: `${visIcon} icon-lock_close_fill`,
    CondTriangleUp: `${visIcon} icon-cond_triangle_up`,
    CondArrowDecreasing: `${visIcon} icon-cond_arrow_decreasing`,
    CondArrowDown: `${visIcon} icon-cond_arrow_down`,
    CondArrowIncreasing: `${visIcon} icon-cond_arrow_increasing`,
    CondArrowUp: `${visIcon} icon-cond_arrow_up`,
    CondBars1: `${visIcon} icon-cond_bars_1`,
    CondBars2: `${visIcon} icon-cond_bars_2`,
    CondBars3: `${visIcon} icon-cond_bars_3`,
    CondBars4: `${visIcon} icon-cond_bars_4`,
    CondCancel: `${visIcon} icon-cond_cancel`,
    CondCancelCircled: `${visIcon} icon-cond_cancel_circled`,
    CondCheck: `${visIcon} icon-cond_check`,
    CondCheckCircled: `${visIcon} icon-cond_check_circled`,
    CondChevronDown: `${visIcon} icon-cond_chevron_down`,
    CondChevronLeft: `${visIcon} icon-cond_chevron_left`,
    CondChevronUp: `${visIcon} icon-cond_chevron_up`,
    CondCircle: `${visIcon} icon-cond_circle`,
    CondDiamond: `${visIcon} icon-cond_diamond`,
    CondExclamation: `${visIcon} icon-cond_exclamation`,
    CondExclamationCicled: `${visIcon} icon-cond_exclamation_cicled`,
    CondFlag: `${visIcon} icon-cond_flag`,
    CondGrid1: `${visIcon} icon-cond_grid_1`,
    CondGrid2: `${visIcon} icon-cond_grid_2`,
    CondGrid3: `${visIcon} icon-cond_grid_3`,
    CondGrid4: `${visIcon} icon-cond_grid_4`,
    CondPie1: `${visIcon} icon-cond_pie_1`,
    CondPie2: `${visIcon} icon-cond_pie_2`,
    CondPie3: `${visIcon} icon-cond_pie_3`,
    CondSquare: `${visIcon} icon-cond_square`,
    CondTriangle: `${visIcon} icon-cond_triangle`,
    CondTriangleDown: `${visIcon} icon-cond_triangle_down`,
    CondCountOne: `${visIcon} icon-cond_counter_1`,
    CondCountTwo: `${visIcon} icon-cond_counter_2`,
    CondCountThree: `${visIcon} icon-cond_counter_3`,
    CondCountFour: `${visIcon} icon-cond_counter_4`,
    CondCountFive: `${visIcon} icon-cond_counter_5`,
    CondCountSix: `${visIcon} icon-cond_counter_6`,
    CondCountSeven: `${visIcon} icon-cond_counter_7`,
    CondCountEight: `${visIcon} icon-cond_counter_8`,
    CondCountNine: `${visIcon} icon-cond_counter_9`,
    Link: "mdi mdi-link",
    UnfoldMoreVertical: "mdi mdi-unfold-more-vertical",
    Cut: `${visIcon} icon-content_cut`,
    ListView: `${visIcon} icon-list_view vis-icon-size`,
    Selections: `${visIcon} icon-selections vis-icon-size`,
    FilterGridLayout: `${visIcon} icon-filter_gridlayout vis-icon-size`,
    SaveOutlined: `${visIcon} icon-save-outline vis-icon-size`,
    Property: `${visIcon} icon-property vis-icon-size`,
    Lamp: `${visIcon} icon-lamp vis-icon-size`,
    ChannelTarget: `${visIcon} icon-channel_target vis-icon-size`,
    FolderData: `${visIcon} icon-folder_data`,
    SharedFolder: `${visIcon} icon-shared_folder`,
    TableLarge: "mdi mdi-table-large",
    FolderOpenOutline: "mdi mdi-folder-open-outline",
    FolderOutline: "mdi mdi-folder-outline",
    TableHeadersEye: "mdi mdi-table-headers-eye",
    EmailSendOutline: `${visIcon} icon-send_email`,
    FileImageOutline: "mdi mdi-file-image-outline",
    FormatPaintFill : `${visIcon} icon-format_paint_fill`,
    FormatPaintBoldOutlined: `${visIcon} icon-format_paint_bold`,
    LockOpenOutlined: `${visIcon} icon-lock_open`,
    LockCloseOutlined: `${visIcon} icon-lock_close`,
    DynamicFormWght300: `${visIcon} icon-dynamic_form_wght300`,
    DynamicFormWght400: `${visIcon} icon-dynamic_form_wght400`,
    UndoBold: `${visIcon} icon-undo_bold`,
    RedoBold: `${visIcon} icon-redo_bold`,
};
