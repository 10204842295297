<template>
  <div class="migrate-content-container">
    <el-dialog
      :title="$t('generalPages.Select filter criteria')"
      :visible.sync="showFilterPopup"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Object Name")
        }}</span>
        <el-select
          style="padding-right: 25px"
          v-model="filterValue.objectNameDropdown"
          :placeholder="$t('generalPages.Select')"
          size="mini"
        >
          <el-option
            v-for="item in filterOptions.objectNameDropdown"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          size="mini"
          :placeholder="$t('generalPages.Object Name')"
          v-model="filterValue.objectName"
        >
        </el-input>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Object Type")
        }}</span>
        <el-select
          v-model="filterValue.objectType"
          :placeholder="$t('generalPages.Select')"
          size="mini"
        >
          <el-option
            v-for="item in filterOptions.objectType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{ $t("generalPages.Owner") }}</span>
        <el-select
          v-model="filterValue.owner"
          :placeholder="$t('generalPages.Select')"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in filterOptions.owner"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Created At")
        }}</span>
        <el-date-picker
          v-model="filterValue.createdAt"
          type="daterange"
          align="right"
          :start-placeholder="$t('generalPages.Start Date')"
          :end-placeholder="$t('generalPages.End Date')"
          :default-value="new Date()"
        >
        </el-date-picker>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{
          $t("generalPages.Updated At")
        }}</span>
        <el-date-picker
          v-model="filterValue.updatedAt"
          type="daterange"
          align="right"
          :start-placeholder="$t('generalPages.Start Date')"
          :end-placeholder="$t('generalPages.End Date')"
          :default-value="new Date()"
        >
        </el-date-picker>
      </div>
      <div class="filter-row">
        <span class="filter-row-title">{{ $t("generalPages.Object Id") }}</span>
        <el-input
          size="mini"
          :placeholder="$t('generalPages.Object Id')"
          v-model="filterValue.objectId"
        >
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeFilterPopup">{{
          $t("generalPages.Close")
        }}</el-button>
        <el-button type="primary" @click="applyFilters">{{
          $t("generalPages.Apply")
        }}</el-button>
      </span>
    </el-dialog>

    <div class="migrate-content-header">
      <span class="migrate-content-header-title">{{
        $t("admin.menuItem.labelMigrateContent")
      }}</span>

      <div class="migrate-content-header-filters">
        <el-input
          style="width: 250px"
          size="mini"
          placeholder="Search..."
          v-model="quickSearchValue"
        >
        </el-input>
        <i
          style="cursor: pointer"
          @click="showFilterPopup = true"
          aria-hidden="true"
          :class="CustomIcon.FilterAltOutlined"
        />
      </div>
    </div>
    <div class="migrate-content-selection-table">
      <div class="vis-flex--spacebeetwen-Ycenter">
        <span class="vis-font-medium"
          >{{ $t("admin.migrate.Select objects to migrate") }}
        </span>
      </div>
      <div class="vis-vgt-card">
        <vue-good-table
          ref="migrate-selection-table"
          :columns="columns"
          :rows="filteredRows"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: false,
            disableSelectInfo: true,
          }"
          :pagination-options="paginationOptions"
          max-height="560px"
        >
          <template slot="table-column" slot-scope="props">
            <span>
              {{ $t(`${props.column.label}`) }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == columnKeys.TYPEICON">
              <i
                class="vis-icon icon-dashboard vis-color-primary"
                aria-hidden="true"
              ></i>
              <!-- TODO Update BE -->
            </span>
            <span
              v-else-if="props.column.field == columnKeys.NAME"
              class="vis-cursor-pointer"
            >
              {{ props.row.name }}
            </span>
            <span v-else-if="props.column.field == columnKeys.TYPE">
              <span>{{ $t(`generalPages.dashboard`) }}</span>
              <!-- TODO Update BE -->
            </span>
            <span v-else-if="props.column.field == columnKeys.CREATE_DATE">
              <span>{{ formatISOToDateTime(props.row.created_date) }}</span>
            </span>
            <span v-else-if="props.column.field == columnKeys.UPDATED_DATE">
              <span>{{ formatISOToDateTime(props.row.updated_date) }}</span>
            </span>
            <span v-else-if="props.column.field == columnKeys.FOLDER_NAMES">
              <span>{{ getDirectoryText(props.row.folderNames) }}</span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>

      <el-button
        class="vis-ml--auto"
        style="width: 100px"
        type="primary"
        size="small"
        @click="migrateSelections"
      >
        {{ $t("generalPages.Continue") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { formatISOToDateTime } from "../../util/moment";
import { CustomIcon } from "../../assets/js/custom-icons";
import cloneDeep from "clone-deep";

// Store
import { mapActions, mapState, mapMutations } from "vuex";
import {
  ACTION as ACTION_MIGRATE_CONTENT,
  STATE as STATE_MIGRATE_CONTENT,
  MUTATION as MUTATION_MIGRATE_CONTENT,
} from "../../store/modules/Transfer/MigrateContent/types";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { Notification } from "element-ui";
import moment from "moment";

function filterByValue(array, string) {
  return array?.filter((o) => {
    return Object.keys(o).some((k) => {
      return String(o[k]).toLowerCase().includes(string.toLowerCase());
    });
  });
}

function removeusingSet(arr) {
  let outputArray = Array.from(new Set(arr));
  return outputArray;
}

export default {
  components: { VueGoodTable },
  data() {
    return {
      filterValue: {
        objectNameDropdown: "Contains",
        objectName: "",
        objectType: "Dashboard",
        owner: "",
        createdAt: "",
        updatedAt: "",
        objectId: "",
      },

      filterValueApplied: {
        objectNameDropdown: "Contains",
        objectName: "",
        objectType: "Dashboard",
        owner: "",
        createdAt: "",
        updatedAt: "",
        objectId: "",
      },

      showFilterPopup: false,
      quickSearchValue: "",
      columnKeys: {
        TYPEICON: "typeIcon",
        NAME: "name",
        CREATED_BY: "created_by",
        TYPE: "type",
        CREATE_DATE: "created_date",
        UPDATED_DATE: "updated_date",
        ID: "id",
        FOLDER_NAMES: "folderNames",
      },
      CustomIcon: CustomIcon,
      paginationOptions: {
        enabled: true,
        mode: "pages",
        perPageDropdownEnabled: false,
        perPage: 10,
        setCurrentPage: 1,
        position: "bottom",
        jumpFirstOrLast: false,
        nextLabel: "",
        prevLabel: "",
        ofLabel: "/",
        pageLabel: this.$t("generalPages.Page"),
      },
    };
  },
  watch: {
    quickSearchValue: {
      handler() {
        this.resetCurrentPage();
      },
    },
    filterValueApplied: {
      deep: true,
      handler() {
        this.resetCurrentPage();
      },
    },
  },
  mounted() {
    this.setDashboardsWithFolders([]);
    this.fetchDashboardsWithFolders({
      loadingComponent: LoadingComponent.MigrateContent,
    });
  },
  computed: {
    ...mapState({
      dashboardsWithFolders: (state) =>
        state.visMigrateContent[STATE_MIGRATE_CONTENT.DASHBOARDS_WITH_FOLDERS],
    }),

    columns() {
      return [
        {
          label: "",
          field: this.columnKeys.TYPEICON,
          width: "30px",
          sortable: false,
        },
        {
          label: "generalPages.labelName",
          field: this.columnKeys.NAME,
        },
        {
          label: "generalPages.labelType",
          field: this.columnKeys.TYPE,
        },
        {
          label: "generalPages.labelOwner",
          field: this.columnKeys.CREATED_BY,
        },
        {
          label: "generalPages.labelCreatedAt",
          field: this.columnKeys.CREATE_DATE,
        },
        {
          label: "generalPages.labelUpdatedAt",
          field: this.columnKeys.UPDATED_DATE,
        },
        {
          label: "generalPages.Object Id",
          field: this.columnKeys.ID,
        },
        {
          label: "generalPages.Directory",
          field: this.columnKeys.FOLDER_NAMES,
        },
      ];
    },

    filteredRows() {
      let filtered = filterByValue(
        this.dashboardsWithFolders,
        this.quickSearchValue
      );

      if (this.filterValueApplied.owner)
        filtered = filtered.filter(
          (f) => f.created_by === this.filterValueApplied.owner
        );

      if (this.filterValueApplied.objectName)
        filtered = filtered.filter((f) =>
          f.name.includes(this.filterValueApplied.objectName)
        );

      if (this.filterValueApplied.objectId)
        filtered = filtered.filter((f) =>
          f.id.includes(this.filterValueApplied.objectId)
        );

      if (this.filterValueApplied.createdAt?.length === 2) {
        const momentCreatedAtStart = moment(
          this.filterValueApplied.createdAt[0]
        );
        const momentCreatedAtEnd = moment(this.filterValueApplied.createdAt[1]);

        filtered = filtered.filter((f) => {
          return moment(f.created_date).isBetween(
            momentCreatedAtStart,
            momentCreatedAtEnd
          );
        });
      }

      if (this.filterValueApplied.updatedAt?.length === 2) {
        const momentUpdatedAtStart = moment(
          this.filterValueApplied.updatedAt[0]
        );
        const momentUpdatedAtEnd = moment(this.filterValueApplied.updatedAt[1]);

        filtered = filtered.filter((f) => {
          return moment(f.updated_date).isBetween(
            momentUpdatedAtStart,
            momentUpdatedAtEnd
          );
        });
      }

      return filtered;
    },

    owners() {
      return this.dashboardsWithFolders.map((d) => d.created_by);
    },

    filterOptions() {
      const distinctOwners = removeusingSet(this.owners);

      return {
        objectNameDropdown: [
          {
            value: "Contains",
            label: this.$t("generalPages.Contains"),
          },
        ],
        objectType: [
          {
            value: "Dashboard",
            label: this.$t("generalPages.Dashboard"),
          },
        ],
        owner: distinctOwners.map((o) => ({
          value: o,
          label: o,
        })),
      };
    },
  },
  methods: {
    ...mapActions({
      fetchDashboardsWithFolders:
        ACTION_MIGRATE_CONTENT.FETCH_DASHBOARDS_WITH_FOLDERS,
    }),
    ...mapMutations({
      setDashboardsWithFolders:
        MUTATION_MIGRATE_CONTENT.SET_DASHBOARDS_WITH_FOLDERS,
    }),
    resetCurrentPage() {
      this.$refs["migrate-selection-table"].changePage(1);
    },
    applyFilters() {
      this.filterValueApplied = cloneDeep(this.filterValue);
      this.showFilterPopup = false;
    },
    closeFilterPopup() {
      this.filterValue = cloneDeep(this.filterValueApplied);
      this.showFilterPopup = false;
    },
    migrateSelections() {
      const selectedRows = this.$refs["migrate-selection-table"].selectedRows;

      if (selectedRows?.length !== 1) {
        // throw notification error
        const resp = {
          message: this.$t("admin.migrate.You should select one object"),
          data: "",
          duration: 3000,
          customClass: "center vis-notification",
          title: this.$t("notifyTitle.warning"),
          type: "warning",
        };

        Notification(resp);

        return;
      }

      this.$emit("onContinueClicked", selectedRows[0]);
    },
    formatISOToDateTime(val) {
      return formatISOToDateTime(val);
    },
    getDirectoryText(folders) {
      if (folders?.length) {
        return cloneDeep(folders).reverse().join(" > ");
      }

      return "";
    },
  },
};
</script>

<style scoped>
.migrate-content-container {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.migrate-content-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding: 20px 30px 0;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.migrate-content-header-filters {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.migrate-content-header-add-button {
  margin-left: auto;
}
.migrate-content-selection-table {
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-y: hidden;
}

::v-deep table.vgt-table thead tr {
  background-color: #fff !important;
}
::v-deep .vgt-table th.vgt-checkbox-col {
  background: #fff !important;
}
::v-deep table.vgt-table tbody tr:hover {
  background-color: #fff !important;
}
::v-deep .vgt-wrap__footer {
  background: white !important;
}
::v-deep .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid var(--primary-lighteen-1) !important;
}
::v-deep .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid var(--primary-lighteen-1) !important;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid var(--primary-lighteen-1);
  padding: 20px 20px;
}
.filter-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.filter-row-title {
  min-width: 150px;
}
.migrate-content-header-title {
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
