import { Orientation, RADAR_CHART_PROPERTIES_ENUM, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { aggregationDateFormatController, getLeftValueWithLegendOrientPosition, getSelectedColorPaletteByProperty, setShowOnlyValue, truncateText } from "../chartOptionsHelper";
import { getAlignPositionByLegend, getOrientPositionByLegend } from "../chartViewHelper";
// import { getSeriesLabelPaddingByChartHeight } from "../chartViewHelper";

export const getRadarChartOptions = (
  radarChartComputedValues,
  paramsFromSearch,
  panelData
) => {
  const optionObject = {

    responsive: true,
    tooltip: {
      trigger: "item",
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 14,
      },
      formatter: showOnlyTypesExceptOnlyItem.includes(
        radarChartComputedValues.RADAR_CHART_SHOW_ONLY
      )
        ? function (value) {
            value.value = value.value[0];
            value.name = aggregationDateFormatController(
              value.name,
              paramsFromSearch?.aggregationFormats
            );
            return setShowOnlyValue(
              value,
              paramsFromSearch,
              radarChartComputedValues,
              RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_ONLY,
              dashboardSPEnums.TOOLTIP,
              panelData
            );
          }
        : function (value) {
            return aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);
          },
    },
    legend: {
      show: panelData.h >= 13 ? radarChartComputedValues.RADAR_CHART_SHOW_LEGEND : false,
      orient: getOrientPositionByLegend(radarChartComputedValues.RADAR_CHART_LEGEND_POSITION).toLowerCase(),
      left: getAlignPositionByLegend(radarChartComputedValues.RADAR_CHART_LEGEND_POSITION),
      top: getOrientPositionByLegend(radarChartComputedValues.RADAR_CHART_LEGEND_POSITION) ? "10%" : radarChartComputedValues.RADAR_CHART_LEGEND_VERTICAL_ALIGN,
      textStyle: {
        fontWeight: radarChartComputedValues.RADAR_CHART_LEGEND_FONT_WEIGHT,
        fontFamily: radarChartComputedValues.RADAR_CHART_LEGEND_FONT_FAMILY,
        fontSize: radarChartComputedValues.RADAR_CHART_LEGEND_FONT_SIZE,
        color: radarChartComputedValues.RADAR_CHART_LEGEND_FONT_COLOR,
      },
      formatter: function (value) {
        return truncateText(aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats), radarChartComputedValues.RADAR_CHART_LEGEND_MAX_CHARACTER);
      },
      type: "scroll",
    },
    radar: {
      indicator: paramsFromSearch?.radarIndicator,
      name: {
        textStyle: {
          color: radarChartComputedValues.RADAR_CHART_AXIS_NAME_FONT_COLOR,
          fontSize: radarChartComputedValues.RADAR_CHART_AXIS_NAME_FONT_SIZE,
          fontFamily: radarChartComputedValues.RADAR_CHART_AXIS_NAME_FONT_FAMILY,
          fontWeight: radarChartComputedValues.RADAR_CHART_AXIS_NAME_FONT_WEIGHT,
        },
        show: panelData.h >= 18 ? radarChartComputedValues.RADAR_CHART_SHOW_AXIS_NAME : false,

      },
      center: (getOrientPositionByLegend(radarChartComputedValues.RADAR_CHART_LEGEND_POSITION) === Orientation.VERTICAL) ?
                    getLeftValueWithLegendOrientPosition(getAlignPositionByLegend(radarChartComputedValues.RADAR_CHART_LEGEND_POSITION), ['60%', '50%'], ['40%', '50%'])
                        :
                        radarChartComputedValues.RADAR_CHART_SHOW_LEGEND ? ['50%', '60%'] : ['50%', '50%'],
    },
    series: paramsFromSearch?.dataSeries?.length
      ? [
          {
            data: paramsFromSearch?.dataSeries?.map((s, index) => ({
              value: s?.length ? s : [],
              name: paramsFromSearch?.dataSeriesNames?.[index],
              label: {
                formatter: showOnlyTypesExceptOnlyItem.includes(
                  radarChartComputedValues.RADAR_CHART_SHOW_ONLY
                )
                  ? function (value) {
                      value.name = aggregationDateFormatController(
                        value.name,
                        paramsFromSearch?.aggregationFormats
                      );
                      return setShowOnlyValue(
                        value,
                        paramsFromSearch,
                        radarChartComputedValues,
                        RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_ONLY,
                        dashboardSPEnums.LABEL,
                        panelData
                      );
                    }
                  : function () {
                      return paramsFromSearch?.dataSeriesNames?.[index];
                  },
              },
            })),
            type: "radar",
            avoidLabelOverlap: true,
            label: {
              position: "outside",
              padding: 0,
              show:
                panelData.h >= 13
                  ? radarChartComputedValues.RADAR_CHART_SHOW_VALUE_LABEL
                  : false,
              fontSize:
                radarChartComputedValues.RADAR_CHART_VALUE_LABEL_FONT_SIZE,
              fontFamily:
                radarChartComputedValues.RADAR_CHART_VALUE_LABEL_FONT_FAMILY,
              fontWeight:
                radarChartComputedValues.RADAR_CHART_VALUE_LABEL_FONT_WEIGHT,
              color:
                radarChartComputedValues.RADAR_CHART_VALUE_LABEL_FONT_COLOR,
            },
            color: getSelectedColorPaletteByProperty(
              radarChartComputedValues.RADAR_CHART_SELECTED_COLOR_PALETTE
            ),
            labelLayout: {
              hideOverlap: radarChartComputedValues.RADAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
            },
          },
        ]
      : null,
  };
  return { options: optionObject };
};
