<template>
  <div class="image-uploads">
    <div class="vis-admin-settings-pages-title" v-if="!panelLogosBox">
      <h2>{{ $t("admin.menuItem.Upload Images") }}</h2>
    </div>
    <!-- Sorting Options -->
    <div class="vis-flex--spacebeetwen-Ycenter">
      <span v-if="!panelLogosBox">{{
        $t(
          "admin.images.Manage uploaded images These images will be shown in charts"
        )
      }}</span>
      <div class="sorting-options">
        <el-popover
          ref="sortPopover"
          v-click-outside="() => handleClickOutside()"
          placement="bottom"
          trigger="manual"
          popper-class="vis-dropdown-popper vis-dropdown-body-close vis-sort-dropdown-ml--15"
          :value="isSortClicked"
          :reference="panelLogosBox ? referenceIcon : null"
        >
          <div class="vis-dropdown vis-sort-dropdown-min-width">
            <ul>
              <li
                @click="sortByProjectList(sort)"
                v-for="sort in orderBySortList"
                :key="sort.id"
                class="vis-flex--spacebeetwen"
              >
                <span :class="activeSortItemClass(sort)">{{
                  $t(`${sort.label}`)
                }}</span>
                <i
                  v-if="sort.id == activeSortItemId"
                  :class="[
                    customIcon.ArrowRight,
                    activeArrowIconClass(sort.type),
                  ]"
                  class="vis-arrow-icon-down vis-mr--none vis-color-primary"
                  :aria-hidden="true"
                ></i>
              </li>
            </ul>
          </div>
          <i
            slot="reference"
            v-if="!panelLogosBox"
            aria-hidden="true"
            :class="customIcon.SortBy"
            :id="sortByEnum"
            @click.stop="(e) => handlePopoverActivity(e)"
          />
        </el-popover>
        <div v-if="!panelLogosBox">
          <el-button
            @click="addImage"
            class="vis-cursor-pointer el-button--with-icon"
            type="text"
          >
            <i aria-hidden="true" :class="customIcon.AddBox"></i
            >{{ $t("admin.images.Add image") }}</el-button
          >
        </div>
      </div>
    </div>
    <!-- Image Cards -->
    <div
      class="vis-row image-cards vis-scroll-thin vis-content-area"
      v-loading="isImageDataLoading"
      @scroll.passive="handleScroll"
      ref="scrollContainer"
    >
      <div
        class="vis-image-card vis-col vis-col-3"
        v-for="image in paginatedImages"
        :key="image.id"
        @click="setSelectedImage(image)"
        :class="{ 'vis-select-image': isSelectedImage(image.id) }"
      >
        <div class="image-container">
          <div class="image-wrapper">
            <img
              :src="getImage(image.id)"
              alt="Uploaded Image"
              class="vis-uploaded-image"
              :lazy="true"
              fit="cover"
            />
            <!-- Hoverable Overlay -->
            <div class="image-overlay">
              <div
                class="image-actions"
                v-if="isShowEditModeImage(showEditIcons, image.id)"
              >
                <i
                  :class="`${customIcon.SaveOutlined} vis-cursor-pointer vis-mr--05`"
                  aria-hidden="true"
                  @click="saveImage(image)"
                ></i>
                <i
                  :class="`${customIcon.SwapHoriz} vis-cursor-pointer vis-mr--05`"
                  aria-hidden="true"
                  @click="handleChangeImage(image)"
                ></i>
                <i
                  :class="`${customIcon.Close} vis-cursor-pointer vis-mr--05`"
                  aria-hidden="true"
                  @click="closeTheEdit(image)"
                ></i>
              </div>
              <div
                class="image-actions"
                v-if="!panelLogosBox && !selectedImage"
              >
                <i
                  :class="`${customIcon.PencilOutline} vis-cursor-pointer vis-mr--05`"
                  aria-hidden="true"
                  @click="openTheEdit(image)"
                ></i>
                <i
                  :class="`${customIcon.Delete} vis-cursor-pointer vis-mr--05`"
                  aria-hidden="true"
                  @click="deleteActionClicked(image)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="image-title">
          <input
            :id="image.name"
            class="vis-image-input"
            :readonly="!isShowEditModeImage(showEditIcons, image.id)"
            :value="image.name"
            tabindex="-1"
            type="text"
            autocomplete="off"
            @input="updateImageTitle(image, $event)"
          />
        </div>
      </div>

      <!-- Upload New Image -->
      <el-upload
        v-if="!panelLogosBox"
        ref="upload"
        class="vis-upload-card vis-col vis-col-3 vis-custom-upload"
        :class="[{ 'vis-display-none': isHandleChangeImage }]"
        action=""
        :on-change="handleImport"
        :auto-upload="false"
        list-type="picture"
        :file-list="fileList"
      >
        <i :class="`${customIcon.Plus}`" aria-hidden="true"></i>
        <div>{{ $t("admin.images.Add image") }}</div>
      </el-upload>
    </div>

    <!-- Confirmation Dialog -->
    <ConfirmDialog
      :title="$t('dialog.Delete Image?')"
      :message="
        $t(
          'dialog.Are you sure to remove this image? You cannot take back this action!'
        )
      "
      :dialogVisible="dialogVisible"
      @confirm="deleteImage"
      @cancel="dialogVisible = $event"
    />
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import ConfirmDialog from "../helper/ConfirmDialog.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import cloneDeep from "clone-deep";
import { Notify } from "../../commons/helper";
import { notificationType } from "../../commons/notificationTypes";
import {
  ACTION as ACTION_UPLOAD_IMAGES,
  GETTER as GETTER_UPLOAD_IMAGES,
  MUTATION as MUTATION_UPLOAD_IMAGES,
} from "../../store/modules/Visualize/UploadImages/types";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { SORT_BY } from "../../commons/homeDataTypes";
import { UPLOAD_IMAGES_SORT_LIST } from "./settings";
import ClickOutside from "vue-click-outside";
import { defaultLogo } from "../../commons/dashboardAndPanel";

export default {
  components: { ConfirmDialog },
  directives: {
    ClickOutside,
  },
  props: {
    panelLogosBox: {
      type: Boolean,
      default: false,
    },
    externalIcon: {
      type: HTMLElement,
      default: () => null,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      dialogVisible: false,
      selectedImage: null,
      showEditIcons: false,
      localImages: [],
      sortBy: "id",
      fileList: [],
      paginatedImages: [],
      isSortClicked: false,
      activeSortItemId: null,
      orderBySortList: UPLOAD_IMAGES_SORT_LIST,
      sortByEnum: "sortBy",
      SORT_BY: SORT_BY,
      scrollPosition: null,
      defaultLogo: defaultLogo,
      isHandleChangeImage: false,
      referenceIcon: null,
      servicePath: process.env.VUE_APP_API_ENDPOINT,
      queryPaginationParam: `page=0`,
      querySortParam: null,
    };
  },
  watch: {
    isImageDataLoading: {
      handler(val) {
        if (val) return;
        this.changeCardColMaxWidthByResponsive();
      },
    },
    allImages: {
      handler(val) {
        this.paginatedImages = cloneDeep(val);
      },
      deep: true,
    },
  },
  destroyed() {
    window.removeEventListener(
      "resize",
      this.changeCardColMaxWidthByResponsive
    );
    this.resetImagesSearch();
  },
  created() {
    window.addEventListener("resize", this.changeCardColMaxWidthByResponsive);
  },
  async mounted() {
    this.resetImagesSearch();
    this.handleHomePageSearch();
  },
  computed: {
    ...mapGetters({
      allImages: GETTER_UPLOAD_IMAGES.GET_IMAGES,
      imagesPagination: GETTER_UPLOAD_IMAGES.GET_IMAGES_PAGINATION,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    isImageDataLoading() {
      return this.loading[LoadingComponent.UploadImages];
    },
    getDefaultLogo() {
      let images = require.context("../../assets/images/", false, /\.png$/);

      return images("./" + this.defaultLogo + ".png");
    },
  },
  methods: {
    ...mapActions({
      fetchUploadImages: ACTION_UPLOAD_IMAGES.FETCH_IMAGES,
      createUploadImages: ACTION_UPLOAD_IMAGES.CREATE_IMAGES,
      updateUploadImages: ACTION_UPLOAD_IMAGES.UPDATE_IMAGES,
      deleteUploadImages: ACTION_UPLOAD_IMAGES.DELETE_IMAGES,
      fetchImage: ACTION_UPLOAD_IMAGES.FETCH_IMAGE_BY_ID,
      fetchImageThumbnail: ACTION_UPLOAD_IMAGES.FETCH_IMAGE_THUMBNAIL,
    }),
    ...mapMutations({
      resetImagesSearch: MUTATION_UPLOAD_IMAGES.RESET_SEARCH_IMAGES_RESULTS,
    }),
    async handleScroll(e) {
      let currentScrollPosition = Math.ceil(e.srcElement.scrollTop + 1);

      this.scrollPosition =
        e.srcElement.scrollHeight - e.srcElement.offsetHeight;

      if (
        currentScrollPosition === this.scrollPosition &&
        this.imagesPagination.number + 1 < this.imagesPagination.totalPages &&
        currentScrollPosition !== 0
      ) {
        this.queryPaginationParam = `page=${this.imagesPagination.number + 1}`;

        await this.handleHomePageSearch();

        this.changeCardColMaxWidthByResponsive();
      }
      this.scrollPosition = currentScrollPosition;
    },
    changeCardColMaxWidthByResponsive() {
      let colList = document.querySelectorAll(
        ".vis-user-tabs-content .vis-row .vis-col"
      );
      let firstCardWidth = null;

      colList.forEach((col) => {
        if (!firstCardWidth) {
          firstCardWidth = cloneDeep(col.clientWidth);
        }
        if (firstCardWidth && col.clientWidth !== firstCardWidth) {
          col.style.maxWidth = firstCardWidth + "px";
        }
      });
    },
    openTheEdit(image) {
      this.selectedImage = image;
      this.showEditIcons = true;
    },
    closeTheEdit(image) {
      if (this.selectedImage?.id === image?.id) {
        const images = cloneDeep(this.localImages);
        const checkImage = images.find(
          (data) => data.id === this.selectedImage?.id
        );

        if (checkImage && checkImage.name !== image.name) {
          Notify(
            this.$t(
              "admin.images.Unsaved changes detected. Please save the changes."
            ),
            notificationType.WARNING
          );
          return;
        } else {
          this.selectedImage = null;
          this.showEditIcons = false;
          this.isHandleChangeImage = false;
        }
      }
    },
    getImage(imageId) {
      return `${this.servicePath}visualize/v1/images/${imageId}/thumbnail`;
    },
    deleteActionClicked(image) {
      this.dialogVisible = true;
      this.selectedImage = image;
    },
    async deleteImage() {
      await this.deleteUploadImages(this.selectedImage.id);
      this.selectedImage = null;
      this.dialogVisible = false;
    },
    updateImageTitle(data, event) {
      data.name = event.target.value;
    },
    async saveImage(data) {
      const bodyParam = new FormData();

      bodyParam.append("file", this.fileList[0]?.raw || null);

      if (this.isHandleChangeImage) {
        bodyParam.append("name", this.selectedImage?.name);
      } else {
        bodyParam.append("name", data?.name);
      }

      await this.updateUploadImages({
        bodyParam,
        queryParam: this.selectedImage.id,
      });
      await this.fetchImageThumbnail({ imageId: this.selectedImage.id });
      await this.resetImagesSearch();
      await this.handleHomePageSearch();

      this.showEditIcons = false;
      this.selectedImage = null;
      this.isHandleChangeImage = false;
    },
    isShowEditModeImage(isShow, imageId) {
      return isShow && imageId === this.selectedImage?.id;
    },
    async handleImport(file, fileList) {
      this.fileList = [fileList[fileList.length - 1]];

      if (this.isHandleChangeImage) {
        await this.saveImage(file);
      } else {
        if (this.fileList.length > 0) {
          const formData = this.createImageUploadPayload();

          await this.createUploadImages(formData);
          await this.handleHomePageSearch();
        }
      }

      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    createImageUploadPayload() {
      const fd = new FormData();

      if (this.fileList.length > 0) {
        fd.append("file", this.fileList[0].raw);
      }

      return fd;
    },
    handleClickOutside() {
      this.isSortClicked = false;
    },
    handlePopoverActivity(e) {
      if (e.target.id === this.sortByEnum) {
        this.isSortClicked = !this.isSortClicked;
      }
    },
    openPopover() {
      this.referenceIcon = this.externalIcon;
      this.$refs.sortPopover.doShow();
    },
    closePopover() {
      this.referenceIcon = null;
      this.$refs.sortPopover.doClose();
    },
    async sortByProjectList(sort) {
      this.activeSortItemId = sort.id;

      sort.type == SORT_BY.ASC
        ? (sort.type = SORT_BY.DESC)
        : (sort.type = SORT_BY.ASC);

      this.querySortParam = `sort=${sort.value},${sort?.type}`;
      this.queryPaginationParam = `page=0`
      
      this.resetImagesSearch();
      await this.handleHomePageSearch();
    },
    activeSortItemClass(item) {
      return item.type && item.id == this.activeSortItemId
        ? "vis-font-bold"
        : "";
    },
    activeArrowIconClass(sortType) {
      return sortType == SORT_BY.DESC ? "vis-arrow-icon-up" : "";
    },
    handleChangeImage() {
      // Upload işlemini tetikle
      this.isHandleChangeImage = true;
      this.$refs.upload.$el.querySelector("input").click();
    },
    addImage() {
      this.$refs.upload.$el.querySelector("input").click();
    },
    isSelectedImage(id) {
      return this.selectedImage?.id === id;
    },
    setSelectedImage(image) {
      if (this.panelLogosBox) {
        this.selectedImage = image;
        this.$emit("setSelectedImage", image);
      }
    },
    async handleHomePageSearch() {
      await this.fetchUploadImages({
        loadingComponent: LoadingComponent.UploadImages,
        queryParam: `${this.queryPaginationParam}${
          this.querySortParam ? `&${this.querySortParam}` : ""
        }`,
      });
    },
  },
};
</script>
<style scoped>
.image-uploads {
  padding: 20px;
  font-size: 13px;
  font-weight: 500;
}

.image-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
}

.vis-image-card {
  border: 1px solid #e4e3e8;
  padding: 12px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: 120px;
  position: relative;
  background-color: white;
}

.image-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  font-size: 13px !important;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.image-wrapper {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.vis-uploaded-image {
  width: 100%;
  object-fit: cover;
  display: block;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
}

.image-wrapper:hover .image-overlay {
  opacity: 1;
}

.image-title {
  color: black;
  text-align: center;
  margin-top: 10px;
  width: 100%;
}

.image-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: white;
}

.image-actions i {
  font-size: 24px;
  margin: 0 10px;
  color: white;
}

.vis-image-input {
  background: transparent;
  border: none;
  color: black;
  text-align: center;
  width: 100%;
  outline: none;
  font-size: 16px;
  position: relative;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.add-image-card {
  border: 1px solid #e4e3e8;
  padding: 16px;
  margin: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #3d62f8;
  font-weight: 600;
  max-width: 220px;
  min-height: 120px;
}

::v-deep .el-upload {
  color: #206ff9 !important;
  width: 100%;
  text-align: center !important;
  align-content: center !important;
  height: 100%;
}

input:read-only {
  cursor: pointer;
}

::v-deep .el-upload-dragger {
  height: 120px !important;
  text-align: center !important;
}

.sorting-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sorting-options {
  display: flex;
  align-items: center;
}

.sorting-options select {
  margin-left: 10px;
}

.vis-upload-card {
  border: 1px solid #e4e3e8;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #3d62f8;
  font-weight: 600;
  max-width: 220px;
  min-height: 260px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.vis-select-image {
  border: 3px solid #3d62f8 !important;
  box-sizing: border-box;
}

::v-deep .vis-custom-upload .el-upload-list__item {
  max-height: 140px;
  text-align: center;
  overflow: hidden;
}

::v-deep::v-deep .vis-custom-upload .el-upload-list__item-thumbnail {
  width: 100%;
  height: 80px;
  object-fit: cover;
  margin-bottom: 5px;
}

::v-deep .vis-custom-upload .el-upload-list__item-name {
  font-size: 12px;
  word-wrap: break-word;
  max-width: 100px;
}
</style>
