
import {
    fontsArr,
    fontSizesArr,
    bigFontSizesArr,
    fontWeightsArr,
    SELECT_LIST_FILTER_PROPERTIES_ENUM,
    predefineColorsArr,
    verticalAlignItems,
    selectListFilterOrientationArr,
    filterDefaultValues,
    filterSelectionActions
} from "../../commons/dashboardProperties";
import { SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/selectListFilterDefaultProperties";
import { chartPropertiesCss } from "./chartPropertiesCssEnum";

export default {
    computed: {
        selectListFilterComponentsMapping() {
            return {
                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_MANDATORY_SELECTION]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_MANDATORY_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.mandatorySelection",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_MANDATORY_SELECTION
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_MANDATORY_SELECTION),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_MANDATORY_SELECTION,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_ALLOW_MULTISELECT]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_ALLOW_MULTISELECT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.multiSelect",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_ALLOW_MULTISELECT
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_ALLOW_MULTISELECT),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_ALLOW_MULTISELECT,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SHOW_SQL]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SHOW_SQL
                      ],
                      SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SHOW_SQL,
                    flexClass: true,
                },
                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_APPLY_TO_TABS]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_APPLY_TO_TABS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Apply to Tabs",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_APPLY_TO_TABS
                    ], [this.dashboardTabsOptionList[this.chartTabIndex]?.value]),

                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_APPLY_TO_TABS,
                    componentType: this.componentTypes.CUSTOM_MULTIPLE_SELECT_BOX,
                    selectValues: this.dashboardTabsOptionList,
                    flexClass: false,
                },
                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DIRECTION]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DIRECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.direction",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DIRECTION
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_DIRECTION),
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: selectListFilterOrientationArr,
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DIRECTION,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SELECTION_ACTION]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SELECTION_ACTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.selectionAction",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SELECTION_ACTION
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_SELECTION_ACTION),

                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SELECTION_ACTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterSelectionActions,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DEFAULT_SELECTION]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DEFAULT_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "generalPages.defaultValue",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DEFAULT_SELECTION
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_DEFAULT_SELECTION),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DEFAULT_SELECTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterDefaultValues,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontFamily",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_FAMILY
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_FONT_FAMILY),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontWeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_WEIGHT
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_FONT_WEIGHT),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontSize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_SIZE
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_FONT_SIZE),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_ALIGN]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.align",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_ALIGN
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_ALIGN),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.value.fontColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_FONT_COLOR),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_SHOW_LABEL]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_SHOW_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_SHOW_LABEL
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_SHOW_LABEL,
                    flexClass: true,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.Value Label Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_LABEL_FONT_FAMILY),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: [...fontSizesArr, ...bigFontSizesArr],
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_LABEL_ALIGN]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_LABEL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.align",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_LABEL_ALIGN
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_ALIGN),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_LABEL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_VALUE_FONT_COLOR),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.backgroundColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_UNIQUE_NAME]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_UNIQUE_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.uniqueName",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_UNIQUE_NAME
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_UNIQUE_NAME),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_UNIQUE_NAME,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },

                [SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_TAB_INDEX]: {
                    id: this.getPropertyId(
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_TAB_INDEX,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.tabIndex",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_TAB_INDEX
                    ], SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS.SELECT_LIST_FILTER_TAB_INDEX),
                    property: SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_TAB_INDEX,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },

            }
        },
    },
};
