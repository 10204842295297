import {
  SANKEY_CHART_PROPERTIES_ENUM,
  dashboardSPEnums,
  showOnlyTypesExceptOnlyItem,
} from "../../commons/dashboardProperties";
import { getSankeyChartDataOpacity, getSankeyChartLineOpacity } from "../chart-filter/filterChartStyles";
import {
  getSelectedColorPaletteByProperty,
  setShowOnlyValue,
  aggregationDateFormatController,
} from "../chartOptionsHelper";

export const getSankeyChartOptions = (
  sankeyChartComputedValues,
  paramsFromSearch,
  panelData
) => {
  const optionObject = {
    title: {
      text: "",
    },
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 12,
      },
      formatter: function (value) {
        value.name = aggregationDateFormatController(
          value?.name,
          paramsFromSearch?.aggregationFormats
        );

        return showOnlyTypesExceptOnlyItem.includes(
          sankeyChartComputedValues.SANKEY_CHART_SHOW_ONLY
        )
          ? setShowOnlyValue(
              value,
              paramsFromSearch,
              sankeyChartComputedValues,
              SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_SHOW_ONLY,
              dashboardSPEnums.TOOLTIP,
              panelData
            )
          : value.name;
      },
    },
    series: [
      {
        type: "sankey",
        data: paramsFromSearch?.dataSeries?.map(data => {
          return {
            ...data,
            itemStyle: {
                ...getSankeyChartDataOpacity(panelData, data),
            },
        };
        }) ?? [],
        links: paramsFromSearch?.links?.map(l => {
            return {
                ...l,
                lineStyle: {
                    ...getSankeyChartLineOpacity(panelData, l),
                },
            };
        }) ?? [],
        layoutIterations: 0,
        nodeAlign: sankeyChartComputedValues.SANKEY_CHART_NODE_ALIGN,
        emphasis: {
          focus: "adjacency",
        },
        lineStyle: {
          color: "gradient",
        },
        label: {
          show: sankeyChartComputedValues.SANKEY_CHART_SHOW_VALUE_LABEL,
          fontSize:
            sankeyChartComputedValues.SANKEY_CHART_VALUE_LABEL_FONT_SIZE,
          fontFamily:
            sankeyChartComputedValues.SANKEY_CHART_VALUE_LABEL_FONT_FAMILY,
          fontWeight:
            sankeyChartComputedValues.SANKEY_CHART_VALUE_LABEL_FONT_WEIGHT,
          color: sankeyChartComputedValues.SANKEY_CHART_VALUE_LABEL_FONT_COLOR,
          formatter: function (value) {
            value.name = aggregationDateFormatController(
              value?.name,
              paramsFromSearch?.aggregationFormats
            );

            return showOnlyTypesExceptOnlyItem.includes(
              sankeyChartComputedValues.SANKEY_CHART_SHOW_ONLY
            )
              ? setShowOnlyValue(
                  value,
                  paramsFromSearch,
                  sankeyChartComputedValues,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_SHOW_ONLY,
                  dashboardSPEnums.LABEL,
                  panelData
                )
              : value?.name;
          },
        },
        labelLayout: {
          hideOverlap:
            sankeyChartComputedValues.SANKEY_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
        },
      },
    ],
    color: getSelectedColorPaletteByProperty(
      sankeyChartComputedValues.SANKEY_CHART_SELECTED_COLOR_PALETTE
    ),
  };

  return { options: optionObject };
};
