<template>
  <div class="summary-seperator">
    <div v-if="trendCalculation !== trendCalculationTypes.SIMPLE">
      <div class="draft-row vis-flex--Ycenter vis-mb--1 vis-pb--1">
        <el-input-number
          :value="draftTrend.value"
          controls-position="right"
          :placeholder="
            trendCalculation === trendCalculationTypes.BY_PERCENT
              ? $t('panelProperties.Enter %')
              : $t('panelProperties.Enter Value')
          "
          @change="(val) => setDraft(summarySeperatorItemTypes.VALUE, val)"
        ></el-input-number>
        <el-color-picker
          :value="draftTrend.color"
          :predefine="predefineColorsArr"
          @change="(value) => setDraft(summarySeperatorItemTypes.COLOR, value)"
        ></el-color-picker>
        <SelectboxWithIcon
          :options="propertiesIconList"
          :value="draftTrend.icon"
          width="25%"
          @handleChange="
            (value) => setDraft(summarySeperatorItemTypes.ICON, value)
          "
        />
        <i
          :class="[CustomIcon.AddPlusCircle, 'vis-cursor-pointer']"
          aria-hidden="true"
          @click="addDraft()"
        ></i>
      </div>
    </div>
    <div>{{ $t(getLabelAccordingToCalculationType()) }}</div>
    <div
      v-for="(trend, index) in checkCalculationType(trends)"
      :key="index"
      class="vis-flex--Ycenter"
      :class="{ 'vis-mb--1': index !== trends.length - 1 }"
    >
      <el-input
        v-if="staticInputList.includes(trend.value)"
        type="text"
        :value="trend.value"
        readonly
        class="trend-input"
      />
      <el-input-number
        v-else
        :value="trend.value"
        :controls="false"
        type="number"
        class="editable-input"
        @change="(value) => setValue(index, value)"
      />
      <el-color-picker
        class="vis-mx--05"
        :value="trend.color"
        :predefine="predefineColorsArr"
        @change="(value) => setColor(index, value)"
      ></el-color-picker>

      <SelectboxWithIcon
        :options="propertiesIconList"
        :value="trend.icon"
        width="25%"
        @handleChange="(value) => setIcon(index, value)"
      />
      <i
        v-if="!staticInputList.includes(trend.value)"
        :class="[CustomIcon.TrashCircle, 'vis-cursor-pointer']"
        aria-hidden="true"
        @click="removeDraft(index)"
      ></i>
    </div>
  </div>
</template>

<script>
import {
  predefineColorsArr,
  propertiesIconList,
  staticInputList,
  summarySeperatorItemTypes,
  trendCalculationTypes,
  trendStaticInputs,
} from "../../../commons/dashboardProperties";
import { CustomIcon } from "../../../assets/js/custom-icons";
import SelectboxWithIcon from "../../helper/SelectboxWithIcon.vue";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import { BOX_KEY_ENUM } from "../../../commons/dashboardAndPanel";
import { SUMMARY_CHART_DEFAULT_CONFIGURATIONS } from "../../../mocks/summaryChartDefaultProperties";

const defaultTrend = { value: undefined, color: "#3D3D3D", icon: "" };

export default {
  components: { SelectboxWithIcon },
  data() {
    return {
      predefineColorsArr: predefineColorsArr,
      propertiesIconList: propertiesIconList,
      CustomIcon: CustomIcon,
      trends: [],
      trendCalculationTypes: trendCalculationTypes,
      trendStaticInputs: trendStaticInputs,
      summarySeperatorItemTypes: summarySeperatorItemTypes,
      draftTrend: { ...defaultTrend },
      staticInputList: staticInputList
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    trendCalculation: {
      type: String,
    },
    summaryResponse: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.trends = this.checkCalculationType(this.value);
  },
  watch: {
    trendCalculation(newVal, oldVal) {
      const targetValue = this.summaryResponse?.response?.find(
        (x) => x.boxKey === BOX_KEY_ENUM.TARGET.KEY
      )?.value;
      
      const actions = {
        [trendCalculationTypes.BY_VALUE]: () =>
        oldVal !== trendCalculationTypes.SIMPLE &&
          this.convertToValue(oldVal, targetValue),
        [trendCalculationTypes.SIMPLE]: () =>
          oldVal !== trendCalculationTypes.BY_VALUE &&
          this.convertToValue(oldVal, targetValue),
        [trendCalculationTypes.BY_PERCENT]: () =>
          this.convertToPercent(oldVal, targetValue),
        [trendCalculationTypes.STATIC_VALUES]: () =>
          this.convertToStatic(oldVal, targetValue),
      };
      
      actions[newVal]?.();

      this.sortByCalculationType(this.trends);
      this.setComponentStyle();
    },
  },
  computed: {
    itemsNotInStaticInputList() {
      return this.trends.filter(
        (trend) => !this.staticInputList.includes(trend.value)
      );
    },
    itemsInStaticInputList() {
      return this.trends.filter(
        (trend) => this.staticInputList.includes(trend.value)
      );
    },
  },
  methods: {
    checkDefaultListAvailability(oldVal) {
      if (
        oldVal === trendCalculationTypes.STATIC_VALUES &&
        !this.itemsInStaticInputList.length
      ) {
        let defaultValues =
          SUMMARY_CHART_DEFAULT_CONFIGURATIONS.SUMMARY_CHART_SEPERATION_VALUES;
        this.trends = [...this.trends, ...defaultValues];
      }
    },
    convertToValue(oldVal, targetValue) {
      this.checkDefaultListAvailability(oldVal);
      this.itemsNotInStaticInputList?.forEach(
        (trend) =>
          (trend.value =
            oldVal === trendCalculationTypes.STATIC_VALUES
              ? trend.value - targetValue
              : Math.floor((trend.value / 100) * targetValue))
      );
    },
    convertToPercent(oldVal, targetValue) {
      this.checkDefaultListAvailability(oldVal);
      this.itemsNotInStaticInputList?.forEach(
        (trend) =>
          (trend.value = Math.ceil(
            ((trend.value -
              (oldVal === trendCalculationTypes.STATIC_VALUES
                ? targetValue
                : 0)) /
              targetValue) *
              100
          ))
      );
    },
    convertToStatic(oldVal, targetValue) {
      this.itemsNotInStaticInputList?.forEach(
        (trend) =>
          (trend.value =
            oldVal === trendCalculationTypes.BY_PERCENT
              ? Math.floor(((trend.value + 100) / 100) * targetValue)
              : trend.value + targetValue)
      );
    },
    checkCalculationType(items) {
      if (this.trendCalculation === trendCalculationTypes.SIMPLE) {
        return items.filter((item) =>
          this.staticInputList.includes(item.value)
        );
      } else if (this.trendCalculation === trendCalculationTypes.STATIC_VALUES) {
        return items.filter((item) =>
          !this.staticInputList.includes(item.value)
        );
      }
       else return items;
    },
    getLabelAccordingToCalculationType() {
      if (this.trendCalculation === trendCalculationTypes.BY_VALUE) {
        return "panelProperties.Enter Splits As Value";
      } else if (this.trendCalculation === trendCalculationTypes.BY_PERCENT) {
        return "panelProperties.Enter Splits As %";
      } else {
        return "panelProperties.Splits";
      }
    },
    setColor(index, value) {
      if (this.trendCalculation === trendCalculationTypes.STATIC_VALUES) {
        this.itemsNotInStaticInputList[index].color = value;
        this.trends = [...this.itemsNotInStaticInputList, ...this.itemsInStaticInputList];
      } else {
        this.trends[index].color = value;
      }
      
      this.setComponentStyle();
    },
    setIcon(index, value) {
      if (this.trendCalculation === trendCalculationTypes.STATIC_VALUES) {
        this.itemsNotInStaticInputList[index].icon = value;
        this.trends = [...this.itemsNotInStaticInputList, ...this.itemsInStaticInputList];
      } else {
        this.trends[index].icon = value;
      }

      this.setComponentStyle();
    },
    setValue(index, value) {
      if (this.trendCalculation === trendCalculationTypes.STATIC_VALUES) {
        this.itemsNotInStaticInputList[index].value = value;
        this.trends = [...this.itemsNotInStaticInputList, ...this.itemsInStaticInputList];
      } else {
        this.trends[index].value = value;
      }
      
      this.setComponentStyle();
      this.sortByCalculationType(this.trends);
    },
    removeDraft(index) {
      if (this.trendCalculation === trendCalculationTypes.STATIC_VALUES) {
        this.itemsNotInStaticInputList?.splice(index, 1)
        this.trends = [...this.itemsNotInStaticInputList, ...this.itemsInStaticInputList];
      } else {
        this.trends.splice(index, 1);
      }

      this.setComponentStyle();
    },
    setDraft(field, value) {
      this.draftTrend[field] = value;
    },
    addDraft() {
      if (this.draftTrend.icon && typeof this.draftTrend.value === "number" && this.draftTrend.value !== 0) {
        this.trends.push(this.draftTrend);
        this.sortByCalculationType(this.trends);
        this.draftTrend = { ...defaultTrend };
        this.setComponentStyle();
      } else if (this.draftTrend.value === 0) {
        Notify(
          this.$t("notifyMessages.Field can not take the value zero"),
          notificationType.WARNING
        );
      } else {
        Notify(
          this.$t("notifyMessages.Please fill in all fields"),
          notificationType.WARNING
        );
      }
    },
    sortByCalculationType(trends) {
      const getComparableValue = (item) => {
        if (item.value === trendStaticInputs.GREATER) return Infinity;
        if (item.value === trendStaticInputs.LESS) return -Infinity;
        if (item.value === trendStaticInputs.ON_TARGET) {
          return 0;
        }
        return item.value;
      };
      trends
        .sort((a, b) => {
          let aValue = getComparableValue(a);
          let bValue = getComparableValue(b);

          return aValue - bValue;
        })
        .reverse();
    },
    setComponentStyle() {
      this.$emit("setComponentStyle", this.trends);
    },
  },
};
</script>
<style scoped>
::v-deep .trend-input {
  max-width: 106px;
  border-radius: 7px;
  opacity: 1;
}

::v-deep .editable-input {
  max-width: 106px;
  height: 32px;
  line-height: 30px;
  font-size: 0.75rem;
  border-radius: 7px;
  opacity: 1;
  margin-right: 0 !important;
}

::v-deep .el-color-picker {
  height: 32px !important;
  margin-right: 10px;
}

::v-deep .el-select {
  margin-right: 10px !important;
}

::v-deep .draft-row {
  border-bottom: 1px solid #d8d8d8;
}

::v-deep .el-input-number {
  margin-right: 10px;
}

::v-deep .el-input .el-input__inner {
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  opacity: 1;
}

::v-deep .el-input-number.is-controls-right .el-input__inner {
  padding-left: 0px !important;
  padding-right: 30px !important;
}

::v-deep .el-select .el-input .el-input__inner {
  padding-left: 35px !important;
}

::v-deep .el-input-number__decrease,
::v-deep .el-input-number__increase {
  width: 25%;
}

::v-deep .el-input__prefix,
::v-deep .el-input__suffix {
  color: #303133;
}
</style>
