export const IngestNodeTypes = {
  Database: "DATABASE",
  SMB: "SMB",
  FTP: "FTP",
  Dataset: "Dataset",
};

export const IngestJobFields = {
  name: "name",
  description: "description",
  targetTableName: "targetTableName",
  cursorField: "cursorField",
  orderBy: "orderBy",
  partitionBy: "partitionBy",
  jobMode: "jobMode",
  scheduleField: "scheduleField",
  scheduleMode: "scheduleMode",
};

export const IngestJobStatus= {
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  FAILED: "FAILED",
};