export const filterOperator = {
    EQ: "eq",
    IN: "in",
    BETWEEN: "between",
    NOTEQ: "notEq"
}

export const singleFilterOperator = {
    EQ: filterOperator.EQ,
    NOTEQ: filterOperator.NOTEQ
}

export const filterPropKey = {
    FILTER_NUMBER_VALUE: "FILTER_NUMBER_VALUE",
    FILTER_MULTIPLETEXT_VALUE: "FILTER_MULTIPLETEXT_VALUE",
    FILTER_VALUE: "FILTER_VALUE",
}

export const filterType = {
    CUSTOM: "CUSTOM",
    SECURITY: "SECURITY",
    TOKEN: "TOKEN",
}

export const panelDataDetailsKey = {
    FILTERS: "filters",
    PARAMS: "params",
    FILTER: "filter"
}