<template>
  <el-radio-group :value="value" class="vis-display-flex" @input="$emit('change', $event)">
    <el-radio-button label="left">
      <i class="mdi mdi-format-align-left"></i>
    </el-radio-button>
    <el-radio-button label="center">
      <i class="mdi mdi-format-align-center"></i>
    </el-radio-button>
    <el-radio-button label="right">
      <i class="mdi mdi-format-align-right"></i>
    </el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "left",
    },
  },
};
</script>

<style scoped>
::v-deep .el-radio-button__inner {
  padding: 4px;
  border: none;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #3d62f8;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: -1px 0 0 0 #ffffff;
}
.el-radio-button i {
  font-size: 1.25rem !important;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border: none !important;
}
::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  box-shadow: none !important;
}
::v-deep .el-radio-group {
  height: 32px !important;
}
</style>
