export const domain = "VISUALIZE_CONFIGURATIONS";

export const STATE = {
   CONFIGURATIONS: `${domain}/CONFIGURATIONS`,
};

export const GETTER = {
  GET_CONFIGURATIONS: `${domain}/GET_CONFIGURATIONS`,
};

export const ACTION = {
  FETCH_CONFIGURATIONS: `${domain}/FETCH_CONFIGURATIONS`,
  CREATE_CONFIGURATION: `${domain}/CREATE_CONFIGURATION`,
  UPDATE_CONFIGURATION: `${domain}/UPDATE_CONFIGURATION`
};

export const MUTATION = {
  SET_CONFIGURATIONS: `${domain}/SET_CONFIGURATIONS`,
};


