import { v4 as uuidv4 } from "uuid";
export const HOME_TABS_KEY = {
  ALL: "all",
  FOLDER: "folders",
  DATA_MODEL: "data_model",
  DASHBOARD: "dashboard",
  RECENTLY_USED: "recently_Used",
  FAVORITES: "favorites",
  DATA_FLOW: "data_flow",
  SHARED_WITH_ME: "SHARED_WITH_ME",
  MY_CONTENT: "MY_CONTENT",
};

export const HOME_SCENARIO_TABS_KEY = {
  ALL: "all",
  SCENARIO: "SCENARIO",
  EVENT: "EVENT",
  ENRICHMENT: "ENRICHMENT",
};

export const getHomeTabListByAuthority = () => {
  return [
    {
      id: uuidv4(),
      key: HOME_TABS_KEY.ALL,
      localizationKey: "all",
      isShow: true,
    },
    {
      id: uuidv4(),
      key: HOME_TABS_KEY.RECENTLY_USED,
      localizationKey: "recentlyUsed",
      isShow: true,
    },
    {
      id: uuidv4(),
      key: HOME_TABS_KEY.SHARED_WITH_ME,
      localizationKey: "Shared With Me",
      isShow: true,
    },
    {
      id: uuidv4(),
      key: HOME_TABS_KEY.MY_CONTENT,
      localizationKey: "My Contents",
      isShow: true,
    },
    {
      id: uuidv4(),
      key: HOME_TABS_KEY.FAVORITES,
      localizationKey: "favorites",
      isShow: true,
    },
  ].filter((x) => x.isShow);
};

export const getHomeScenarioTabListByAuthority = () => {
  return [
    {
      id: uuidv4(),
      key: HOME_SCENARIO_TABS_KEY.ALL,
      localizationKey: "all",
      isShow: true,
    },
    {
      id: uuidv4(),
      key: HOME_SCENARIO_TABS_KEY.SCENARIO,
      localizationKey: "scenario",
      isShow: true,
    },
    {
      id: uuidv4(),
      key: HOME_SCENARIO_TABS_KEY.EVENT,
      localizationKey: "event",
      isShow: true,
    },
    {
      id: uuidv4(),
      key: HOME_SCENARIO_TABS_KEY.ENRICHMENT,
      localizationKey: "enrichment",
      isShow: true,
    },
  ].filter((x) => x.isShow);
};

export const SORT_BY = {
  ASC: "ASC",
  DESC: "DESC",
};

export const HOMEPAGE_SEARH_PARAMS = {
  PAGE: "page",
  OBJECT_TYPE: "objectType",
  FOLDER_ID: "folderId",
  SORT_PARAMS: "sortParams",
  SEARCH_KEY: "searchKey",
  FAVORITE: "isFav",
  MY_CONTENT: "myContent",
  SHARED_WITH_ME: "sharedWithMe",
};

export const HOME_TABS_SORT_VALUES = {
  UPDATED_DATE: "updated_date",
  NAME: "name",
  OBJECT_TYPE: "object_type",
  OWNER_NAME: "owner_name",
};

export const HOME_TABS_SORT_LIST = [
  { id: 1, label: "generalPages.labelUpdateDate", value: HOME_TABS_SORT_VALUES.UPDATED_DATE, type: "" },
  { id: 2, label: "generalPages.name", value: HOME_TABS_SORT_VALUES.NAME, type: "" },
  { id: 3, label: "generalPages.labelType", value: HOME_TABS_SORT_VALUES.OBJECT_TYPE, type: "" },
  { id: 4, label: "generalPages.labelOwner", value: HOME_TABS_SORT_VALUES.OWNER_NAME, type: "" },
];

export const HOME_TABS_FILTER_BY_TYPE = [
  { id: 1, label: "home.dashboards", value: "DASHBOARDS", type: "Dashboard"},
  { id: 2, label: "home.dataModels", value: "DATAMODELS", type: "Datamodel"},
  { id: 3, label: "home.dataFlows", value: "DATAFLOWS", type: "Dataflow"},
  { id: 4, label: "home.folders", value: "FOLDERS", type: "Folder"},
];

export const HOMEPAGE_SEARH_QUERY_DATA_LIST = [
  {key: HOMEPAGE_SEARH_PARAMS.PAGE, value: ""},
  {key: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE, value: []},
  {key: HOMEPAGE_SEARH_PARAMS.FOLDER_ID, value: "root"},
  {key: HOMEPAGE_SEARH_PARAMS.SORT_PARAMS, value: ""},
  {key: HOMEPAGE_SEARH_PARAMS.SEARCH_KEY, value: ""},
  {key: HOMEPAGE_SEARH_PARAMS.FAVORITE, value: ""},
  {key: HOMEPAGE_SEARH_PARAMS.MY_CONTENT, value: ""},
  {key: HOMEPAGE_SEARH_PARAMS.SHARED_WITH_ME, value: ""},
]