import { colorPalette, defaultColorPalette } from "../commons/chartOptions";
import { getItemPercent, legendPositionEnums } from "../commons/dashboardProperties";

export const RADAR_CHART_DEFAULT_CONFIGURATIONS = {
  RADAR_CHART_SHOW_VALUE_LABEL: true,
  RADAR_CHART_SHOW_ONLY: getItemPercent(),
  RADAR_CHART_SHOW_INSIGHT: true,
  RADAR_CHART_SHOW_SQL: false,
  RADAR_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
  RADAR_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
  RADAR_CHART_VALUE_LABEL_FONT_SIZE: 12,
  RADAR_CHART_VALUE_LABEL_FONT_COLOR: "#000",
  RADAR_CHART_SHOW_LEGEND: true,
  RADAR_CHART_LEGEND_MAX_CHARACTER: 15,
  RADAR_CHART_LEGEND_POSITION: legendPositionEnums.TOP_CENTER,
  RADAR_CHART_LEGEND_VERTICAL_ALIGN: "top",
  RADAR_CHART_LEGEND_FONT_FAMILY: "Poppins, sans-serif",
  RADAR_CHART_LEGEND_FONT_WEIGHT: 500,
  RADAR_CHART_LEGEND_FONT_SIZE: 12,
  RADAR_CHART_LEGEND_FONT_COLOR: "#000",
  RADAR_CHART_SHOW_AXIS_NAME: true,
  RADAR_CHART_AXIS_NAME_FONT_FAMILY: "Poppins, sans-serif",
  RADAR_CHART_AXIS_NAME_FONT_WEIGHT: 500,
  RADAR_CHART_AXIS_NAME_FONT_SIZE: 12,
  RADAR_CHART_AXIS_NAME_FONT_COLOR: "#000",
  RADAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS: true,
  RADAR_CHART_GENERAL_COLORS: colorPalette,
  RADAR_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
  RADAR_CHART_AUTO_REFRESH: 0,
  RADAR_CHART_AUTO_REFRESH_CUSTOM: null,
  RADAR_CHART_DYNAMIC_TITLE_VALUE: "",
  RADAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
  RADAR_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
  RADAR_CHART_GROUP_MULTIPLE_VALUES: true,
  RADAR_CHART_ALLOW_DRILL_THROUGH: true,
  RADAR_CHART_DRILL_THROUGH_DASHBOARD_LIST: [],
  RADAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK: false
};
