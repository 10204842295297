<template>
  <div v-if="isPanelPage">
    <div class="vis-height-100">
      <UploadImages
        ref="uploadComponent"
        :externalIcon="externalIcon"
        :panelLogosBox="true"
        @setSelectedImage="setSelectedImage"
      />
    </div>
  </div>
  <div v-else class="logo-container">
    <img
      class="vis-logo-image"
      :src="getLogo"
      :alt="activeLogo ? activeLogo.name : 'default-logo'"
      @click.stop="$emit('closePopoverMenu')"
    />
  </div>
</template>
<script>
import { defaultLogo } from "../../../commons/dashboardAndPanel";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import UploadImages from "../../settings/UploadImages.vue";
import cloneDeep from "clone-deep";

export default {
  components: {
    UploadImages,
  },
  props: {
    panelData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPanelPage: {
      type: Boolean,
      default: false,
    },
    externalIcon: {
      type: HTMLElement,
      default: () => null,
    },
  },
  mixins: [panelDataPropertiesStyle],
  data() {
    return {
      activeLogo: defaultLogo,
      defaultLogo: defaultLogo,
      servicePath: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  async mounted() {
    this.activeLogo =
      this.panelDataDetailsLogo?.length || this.panelDataDetailsLogo?.id
        ? this.panelDataDetailsLogo
        : defaultLogo;
  },
  computed: {
    getLogo() {
      let images = require.context("../../../assets/images/", false, /\.png$/);

      if (this.activeLogo?.id) {
        return `${this.servicePath}visualize/v1/images/${this.activeLogo?.id}`;
      } else {
        return images("./" + this.activeLogo + ".png");
      }
    },
  },
  methods: {
    setSelectedImage(image) {
      const selectedPanelCloned = cloneDeep(this.panelData);
      selectedPanelCloned.details.logo = image;
      this.updateSelectedPanel(selectedPanelCloned);
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    triggerUploadImageSortMenu() {
      if (this.$refs.uploadComponent?.$refs.sortPopover._data.showPopper) {
        this.$refs.uploadComponent.closePopover();
      } else {
        this.$refs.uploadComponent.openPopover();
      }
    },
  },
};
</script>
<style scoped>
#main {
  height: 400px;
  width: 600px;
}
.vis-logo-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>
