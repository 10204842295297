<template>
  <div>
    <div
      class="vis-vgt-card vis-dataModel-table-popup w700"
    >
      <div class="vis-vgt-card-title vis-flex--spacebeetwen vis-flex--Ycenter">
        <div v-if="!inFolder">
          <span class="vis-font-medium">
            {{ $t("generalPages.selectDataModel") }}
          </span>
        </div>
        <div v-else>
          <ul class="vis-user-tabs vis-flex--Ycenter">
            <li
              @click="getDatamodelsAndFoldersById('root', null)"
              class="vis-main-breadcrumb vis-ml--none"
            >
              {{ $t("home.all") }}
            </li>
            <div
              v-for="folder in folderNameList"
              :key="folder.id"
              class="vis-flex--Ycenter"
            >
              <li class="vis-main-breadcrumb">
                <i aria-hidden="true" :class="CustomIcon.ArrowRight"></i>
              </li>
              <li
                @click="getDatamodelsAndFoldersById(folder.id, folder.name)"
                :class="{ 'vis-font-medium': folderId == folder.id }"
                class="vis-main-breadcrumb"
              >
                {{ folder.name }}
              </li>
            </div>
          </ul>
        </div>
        <div class="vis-position-relative">
          <el-input
            v-model="searchDataValue"
            size="mini"
            :placeholder="$t('filterComponents.typeToSeach')"
            @keyup.enter.native="searchProjectList(HOMEPAGE_SEARH_PARAMS.SEARCH_KEY)"
          />
          <i 
            :class="CustomIcon.Search" 
            class="vis-input-icon" aria-hidden="true" 
            @click="searchProjectList(HOMEPAGE_SEARH_PARAMS.SEARCH_KEY)" 
          />
        </div>
      </div>
      <div class="vis-scroll-thin dataModel-select-popup" @scroll.stop="handleScroll">
        <div class="vis-vgt-card" v-loading="isHomepageSearchListLoading">
          <vue-good-table
            :columns="columns"
            :rows="mergeList"
            :row-style-class="rowStyleClassFn"
            :fixed-header="true"
            :search-options="{
              enabled: true,
              externalQuery: searchDataModel,
            }"
            @on-row-click="onRowClick"
            @on-row-dblclick="(item) => getDatamodelsAndFoldersById(item.row.id, item.row.name, item.row)"
          >
            <template slot="table-column" slot-scope="props">
              <span>
                {{ $t(`${props.column.label}`) }}
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <span
                v-if="props.column.field == CardFieldsTypeEnum.NAME"
                class="vis-cursor-pointer"
              >
                {{ props.row.name }}
              </span>
              <span v-else-if="props.column.field == CardFieldsTypeEnum.TYPEICON">
                <i :class="getIconByType(props.row)" aria-hidden="true"></i>
              </span>
              <span v-else-if="props.column.field == CardFieldsTypeEnum.CREATED_BY">
                <span>{{ props.row.created_by || props.row.createdBy }}</span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
      <div class="vis-vgt-card-footer vis-text--right">
        <el-button
          :disabled="!isNextButtonActive"
          @click="setDataModel()"
          type="primary"
          size="small"
          >{{ $t("generalPages.done") }}</el-button
        >
      </div>
    </div>
    <div class="vis-popup-mask" />
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { CustomIcon } from "../../assets/js/custom-icons";
import { CardFieldsTypeEnum, ObjectType} from "../../util/homePageMappers";
import { HOMEPAGE_SEARH_PARAMS, HOMEPAGE_SEARH_QUERY_DATA_LIST } from "../../commons/homeDataTypes";
import cloneDeep from "clone-deep";
import projectPopupSearch from "../../mixins/projectPopupSearch";
import { isSearchingWithDifferentKey } from "../../commons/helper";

export default {
  components: { VueGoodTable},
  mixins: [projectPopupSearch],
  props: {
    isHomepageSearchListLoading: {
      type: Boolean,
    },
    folderNameList: {
      type: Array,
    }
  },
  data() {
    return {
      columns: [
        {
          label: "",
          field: "typeIcon",
          width: "30px",
          sortable: false,
        },
        {
          label: "generalPages.labelName",
          field: "name",
        },
        {
          label: "generalPages.labelOwner",
          field: "created_by",
        },
      ],
      isNextButtonActive: false,
      searchDataModel: "",
      inFolder: false,
      folderId: null,
      CustomIcon: CustomIcon,
      CardFieldsTypeEnum: CardFieldsTypeEnum,
      selectedRow: "",
      datamodelObj: {},
      searchDataValue: "",
      dataModels: [],
      folders: [],
      mergeList: [],
      searchQueryData: {},
    };
  },
  async mounted() {
    this.searchQueryData = cloneDeep(HOMEPAGE_SEARH_QUERY_DATA_LIST);
    await this.getTotalList();
  },
  methods: {
    async getFolderList() {
      this.handleSearchQueryData({
        val: [ObjectType.FOLDER],
        type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE,
      });
      this.resetHomepageSearch();
      await this.getHomepageSearchResults();
      this.folders = [...this.folders, ...this.getSearchResults];
    },
    async getDatamodelList() {
      this.handleSearchQueryData({
        val: [ObjectType.DATAMODEL],
        type: HOMEPAGE_SEARH_PARAMS.OBJECT_TYPE,
      });
      this.resetHomepageSearch();
      await this.getHomepageSearchResults();
      this.dataModels = [...this.dataModels, ...this.getSearchResults];
    },
    async getTotalList() {
      if (this.folderId) {
        this.handleSearchQueryData({
          val: this.folderId,
          type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
        });
      }
      await this.getDatamodelList();
      await this.getFolderList();
      
      this.mergeList = [...this.dataModels, ...this.folders]
    },
    onRowClick(params) {
      this.selectedRow = params.row.originalIndex;
      if (params.row?.objectType === ObjectType.DATAMODEL) {
        this.datamodelObj = {
          id: params.row.id,
          name: params.row.name,
        };
        this.isNextButtonActive = true;
      } else {
        this.isNextButtonActive = false;
      }
    },
    rowStyleClassFn(row) {
      return row.originalIndex === this.selectedRow ? "vis-selectedRow" : "";
    },
    getIconByType(row) {
      return row.objectType === ObjectType.DATAMODEL
        ? "vis-icon icon-account_tree vis-color-success"
        : "vis-icon icon-folder vis-color-folder";
    },
    searchProjectList(type) {
      if (
        (this.searchDataValue.length > 2 ||
          this.searchDataValue.length === 0) &&
        isSearchingWithDifferentKey(this.searchDataValue, this.searchQueryData)
      ) {
        if (this.folderId === "root") {
          this.folderId = null;
        }
        
        this.resetHomepageSearchParameters();
        this.handleSearchQueryData({ val: this.searchDataValue, type: type });
        this.getTotalList();
      }
    },
    async getDatamodelsAndFoldersById(id, name, row) {
      if(row?.objectType !== ObjectType.DATAMODEL) {
        this.inFolder = id !== 'root' ?? false;
        this.$emit("checkBreadcrumb", id, name);
        this.folderId = id;
        this.resetHomepageSearchParameters()
        this.searchDataValue = "";
        this.$emit("setFolderId", id);
        await this.getTotalList();
      }
    },
    setDataModel() {
      this.$emit("setDataModel",  this.datamodelObj);
    },
  },
};
</script>
<style scoped>
.dataModel-select-popup {
  max-height: 600px;
}
.vis-dataModel-table-popup {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.vis-popup-mask {
  background: rgba(0, 0, 0, 0.007);
}
.vis-vgt-card-title {
  border-bottom: none;
  height: 48px;
}
</style>