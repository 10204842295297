import {
    chartTypes,
    detailsKeys,
  } from "../commons/dashboardAndPanel";
import { SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM } from "../commons/dashboardProperties";
import { checkTypeSelectorDataItem } from "../commons/dataModelTypes";

export const getPanelDataPropertiesStyle = (panelData, key) => {
    return panelData?.properties?.style?.[key]
}

export const createSmartTitle = (chartData) => {

    //FOR SELECTOR DATA ITEM CHART
    if (chartData?.details?.[detailsKeys.SELECTOR_PARAMETERS]) {
        return chartData?.properties?.style[SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME];
    }

    const { aggregation, metrics } = chartData.details;
    let title = "";

    //for table chart If metrics array empty will use aggregation list
    if ((!metrics || !metrics.length) && chartData.type !== chartTypes.TABLE) {
        return null;
    }
      
    title = metrics.filter(x=> x.isAuthorize && !checkTypeSelectorDataItem(x.type)).map(i => i.alias).join(', ');

    if (aggregation?.length > 0) {
        const aggAlias = aggregation.filter(x=> x.isAuthorize && !checkTypeSelectorDataItem(x.fieldType)).map(i => i.alias);
        if (title) title += ' by ' + aggAlias.join(', ');
        else title = aggAlias.join(', ')
    }

    return title;
}