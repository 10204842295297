<template>
  <div class="vis-height-100 vis-flex--Ycenter">
    <draggable
      id="menu-container"
      tag="ul"
      :disabled="isModeDashboardView"
      :list="tabList"
      @change="$emit('dashboardTabChanged')"
      class="list-group vis-dashboard-tab vis-height-100"
    >
      <li
        :class="[{ active: index == tabIndex }]"
        :style="tabsStyle(index)"
        v-for="(tabItem, index) in tabList"
        :key="tabItem.order"
        class="menu-container-item"
      >
        <el-tooltip
          placement="bottom"
          popper-class="vis-bg-neon-blue"
          :content="tabItem.name"
          :disabled="isTooltipDisabled"
        >
          <input
            :id="tabItem.name"
            @click="setTabIndex(index)"
            @focusout="readonlyInput = true"
            @keypress.enter="readonlyInput = true"
            @mouseover="controlThreeDot($event)"
            tabindex="-1"
            type="text"
            autocomplete="off"
            v-model="tabItem.name"
            :style="tabsFontStyle(index)"
            :readonly="readonlyInput"
          />
        </el-tooltip>
        <!-- ThreeDotIcon  START -->
        <el-dropdown
          trigger="click"
          placement="bottom"
          @command="handleCommand"
        >
          <i
            v-show="!isModeDashboardView"
            :class="CustomIcon.VerticalThreeDot"
            class="vis-cursor-pointer"
            @click="contextMenuItemOfTheSelectedTab(index, tabItem)"
            aria-hidden="true"
          ></i>

          <el-dropdown-menu class="vis-dropdown-menu">
            <el-dropdown-item
              v-for="popoverItem in popoverMenuItems"
              :key="popoverItem.id"
              :command="popoverItem"
            >
              <label class="vis-cursor-pointer" :for="tabItem.name">
                <i :class="popoverItem.icon" aria-hidden="true"></i>
                {{ $t(popoverItem.label) }}
              </label>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- ThreeDotIcon END -->
      </li>
      <li v-if="!isModeDashboardView" class="plusIcon menu-container-item">
        <i
          @click="addDashboardTab()"
          :class="CustomIcon.Plus"
          aria-hidden="true"
        ></i>
      </li>
    </draggable>

    <ArrowScroll
      ref="arrowScroll"
      containerId="menu-container"
      containerItemClassName="menu-container-item"
      :scrollWidth="210"
    />
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { CustomIcon } from "../../assets/js/custom-icons";
import {
  tabsPropertiesEnum,
  propertiesKeyToCSSKeyMapping,
} from "../../commons/dashboardProperties";
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import ArrowScroll from "../helper/ArrowScroll.vue";

export default {
  components: { draggable, ArrowScroll },
  props: {
    tabList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    selectedTab: {
      type: Array,
    },
    isModeDashboardView: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
    },
  },
  watch: {
    isModeDashboardView: {
      handler() {
        this.triggerScroll();
      },
    },
  },
  data() {
    return {
      readonlyInput: true,
      CustomIcon: CustomIcon,
      selectedTabItemValue: null,
      selectedTabItemIndex: null,
      selectedContextMenuItem: DatamodelContextDefaults.TAB_NAME,
      activeInputIndex: null,
      isTooltipDisabled: true,
    };
  },
  computed: {
    popoverMenuItems() {
      return [
        {
          id: 1,
          icon: CustomIcon.Pencil,
          label: "generalPages.editName",
          params: this.selectedTabItemIndex,
          serviceFn: (x) => this.editTabName(x),
          active: !this.isModeDashboardView,
        },
        {
          id: 2,
          icon: CustomIcon.Copy,
          label: "generalPages.duplicate",
          params: this.selectedTabItemValue,
          serviceFn: (x) => this.duplicateDashboardTab(x),
          active: !this.isModeDashboardView,
        },
        {
          id: 3,
          icon: CustomIcon.Trash,
          label: "generalPages.delete",
          params: this.selectedTabItemIndex,
          serviceFn: (x) => this.deleteDashboardTab(x),
          active: !this.isModeDashboardView,
        },
      ].filter((x) => x.active);
    },
  },
  methods: {
    controlThreeDot(event) {
      if (event.target.offsetWidth < event.target.scrollWidth) {
        this.isTooltipDisabled = false;
      } else {
        this.isTooltipDisabled = true;
      }
    },
    triggerScroll() {
      this.$refs["arrowScroll"].checkScrollArrowButtonAndSet();
    },
    triggerScrollToRight() {
      this.$refs["arrowScroll"].scrollToRight();
    },
    setTabIndex(index) {
      if (this.activeInputIndex != index) {
        this.readonlyInput = true;
      }
      this.$emit("setTabIndex", index);
    },
    deleteDashboardTab(index) {
      this.$emit("deleteDashboardTab", index);
      this.triggerScroll();
    },
    addDashboardTab() {
      this.$emit("addDashboardTab");
      this.triggerScrollToRight();
      this.triggerScroll();
    },
    duplicateDashboardTab(tabData) {
      this.$emit("duplicateDashboardTab", tabData);
      this.triggerScrollToRight();
      this.triggerScroll();
    },

    tabsFontStyle(index) {
      return {
        [propertiesKeyToCSSKeyMapping.TABS_FONT_FAMILY]:
          this.selectedTab[index]?.properties?.style[
            tabsPropertiesEnum.TABS_FONT_FAMILY
          ],
        [propertiesKeyToCSSKeyMapping.TABS_FONT_COLOR]:
          this.selectedTab[index]?.properties?.style[
            tabsPropertiesEnum.TABS_FONT_COLOR
          ],
      };
    },
    tabsStyle(index) {
      return {
        [propertiesKeyToCSSKeyMapping.TABS_BACKGROUND_COLOR]:
          this.selectedTab[index]?.properties?.style[
            tabsPropertiesEnum.TABS_BACKGROUND_COLOR
          ],
      };
    },
    editTabName(index) {
      this.activeInputIndex = index;
      return (this.readonlyInput = false);
    },
    handleCommand(command) {
      if (command) command.serviceFn(command.params);
    },
    contextMenuItemOfTheSelectedTab(index, item) {
      this.selectedTabItemValue = item;
      this.selectedTabItemIndex = index;
    },
  },
};
</script>

<style scoped>
#menu-container {
  scroll-behavior: smooth;
}
</style>
