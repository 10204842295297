import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";
import {
  createMetricKey,
  formatValueController,
  getConcatArrayWithPipe,
} from "../chartOptionsHelper";
import { purgeDuplicatesFromArray } from "../utils";

export const geoMapSearch = (params, searchData, chartI) => {
  const metricKeys = params?.metric?.map((a) => createMetricKey(a));
  const aggCityList = params?.aggregation?.filter((x) => x.boxKey === BOX_KEY_ENUM.CITY.KEY);
  const aggDistrictList = params?.aggregation?.filter((x) => x.boxKey === BOX_KEY_ENUM.DISTRICT.KEY)
  params.aggregation = [...aggCityList, ...aggDistrictList]
  const aggregationKeys = params?.aggregation?.map((a) => a.field);
  const aggregationFormats = params?.aggregation?.map((a) => a.format);
  const metricFormatsMapping = params?.metric?.reduce((accumulator, value) => {
    return { ...accumulator, [value.field]: value.format };
  }, {});
  const visualMapValues = {
    min: null,
    max: null,
  };
  let chars = {
    ç: "c",
    ğ: "g",
    ı: "i",
    ş: "s",
    ü: "u",
    ö: "o",
  };
  let bigChars = {};
  
  Object.keys(chars).forEach((key) => {
    bigChars[key.toUpperCase().replace("I", "İ")] = chars[key].toUpperCase();
  })

  chars = {...chars, ...bigChars}
  
  let metricFormats = [],
    dataSeries = [],
    dataSeriesNames = [];

  let xAxisKeys = searchData?.aggregations?.map((i) => {
    return getConcatArrayWithPipe(
      aggregationKeys.map((key, index) =>
        formatValueController(
          aggregationFormats[index],
          i?.[key]?.toLowerCase()?.replace(/[ç,ğ,ı,ş,ü,ö]/g, (m) => chars[m])
        )
      )
    );
  });

  xAxisKeys = purgeDuplicatesFromArray(xAxisKeys);
  dataSeriesNames = params?.metric?.map((e) => e.alias);

  //Map'in oluşturulma amacı backend'den gelen ana veriyi, ana verinin parse edilmiş hali ile backend'e gidecek isteklerde
  //tekrar kullanmak için.
  let mapPureLocName = new Map();
  
  dataSeries = searchData?.aggregations?.map((i) => {
    const value = i[metricKeys[0]];

    if (!visualMapValues.max || visualMapValues.max < value) {
      visualMapValues.max = value;
    }

    if (!visualMapValues.min || visualMapValues.min > value) {
      visualMapValues.min = value;
    }
    if (visualMapValues.max === visualMapValues.min) {
      visualMapValues.min = visualMapValues.min - 1;
    }
    let locName = "";
    let aggKey = "";
   
    aggregationKeys.forEach((aggKeyItem, index) => {
            if (index >= 1) {
        locName += " | ";
        aggKey += " | ";
      } else {
        locName += "";
        aggKey += "";
      }
      locName += i?.[aggKeyItem]
        ?.replace(/[Ç,Ğ,İ,Ş,Ü,Ö]/g, (m) => chars[m])
        .toLowerCase()
        .replace(/[ç,ğ,ı,ş,ü,ö]/g, (m) => chars[m]);

      aggKey += i[aggKeyItem];
    });
    
    mapPureLocName.set(locName, aggKey);

    return { name: locName, value };
  });
  
  metricFormats = params?.metric?.map((a) => a.format);

  let dataSeriesTotal = 0;
  if (dataSeries?.length) {
    Object.values(dataSeries)?.forEach((item) =>
      dataSeriesTotal += item.value
    );
  }
  
  return {
    metricFormats,
    metricFormatsMapping,
    dataSeries: [dataSeries],
    dataSeriesTotal,
    dataAxis: xAxisKeys,
    dataSeriesNames,
    aggregationFormats,
    visualMapValues,
    id: chartI,
    mapPureLocName,
    hasDistrictInAggregation: aggDistrictList?.length,
    hasCityInAggregation: aggCityList?.length
  };
};
