<template>
  <CommonFilterLayout>
    <template v-slot:title>
      <div class="vis-row">
        <div class="vis-col vis-col-12 vis-mb--none">
          <label class="vis-mb--1 vis-font-bold" for=""
            >{{ $t("filterComponents.type") }}
          </label>
          <el-radio-group class="vis-mb--1" v-model="selectedTabValue">
            <el-radio
              v-for="tab in filterTabList"
              :key="tab.value"
              :label="tab.value"
              @change="setTabValue()"
              >{{ $t(tab.label) }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>

      <div
        v-if="selectedComponent == filterPopupTabValue.SELECTION"
        class="vis-row"
      >
        <div class="vis-col vis-col-6 vis-mb--none">
          <el-checkbox
            v-if="showExcludeSelectedCheckbox"
            v-model="isExcludeSelectedChecked"
            class="exclude-selected-checkbox"
            >{{ $t("filterComponents.Exclude selected") }}
          </el-checkbox>
        </div>
        <div class="vis-col vis-col-6 vis-text--right vis-mb--none">
          <FilterOptions
            @inverseFilters="inverseFilters()"
            @clearSelectedFilters="clearSelectionList()"
          />
        </div>
      </div>
    </template>
    <template v-slot:content>
      <Selection
        v-if="selectedComponent == filterPopupTabValue.SELECTION"
        :selectionList="selectionFilterList"
        :isLoading="isLoading"
      />
      <Rule
        v-else-if="selectedComponent == filterPopupTabValue.RULE"
        :ruleFilterComponentList="ruleFilterComponentList"
        :selectedComponent="selectedComponent"
        :selectionList="selectionFilterList"
        @setRuleFormData="setRuleFormData"
        @addRuleFilterComponent="addRuleFilterComponent"
      />
    </template>
    <template v-if="showFooterActions" v-slot:footer>
      <div class="vis-row-box vis-mb--1">
        <div class="vis-line-1"></div>
      </div>
      <FilterButton
        @submitFilter="submitAttributeFilter"
        @closeFilters="closeFilters"
      />
    </template>
  </CommonFilterLayout>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ACTION as ACTION_TEST_QUERY } from "../../../store/modules/Visualize/TestQuery/types";
import CommonFilterLayout from "./helper/CommonFilterLayout.vue";
import FilterOptions from "./FilterOptions.vue";
import Selection from "./SelectionTab.vue";
import Rule from "./RuleTab.vue";
// import Advanced from "./AdvancedTab.vue";
import FilterButton from "./FilterButton.vue";
import { DatamodelContextDefaults } from "../../../commons/dataModelTypes";
import {
  filterTabList,
  filterPopupTabValue,
  filterObjectProperty,
  objectsIncludesIn,
  operator,
} from "./js/filters";
import cloneDeep from "clone-deep";
import { chartTypes } from "../../../commons/dashboardAndPanel";
import { filterOperator } from "../../../commons/filterComponents";
import { LoadingComponent } from "../../../store/modules/Visualize/General/loadingComponentDefinitions";
import { GETTER as GETTER_GENERAL } from "../../../store/modules/Visualize/General/types";

export default {
  components: {
    CommonFilterLayout,
    FilterOptions,
    Selection,
    Rule,
    // Advanced,
    FilterButton,
  },
  props: {
    attributeFilterSearchParam: {
      type: Object,
    },
    selectedComponent: {
      type: String,
    },
    filterDetailData: {
      type: Object,
    },
    customFilterParams: {
      type: Array,
      default: () => [],
    },
    showFooterActions: {
      type: Boolean,
      default: true,
    },
    showExcludeSelectedCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExcludeSelectedChecked: false,
      filterTabList: filterTabList,
      filterPopupTabValue: filterPopupTabValue,
      selectedTabValue: "",
      selectionFilterList: [],
      ruleFilterComponentList: [{ operator: "", value: "" }],
      ruleFilterFormData: {},
      filterQuery: "",
    };
  },
  watch: {
    "filterDetailData.operator": {
      handler(val) {
        if (val === operator.NOT_IN) {
          this.isExcludeSelectedChecked = true;
        }
      },
      immediate: true,
    },
    selectionFilterList: {
      handler() {
        if (!this.showFooterActions) {
          this.submitAttributeFilter();
        }
      },
      deep: true,
    },
    ruleFilterFormData: {
      handler() {
        if (!this.showFooterActions) {
          this.submitAttributeFilter();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.selectedTabValue = this.selectedComponent;

    await this.getSearch();

    await this.setDetailFilter();
  },
  created() {
    // parent'dan gelen event'i kullanabilmek için
    this.$parent.$on("clearAllFilter", this.clearSelectedFilters);
  },
  computed: {
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    isLoading() {
      return this.loading[LoadingComponent.GetSearch];
    },
    getByFieldPanelDataDetailsFilterItem() {
      return this.attributeFilterSearchParam?.fieldList?.find(
        (x) => x.field == this.filterDetailData?.field
      );
    },
    searchParams() {
      const { field, alias, fieldId, datasetId, fieldType } =
        this.getByFieldPanelDataDetailsFilterItem;

      return {
        filter: [],
        metric: [
          {
            field,
            type: DatamodelContextDefaults?.DISTINCT,
            alias,
            fieldId,
            datasetId,
            fieldType,
          },
        ],
        aggregation: [],
        params:
          this.customFilterParams?.length > 0
            ? this.customFilterParams.map((c) => {
                const operator = c.multi_select
                  ? filterOperator.IN
                  : filterOperator.EQ;
                let value = [];

                if (c.multi_select) {
                  value = c.localStorageValue.split(",");
                } else {
                  value = [c.localStorageValue];
                }

                return {
                  field: c.name,
                  value,
                  operator,
                };
              })
            : [],
        dataset: this.attributeFilterSearchParam.dataset,
        vismetadata: {
          vistype: chartTypes?.OTHER,
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchSearchWithoutMutate: ACTION_TEST_QUERY.FETCH_SEARCH_WITHOUT_MUTATE,
    }),

    async getSearch() {
      const response = await this.fetchSearchWithoutMutate({
        loadingComponent: LoadingComponent.GetSearch,
        bodyParam: this.searchParams,
      });
      this.selectionFilterList = response?.aggregations;
    },

    async setDetailFilter() {
      if (this.filterDetailData.popupTabName) {
        if (this.selectedComponent === filterPopupTabValue.SELECTION) {
          await this.setSelectionListDetailData();
        } else if (this.selectedComponent === filterPopupTabValue.RULE) {
          await this.setRuleByFormData();
        } else {
          //Here Code
        }
      }
    },
    async setSelectionListDetailData() {
      let clonedSimpleList = cloneDeep(this.selectionFilterList) ?? [];

      clonedSimpleList.forEach((element) => {
        this.filterDetailData.value.forEach((selectedFilterVal) => {
          if (element.value == selectedFilterVal) {
            this.$set(element, "value", selectedFilterVal);
            this.$set(element, filterObjectProperty.CHECKED, true);
          }
        });
      });

      this.selectionFilterList = clonedSimpleList;
    },
    async setRuleByFormData() {
      if (this.ruleFilterComponentList) {
        this.ruleFilterFormData = this.ruleFilterComponentList[0];
      }
      const clonedFilterDetailData = cloneDeep(this.filterDetailData);
      this.ruleFilterComponentList.forEach((element) => {
        let value = null;
        this.$set(
          element,
          filterObjectProperty.OPERATOR,
          clonedFilterDetailData.operator
        );
        if (
          Object.values(objectsIncludesIn).includes(
            clonedFilterDetailData.operator
          )
        ) {
          value = clonedFilterDetailData.value;
        } else {
          value = clonedFilterDetailData.value.toString();
        }
        this.$set(element, filterObjectProperty.VALUE, value);
      });
    },

    setRuleFormData(param) {
      this.ruleFilterFormData = param;
    },

    addRuleFilterComponent(param) {
      this.ruleFilterComponentList.push(param);
    },

    setFilterQueryData(param) {
      this.filterQuery = param;
    },
    setTabValue() {
      this.$emit("setTabValue", this.selectedTabValue);

      setTimeout(() => {
        if (!this.showFooterActions) {
          this.submitAttributeFilter();
        }
      }, 300);
    },

    submitAttributeFilter() {
      if (this.selectedComponent == filterPopupTabValue.SELECTION) {
        this.$emit(
          "saveFilterByAttribute",
          this.selectedComponent,
          this.selectionFilterList,
          this.isExcludeSelectedChecked
        );
      } else if (this.selectedComponent == filterPopupTabValue.RULE) {
        this.$emit(
          "saveFilterByAttribute",
          this.selectedComponent,
          this.ruleFilterFormData,
          this.isExcludeSelectedChecked
        );
      }
    },
    closeFilters(value) {
      this.$emit("closeFilters", value);
    },
    clearSelectionList() {
      this.selectionFilterList.forEach((element) => {
        this.$set(element, filterObjectProperty.CHECKED, false);
      });
    },
    clearSelectedFilters() {
      this.clearSelectionList();

      this.ruleFilterComponentList.forEach((element) => {
        this.$set(element, filterObjectProperty.OPERATOR, "");
        this.$set(element, filterObjectProperty.VALUE, "");
      });

      this.filterQuery = "";
    },

    inverseFilters() {
      this.selectionFilterList.forEach((element) => {
        if (element.checked)
          this.$set(element, filterObjectProperty.CHECKED, false);
        else this.$set(element, filterObjectProperty.CHECKED, true);
      });
    },
  },
};
</script>
<style scoped>
::v-deep .vis-filter-common-title {
  padding: 0 !important;
}
.exclude-selected-checkbox {
  display: inline-flex;
  align-items: center;
  height: 100%;
}
</style>
