import { COMPONENT_TYPES } from "./Enum";

export const dbGeneralPropertiesKeys = {
    name: "name",
    type: "type",
    connection: "connection",
    status: "status",
    columnCount: "columnCount",
    tableType: "tableType"
}
export const dbGeneralPropertiesMapper = (sqlViewVisible, { name, type, connection, status, columnCount, tableType }) => ([
    {
        key: dbGeneralPropertiesKeys.name,
        label: "generalPages.labelName",
        readonly: !sqlViewVisible,
        value: name,
        componentType: COMPONENT_TYPES.INPUT
    },
    {
        key: dbGeneralPropertiesKeys.type,
        label: "generalPages.labelType",
        readonly: true,
        value: type,
        componentType: COMPONENT_TYPES.INPUT
    },
    {
        key: dbGeneralPropertiesKeys.connection,
        label: "generalPages.labelConnection",
        readonly: true,
        value: connection,
        componentType: COMPONENT_TYPES.INPUT,
        style: "cursor: pointer;",
        clickable: true,
        tooltip: "datamodel.Change dataset connection",
    },
    {
        key: dbGeneralPropertiesKeys.status,
        label: "generalPages.labelStatus",
        value: status, //YOK
        componentType: COMPONENT_TYPES.TEXT
    },
    {
        key: dbGeneralPropertiesKeys.columnCount,
        label: "generalPages.labelColumnCount",
        readonly: true,
        value: columnCount,
        componentType: COMPONENT_TYPES.INPUT
    },
    {
        key: dbGeneralPropertiesKeys.tableType,
        label: "generalPages.labelTableType",
        readonly: !sqlViewVisible,
        value: tableType,
        componentType: COMPONENT_TYPES.SELECT_BOX
    },
]);
