<template>
  <div v-show="isActiveArrow">
    <div
      @click="scrollToLeft"
      class="vis-scroll-arrow vis-scroll-arrow-left"
      v-html="'<'"
    ></div>
    <div
      @click="scrollToRight"
      class="vis-scroll-arrow vis-scroll-arrow-right"
      v-html="'>'"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    containerId: {
      type: String,
      default: "",
    },
    containerItemClassName: {
      type: String,
      default: "",
    },
    triggerRightScroll: {
      type: Boolean,
      default: false,
    },
    scrollWidth: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      isActiveArrow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.checkScrollArrowButtonAndSet();
    }, 1000);
  },
  methods: {
    scrollToRight() {
      this.$nextTick(() => {
        let content = document.querySelectorAll(`#${this.containerId}`);
        content[0].scrollLeft += this.scrollWidth;
      });
    },
    scrollToLeft() {
      this.$nextTick(() => {
        let content = document.querySelectorAll(`#${this.containerId}`);
        content[0].scrollLeft -= this.scrollWidth;
      });
    },
    checkScrollArrowButtonAndSet() {
      this.$nextTick(() => {
        let itemTotalWidth = 0;

        let items = document.querySelectorAll(
          `#${this.containerId} .${this.containerItemClassName}`
        );

        items.forEach((element) => {
          const elementWidth = element.offsetWidth + element.offsetLeft;
          itemTotalWidth = elementWidth;
        });

        let content = document.querySelectorAll(`#${this.containerId}`);

        if (itemTotalWidth > content[0].offsetWidth) {
          this.isActiveArrow = true;
        } else {
          this.isActiveArrow = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.vis-scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 1.625rem;
  cursor: pointer;
  text-align: center;
  background-color: #ffffff;
}
.vis-scroll-arrow-left {
  left: 6px;
}
.vis-scroll-arrow-right {
  right: 0;
}
</style>
