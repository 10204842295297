<template>
  <ContextPopup>
    <template v-slot:title>
      <span
        >{{ $t("measures.title") }} -
        <span class="vis-color-primary">{{ field }}</span>
      </span>
      <div class="vis-text--right">
        <i
          @click="resetFormat"
          class="mdi mdi-refresh vis-cursor-pointer vis-mr--1"
          aria-hidden="true"
        ></i>
        <i
          @click="$emit('closeFormat', 0)"
          class="mdi mdi-close vis-cursor-pointer"
          aria-hidden="true"
        ></i>
      </div>
    </template>
    <template v-slot:content>
      <div class="vis-row">
        <div class="vis-col">
          <label class="vis-mb--1 vis-font-medium" for="">{{ $t("measures.type") }}</label>
          <el-radio-group class="vis-mb--2" v-model="format.type">
            <el-radio
              v-for="tab in measuresTabList"
              :key="tab.id"
              :label="tab.label"
              >{{ $t(tab.text) }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>

      <div class="vis-row">
        <div class="vis-col vis-col-6 vis-mb--2">
          <div class="vis-row-box">
            <label class="vis-mb--1 vis-font-medium" for="">{{
              $t("measures.thousand")
            }}</label>
            <el-radio-group v-model="format.thousandSeperator">
              <el-radio
                v-for="sep in thousandSeperatorList"
                :key="sep.value"
                :label="sep.value"
                >{{ sep.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="vis-col vis-col-6 vis-mb--2">
          <label class="vis-mb--1 vis-font-medium" for=""> {{ $t("measures.decimal") }}</label>
          <el-input-number
            controls-position="right"
            :min="0"
            :max="10"
            v-model="format.decimal"
          ></el-input-number>
        </div>
        <div
          class="vis-col vis-col-6 vis-mb--2"
          v-if="format.type == measureTypes.CURRENCY_UNIT"
        >
          <label class="vis-mb--1 vis-font-medium" for="">{{
            $t("measures.currencyLocation")
          }}</label>
          <el-radio-group v-model="format.currencyLocation">
            <el-radio
              v-for="cur in currencyLocationList"
              :key="cur.value"
              :label="cur.value"
              >{{ cur.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div
          class="vis-col vis-col-6 vis-mb--2"
          v-if="format.type == measureTypes.CURRENCY_UNIT"
        >
          <label class="vis-mb--1 vis-font-medium" for=""> {{ $t("measures.currency") }}</label>
          <el-select v-model="format.currency" placeholder="Select">
            <el-option
              v-for="item in currencyList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="vis-col vis-col-6 vis-mb--2"
          v-if="format.type != measureTypes.RATIO"
        >
          <label class="vis-mb--1 vis-font-medium" for=""> {{ $t("measures.scale") }}</label>
          <el-select v-model="format.scale" placeholder="Select">
            <el-option
              v-for="item in scaleList"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div 
            class="vis-display-flex vis-col vis-col-6"
            v-if="format.type == measureTypes.NUMBER"  
        >
          <label class="vis-mb--1 vis-font-medium vis-mt--2_5" for=""> {{ $t("measures.Show Scale Unit") }}</label>
          <el-switch 
            v-model="format.showScaleUnit"
            class="vis-mt--2_5 vis-ml--auto"
          ></el-switch>
        </div>
        <div
          class="vis-col vis-col-6 vis-mb--2"
          v-if="format.type == measureTypes.NUMBER" 
        >
          <label class="vis-mb--1 vis-font-medium" for=""> {{ $t("measures.Prefix") }}</label>
          <el-input v-model="format.prefix"></el-input>
        </div>
        <div
          class="vis-col vis-col-6 vis-mb--2"
          v-if="format.type == measureTypes.NUMBER" 
        >
          <label class="vis-mb--1 vis-font-medium" for=""> {{ $t("measures.Suffix") }}</label>
          <el-input v-model="format.suffix"></el-input>
        </div>
        <div v-if="format.type == measureTypes.RATIO" class="vis-col vis-col-6 vis-mb--2">
          <label class="vis-mb--1 vis-font-medium" for="">{{
            $t("measures.ratioSignLocation")
          }}</label>
          <el-radio-group v-model="format.ratioSignLocation">
            <el-radio
              v-for="ratio in ratioSignLocationList"
              :key="ratio.value"
              :label="ratio.value"
              >{{ ratio.label }}</el-radio
            >
          </el-radio-group>
        </div>
        
      </div>
      <div class="vis-col vis-text--right">
        <el-button size="small" @click="$emit('closeFormat', null)" plain>
          {{ $t("generalPages.cancel") }}</el-button
        >
        <el-button size="small" type="primary" @click="saveFormat">
          {{ $t("generalPages.apply") }}</el-button
        >
      </div>
    </template>
  </ContextPopup>
</template>
<script>
import cloneDeep from "clone-deep";
import ContextPopup from "../ContextPopup.vue";
import {
  measureTypes,
  measuresTabList,
  defaultNumberFormat,
  scaleList,
  thousandSeperatorList,
  ratioSignLocationList,
  currencyLocationList,
  currencyList,
} from "./js/measures";

export default {
  components: {
    ContextPopup,
  },
  props: {
    selectedFormat: {
      type: Object,
    },
    field: {
      type: String,
    },
  },
  mounted() {
    this.updateDefault();
  },
  data() {
    return {
      measureTypes: measureTypes,
      measuresTabList: measuresTabList,
      scaleList: scaleList,
      thousandSeperatorList: thousandSeperatorList,
      ratioSignLocationList: ratioSignLocationList,
      currencyLocationList: currencyLocationList,
      currencyList: currencyList,
      format: {},
    };
  },
  methods: {
    saveFormat() {
      this.$emit("saveFormat", this.format);
    },
    resetFormat() {
      this.$emit("saveFormat", {});
    },
    updateDefault() {
      // Her zaman formatı sıfırlayarak başla
      this.format = cloneDeep(defaultNumberFormat);

      if (Object.keys(this.selectedFormat)?.length) {
        // selectedFormat varsa onunla formatı güncelle
        Object.assign(this.format, this.selectedFormat);
      }
    },
  },
};
</script>
<style>
</style>
