import { createMetricKey, getConcatArrayWithPipe, getDataSeriesNames, mapAxisItems } from "../chartOptionsHelper";
import { groupBy } from "lodash";
import { DatamodelContextDefaults} from "../../commons/dataModelTypes";
import { AXIS_TYPES_ENUM } from "../../commons/dashboardProperties";
import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";

export const bubbleSearch = (params, searchData, chartI) => {
  const groups = groupBy(
    [...params.aggregation, ...params.metric],
    (val) => val.boxKey
  );
  const aggregationFormats = params?.aggregation?.map((a) => a.format);
  const isYAxisMetric = groups?.[BOX_KEY_ENUM.YAXIS.KEY]?.every(
    (i) => i.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
  );
  const isXAxisMetric = groups?.[BOX_KEY_ENUM.XAXIS.KEY]?.every(
    (i) => i.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
  );
  const metricFormatsMapping = params?.metric?.reduce((accumulator, value) => {
    return { ...accumulator, [value.field]: value.format };
  }, {});
  
  const xAxisItems = groups?.[BOX_KEY_ENUM.XAXIS.KEY]?.map(mapAxisItems),
        yAxisItems = groups?.[BOX_KEY_ENUM.YAXIS.KEY]?.map(mapAxisItems),
        pointItems = groups?.[BOX_KEY_ENUM.POINTS.KEY]?.map(mapAxisItems),
        colorByItems = groups[BOX_KEY_ENUM.COLORBY.KEY]?.map(mapAxisItems),
        xAxisName = getConcatArrayWithPipe(xAxisItems?.map(i => i.alias)),
        yAxisName = getConcatArrayWithPipe(yAxisItems?.map(i => i.alias)),
        pointName = getConcatArrayWithPipe(pointItems?.map(i => i.alias)),
        yAxisData = new Set(),
        xAxisData = new Set(),
        pointSizes = new Set(),
        pointSizeKey = createMetricKey(groups?.[BOX_KEY_ENUM.POINT_SIZE.KEY]?.[0]),
        // dimensions is used when highlighting clicked node on the chart. 
        // we need the exact index during highlighting so null values also play an important role and should not be removed.
        dimensionNames = [
          isXAxisMetric ? null : xAxisName, 
          isYAxisMetric ? null : yAxisName, 
          pointName
        ];

  let dataSeries = {};
  let dataSeriesNames = [];

  searchData.aggregations.forEach((i) => {
    const xAxisValue =
      getConcatArrayWithPipe(xAxisItems?.map((item) => i[item.field])) || "";
    const yAxisValue =
      getConcatArrayWithPipe(yAxisItems?.map((item) =>i[item.field])) || "";
    const point =
      getConcatArrayWithPipe(pointItems?.map((item) =>  i[item.field]))|| "";
    const colorBy =
      getConcatArrayWithPipe(colorByItems?.map((item) =>  i[item.field])) || "";
    yAxisData.add(yAxisValue);
    xAxisData.add(xAxisValue);

    const x = isXAxisMetric ? i[createMetricKey(groups[BOX_KEY_ENUM.XAXIS.KEY]?.[0])] : xAxisValue,
          y = isYAxisMetric ? i[createMetricKey(groups[BOX_KEY_ENUM.YAXIS.KEY]?.[0])] : yAxisValue,
          pointKey = point,
          pointSize = i[pointSizeKey],
          result = [x, y, pointKey, pointSize, colorBy]
          
    i[pointSizeKey] && pointSizes.add(i[pointSizeKey]);

    const objKey = JSON.stringify(x + y + pointKey + pointSize + colorBy);

    if (!dataSeries[objKey]) {
      dataSeries[objKey] = result;
    }
    getDataSeriesNames(colorBy, point, dataSeriesNames);
  });

  if (colorByItems?.length) {
    dataSeries = groupBy(dataSeries, (val) => val[val.length - 1]);
  } else {
    // metric yok ise legend da ne yazılacağı henuz netleştirilmedi. Netleştirildiğinde burası düzenlenmeli.
    dataSeries = groupBy(dataSeries, (val) => val[val.length -2]);
  }

  const axisData = {
    x: {
      name: xAxisName,
      type: isXAxisMetric ? AXIS_TYPES_ENUM.VALUE : AXIS_TYPES_ENUM.CATEGORY,
      data: [...xAxisData]
    },
    y: {
      name: yAxisName,
      type: isYAxisMetric ? AXIS_TYPES_ENUM.VALUE : AXIS_TYPES_ENUM.CATEGORY,
      data: [...yAxisData]
    }
  };

  const formatMap = {
    x: [...params.metric, ...params.aggregation]
      .filter((i) => i.boxKey === BOX_KEY_ENUM.XAXIS.KEY)
      .map((i) => i.format),
    y: [...params.metric, ...params.aggregation]
      .filter((i) => i.boxKey === BOX_KEY_ENUM.YAXIS.KEY)
      .map((i) => i.format),
    pointSize: [...params.metric, ...params.aggregation]
      .filter((i) => i.boxKey === BOX_KEY_ENUM.POINT_SIZE.KEY)
      .map((i) => i.format),
  };

  const visualMap = {};

  if (pointSizes.size > 0) {
    visualMap.min = Math.min(...pointSizes);
    visualMap.max = Math.max(...pointSizes);
  }

  let dataSeriesTotal = [0, 0, 0];
  if (Object.keys(dataSeries).length) {
    Object.values(dataSeries)?.forEach((item) =>
      item?.forEach((y) => {
        dataSeriesTotal[0] += y[0]; // x eksenindeki değerler toplamını döner
        dataSeriesTotal[1] += y[1]; // y eksenindeki değerler toplamını döner
        dataSeriesTotal[2] += y[3]; // pointsize değerler toplamını döner
      })
    );
  }

  return {
    dataSeries,
    dataSeriesTotal,
    dataSeriesNames,
    axisData,
    id: chartI,
    dimensionNames,
    visualMap,
    formatMap,
    metricFormatsMapping,
    aggregationFormats
  };
};

