import { AXIS_TYPES_ENUM, Orientation, SCATTER_CHART_PROPERTIES_ENUM, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { SCATTER_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/scatterChartDefaultProperties";
import { getScatterAndHeatmapCommonStyle } from "../chart-filter/filterChartStyles";
import { calculateGridDistances, generateHTMLForScatterTooltip, getSelectedColorPaletteByProperty, isAxisLocationDefault, isAxisNameActive, isNameLocationDefault, formatValueAxisLabels, truncateText, setShowOnlyValue, getLabelPositionByRotate, aggregationDateFormatController, colorConverter, sliderPositionHandler, xAxisWidthHandler} from "../chartOptionsHelper";
import { getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";
export const getScatterChartOptions = (scatterChartComputedValues, paramsFromSearch, panelData) => {
    const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
        yAxisPosition: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_POSITION,
        showYAxisLabels: scatterChartComputedValues.SCATTER_CHART_SHOW_Y_AXIS_LABEL,
        showXAxisLabels: scatterChartComputedValues.SCATTER_CHART_SHOW_X_AXIS_LABEL,
        showLegend: scatterChartComputedValues.SCATTER_CHART_SHOW_LEGEND,
        labelRotate: scatterChartComputedValues.SCATTER_CHART_VALUE_LABEL_ROTATE,
        showLabel: scatterChartComputedValues.SCATTER_CHART_SHOW_VALUE_LABEL,
        legendPosition: scatterChartComputedValues.SCATTER_CHART_LEGEND_POSITION,
        legendMaxCharacter: scatterChartComputedValues.SCATTER_CHART_LEGEND_MAX_CHARACTER,
        yAxisNameLocation: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_NAME_LOCATION,
        showOnly: scatterChartComputedValues.SCATTER_CHART_SHOW_ONLY,
        xAxisDataZoom: scatterChartComputedValues.SCATTER_CHART_X_AXIS_SHOW_DATA_ZOOM,
        xAxisRotate: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_ROTATE,
        yAxisDataZoom: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_SHOW_DATA_ZOOM,
        panelData
    });
    const optionObject = {
        grid: isChartWidthLimited(panelData.w) ?
            {
              top: gridTop + 20,
              bottom: gridBottom,
              left: gridLeft,
              right: gridRight + 30,
              containLabel: true
            } :
            undefined,
        title: {
            text: "",
        },
        legend: {
          show: isChartWidthLimited(panelData.w) ? scatterChartComputedValues.SCATTER_CHART_SHOW_LEGEND : false,
          orient: getOrientPositionByLegend(
            scatterChartComputedValues.SCATTER_CHART_LEGEND_POSITION
          ).toLowerCase(),
          left: getAlignPositionByLegend(
            scatterChartComputedValues.SCATTER_CHART_LEGEND_POSITION
          ),
          top: getOrientPositionByLegend(
            scatterChartComputedValues.SCATTER_CHART_LEGEND_POSITION
          ) === Orientation.VERTICAL
          ? "4%"
          : scatterChartComputedValues.SCATTER_CHART_LEGEND_VERTICAL_ALIGN,
          textStyle: {
            fontWeight: scatterChartComputedValues.SCATTER_CHART_LEGEND_FONT_WEIGHT,
            fontFamily: scatterChartComputedValues.SCATTER_CHART_LEGEND_FONT_FAMILY,
            fontSize: scatterChartComputedValues.SCATTER_CHART_LEGEND_FONT_SIZE,
            color: scatterChartComputedValues.SCATTER_CHART_LEGEND_FONT_COLOR,
          },
          backgroundColor: "transparent",
          formatter: function (value) {
            return truncateText(
              aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats),
              scatterChartComputedValues.SCATTER_CHART_LEGEND_MAX_CHARACTER
            );
          },
          type: "scroll",
        },
        tooltip: {
            show: true,
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: {
                fontFamily: 'Poppins',
                fontSize: 12
            },
            formatter: function (value) {
                const [x, y, name, seriesName] = value?.value;
                
                value.name = aggregationDateFormatController(name, paramsFromSearch?.aggregationFormats); // Points alanındaki objenin değerini çeker
                value.seriesName = aggregationDateFormatController(seriesName, paramsFromSearch?.aggregationFormats); // ColorBy alanındaki objenin değerini çeker

                if (
                  showOnlyTypesExceptOnlyItem.includes(
                    scatterChartComputedValues.SCATTER_CHART_SHOW_ONLY
                  )
                ) {
                  value.value = [x, y];
                  return setShowOnlyValue(
                    value,
                    paramsFromSearch,
                    scatterChartComputedValues,
                    SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_ONLY,
                    dashboardSPEnums.TOOLTIP,
                    panelData
                  );
                } else {
                  return generateHTMLForScatterTooltip(
                    value,
                    paramsFromSearch?.formatMap,
                    panelData?.details?.aggregation,
                    panelData?.details?.metrics
                  );
                }
              },
        },
        yAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartWidthLimited(panelData.w) ? scatterChartComputedValues.SCATTER_CHART_SHOW_Y_AXIS_LABEL : false,
                fontWeight: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                fontSize: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE,
                color: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR,
                formatter: function (value) {
                    if(typeof value === 'number') return formatValueAxisLabels(paramsFromSearch?.formatMap?.y, value);
                    else return aggregationDateFormatController(value, paramsFromSearch?.formatMap?.y);
                },
                hideOverlap: true,
            },
            type: paramsFromSearch?.axisData?.y?.type !== AXIS_TYPES_ENUM.VALUE ?
                paramsFromSearch?.axisData?.y?.type :
                scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE ?
                    AXIS_TYPES_ENUM.LOG :
                    paramsFromSearch?.axisData?.y?.type,
            position: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_POSITION,
            axisLine: {
                show: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_SHOW_LINE,
            },
            data: paramsFromSearch?.axisData?.y?.data ?? [],
            axisTick: {
                show: false
            },
            splitLine: {
                show: true
            },
            boundaryGap: false,
            min: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_MIN,
        },
        xAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartHeightLimited(panelData.h) ? scatterChartComputedValues.SCATTER_CHART_SHOW_X_AXIS_LABEL : false,
                align: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                verticalAlign: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                fontWeight: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY,
                fontSize: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE,
                color: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR,
                rotate: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_ROTATE,
                overflow: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_WRAP ? 'break' : 'none',
                width: xAxisWidthHandler(scatterChartComputedValues.SCATTER_CHART_X_AXIS_LABEL_WRAP),
                formatter: function (value) {
                  if (typeof value === 'number') {
                    return formatValueAxisLabels(paramsFromSearch?.formatMap?.x, value);
                  } else {
                    return aggregationDateFormatController(value, paramsFromSearch?.formatMap?.x);
                  }
                },
                hideOverlap: true,
            },
            axisTick: {
                show: false
            },
            data: paramsFromSearch?.axisData?.x?.data ?? [],
            type: paramsFromSearch?.axisData?.x?.type !== AXIS_TYPES_ENUM.VALUE ?
                paramsFromSearch?.axisData?.x?.type :
                scatterChartComputedValues.SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE ?
                    AXIS_TYPES_ENUM.LOG :
                    paramsFromSearch?.axisData?.x?.type,
            axisLine: {
                show: true,
            },
            splitLine: {
                show: true
            },
            boundaryGap: false,
            min: scatterChartComputedValues.SCATTER_CHART_X_AXIS_MIN,
        },
        dataZoom: Object.keys(paramsFromSearch).length
        ? [
            {
              type: 'slider',
              show: scatterChartComputedValues.SCATTER_CHART_X_AXIS_SHOW_DATA_ZOOM,
              start: scatterChartComputedValues.SCATTER_CHART_X_AXIS_DATA_ZOOM_START,
              end: scatterChartComputedValues.SCATTER_CHART_X_AXIS_DATA_ZOOM_END,
              zoomLock: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !scatterChartComputedValues.SCATTER_CHART_X_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(scatterChartComputedValues.SCATTER_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
                SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(scatterChartComputedValues.SCATTER_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
                SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: scatterChartComputedValues.SCATTER_CHART_X_AXIS_DATA_ZOOM_PALETTE
                }
              },
              xAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !scatterChartComputedValues.SCATTER_CHART_X_AXIS_SHOW_DATA_ZOOM,
              zoomLock: scatterChartComputedValues.SCATTER_CHART_X_AXIS_LOCK_DATA_ZOOM,
              xAxisIndex: [0],
            },
            {
              type: 'slider',
              show: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_SHOW_DATA_ZOOM,
              left:  sliderPositionHandler(scatterChartComputedValues.SCATTER_CHART_SHOW_LEGEND,
                scatterChartComputedValues.SCATTER_CHART_LEGEND_POSITION),
              start: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_DATA_ZOOM_START,
              end: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_DATA_ZOOM_END,
              zoomLock: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(scatterChartComputedValues.SCATTER_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(scatterChartComputedValues.SCATTER_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                SCATTER_CHART_DEFAULT_CONFIGURATIONS.SCATTER_CHART_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_DATA_ZOOM_PALETTE
                }
              },
              yAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !scatterChartComputedValues.SCATTER_CHART_Y_AXIS_SHOW_DATA_ZOOM,
              zoomLock: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              yAxisIndex: [0],
            },
          ]
        : [],
        series: paramsFromSearch?.dataSeries
        ? Object?.values(paramsFromSearch.dataSeries)?.map((key, i) => {
          return {
            data: key?.map((a) => {
              return {
                value: a,
                itemStyle: getScatterAndHeatmapCommonStyle(
                  a,
                  panelData,
                  paramsFromSearch?.dimensionNames
                ),
              };
            }),
            type: "scatter",
            dimensions: paramsFromSearch?.dimensionNames,
            name: paramsFromSearch?.dataSeriesNames?.[i],
            symbolSize: scatterChartComputedValues.SCATTER_CHART_SYMBOL_SIZE,
            label: {
              show: isChartWidthLimited(panelData.w)
                ? scatterChartComputedValues.SCATTER_CHART_SHOW_VALUE_LABEL
                : false,
              position: getLabelPositionByRotate(
                scatterChartComputedValues.SCATTER_CHART_VALUE_LABEL_ROTATE
              ),
              rotate:
                scatterChartComputedValues.SCATTER_CHART_VALUE_LABEL_ROTATE,
              fontSize:
                scatterChartComputedValues.SCATTER_CHART_VALUE_LABEL_FONT_SIZE,
              fontFamily:
                scatterChartComputedValues.SCATTER_CHART_VALUE_LABEL_FONT_FAMILY,
              fontWeight:
                scatterChartComputedValues.SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT,
              color:
                scatterChartComputedValues.SCATTER_CHART_VALUE_LABEL_FONT_COLOR,
              formatter: function (info) {
                const [x, y, name] = info?.value;

                info.name = aggregationDateFormatController(name, paramsFromSearch?.aggregationFormats);
                info.value = [x, y];

                return showOnlyTypesExceptOnlyItem.includes(
                  scatterChartComputedValues.SCATTER_CHART_SHOW_ONLY
                )
                  ? setShowOnlyValue(
                      info,
                      paramsFromSearch,
                      scatterChartComputedValues,
                      SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_ONLY,
                      dashboardSPEnums.LABEL,
                      panelData
                    )
                  : info?.name;
              },
            },
            labelLayout: {
              hideOverlap:
                scatterChartComputedValues.SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
            },
          };
        })
      : null,
        color: getSelectedColorPaletteByProperty(scatterChartComputedValues.SCATTER_CHART_SELECTED_COLOR_PALETTE),
    }

    return {
        options: optionObject,
        yAxis: {
            name: isAxisNameActive(paramsFromSearch, paramsFromSearch?.axisData?.y?.name),
            show: isChartWidthLimited(panelData.w) ? scatterChartComputedValues.SCATTER_CHART_SHOW_Y_AXIS_NAME : false,
            fontFamily: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY,
            fontSize: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE,
            fontWeight: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT,
            fontColor: scatterChartComputedValues.SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR,
            isAxisLocationDefault: isAxisLocationDefault(scatterChartComputedValues.SCATTER_CHART_Y_AXIS_POSITION),
            isNameLocationDefault: isNameLocationDefault(scatterChartComputedValues.SCATTER_CHART_Y_AXIS_NAME_LOCATION)
        },
        xAxis: {
            name: isAxisNameActive(paramsFromSearch, paramsFromSearch?.axisData?.x?.name),
            show: isChartHeightLimited(panelData.h) ? scatterChartComputedValues.SCATTER_CHART_SHOW_X_AXIS_NAME : false,
            fontFamily: scatterChartComputedValues.SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY,
            fontSize: scatterChartComputedValues.SCATTER_CHART_X_AXIS_NAME_FONT_SIZE,
            fontWeight: scatterChartComputedValues.SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT,
            fontColor: scatterChartComputedValues.SCATTER_CHART_X_AXIS_NAME_FONT_COLOR,
        }
    }
}