import { calculateGridDistances, formatValueController, getSelectedColorPaletteByProperty, getChartLabelFormatByAggregation, isAxisLocationDefault, isAxisNameActive, isNameLocationDefault, setShowOnlyValue, colorConverter, xAxisWidthHandler} from "../chartOptionsHelper";
import { getScatterAndHeatmapCommonStyle } from "../chart-filter/filterChartStyles";
import { getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";
import { HEATMAP_PROPERTIES_ENUM, Orientation, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { HEATMAP_DEFAULT_CONFIGURATIONS } from "../../mocks/heatmapDefaultProperties";
export const getHeatmapOptions = (heatmapComputedValues, paramsFromSearch, panelData) => {

    const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
        yAxisPosition: heatmapComputedValues.HEATMAP_Y_AXIS_POSITION,
        showYAxisLabels: heatmapComputedValues.HEATMAP_SHOW_Y_AXIS_LABEL,
        showXAxisLabels: heatmapComputedValues.HEATMAP_SHOW_X_AXIS_LABEL,
        showLegend: heatmapComputedValues.HEATMAP_SHOW_VISUALMAP,
        legendPosition: heatmapComputedValues.HEATMAP_VISUALMAP_POSITION,
        legendMaxCharacter: heatmapComputedValues.HEATMAP_VISUALMAP_MAX_CHARACTER,
        yAxisNameLocation: heatmapComputedValues.HEATMAP_Y_AXIS_NAME_LOCATION,
        maxGridTopValue: 60,
        xAxisDataZoom: heatmapComputedValues.HEATMAP_X_AXIS_SHOW_DATA_ZOOM,
        xAxisRotate: heatmapComputedValues.HEATMAP_X_AXIS_LABEL_ROTATE,
        yAxisDataZoom: heatmapComputedValues.HEATMAP_Y_AXIS_SHOW_DATA_ZOOM,
        panelData
    });
    const optionObject = {
        grid:
        {
            top: gridTop,
            bottom: gridBottom,
            left: gridLeft,
            right: gridRight,
            height: isChartHeightLimited(panelData.h) && heatmapComputedValues.HEATMAP_X_AXIS_SHOW_DATA_ZOOM ? '80%' : '100%',
            containLabel: true
        },
        tooltip: {
            show: true,
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: {
                fontFamily: 'Poppins',
                fontSize: 12
            },
            formatter: function (info) {
                const nameList = info.value.filter((i) => typeof i === 'string').reverse().join(" | ");

                info.name =  getChartLabelFormatByAggregation(nameList, paramsFromSearch?.aggregationFormats)
                info.value = info.value[2]; // value array'inin içerisinden Numeric değeri çeker.
                
                return showOnlyTypesExceptOnlyItem.includes(heatmapComputedValues.HEATMAP_SHOW_ONLY) ?
                setShowOnlyValue(
                    info,
                    paramsFromSearch,
                    heatmapComputedValues,
                    HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_ONLY,
                    dashboardSPEnums.TOOLTIP,
                    panelData
                )
                :
                info.name;
            },

        },
        xAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartHeightLimited(panelData.h) ? heatmapComputedValues.HEATMAP_SHOW_X_AXIS_LABEL : false,
                fontWeight: heatmapComputedValues.HEATMAP_X_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: heatmapComputedValues.HEATMAP_X_AXIS_LABEL_FONT_FAMILY,
                fontSize: heatmapComputedValues.HEATMAP_X_AXIS_LABEL_FONT_SIZE,
                color: heatmapComputedValues.HEATMAP_X_AXIS_LABEL_FONT_COLOR,
                rotate: heatmapComputedValues.HEATMAP_X_AXIS_LABEL_ROTATE,
                overflow: heatmapComputedValues.HEATMAP_X_AXIS_LABEL_WRAP ? 'break' : 'none',
                width: xAxisWidthHandler(heatmapComputedValues.HEATMAP_X_AXIS_LABEL_WRAP),
                formatter: function (value) {
                    return getChartLabelFormatByAggregation(value, paramsFromSearch?.formatMap?.x);
                },
                hideOverlap: true,
            },
            type: 'category',
            axisTick: {
                show: false
            },
            data: paramsFromSearch?.xAxisData ?? [],
            axisLine: {
                show: heatmapComputedValues.HEATMAP_SHOW_X_AXIS_LINE,
            },
            splitArea: {
                show: true
            }
        },
        yAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartWidthLimited(panelData.w) ? heatmapComputedValues.HEATMAP_SHOW_Y_AXIS_LABEL : false,
                fontWeight: heatmapComputedValues.HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: heatmapComputedValues.HEATMAP_Y_AXIS_LABEL_FONT_FAMILY,
                fontSize: heatmapComputedValues.HEATMAP_Y_AXIS_LABEL_FONT_SIZE,
                color: heatmapComputedValues.HEATMAP_Y_AXIS_LABEL_FONT_COLOR,
                formatter: function (value) {
                    return getChartLabelFormatByAggregation(value, paramsFromSearch?.formatMap?.y);
                },
                hideOverlap: true,
            },
            type: "category",
            data: [].concat(paramsFromSearch?.yAxisData).reverse(),
            position: heatmapComputedValues.HEATMAP_Y_AXIS_POSITION,
            axisLine: {
                show: heatmapComputedValues.HEATMAP_Y_AXIS_SHOW_LINE,
                lineStyle: {
                    color: heatmapComputedValues.HEATMAP_Y_AXIS_LINE_STYLE_COLOR
                }
            },
            axisTick: {
                show: false
            },
            splitArea: {
                show: true
            }
        },
        visualMap: {
            show: isChartWidthLimited(panelData.w) && paramsFromSearch?.dataSeries?.length ? heatmapComputedValues.HEATMAP_SHOW_VISUALMAP : false,
            type: heatmapComputedValues.HEATMAP_VISUALMAP_TYPE,
            color: getSelectedColorPaletteByProperty(heatmapComputedValues.HEATMAP_SELECTED_COLOR_PALETTE),
            min: 0,
            max: paramsFromSearch?.maxValue || 10,
            calculable: true,
            orient: getOrientPositionByLegend(heatmapComputedValues.HEATMAP_VISUALMAP_POSITION).toLowerCase(),
            padding: [0, 0, 10, 0],
            left:  getAlignPositionByLegend(heatmapComputedValues.HEATMAP_VISUALMAP_POSITION),
            top: getOrientPositionByLegend(heatmapComputedValues.HEATMAP_VISUALMAP_POSITION) === Orientation.VERTICAL ? "5%" : heatmapComputedValues.HEATMAP_VISUALMAP_VERTICAL_ALIGN,
            itemWidth: 12,
            textStyle: {
                fontWeight: heatmapComputedValues.HEATMAP_VISUALMAP_FONT_WEIGHT,
                fontSize: heatmapComputedValues.HEATMAP_VISUALMAP_FONT_SIZE,
                fontFamily: heatmapComputedValues.HEATMAP_VISUALMAP_FONT_FAMILY,
                color: heatmapComputedValues.HEATMAP_VISUALMAP_FONT_COLOR,
            },
            formatter: function (value, value2) {
                // if the type of visualmap set to 'piecewise', value2 will be defined

                const serieName = paramsFromSearch?.metricFormatsMapping?.[paramsFromSearch?.dataSeriesNames?.[0]];
                if (value2) {
                    return formatValueController(serieName, value) + ' - ' + formatValueController(serieName, value2)
                }

                return formatValueController(serieName, value)
            }
        },
        dataZoom: Object.keys(paramsFromSearch).length
        ? [
            {
              type: 'slider',
              show: heatmapComputedValues.HEATMAP_X_AXIS_SHOW_DATA_ZOOM,
              start: heatmapComputedValues.HEATMAP_X_AXIS_DATA_ZOOM_START,
              end: heatmapComputedValues.HEATMAP_X_AXIS_DATA_ZOOM_END,
              zoomLock: heatmapComputedValues.HEATMAP_X_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !heatmapComputedValues.HEATMAP_X_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(heatmapComputedValues.HEATMAP_X_AXIS_DATA_ZOOM_PALETTE, 
                HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(heatmapComputedValues.HEATMAP_X_AXIS_DATA_ZOOM_PALETTE,
                HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_X_AXIS_DATA_ZOOM_PALETTE, 
                HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: heatmapComputedValues.HEATMAP_X_AXIS_DATA_ZOOM_PALETTE
                }
              },
              top: '80%',
              xAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !heatmapComputedValues.HEATMAP_X_AXIS_SHOW_DATA_ZOOM,
              zoomLock: heatmapComputedValues.HEATMAP_X_AXIS_LOCK_DATA_ZOOM,
              xAxisIndex: [0],
            },
            {
              type: 'slider',
              show: heatmapComputedValues.HEATMAP_Y_AXIS_SHOW_DATA_ZOOM,
              start: heatmapComputedValues.HEATMAP_Y_AXIS_DATA_ZOOM_START,
              end: heatmapComputedValues.HEATMAP_Y_AXIS_DATA_ZOOM_END,
              zoomLock: heatmapComputedValues.HEATMAP_Y_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !heatmapComputedValues.HEATMAP_Y_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(heatmapComputedValues.HEATMAP_Y_AXIS_DATA_ZOOM_PALETTE, 
                HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(heatmapComputedValues.HEATMAP_Y_AXIS_DATA_ZOOM_PALETTE,
                HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_Y_AXIS_DATA_ZOOM_PALETTE, 
                HEATMAP_DEFAULT_CONFIGURATIONS.HEATMAP_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: heatmapComputedValues.HEATMAP_Y_AXIS_DATA_ZOOM_PALETTE
                }
              },
              yAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !heatmapComputedValues.HEATMAP_Y_AXIS_SHOW_DATA_ZOOM,
              zoomLock: heatmapComputedValues.HEATMAP_Y_AXIS_LOCK_DATA_ZOOM,
              yAxisIndex: [0],
            },
          ]
        : [],
        series: paramsFromSearch?.dataSeries?.length ? paramsFromSearch?.dataSeries?.map((s, i) => {
            return {
                data: s.map((a) => {
                    return {
                        value: a,
                        itemStyle: getScatterAndHeatmapCommonStyle(a, panelData, paramsFromSearch?.dimensionNames),
                    }
                }) ?? [],
                type: "heatmap",
                dimensions: paramsFromSearch?.dimensionNames,
                name: paramsFromSearch?.dataSeriesNames?.[i],
                label: {
                    show: isChartWidthLimited(panelData.w) ? heatmapComputedValues.HEATMAP_SHOW_VALUE_LABEL : false,
                    fontSize: heatmapComputedValues.HEATMAP_VALUE_LABEL_FONT_SIZE,
                    fontFamily: heatmapComputedValues.HEATMAP_VALUE_LABEL_FONT_FAMILY,
                    fontWeight: heatmapComputedValues.HEATMAP_VALUE_LABEL_FONT_WEIGHT,
                    color: heatmapComputedValues.HEATMAP_VALUE_LABEL_FONT_COLOR,
                    formatter: function (info) {
                        const nameList = info.value.filter((i) => typeof i === 'string').reverse().join(" | ");

                        info.name =  getChartLabelFormatByAggregation(nameList, paramsFromSearch?.aggregationFormats);
                        info.value =  [info.value[2]]

                        return showOnlyTypesExceptOnlyItem.includes(heatmapComputedValues.HEATMAP_SHOW_ONLY) ?
                        setShowOnlyValue(
                            info,
                            paramsFromSearch,
                            heatmapComputedValues,
                            HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_ONLY,
                            dashboardSPEnums.LABEL,
                            panelData
                        )
                        :
                        info.name;
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        }) : null,
    }

    return {
        options: optionObject,
        yAxis: {
            name: isAxisNameActive(paramsFromSearch, paramsFromSearch?.axisNames?.y),
            show: isChartWidthLimited(panelData.w) ? heatmapComputedValues.HEATMAP_SHOW_Y_AXIS_NAME : false,
            fontFamily: heatmapComputedValues.HEATMAP_Y_AXIS_NAME_FONT_FAMILY,
            fontSize: heatmapComputedValues.HEATMAP_Y_AXIS_NAME_FONT_SIZE,
            fontWeight: heatmapComputedValues.HEATMAP_Y_AXIS_NAME_FONT_WEIGHT,
            fontColor: heatmapComputedValues.HEATMAP_Y_AXIS_NAME_FONT_COLOR,
            isAxisLocationDefault: isAxisLocationDefault(heatmapComputedValues.HEATMAP_Y_AXIS_POSITION),
            isNameLocationDefault: isNameLocationDefault(heatmapComputedValues.HEATMAP_Y_AXIS_NAME_LOCATION)
        },
        xAxis: {
            name: isAxisNameActive(paramsFromSearch, paramsFromSearch?.axisNames?.x),
            show: isChartHeightLimited(panelData.h) ? heatmapComputedValues.HEATMAP_SHOW_X_AXIS_NAME : false,
            fontFamily: heatmapComputedValues.HEATMAP_X_AXIS_NAME_FONT_FAMILY,
            fontSize: heatmapComputedValues.HEATMAP_X_AXIS_NAME_FONT_SIZE,
            fontWeight: heatmapComputedValues.HEATMAP_X_AXIS_NAME_FONT_WEIGHT,
            fontColor: heatmapComputedValues.HEATMAP_X_AXIS_NAME_FONT_COLOR,
        }
    }
}