import { generalChartSearch } from "./chart-search/generalChartSearch";
import { treemapSearch } from "./chart-search/treemapSearch";
import { heatmapSearch } from "./chart-search/heatmapSearch";
import { scatterSearch } from "./chart-search/scatterSearch";
import { bubbleSearch } from "./chart-search/bubbleSearch";
import { combinationChartSearch } from "./chart-search/combinationChartSearch";
import { gaugeChartSearch } from "./chart-search/gaugeChartSearch";
import { pivotTableSearch } from "./chart-search/pivotTableSearch";
import { summaryChartSearch } from "./chart-search/summaryChartSearch";
import { chartRules, chartTypes, otherChartType, dashboardFilterTypes, notSearchServiceFilterTypes } from "../commons/dashboardAndPanel";
import { tableAndFilterSearch } from "./chart-search/tableAndFilterSearch";
import { radarChartSearch } from "./chart-search/radarChartSearch";
import { createMetricKey } from "./chartOptionsHelper";
import { geoMapSearch } from "./chart-search/geoMapSearch";
import { filterType } from "../commons/filterComponents";
import { getSankeyChartSearch } from "./chart-search/sankeyChartSearch";
import { checkTypeSelectorDataItem } from "../commons/dataModelTypes";
import { richTextChartSearch } from "./chart-search/richTextChartSearch";

export const chartServices = {
  [chartTypes.PIE]: {
    serviceFn: (params, data, id) => generalChartSearch(params, data, id),
  },
  [chartTypes.BAR]: {
    serviceFn: (params, data, id) => generalChartSearch(params, data, id),
  },
  [chartTypes.COLUMN_CHART]: {
    serviceFn: (params, data, id) => generalChartSearch(params, data, id),
  },
  [chartTypes.LINE_CHART]: {
    serviceFn: (params, data, id) => generalChartSearch(params, data, id),
  },
  [chartTypes.AREA_CHART]: {
    serviceFn: (params, data, id) => generalChartSearch(params, data, id),
  },
  [chartTypes.STACKED_BAR_CHART]: {
    serviceFn: (params, data, id) => generalChartSearch(params, data, id),
  },
  [chartTypes.STACKED_COLUMN_CHART]: {
    serviceFn: (params, data, id) => generalChartSearch(params, data, id),
  },
  [chartTypes.SUMMARY]: {
    serviceFn: (params, data, id) => summaryChartSearch(params, data, id),
  },
  [chartTypes.TREEMAP_CHART]: {
    serviceFn: (params, data, id) => treemapSearch(params, data, id),
  },
  [chartTypes.HEATMAP_CHART]: {
    serviceFn: (params, data, id) => heatmapSearch(params, data, id),
  },
  [chartTypes.SCATTER_CHART]: {
    serviceFn: (params, data, id) => scatterSearch(params, data, id),
  },
  [chartTypes.BUBBLE_CHART]: {
    serviceFn: (params, data, id) => bubbleSearch(params, data, id),
  },
  [chartTypes.COMBINATION_CHART]: {
    serviceFn: (params, data, id) => combinationChartSearch(params, data, id),
  },
  [chartTypes.GAUGE_CHART]: {
    serviceFn: (params, data, id) => gaugeChartSearch(params, data, id),
  },
  [chartTypes.RADAR_CHART]: {
    serviceFn: (params, data, id) => radarChartSearch(params, data, id),
  },
  [chartTypes.GEO_MAP_CHART]: {
    serviceFn: (params, data, id) => geoMapSearch(params, data, id),
  },
  [chartTypes.SANKEY_CHART]: {
    serviceFn: (params, data, id) => getSankeyChartSearch(params, data, id),
  },
  [chartTypes.TABLE]: {
    serviceFn: (params, data, id, chartId) =>
      tableAndFilterSearch(data, id, chartId),
  },
  [chartTypes.PIVOT_TABLE]: {
    serviceFn: (params, data, id) => pivotTableSearch(params, data, id),
  },
  [otherChartType.TEXT_EDITOR]: {
    serviceFn: (params, data, id) => richTextChartSearch(params, data, id),
  },
  [dashboardFilterTypes.FILTER_SELECT_LIST]: {
    serviceFn: (params, data, id) => tableAndFilterSearch(data, id),
  },
  [dashboardFilterTypes.FILTER_DROPDOWN]: {
    serviceFn: (params, data, id) => tableAndFilterSearch(data, id),
  },
};

export const doesChartTypeNotUseSearchService = (chartType) => {
  return notSearchServiceFilterTypes.find((x) => x.value == chartType);
}

export const checkIfChartIsEligibleToCallService = (chartType, params, chartSearchView) => {
  if (doesChartTypeNotUseSearchService(chartType)) return false;

  const rules = chartRules[chartType];
  const columns = [...params.metric, ...params.aggregation];

  if (chartSearchView == chartSearchViewEnum.DASHBOARD) {
    if (params.dataset?.filterProperty?.filterType == filterType.CUSTOM && params.dataset?.filterProperty?.mandatory && !params.params.length) {
      return false;
    }
  }

  if (!columns.length) return false;

  if (!rules) return true;
  else if (rules.isMetricRequired && !params?.metric?.length) return false;

  return true;
}

export const getAggregationCombinations = (params, searchData) => {

  const metricKeys = params?.metric?.map((a) => createMetricKey(a));
  let result = {}
  searchData?.forEach(x => {
    Object.keys(x).forEach(s => {
      if (!metricKeys.includes(s)) {
        //Chart filterlar için aggregation array'inden find yapılıyor.
        //slotFieldId kontrolü Tabloda aggregate result kapalı olduğunda yapılıyor. Tablo bu durumda chart gibi davranıyor.
        let field = params.aggregation.find(x => x?.slotFieldId ? x.field + x?.slotFieldId === s : x.field == s)
        if (!field) {
          //filter compenentler için
          field = params.metric[0]
        }

        if (result[field.field]) {
          result[field.field].add(x[s])
        }
        else {
          result[field.field] = new Set([x[s]])
        }
      }
    })
  })

  Object.keys(result).forEach(i => {
    result[i] = [...result[i]]
  })
  return result;

}

export const chartSearchViewEnum = {
  DASHBOARD: "DASHBOARD",
  PANEL: "PANEL"
} 

export const getSearchBodyParam = (chartData, cacheInfo) => {
  const filterListWithHasValue = chartData?.details?.filters?.filter(
    (x) => x.value
  );
  const { aggregation, params, orders } = chartData?.details;
  
  return {
    filter: filterListWithHasValue,
    metric: chartData?.details?.metrics?.filter(x=> !checkTypeSelectorDataItem(x.type)),
    aggregation: aggregation?.filter(x=> !checkTypeSelectorDataItem(x.fieldType)),
    params,
    dataset: chartData?.properties?.dataSet,
    vismetadata: chartData?.properties?.vismetadata,
    chartType: chartData?.type,
    orders,
    cacheInfo
  };
};
export const getSearchResponseError = (response, chartI, searchResponseError) => {
  //Bir süre code dap_ec_126 ise hata mesajı gösterilecek. Diğer hatalar için çalışma yapılacak
  const hasAuthorizeCode = response?.data?.code?.includes?.("dap_ec_126");

  if (!hasAuthorizeCode && searchResponseError?.[chartI]) {
    delete searchResponseError[chartI]
  }
  
  if (!hasAuthorizeCode) return;

  const { message, params, statusCode } = response;

  searchResponseError[chartI] = { message, params, statusCode };

  return searchResponseError;
};