import moment from "moment";

const formatIsoToDate = (isoDate) => {
    return moment(isoDate).format("DD-MM-YYYY");
}

export const dateQuickOptions = {
    today: "today",
    yesterday: "yesterday",
    aWeekAgo: "aWeekAgo",
}

export const changeFormatTodayDate = () => {
    const date = new Date();
    const formattedValue = formatIsoToDate(date);
    
    return formattedValue;
}
export const changeFormatYesterdayDate = () => {
    const convertedDate = getYesterdayInDate();
    const formattedValue = formatIsoToDate(convertedDate);

    return formattedValue;
}
export const changeFormatAweekagoDate = () => {
    const week = 7;
    const convertedDate = getAweekAgoDateByParam(week);
    const formattedValue = formatIsoToDate(convertedDate);

    return formattedValue;
}

export const getAweekAgoDateByParam = (week) => {
    const date = new Date();
    return date.setTime(date.getTime() - 3600 * 1000 * 24 * week);
}

export const getYesterdayInDate = () => {
    const date = new Date();
    return date.setTime(date.getTime() - 3600 * 1000 * 24);
}