<template>
  <div class="vis-height-100 vis-scroll-thin">
    <div v-if="isMultiple">
      <el-checkbox-group :value="value" @input="$emit('handleChange', $event)">
        <el-checkbox-button
          v-for="item in list"
          :label="item.value"
          :key="item.id"
          :disabled="isDisabled"
          class="vis-checkbox-button"
          >{{ item.label }}</el-checkbox-button
        >
      </el-checkbox-group>
    </div>
    <div v-else>
      <div class="vis-position-relative" v-for="item in list" :key="item.id">
        <el-radio
          :value="value"
          :label="item.value"
          :class="{ activeClass: value == item.value }"
          :disabled="isDisabled"
          @change="$emit('handleChange', item.value)"
          >{{ item.label }}
        </el-radio>
        <!-- For Uncheck Radio -->
        <div
          v-if="item.value === value"
          @click="$emit('handleChange')"
          class="uncheckRadioButton"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: {
      type: [Array, String],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
::v-deep .el-radio {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgb(227, 227, 227);
  color: #000000;
  margin-bottom: 12px;
  padding: 9px 12px;
}
.el-radio .is-disabled {
  cursor: not-allowed !important;
}
::v-deep .el-radio .el-radio__input {
  display: none;
}
::v-deep .el-radio__label {
  font-weight: 500 !important;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0 !important;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: inherit !important;
}

.vis-checkbox-button {
  display: block;
  margin-bottom: 12px;
  color: rgb(0, 0, 0);
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  background-color: rgb(227, 227, 227);
}

::v-deep .el-checkbox-button__inner {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #000000;
  font-size: inherit;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #3d62f8 !important;
  color: #ffffff !important;
}
::v-deep .el-checkbox-button__inner:hover {
  color: inherit;
}

::v-deep .el-checkbox-button.is-disabled .el-checkbox-button__inner {
  background-color: rgb(227, 227, 227) !important;
  color: #c0c4cc !important;
}
.activeClass {
  background-color: #3d62f8 !important;
  color: #ffffff !important;
}

.uncheckRadioButton {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
}
</style>
