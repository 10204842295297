<template>
  <div :class="{ 'vis-row-box mb-25': applyRowBoxStyle }">
    <label
      class="vis-font-bold vis-mb--05"
      v-if="isShowCustomLabel && customLabelText"
      >{{ $t(customLabelText) }}</label
    >
    <div :class="[spacebetween, yCenter, paddingLeft]">
      <slot name="content"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    spacebetween: {
      type: String,
    },
    yCenter: {
      type: String,
    },
    paddingLeft: {
      type: String,
      default: "",
    },
    isShowCustomLabel: {
      type: Boolean,
      default: false,
    },
    customLabelText: {
      type: String,
      default: null,
    },
    applyRowBoxStyle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>
