
import {
    TEXT_EDITOR_PROPERTIES_ENUM,
} from "../../commons/dashboardProperties";
import { TEXT_EDITOR_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/textEditorDefaultProperties";

export default {
    computed: {
        textEditorComponentsMapping() {
            return {
                [TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_VALUE
                    ], TEXT_EDITOR_DEFAULT_CONFIGURATIONS.TEXT_EDITOR_DYNAMIC_TITLE_VALUE),
                    property: TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], TEXT_EDITOR_DEFAULT_CONFIGURATIONS.TEXT_EDITOR_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE
                    ], TEXT_EDITOR_DEFAULT_CONFIGURATIONS.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_GROUP_MULTIPLE_VALUES
                    ], TEXT_EDITOR_DEFAULT_CONFIGURATIONS.TEXT_EDITOR_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
                [TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_COUNT]: {
                    id: this.getPropertyId(
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_COUNT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Max Image Count",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_COUNT
                    ], TEXT_EDITOR_DEFAULT_CONFIGURATIONS.TEXT_EDITOR_MAX_IMAGE_COUNT),
                    property: TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_COUNT,
                    componentType: this.componentTypes.INPUT,
                    inputType: "text",
                    flexClass: false,
                },
                [TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_SIZE]: {
                    id: this.getPropertyId(
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Max Image Size",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_SIZE
                    ], TEXT_EDITOR_DEFAULT_CONFIGURATIONS.TEXT_EDITOR_MAX_IMAGE_SIZE),
                    property: TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_SIZE,
                    componentType: this.componentTypes.INPUT,
                    inputType: "text",
                    flexClass: false,
                },
                [TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_SHOW_IMAGE]: {
                    id: this.getPropertyId(
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_SHOW_IMAGE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Allow Image Adding",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_SHOW_IMAGE
                    ], TEXT_EDITOR_DEFAULT_CONFIGURATIONS.TEXT_EDITOR_SHOW_IMAGE),
                    componentType: this.componentTypes.SWITCH,
                    property: TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_SHOW_IMAGE,
                    flexClass: true,
                },
            }
        },
    },
};
