<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold">
        {{ $t("ingest.Connection Properties") }}
      </span>
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.label}`)"
        :name="collapse.key"
        :disabled="collapse.disabled"
      >
        <div v-if="collapseTypes.general === collapse.key">
          <div class="property-wrapper">
            <label>{{ $t("ingest.Connection") }}</label>
            <div class="input-container">
              <input
                :placeholder="$t('ingest.Select Connection')"
                style="cursor: pointer"
                class="vis-input"
                :value="getDatabase.name"
                readonly
                @click="$emit('showConnectionPopup')"
              />
              <i
                class="vis-cursor-pointer icon-in-input"
                :class="CustomIcon.VerticalThreeDot"
                aria-hidden="true"
                @click="$emit('showConnectionPopup')"
              />
            </div>
          </div>
          <div
            v-if="isSourceTypeFTP || isSourceTypeSMB"
            class="property-wrapper"
          >
            <label>{{ $t("ingest.File") }}</label>
            <div class="input-container">
              <input
                :placeholder="$t('ingest.File')"
                style="cursor: pointer"
                class="vis-input"
                :value="fileName"
                readonly
                @click="$emit('showFilePopup')"
              />
              <i
                class="vis-cursor-pointer icon-in-input"
                :class="CustomIcon.VerticalThreeDot"
                aria-hidden="true"
                @click="$emit('showFilePopup')"
              />
            </div>
          </div>
          <div class="property-wrapper">
            <label>{{ $t("ingest.Type") }}</label>
            <input class="vis-input" :value="getDatabase.type" readonly />
          </div>
          <div class="property-wrapper">
            <label>{{ $t("ingest.Description") }}</label>
            <textarea
              class="vis-textarea"
              :value="getDatabase.description"
              rows="3"
              readonly
            >
            </textarea>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { IngestNodeTypes } from "./enums";

export default {
  components: {},
  props: {
    sourceProperties: {
      type: Object,
      required: true,
    },
    jobProperties: {
      type: Object,
      required: true,
    },
    connections: {
      type: Array,
    },
  },
  data() {
    return {
      collapseTypes: {
        general: "general",
        dataRefresh: "dataRefresh",
        optimization: "optimization",
        schedule: "schedule",
        runHistory: "runHistory",
      },
      activeCollapses: [],
      CustomIcon: CustomIcon,
    };
  },
  mounted() {
    this.activeCollapses.push(this.collapseTypes.general);
  },
  computed: {
    fileName() {
      return (
        this.sourceProperties?.fileIngestRequestBody?.remoteFileLocation ??
        this.jobProperties?.fileIngestTask?.remoteFileLocation ??
        ""
      );
    },
    getDatabase() {
      return (
        this.connections.find(
          (c) => c.connectionId === this.sourceProperties?.sourceConnectionId
        ) ?? {}
      );
    },
    allCollapses() {
      return [
        {
          key: this.collapseTypes.general,
          label: "generalPages.general",
          isShow: true,
        },
      ];
    },
    isSourceTypeFTP() {
      return this.sourceProperties?.nodeType === IngestNodeTypes.FTP;
    },
    isSourceTypeSMB() {
      return this.sourceProperties?.nodeType === IngestNodeTypes.SMB;
    },
  },
  methods: {},
};
</script>

<style scoped>
.property-wrapper {
  margin-bottom: 10px;
}
::v-deep .el-collapse-item__wrap {
  margin-bottom: 20px;
}
.input-container {
  position: relative;
}
.icon-in-input {
  position: absolute;
  top: 50%; /* Adjust this to vertically center the icon */
  right: 10px; /* Adjust this to control the distance from the input field */
  transform: translateY(-50%);
  /* You can also add other styles to your icon, like font-size or color */
}
</style>
