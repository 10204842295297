import { AXIS_TYPES_ENUM, BAR_CHART_PROPERTIES_ENUM, Orientation, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { BAR_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/barChartDefaultProperties";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import {
  calculateGridDistances,
  formatValueAxisLabels,
  getBarColorItemStyle,
  getSelectedColorPaletteByProperty,
  isAxisLocationDefault,
  isAxisNameActive,
  isNameLocationDefault,
  setShowOnlyValue,
  truncateText,
  aggregationDateFormatController,
  colorConverter,
  sliderPositionHandler,
  xAxisWidthHandler,
} from "../chartOptionsHelper";
import { getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";

export const getBarChartOptions = (
  barChartComputedValues,
  paramsFromSearch,
  yAxisName,
  xAxisName,
  panelData
) => {
  const dataAxis = [...(paramsFromSearch?.dataAxis ?? [])];

  const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
    yAxisPosition: barChartComputedValues.BAR_CHART_Y_AXIS_POSITION,
    showYAxisLabels: barChartComputedValues.BAR_CHART_SHOW_Y_AXIS_LABEL,
    showXAxisLabels: barChartComputedValues.BAR_CHART_SHOW_X_AXIS_LABEL,
    showLegend: barChartComputedValues.BAR_CHART_SHOW_LEGEND,
    legendPosition: barChartComputedValues.BAR_CHART_LEGEND_POSITION,
    legendMaxCharacter: barChartComputedValues.BAR_CHART_LEGEND_MAX_CHARACTER,
    yAxisNameLocation: barChartComputedValues.BAR_CHART_Y_AXIS_NAME_LOCATION,
    showOnly: barChartComputedValues.BAR_CHART_SHOW_ONLY,
    xAxisDataZoom: barChartComputedValues.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
    xAxisRotate: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_ROTATE,
    yAxisDataZoom: barChartComputedValues.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
    panelData
  });

  const optionObject = {
    grid: {
      top: gridTop + 10,
      bottom: gridBottom,
      left: gridLeft,
      right: gridRight + 20,
      containLabel: true,
    },
    title: {
      text: "",
    },
    legend: {
      show: isChartWidthLimited(panelData.w)
        ? barChartComputedValues.BAR_CHART_SHOW_LEGEND
        : false,
      orient: getOrientPositionByLegend(barChartComputedValues.BAR_CHART_LEGEND_POSITION)?.toLowerCase(),
      left: getAlignPositionByLegend(barChartComputedValues.BAR_CHART_LEGEND_POSITION),
      top: getOrientPositionByLegend(barChartComputedValues.BAR_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "4%" : barChartComputedValues.BAR_CHART_LEGEND_VERTICAL_ALIGN,
      textStyle: {
        fontWeight: barChartComputedValues.BAR_CHART_LEGEND_FONT_WEIGHT,
        fontFamily: barChartComputedValues.BAR_CHART_LEGEND_FONT_FAMILY,
        fontSize: barChartComputedValues.BAR_CHART_LEGEND_FONT_SIZE,
        color: barChartComputedValues.BAR_CHART_LEGEND_FONT_COLOR,
      },
      backgroundColor: 'transparent',
      formatter: function (value) {
        return truncateText(aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats), barChartComputedValues.BAR_CHART_LEGEND_MAX_CHARACTER);
      },
      type: "scroll",
    },
    tooltip: {
      show: true,
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 12,
      },
      formatter: function (value) {
        value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);
        
        return showOnlyTypesExceptOnlyItem.includes(barChartComputedValues.BAR_CHART_SHOW_ONLY)
        ? setShowOnlyValue(
            value,
            paramsFromSearch,
            barChartComputedValues,
            BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_ONLY,
            dashboardSPEnums.TOOLTIP,
            panelData
        )
        : 
        value.name;
      },
    },
    yAxis: {
      show: paramsFromSearch?.dataAxis?.length,
      name: "",
      axisLabel: {
        show: isChartWidthLimited(panelData.w)
          ? barChartComputedValues.BAR_CHART_SHOW_Y_AXIS_LABEL
          : false,
        fontWeight: barChartComputedValues.BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
        fontFamily: barChartComputedValues.BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY,
        fontSize: barChartComputedValues.BAR_CHART_Y_AXIS_LABEL_FONT_SIZE,
        color: barChartComputedValues.BAR_CHART_Y_AXIS_LABEL_FONT_COLOR,
        formatter: function (value) {
          const formattedValue = aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats);
          return truncateText(
            formattedValue,
            barChartComputedValues.BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER
          );
        },
        hideOverlap: true,
      },
      type: "category",
      position: barChartComputedValues.BAR_CHART_Y_AXIS_POSITION,
      axisLine: {
        show: barChartComputedValues.BAR_CHART_Y_AXIS_SHOW_LINE,
        lineStyle: {
          color: barChartComputedValues.BAR_CHART_Y_AXIS_LINE_STYLE_COLOR,
        },
      },
      data: dataAxis,
      axisTick: {
        show: false,
      },
    },
    xAxis: {
      show: paramsFromSearch?.dataSeries,
      name: "",
      axisLabel: {
        show: isChartHeightLimited(panelData.h)
          ? barChartComputedValues.BAR_CHART_SHOW_X_AXIS_LABEL
          : false,
        align: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
        verticalAlign: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
        fontWeight: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT,
        fontFamily: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_FONT_FAMILY,
        fontSize: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_FONT_SIZE,
        color: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_FONT_COLOR,
        rotate: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_ROTATE,
        overflow: barChartComputedValues.BAR_CHART_X_AXIS_LABEL_WRAP ? 'break' : 'none',
        width: xAxisWidthHandler(barChartComputedValues.BAR_CHART_X_AXIS_LABEL_WRAP),
        formatter: function (value) {
          return formatValueAxisLabels(paramsFromSearch?.metricFormats, value);
        },
        hideOverlap: true,
      },
      axisTick: {
        show: false,
      },
      type: barChartComputedValues.BAR_CHART_X_AXIS_LOGARITHMIC_SCALE
        ? AXIS_TYPES_ENUM.LOG
        : AXIS_TYPES_ENUM.VALUE,
      min: barChartComputedValues.BAR_CHART_X_AXIS_MIN,
      axisLine: {
        show: true,
      },
      splitLine: {
        lineStyle: {
          color: barChartComputedValues.BAR_CHART_X_AXIS_GRID_LINE_COLOR,
        }
      },
    },
    dataZoom: Object.keys(paramsFromSearch).length
      ? [
          {
            type: "slider",
            show: barChartComputedValues.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
            start: barChartComputedValues.BAR_CHART_X_AXIS_DATA_ZOOM_START,
            end: barChartComputedValues.BAR_CHART_X_AXIS_DATA_ZOOM_END,
            zoomLock: barChartComputedValues.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
            brushSelect:
              !barChartComputedValues.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
            backgroundColor: colorConverter(barChartComputedValues.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
              BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE),
            fillerColor: colorConverter(barChartComputedValues.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE,
              BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
              BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_DATA_ZOOM_FILLER_COLOR),
            dataBackground: {
              areaStyle: {
                color:
                  barChartComputedValues.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE,
              },
            },
            xAxisIndex: [0],
          },
          {
            type: "inside",
            disabled: !barChartComputedValues.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
            zoomLock: barChartComputedValues.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
            xAxisIndex: [0],
          },
          {
            type: "slider",
            show: barChartComputedValues.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
            left:  sliderPositionHandler(barChartComputedValues.BAR_CHART_SHOW_LEGEND,
              barChartComputedValues.BAR_CHART_LEGEND_POSITION),
            start: barChartComputedValues.BAR_CHART_Y_AXIS_DATA_ZOOM_START,
            end: barChartComputedValues.BAR_CHART_Y_AXIS_DATA_ZOOM_END,
            zoomLock: barChartComputedValues.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
            brushSelect:
              !barChartComputedValues.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
            backgroundColor: colorConverter(barChartComputedValues.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
            fillerColor: colorConverter(barChartComputedValues.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                BAR_CHART_DEFAULT_CONFIGURATIONS.BAR_CHART_DATA_ZOOM_FILLER_COLOR),
            dataBackground: {
              areaStyle: {
                color:
                  barChartComputedValues.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
              },
            },
            yAxisIndex: [0],
          },
          {
            type: "inside",
            disabled: !barChartComputedValues.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
            zoomLock: barChartComputedValues.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
            yAxisIndex: [0],
          },
        ]
      : [],

    series: paramsFromSearch?.dataSeries?.length
      ? paramsFromSearch.dataSeries.map((s, i) => {
        return {
          data: s.map((a, index) => {
            return {
              value: a,
              itemStyle: {
                ...getCommonChartItemStyle(
                  dataAxis[index],
                  panelData
                ), ...getBarColorItemStyle(getSelectedColorPaletteByProperty(barChartComputedValues.BAR_CHART_SELECTED_COLOR_PALETTE, index), panelData)
              }
            };
          }),
          name: paramsFromSearch?.dataSeriesNames?.[i],
          type: "bar",
          label: {
            position: "outside",
            show: isChartWidthLimited(panelData.w)
              ? barChartComputedValues.BAR_CHART_SHOW_VALUE_LABEL
              : false,
            fontSize: barChartComputedValues.BAR_CHART_VALUE_LABEL_FONT_SIZE,
            fontFamily:
              barChartComputedValues.BAR_CHART_VALUE_LABEL_FONT_FAMILY,
            fontWeight:
              barChartComputedValues.BAR_CHART_VALUE_LABEL_FONT_WEIGHT,
            color: barChartComputedValues.BAR_CHART_VALUE_LABEL_FONT_COLOR,
            formatter: function (value) {
              value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);

              return showOnlyTypesExceptOnlyItem.includes(barChartComputedValues.BAR_CHART_SHOW_ONLY)
              ? setShowOnlyValue(
                  value,
                  paramsFromSearch,
                  barChartComputedValues,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_ONLY,
                  dashboardSPEnums.LABEL,
                  panelData
              )
              :
              value.name;
            },
          },
          labelLayout: {
            hideOverlap:
              barChartComputedValues.BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
          },
          itemStyle: {
            borderRadius: [0, 3, 3, 0],
          },
        };
      })
      : null,
    color: getSelectedColorPaletteByProperty(barChartComputedValues.BAR_CHART_SELECTED_COLOR_PALETTE),
  };
  return {
    options: optionObject,
    yAxis: {
      name: isAxisNameActive(paramsFromSearch, xAxisName),
      show: isChartWidthLimited(panelData.w)
        ? barChartComputedValues.BAR_CHART_SHOW_Y_AXIS_NAME
        : false,
      fontFamily: barChartComputedValues.BAR_CHART_Y_AXIS_NAME_FONT_FAMILY,
      fontSize: barChartComputedValues.BAR_CHART_Y_AXIS_NAME_FONT_SIZE,
      fontWeight: barChartComputedValues.BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT,
      fontColor: barChartComputedValues.BAR_CHART_Y_AXIS_NAME_FONT_COLOR,
      isAxisLocationDefault: isAxisLocationDefault(
        barChartComputedValues.BAR_CHART_Y_AXIS_POSITION
      ),
      isNameLocationDefault: isNameLocationDefault(
        barChartComputedValues.BAR_CHART_Y_AXIS_NAME_LOCATION
      ),
    },
    xAxis: {
      name: isAxisNameActive(paramsFromSearch, yAxisName),
      show: isChartHeightLimited(panelData.h)
        ? barChartComputedValues.BAR_CHART_SHOW_X_AXIS_NAME
        : false,
      fontFamily: barChartComputedValues.BAR_CHART_X_AXIS_NAME_FONT_FAMILY,
      fontSize: barChartComputedValues.BAR_CHART_X_AXIS_NAME_FONT_SIZE,
      fontWeight: barChartComputedValues.BAR_CHART_X_AXIS_NAME_FONT_WEIGHT,
      fontColor: barChartComputedValues.BAR_CHART_X_AXIS_NAME_FONT_COLOR,
    },
  };
};
