import {
    fontsArr,
    fontSizesArr,
    fontWeightsArr,
    TREEMAP_PROPERTIES_ENUM,
    predefineColorsArr,
    verticalAlignItems,
    allPropertiesKeys,
    autoRefreshTimes,
    legendMaxCharArr,
    legendPositionArr,
    showOnlyArr,
    tableColumnsInDrillThrough
} from "../../commons/dashboardProperties";
import { TREEMAP_DEFAULT_CONFIGURATIONS} from "../../mocks/treemapDefaultProperties";
import ColorPaletteSelector from "../../components/panel/properties/ColorPaletteSelector";
import { getPropertiesWithUndefinedCheck } from "../../util/chartViewHelper";
import { chartPropertiesCss } from "./chartPropertiesCssEnum";
import { mapActions } from "vuex";
import { ACTION as ACTION_SEARCH } from "../../store/modules/Visualize/Home/types";

export default {
    computed: {
        treemapComponentsMapping() {
            return {
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_LEGEND]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_LEGEND,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_LEGEND
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_SHOW_LEGEND),
                    componentType: this.componentTypes.SWITCH,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_LEGEND,
                    flexClass: true,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_MAX_CHARACTER]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_MAX_CHARACTER,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Max Character",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_MAX_CHARACTER
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_LEGEND_MAX_CHARACTER),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_MAX_CHARACTER,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendMaxCharArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_VERTICAL_ALIGN]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_VERTICAL_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.verticalAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_VERTICAL_ALIGN
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_LEGEND_VERTICAL_ALIGN),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_VERTICAL_ALIGN,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: verticalAlignItems,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_WEIGHT
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_LEGEND_FONT_WEIGHT),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Legend Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_FAMILY
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_LEGEND_FONT_FAMILY),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_SIZE]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_SIZE
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_LEGEND_FONT_SIZE),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_COLOR]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_COLOR
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_LEGEND_FONT_COLOR),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_POSITION]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.legend.Legend Position",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_POSITION
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_LEGEND_POSITION),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: legendPositionArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_VALUE_LABEL]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_VALUE_LABEL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_VALUE_LABEL
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_SHOW_VALUE_LABEL),
                    componentType: this.componentTypes.SWITCH,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_VALUE_LABEL,
                    flexClass: true,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_ONLY]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_ONLY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showOnly.title",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_ONLY
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_SHOW_ONLY),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_ONLY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: showOnlyArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_SIZE
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_VALUE_LABEL_FONT_SIZE),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.valueLabel.Value Label Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_FAMILY
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_VALUE_LABEL_FONT_FAMILY),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_WEIGHT
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_VALUE_LABEL_FONT_WEIGHT),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_COLOR
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_VALUE_LABEL_FONT_COLOR),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_SELECTED_COLOR_PALETTE]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SELECTED_COLOR_PALETTE,
                        this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SELECTED_COLOR_PALETTE
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_SELECTED_COLOR_PALETTE),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_SELECTED_COLOR_PALETTE,
                    dashboardProperty: allPropertiesKeys.CUSTOM_COLOR_PALETTES,
                    componentType: this.componentTypes.OTHER,
                    component: ColorPaletteSelector
                },

                [TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_AUTO_REFRESH),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH_CUSTOM
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_AUTO_REFRESH_CUSTOM),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_VALUE
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_DYNAMIC_TITLE_VALUE),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_GROUP_MULTIPLE_VALUES
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_INSIGHT
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_SHOW_INSIGHT),
                    componentType: this.componentTypes.SWITCH,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_SQL]: {
                    id: this.getPropertyId(
                      TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_SQL],
                      TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_SQL,
                    flexClass: true,
                },
                // START DRILL THROUGH
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),  
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_ALLOW_DRILL_THROUGH
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_DASHBOARD_LIST]:
                {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_DASHBOARD_LIST,
                    this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(
                    this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM
                        .TREEMAP_DRILL_THROUGH_DASHBOARD_LIST
                    ],
                    TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_DRILL_THROUGH_DASHBOARD_LIST
                    ),
                    componentType: this.componentTypes.AUTO_COMPLETE_INPUT_WITH_TABLE,
                    tableColumns: tableColumnsInDrillThrough,
                    property:
                    TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_DASHBOARD_LIST,
                    flexClass: false,
                    queryParam: (value) => {
                        return `dashboardName=${value}&dataModelId=${this.datamodelId}`;
                      },
                    serviceFn: (params) => this.fetchDashboardSearch(params),
                    mapperFn: (content) => {
                        return content.map((m) => {
                          return {
                            id: m.id,
                            value: m.name,
                            nameSubtitle: m?.folderNames?.join(" / ") ?? "Home",
                          };
                        });
                      },
                      getAutoCompleteItem: (item) => {
                        return {
                          id: item.id,
                          name: item.value,
                          nameSubtitle: item?.nameSubtitle,
                        };
                      },
                },
                [TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_ON_DOUBLE_CLICK]: {
                    id: this.getPropertyId(
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_ON_DOUBLE_CLICK,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Drill On Double Click",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_ON_DOUBLE_CLICK
                    ], TREEMAP_DEFAULT_CONFIGURATIONS.TREEMAP_DRILL_THROUGH_ON_DOUBLE_CLICK),
                    componentType: this.componentTypes.SWITCH,
                    property: TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_ON_DOUBLE_CLICK,
                    flexClass: true,
                },
                // END DRILL THROUGH 
            };
        },
    },
    methods: {
        ...mapActions({
            fetchDashboardSearch: ACTION_SEARCH.FETCH_DASHBOARD_SEARCH,
          }),
    }
};

