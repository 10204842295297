export const domain = "VISUALIZE_ABOUT";

export const STATE = {
    LICENSE: `${domain}/LICENSE`,
    VERSION: `${domain}/VERSION`,
};

export const GETTER = {
    GET_LICENSE: `${domain}/GET_LICENSE`,
    GET_VERSION: `${domain}/GET_VERSION`,
};

export const ACTION = {
    FETCH_LICENSE: `${domain}/FETCH_LICENSE`,
    FETCH_VERSION: `${domain}/FETCH_VERSION`,
};

export const MUTATION = {
    SET_LICENSE: `${domain}/SET_LICENSE`,
    SET_VERSION: `${domain}/SET_VERSION`,
};


