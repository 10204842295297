<template>
  <div class="vis-flex--spacebeetwen-Ycenter vis-mobile-flex-direction-column">
    <el-tabs
      v-if="!currentFolderId && !isInSearch"
      v-model="activeTab"
      @tab-click="handleSelectTab"
    >
      <el-tab-pane
        v-for="tab in getTabList()"
        :key="tab.id"
        :label="$t(`home.${tab.localizationKey}`)"
        :name="tab.key"
        :disabled="activeTab === tab.key"
      ></el-tab-pane>
    </el-tabs>
    <!-- BREADCRUMB START -->
    <div
      v-if="currentFolderId && !isInSearch"
      class="vis-flex--spacebeetwen-Ycenter vis-mb-0375"
    >
      <ul class="vis-user-tabs vis-flex--Ycenter">
        <li @click="getAllProjects" class="vis-main-breadcrumb vis-ml--none">
          {{ $t("home.all") }}
        </li>
        <div
          v-for="folder in folderNameList"
          :key="folder.id"
          class="vis-flex--Ycenter"
        >
          <li class="vis-main-breadcrumb">
            <i aria-hidden="true" :class="CustomIcon.ExpandRight"></i>
          </li>
          <li
            @click="$emit('viewFolder', folder.id, folder.name)"
            :class="{ 'vis-font-medium': currentFolderId == folder.id }"
            class="vis-main-breadcrumb"
          >
            {{ folder.name }}
          </li>
        </div>
      </ul>
    </div>
    <!--/ BREADCRUMB END -->
    <!-- LISTED ITEMS BAR START-->
    <div v-if="isInSearch" class="vis-flex--spacebeetwen-Ycenter">
      <span class="el-tabs__item vis-search-tab-label">
        {{
          $t("generalPages.Search Results") +
          " " +
          $t("generalPages.Listing") +
          " " +
          paginationResult.numberOfElements +
          " " +
          $t("generalPages.Items")
        }}
      </span>
    </div>
    <!-- / LISTED ITEMS END -->
    <div class="vis-user-tabs-iconbox vis-flex--Ycenter">
      <!-- MULTIPLE SELECTED START -->
      <div class="vis-flex--Ycenter" v-if="isActiveBulkDelete">
        <span class="vis-font-medium"
          >{{ selectedProjectsLength }} {{ $t("home.selectedObject") }}</span
        >
        <el-popover
          placement="bottom-start"
          trigger="click"
          popper-class="vis-dropdown-popper"
        >
          <div class="vis-dropdown">
            <ul>
              <li
                v-for="item in multipleSelectDropDownList()"
                :key="item.id"
                @click="item.serviceFn"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
          <i
            slot="reference"
            aria-hidden="true"
            :class="CustomIcon.VerticalThreeDot"
            class="vis-mr--1 vis-ml--none vis-vertical-align-middle"
          />
        </el-popover>
      </div>
      <!-- MULTIPLE SELECTED END -->
      <!-- FILTERS START-->
      <div class="filter-card">
        <div 
          v-for="entityType in entityTypeList"
          :key="entityType.id"
        >
          <el-tooltip 
            :content="entityType.label"
            :popper-class="tooltipProps.popperClass"
            :placement="tooltipProps.placement"
            :effect="tooltipProps.effect"
            :visible-arrow="tooltipProps.visibleArrow"
          >
            <div 
              :class="{'active-entity-icon': isInEntityList(entityType.type)}"
              class="filter-icon-tab"
            >
              <i
                @click="filterItemsByEntityType(entityType.type)"
                aria-hidden="true"
                :class="entityType.className"
              />
            </div>
          </el-tooltip>
        </div>
      </div>
      <!-- FILTERS END-->
      <!-- SEARCH -->
      <div class="vis-position-relative" :class="{'vis-ml--05': !isSearchEnabled}">
        <el-input
          v-if="isSearchEnabled"
          v-model="searchValue"
          size="medium"
          class="search-input"
          :placeholder="$t('filterComponents.typeToSeach')"
          @keyup.enter.native="
            searchProjectList(HOMEPAGE_SEARH_PARAMS.SEARCH_KEY)
          "
        />
        <el-tooltip 
          :content="$t('home.search')"
          :popper-class="tooltipProps.popperClass"
          :placement="tooltipProps.placement"
          :effect="tooltipProps.effect"
          :visible-arrow="tooltipProps.visibleArrow"
        >  
          <i
            :class="CustomIcon.Search"
            class="vis-input-icon"
            aria-hidden="true"
            @click="toggleSearchVisibility"
          />
        </el-tooltip>
      </div>
      <!-- SEARCH END -->
      <!-- ADD FOLDER START -->
      <el-tooltip
        :content="$t('home.New Folder')"
        :popper-class="tooltipProps.popperClass"
        :placement="tooltipProps.placement"
        :effect="tooltipProps.effect"
        :visible-arrow="tooltipProps.visibleArrow"
      >
        <i
          v-if="
            isCreateFolderAvailable && isCreateFolderAuthorized && !isInSearch
          "
          @click="$emit('openNewFolderPopup')"
          aria-hidden="true"
          :class="CustomIcon.AddFolder"
        />
      </el-tooltip>
      <!-- / ADD FOLDER END -->
      <!-- SORT -->
      <el-popover
        v-if="isSortAvailable"
        v-click-outside="() => handleClickOutside()"
        placement="bottom"
        trigger="manual"
        popper-class="vis-dropdown-popper vis-dropdown-body-close vis-sort-dropdown-ml--15"
        :value="isSortClicked"
      >
        <div class="vis-dropdown vis-sort-dropdown-min-width">
          <ul>
            <li
              @click="sortByProjectList(sort)"
              v-for="sort in orderBySortList"
              :key="sort.id"
              class="vis-flex--spacebeetwen"
            >
              <span :class="activeSortItemClass(sort)">{{
                $t(`${sort.label}`)
              }}</span>
              <i
                v-if="sort.id == activeSortItemId"
                :class="[
                  CustomIcon.ArrowRight,
                  activeArrowIconClass(sort.type),
                ]"
                class="vis-arrow-icon-down vis-mr--none vis-color-primary"
                :aria-hidden="true"
              ></i>
            </li>
          </ul>
        </div>
        <template #reference>
          <el-tooltip 
            :content="$t('home.sort')"
            :popper-class="tooltipProps.popperClass"
            :placement="tooltipProps.placement"
            :effect="tooltipProps.effect"
            :visible-arrow="tooltipProps.visibleArrow"
          >
            <i
              aria-hidden="true"
              :class="CustomIcon.SortBy"
              :id="sortByEnum"
              @click.stop="(e) => handlePopoverActivity(e)"
            />
          </el-tooltip>
        </template>
      </el-popover>
      <!-- SORT END -->

      <el-tooltip
        :content="$t('home.Table View')"
        :popper-class="tooltipProps.popperClass"
        :placement="tooltipProps.placement"
        :effect="tooltipProps.effect"
        :visible-arrow="tooltipProps.visibleArrow"
      >
        <i
          @click="$emit('toggleView')"
          aria-hidden="true"
          :class="iconName.changeViewIcon"
        />
      </el-tooltip>
      <el-tooltip
        :content="$t('home.Full Screen')"
        :popper-class="tooltipProps.popperClass"
        :placement="tooltipProps.placement"
        :effect="tooltipProps.effect"
        :visible-arrow="tooltipProps.visibleArrow"
      >
        <i
          v-if="isMaximizeAvailable && !isInSearch"
          @click="$emit('changeResize')"
          aria-hidden="true"
          :class="iconName.resizeIcon"
        />
      </el-tooltip>
      <i
        v-if="isInSearch"
        @click="closeSearchHandler()"
        aria-hidden="true"
        :class="iconName.closeIcon"
      />
    </div>
  </div>
</template>
<script>
import uuid from "uuid";
import { CustomIcon } from "../../assets/js/custom-icons";
import ClickOutside from "vue-click-outside";
import {
  HOME_TABS_KEY,
  HOME_TABS_SORT_LIST,
  HOME_TABS_FILTER_BY_TYPE,
  SORT_BY,
  getHomeTabListByAuthority,
  getHomeScenarioTabListByAuthority,
  HOMEPAGE_SEARH_PARAMS,
} from "../../commons/homeDataTypes";
import { isSearchingWithDifferentKey } from "../../commons/helper";
import { ObjectType } from "../../util/homePageMappers";

export default {
  directives: {
    ClickOutside,
  },
  props: {
    isModuleVisualize: {
      type: Boolean,
      default: true,
    },
    isSearchAvailable: {
      type: Boolean,
      default: true,
    },
    isSortAvailable: {
      type: Boolean,
      default: true,
    },
    isCreateFolderAvailable: {
      type: Boolean,
      default: true,
    },
    isCreateFolderAuthorized: {
      type: Boolean,
      default: false,
    },
    isMaximizeAvailable: {
      type: Boolean,
      default: true,
    },
    iconName: {
      type: Object,
    },
    selectedProjectsLength: {
      type: Number,
    },
    currentFolderId: {
      type: String,
    },
    folderNameList: {
      type: Array,
    },
    isActiveBulkDelete: {
      type: Boolean,
      default: false,
    },
    forDataFlowDoesUserHaveEditRoles: {
      type: Boolean,
      default: false,
    },
    homePageSearchQueryData: {
      type: Array,
    },
    isInSearch: {
      type: Boolean,
      default: false,
    },
    showCardOrTable: {
      type: Boolean,
      default: false,
    },
    paginationResult: {
      type: Object,
    },
    defaultSearchValue: {
      type: String,
      default: "",
    },
    lastSelectedTab: {
      type: String,
      required: true,
    },
    entityFilterList: {
      type: Array,
    }
  },
  data() {
    return {
      activeTab: HOME_TABS_KEY.ALL,
      orderBySortList: HOME_TABS_SORT_LIST,
      filterByType: HOME_TABS_FILTER_BY_TYPE,
      projectName: "",
      SORT_BY: SORT_BY,
      activeSortItemId: null,
      CustomIcon: CustomIcon,
      HOMEPAGE_SEARH_PARAMS: HOMEPAGE_SEARH_PARAMS,
      searchValue: "",
      isSearchEnabled: false,
      selectedType: "",
      isFilterClicked: false,
      isFilterSelected: false,
      isSortClicked: false,
      filterByTypeEnum: "filterByType",
      sortByEnum: "sortBy",
      tooltipProps: {
        popperClass: "el-tooltip__popper el-tooltip__arrow vis-color-black",
        placement: "top-end",
        effect: "light",
        visibleArrow: true
      }
    };
  },
  computed: {
    entityTypeList() {
      return [
        {
          id: uuid(),
          className: `${CustomIcon.Dashboard} filter-icon`,
          label: this.$t("generalPages.dashboard"),
          type: ObjectType.DASHBOARD
        },
        {
          id: uuid(),
          className: `${CustomIcon.DatamodelIcon} filter-icon`,
          label: this.$t("generalPages.dataModel"),
          type: ObjectType.DATAMODEL
        },
        {
          id: uuid(),
          className: `${CustomIcon.Dataflow} filter-icon icon-color-black`,
          label: this.$t("generalPages.Dataflow"),
          type: ObjectType.DATAFLOW
        },
        {
          id: uuid(),
          className: `${CustomIcon.Folder} filter-icon`,
          label: this.$t("generalPages.Folder"),
          type: ObjectType.FOLDER
        }
      ]
    }
  },
  watch: {
    lastSelectedTab: {
      handler(val) {
        this.activeTab = val;
      },
      immediate: true,
    },
    defaultSearchValue: {
      handler(val) {
        if (val?.length > 0) {
          this.searchValue = val;
          this.toggleSearchVisibility();
        }
      },
      immediate: true,
    },
  },
  methods: {
    isInEntityList(type) {
      return this.entityFilterList.includes(type);
    },
    filterItemsByEntityType(type) {
      this.$emit("filterByEntity", type);
    },
    handleClickOutside() {
      this.isFilterClicked = false;
      this.isSortClicked = false;
    },
    handlePopoverActivity(e) {
      if (e.target.id === this.filterByTypeEnum) {
        this.isFilterClicked = !this.isFilterClicked;
        this.isSortClicked = false;
      } else if (e.target.id === this.sortByEnum) {
        this.isSortClicked = !this.isSortClicked;
        this.isFilterClicked = false;
      }
    },
    toggleSearchVisibility() {
      this.isSearchEnabled = !this.isSearchEnabled;
    },
    toggleView() {
      if (!this.isInSearch && this.showCardOrTable) {
        this.$emit("toggleView");
      }
    },
    searchProjectList(type) {
      if (
        this.searchValue.length > 2 &&
        isSearchingWithDifferentKey(
          this.searchValue,
          this.homePageSearchQueryData
        )
      ) {
        this.$emit("resetHomepageSearchParameters");
        this.isFilterSelected = false;
        this.selectedType = "";
        this.$emit("handleSearchQueryData", {
          val: this.searchValue,
          type: type,
        });
        this.$emit("handleSearchQueryData", {
          val: "",
          type: HOMEPAGE_SEARH_PARAMS.FOLDER_ID,
        });
        this.$emit("searchProjectList");
        this.$emit("handleSearch");
      }
    },
    getTabList() {
      if (this.isModuleVisualize) {
        return getHomeTabListByAuthority();
      } else {
        return getHomeScenarioTabListByAuthority();
      }
    },
    handleSelectTab() {
      this.$emit("tabChanged", this.activeTab);
    },
    sortByProjectList(sort) {
      this.activeSortItemId = sort.id;
      sort.type == SORT_BY.ASC
        ? (sort.type = SORT_BY.DESC)
        : (sort.type = SORT_BY.ASC);
      this.$emit("resetHomepageSearch");
      this.$emit("handleSearchQueryData", {
        val: `${sort?.value},${sort?.type}`,
        type: HOMEPAGE_SEARH_PARAMS.SORT_PARAMS,
      });
      this.$emit("searchProjectList");
    },
    resetSearchValue() {
      this.searchValue = "";
    },
    resetTabValue() {
      this.$emit("tabChanged", HOME_TABS_KEY.ALL);
    },
    closeSearchHandler() {
      this.$emit("closeHomePageSearch");
      this.resetSearchValue();
    },
    getAllProjects() {
      this.$emit("resetAllFilters");
      this.resetTabValue();
    },
    activeArrowIconClass(sortType) {
      return sortType == SORT_BY.DESC ? "vis-arrow-icon-up" : "";
    },
    activeSortItemClass(item) {
      return item.type && item.id == this.activeSortItemId
        ? "vis-font-bold"
        : "";
    },
    multipleSelectDropDownList() {
      return [
        {
          id: uuid(),
          label: this.$t("generalPages.delete"),
          serviceFn: () => this.$emit("deleteSelectedProjects"),
          isActive: true,
        },
        {
          id: uuid(),
          label: this.$t("generalPages.move"),
          serviceFn: () => this.$emit("moveMultipleProjects"),
          isActive: false,
        },
      ];
    },
  },
};
</script>
<style scoped>
.icon-color-black::before{
  color: #333333;
}
.search-input {
  width: 15rem;
}
.el-input :deep(.el-input__inner){
  border-radius: 25px;
  height: 40px;
}
.active-entity-icon{
  border: 1px solid #e1d6fa;
  background-color: #e1d6fa;
  border-radius: 25px;
}
.filter-icon{
  margin: 5px 15px !important;
  padding: 2px;
}
.filter-icon-tab {
  margin: 5px !important;
  padding: 2px;
}
.filter-card{
  display: inline-flex;
  border: 1px solid #e4e3e8;
  border-radius: 25px;
  margin-right: 30px
}
::v-deep .el-tabs {
  max-width: 70%;
  z-index: 0;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none !important;
}
::v-deep .el-tabs__item {
  padding: 0 25px;
  font-size: 0.75rem;
  color: var(--secondary-text);
  font-weight: normal;
  margin-bottom: 6px;
}
::v-deep .el-tabs__item:hover {
  color: var(--secondary-text);
}
::v-deep .el-tabs__item.is-active {
  color: var(--secondary-text);
  font-weight: 600;
}
::v-deep .el-tabs__header {
  margin: 0 !important;
}
::v-deep .el-tabs__active-bar {
  background-color: var(--primary);
}
.vis-vertical-align-middle {
  vertical-align: middle;
}
.vis-ml--none {
  margin-left: 0 !important;
}
.vis-sort-dropdown-ml--15 {
  margin-left: -15px !important;
}
.vis-sort-dropdown-min-width {
  min-width: 163px !important;
}
.el-tooltip__popper {
  background-color: #f0f0f0 !important;
  transform: translateY(-50%);
} 
.el-tooltip__arrow {
  background-color: #f0f0f0 !important;
}
@media only screen and (max-width: 1199px) {
  ::v-deep .el-tabs {
    max-width: 62% !important;
  }
  .vis-user-tabs-iconbox .vis-icon {
    margin-left: 15px !important;
  }
}
</style>
