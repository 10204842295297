/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { ACTION as ACTION_CONNECTIONS } from "../Connections/types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";
import { groupBy } from "lodash";
import {MUTATION as MUTATION_TEMP_STORAGE} from "../../temp-storage/types";
import { NOTIFY_TITLE } from "../../../../commons/notifierTypes";

export const visDataModel = {
  state: {
    [STATE.DATAMODELS]: [],
    [STATE.SELECTED_DATAMODEL]: {},
    [STATE.DATAMODEL_FIELDS]: {},
    [STATE.DATAMODEL_FILTER_PARAMS]: [],
  },
  getters: {
    [GETTER.GET_DATAMODELS]: (state) => {
      return state[STATE.DATAMODELS];
    },
    [GETTER.GET_SELECTED_DATAMODEL]: (state) => {
      return state[STATE.SELECTED_DATAMODEL];
    },
    [GETTER.GET_DATAMODEL_BY_ID_FIELDS]: (state) => {
      return state[STATE.DATAMODEL_FIELDS];
    },
    [GETTER.GET_DATAMODEL_BY_ID_FILTER_PARAMS]: (state) => {
      return state[STATE.DATAMODEL_FILTER_PARAMS];
    },
  },
  actions: {
    [ACTION.UPDATE_QUERY_TIMEOUT]: async ({ commit }, { datamodelId, bodyParam }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.updateQueryTimeout,
        queryParam: datamodelId,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_QUERY_STATUS]: async ({ commit }, { datamodelId, bodyParam }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.updateQueryStatus,
        queryParam: datamodelId,
        bodyParam: bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_DATAMODEL_NAME]: async ({ commit }, { bodyParam }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.updateDatamodelName,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_DATAMODEL_PROPERTIES]: async (
      { commit },
      { queryParam, bodyParam, withNotify = false }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.updateDatamodelProperties,
        queryParam,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withNotify,
      });
    },
    [ACTION.DELETE_DATAMODEL]: async ({ commit }, { queryParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.deleteDatamodel,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_DATAMODEL]: async (
      { commit, dispatch },
      { queryParam, bodyParam }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.updateDatamodel,
        queryParam,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
      await dispatch(ACTION.FETCH_DATAMODEL_BY_ID, queryParam);
    },
    [ACTION.COPY_DATAMODEL]: async ({ commit }, { loadingComponent, payload }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.copyDatamodel,
        queryParam: payload,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        loadingComponent,
      });
    },
    [ACTION.FETCH_DATAMODELS]: async (
      { commit },
      { loadingComponent, folderId }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.readDataModelList,
        successMutation: MUTATION.SET_DATAMODELS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        queryParam: folderId,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.FETCH_DATAMODEL_BY_ID]: async ({ commit, dispatch }, datamodelId) => {
      // call action ACTION.FETCH_CONNECTIONS from Visualize/Connections
      const connections = await dispatch(ACTION_CONNECTIONS.FETCH_CONNECTIONS);

      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.readDatamodelById,
        queryParam: datamodelId,
        successMutation: MUTATION.SET_SELECTED_DATAMODEL,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => {
            const datamodel = result.data;

            // for each datasets in datamodel, there is a connectionId
            // find the connection in connections using connectionId
            // and add the connection object to each dataset
            datamodel.datasets.forEach(dataset => {
                dataset.connection = connections.find(
                    connection => connection.connectionId === dataset.connectionId
                );
            });

            return datamodel;
        },
        withLoading: true,
      });
    },
    [ACTION.FETCH_DATAMODEL_BY_ID_FILTER_PARAMS]: async ({ commit }, { loadingComponent, id }) => {
      await vuexActionHandler(
          {
              commit,
              serviceMethodParent: serviceMethodParent.DataModel,
              serviceMethodSub: serviceMethodSub.readDatamodelFilterParamsById,
              queryParam: id,
              successMutation: MUTATION.SET_DATAMODEL_FILTER_PARAMS,
              errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
              resultSelector: (result) => result.data,
              withLoading: true,
              loadingComponent,
          },
      );
  },
  [ACTION.FETCH_DATAMODEL_BY_ID_FIELDS]: async ({ commit }, id) => {
    const result = await vuexActionHandler(
        {
            commit,
            serviceMethodParent: serviceMethodParent.DataModel,
            serviceMethodSub: serviceMethodSub.readDatamodelFieldsById,
            queryParam: id,
            successMutation: MUTATION.SET_DATAMODEL_FIELDS,
            errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
            resultSelector: (result) => result.data,
            withLoading: true
        },
    );
    commit(MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_DATASET_FIELDS, result)

},
    [ACTION.CREATE_DATAMODEL]: async ({ commit }, payload) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.createDatamodel,
        bodyParam: payload,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.MOVE_DATAMODEL]: async ({ commit }, { itemId, targetFolderId }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataModel,
        serviceMethodSub: serviceMethodSub.moveDatamodel,
        queryParam: { itemId, targetFolderId: targetFolderId ?? "" },
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.DUPLICATE_DATAMODEL_FIELD]: async ({ commit }, { bodyParam } ) => {
      await vuexActionHandler(
          {
              commit,
              serviceMethodParent: serviceMethodParent.DataModel,
              serviceMethodSub: serviceMethodSub.duplicateDatamodelField,
              bodyParam,
              resultSelector: (result) => result.data,
              withLoading: true,
              withNotify: true,
              notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
          },
      );
    },
    [ACTION.FETCH_HIGHEST_PRIORITY_BY_DATAMODEL_ID]: async ({ commit }, { datamodelId }) => {
      return await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.DataModel,
          serviceMethodSub: serviceMethodSub.readHighestPriorityByDatamodelId,
          queryParam: datamodelId,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
        },
      );
    },
    [ACTION.CREATE_BY_DATAMODEL_WIZARD]: async ({ commit }, { bodyParam, loadingComponent }) => {
      return await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.DataModel,
          serviceMethodSub: serviceMethodSub.createByDatamodelWizard,
          bodyParam,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          loadingComponent,
        },
      );
    },
    [ACTION.ADD_DATASETS_BY_DATAMODEL_WIZARD]: async ({ commit }, { bodyParam, loadingComponent }) => {
      return await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dataset,
          serviceMethodSub: serviceMethodSub.addDatasetsByDatamodelWizard,
          bodyParam,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          loadingComponent,
        },
      );
    },
  },
  mutations: {
    [MUTATION.SET_DATAMODELS](state, items) {
      Vue.set(state, [STATE.DATAMODELS], items);
    },
    [MUTATION.SET_SELECTED_DATAMODEL](state, items) {
      Vue.set(state, [STATE.SELECTED_DATAMODEL], items);
    },
    [MUTATION.SET_SELECTED_DATAMODEL_QUERY_TIMEOUT](state, newValue) {
      Vue.set(state[STATE.SELECTED_DATAMODEL], "queryTimeout", newValue);
    },
    [MUTATION.SET_SELECTED_DATASET](state, payload) {
      const findIndex = state[STATE.SELECTED_DATAMODEL]?.datasets.findIndex(
        (x) => x.datasetId === payload.datasetId
      );
      Vue.set(state[STATE.SELECTED_DATAMODEL]?.datasets, findIndex, payload);
    },
    [MUTATION.SET_DATAMODEL_FIELDS](state, items) {
      Vue.set(state, STATE.DATAMODEL_FIELDS,  groupBy(items, x => x.datasetId));
    },
    [MUTATION.RESET_DATAMODEL_FIELDS](state, items) {
      Vue.set(state, [STATE.DATAMODEL_FIELDS], {});
    },
    [MUTATION.SET_DATAMODEL_FILTER_PARAMS](state, items) {
      state[STATE.DATAMODEL_FILTER_PARAMS].push(...items);
    },
    [MUTATION.RESET_DATAMODEL_FILTER_PARAMS](state, items) {
      Vue.set(state, [STATE.DATAMODEL_FILTER_PARAMS], []);
    },
  },
};
