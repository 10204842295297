<template>
  <div>
    <div style="max-height: 250px; margin-bottom: 20px; overflow: auto">
      <div class="data-flow-linker-relationType vis-row">
        <div class="vis-col vis-col-4">
          <label>Dataset</label>
          <el-input :value="firstDatasetName" :disabled="true"> </el-input>
        </div>
        <div class="vis-col vis-col-4">
          <label style="text-align: center">Join Type</label>
          <el-select v-model="joinType" placeholder="Join">
            <template slot="prefix"
              ><span style="line-height: 30px">
                <i
                  :class="getRelationTypeIconClass(joinType)"
                  class="selected-connection-action-icon vis-cursor-pointer"
                  aria-hidden="true"
                >
                </i> </span
            ></template>
            <el-option
              v-for="item in joinTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
              <span style="float: left"
                ><i
                  :class="getRelationTypeIconClass(item.value)"
                  class="option-connection-action-icon vis-cursor-pointer"
                  aria-hidden="true"
                >
                </i>
              </span>
            </el-option>
          </el-select>
        </div>
        <div class="vis-col vis-col-4">
          <label>Dataset</label>
          <el-input :value="secondDatasetName" :disabled="true"> </el-input>
        </div>
      </div>

      <div class="data-flow-linker-relationType vis-row">
        <div class="vis-col vis-col-4 vis-mb--none">
          <label>Data Item</label>
        </div>
        <div class="vis-col vis-col-4 vis-mb--none"></div>
        <div class="vis-col vis-col-4 vis-mb--none">
          <label>Data Item</label>
        </div>
      </div>
      <div
        v-for="(join, index) in joins"
        :key="index"
        class="data-flow-linker-selections vis-row"
      >
        <div class="vis-col vis-col-4">
          <el-select v-model="join.left" placeholder="First column">
            <el-option
              v-for="item in optionsFirstColumn"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="vis-col vis-col-4">
          <div class="divider-delete-container">
            <el-divider
              ><i
                :class="CustomIcon.TrashCanOutline"
                class="divider-delete-icon"
                @click="removeJoinOnIndex(index)"
              ></i
            ></el-divider>
          </div>
        </div>

        <div class="vis-col vis-col-4">
          <el-select v-model="join.right" placeholder="Second column">
            <el-option
              v-for="item in optionsSecondColumn"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <el-link class="data-flow-linker-add" :underline="false" @click="addLink"
        >Add link</el-link
      >
    </div>

    <div class="data-flow-linker-footer vis-row">
      <div v-if="joinType === 'INNER'" class="vis-col vis-col-9">
        <div class="data-flow-linker-footer-explanation">
          <span>Only matching rows from</span>
          <span class="vis-font-medium">{{ firstDatasetName }}</span>
          <span>and</span>
          <span class="vis-font-medium">{{ secondDatasetName }}</span>
        </div>
      </div>
      <div v-if="joinType === 'LEFT'" class="vis-col vis-col-9">
        <div class="data-flow-linker-footer-explanation">
          <span>All rows from</span>
          <span class="vis-font-medium">{{ firstDatasetName }}</span>
          <span>and only matching</span>
          <span class="vis-font-medium">{{ secondDatasetName }}</span>
        </div>
      </div>
      <div v-if="joinType === 'RIGHT'" class="vis-col vis-col-9">
        <div class="data-flow-linker-footer-explanation">
          <span>Only matching rows from</span>
          <span class="vis-font-medium">{{ firstDatasetName }}</span>
          <span>and all rows from</span>
          <span class="vis-font-medium">{{ secondDatasetName }}</span>
        </div>
      </div>
      <div v-if="joinType === 'FULL'" class="vis-col vis-col-9">
        <div class="data-flow-linker-footer-explanation">
          <span>All rows from</span>
          <span class="vis-font-medium">{{ firstDatasetName }}</span>
          <span>and</span>
          <span class="vis-font-medium">{{ secondDatasetName }}</span>
        </div>
      </div>

      <div
        class="vis-col vis-col-3"
        :class="{
          'vis-col-3': joinType,
          'vis-col-12': !joinType,
        }"
      >
        <div class="data-flow-linker-footer-actions">
          <el-button size="mini" type="text" @click="handleClose"
            >Cancel</el-button
          >
          <el-button type="primary" size="mini" @click="confirmClicked"
            >Done</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../../assets/js/custom-icons";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import cloneDeep from "clone-deep";

export default {
  props: {
    connectionPopupLinks: {
      type: Array,
    },
    datasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dataModelCards: {
      type: Array,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      joinTypes: [
        {
          value: "INNER",
          label: "Inner Join",
        },
        {
          value: "LEFT",
          label: "Left Join",
        },
        {
          value: "RIGHT",
          label: "Right Join",
        },
        {
          value: "FULL",
          label: "Full Join",
        },
      ],
      value1: [],
      value2: [],
      joins: [],
      joinType: null,
    };
  },
  mounted() {
    this.initJoins();
  },
  computed: {
    firstDatasetName() {
      return this.dataModelCards?.find(
        (d) => d.id === this.connectionPopupLinks[0].leftDatasetId
      )?.name;
    },
    secondDatasetName() {
      return this.dataModelCards?.find(
        (d) => d.id === this.connectionPopupLinks[0].rightDatasetId
      )?.name;
    },
    optionsFirstColumn() {
      return this.datasetFields?.[
        this.connectionPopupLinks[0]?.leftDatasetId
      ]?.map((df) => ({
        value: df.fieldId,
        label: df.alias,
      }));
    },
    optionsSecondColumn() {
      return this.datasetFields?.[
        this.connectionPopupLinks[0]?.rightDatasetId
      ]?.map((df) => ({
        value: df.fieldId,
        label: df.alias,
      }));
    },
  },
  methods: {
    handleClose() {
      if (this.connectionPopupLinks[0]?.id) {
        this.$emit("close");

        return;
      }

      this.$emit("closeAndRemoveLink");
    },
    removeJoinOnIndex(index) {
      const clonedJoins = cloneDeep(this.joins);

      clonedJoins.splice(index, 1);

      this.joins = clonedJoins;
    },
    initJoins() {
      if (
        !this.connectionPopupLinks?.[0]?.leftDatasetFieldId ||
        !this.connectionPopupLinks?.[0]?.rightDatasetFieldId ||
        !this.connectionPopupLinks?.[0]?.relationType
      ) {
        this.joins.push({
          left: "",
          right: "",
          leftDatasetId: this.connectionPopupLinks[0].leftDatasetId,
          rightDatasetId: this.connectionPopupLinks[0].rightDatasetId,
        });

        return;
      }

      this.joins = this.connectionPopupLinks.map((c) => {
        return {
          left: c.leftDatasetFieldId,
          right: c.rightDatasetFieldId,
          leftDatasetId: c.leftDatasetId,
          rightDatasetId: c.rightDatasetId,
          id: c.id,
        };
      });

      this.joinType = this.connectionPopupLinks[0].relationType;
    },
    confirmClicked() {
      if (!this.joinType || !this.joins[0].left || !this.joins[0].right) {
        Notify(
          "Please fill all the fields on link modal",
          notificationType.WARNING
        );
        return;
      }

      this.$emit(
        "add",
        this.joins.map((j) => ({ ...j, type: this.joinType }))
      );
    },
    getRelationTypeIconClass(type) {
      const connectionImage = type
        ? CustomIcon[
            "Join" + type.charAt(0).toUpperCase() + type.toLowerCase().slice(1)
          ]
        : "";

      return connectionImage;
    },
    addLink() {
      this.joins.push({
        left: "",
        right: "",
        leftDatasetId: this.connectionPopupLinks[0].leftDatasetId,
        rightDatasetId: this.connectionPopupLinks[0].rightDatasetId,
      });
    },
  },
};
</script>

<style scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  color: black;
  font-weight: 500;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 55px;
}
::v-deep .el-input__prefix {
  left: 15px !important;
}
::v-deep .el-divider--horizontal {
  margin: 20px 0;
}
.divider-delete-container .divider-delete-icon {
  display: none;
}
.divider-delete-container:hover .divider-delete-icon {
  display: block;
}
.data-flow-linker-selections {
  align-items: center;
}
.data-flow-linker-relationType {
  margin-top: 20px;
}
.divider {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin: 5px;
}
.data-flow-linker-add {
  margin-top: 20px;
  color: var(--primary-lighteen-1) !important;
  margin-left: 15px;
}
.data-flow-linker-footer {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.data-flow-linker-footer-explanation {
  display: flex;
  column-gap: 5px;
}
.data-flow-linker-footer-actions {
  text-align: right;
}
.ellipsisLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected-dataset-label {
  color: gray;
  text-transform: uppercase;
  font-size: 1rem;
  max-width: 150px;
}
.option-connection-action-icon {
  font-size: 30px;
  color: gray;
}
.selected-connection-action-icon {
  font-size: 25px;
  color: gray;
  vertical-align: middle;
}
.divider-delete-icon {
  font-size: 30px;
  cursor: pointer;
  color: var(--primary-lighteen-1);
}
</style>
