import { ACTION as ACTION_TEST_QUERY } from "../store/modules/Visualize/TestQuery/types";
import { mapActions } from "vuex";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import panelDataPropertiesStyle from "../mixins/panelDataPropertiesStyle";
import {
  chartServices,
  checkIfChartIsEligibleToCallService,
  getAggregationCombinations,
  getSearchBodyParam,
  getSearchResponseError,
} from "../util/chartSearchServices";
import { getDetailsDataByAuthorize } from "../commons/dashboardAndPanel";

export default {
  mixin: [panelDataPropertiesStyle],
  data() {
    return {
      options: {},
      sqlQuery: "",
      selectedDbType: "",
      searchResponseError: {}
    };
  },
  methods: {
    ...mapActions({
      fetchSearchWithoutMutate: ACTION_TEST_QUERY.FETCH_SEARCH_WITHOUT_MUTATE,
    }),
    async viewChart() {
      this.options = {};

      const cacheInfoForSearchRequest = this.getCacheInfo(this.selectedPanel);
      let params = getSearchBodyParam(this.selectedPanel, cacheInfoForSearchRequest);
      const chartType = params?.chartType ?? this.chartType;
     
      await this.callChartService(chartType, params);
    },
    async callChartService(chartType, params) {
      if (!params?.metric) return false;
      
      const callService = checkIfChartIsEligibleToCallService(
        chartType,
        params
      );

      if (!callService) return;

      const response = await this.fetchSearchWithoutMutate({
        loadingComponent: LoadingComponent.GetSearch,
        bodyParam: params,
      });

      getSearchResponseError(response, this.selectedPanel?.i, this.searchResponseError);

      if (!response) return false;

      this.sqlQuery = response.sql;
      this.selectedDbType = response.dbTypes;

      const chartDetails = getDetailsDataByAuthorize({
        chartDataDetails: params,
        unAuthorizedDashboardFields: this.unAuthorizedDashboardFields,
      });

      this.options = {
        ...chartServices[chartType].serviceFn(chartDetails, response),
        searchFilterList: getAggregationCombinations(
          params,
          response?.aggregations
        ),
      };
    },
  },
};
