export const calculatedColumnOracleFunctions = {
  "ORACLE.AVG": {
    key: "ORACLE.AVG",
    explanation: "calculatedColumnExplanation.oracle.oracleAvg",
    example: "AVG([ DISTINCT | ALL ] expr) [ OVER(analytic_clause) ]",
  },
  "ORACLE.CASE": {
    key: "ORACLE.CASE",
    explanation: "calculatedColumnExplanation.oracle.oracleCase",
    example:
      "[ <<label_name>> ] CASE case_operand { WHEN when_operand THEN { _statement_ ;} ... }... [ ELSE { _statement_ ;} ... ] END CASE [ label_name ];",
  },
  "ORACLE.COUNT": {
    key: "ORACLE.COUNT",
    explanation: "calculatedColumnExplanation.oracle.oracleCount",
    example:
      "COUNT({ * | [ DISTINCT | ALL ] expr }) [ OVER (analytic_clause) ]",
  },
  "ORACLE.COUNT(DISTINCT)": {
    key: "ORACLE.COUNT(DISTINCT)",
    explanation: "calculatedColumnExplanation.oracle.oracleCountDistinct",
    example:
      "ORACLE.COUNT(DISTINCT expr)",
  },
  "ORACLE.MAX": {
    key: "ORACLE.MAX",
    explanation: "calculatedColumnExplanation.oracle.oracleMax",
    example: "MAX([ DISTINCT | ALL ] expr) [ OVER (analytic_clause) ]",
  },
  "ORACLE.MIN": {
    key: "ORACLE.MIN",
    explanation: "calculatedColumnExplanation.oracle.oracleMin",
    example: "MIN([ DISTINCT | ALL ] expr) [ OVER (analytic_clause) ]",
  },
  "ORACLE.SUM": {
    key: "ORACLE.SUM",
    explanation: "calculatedColumnExplanation.oracle.oracleSum",
    example: "SUM([ DISTINCT | ALL ] expr) [ OVER (analytic_clause) ]",
  },
  "ORACLE.CAST": {
    key: "ORACLE.CAST",
    explanation: "calculatedColumnExplanation.oracle.oracleCast",
    example:
      "CAST({ expr | MULTISET (subquery) } AS type_name[ DEFAULT return_value ON CONVERSION ERROR ][, fmt [, 'nlsparam' ] ])",
  },
  "ORACLE.TO_CHAR": {
    key: "ORACLE.TO_CHAR",
    multipleExplanation: [
      {
        key: "(character)",
        explanation: "calculatedColumnExplanation.oracle.oracleToCharCharacter",
        example: "TO_CHAR(nchar | clob | nclob)",
      },
      {
        key: "(datetime)",
        explanation: "calculatedColumnExplanation.oracle.oracleToCharDatetime",
        example: "TO_CHAR({ datetime | interval } [, fmt [, 'nlsparam' ] ])",
      },
      {
        key: "(number)",
        explanation: "calculatedColumnExplanation.oracle.oracleToCharNumber",
        example: "TO_CHAR(n [, fmt [, 'nlsparam' ] ])",
      },
    ],
  },
  "ORACLE.TO_CLOB": {
    key: "ORACLE.TO_CLOB",
    explanation: "calculatedColumnExplanation.oracle.oracleToClob",
    example: "TO_CLOB(lob_column | char)",
  },
  "ORACLE.TO_DATE": {
    key: "ORACLE.TO_DATE",
    explanation: "calculatedColumnExplanation.oracle.oracleToDate",
    example:
      "TO_DATE(char [ DEFAULT return_value ON CONVERSION ERROR ][, fmt [, 'nlsparam' ] ])",
  },
  "ORACLE.TO_DSINTERVAL": {
    key: "ORACLE.TO_DSINTERVAL",
    explanation: "calculatedColumnExplanation.oracle.oracleToDsinterval",
    example:
      "TO_DSINTERVAL ( ' { sql_format | ds_iso_format } ' [ DEFAULT return_value ON CONVERSION ERROR ] )",
  },
  "ORACLE.TO_LOB": {
    key: "ORACLE.TO_LOB",
    explanation: "calculatedColumnExplanation.oracle.oracleToLob",
    example: "TO_LOB(long_column)",
  },
  "ORACLE.TO_MULTI_BYTE": {
    key: "ORACLE.TO_MULTI_BYTE",
    explanation: "calculatedColumnExplanation.oracle.oracleToMultiByte",
    example: "TO_MULTI_BYTE(char)",
  },
  "ORACLE.TO_NCHAR": {
    key: "ORACLE.TO_NCHAR",
    multipleExplanation: [
      {
        key: "(character)",
        explanation:
          "calculatedColumnExplanation.oracle.oracleToNcharCharacter",
        example: "TO_NCHAR({char | clob | nclob})",
      },
      {
        key: "(datetime)",
        explanation: "calculatedColumnExplanation.oracle.oracleToNcharDatetime",
        example: "TO_NCHAR({ datetime | interval }[, fmt [, 'nlsparam' ] ])",
      },
      {
        key: "(number)",
        explanation: "calculatedColumnExplanation.oracle.oracleToNcharNumber",
        example: "TO_NCHAR(n [, fmt [, 'nlsparam' ] ])",
      },
    ],
  },
  "ORACLE.TO_NCLOB": {
    key: "ORACLE.TO_NCLOB",
    explanation: "calculatedColumnExplanation.oracle.oracleToNclob",
    example: "TO_NCLOB(lob_column | char)",
  },
  "ORACLE.TO_NUMBER": {
    key: "ORACLE.TO_NUMBER",
    explanation: "calculatedColumnExplanation.oracle.oracleToNumber",
    example:
      "TO_NUMBER(expr [ DEFAULT return_value ON CONVERSION ERROR ][, fmt [, 'nlsparam' ] ])",
  },
  "ORACLE.TO_SINGLE_BYTE": {
    key: "ORACLE.TO_SINGLE_BYTE",
    explanation: "calculatedColumnExplanation.oracle.oracleToSingleByte",
    example: "TO_SINGLE_BYTE(char)",
  },
  "ORACLE.TO_TIMESTAMP": {
    key: "ORACLE.TO_TIMESTAMP",
    explanation: "calculatedColumnExplanation.oracle.oracleToTimestamp",
    example:
      "TO_TIMESTAMP(char [ DEFAULT return_value ON CONVERSION ERROR ][, fmt [, 'nlsparam' ] ])",
  },
  "ORACLE.ADD_MONTHS": {
    key: "ORACLE.ADD_MONTHS",
    explanation: "calculatedColumnExplanation.oracle.oracleAddMonths",
    example: "ADD_MONTHS(date, integer)",
  },
  "ORACLE.CURRENT_DATE": {
    key: "ORACLE.CURRENT_DATE",
    explanation: "calculatedColumnExplanation.oracle.oracleCurrentDate",
    example: "CURRENT_DATE",
  },
  "ORACLE.CURRENT_TIMESTAMP": {
    key: "ORACLE.CURRENT_TIMESTAMP",
    explanation: "calculatedColumnExplanation.oracle.oracleCurrentTimestamp",
    example: "CURRENT_TIMESTAMP [ (precision) ]",
  },
  "ORACLE.DBTIMEZONE": {
    key: "ORACLE.DBTIMEZONE",
    explanation: "calculatedColumnExplanation.oracle.oracleDbTimezone",
    example: "DBTIMEZONE",
  },
  "ORACLE.LAST_DAY": {
    key: "ORACLE.LAST_DAY",
    explanation: "calculatedColumnExplanation.oracle.oracleLastDay",
    example: "LAST_DAY(date)",
  },
  "ORACLE.LOCALTIMESTAMP": {
    key: "ORACLE.LOCALTIMESTAMP",
    explanation: "calculatedColumnExplanation.oracle.oracleLocalTimestamp",
    example: "LOCALTIMESTAMP [ (timestamp_precision) ]",
  },
  "ORACLE.MONTHS_BETWEEN": {
    key: "ORACLE.MONTHS_BETWEEN",
    explanation: "calculatedColumnExplanation.oracle.oracleMonthsBetween",
    example: "MONTHS_BETWEEN(date1, date2)",
  },
  "ORACLE.NEW_TIME": {
    key: "ORACLE.NEW_TIME",
    explanation: "calculatedColumnExplanation.oracle.oracleNewTime",
    example: "NEW_TIME(date, timezone1, timezone2)",
  },
  "ORACLE.NEXT_DAY": {
    key: "ORACLE.NEXT_DAY",
    explanation: "calculatedColumnExplanation.oracle.oracleNextDay",
    example: "NEXT_DAY(date, char)",
  },
  "ORACLE.SESSIONTIMEZONE": {
    key: "ORACLE.SESSIONTIMEZONE",
    explanation: "calculatedColumnExplanation.oracle.oracleSessionTimezone",
    example: "SESSIONTIMEZONE",
  },
  "ORACLE.SYS_EXTRACT_UTC": {
    key: "ORACLE.SYS_EXTRACT_UTC",
    explanation: "calculatedColumnExplanation.oracle.oracleSysExtractUtc",
    example: "SYS_EXTRACT_UTC(datetime_with_timezone)",
  },
  "ORACLE.SYSDATE": {
    key: "ORACLE.SYSDATE",
    explanation: "calculatedColumnExplanation.oracle.oracleSysdate",
    example: "SYSDATE",
  },
  "ORACLE.SYSTIMESTAMP": {
    key: "ORACLE.SYSTIMESTAMP",
    explanation: "calculatedColumnExplanation.oracle.oracleSysTimestamp",
    example: "SYSTIMESTAMP",
  },
  "ORACLE.TZ_OFFSET": {
    key: "ORACLE.TZ_OFFSET",
    explanation: "calculatedColumnExplanation.oracle.oracleTzOffset",
    example:
      "TZ_OFFSET({ 'time_zone_name'| '{ + | - } hh : mi' | SESSIONTIMEZONE| DBTIMEZONE })",
  },
  "ORACLE.ABS": {
    key: "ORACLE.ABS",
    explanation: "calculatedColumnExplanation.oracle.oracleAbs",
    example: "ABS(n)",
  },
  "ORACLE.ACOS": {
    key: "ORACLE.ACOS",
    explanation: "calculatedColumnExplanation.oracle.oracleAcos",
    example: "ACOS(n)",
  },
  "ORACLE.ASIN": {
    key: "ORACLE.ASIN",
    explanation: "calculatedColumnExplanation.oracle.oracleAsin",
    example: "ASIN(n)",
  },
  "ORACLE.ATAN2": {
    key: "ORACLE.ATAN2",
    explanation: "calculatedColumnExplanation.oracle.oracleAtan",
    example: "ATAN2(n1 , n2)",
  },
  "ORACLE.BITAND": {
    key: "ORACLE.BITAND",
    explanation: "calculatedColumnExplanation.oracle.oracleBitand",
    example: "BITAND(expr1, expr2)",
  },
  "ORACLE.CEIL": {
    key: "ORACLE.CEIL",
    explanation: "calculatedColumnExplanation.oracle.oracleCeil",
    example: "CEIL(n)",
  },
  "ORACLE.CORR": {
    key: "ORACLE.CORR",
    explanation: "calculatedColumnExplanation.oracle.oracleCorr",
    example: "CORR(expr1, expr2) [ OVER (analytic_clause) ]",
  },
  "ORACLE.COS": {
    key: "ORACLE.COS",
    explanation: "calculatedColumnExplanation.oracle.oracleCos",
    example: "COS(n)",
  },
  "ORACLE.POWER": {
    key: "ORACLE.POWER",
    explanation: "calculatedColumnExplanation.oracle.oraclePower",
    example: "POWER(n2, n1)",
  },
  "ORACLE.RANK": {
    key: "ORACLE.RANK",
    explanation: "calculatedColumnExplanation.oracle.oracleRank",
    example:
      "RANK(expr [, expr ]...) WITHIN GROUP (ORDER BY expr [ DESC | ASC ]  [ NULLS { FIRST | LAST } ] [, expr [ DESC | ASC ] [ NULLS { FIRST | LAST } ] ]...  )",
  },
  "ORACLE.FLOOR": {
    key: "ORACLE.FLOOR",
    explanation: "calculatedColumnExplanation.oracle.oracleFloor",
    example: "FLOOR(n)",
  },
  "ORACLE.GREATEST": {
    key: "ORACLE.GREATEST",
    explanation: "calculatedColumnExplanation.oracle.oracleGreatest",
    example: "GREATEST(expr [, expr ]...)",
  },
  "ORACLE.LEAST": {
    key: "ORACLE.LEAST",
    explanation: "calculatedColumnExplanation.oracle.oracleLeast",
    example: "LEAST(expr [, expr ]...)",
  },
  "ORACLE.LN": {
    key: "ORACLE.LN",
    explanation: "calculatedColumnExplanation.oracle.oracleLn",
    example: "LN(n)",
  },
  "ORACLE.LOG": {
    key: "ORACLE.LOG",
    explanation: "calculatedColumnExplanation.oracle.oracleLog",
    example: "LOG(n2, n1)",
  },
  "ORACLE.MEDIAN": {
    key: "ORACLE.MEDIAN",
    explanation: "calculatedColumnExplanation.oracle.oracleMedian",
    example: "MEDIAN(expr) [ OVER ( [ query_partition_clause ] ) ]",
  },
  "ORACLE.NVL": {
    key: "ORACLE.NVL",
    explanation: "calculatedColumnExplanation.oracle.oracleNvl",
    example: "NVL(expr1, expr2)",
  },
  "ORACLE.NVL2": {
    key: "ORACLE.NVL2",
    explanation: "calculatedColumnExplanation.oracle.oracleNvl2",
    example: "NVL2(expr1, expr2, expr3)",
  },
  "ORACLE.NULLIF": {
    key: "ORACLE.NULLIF",
    explanation: "calculatedColumnExplanation.oracle.oracleNullIf",
    example: "NULLIF(expr1, expr2)",
  },
  "ORACLE.REGEXP_COUNT": {
    key: "ORACLE.REGEXP_COUNT",
    explanation: "calculatedColumnExplanation.oracle.oracleRegexpCount",
    example: "REGEXP_COUNT (source_char, pattern [, position [, match_param]])",
  },
  "ORACLE.REGEXP_INSTR": {
    key: "ORACLE.REGEXP_INSTR",
    explanation: "calculatedColumnExplanation.oracle.oracleRegexpInstr",
    example:
      "REGEXP_INSTR ( source_char, pattern    [, position   [, occurrence [, return_opt [, match_param [, subexpr ] ]  ]  ] ] )",
  },
  "ORACLE.SUBSTR": {
    key: "ORACLE.SUBSTR",
    explanation: "calculatedColumnExplanation.oracle.oracleSubstr",
    example:
      "{ SUBSTR    | SUBSTRB    | SUBSTRC    | SUBSTR2    | SUBSTR4    }    (char, position [, substring_length ])",
  },
  "ORACLE.CONCAT": {
    key: "ORACLE.CONCAT",
    explanation: "calculatedColumnExplanation.oracle.oracleConcat",
    example: "CONCAT(char1, char2)",
  },
  "ORACLE.TRANSLATE": {
    key: "ORACLE.TRANSLATE",
    explanation: "calculatedColumnExplanation.oracle.oracleTranslate",
    example: "TRANSLATE(expr, from_string, to_string)",
  },
  "ORACLE.REGEXP_REPLACE": {
    key: "ORACLE.REGEXP_REPLACE",
    explanation: "calculatedColumnExplanation.oracle.oracleRegexpReplace",
    example:
      "REGEXP_REPLACE ( source_char, pattern [, replace_string [, position  [, occurrence [, match_param ] ] ]  ])",
  },
  "ORACLE.CONVERT": {
    key: "ORACLE.CONVERT",
    explanation: "calculatedColumnExplanation.oracle.oracleConvert",
    example: "CONVERT(char, dest_char_set[, source_char_set ])",
  },
};

export const calculatedColumnMsSqlFunctions = {
  "MSSQL.COUNT(DISTINCT)": {
    key: "MSSQL.COUNT(DISTINCT)",
    explanation: "calculatedColumnExplanation.msSql.msSqlCountDistinct",
    example:
      "MSSQL.COUNT(DISTINCT expr)",
  },
  "MSSQL.NULLIF": {
    key: "MSSQL.NULLIF",
    explanation: "calculatedColumnExplanation.msSql.msSqlNullIf",
    example: "NULLIF(expr1, expr2)",
  },
  "MSSQL.ISNULL": {
    key: "MSSQL.ISNULL",
    explanation: "calculatedColumnExplanation.msSql.msSqlIsNull",
    example: "ISNULL(expression, value)",
  },
  "MSSQL.CASE": {
    key: "MSSQL.CASE",
    explanation: "calculatedColumnExplanation.msSql.msSqlCase",
    example:
      "[ <<label_name>> ] CASE case_operand { WHEN when_operand THEN { _statement_ ;} ... }... [ ELSE { _statement_ ;} ... ] END CASE [ label_name ];",
  },
  "MSSQL.UPPER": {
    key: "MSSQL.UPPER",
    explanation: "calculatedColumnExplanation.msSql.msSqlUpper",
    example: "UPPER(text)",
  },
  "MSSQL.SUBSTRING": {
    key: "MSSQL.SUBSTRING",
    explanation: "calculatedColumnExplanation.msSql.msSqlSubstring",
    example: "SUBSTRING(string, start, length)",
  },
  "MSSQL.STR": {
    key: "MSSQL.STR",
    explanation: "calculatedColumnExplanation.msSql.msSqlStr",
    example: "STR(number, length, decimals)",
  },
  "MSSQL.RTRIM": {
    key: "MSSQL.RTRIM",
    explanation: "calculatedColumnExplanation.msSql.msSqlRtrim",
    example: "RTRIM(string)",
  },
  "MSSQL.RIGHT": {
    key: "MSSQL.RIGHT",
    explanation: "calculatedColumnExplanation.msSql.msSqlRight",
    example: "RIGHT(string, number_of_chars)",
  },
  "MSSQL.REPLACE": {
    key: "MSSQL.REPLACE",
    explanation: "calculatedColumnExplanation.msSql.msSqlReplace",
    example: "REPLACE(string, old_string, new_string)",
  },
  "MSSQL.LTRIM": {
    key: "MSSQL.LTRIM",
    explanation: "calculatedColumnExplanation.msSql.msSqlLtrim",
    example: "LTRIM(string)",
  },
  "MSSQL.LOWER": {
    key: "MSSQL.LOWER",
    explanation: "calculatedColumnExplanation.msSql.msSqlLower",
    example: "LOWER(text)",
  },
  "MSSQL.LEN": {
    key: "MSSQL.LEN",
    explanation: "calculatedColumnExplanation.msSql.msSqlLen",
    example: "LEN(string)",
  },
  "MSSQL.LEFT": {
    key: "MSSQL.LEFT",
    explanation: "calculatedColumnExplanation.msSql.msSqlLeft",
    example: "LEFT(string, number_of_chars)",
  },
  "MSSQL.DIFFERENCE": {
    key: "MSSQL.DIFFERENCE",
    explanation: "calculatedColumnExplanation.msSql.msSqlDifference",
    example: "DIFFERENCE(expression, expression)",
  },
  "MSSQL.YEAR": {
    key: "MSSQL.YEAR",
    explanation: "calculatedColumnExplanation.msSql.msSqlYear",
    example: "YEAR(date)",
  },
  "MSSQL.MONTH": {
    key: "MSSQL.MONTH",
    explanation: "calculatedColumnExplanation.msSql.msSqlMonth",
    example: "MONTH(date)",
  },
  "MSSQL.DAY": {
    key: "MSSQL.DAY",
    explanation: "calculatedColumnExplanation.msSql.msSqlDay",
    example: "DAY(date)",
  },
  "MSSQL.DATEPART": {
    key: "MSSQL.DATEPART",
    explanation: "calculatedColumnExplanation.msSql.msSqlDatepart",
    example: "DATEPART(interval, date)",
  },
  "MSSQL.GETDATE": {
    key: "MSSQL.GETDATE",
    explanation: "calculatedColumnExplanation.msSql.msSqlGetDate",
    example: "GETDATE()",
  },
  "MSSQL.DATEDIFF": {
    key: "MSSQL.DATEDIFF",
    explanation: "calculatedColumnExplanation.msSql.msSqlDateDiff",
    example: "DATEDIFF(interval, date1, date2)",
  },
  "MSSQL.DATENAME": {
    key: "MSSQL.DATENAME",
    explanation: "calculatedColumnExplanation.msSql.msSqlDateName",
    example: "DATENAME(interval, date)",
  },
  "MSSQL.DATEADD": {
    key: "MSSQL.DATEADD",
    explanation: "calculatedColumnExplanation.msSql.msSqlDateAdd",
    example: "DATEADD(interval, number, date)",
  },
  "MSSQL.ROUND": {
    key: "MSSQL.ROUND",
    explanation: "calculatedColumnExplanation.msSql.msSqlRound",
    example: "ROUND(number, decimals, operation)",
  },
  "MSSQL.POWER": {
    key: "MSSQL.POWER",
    explanation: "calculatedColumnExplanation.msSql.msSqlPower",
    example: "POWER(a, b)",
  },
  "MSSQL.LOG": {
    key: "MSSQL.LOG",
    explanation: "calculatedColumnExplanation.msSql.msSqlLog",
    example: " LOG(number, base) -- Syntax for SQL Server",
  },
  "MSSQL.FLOOR": {
    key: "MSSQL.FLOOR",
    explanation: "calculatedColumnExplanation.msSql.msSqlFloor",
    example: "FLOOR(number)",
  },
  "MSSQL.CEILING": {
    key: "MSSQL.CEILING",
    explanation: "calculatedColumnExplanation.msSql.msSqlCeiling",
    example: "CEILING(number)",
  },
  "MSSQL.ABS": {
    key: "MSSQL.ABS",
    explanation: "calculatedColumnExplanation.msSql.msSqlAbs",
    example: "ABS(number)",
  },
  "MSSQL.CONVERT": {
    key: "MSSQL.CONVERT",
    explanation: "calculatedColumnExplanation.msSql.msSqlConvert",
    example: "CONVERT(data_type(length), expression, style)",
  },
  "MSSQL.CAST": {
    key: "MSSQL.CAST",
    explanation: "calculatedColumnExplanation.msSql.msSqlCast",
    example: "CAST(expression AS datatype(length))",
  },
};

export const calculatedColumnClickhouseFunctions = {
  "CLICKHOUSE.count": {
    key: "CLICKHOUSE.count",
    explanation: "calculatedColumnExplanation.clickhouse.count",
    example:
      "count({ * | [ DISTINCT | ALL ] expr }) [ OVER (analytic_clause) ]",
  },
  "CLICKHOUSE.count(DISTINCT)": {
    key: "CLICKHOUSE.count(DISTINCT)",
    explanation: "calculatedColumnExplanation.clickhouse.countDistinct",
    example: "count(DISTINCT expr)",
  },
  "CLICKHOUSE.min": {
    key: "CLICKHOUSE.min",
    explanation: "calculatedColumnExplanation.clickhouse.min",
    example: "min([ DISTINCT | ALL ] expr) [ OVER (analytic_clause) ]",
  },
  "CLICKHOUSE.max": {
    key: "CLICKHOUSE.max",
    explanation: "calculatedColumnExplanation.clickhouse.max",
    example: "max([ DISTINCT | ALL ] expr) [ OVER (analytic_clause) ]",
  },
  "CLICKHOUSE.sum": {
    key: "CLICKHOUSE.sum",
    explanation: "calculatedColumnExplanation.clickhouse.sum",
    example: "sum([ DISTINCT | ALL ] expr) [ OVER (analytic_clause) ]",
  },
  "CLICKHOUSE.avg": {
    key: "CLICKHOUSE.avg",
    explanation: "calculatedColumnExplanation.clickhouse.avg",
    example: "avg([ DISTINCT | ALL ] expr) [ OVER(analytic_clause) ]",
  },
  "CLICKHOUSE.modulo": {
    key: "CLICKHOUSE.modulo",
    explanation: "calculatedColumnExplanation.clickhouse.modulo",
    example: "modulo(a,b)",
  },
  "CLICKHOUSE.abs": {
    key: "CLICKHOUSE.abs",
    explanation: "calculatedColumnExplanation.clickhouse.abs",
    example: "abs(a)",
  },
  "CLICKHOUSE.max2": {
    key: "CLICKHOUSE.max2",
    explanation: "calculatedColumnExplanation.clickhouse.max2",
    example: "max2(a,b)",
  },
  "CLICKHOUSE.min2": {
    key: "CLICKHOUSE.min2",
    explanation: "calculatedColumnExplanation.clickhouse.min2",
    example: "min2(a,b)",
  },
  "CLICKHOUSE.toInt(8|16|32|64|128|256)": {
    key: "CLICKHOUSE.toInt(8|16|32|64|128|256)",
    multipleExplanation: [
      {
        key: "toInt8(expr)",
        explanation:
          "calculatedColumnExplanation.clickhouse.toInt(8|16|32|64|128|256)",
        example: "toInt8(expr)",
      },
      {
        key: "toInt16(expr)",
        explanation:
          "calculatedColumnExplanation.clickhouse.toInt(8|16|32|64|128|256)",
        example: "toInt16(expr)",
      },
    ],
  },
  "CLICKHOUSE.toInt(8|16|32|64|128|256)OrZero": {
    key: "CLICKHOUSE.toInt(8|16|32|64|128|256)OrZero",
    explanation:
      "calculatedColumnExplanation.clickhouse.toInt(8|16|32|64|128|256)OrZero",
    example:
      "toInt8OrZero(expr), toInt16OrZero(expr), toInt32OrZero(expr), toInt64OrZero(expr), toInt128OrZero(expr)                                                      toInt256OrZero(expr)",
  },
  "CLICKHOUSE.toInt(8|16|32|64|128|256)OrNull": {
    key: "CLICKHOUSE.toInt(8|16|32|64|128|256)OrNull",
    explanation:
      "calculatedColumnExplanation.clickhouse.toInt(8|16|32|64|128|256)OrNull",
    example:
      "toInt8OrNull(expr), toInt16OrNull(expr), toInt32OrNull(expr), toInt64OrNull(expr), toInt128OrNull(expr)                                                        toInt256OrNull(expr)",
  },
  "CLICKHOUSE.toUInt(8|16|32|64|256)": {
    key: "CLICKHOUSE.toUInt(8|16|32|64|256)",
    explanation:
      "calculatedColumnExplanation.clickhouse.toUInt(8|16|32|64|256)",
    example:
      "toUInt8(expr), toUInt16(expr), toUInt32(expr), toUInt64(expr), toUInt128(expr), toUInt256(expr)",
  },
  "CLICKHOUSE.toUInt(8|16|32|64|256)OrZero": {
    key: "CLICKHOUSE.toUInt(8|16|32|64|256)OrZero",
    explanation:
      "calculatedColumnExplanation.clickhouse.toUInt(8|16|32|64|256)OrZero",
    example:
      "toUInt8OrZero(expr), toUInt16OrZero(expr), toUInt32OrZero(expr), toUInt64OrZero(expr), toUInt128OrZero(expr)                                                        toUInt256OrZero(expr)",
  },
  "CLICKHOUSE.toUInt(8|16|32|64|256)OrNull": {
    key: "CLICKHOUSE.toUInt(8|16|32|64|256)OrNull",
    explanation:
      "calculatedColumnExplanation.clickhouse.toUInt(8|16|32|64|256)OrNull",
    example:
      "toUInt8OrNull(expr), toUInt16OrNull(expr), toUInt32OrNull(expr), toUInt64OrNull(expr), toUInt128OrNull(expr)                                                          toUInt256OrNull(expr)",
  },
  "CLICKHOUSE.toFloat(32|64)": {
    key: "CLICKHOUSE.toFloat(32|64)",
    explanation: "calculatedColumnExplanation.clickhouse.toFloat(32|64)",
    example: "toFloat32(expr), toFloat64(expr)",
  },
  "CLICKHOUSE.toFloat(32|64)OrZero": {
    key: "CLICKHOUSE.toFloat(32|64)OrZero",
    explanation: "calculatedColumnExplanation.clickhouse.toFloat(32|64)OrZero",
    example: "toFloat32OrZero(expr), toFloat64OrZero(expr)",
  },
  "CLICKHOUSE.toFloat(32|64)OrNull": {
    key: "CLICKHOUSE.toFloat(32|64)OrNull",
    explanation: "calculatedColumnExplanation.clickhouse.toFloat(32|64)OrNull",
    example: "toFloat32OrNull(expr), toFloat64OrNull(expr)",
  },
  "CLICKHOUSE.toDate": {
    key: "CLICKHOUSE.toDate",
    explanation: "calculatedColumnExplanation.clickhouse.toDate",
    example: "toDate(expr)",
  },
  "CLICKHOUSE.toDateOrZero": {
    key: "CLICKHOUSE.toDateOrZero",
    explanation: "calculatedColumnExplanation.clickhouse.toDateOrZero",
    example: "toDateOrZero(expr)",
  },
  "CLICKHOUSE.toDateOrNull": {
    key: "CLICKHOUSE.toDateOrNull",
    explanation: "calculatedColumnExplanation.clickhouse.toDateOrNull",
    example: "toDateOrNull(expr)",
  },
  "CLICKHOUSE.toDateTime": {
    key: "CLICKHOUSE.toDateTime",
    explanation: "calculatedColumnExplanation.clickhouse.toDateTime",
    example: "toDateTime(expr)",
  },
  "CLICKHOUSE.toDateTimeOrZero": {
    key: "CLICKHOUSE.toDateTimeOrZero",
    explanation: "calculatedColumnExplanation.clickhouse.toDateTimeOrZero",
    example: "toDateTimeOrZero(expr)",
  },
  "CLICKHOUSE.toDateTimeOrNull": {
    key: "CLICKHOUSE.toDateTimeOrNull",
    explanation: "calculatedColumnExplanation.clickhouse.toDateTimeOrNull",
    example: "toDateTimeOrNull(expr)",
  },
  "CLICKHOUSE.toDate32": {
    key: "CLICKHOUSE.toDate32",
    explanation: "calculatedColumnExplanation.clickhouse.toDate32",
    example: "toDate32(expr)",
  },
  "CLICKHOUSE.toDate32OrZero": {
    key: "CLICKHOUSE.toDate32OrZero",
    explanation: "calculatedColumnExplanation.clickhouse.toDate32OrZero",
    example: "toDate32OrZero(expr)",
  },
  "CLICKHOUSE.toDate32OrNull": {
    key: "CLICKHOUSE.toDate32OrNull",
    explanation: "calculatedColumnExplanation.clickhouse.toDate32OrNull",
    example: "toDate32OrNull(expr)",
  },
  "CLICKHOUSE.toDateTime64": {
    key: "CLICKHOUSE.toDateTime64",
    explanation: "calculatedColumnExplanation.clickhouse.toDateTime64",
    example: "DateTime64(expr)",
  },
  "CLICKHOUSE.toDecimal(32|64|128|256)": {
    key: "CLICKHOUSE.toDecimal(32|64|128|256)",
    explanation:
      "calculatedColumnExplanation.clickhouse.toDecimal(32|64|128|256)",
    example:
      "toDecimal32(value, S), toDecimal64(value, S), toDecimal128(value, S), toDecimal256(value, S)",
  },
  "CLICKHOUSE.toDecimal(32|64|128|256)OrNull": {
    key: "CLICKHOUSE.toDecimal(32|64|128|256)OrNull",
    explanation:
      "calculatedColumnExplanation.clickhouse.toDecimal(32|64|128|256)OrNull",
    example:
      "toDecimal32OrNull(expr, S), toDecimal64OrNull(expr, S), toDecimal128OrNull(expr, S), toDecimal256OrNull(expr, S)",
  },
  "CLICKHOUSE.toDecimal(32|64|128|256)OrZero": {
    key: "CLICKHOUSE.toDecimal(32|64|128|256)OrZero",
    explanation:
      "calculatedColumnExplanation.clickhouse.toDecimal(32|64|128|256)OrZero",
    example:
      "toDecimal32OrZero(expr, S), toDecimal64OrZero(expr, S), toDecimal128OrZero(expr, S), toDecimal256OrZero(expr, S)",
  },
  "CLICKHOUSE.toString": {
    key: "CLICKHOUSE.toString",
    explanation: "calculatedColumnExplanation.clickhouse.toString",
    example: "toString(expr)",
  },
  "CLICKHOUSE.CAST": {
    key: "CLICKHOUSE.CAST",
    explanation: "calculatedColumnExplanation.clickhouse.CAST",
    example: "CAST(x,T) , CAST(x as t) , x::t",
  },
  "CLICKHOUSE.toInterval(Year|Quarter|Month|Week|Day|Hour|Minute|Second)": {
    key: "CLICKHOUSE.toInterval(Year|Quarter|Month|Week|Day|Hour|Minute|Second)",
    explanation:
      "calculatedColumnExplanation.clickhouse.toInterval(Year|Quarter|Month|Week|Day|Hour|Minute|Second)",
    example:
      "toIntervalSecond(number), toIntervalMinute(number), toIntervalHour(number), toIntervalDay(number), toIntervalWeek(number), toIntervalMonth(number), toIntervalQuarter(number), toIntervalYear(number)",
  },
  "CLICKHOUSE.timeZone": {
    key: "CLICKHOUSE.timeZone",
    explanation: "calculatedColumnExplanation.clickhouse.timeZone",
    example: "timeZone()",
  },
  "CLICKHOUSE.toTimeZone": {
    key: "CLICKHOUSE.toTimeZone",
    explanation: "calculatedColumnExplanation.clickhouse.toTimeZone",
    example: "toTimezone(value, timezone)",
  },
  "CLICKHOUSE.timeZoneOf": {
    key: "CLICKHOUSE.timeZoneOf",
    explanation: "calculatedColumnExplanation.clickhouse.timeZoneOf",
    example: "timeZoneOf(value)",
  },
  "CLICKHOUSE.timeZoneOffset": {
    key: "CLICKHOUSE.timeZoneOffset",
    explanation: "calculatedColumnExplanation.clickhouse.timeZoneOffset",
    example: "timeZoneOffset(value)",
  },
  "CLICKHOUSE.toYear": {
    key: "CLICKHOUSE.toYear",
    explanation: "calculatedColumnExplanation.clickhouse.toYear",
    example: "toYear(expr)",
  },
  "CLICKHOUSE.toQuarter": {
    key: "CLICKHOUSE.toQuarter",
    explanation: "calculatedColumnExplanation.clickhouse.toQuarter",
    example: "toQuarter(expr)",
  },
  "CLICKHOUSE.toMonth": {
    key: "CLICKHOUSE.toMonth",
    explanation: "calculatedColumnExplanation.clickhouse.toMonth",
    example: "toMonth(expr)",
  },
  "CLICKHOUSE.toDayOfYear": {
    key: "CLICKHOUSE.toDayOfYear",
    explanation: "calculatedColumnExplanation.clickhouse.toDayOfYear",
    example: "toDayOfYear(expr)",
  },
  "CLICKHOUSE.toDayOfMonth": {
    key: "CLICKHOUSE.toDayOfMonth",
    explanation: "calculatedColumnExplanation.clickhouse.toDayOfMonth",
    example: "toDayOfMonth(expr)",
  },
  "CLICKHOUSE.toDayOfWeek": {
    key: "CLICKHOUSE.toDayOfWeek",
    explanation: "calculatedColumnExplanation.clickhouse.toDayOfWeek",
    example: "toDayOfWeek(t[, mode[, timezone]])",
  },
  "CLICKHOUSE.toHour": {
    key: "CLICKHOUSE.toHour",
    explanation: "calculatedColumnExplanation.clickhouse.toHour",
    example: "toHour(expr)",
  },
  "CLICKHOUSE.toMinute": {
    key: "CLICKHOUSE.toMinute",
    explanation: "calculatedColumnExplanation.clickhouse.toMinute",
    example: "toMinute(expr)",
  },
  "CLICKHOUSE.toSecond": {
    key: "CLICKHOUSE.toSecond",
    explanation: "calculatedColumnExplanation.clickhouse.toSecond",
    example: "toSecond(expr)",
  },
  "CLICKHOUSE.toUnixTimestamp": {
    key: "CLICKHOUSE.toUnixTimestamp",
    explanation: "calculatedColumnExplanation.clickhouse.toUnixTimestamp",
    example: "toUnixTimestamp",
  },
  "CLICKHOUSE.toStartOfYear": {
    key: "CLICKHOUSE.toStartOfYear",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfYear",
    example: "toStartOfYear(expr)",
  },
  "CLICKHOUSE.toStartOfISOYear": {
    key: "CLICKHOUSE.toStartOfISOYear",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfISOYear",
    example: "toStartOfISOYear(expr)",
  },
  "CLICKHOUSE.toStartOfQuarter": {
    key: "CLICKHOUSE.toStartOfQuarter",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfQuarter",
    example: "toStartOfQuarter(expr)",
  },
  "CLICKHOUSE.toStartOfMonth": {
    key: "CLICKHOUSE.toStartOfMonth",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfMonth",
    example: "toStartOfMonth(expr)",
  },
  "CLICKHOUSE.toLastDayOfMonth": {
    key: "CLICKHOUSE.toLastDayOfMonth",
    explanation: "calculatedColumnExplanation.clickhouse.toLastDayOfMonth",
    example: "toLastDayOfMonth(expr)",
  },
  "CLICKHOUSE.toMonday": {
    key: "CLICKHOUSE.toMonday",
    explanation: "calculatedColumnExplanation.clickhouse.toMonday",
    example: "toMonday(expr)",
  },
  "CLICKHOUSE.toStartOfWeek": {
    key: "CLICKHOUSE.toStartOfWeek",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfWeek",
    example: "toStartOfWeek(t[, mode[, timezone]])",
  },
  "CLICKHOUSE.toStartOfDay": {
    key: "CLICKHOUSE.toStartOfDay",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfDay",
    example: "toStartOfDay(expr)",
  },
  "CLICKHOUSE.toStartOfHour": {
    key: "CLICKHOUSE.toStartOfHour",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfHour",
    example: "toStartOfHour(expr)",
  },
  "CLICKHOUSE.toStartOfMinute": {
    key: "CLICKHOUSE.toStartOfMinute",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfMinute",
    example: "toStartOfMinute(expr)",
  },
  "CLICKHOUSE.toStartOfSecond": {
    key: "CLICKHOUSE.toStartOfSecond",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfSecond",
    example: "toStartOfSecond(value, [timezone])",
  },
  "CLICKHOUSE.toStartOfFiveMinutes": {
    key: "CLICKHOUSE.toStartOfFiveMinutes",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfFiveMinutes",
    example: "toStartOfFiveMinutes(expr)",
  },
  "CLICKHOUSE.toStartOfTenMinutes": {
    key: "CLICKHOUSE.toStartOfTenMinutes",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfTenMinutes",
    example: "toStartOfTenMinutes(expr)",
  },
  "CLICKHOUSE.toStartOfFifteenMinutes": {
    key: "CLICKHOUSE.toStartOfFifteenMinutes",
    explanation:
      "calculatedColumnExplanation.clickhouse.toStartOfFifteenMinutes",
    example: "toStartOfFifteenMinutes(expr)",
  },
  "CLICKHOUSE.toStartOfInterval": {
    key: "CLICKHOUSE.toStartOfInterval",
    explanation: "calculatedColumnExplanation.clickhouse.toStartOfInterval",
    example:
      "toStartOfInterval(t, INTERVAL 1 year)=toStartOfYear(t) toStartOfInterval(t, INTERVAL 1 month)=toStartOfMonth(t) toStartOfInterval(t, INTERVAL 1 day)=toStartOfDay(t)   toStartOfInterval(t, INTERVAL 15 minute)=toStartOfFifteenMinutes(t)",
  },
  "CLICKHOUSE.toTime": {
    key: "CLICKHOUSE.toTime",
    explanation: "calculatedColumnExplanation.clickhouse.toTime",
    example: "toTime(expr)",
  },
  "CLICKHOUSE.toRelativeYearNum": {
    key: "CLICKHOUSE.toRelativeYearNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeYearNum",
    example: "toRelativeYearNum",
  },
  "CLICKHOUSE.toRelativeQuarterNum": {
    key: "CLICKHOUSE.toRelativeQuarterNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeQuarterNum",
    example: "toRelativeQuarterNum",
  },
  "CLICKHOUSE.toRelativeMonthNum": {
    key: "CLICKHOUSE.toRelativeMonthNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeMonthNum",
    example: "toRelativeMonthNum",
  },
  "CLICKHOUSE.toRelativeWeekNum": {
    key: "CLICKHOUSE.toRelativeWeekNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeWeekNum",
    example: "toRelativeWeekNum",
  },
  "CLICKHOUSE.toRelativeDayNum": {
    key: "CLICKHOUSE.toRelativeDayNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeDayNum",
    example: "toRelativeDayNum",
  },
  "CLICKHOUSE.toRelativeHourNum": {
    key: "CLICKHOUSE.toRelativeHourNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeHourNum",
    example: "toRelativeHourNum",
  },
  "CLICKHOUSE.toRelativeMinuteNum": {
    key: "CLICKHOUSE.toRelativeMinuteNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeMinuteNum",
    example: "toRelativeMinuteNum",
  },
  "CLICKHOUSE.toRelativeSecondNum": {
    key: "CLICKHOUSE.toRelativeSecondNum",
    explanation: "calculatedColumnExplanation.clickhouse.toRelativeSecondNum",
    example: "toRelativeSecondNum",
  },
  "CLICKHOUSE.toISOYear": {
    key: "CLICKHOUSE.toISOYear",
    explanation: "calculatedColumnExplanation.clickhouse.toISOYear",
    example: "toISOYear(expr)",
  },
  "CLICKHOUSE.toISOWeek": {
    key: "CLICKHOUSE.toISOWeek",
    explanation: "calculatedColumnExplanation.clickhouse.toISOWeek",
    example: "toISOWeek(expr)",
  },
  "CLICKHOUSE.toWeek": {
    key: "CLICKHOUSE.toWeek",
    explanation: "calculatedColumnExplanation.clickhouse.toWeek",
    example: "toWeek(t[, mode[, time_zone]])",
  },
  "CLICKHOUSE.date_diff": {
    key: "CLICKHOUSE.date_diff",
    explanation: "calculatedColumnExplanation.clickhouse.date_diff",
    example: "date_diff('unit', startdate, enddate, [timezone])",
  },
  "CLICKHOUSE.date_trunc": {
    key: "CLICKHOUSE.date_trunc",
    explanation: "calculatedColumnExplanation.clickhouse.date_trunc",
    example: "date_trunc(unit, value[, timezone])",
  },
  "CLICKHOUSE.date_add": {
    key: "CLICKHOUSE.date_add",
    explanation: "calculatedColumnExplanation.clickhouse.date_add",
    example: "date_add(unit, value, date)",
  },
  "CLICKHOUSE.date_sub": {
    key: "CLICKHOUSE.date_sub",
    explanation: "calculatedColumnExplanation.clickhouse.date_sub",
    example: "date_sub(unit, value, date)",
  },
  "CLICKHOUSE.timestamp_add": {
    key: "CLICKHOUSE.timestamp_add",
    explanation: "calculatedColumnExplanation.clickhouse.timestamp_add",
    example: "",
  },
  "CLICKHOUSE.timestamp_sub": {
    key: "CLICKHOUSE.timestamp_sub",
    explanation: "calculatedColumnExplanation.clickhouse.timestamp_sub",
    example: "",
  },
  "CLICKHOUSE.now": {
    key: "CLICKHOUSE.now",
    explanation: "calculatedColumnExplanation.clickhouse.now",
    example: "now64([timezone])",
  },
  "CLICKHOUSE.now64": {
    key: "CLICKHOUSE.now64",
    explanation: "calculatedColumnExplanation.clickhouse.now64",
    example: "now64([scale], [timezone])",
  },
  "CLICKHOUSE.today": {
    key: "CLICKHOUSE.today",
    explanation: "calculatedColumnExplanation.clickhouse.today",
    example: "today()",
  },
  "CLICKHOUSE.yesterday": {
    key: "CLICKHOUSE.yesterday",
    explanation: "calculatedColumnExplanation.clickhouse.yesterday",
    example: "yesterday()",
  },
  "CLICKHOUSE.timeSlot": {
    key: "CLICKHOUSE.timeSlot",
    explanation: "calculatedColumnExplanation.clickhouse.timeSlot",
    example: "",
  },
  "CLICKHOUSE.toYYYYMM": {
    key: "CLICKHOUSE.toYYYYMM",
    explanation: "calculatedColumnExplanation.clickhouse.toYYYYMM",
    example: "toYYYYMM(value,[timezone])",
  },
  "CLICKHOUSE.toYYYYMMDD": {
    key: "CLICKHOUSE.toYYYYMMDD",
    explanation: "calculatedColumnExplanation.clickhouse.toYYYYMMDD",
    example: "toYYYYMM(value,[timezone])",
  },
  "CLICKHOUSE.toYYYYMMDDhhmmss": {
    key: "CLICKHOUSE.toYYYYMMDDhhmmss",
    explanation: "calculatedColumnExplanation.clickhouse.toYYYYMMDDhhmmss",
    example: "",
  },
  "CLICKHOUSE.addYears, addMonths, addWeeks, addDays, addHours, addMinutes, addSeconds, addQuarters":
    {
      key: "CLICKHOUSE.addYears, addMonths, addWeeks, addDays, addHours, addMinutes, addSeconds, addQuarters",
      multipleExplanation: [
        {
          key: "addYears",
          explanation: "calculatedColumnExplanation.clickhouse.addYears",
          example: "",
        },
        {
          key: "addMonths",
          explanation: "calculatedColumnExplanation.clickhouse.addMonths",
          example: "",
        },
        {
          key: "addWeeks",
          explanation: "calculatedColumnExplanation.clickhouse.addWeeks",
          example: "",
        },
        {
          key: "addDays",
          explanation: "calculatedColumnExplanation.clickhouse.addDays",
          example: "",
        },
        {
          key: "addHours",
          explanation: "calculatedColumnExplanation.clickhouse.addHours",
          example: "",
        },
        {
          key: "addMinutes",
          explanation: "calculatedColumnExplanation.clickhouse.addMinutes",
          example: "",
        },
        {
          key: "addSeconds",
          explanation: "calculatedColumnExplanation.clickhouse.addSeconds",
          example: "",
        },
        {
          key: "addQuarters",
          explanation: "calculatedColumnExplanation.clickhouse.addQuarters",
          example: "",
        },
      ],
    },
  "CLICKHOUSE.subtractYears, subtractMonths, subtractWeeks, subtractDays, subtractHours, subtractMinutes, subtractSeconds, subtractQuarters":
    {
      key: "CLICKHOUSE.subtractYears, subtractMonths, subtractWeeks, subtractDays, subtractHours, subtractMinutes, subtractSeconds, subtractQuarters",
      multipleExplanation: [
        {
          key: "subtractYears",
          explanation: "calculatedColumnExplanation.clickhouse.subtractYears",
          example: "",
        },
        {
          key: "subtractMonths",
          explanation: "calculatedColumnExplanation.clickhouse.subtractMonths",
          example: "",
        },
        {
          key: "subtractWeeks",
          explanation: "calculatedColumnExplanation.clickhouse.subtractWeeks",
          example: "",
        },
        {
          key: "subtractDays",
          explanation: "calculatedColumnExplanation.clickhouse.subtractDays",
          example: "",
        },
        {
          key: "subtractHours",
          explanation: "calculatedColumnExplanation.clickhouse.subtractHours",
          example: "",
        },
        {
          key: "subtractMinutes",
          explanation: "calculatedColumnExplanation.clickhouse.subtractMinutes",
          example: "",
        },
        {
          key: "subtractSeconds",
          explanation: "calculatedColumnExplanation.clickhouse.subtractSeconds",
          example: "",
        },
        {
          key: "subtractQuarters",
          explanation:
            "calculatedColumnExplanation.clickhouse.subtractQuarters",
          example: "",
        },
      ],
    },
  "CLICKHOUSE.formatDateTime": {
    key: "CLICKHOUSE.formatDateTime",
    explanation: "calculatedColumnExplanation.clickhouse.formatDateTime",
    example: "formatDateTime(Time, Format[, Timezone])",
  },
  "CLICKHOUSE.dateName": {
    key: "CLICKHOUSE.dateName",
    explanation: "calculatedColumnExplanation.clickhouse.dateName",
    example: "dateName(date_part, date)",
  },
  "CLICKHOUSE.monthName": {
    key: "CLICKHOUSE.monthName",
    explanation: "calculatedColumnExplanation.clickhouse.monthName",
    example: "monthName(date)",
  },
  "CLICKHOUSE.length": {
    key: "CLICKHOUSE.length",
    explanation: "calculatedColumnExplanation.clickhouse.length",
    example: "length('string')",
  },
  "CLICKHOUSE.lengthUTF8": {
    key: "CLICKHOUSE.lengthUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.lengthUTF8",
    example: "lengthUTF8('string')",
  },
  "CLICKHOUSE.char_length": {
    key: "CLICKHOUSE.char_length",
    explanation: "calculatedColumnExplanation.clickhouse.char_length",
    example: "char_length('string')",
  },
  "CLICKHOUSE.character_length": {
    key: "CLICKHOUSE.character_length",
    explanation: "calculatedColumnExplanation.clickhouse.character_length",
    example: "",
  },
  "CLICKHOUSE.leftPad": {
    key: "CLICKHOUSE.leftPad",
    explanation: "calculatedColumnExplanation.clickhouse.leftPad",
    example: "leftPad('string', 'length'[, 'pad_string'])",
  },
  "CLICKHOUSE.leftPadUTF8": {
    key: "CLICKHOUSE.leftPadUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.leftPadUTF8",
    example: "leftPadUTF8('string','length'[, 'pad_string'])",
  },
  "CLICKHOUSE.rightPad": {
    key: "CLICKHOUSE.rightPad",
    explanation: "calculatedColumnExplanation.clickhouse.rightPad",
    example: "rightPad('string', 'length'[, 'pad_string'])",
  },
  "CLICKHOUSE.rightPadUTF8": {
    key: "CLICKHOUSE.rightPadUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.rightPadUTF8",
    example: "rightPadUTF8('string','length'[, 'pad_string'])",
  },
  "CLICKHOUSE.lower": {
    key: "CLICKHOUSE.lower",
    explanation: "calculatedColumnExplanation.clickhouse.lower",
    example: "lower('string')",
  },
  "CLICKHOUSE.upper": {
    key: "CLICKHOUSE.upper",
    explanation: "calculatedColumnExplanation.clickhouse.upper",
    example: "upper('string')",
  },
  "CLICKHOUSE.lowerUTF8": {
    key: "CLICKHOUSE.lowerUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.lowerUTF8",
    example: "lowerUTF8('string')",
  },
  "CLICKHOUSE.upperUTF8": {
    key: "CLICKHOUSE.upperUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.upperUTF8",
    example: "upperUTF8('string')",
  },
  "CLICKHOUSE.isValidUTF8": {
    key: "CLICKHOUSE.isValidUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.isValidUTF8",
    example: "isValidUTF8('string')",
  },
  "CLICKHOUSE.toValidUTF8": {
    key: "CLICKHOUSE.toValidUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.toValidUTF8",
    example: "",
  },
  "CLICKHOUSE.repeat": {
    key: "CLICKHOUSE.repeat",
    explanation: "calculatedColumnExplanation.clickhouse.repeat",
    example: "repeat('string', repeat_count)",
  },
  "CLICKHOUSE.reverse": {
    key: "CLICKHOUSE.reverse",
    explanation: "calculatedColumnExplanation.clickhouse.reverse",
    example: "reverse('string')",
  },
  "CLICKHOUSE.reverseUTF8": {
    key: "CLICKHOUSE.reverseUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.reverseUTF8",
    example: "reverseUTF8('string')",
  },
  "CLICKHOUSE.format": {
    key: "CLICKHOUSE.format",
    explanation: "calculatedColumnExplanation.clickhouse.format",
    example: "format(pattern, s0, s1, …)",
  },
  "CLICKHOUSE.substring": {
    key: "CLICKHOUSE.substring",
    explanation: "calculatedColumnExplanation.clickhouse.substring",
    example: "substring('string', offset, length)",
  },
  "CLICKHOUSE.substringUTF8": {
    key: "CLICKHOUSE.substringUTF8",
    explanation: "calculatedColumnExplanation.clickhouse.substringUTF8",
    example: "substringUTF8('string', offset, length)",
  },
  "CLICKHOUSE.appendTrailingCharIfAbsent": {
    key: "CLICKHOUSE.appendTrailingCharIfAbsent",
    explanation:
      "calculatedColumnExplanation.clickhouse.appendTrailingCharIfAbsent",
    example: "appendTrailingCharIfAbsent(s, c)",
  },
  "CLICKHOUSE.endsWith": {
    key: "CLICKHOUSE.endsWith",
    explanation: "calculatedColumnExplanation.clickhouse.endsWith",
    example: "endsWith('string', suffix)",
  },
  "CLICKHOUSE.startsWith": {
    key: "CLICKHOUSE.startsWith",
    explanation: "calculatedColumnExplanation.clickhouse.startsWith",
    example: "startsWith('string', prefix)",
  },
  "CLICKHOUSE.trim": {
    key: "CLICKHOUSE.trim",
    explanation: "calculatedColumnExplanation.clickhouse.trim",
    example: "trim([[LEADING|TRAILING|BOTH] trim_character FROM] input_string)",
  },
  "CLICKHOUSE.trimLeft": {
    key: "CLICKHOUSE.trimLeft",
    explanation: "calculatedColumnExplanation.clickhouse.trimLeft",
    example: "trimLeft(input_string)",
  },
  "CLICKHOUSE.trimRight": {
    key: "CLICKHOUSE.trimRight",
    explanation: "calculatedColumnExplanation.clickhouse.trimRight",
    example: "trimRight(input_string)",
  },
  "CLICKHOUSE.trimBoth": {
    key: "CLICKHOUSE.trimBoth",
    explanation: "calculatedColumnExplanation.clickhouse.trimBoth",
    example: "trimBoth(input_string)",
  },
  "CLICKHOUSE.concatWithSeparator": {
    key: "CLICKHOUSE.concatWithSeparator",
    explanation: "calculatedColumnExplanation.clickhouse.concatWithSeparator",
    example: "concatWithSeparator(sep, expr1, expr2, expr3...)",
  },
  "CLICKHOUSE.regexpExtract": {
    key: "CLICKHOUSE.regexpExtract",
    explanation: "calculatedColumnExplanation.clickhouse.regexpExtract",
    example: "regexpExtract(haystack, pattern[, index])",
  },
  "CLICKHOUSE.if": {
    key: "CLICKHOUSE.if",
    explanation: "calculatedColumnExplanation.clickhouse.if",
    example: "if(cond, then, else)",
  },
  "CLICKHOUSE.multiIf": {
    key: "CLICKHOUSE.multiIf",
    explanation: "calculatedColumnExplanation.clickhouse.multiIf",
    example: "multiIf(cond_1, then_1, cond_2, then_2, ..., else)",
  },
  "CLICKHOUSE.e": {
    key: "CLICKHOUSE.e",
    explanation: "calculatedColumnExplanation.clickhouse.e",
    example: "e()",
  },
  "CLICKHOUSE.pi": {
    key: "CLICKHOUSE.pi",
    explanation: "calculatedColumnExplanation.clickhouse.pi",
    example: "pi()",
  },
  "CLICKHOUSE.exp": {
    key: "CLICKHOUSE.exp",
    explanation: "calculatedColumnExplanation.clickhouse.exp",
    example: "exp(x)",
  },
  "CLICKHOUSE.log": {
    key: "CLICKHOUSE.log",
    explanation: "calculatedColumnExplanation.clickhouse.log",
    example: "log(x)",
  },
  "CLICKHOUSE.exp2": {
    key: "CLICKHOUSE.exp2",
    explanation: "calculatedColumnExplanation.clickhouse.exp2",
    example: "exp2(x)",
  },
  "CLICKHOUSE.log2": {
    key: "CLICKHOUSE.log2",
    explanation: "calculatedColumnExplanation.clickhouse.log2",
    example: "log2(x)",
  },
  "CLICKHOUSE.exp10": {
    key: "CLICKHOUSE.exp10",
    explanation: "calculatedColumnExplanation.clickhouse.exp10",
    example: "exp10(x)",
  },
  "CLICKHOUSE.log10": {
    key: "CLICKHOUSE.log10",
    explanation: "calculatedColumnExplanation.clickhouse.log10",
    example: "log10(x)",
  },
  "CLICKHOUSE.sqrt": {
    key: "CLICKHOUSE.sqrt",
    explanation: "calculatedColumnExplanation.clickhouse.sqrt",
    example: "sqrt(x)",
  },
  "CLICKHOUSE.sin": {
    key: "CLICKHOUSE.sin",
    explanation: "calculatedColumnExplanation.clickhouse.sin",
    example: "sin(x)",
  },
  "CLICKHOUSE.cos": {
    key: "CLICKHOUSE.cos",
    explanation: "calculatedColumnExplanation.clickhouse.cos",
    example: "cos(x)",
  },
  "CLICKHOUSE.tan": {
    key: "CLICKHOUSE.tan",
    explanation: "calculatedColumnExplanation.clickhouse.tan",
    example: "tan(x)",
  },
  "CLICKHOUSE.asin": {
    key: "CLICKHOUSE.asin",
    explanation: "calculatedColumnExplanation.clickhouse.asin",
    example: "asin(x)",
  },
  "CLICKHOUSE.acos": {
    key: "CLICKHOUSE.acos",
    explanation: "calculatedColumnExplanation.clickhouse.acos",
    example: "acos(x)",
  },
  "CLICKHOUSE.atan": {
    key: "CLICKHOUSE.atan",
    explanation: "calculatedColumnExplanation.clickhouse.atan",
    example: "atan(x)",
  },
  "CLICKHOUSE.pow": {
    key: "CLICKHOUSE.pow",
    explanation: "calculatedColumnExplanation.clickhouse.pow",
    example: "pow(x)",
  },
  "CLICKHOUSE.degrees": {
    key: "CLICKHOUSE.degrees",
    explanation: "calculatedColumnExplanation.clickhouse.degrees",
    example: "degrees(x)",
  },
  "CLICKHOUSE.radians": {
    key: "CLICKHOUSE.radians",
    explanation: "calculatedColumnExplanation.clickhouse.radians",
    example: "radians(x)",
  },
  "CLICKHOUSE.factorial": {
    key: "CLICKHOUSE.factorial",
    explanation: "calculatedColumnExplanation.clickhouse.factorial",
    example: "factorial(x)",
  },
  "CLICKHOUSE.floor": {
    key: "CLICKHOUSE.floor",
    explanation: "calculatedColumnExplanation.clickhouse.floor",
    example: "floor(x[, N])",
  },
  "CLICKHOUSE.ceil": {
    key: "CLICKHOUSE.ceil",
    explanation: "calculatedColumnExplanation.clickhouse.ceil",
    example: "ceil(x[, N]), ceiling(x[, N])",
  },
  "CLICKHOUSE.trunc": {
    key: "CLICKHOUSE.trunc",
    explanation: "calculatedColumnExplanation.clickhouse.trunc",
    example: "trunc(x[, N])",
  },
  "CLICKHOUSE.round": {
    key: "CLICKHOUSE.round",
    explanation: "calculatedColumnExplanation.clickhouse.round",
    example: "round(x[, N])",
  },
  "CLICKHOUSE.roundDown": {
    key: "CLICKHOUSE.roundDown",
    explanation: "calculatedColumnExplanation.clickhouse.roundDown",
    example: "roundDown(num, arr)",
  },
  "CLICKHOUSE.isNull": {
    key: "CLICKHOUSE.isNull",
    explanation: "calculatedColumnExplanation.clickhouse.isNull",
    example: "isnull(expr)",
  },
  "CLICKHOUSE.isNotNull": {
    key: "CLICKHOUSE.isNotNull",
    explanation: "calculatedColumnExplanation.clickhouse.isNotNull",
    example: "isNotNull(expr)",
  },
  "CLICKHOUSE.coalesce": {
    key: "CLICKHOUSE.coalesce",
    explanation: "calculatedColumnExplanation.clickhouse.coalesce",
    example: "coalesce(x1,x2,x3…..)",
  },
  "CLICKHOUSE.ifNull": {
    key: "CLICKHOUSE.ifNull",
    explanation: "calculatedColumnExplanation.clickhouse.ifNull",
    example: "ifNULL(x,alternative)",
  },
  "CLICKHOUSE.nullIf": {
    key: "CLICKHOUSE.nullIf",
    explanation: "calculatedColumnExplanation.clickhouse.nullIf",
    example: "nullif(x,y)",
  },
  "CLICKHOUSE.toNullable": {
    key: "CLICKHOUSE.toNullable",
    explanation: "calculatedColumnExplanation.clickhouse.toNullable",
    example: "toNullable(x)",
  },
};

export const calculatedColumnPostgreSqlFunctions = {
  "POSTGRESQL.NULLIF": {
    key: "POSTGRESQL.NULLIF",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlNullIf",
    example: "NULLIF(expr1, expr2)",
  },
  "POSTGRESQL.AVG": {
    key: "POSTGRESQL.AVG",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlAvg",
    example: "AVG(DISTINCT column)",
  },
  "POSTGRESQL.COUNT": {
    key: "POSTGRESQL.COUNT",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCount",
    example: "COUNT(column_name)",
  },
  "POSTGRESQL.COUNT(DISTINCT)": {
    key: "POSTGRESQL.COUNT(DISTINCT)",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCountDistinct",
    example:
      "POSTGRESQL.COUNT(DISTINCT expr)",
  },
  "POSTGRESQL.MAX": {
    key: "POSTGRESQL.MAX",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlMax",
    example: "MAX(expression)",
  },
  "POSTGRESQL.MIN": {
    key: "POSTGRESQL.MIN",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlMin",
    example: "MIN(expression)",
  },
  "POSTGRESQL.SUM": {
    key: "POSTGRESQL.SUM",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlSum",
    example: "SUM(DISTINCT expression)",
  },
  "POSTGRESQL.COALESCE": {
    key: "POSTGRESQL.COALESCE",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCoalesce",
    example: "COALESCE(argument_1, argument_2, …)",
  },
  "POSTGRESQL.UPPER": {
    key: "POSTGRESQL.UPPER",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlUpper",
    example: "UPPER(text)",
  },
  "POSTGRESQL.SUBSTRING": {
    key: "POSTGRESQL.SUBSTRING",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlSubstring",
    example: "SUBSTRING(string, start, length)",
  },
  "POSTGRESQL.RTRIM": {
    key: "POSTGRESQL.RTRIM",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlRtrim",
    example: "RTRIM(string)",
  },
  "POSTGRESQL.RIGHT": {
    key: "POSTGRESQL.RIGHT",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlRight",
    example: "RIGHT(string, number_of_chars)",
  },
  "POSTGRESQL.REPLACE": {
    key: "POSTGRESQL.REPLACE",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlReplace",
    example: "REPLACE(string, old_string, new_string)",
  },
  "POSTGRESQL.LTRIM": {
    key: "POSTGRESQL.LTRIM",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlLtrim",
    example: "LTRIM(string)",
  },
  "POSTGRESQL.LOWER": {
    key: "POSTGRESQL.LOWER",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlLower",
    example: "LOWER(text)",
  },
  "POSTGRESQL.LENGTH": {
    key: "POSTGRESQL.LENGTH",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlLength",
    example: "LENGTH(string)",
  },
  "POSTGRESQL.LEFT": {
    key: "POSTGRESQL.LEFT",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlLeft",
    example: "LEFT(string, number_of_chars)",
  },
  "POSTGRESQL.CAST": {
    key: "POSTGRESQL.CAST",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCast",
    example: "CAST(expression AS datatype(length))",
  },
  "POSTGRESQL.CONCAT": {
    key: "POSTGRESQL.CONCAT",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlConcat",
    example: "CONCAT(str 'any' [, str 'any' [, ...] ])",
  },
  "POSTGRESQL.TO_CHAR": {
    key: "POSTGRESQL.TO_CHAR",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlToChar",
    example: "TO_CHAR(time stamp/integer/interval/numerix, format)",
  },
  "POSTGRESQL.CASE": {
    key: "POSTGRESQL.CASE",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCase",
    example:
      "CASE search-expression WHEN expression [, expression [ ... ]] THEN statements [ WHEN expression [, expression [ ... ]] THEN statements ... ] [ ELSE statements ] END CASE;",
  },
  "POSTGRESQL.TO_NUMBER": {
    key: "POSTGRESQL.TO_NUMBER",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlToNumber",
    example: "TO_NUMBER(text, format)",
  },
  "POSTGRESQL.TO_DATE": {
    key: "POSTGRESQL.TO_DATE",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlToDate",
    example: "TO_DATE(text, format)",
  },
  "POSTGRESQL.TO_TIMESTAMP": {
    key: "POSTGRESQL.TO_TIMESTAMP",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlToTimestamp",
    example: "TO_TIMESTAMP(text, format)",
  },
  "POSTGRESQL.EXTRACT": {
    key: "POSTGRESQL.EXTRACT",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlExtract",
    example: "EXTRACT(field FROM source)",
  },
  "POSTGRESQL.DATE_PART": {
    key: "POSTGRESQL.DATE_PART",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlDatePart",
    example: "DATE_PART('field', source)",
  },
  "POSTGRESQL.NOW": {
    key: "POSTGRESQL.NOW",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlNow",
    example: "NOW()",
  },
  "POSTGRESQL.DATE_SUBTRACT": {
    key: "POSTGRESQL.DATE_SUBTRACT",
    explanation:
      "calculatedColumnExplanation.postgreSql.postgreSqlDateSubtract",
    example: "DATE_SUBTRACT( timestamp with time zone, interval [, text ] )",
  },
  "POSTGRESQL.CURRENT_DATE": {
    key: "POSTGRESQL.CURRENT_DATE",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCurrentDate",
    example: "CURRENT_DATE()",
  },
  "POSTGRESQL.CURRENT_TIME": {
    key: "POSTGRESQL.CURRENT_TIME",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCurrentTime",
    example: "CURRENT_TIME()",
  },
  "POSTGRESQL.CURRENT_TIMESTAMP": {
    key: "POSTGRESQL.CURRENT_TIMESTAMP",
    explanation:
      "calculatedColumnExplanation.postgreSql.postgreSqlCurrentTimestamp",
    example: "CURRENT_TIMESTAMP()",
  },
  "POSTGRESQL.LOCALTIMESTAMP": {
    key: "POSTGRESQL.LOCALTIMESTAMP",
    explanation:
      "calculatedColumnExplanation.postgreSql.postgreSqlLocalTimestamp",
    example: "LOCALTIMESTAMP()",
  },
  "POSTGRESQL.ROUND": {
    key: "POSTGRESQL.ROUND",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlRound",
    example: "ROUND(number, decimals, operation)",
  },
  "POSTGRESQL.POWER": {
    key: "POSTGRESQL.POWER",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlPower",
    example: "POWER(a, b)",
  },
  "POSTGRESQL.LOG": {
    key: "POSTGRESQL.LOG",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlLog",
    example: "LOG(number, base) -- Syntax for SQL Server",
  },
  "POSTGRESQL.FLOOR": {
    key: "POSTGRESQL.FLOOR",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlFloor",
    example: "FLOOR(number)",
  },
  "POSTGRESQL.CEILING": {
    key: "POSTGRESQL.CEILING",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCeiling",
    example: "CEILING(number)",
  },
  "POSTGRESQL.ABS": {
    key: "POSTGRESQL.ABS",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlAbs",
    example: "ABS(number)",
  },
  "POSTGRESQL.MOD": {
    key: "POSTGRESQL.MOD",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlMod",
    example: "MOD(y,x)",
  },
  "POSTGRESQL.SQRT": {
    key: "POSTGRESQL.SQRT",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlSqrt",
    example: "SQRT(double precision & number)",
  },
  "POSTGRESQL.SIN": {
    key: "POSTGRESQL.SIN",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlSin",
    example: "SIN(x)",
  },
  "POSTGRESQL.COS": {
    key: "POSTGRESQL.COS",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCos",
    example: "COS(x)",
  },
  "POSTGRESQL.TAN": {
    key: "POSTGRESQL.TAN",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlTan",
    example: "TAN(x)",
  },
  "POSTGRESQL.COT": {
    key: "POSTGRESQL.COT",
    explanation: "calculatedColumnExplanation.postgreSql.postgreSqlCot",
    example: "COT(x)",
  },
};

export const calculatedColumnSybaseFunctions = {
  "SYBASE.ABS": {
    key: "SYBASE.ABS",
    explanation: "calculatedColumnExplanation.sybase.sybaseAbs",
    example: "ABS( numeric-expression )",
  },
  "SYBASE.ACOS": {
    key: "SYBASE.ACOS",
    explanation: "calculatedColumnExplanation.sybase.sybaseAcos",
    example: "ACOS( numeric-expression )",
  },
  "SYBASE.ARGN": {
    key: "SYBASE.ARGN",
    explanation: "calculatedColumnExplanation.sybase.sybaseArgn",
    example: "ARGN ( integer-expression, expression [ , …] )",
  },
  "SYBASE.ASCII": {
    key: "SYBASE.ASCII",
    explanation: "calculatedColumnExplanation.sybase.sybaseAscii",
    example: "ASCII ( string-expression )",
  },
  "SYBASE.ASIN": {
    key: "SYBASE.ASIN",
    explanation: "calculatedColumnExplanation.sybase.sybaseAsin",
    example: "ASIN ( numeric-expression )",
  },
  "SYBASE.ATAN": {
    key: "SYBASE.ATAN",
    explanation: "calculatedColumnExplanation.sybase.sybaseAtan",
    example: "ATAN ( numeric-expression )",
  },
  "SYBASE.ATAN2": {
    key: "SYBASE.ATAN2",
    explanation: "calculatedColumnExplanation.sybase.sybaseAtan2",
    example: "ATAN2 ( numeric-expression1, numeric-expression2 )",
  },
  "SYBASE.AVG": {
    key: "SYBASE.AVG",
    explanation: "calculatedColumnExplanation.sybase.sybaseAvg",
    example: "AVG ( numeric-expression  | DISTINCT column-name )",
  },
  "SYBASE.BIGINTTOHEX": {
    key: "SYBASE.BIGINTTOHEX",
    explanation: "calculatedColumnExplanation.sybase.sybaseBigIntTohex",
    example: "BIGINTTOHEX ( integer-expression )",
  },
  "SYBASE.CAST": {
    key: "SYBASE.CAST",
    explanation: "calculatedColumnExplanation.sybase.sybaseCast",
    example: "CAST ( expression AS data type )",
  },
  "SYBASE.CEIL": {
    key: "SYBASE.CEIL",
    explanation: "calculatedColumnExplanation.sybase.sybaseCeil",
    example: "CEIL ( numeric-expression )",
  },
  "SYBASE.CEILING": {
    key: "SYBASE.CEILING",
    explanation: "calculatedColumnExplanation.sybase.sybaseCeiling",
    example: "CEILING ( numeric-expression )",
  },
  "SYBASE.CHAR": {
    key: "SYBASE.CHAR",
    explanation: "calculatedColumnExplanation.sybase.sybaseChar",
    example: "CHAR ( integer-expression )",
  },
  "SYBASE.CHAR_LENGTH": {
    key: "SYBASE.CHAR_LENGTH",
    explanation: "calculatedColumnExplanation.sybase.sybaseCharLength",
    example: "CHAR_LENGTH ( string-expression )",
  },
  "SYBASE.CHARINDEX": {
    key: "SYBASE.CHARINDEX",
    explanation: "calculatedColumnExplanation.sybase.sybaseCharIndex",
    example: "CHARINDEX ( string-expression1, string-expression2 )",
  },
  "SYBASE.COALESCE": {
    key: "SYBASE.COALESCE",
    explanation: "calculatedColumnExplanation.sybase.sybaseCoalesce",
    example: "COALESCE ( expression, expression [ , … ] )",
  },
  "SYBASE.COL_LENGTH": {
    key: "SYBASE.COL_LENGTH",
    explanation: "calculatedColumnExplanation.sybase.sybaseColLength",
    example: "COL_LENGTH ( table-name, column-name )",
  },
  "SYBASE.COL_NAME": {
    key: "SYBASE.COL_NAME",
    explanation: "calculatedColumnExplanation.sybase.sybaseColName",
    example: "COL_NAME ( table-id, column-id [ , database-id ] )",
  },
  "SYBASE.CONNECTION_PROPERTY": {
    key: "SYBASE.CONNECTION_PROPERTY",
    explanation: "calculatedColumnExplanation.sybase.sybaseConnectionProperty",
    example:
      "CONNECTION_PROPERTY ( { integer-expression1 | string-expression } … [ , integer-expression2 ] )",
  },
  "SYBASE.CONVERT": {
    key: "SYBASE.CONVERT",
    explanation: "calculatedColumnExplanation.sybase.sybaseConvert",
    example: "CONVERT ( data-type, expression [ , format-style ] )",
  },
  "SYBASE.CORR": {
    key: "SYBASE.CORR",
    explanation: "calculatedColumnExplanation.sybase.sybaseCorr",
    example: "CORR (dependent-expression, independent-expression)",
  },
  "SYBASE.COS": {
    key: "SYBASE.COS",
    explanation: "calculatedColumnExplanation.sybase.sybaseCos",
    example: "COS ( numeric-expression )",
  },
  "SYBASE.COT": {
    key: "SYBASE.COT",
    explanation: "calculatedColumnExplanation.sybase.sybaseCot",
    example: "COT ( numeric-expression )",
  },
  "SYBASE.COVAR_POP": {
    key: "SYBASE.COVAR_POP",
    explanation: "calculatedColumnExplanation.sybase.sybaseCovarPop",
    example: "COVAR_POP (dependent-expression, independent-expression)",
  },
  "SYBASE.COVAR_SAMP": {
    key: "SYBASE.COVAR_SAMP",
    explanation: "calculatedColumnExplanation.sybase.sybaseCovarSamp",
    example: "COVAR_SAMP (dependent-expression, independent-expression)",
  },
  "SYBASE.COUNT": {
    key: "SYBASE.COUNT",
    explanation: "calculatedColumnExplanation.sybase.sybaseCount",
    example: "COUNT ( * | expression | DISTINCT column-name )",
  },
  "SYBASE.COUNT(DISTINCT)": {
    key: "SYBASE.COUNT(DISTINCT)",
    explanation: "calculatedColumnExplanation.sybase.sybaseCountDistinct",
    example:
      "SYBASE.COUNT(DISTINCT expr)",
  },
  "SYBASE.CUME_DIST": {
    key: "SYBASE.CUME_DIST",
    explanation: "calculatedColumnExplanation.sybase.sybaseCumeDist",
    example: "CUME_DIST () OVER (window-spec)",
  },
  "SYBASE.DATALENGTH": {
    key: "SYBASE.DATALENGTH",
    explanation: "calculatedColumnExplanation.sybase.sybaseDataLength",
    example: "DATALENGTH ( expression )",
  },
  "SYBASE.DATE": {
    key: "SYBASE.DATE",
    explanation: "calculatedColumnExplanation.sybase.sybaseDate",
    example: "DATE ( expression )",
  },
  "SYBASE.DATEADD": {
    key: "SYBASE.DATEADD",
    explanation: "calculatedColumnExplanation.sybase.sybaseDateAdd",
    example: "DATEADD ( date-part, numeric-expression, date-expression )",
  },
  "SYBASE.DATECEILING": {
    key: "SYBASE.DATECEILING",
    explanation: "calculatedColumnExplanation.sybase.sybaseDateCeiling",
    example:
      "DATECEILING ( date-part, datetime-expression [,multiple -expression])",
  },
  "SYBASE.DATEDIFF": {
    key: "SYBASE.DATEDIFF",
    explanation: "calculatedColumnExplanation.sybase.sybaseDateDiff",
    example: "DATEDIFF ( date-part, date-expression1, date-expression2 )",
  },
  "SYBASE.DATEFLOOR": {
    key: "SYBASE.DATEFLOOR",
    explanation: "calculatedColumnExplanation.sybase.sybaseDateFloor",
    example:
      "DATEFLOOR ( date-part, datetime-expression [,multiple-expression])",
  },
  "SYBASE.DATEFORMAT": {
    key: "SYBASE.DATEFORMAT",
    explanation: "calculatedColumnExplanation.sybase.sybaseDateFormat",
    example: "DATEFORMAT ( datetime-expression, string-expression )",
  },
  "SYBASE.DATENAME": {
    key: "SYBASE.DATENAME",
    explanation: "calculatedColumnExplanation.sybase.sybaseDateName",
    example: "DATENAME ( date-part, date-expression )",
  },
  "SYBASE.DATEPART": {
    key: "SYBASE.DATEPART",
    explanation: "calculatedColumnExplanation.sybase.sybaseDatePart",
    example: "DATEPART ( date-part, date-expression )",
  },
  "SYBASE.DATEROUND ": {
    key: "SYBASE.DATEROUND ",
    explanation: "calculatedColumnExplanation.sybase.sybaseDateRound",
    example:
      "DATEROUND (date-part, datetime-expression [,multiple-expression] )",
  },
  "SYBASE.DATETIME": {
    key: "SYBASE.DATETIME",
    explanation: "calculatedColumnExplanation.sybase.sybaseDatetime",
    example: "DATETIME ( expression )",
  },
  "SYBASE.DAY": {
    key: "SYBASE.DAY",
    explanation: "calculatedColumnExplanation.sybase.sybaseDay",
    example: "DAY ( date-expression )",
  },
  "SYBASE.DAYNAME": {
    key: "SYBASE.DAYNAME",
    explanation: "calculatedColumnExplanation.sybase.sybaseDayName",
    example: "DAYNAME ( date-expression )",
  },
  "SYBASE.DAYS": {
    key: "SYBASE.DAYS",
    explanation: "calculatedColumnExplanation.sybase.sybaseDays",
    example:
      "DAYS ( datetime-expression ) |   ( datetime-expression, datetime-expression ) |   ( datetime-expression, integer-expression )",
  },
  "SYBASE.DB_ID": {
    key: "SYBASE.DB_ID",
    explanation: "calculatedColumnExplanation.sybase.sybaseDbId",
    example: "DB_ID ( [ database-name ] )",
  },
  "SYBASE.DB_NAME": {
    key: "SYBASE.DB_NAME",
    explanation: "calculatedColumnExplanation.sybase.sybaseDbName",
    example: "DB_NAME ( [ database-id ] )",
  },
  "SYBASE.DB_PROPERTY": {
    key: "SYBASE.DB_PROPERTY",
    explanation: "calculatedColumnExplanation.sybase.sybaseDbProperty",
    example:
      "DB_PROPERTY ( { property-id | property-name } [ , { database-id | database-name } ] )",
  },
  "SYBASE.DEGREES": {
    key: "SYBASE.DEGREES",
    explanation: "calculatedColumnExplanation.sybase.sybaseDegrees",
    example: "DEGREES ( numeric-expression )",
  },
  "SYBASE.DIFFERENCE": {
    key: "SYBASE.DIFFERENCE",
    explanation: "calculatedColumnExplanation.sybase.sybaseDifference",
    example: "DIFFERENCE ( string-expression1, string-expression2 )",
  },
  "SYBASE.DOW": {
    key: "SYBASE.DOW",
    explanation: "calculatedColumnExplanation.sybase.sybaseDow",
    example: "DOW ( date-expression )",
  },
  "SYBASE.EXP": {
    key: "SYBASE.EXP",
    explanation: "calculatedColumnExplanation.sybase.sybaseExp",
    example: "EXP ( numeric-expression )",
  },
  "SYBASE.EXP_WEIGHTED_AVG": {
    key: "SYBASE.EXP_WEIGHTED_AVG",
    explanation: "calculatedColumnExplanation.sybase.sybaseExpWeightedAvg",
    example: "EXP_WEIGHTED_AVG (expression, period-expression)",
  },
  "SYBASE.FIRST_VALUE": {
    key: "SYBASE.FIRST_VALUE",
    explanation: "calculatedColumnExplanation.sybase.sybaseFirstValue",
    example: "FIRST_VALUE (expression [IGNORE NULLS | RESPECT NULLS])",
  },
  "SYBASE.FLOOR": {
    key: "SYBASE.FLOOR",
    explanation: "calculatedColumnExplanation.sybase.sybaseFloor",
    example: "FLOOR ( numeric-expression )",
  },
  "SYBASE.GETDATE": {
    key: "SYBASE.GETDATE",
    explanation: "calculatedColumnExplanation.sybase.sybaseGetdate",
    example: "GETDATE ()",
  },
  "SYBASE.GROUPING": {
    key: "SYBASE.GROUPING",
    explanation: "calculatedColumnExplanation.sybase.sybaseGrouping",
    example: "GROUPING ( group-by-expression ) ",
  },
  "SYBASE.GROUP_MEMBER": {
    key: "SYBASE.GROUP_MEMBER",
    explanation: "calculatedColumnExplanation.sybase.sybaseGroupMember",
    example:
      "GROUP_MEMBER ( group-name-string-expression[ , user-name-string-expression ] )",
  },
  "SYBASE.HEXTOBIGINT": {
    key: "SYBASE.HEXTOBIGINT",
    explanation: "calculatedColumnExplanation.sybase.sybaseHexToBigInt",
    example: "HEXTOBIGINT ( hexadecimal-string )",
  },
  "SYBASE.HEXTOINT": {
    key: "SYBASE.HEXTOINT",
    explanation: "calculatedColumnExplanation.sybase.sybaseHexToInt",
    example: "HEXTOINT ( hexadecimal-string )",
  },
  "SYBASE.HOUR": {
    key: "SYBASE.HOUR",
    explanation: "calculatedColumnExplanation.sybase.sybaseHour",
    example: "HOUR ( datetime-expression )",
  },
  "SYBASE.HOURS": {
    key: "SYBASE.HOURS",
    explanation: "calculatedColumnExplanation.sybase.sybaseHours",
    example:
      "HOURS ( datetime-expression  | datetime-expression, datetime-expression  | datetime-expression, integer-expression )",
  },
  "SYBASE.IFNULL": {
    key: "SYBASE.IFNULL",
    explanation: "calculatedColumnExplanation.sybase.sybaseIfNull",
    example: "IFNULL ( expression1, expression2 [ , expression3 ] )",
  },
  "SYBASE.INDEX_COL": {
    key: "SYBASE.INDEX_COL",
    explanation: "calculatedColumnExplanation.sybase.sybaseIndexCol",
    example: "INDEX_COL ( table-name, index-id, key_# [ , user-id ] )",
  },
  "SYBASE.INTTOHEX": {
    key: "SYBASE.INTTOHEX",
    explanation: "calculatedColumnExplanation.sybase.sybaseInToHex",
    example: "INTTOHEX ( integer-expression )",
  },
  "SYBASE.ISNULL": {
    key: "SYBASE.ISNULL",
    explanation: "calculatedColumnExplanation.sybase.sybaseIsNull",
    example: "ISNULL ( expression, expression [ …, expression ] )",
  },
  "SYBASE.ISNUMERIC": {
    key: "SYBASE.ISNUMERIC",
    explanation: "calculatedColumnExplanation.sybase.sybaseIsNumeric",
    example: "ISNUMERIC ( string )",
  },
  "SYBASE.LAST_VALUE": {
    key: "SYBASE.LAST_VALUE",
    explanation: "calculatedColumnExplanation.sybase.sybaseLastValue",
    example: "LAST_VALUE (expression [IGNORE NULLS | RESPECT NULLS])",
  },
  "SYBASE.LCASE": {
    key: "SYBASE.LCASE",
    explanation: "calculatedColumnExplanation.sybase.sybaseLcase",
    example: "LCASE ( string-expression )",
  },
  "SYBASE.LEAD": {
    key: "SYBASE.LEAD",
    explanation: "calculatedColumnExplanation.sybase.sybaseLead",
    example:
      "LEAD (value_expr) [, offset [, default]]) OVER ([PARTITION BY window partition] ORDER BY window ordering)",
  },
  "SYBASE.LEFT": {
    key: "SYBASE.LEFT",
    explanation: "calculatedColumnExplanation.sybase.sybaseLeft",
    example: "LEFT ( string-expression, numeric-expression )",
  },
  "SYBASE.LEN": {
    key: "SYBASE.LEN",
    explanation: "calculatedColumnExplanation.sybase.sybaseLen",
    example: "LEN ( string_expr )",
  },
  "SYBASE.LIST": {
    key: "SYBASE.LIST",
    explanation: "calculatedColumnExplanation.sybase.sybaseList",
    example:
      "LIST( [DISTINCT] string-expresssion [, 'delimiter-string'] [ORDER BY order-by-expression [ ASC | DESC ], ... ] ) ",
  },
  "SYBASE.LN": {
    key: "SYBASE.LN",
    explanation: "calculatedColumnExplanation.sybase.sybaseLn",
    example: "LN ( numeric-expression )",
  },
  "SYBASE.LOCATE": {
    key: "SYBASE.LOCATE",
    explanation: "calculatedColumnExplanation.sybase.sybaseLocate",
    example:
      "LOCATE ( string-expression1, string-expression2 [ , numeric-expression ] )",
  },
  "SYBASE.LOG": {
    key: "SYBASE.LOG",
    explanation: "calculatedColumnExplanation.sybase.sybaseLog",
    example: "LOG ( numeric-expression )",
  },
  "SYBASE.LOG10": {
    key: "SYBASE.LOG10",
    explanation: "calculatedColumnExplanation.sybase.sybaseLog10",
    example: "LOG10 ( numeric-expression )",
  },
  "SYBASE.LOWER": {
    key: "SYBASE.LOWER",
    explanation: "calculatedColumnExplanation.sybase.sybaseLower",
    example: "LOWER ( string-expression )",
  },
  "SYBASE.LTRIM": {
    key: "SYBASE.LTRIM",
    explanation: "calculatedColumnExplanation.sybase.sybaseLtrim",
    example: "LTRIM ( string-expression )",
  },
  "SYBASE.MAX": {
    key: "SYBASE.MAX",
    explanation: "calculatedColumnExplanation.sybase.sybaseMax",
    example: "MAX ( expression | DISTINCT column-name )",
  },
  "SYBASE.MEDIAN": {
    key: "SYBASE.MEDIAN",
    explanation: "calculatedColumnExplanation.sybase.sybaseMedian",
    example: "MEDIAN([ALL | DISTINCT] expression)",
  },
  "SYBASE.MIN": {
    key: "SYBASE.MIN",
    explanation: "calculatedColumnExplanation.sybase.sybaseMin",
    example: "MIN ( expression | DISTINCT column-name )",
  },
  "SYBASE.MINUTE": {
    key: "SYBASE.MINUTE",
    explanation: "calculatedColumnExplanation.sybase.sybaseMinute",
    example: "MINUTE ( datetime-expression )",
  },
  "SYBASE.MOD": {
    key: "SYBASE.MOD",
    explanation: "calculatedColumnExplanation.sybase.sybaseMod",
    example: "MOD ( dividend, divisor )",
  },
  "SYBASE.MONTH": {
    key: "SYBASE.MONTH",
    explanation: "calculatedColumnExplanation.sybase.sybaseMonth",
    example: "MONTH ( date-expression )",
  },

  "SYBASE.NOW": {
    key: "SYBASE.NOW",
    explanation: "calculatedColumnExplanation.sybase.sybaseNow",
    example: "NOW ( * )",
  },
  "SYBASE.NULLIF": {
    key: "SYBASE.NULLIF",
    explanation: "calculatedColumnExplanation.sybase.sybaseNullIf",
    example: "NULLIF ( expression1, expression2 )",
  },
  "SYBASE.NUMBER": {
    key: "SYBASE.NUMBER",
    explanation: "calculatedColumnExplanation.sybase.sybaseNumber",
    example: "NUMBER ( * )",
  },
  "SYBASE.PERCENT_RANK": {
    key: "SYBASE.PERCENT_RANK",
    explanation: "calculatedColumnExplanation.sybase.sybasePercentRank",
    example: "PERCENT_RANK () OVER ( ORDER BY expression [ ASC | DESC ] )",
  },
  "SYBASE.PERCENTILE_CONT": {
    key: "SYBASE.PERCENTILE_CONT",
    explanation: "calculatedColumnExplanation.sybase.sybasePercentileCont",
    example:
      "PERCENTILE_CONT ( expression1 ) WITHIN GROUP ( ORDER BY expression2 [ ASC | DESC ] )",
  },
  "SYBASE.PERCENTILE_DISC": {
    key: "SYBASE.PERCENTILE_DISC",
    explanation: "calculatedColumnExplanation.sybase.sybasePercentileDisc",
    example:
      "PERCENTILE_DISC ( expression1 ) WITHIN GROUP ( ORDER BY expression2 [ ASC | DESC ] )",
  },
  "SYBASE.PI": {
    key: "SYBASE.PI",
    explanation: "calculatedColumnExplanation.sybase.sybasePi",
    example: "PI ( * )",
  },
  "SYBASE.POWER": {
    key: "SYBASE.POWER",
    explanation: "calculatedColumnExplanation.sybase.sybasePower",
    example: "POWER ( numeric-expression1, numeric-expression2 )",
  },
  "SYBASE.PROPERTY": {
    key: "SYBASE.PROPERTY",
    explanation: "calculatedColumnExplanation.sybase.sybaseProperty",
    example: "PROPERTY ( { property-id  |  property-name } )",
  },
  "SYBASE.RADIANS": {
    key: "SYBASE.RADIANS",
    explanation: "calculatedColumnExplanation.sybase.sybaseRadians",
    example: "RADIANS ( numeric-expression )",
  },
  "SYBASE.RAND": {
    key: "SYBASE.RAND",
    explanation: "calculatedColumnExplanation.sybase.sybaseRand",
    example: "RAND ( [ integer-expression ] )",
  },
  "SYBASE.RANK": {
    key: "SYBASE.RANK",
    explanation: "calculatedColumnExplanation.sybase.sybaseRank",
    example:
      "RANK () OVER ( [ PARTITION BY ] ORDER BY  expression [ ASC | DESC ] )",
  },
  "SYBASE.REGR_AVGX": {
    key: "SYBASE.REGR_AVGX",
    explanation: "calculatedColumnExplanation.sybase.sybaseRegrAvgx",
    example: "REGR_AVGX (dependent-expression, independent-expression)",
  },
  "SYBASE.REGR_AVGY": {
    key: "SYBASE.REGR_AVGY",
    explanation: "calculatedColumnExplanation.sybase.sybaseRegrAvgy",
    example: "REGR_AVGY(dependent-expression, independent-expression)",
  },
  "SYBASE.REGR_COUNT": {
    key: "SYBASE.REGR_COUNT",
    explanation: "calculatedColumnExplanation.sybase.sybaseRegrCount",
    example: "REGR_COUNT(dependent-expression, independent-expression)",
  },
  "SYBASE.REPLICATE": {
    key: "SYBASE.REPLICATE",
    explanation: "calculatedColumnExplanation.sybase.sybaseReplicate",
    example: "REPLICATE ( string-expression, integer-expression )",
  },
  "SYBASE.REVERSE": {
    key: "SYBASE.REVERSE",
    explanation: "calculatedColumnExplanation.sybase.sybaseReverse",
    example: "REVERSE ( expression | uchar_expr )",
  },
  "SYBASE.RIGHT": {
    key: "SYBASE.RIGHT",
    explanation: "calculatedColumnExplanation.sybase.sybaseRight",
    example: "RIGHT ( string-expression, numeric-expression )",
  },
  "SYBASE.ROUND": {
    key: "SYBASE.ROUND",
    explanation: "calculatedColumnExplanation.sybase.sybaseRound",
    example: "ROUND ( numeric-expression, integer-expression )",
  },
  "SYBASE.RTRIM": {
    key: "SYBASE.RTRIM",
    explanation: "calculatedColumnExplanation.sybase.sybaseRtrim",
    example: "RTRIM ( string-expression )",
  },
  "SYBASE.SIN": {
    key: "SYBASE.SIN",
    explanation: "calculatedColumnExplanation.sybase.sybaseSin",
    example: "SIN ( numeric-expression )",
  },
  "SYBASE.SQRT": {
    key: "SYBASE.SQRT",
    explanation: "calculatedColumnExplanation.sybase.sybaseSqrt",
    example: "SQRT ( numeric-expression )",
  },
  "SYBASE.SQUARE": {
    key: "SYBASE.SQUARE",
    explanation: "calculatedColumnExplanation.sybase.sybaseSquare",
    example: "SQUARE ( numeric-expression )",
  },
  "SYBASE.STR": {
    key: "SYBASE.STR",
    explanation: "calculatedColumnExplanation.sybase.sybaseStr",
    example: "STR ( numeric-expression [ , length [ , decimal ] ] )",
  },
  "SYBASE.STRING": {
    key: "SYBASE.STRING",
    explanation: "calculatedColumnExplanation.sybase.sybaseString",
    example: "STRING ( string-expression [ , … ] ) ",
  },
  "SYBASE.SUBSTRING": {
    key: "SYBASE.SUBSTRING",
    explanation: "calculatedColumnExplanation.sybase.sybaseSubstring",
    example: "{ SUBSTRING | SUBSTR } ( string-expression, start [ , length ] )",
  },
  "SYBASE.SUM": {
    key: "SYBASE.SUM",
    explanation: "calculatedColumnExplanation.sybase.sybaseSum",
    example: "SUM ( expression | DISTINCT column-name )",
  },
  "SYBASE.TAN": {
    key: "SYBASE.TAN",
    explanation: "calculatedColumnExplanation.sybase.sybaseTan",
    example: "TAN ( numeric-expression )",
  },
  "SYBASE.TRUNCNUM": {
    key: "SYBASE.TRUNCNUM",
    explanation: "calculatedColumnExplanation.sybase.sybaseTruncnum",
    example: "TRUNCNUM ( numeric-expression, integer-expression )",
  },
  "SYBASE.UPPER": {
    key: "SYBASE.UPPER",
    explanation: "calculatedColumnExplanation.sybase.sybaseUpper",
    example: "UPPER ( string-expression )",
  },
};

export const calculatedColumnSybaseAseFunctions = {
  "SYBASE_ASE.AVG": {
    key: "SYBASE_ASE.AVG",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.AVG",
    example: "calculatedColumnExample.SYBASE_ASE.AVG",
  },
  "SYBASE_ASE.SUM": {
    key: "SYBASE_ASE.SUM",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.SUM",
    example: "calculatedColumnExample.SYBASE_ASE.SUM",
  },
  "SYBASE_ASE.COUNT": {
    key: "SYBASE_ASE.COUNT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.COUNT",
    example: "calculatedColumnExample.SYBASE_ASE.COUNT",
  },
  "SYBASE_ASE.COUNT(DISTINCT)": {
    key: "SYBASE_ASE.COUNT(DISTINCT)",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.COUNT_DISTINCT",
    example:
      "SYBASE_ASE.COUNT(DISTINCT expr)",
  },
  "SYBASE_ASE.MIN": {
    key: "SYBASE_ASE.MIN",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.MIN",
    example: "calculatedColumnExample.SYBASE_ASE.MIN",
  },
  "SYBASE_ASE.MAX": {
    key: "SYBASE_ASE.MAX",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.MAX",
    example: "calculatedColumnExample.SYBASE_ASE.MAX",
  },
  "SYBASE_ASE.ABS": {
    key: "SYBASE_ASE.ABS",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ABS",
    example: "calculatedColumnExample.SYBASE_ASE.ABS",
  },
  "SYBASE_ASE.SQUARE": {
    key: "SYBASE_ASE.SQUARE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.SQUARE",
    example: "calculatedColumnExample.SYBASE_ASE.SQUARE",
  },
  "SYBASE_ASE.PI": {
    key: "SYBASE_ASE.PI",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.PI",
    example: "calculatedColumnExample.SYBASE_ASE.PI",
  },
  "SYBASE_ASE.STDEV": {
    key: "SYBASE_ASE.STDEV",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.STDEV",
    example: "calculatedColumnExample.SYBASE_ASE.STDEV",
  },
  "SYBASE_ASE.ASCII": {
    key: "SYBASE_ASE.ASCII",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ASCII",
    example: "calculatedColumnExample.SYBASE_ASE.ASCII",
  },
  "SYBASE_ASE.CHAR": {
    key: "SYBASE_ASE.CHAR",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CHAR",
    example: "calculatedColumnExample.SYBASE_ASE.CHAR",
  },
  "SYBASE_ASE.CHARLENGTH": {
    key: "SYBASE_ASE.CHARLENGTH",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CHARLENGTH",
    example: "calculatedColumnExample.SYBASE_ASE.CHARLENGTH",
  },
  "SYBASE_ASE.CHARINDEX": {
    key: "SYBASE_ASE.CHARINDEX",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CHARINDEX",
    example: "calculatedColumnExample.SYBASE_ASE.CHARINDEX",
  },
  "SYBASE_ASE.LEFT": {
    key: "SYBASE_ASE.LEFT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LEFT",
    example: "calculatedColumnExample.SYBASE_ASE.LEFT",
  },
  "SYBASE_ASE.LEN": {
    key: "SYBASE_ASE.LEN",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LEN",
    example: "calculatedColumnExample.SYBASE_ASE.LEN",
  },
  "SYBASE_ASE.LOWER": {
    key: "SYBASE_ASE.LOWER",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LOWER",
    example: "calculatedColumnExample.SYBASE_ASE.LOWER",
  },
  "SYBASE_ASE.UPPER": {
    key: "SYBASE_ASE.UPPER",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.UPPER",
    example: "calculatedColumnExample.SYBASE_ASE.UPPER",
  },
  "SYBASE_ASE.LTRIM": {
    key: "SYBASE_ASE.LTRIM",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LTRIM",
    example: "calculatedColumnExample.SYBASE_ASE.LTRIM",
  },
  "SYBASE_ASE.PATINDEX": {
    key: "SYBASE_ASE.PATINDEX",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.PATINDEX",
    example: "calculatedColumnExample.SYBASE_ASE.PATINDEX",
  },
  "SYBASE_ASE.RIGHT": {
    key: "SYBASE_ASE.RIGHT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.RIGHT",
    example: "calculatedColumnExample.SYBASE_ASE.RIGHT",
  },
  "SYBASE_ASE.RTRIM": {
    key: "SYBASE_ASE.RTRIM",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.RTRIM",
    example: "calculatedColumnExample.SYBASE_ASE.RTRIM",
  },
  "SYBASE_ASE.SUBSTRING": {
    key: "SYBASE_ASE.SUBSTRING",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.SUBSTRING",
    example: "calculatedColumnExample.SYBASE_ASE.SUBSTRING",
  },
  "SYBASE_ASE.REVERSE": {
    key: "SYBASE_ASE.REVERSE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.REVERSE",
    example: "calculatedColumnExample.SYBASE_ASE.REVERSE",
  },
  "SYBASE_ASE.ISNULL": {
    key: "SYBASE_ASE.ISNULL",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ISNULL",
    example: "calculatedColumnExample.SYBASE_ASE.ISNULL",
  },
  "SYBASE_ASE.CURRENT_DATE": {
    key: "SYBASE_ASE.CURRENT_DATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CURRENT_DATE",
    example: "calculatedColumnExample.SYBASE_ASE.CURRENT_DATE",
  },
  "SYBASE_ASE.CURRENT_TIME": {
    key: "SYBASE_ASE.CURRENT_TIME",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CURRENT_TIME",
    example: "calculatedColumnExample.SYBASE_ASE.CURRENT_TIME",
  },
  "SYBASE_ASE.YEAR": {
    key: "SYBASE_ASE.YEAR",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.YEAR",
    example: "calculatedColumnExample.SYBASE_ASE.YEAR",
  },
  "SYBASE_ASE.DATENAME": {
    key: "SYBASE_ASE.DATENAME",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATENAME",
    example: "calculatedColumnExample.SYBASE_ASE.DATENAME",
  },
  "SYBASE_ASE.DATE_PART": {
    key: "SYBASE_ASE.DATE_PART",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATE_PART",
    example: "calculatedColumnExample.SYBASE_ASE.DATE_PART",
  },
  "SYBASE_ASE.DATEDIFF": {
    key: "SYBASE_ASE.DATEDIFF",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATEDIFF",
    example: "calculatedColumnExample.SYBASE_ASE.DATEDIFF",
  },
  "SYBASE_ASE.DATEADD": {
    key: "SYBASE_ASE.DATEADD",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATEADD",
    example: "calculatedColumnExample.SYBASE_ASE.DATEADD",
  },
  "SYBASE_ASE.GETDATE": {
    key: "SYBASE_ASE.GETDATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.GETDATE",
    example: "calculatedColumnExample.SYBASE_ASE.GETDATE",
  },
  "SYBASE_ASE.GETUTCDATE": {
    key: "SYBASE_ASE.GETUTCDATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.GETUTCDATE",
    example: "calculatedColumnExample.SYBASE_ASE.GETUTCDATE",
  },
  "SYBASE_ASE.CASE": {
    key: "SYBASE_ASE.CASE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CASE",
    example: "calculatedColumnExample.SYBASE_ASE.CASE",
  },
  "SYBASE_ASE.CAST": {
    key: "SYBASE_ASE.CAST",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CAST",
    example: "calculatedColumnExample.SYBASE_ASE.CAST",
  },
  "SYBASE_ASE.CONVERT": {
    key: "SYBASE_ASE.CONVERT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CONVERT",
    example: "calculatedColumnExample.SYBASE_ASE.CONVERT",
  },
  "SYBASE_ASE.ISDATE": {
    key: "SYBASE_ASE.ISDATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ISDATE",
    example: "calculatedColumnExample.SYBASE_ASE.ISDATE",
  },
};

export const calculatedColumnSybaseIqFunctions = {
  "SYBASE_IQ.AVG": {
    key: "SYBASE_IQ.AVG",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.AVG",
    example: "calculatedColumnExample.SYBASE_ASE.AVG",
  },
  "SYBASE_IQ.SUM": {
    key: "SYBASE_IQ.SUM",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.SUM",
    example: "calculatedColumnExample.SYBASE_ASE.SUM",
  },
  "SYBASE_IQ.COUNT": {
    key: "SYBASE_IQ.COUNT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.COUNT",
    example: "calculatedColumnExample.SYBASE_ASE.COUNT",
  },
  "SYBASE_IQ.COUNT(DISTINCT)": {
    key: "SYBASE_IQ.COUNT(DISTINCT)",
    explanation: "calculatedColumnExplanation.SYBASE_IQ.COUNT_DISTINCT",
    example:
      "SYBASE_IQ.COUNT(DISTINCT expr)",
  },
  "SYBASE_IQ.MIN": {
    key: "SYBASE_IQ.MIN",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.MIN",
    example: "calculatedColumnExample.SYBASE_ASE.MIN",
  },
  "SYBASE_IQ.MAX": {
    key: "SYBASE_IQ.MAX",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.MAX",
    example: "calculatedColumnExample.SYBASE_ASE.MAX",
  },
  "SYBASE_IQ.ABS": {
    key: "SYBASE_IQ.ABS",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ABS",
    example: "calculatedColumnExample.SYBASE_ASE.ABS",
  },
  "SYBASE_IQ.SQUARE": {
    key: "SYBASE_IQ.SQUARE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.SQUARE",
    example: "calculatedColumnExample.SYBASE_ASE.SQUARE",
  },
  "SYBASE_IQ.PI": {
    key: "SYBASE_IQ.PI",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.PI",
    example: "calculatedColumnExample.SYBASE_ASE.PI",
  },
  "SYBASE_IQ.STDEV": {
    key: "SYBASE_IQ.STDEV",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.STDEV",
    example: "calculatedColumnExample.SYBASE_ASE.STDEV",
  },
  "SYBASE_IQ.ASCII": {
    key: "SYBASE_IQ.ASCII",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ASCII",
    example: "calculatedColumnExample.SYBASE_ASE.ASCII",
  },
  "SYBASE_IQ.CHAR": {
    key: "SYBASE_IQ.CHAR",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CHAR",
    example: "calculatedColumnExample.SYBASE_ASE.CHAR",
  },
  "SYBASE_IQ.CHARLENGTH": {
    key: "SYBASE_IQ.CHARLENGTH",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CHARLENGTH",
    example: "calculatedColumnExample.SYBASE_ASE.CHARLENGTH",
  },
  "SYBASE_IQ.CHARINDEX": {
    key: "SYBASE_IQ.CHARINDEX",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CHARINDEX",
    example: "calculatedColumnExample.SYBASE_ASE.CHARINDEX",
  },
  "SYBASE_IQ.LEFT": {
    key: "SYBASE_IQ.LEFT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LEFT",
    example: "calculatedColumnExample.SYBASE_ASE.LEFT",
  },
  "SYBASE_IQ.LEN": {
    key: "SYBASE_IQ.LEN",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LEN",
    example: "calculatedColumnExample.SYBASE_ASE.LEN",
  },
  "SYBASE_IQ.LOWER": {
    key: "SYBASE_IQ.LOWER",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LOWER",
    example: "calculatedColumnExample.SYBASE_ASE.LOWER",
  },
  "SYBASE_IQ.UPPER": {
    key: "SYBASE_IQ.UPPER",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.UPPER",
    example: "calculatedColumnExample.SYBASE_ASE.UPPER",
  },
  "SYBASE_IQ.LTRIM": {
    key: "SYBASE_IQ.LTRIM",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.LTRIM",
    example: "calculatedColumnExample.SYBASE_ASE.LTRIM",
  },
  "SYBASE_IQ.PATINDEX": {
    key: "SYBASE_IQ.PATINDEX",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.PATINDEX",
    example: "calculatedColumnExample.SYBASE_ASE.PATINDEX",
  },
  "SYBASE_IQ.RIGHT": {
    key: "SYBASE_IQ.RIGHT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.RIGHT",
    example: "calculatedColumnExample.SYBASE_ASE.RIGHT",
  },
  "SYBASE_IQ.RTRIM": {
    key: "SYBASE_IQ.RTRIM",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.RTRIM",
    example: "calculatedColumnExample.SYBASE_ASE.RTRIM",
  },
  "SYBASE_IQ.SUBSTRING": {
    key: "SYBASE_IQ.SUBSTRING",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.SUBSTRING",
    example: "calculatedColumnExample.SYBASE_ASE.SUBSTRING",
  },
  "SYBASE_IQ.REVERSE": {
    key: "SYBASE_IQ.REVERSE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.REVERSE",
    example: "calculatedColumnExample.SYBASE_ASE.REVERSE",
  },
  "SYBASE_IQ.ISNULL": {
    key: "SYBASE_IQ.ISNULL",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ISNULL",
    example: "calculatedColumnExample.SYBASE_ASE.ISNULL",
  },
  "SYBASE_IQ.CURRENT_DATE": {
    key: "SYBASE_IQ.CURRENT_DATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CURRENT_DATE",
    example: "calculatedColumnExample.SYBASE_ASE.CURRENT_DATE",
  },
  "SYBASE_IQ.CURRENT_TIME": {
    key: "SYBASE_IQ.CURRENT_TIME",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CURRENT_TIME",
    example: "calculatedColumnExample.SYBASE_ASE.CURRENT_TIME",
  },
  "SYBASE_IQ.YEAR": {
    key: "SYBASE_IQ.YEAR",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.YEAR",
    example: "calculatedColumnExample.SYBASE_ASE.YEAR",
  },
  "SYBASE_IQ.DATENAME": {
    key: "SYBASE_IQ.DATENAME",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATENAME",
    example: "calculatedColumnExample.SYBASE_ASE.DATENAME",
  },
  "SYBASE_IQ.DATE_PART": {
    key: "SYBASE_IQ.DATE_PART",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATE_PART",
    example: "calculatedColumnExample.SYBASE_ASE.DATE_PART",
  },
  "SYBASE_IQ.DATEDIFF": {
    key: "SYBASE_IQ.DATEDIFF",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATEDIFF",
    example: "calculatedColumnExample.SYBASE_ASE.DATEDIFF",
  },
  "SYBASE_IQ.DATEADD": {
    key: "SYBASE_IQ.DATEADD",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.DATEADD",
    example: "calculatedColumnExample.SYBASE_ASE.DATEADD",
  },
  "SYBASE_IQ.GETDATE": {
    key: "SYBASE_IQ.GETDATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.GETDATE",
    example: "calculatedColumnExample.SYBASE_ASE.GETDATE",
  },
  "SYBASE_IQ.GETUTCDATE": {
    key: "SYBASE_IQ.GETUTCDATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.GETUTCDATE",
    example: "calculatedColumnExample.SYBASE_ASE.GETUTCDATE",
  },
  "SYBASE_IQ.CASE": {
    key: "SYBASE_IQ.CASE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CASE",
    example: "calculatedColumnExample.SYBASE_ASE.CASE",
  },
  "SYBASE_IQ.CAST": {
    key: "SYBASE_IQ.CAST",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CAST",
    example: "calculatedColumnExample.SYBASE_ASE.CAST",
  },
  "SYBASE_IQ.CONVERT": {
    key: "SYBASE_IQ.CONVERT",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.CONVERT",
    example: "calculatedColumnExample.SYBASE_ASE.CONVERT",
  },
  "SYBASE_IQ.ISDATE": {
    key: "SYBASE_IQ.ISDATE",
    explanation: "calculatedColumnExplanation.SYBASE_ASE.ISDATE",
    example: "calculatedColumnExample.SYBASE_ASE.ISDATE",
  },
};
