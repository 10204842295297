var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"gutter":20}},[_c('div',{class:{ 'disabled-overlay': _vm.enterSqlStatementSwitch }}),_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"tables-views"},[_c('div',{staticClass:"table-views-search vis-flex--spacebeetwen-Ycenter vis-flex-direction--row"},[_c('span',{staticClass:"table-views-title"},[_vm._v(_vm._s(_vm.$t("datamodel.Tables/Views")))]),_c('el-input',{attrs:{"placeholder":_vm.$t('datamodel.Type to search')},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}},[_c('el-button',{attrs:{"slot":"append","icon":_vm.CustomIcon.Search},on:{"click":_vm.handleSearch},slot:"append"})],1)],1),_c('el-table',{staticClass:"schema-header",attrs:{"data":[],"size":"mini"}},[_c('template',{slot:"empty"}),_c('el-table-column',{attrs:{"property":"name","label":_vm.$t('generalPages.name')}}),_c('el-table-column',{attrs:{"property":"type","label":_vm.$t('generalPages.labelType'),"align":"right"}})],2),_c('div',{staticClass:"schema-body"},[_c('div',{staticClass:"schema-body-wrapper vis-scroll-thin"},_vm._l((_vm.searchedSchemas),function(schema){return _c('div',{key:schema.schemaName},[_c('span',{staticClass:"table-views-folder",on:{"click":function($event){return _vm.toggleSchema(schema.schemaName)}}},[_c('i',{class:{
                    [_vm.CustomIcon.FolderOpenOutline]: schema.isSchemaOpen,
                    [_vm.CustomIcon.FolderOutline]: !schema.isSchemaOpen,
                  }}),_vm._v(_vm._s(schema.schemaName))]),(schema.isSchemaOpen)?_c('ul',{staticClass:"tables-views-list"},[_vm._l((schema.tables),function(table,index){return _c('li',{key:table.name,staticClass:"li-table-container",class:_vm.getLiTableContainerClass(table.name, index, schema)},[_c('el-checkbox',{attrs:{"value":table.selected},on:{"change":function($event){return _vm.handleTableSelected(
                        schema.schemaName,
                        table.name,
                        $event
                      )}}}),_c('span',{staticClass:"table-views-name-and-icon",on:{"click":function($event){return _vm.handleHighlightTable(
                        schema.schemaName,
                        table.name,
                        false
                      )}}},[(table.type === _vm.schemaItems.Table)?_c('i',{class:_vm.CustomIcon.TableLarge}):_c('i',{class:_vm.CustomIcon.TableHeadersEye}),_vm._v(_vm._s(table.name))]),_c('span',{staticClass:"table-views-type"},[_c('el-tag',{attrs:{"type":"info","size":"small"}},[_vm._v(_vm._s(table.type))])],1)],1)}),(schema.tables.length >= _vm.moreCount && !schema.showMore)?_c('li',{staticClass:"li-table-container-show-more",on:{"click":function($event){schema.showMore = true}}},[_c('span',{staticClass:"show-more"},[_vm._v("More...")])]):_vm._e()],2):_vm._e()])}),0)])],1)]),_c('el-col',{attrs:{"span":11}},[_c('div',{staticClass:"columns"},[_c('span',{staticClass:"columns-title"},[_vm._v(_vm._s(_vm.$t("datamodel.columns")))]),(_vm.highlightedTable)?_c('el-table',{ref:"columnsTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.highlightedTable.columns,"size":"mini","max-height":"300"},on:{"selection-change":function($event){return _vm.handleSelectionChange(
              _vm.highlightedSchemaName,
              _vm.highlightedTable.name,
              $event
            )}}},[_c('el-table-column',{attrs:{"type":"selection","width":"45"}}),_c('el-table-column',{attrs:{"property":"columnName","label":_vm.$t('generalPages.name')}}),_c('el-table-column',{attrs:{"property":"columnType","label":_vm.$t('generalPages.labelType')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":"info","size":"small"}},[_vm._v(_vm._s(scope.row.columnType))])]}}],null,false,1135342308)})],1):_vm._e()],1)])],1),_c('div',{staticClass:"select-table-or-view-container-footer"},[_c('el-switch',{staticClass:"enter-sql-statement-switch",attrs:{"disabled":!_vm.doesSelectedConnectionHaveWizardFeature,"active-text":_vm.$t('datamodel.Enter sql statement')},model:{value:(_vm.enterSqlStatementSwitch),callback:function ($$v) {_vm.enterSqlStatementSwitch=$$v},expression:"enterSqlStatementSwitch"}}),_c('button',{staticClass:"vis-btn medium back-button",on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm.$t("generalPages.back"))+" ")]),_c('button',{staticClass:"vis-btn medium done-button",on:{"click":_vm.done}},[_vm._v(" "+_vm._s(_vm.$t("generalPages.done"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }