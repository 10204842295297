<template>
  <div class="vis-filter-popup" :style="filterTypeDateStyle">
    <div
      class="vis-popup-common-title vis-flex--spacebeetwen-Ycenter vis-mb--1"
    >
      <span>
        {{ $t("filterComponents.filter") }} -
        <span class="vis-color-primary">
          {{ filterDetailData.field }}
        </span>
      </span>
      <div class="vis-filter-title-icons">
        <i
          @click="$emit('clearAllFilter')"
          :class="CustomIcon.ResetRefresh"
          aria-hidden="true"
        ></i>
        <i
          @click="changeFilterIconView()"
          :class="eyeIcon"
          aria-hidden="true"
        ></i>
      </div>
    </div>
    <div class="vis-filter-body">
      <AttributeFilter
        v-if="filterTypeAttribute"
        :attributeFilterSearchParam="attributeFilterSearchParam"
        :selectedComponent="selectedComponent"
        :filterDetailData="filterDetailData"
        :showExcludeSelectedCheckbox="showExcludeSelectedCheckbox"
        @setTabValue="setFilterTabComponent"
        @saveFilterByAttribute="saveFilterByAttribute"
        @closeFilters="closeFilters"
      />
      <!-- FIELD TYPE ATTRIBUTE END -->

      <!-- FIELD TYPE MEASURE -->
      <MeasuresFilter
        v-if="filterTypeMeasure"
        :selectedComponent="selectedComponent"
        :filterDetailData="filterDetailData"
        @setTabValue="setFilterTabComponent"
        @saveFilterByMeasure="saveFilterByMeasure"
        @closeFilters="closeFilters"
      />
      <!-- FIELD TYPE MEASURE END -->

      <!-- FIELD TYPE DATE -->
      <DateFilter
        v-if="filterTypeDate"
        :selectedComponent="selectedComponent"
        :filterDetailData="filterDetailData"
        @setTabValue="setFilterTabComponent"
        @saveFilterByDate="saveFilterByDate"
        @closeFilters="closeFilters"
      />
      <!-- FIELD TYPE DATE END -->
    </div>
  </div>
</template>
<script>
import AttributeFilter from "./AttributeFilter.vue";
import MeasuresFilter from "./MeasuresFilter.vue";
import DateFilter from "./DateFilter.vue";
// import ContextPopup from "../ContextPopup.vue";
import { filterPopupTabValue } from "./js/filters";
import { DatamodelContextDefaults } from "../../../commons/dataModelTypes";
import { CustomIcon } from "../../../assets/js/custom-icons";

export default {
  props: {
    filterType: {
      type: String,
    },
    selectedComponent: {
      type: String,
    },
    filterDetailData: {
      type: Object,
    },
    selectedPanel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    attributeFilterSearchParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showExcludeSelectedCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AttributeFilter,
    MeasuresFilter,
    DateFilter,
    // ContextPopup,
  },
  watch: {
    selectedPanel() {
      this.setActiveFilterIconData();
    },
  },
  data() {
    return {
      selectedTab: filterPopupTabValue.SELECTION,
      filterPopupTabValue: filterPopupTabValue,
      DatamodelContextDefaults: DatamodelContextDefaults,
      CustomIcon: CustomIcon,
      activeFilterIcon: null,
    };
  },
  mounted() {
    this.setActiveFilterIconData();
  },
  computed: {
    filterTypeAttribute() {
      return this.filterType == DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE;
    },
    filterTypeMeasure() {
      return this.filterType == DatamodelContextDefaults.USAGE_TYPES.MEASURE;
    },
    filterTypeDate() {
      return this.filterType == DatamodelContextDefaults.USAGE_TYPES.DATE;
    },
    eyeIcon() {
      return this.activeFilterIcon
        ? CustomIcon.VisibilityEye
        : CustomIcon.VisibilityOffBlack;
    },
    filterTypeDateStyle() {
      return this.filterTypeDate ? { height: "515px" } : "";
    },
  },
  methods: {
    saveFilterByAttribute(selectedComponent, filterFormData, isExcludeSelectedChecked) {
      this.$emit("saveFilterByAttribute", selectedComponent, filterFormData, isExcludeSelectedChecked);
    },

    saveFilterByDate(dateFilterFormData) {
      this.$emit(
        "saveFilterByDate",
        this.selectedComponent,
        dateFilterFormData
      );
    },

    saveFilterByMeasure(measureFilterFormData) {
      this.$emit(
        "saveFilterByMeasure",
        this.selectedComponent,
        measureFilterFormData
      );
    },

    closeFilters(value) {
      this.$emit("closeFilters", value);
    },

    setFilterTabComponent(value) {
      this.$emit("setFilterTabComponent", value);
    },

    changeFilterIconView() {
      this.$emit("changeFilterIconView");
    },

    setActiveFilterIconData() {
      let filter = this.selectedPanel?.details?.filters.find(
        (x) => x.field === this.filterDetailData.field
      );

      this.activeFilterIcon = filter?.isActiveFilterIcon;
    },
  },
};
</script>
<style scoped>
.vis-filter-popup {
  width: 420px;
}

.vis-filter-title {
  position: relative;
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 30px;
  background-color: #f5f5f5;
  font-size: 0.875rem;
  font-weight: 500;
}

.vis-filter-title-icons i {
  cursor: pointer;
}
.vis-filter-title-icons i:not(:first-child) {
  margin-left: 16px;
}
.vis-filter-content {
  position: relative;
  width: 100%;
  height: 250px;
}
.vis-panel-dropdown.vis-filtersPopup {
  width: 330px;
}
</style>
