import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/tr-TR";
import i18n from "./i18n";
import ElementLocale from "element-ui/lib/locale";
import { jwtDecode } from "jwt-decode";

import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NGaF1cXGNCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5fcHRXQmNfVEx1W0Q="
);

/*CSS*/
import "vue-good-table/dist/vue-good-table.css";
import "@mdi/font/css/materialdesignicons.css";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/dapVis.css";
//Dosyaya Çıkarıldı. import "@syncfusion/ej2-base/styles/material.css";
// Dosyaya Çıkarıldı. import "@syncfusion/ej2-buttons/styles/material.css";
// Dosyaya Çıkarıldı. import "@syncfusion/ej2-inputs/styles/material.css";
// Dosyaya Çıkarıldı. import "@syncfusion/ej2-popups/styles/material.css";
//Dosyaya Çıkarıldı. import "@syncfusion/ej2-navigations/styles/material.css";
/* CodeMirror Custom Css */
import "./assets/css/codemirror-custom.css";
import "material-icons/iconfont/material-icons.css";
import Keycloak from "keycloak-js";

import {
  getPrivilegesByRole,
  getPagePrivilegesByRole,
} from "./util/authorization";
import VueLogger from "vuejs-logger";

import { JsPlumbToolkitVue2Plugin } from "@jsplumbtoolkit/browser-ui-vue2";
import VueSSE from "vue-sse";

import { ACTION as ACTION_SSE } from "./store/modules/SSE/types";
import { ACTION as ACTION_SCENARIO } from "./store/modules/Scenario/Main/types";

// using defaults
Vue.use(VueSSE);

// import Toolkit plugin
Vue.use(JsPlumbToolkitVue2Plugin);

Vue.use(ElementUI, { locale });

ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.config.productionTip = false;
const options = {
  isEnabled: true,
  logLevel: Vue.config.productionTip ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
Vue.use(VueLogger, options);

import Typography from "./components/helper/Typography.vue";
import { PostMessageTypes } from "./postMessage";

Vue.component("Typography", Typography);

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

Vue.component('Splitpanes', Splitpanes);
Vue.component('Pane', Pane);

const createVueApp = () => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
};

if (process.env.VUE_APP_KEYCLOAK_ENABLED === "true") {
  const urlParams = new URLSearchParams(window.location.search);
  const realm = urlParams.get("realm");

  const setKeyCloackVariables = (keycloakParam, tokenString) => {
    const roles =
      keycloakParam?.realm_access?.roles ?? keycloakParam?.realmAccess?.roles;
    const groups =
      keycloakParam?.groups ?? keycloakParam?.idTokenParsed?.groups;

    store.dispatch(
      "Security/Set/Keycloak/Token",
      keycloakParam.token ?? tokenString
    );
    store.dispatch(
      "Security/Set/Keycloak/RefreshToken",
      keycloakParam.refreshToken
    );
    store.dispatch(
      "Security/Set/Keycloak/Username",
      keycloakParam.preferred_username ??
        keycloakParam.idTokenParsed.preferred_username
    );
    store.dispatch("Security/Set/Keycloak/LogoutFunc", keycloakParam.logout);
    store.dispatch(
      "Security/Set/Keycloak/Privileges",
      getPrivilegesByRole(roles)
    );
    store.dispatch(
      "Security/Set/Keycloak/PagePrivileges",
      getPagePrivilegesByRole(roles)
    );
    store.dispatch("Security/Set/Keycloak/Roles", roles);
    store.dispatch("Security/Set/Keycloak/Groups", groups);
    store.commit("Security/Mutate/Keycloak/UserFullName", keycloakParam?.idTokenParsed?.name ?? keycloakParam?.idTokenParsed?.preferred_username);
    store.commit("Security/Mutate/Keycloak/UserEmail", keycloakParam?.idTokenParsed?.email);
  };

  const initSSE = (keycloakParam) => {
    const isSSEEnabled = process.env.VUE_APP_ENABLE_SSE;

    if (isSSEEnabled === "true") {
      store.dispatch(ACTION_SSE.INIT_SSE, keycloakParam.token);
    }
  };

  const initModules = () => {
    const isScenarioModuleAvailable =
      process.env.VUE_APP_IS_SCENARIO_MODULE_AVAILABLE;

    if (isScenarioModuleAvailable === "true") {
      store.dispatch(ACTION_SCENARIO.INIT_SCENARIO_MODULE, true);
    }
  };

  const createRefreshInterval = (keycloak) => {
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            Vue.$log.debug("Token refreshed" + refreshed);
            setKeyCloackVariables(keycloak);
            initSSE(keycloak);
            initModules();
          } else {
            Vue.$log.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          Vue.$log.error("Failed to refresh token");
        });
    }, 60 * 1000);
  };

  const initKeycloak = () => {
    // Keycloak init options
    let initOptions = {
      url: process.env.VUE_APP_KEYCLOAK_ENDPOINT,
      realm: realm ?? process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
    };

    let keycloak = new Keycloak(initOptions);
    Vue.prototype.$keycloak = keycloak;

    let isAuthenticated = true;
    let isValidLoginUrl = true;

    keycloak
      .init({ onLoad: "check-sso" })
      .then(async () => {
        if (!keycloak.authenticated) {
          isAuthenticated = false;

          const url = new URL(window.location.href);
          const searchParams = new URLSearchParams(url.search);
          const hasTicketParam = searchParams.has("ticket");

          if (hasTicketParam) {
            isValidLoginUrl = true;
          } else {
            const isLoginPostfixRequired = process.env.VUE_APP_LOGIN_POSTFIX_REQUIRED;

            if (isLoginPostfixRequired === "true") {
              if (!window.location.href.endsWith("login"))
                isValidLoginUrl = false;
            } else {
              isValidLoginUrl = true;
            }
          }

          if (!isAuthenticated & isValidLoginUrl) {
            try {
                const auth = await keycloak.login();

                if (!auth) {
                  isAuthenticated = false;

                  return;
                }

                setKeyCloackVariables(keycloak);
                initSSE(keycloak);
                initModules();
                createVueApp();
                createRefreshInterval(keycloak);
            } catch(err) {
                Vue.$log.error("Authentication Failed");
                Vue.$log.error(err);
            }
          } else if (!isAuthenticated & !isValidLoginUrl) {
            window.location.href = "/login";
          }
        } else {
          setKeyCloackVariables(keycloak);
          initSSE(keycloak);
          initModules();
          createVueApp();
          createRefreshInterval(keycloak);
        }
      })
      .catch(() => {
        Vue.$log.error("Authentication Failed");
      });
  };

  const requestParentTokenFromIFrame = () => {
    console.log(
      "requestParentTokenFromIFrame because of token expire date is less than 5 minute"
    );
    window.parent.postMessage(
      { type: PostMessageTypes.DataPlateauTokenRequest },
      "*"
    );
  };

  let tokenCheckInterval = null;

  // Function to create or reset the interval for token expiration check
  const setupTokenExpirationCheck = (expirationMilliseconds) => {
    // Clear any existing interval before creating a new one
    clearInterval(tokenCheckInterval);

    const checkTokenExpiration = () => {
      console.log("checkTokenExpiration every 1 minutes");
      const currentTime = Date.now();
      const fiveMinutesInMillis = 5 * 60 * 1000; // 5 minutes in milliseconds

      if (expirationMilliseconds - currentTime < fiveMinutesInMillis) {
        requestParentTokenFromIFrame();
      }
    };

    // Interval to check token expiration every minute
    tokenCheckInterval = setInterval(checkTokenExpiration, 60 * 1000); // 1 minute in milliseconds
  };

  // simulate child listener
  window.addEventListener("message", function (event) {
    if (event.data.type === PostMessageTypes.DataPlateauTokenResponse) {
      const token = event.data.token;
      const decoded = jwtDecode(token);

      setupTokenExpirationCheck(decoded.exp); // Create or reset the interval
      setKeyCloackVariables(decoded, token);
      initSSE({ token });
      initModules();
      createVueApp();
    }
  });

  if (
    window.self !== window.top &&
    process.env.VUE_APP_REQUEST_PARENT_TOKEN_FROM_IFRAME === "true"
  ) {
    // Send a message to the parent window
    console.log(
      "VUE_APP_REQUEST_PARENT_TOKEN_FROM_IFRAME is true, so getting first token from parent window"
    );
    requestParentTokenFromIFrame();
  } else {
    initKeycloak();
  }
} else {
  createVueApp();
}
