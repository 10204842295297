<template>
  <div class="vis-row vis-setting-bar vis-bg-color-white">
    <div
      class="vis-dashboard-custom-col vis-height-100 vis-right-border vis-panelsettings-bar-left-area"
    >
      <div class="vis-panelsettings-bar-iconbox">
        <i
          v-for="icon in getLeftAreaIconList()"
          :key="icon.id"
          :class="[icon.iconName, icon.className]"
          aria-hidden="true"
          @click="icon.handleServiceFn()"
        ></i>
      </div>
    </div>
    <div class="vis-col vis-ma--none vis-panelsettings-bar-right-area">
      <span class="vis-font-size-0875 vis-font-medium">
        {{ panelSettingsBarTitle }}
      </span>
      <div class="vis-panelsettings-bar-iconbox">
        <i
          v-for="icon in getRightAreaIconList()"
          :key="icon.id"
          :class="[icon.iconName, icon.className]"
          aria-hidden="true"
          @click="icon.handleServiceFn()"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { panelTools } from "../../commons/dashboardAndPanel";
import uuid from "uuid";

export default {
  props: {
    selectedToolBoxRightArea: {
      default: "",
      type: String,
    },
    isDatasetFieldMenuActive: {
      type: Boolean,
      default: true
    },
    isSlotMenuActive: {
      type: Boolean,
      default: true
    },
    isVisualizeAiIconDisabled: {
      type: Boolean,
      default: false
    },
    isShowLogoTitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      panelTools: panelTools,
    };
  },
  computed: {
    panelSettingsBarTitle() {
      return panelTools.VISUALIZE_AI_TOOLBOX === this.selectedToolBoxRightArea
        ? this.$t("generalPages.How can I help you today?")
        : this.isShowLogoTitle ? this.$t("generalPages.Select Image"): this.$t("generalPages.Edit Chart");
    },
  },
  methods: {
    getRightAreaIconList() {
      return [
        {
          id: uuid(),
          iconName: CustomIcon.Lamp,
          className: `vis-cursor-pointer vis-mr--1 ${
            panelTools.VISUALIZE_AI_TOOLBOX === this.selectedToolBoxRightArea
              ? "vis-active-setting-icon"
              : ""
          }`,
          isHiddenIcon: this.isVisualizeAiIconDisabled,
          handleServiceFn: () => {
            this.$emit(
              "handleChangeSelectedToolBoxRightArea",
              panelTools.VISUALIZE_AI_TOOLBOX
            );
          },
        },
        {
          id: uuid(),
          iconName: CustomIcon.Property,
          className: `vis-cursor-pointer  ${
            panelTools.CHART_PROPERTIES_TOOLBOX ===
            this.selectedToolBoxRightArea
              ? "vis-active-setting-icon"
              : ""
          }`,
          handleServiceFn: () => {
            this.$emit(
              "handleChangeSelectedToolBoxRightArea",
              panelTools.CHART_PROPERTIES_TOOLBOX
            );
          },
        },
      ].filter((x) => !x?.isHiddenIcon);
    },
    getLeftAreaIconList() {
      return [
        {
          id: uuid(),
          iconName: CustomIcon.DatamodelIcon,
          className: `vis-cursor-pointer vis-mr--1 vis-font-size-1 ${
            this.isDatasetFieldMenuActive ? "vis-active-setting-icon" : ""
          }`,
          handleServiceFn: () => {
            this.$emit("toggleDatasetFieldMenu");
          },
        },
        {
          id: uuid(),
          iconName: CustomIcon.ListView,
          className: `vis-cursor-pointer  ${
            this.isSlotMenuActive ? "vis-active-setting-icon" : ""
          }`,
          handleServiceFn: () => {
            this.$emit("toggleSlotMenu");
          },
        },
      ];
    },
  },
};
</script>
<style scoped>
.vis-panelsettings-bar-right-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
.vis-panelsettings-bar-left-area {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 24px;
}
</style>
