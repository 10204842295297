
import {
    DROPDOWN_FILTER_PROPERTIES_ENUM,
    filterDefaultValues,
    filterSelectionActions
} from "../../commons/dashboardProperties";
import { DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/dropdownFilterDefaultProperties";

export default {
    computed: {
        dropdownFilterComponentsMapping() {
            return {
                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_MANDATORY_SELECTION]: {
                    id: this.getPropertyId(
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_MANDATORY_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.mandatorySelection",
                    value: this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_MANDATORY_SELECTION
                    ],
                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_MANDATORY_SELECTION,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },

                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_ALLOW_MULTISELECT]: {
                    id: this.getPropertyId(
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_ALLOW_MULTISELECT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.multiSelect",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_ALLOW_MULTISELECT
                    ], DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_ALLOW_MULTISELECT),
                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_ALLOW_MULTISELECT,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SHOW_SQL]: {
                    id: this.getPropertyId(
                      DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SHOW_SQL
                      ],
                      DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_SHOW_SQL
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SHOW_SQL,
                    flexClass: true,
                },
                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_APPLY_TO_TABS]: {
                    id: this.getPropertyId(
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_APPLY_TO_TABS,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Apply to Tabs",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_APPLY_TO_TABS
                    ], [this.dashboardTabsOptionList[this.chartTabIndex]?.value]),

                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_APPLY_TO_TABS,
                    componentType: this.componentTypes.CUSTOM_MULTIPLE_SELECT_BOX,
                    selectValues: this.dashboardTabsOptionList,
                    flexClass: false,
                },
                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SELECTION_ACTION]: {
                    id: this.getPropertyId(
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SELECTION_ACTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.selectionAction",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SELECTION_ACTION
                    ], DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_SELECTION_ACTION),

                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SELECTION_ACTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterSelectionActions,
                    flexClass: false,
                },

                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_DEFAULT_SELECTION]: {
                    id: this.getPropertyId(
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_DEFAULT_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.defaultValue",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_DEFAULT_SELECTION
                    ], DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_DEFAULT_SELECTION),
                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_DEFAULT_SELECTION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterDefaultValues,
                    flexClass: false,
                },

                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_UNIQUE_NAME]: {
                    id: this.getPropertyId(
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_UNIQUE_NAME,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.uniqueName",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_UNIQUE_NAME
                    ], DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_UNIQUE_NAME),
                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_UNIQUE_NAME,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },

                [DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_TAB_INDEX]: {
                    id: this.getPropertyId(
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_TAB_INDEX,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.tabIndex",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_TAB_INDEX
                    ], DROPDOWN_FILTER_DEFAULT_CONFIGURATIONS.DROPDOWN_FILTER_TAB_INDEX),
                    property: DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_TAB_INDEX,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                },
            }
        },
    },
};
