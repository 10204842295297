import { BOX_KEY_ENUM, chartTypes, detailsKeys, getSelectedField, otherChartType, panelTypes } from "../../commons/dashboardAndPanel";
import { allPropertiesKeys, datatablePropertiesEnum } from "../../commons/dashboardProperties";
import cloneDeep from "clone-deep";
import { CustomIcon } from "../../assets/js/custom-icons";
import { chartContextMenuItemKey } from "../../util/chart-context-menu/chartContextMenuHelper";
import i18n from "../../i18n";
import uuid from "uuid";
import { SLOT_FIELD_ID_PREFIX } from "../../util/data-table/tableCommonsHelper";
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";

const chartUsingCommonStyles = {
    [chartTypes.SUMMARY]: chartTypes.SUMMARY,
    [chartTypes.LOGO]: chartTypes.LOGO,
    [otherChartType.BUTTON]: otherChartType.BUTTON,
    [otherChartType.TEXT_EDITOR]: otherChartType.TEXT_EDITOR,
    [chartTypes.SELECTOR_DATA_ITEM_CHART]: chartTypes.SELECTOR_DATA_ITEM_CHART
}
export const GRID_LAYOUT_CONTEXT_MENU_KEYS = {
    PASTE: "PASTE"
};
export const panelObj = ({
  x,
  y,
  gridItemW,
  gridItemH,
  chartItem,
  tabIndex,
  heightValue,
  widthValue,
  chartId,
  selectedTabPanelList,
  selectedTab,
  isFilterGridLayoutItem,
  layoutInfo,
  isDuplicated = false
}) => {
    let d = new Date();
    let currentTime = d.getTime();

    let addGridItem = {
        name: "",
        description: "",
        footnote: "",
        created_by: "",
        created_date: "",
        updated_by: "",
        updated_date: "",
        type: chartId,
        chartTypeName: chartItem.name,
        x: x,
        y: y,
        w: gridItemW,
        h: gridItemH,
        i: "temp_" + uuid(),
        details: {
            [detailsKeys.METRICS]: [],
            [detailsKeys.AGGREGATION]: [],
            [detailsKeys.COLUMNS]: [],
            [detailsKeys.SECTIONS]: [],
            [detailsKeys.FILTERS]: [],
            [detailsKeys.PARAMS]: [],
            [detailsKeys.CUSTOM_FILTERS]: [],
            [detailsKeys.ORDERS]: [],
            [detailsKeys.LOGO]: ""
        },
        properties: {
            style: {
            },
            height: heightValue,
            width: widthValue,
            generatedDivid: "chrt_" + currentTime,
            tabIndex: tabIndex,
            uiTabId: selectedTab?.properties?.uiTabId,
            dataSet: {
                id: "",
                name: "",
            },
            vismetadata: {
                vistype: chartTypes.OTHER,
            },
            smartTitle: true
        },
    };
    
    //When a chart is duplicated, it should not enter the following if conditions. The following if conditions are intended for newly created charts.
    if (isDuplicated) return addGridItem;

    if (selectedTabPanelList?.length > 0) {
        let selectedPanelParams = [];
        selectedPanelParams = cloneDeep(
            selectedTabPanelList[0].details.params
        );
        if (selectedPanelParams?.length > 0) {
            addGridItem.details.params = selectedPanelParams;
        }
    }

    const pivotAndTableCommonSetProperties = () =>  {
        addGridItem.properties.vismetadata.vistype = chartTypes.OTHER;
        addGridItem.properties.conditionalFormattings = [];
        addGridItem.properties.columnBasedFormattings = []
    };

    if (chartId === chartTypes.TABLE) {
        addGridItem.properties.style[
            datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
        ] = true;
        pivotAndTableCommonSetProperties();
    }

    if (chartId === chartTypes.PIVOT_TABLE) {
        pivotAndTableCommonSetProperties();
    }

    if (Object.values(chartUsingCommonStyles).includes(chartId)) {
        const defaultStyle = {
            HAS_TITLE: false,
            HAS_SUBTITLE: false,
            HAS_FOOTNOTE: false,
        };

        addGridItem.properties.style = defaultStyle;
    }

    if (isFilterGridLayoutItem) {
      addGridItem.properties.isFilterPaneChart = isFilterGridLayoutItem;
      addGridItem.properties.style = {
        ...addGridItem.properties.style,
        ...{
          HAS_SUBTITLE: false,
        },
      };
    }

    if (chartId === chartTypes.TAB_PANEL) {
      addGridItem.properties.tabList = [];
      addGridItem.properties.tabList.push({id: 'panelTabId' + uuid(), name: "Tab"})
    }

    //Tab panel içine drag-drop yapılan chart'lar için çalışır.
    if (layoutInfo?.getAttribute("chart-type") === chartTypes.TAB_PANEL) {
      const dragAndDropTabPanel =  selectedTabPanelList.find(x=> x.i === layoutInfo.id);

      addGridItem.properties = {...addGridItem.properties, ...getTabPanelChartProperties(layoutInfo, dragAndDropTabPanel)}
      addGridItem.properties.style = {...addGridItem.properties.style, ...{
        [allPropertiesKeys.HAS_BORDER]: false,
        [allPropertiesKeys.HAS_DROP_SHADOW]: false,
        HAS_FOOTNOTE: false,
      }}
    }

    if (chartId === chartTypes.SELECTOR_DATA_ITEM_CHART) {
      addGridItem.details = {};
      addGridItem.details[detailsKeys.SELECTOR_PARAMETERS] = []
      addGridItem.details[detailsKeys.selectorDataitemValue] = "";
      addGridItem.properties.style.HAS_TITLE = true;
    }
    
    return addGridItem;
}
export const getTabPanelChartProperties = (layoutInfo, dragAndDropTabPanel) => {
  return {
    isTabPanelTabChart: true,
    tabPanelTabId: layoutInfo.getAttribute("tab-panel-tab-id"),
    connectedChartId: layoutInfo.id,
    height: Number(dragAndDropTabPanel?.properties?.height) - 50,
    width: dragAndDropTabPanel?.properties?.width
  }
} 
export const getChartAggregationsByIsDrillDown = (selectedChart) => {
    return selectedChart?.details?.[detailsKeys.AGGREGATION]?.filter(x => !x.isDrillDownAgg)?.length ? selectedChart?.details?.[detailsKeys.AGGREGATION] : selectedChart?.details?.defaultAggregation;
}

const getCommonDrildownMenu = (path, selectedField, fields) => {
    return {
        text: getSelectedField(fields, path.datasetId, path.fieldId)?.alias,
        value: { activeFieldInAggregation: path, selectedField },
        key: chartContextMenuItemKey.DRILL_DOWN,
        notRunTheViewChart: true,
        emitFunction: "addSelectedFilterFromChart",
    }
}

export const getChartContextMenuItems = (filteredDashboardListByDatamodelId, clickedChartItemName, selectedChart, fields) => {
    if(!selectedChart) return;
    const drillDownMenuAggregations = getChartAggregationsByIsDrillDown(selectedChart);
    const styleKeys = Object.keys(selectedChart?.properties?.style);
    const foundAllowDrillThroughKey = styleKeys.find(x=> x.includes("ALLOW_DRILL_THROUGH"));
    const allowDrillThrough =
    selectedChart?.properties?.style?.[foundAllowDrillThroughKey] === undefined
      ? true
      : selectedChart?.properties?.style?.[foundAllowDrillThroughKey];
    
    return [
        clickedChartItemName ? {
            text: clickedChartItemName,
            notClicked: true,
            title: true
        } : null,
        clickedChartItemName ? {
            separator: true
        } : null,
        clickedChartItemName ? {
            iconCss: CustomIcon.Title, //TODO İconlar tasarımdan gelince değiştirilecek
            text: i18n.tc("dashboard.contextMenu.exclude"),
            emitFunction: "addSelectedFilterFromChart",
            key: chartContextMenuItemKey.EXCLUDE,
        } : null,
        clickedChartItemName ? {
            iconCss: CustomIcon.Title, //TODO İconlar tasarımdan gelince değiştirilecek
            text: i18n.tc("dashboard.contextMenu.include"),
            emitFunction: "addSelectedFilterFromChart",
            key: chartContextMenuItemKey.INCLUDE,
        } : null,
        //DRİL DOWN MENU START
        drillDownMenuAggregations?.filter(x => x.drillPaths?.length)?.length ? {
            iconCss: CustomIcon.Title, //TODO İconlar tasarımdan gelince değiştirilecek
            text: i18n.tc("dashboard.contextMenu.drillDown"),
            notClicked: true,
            items: drillDownMenuAggregations?.filter(x => x.drillPaths?.length)?.length > 1 ? drillDownMenuAggregations?.map((selectedField) => {
                return {
                    text: selectedField.alias,
                    iconCss: CustomIcon.Attribute,
                    notClicked: true,
                    items: selectedField?.drillPaths?.map((d) => {
                        return {
                            text: d.name,
                            items: d.paths?.map((path) => {
                                return getCommonDrildownMenu(path, selectedField, fields)
                            })
                        }
                    })
                }
            }) : drillDownMenuAggregations?.find(x=> x?.drillPaths?.length)?.drillPaths?.map((hierarchy) => {
                return {
                    text: hierarchy.name,
                    items: hierarchy.paths.map((path) => {
                        return getCommonDrildownMenu(path, drillDownMenuAggregations?.[0], fields)
                    })
                }
            })
        } : null,
        //DRİL DOWN MENU END
        allowDrillThrough && filteredDashboardListByDatamodelId?.length ?
        {
            iconCss: CustomIcon.ArrowRight,
            text: i18n.tc("dashboard.contextMenu.goTo"),
            notClicked: true,
            items: filteredDashboardListByDatamodelId.map((x) => {
                return {
                    iconCss: CustomIcon.Dashboard,
                    text: x.name,
                    value: x.id,
                    emitFunction: "onChartDrillThroughClick",
                    key: chartContextMenuItemKey.DRILL_THROUGH,
                    items: x?.tabs?.filter((f) => !f.order).map((t) => { //TODO - Tabs are temporarily hidden.
                        return {
                            text: t.name,
                            value: t.id,
                            notClicked: true,
                        }
                    })
                };
            }),
        } : null,
    ].filter(x => x);
}
export const getGridLayoutMenuItems = () => {
    return [
        {
            text: i18n.tc("generalPages.Paste"),
            value: GRID_LAYOUT_CONTEXT_MENU_KEYS.PASTE,
        },
    ]
}



export const addNewKeyInDetailsDataArrays = (detailsData) => {
  const charts = {
    [chartTypes.TABLE]: {
      serviceFn: () => changeTableDetailsField(detailsData),
    },
    [chartTypes.PIVOT_TABLE]: {
        serviceFn: () => changePivotDetailsField(detailsData),
    },
    [chartTypes.BAR]: {
      serviceFn: () => changeBarAndColumnFieldKey(detailsData),
    },
    [chartTypes.COLUMN_CHART]: {
      serviceFn: () => changeBarAndColumnFieldKey(detailsData),
    },
    [chartTypes.STACKED_COLUMN_CHART]: {
        serviceFn: () => changeBarAndColumnFieldKey(detailsData),
      },
      [chartTypes.STACKED_BAR_CHART]: {
        serviceFn: () => changeBarAndColumnFieldKey(detailsData),
      },
    [chartTypes.AREA_CHART]: {
      serviceFn: () => changeAreaAndLineFieldKey(detailsData),
    },
    [chartTypes.LINE_CHART]: {
      serviceFn: () => changeAreaAndLineFieldKey(detailsData),
    },
    [chartTypes.PIE]: {
      serviceFn: () => changePieChartFieldKey(detailsData),
    },
    [chartTypes.SUMMARY]: {
      serviceFn: () => changeSummaryFieldKey(detailsData),
    },
  };

  return charts?.[detailsData?.chartType]?.serviceFn();
};

const changeFieldInFilters = (detailsData) => {
  detailsData?.filters.forEach((filter) => {
    if (filter.operator?.includes("g")) {
      filter.operator = filter.operator.replace("g", "q");
    }
  });
};

const changeFieldInMetrics = (detailsData) => {
  detailsData?.[detailsKeys.METRICS].forEach((metric) => {
    metric.boxKey = BOX_KEY_ENUM.MEASURES.KEY;
  });
}

const changeTableDetailsField = (detailsData) => {
  detailsData?.[detailsKeys.METRICS].forEach((metric, index) => {
    metric.slotFieldId = SLOT_FIELD_ID_PREFIX.MEA + index;
    metric.boxKey = BOX_KEY_ENUM.COLUMN.KEY;
  });
  detailsData?.[detailsKeys.AGGREGATION].forEach((agg, index) => {
    agg.slotFieldId = SLOT_FIELD_ID_PREFIX.AGG + index;
    agg.boxKey = BOX_KEY_ENUM.COLUMN.KEY;
  });

  changeFieldInFilters(detailsData);

  detailsData.columns = [...detailsData?.[detailsKeys.METRICS], ...detailsData?.[detailsKeys.AGGREGATION]];

  return detailsData;
};
const changePivotDetailsField = (detailsData) => {
    changeFieldInMetrics(detailsData);
    
    detailsData?.[detailsKeys.AGGREGATION].forEach((agg) => {
      if (!agg?.boxKey) agg.boxKey = BOX_KEY_ENUM.COLUMN.KEY;
    });
  
    changeFieldInFilters(detailsData);

    detailsData.columns = [...detailsData?.[detailsKeys.METRICS], ...detailsData?.[detailsKeys.AGGREGATION]];
  
    return detailsData;
};
const changeBarAndColumnFieldKey = (detailsData) => {
  changeFieldInMetrics(detailsData);

  detailsData?.[detailsKeys.AGGREGATION].forEach((agg) => {
    if (!agg?.boxKey) agg.boxKey = BOX_KEY_ENUM.BARS.KEY;
  });

  changeFieldInFilters(detailsData);

  return detailsData;
};
const changeAreaAndLineFieldKey = (detailsData) => {
  changeFieldInMetrics(detailsData);

  detailsData?.[detailsKeys.AGGREGATION].forEach((agg) => {
    agg.boxKey = BOX_KEY_ENUM.POINTS.KEY;
  });

  changeFieldInFilters(detailsData);

  return detailsData;
};
const changePieChartFieldKey = (detailsData) => {
  changeFieldInMetrics(detailsData);

  detailsData?.[detailsKeys.AGGREGATION].forEach((agg) => {
    agg.boxKey = BOX_KEY_ENUM.SLICES.KEY;
  });

  changeFieldInFilters(detailsData);

  return detailsData;
};
const changeSummaryFieldKey = (detailsData) => {
  changeFieldInMetrics(detailsData);

  changeFieldInFilters(detailsData);

  return detailsData;
};
export class ChartPropertyFormat {
  getNotToCopyStyleRuleList() {
    return [
      "UNIQUE_NAME",
      "DRILL_THROUGH_DASHBOARD_LIST",
      "ALLOW_DRILL_THROUGH",
      "DRILL_THROUGH_ON_DOUBLE_CLICK",
      "POSITION_X",
      "POSITION_Y"
    ];
  }

  getSameChartCondition(chartUsedChartType, chartTypeToChange) {
    //Aşağıdaki chartların özellikleri Bar,pie,colum vb chartları etkilemeyecek. Kendi aralarında kopyalama işlemi yapılacak. Bu fonksiyon bunu sağlıyor.
    const isSwitchingToTable = chartTypeToChange === chartTypes.TABLE;
    const isCurrentlyTable = chartUsedChartType === chartTypes.TABLE;

    const isSwitchingToPivotTable =
      chartTypeToChange === chartTypes.PIVOT_TABLE;
    const isCurrentlyPivotTable = chartUsedChartType === chartTypes.PIVOT_TABLE;

    const isSwitchingToFilter = chartTypeToChange?.toLowerCase().includes(panelTypes.FILTER);
    const isCurrentlyFilter = chartUsedChartType?.toLowerCase().includes(panelTypes.FILTER);

    return (
      isSwitchingToTable !== isCurrentlyTable ||
      isSwitchingToPivotTable !== isCurrentlyPivotTable ||
      isSwitchingToFilter !== isCurrentlyFilter
    );
  }
}

export const setCommonFieldKey = (field, boxKey, clonedParams) => {
  field.selectorChartI = clonedParams?.selectorChartI;
  field.isSelectorDataItemDefaultValue = clonedParams?.isDefaultValueActive;
  field.boxKey = boxKey;
}
export const setTableDetailList = (chart, changedList, field) => {
  const foundFindIndex = chart.details.columns.findIndex(x=> x.fieldId === field.selectorChartI);
  
  const fieldUsageTypeMeasure = field?.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE;
  const slotFieldIdPrefix = fieldUsageTypeMeasure ? SLOT_FIELD_ID_PREFIX.MEA: SLOT_FIELD_ID_PREFIX.AGG;

  changedList = fieldUsageTypeMeasure ? chart.details?.[detailsKeys.METRICS] : chart.details?.[detailsKeys.AGGREGATION];
  field.slotFieldId = slotFieldIdPrefix + chart?.details?.columns?.length;
  
  chart.details.columns.splice(foundFindIndex + 1, 0, field);
  changedList.push(field);
 
}
export const setChartDetailList = (chart, changedList, boxKey, clonedParams) => {
  if (clonedParams.isMultiple && clonedParams?.field?.length) { //FOR SELECTOR CHART IS MULTIPLE
      clonedParams.field.forEach(field => {
          setCommonFieldKey(field, boxKey, clonedParams);

          if (chart.type === chartTypes.TABLE) {
              setTableDetailList(chart, changedList, field);
              return;
          }

          changedList.push(field);
      });
  } else if (!clonedParams?.isMultiple && clonedParams?.field) {  //FOR SELECTOR CHART IS SINGLE
      setCommonFieldKey(clonedParams.field, boxKey, clonedParams);

      if (chart.type === chartTypes.TABLE) {
          setTableDetailList(chart, changedList, clonedParams.field);
          return;
      }

      changedList.push(clonedParams.field);
  }
}

// --- TODO SURUM SONRASI CALISMA YAPILACAK ---
// const commonDetailKeys = {
//   [detailsKeys.METRICS]: [],
//   [detailsKeys.AGGREGATION]: [],
//   [detailsKeys.CUSTOM_FILTERS]: []
// }
// const getChartConfiguration = {
//   [dashboardFilterTypes.FILTER_DATE]: {
//     details: {
//       ...commonDetailKeys
//     },
//   },
//   [dashboardFilterTypes.FILTER_TEXT]: {
//     details: {
//       ...commonDetailKeys
//     },
//   },
//   [chartTypes.SELECTOR_DATA_ITEM_CHART]: {
//     details: {
//       [detailsKeys.SELECTOR_PARAMETERS]: [],
//       [detailsKeys.selectorDataitemValue]: "",
//     },
//     propertiesStyle: {
//       HAS_TITLE: true
//     }
//   },
// };