import { allPropertiesKeys } from "../../commons/dashboardProperties";
import { v4 as uuidv4 } from "uuid";

export const chatbotProviders = [
  { value: "dontUseAny", label: "Do Not Use Any GPT Provider" },
  { label: "Microsoft (Azure OpenAI)", value: "microsoft" },
  { label: "Google (Vertex AI PaLM)", value: "google" },
];

export const configurationNameTypes = {
  LLM_TYPE: "llm_type",
  MAIL_SMTP_PORT: "MAIL_SMTP_PORT",
  MAIL_PASSWORD: "MAIL_PASSWORD",
  MAIL_FROM: "MAIL_FROM",
  MAIL_SMTP_AUTH: "MAIL_SMTP_AUTH",
  MAIL_SMTP_STARTTLS_ENABLE: "MAIL_SMTP_STARTTLS_ENABLE",
  MAIL_SMTP_HOST: "MAIL_SMTP_HOST",
  MAIL_USERNAME: "MAIL_USERNAME",
  IS_CACHE_CONNECTION_ACTIVE: "IS_CACHE_CONNECTION_ACTIVE",
  CACHE_MAX_MEMORY_SIZE: "CACHE_MAX_MEMORY_SIZE",
  CACHE_MAX_MEMORY_POLICY: "CACHE_MAX_MEMORY_POLICY",
  IS_CACHE_CLEANUP_JOB_ENABLED: "IS_CACHE_CLEANUP_JOB_ENABLED",
  DEFAULT_CACHE_DURATION_MIN: "DEFAULT_CACHE_DURATION_MIN"
};

export const SETTINGS_MENU_ENUM = {
  MAILSERVER: "Mail Server",
  PALETTES: "Palettes",
  TEMPLATES: "Templates",
  GPTPROVIDER: "GPT Provider",
  DATA_CACHE: "Data Cache"
};

export const settingMenuItems = [
  SETTINGS_MENU_ENUM.MAILSERVER,
  SETTINGS_MENU_ENUM.PALETTES,
  SETTINGS_MENU_ENUM.TEMPLATES,
  SETTINGS_MENU_ENUM.GPTPROVIDER,
  SETTINGS_MENU_ENUM.DATA_CACHE
];

export const defaultTemplate = () => {
  return {
    id: uuidv4(),
    name: "New Template",
    isTempData: true,
    properties: {
      style: {
        [allPropertiesKeys.BACKGROUND_COLOR]: "#ffffff",
        [allPropertiesKeys.HAS_BORDER]: true,
        [allPropertiesKeys.BORDER_COLOR]: "#f4f4f4",
        [allPropertiesKeys.HAS_DROP_SHADOW]: true,
        [allPropertiesKeys.BORDER_RADIUS]: "3",

        [allPropertiesKeys.HAS_TITLE]: true,
        [allPropertiesKeys.TITLE_FONT_FAMILY]: `Poppins, ${"sans-serif"}`,
        [allPropertiesKeys.TITLE_FONT_SIZE]: "16",
        [allPropertiesKeys.TITLE_FONT_WEIGHT]: 600,
        [allPropertiesKeys.TITLE_FONT_COLOR]: "#000000",

        [allPropertiesKeys.HAS_SUBTITLE]: true,
        [allPropertiesKeys.DESCRIPTION_FONT_FAMILY]: `Poppins, ${"sans-serif"}`,
        [allPropertiesKeys.DESCRIPTION_FONT_SIZE]: "16",
        [allPropertiesKeys.DESCRIPTION_FONT_WEIGHT]: "normal",
        [allPropertiesKeys.DESCRIPTION_FONT_COLOR]: "#000000",

        [allPropertiesKeys.HAS_FOOTNOTE]: false,
        [allPropertiesKeys.FOOTNOTE_FONT_FAMILY]: `Poppins, ${"sans-serif"}`,
        [allPropertiesKeys.FOOTNOTE_FONT_SIZE]: "16",
        [allPropertiesKeys.FOOTNOTE_FONT_WEIGHT]: "normal",
        [allPropertiesKeys.FOOTNOTE_FONT_COLOR]: "#000000",
      },
    },
    isDefault: false,
  };
};

export const defaultColorPalettes = [
  {
    id: 1,
    name: "Softtech Palette",
    paletteColors: [
      "#2f4b7c",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#ff7c43",
      "#9e99db",
      "#ffa600",
      "#003f5c",
    ],
  },
  {
    id: 2,
    name: "Heatmap Default",
    paletteColors: ["#bf444c", "#d88273", "#f6efa6"],
  },
  {
    id: 3,
    name: "ECharts Default",
    paletteColors: [
      "#5470c6",
      "#91cc75",
      "#fac858",
      "#ee6666",
      "#73c0de",
      "#3ba272",
      "#fc8452",
      "#9a60b4",
      "#ea7ccc",
    ],
  },
];

export const UPLOAD_IMAGES_SORT_MENU_ENUM = {
    NAME: "name",
    TYPE: "type",
    DESCRIPTION: "description",
    CREATE_DATE: "createDate",
    UPDATED_DATE: "updatedDate",
};

export const UPLOAD_IMAGES_SORT_LIST = [
    { id: uuidv4(), label: "generalPages.name", value: UPLOAD_IMAGES_SORT_MENU_ENUM.NAME, type: "" },
    { id: uuidv4(), label: "generalPages.labelType", value: UPLOAD_IMAGES_SORT_MENU_ENUM.TYPE, type: "" },
    { id: uuidv4(), label: "generalPages.labelDescription", value: UPLOAD_IMAGES_SORT_MENU_ENUM.DESCRIPTION, type: "" },
    { id: uuidv4(), label: "generalPages.labelCreateDate", value: UPLOAD_IMAGES_SORT_MENU_ENUM.CREATE_DATE, type: "" },
    { id: uuidv4(), label: "generalPages.labelUpdateDate", value: UPLOAD_IMAGES_SORT_MENU_ENUM.UPDATED_DATE, type: "" },
];