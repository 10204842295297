/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visAbout = {
    state: {
        [STATE.LICENSE]: [],
        [STATE.VERSION]: [],
    },
    getters: {
        [GETTER.GET_LICENSE]: (state) => {
            return state[STATE.LICENSE];
        },
        [GETTER.GET_VERSION]: (state) => {
            return state[STATE.VERSION];
        },
    },
    actions: {
        [ACTION.FETCH_LICENSE]: async ({ commit }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.About,
                    serviceMethodSub: serviceMethodSub.readLicense,
                    successMutation: MUTATION.SET_LICENSE,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true
                },
            );
        },
        [ACTION.FETCH_VERSION]: async ({ commit }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.About,
                    serviceMethodSub: serviceMethodSub.readVersion,
                    successMutation: MUTATION.SET_VERSION,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true
                },
            );
        },
    },
    mutations: {
        [MUTATION.SET_LICENSE](state, item) {
            Vue.set(state, [STATE.LICENSE], item);
        },
        [MUTATION.SET_VERSION](state, item) {
            Vue.set(state, [STATE.VERSION], item);
        },
    },
};
