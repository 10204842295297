import { filterPropKey } from "../../commons/filterComponents";
import deepEqual from 'deep-equal';
import { checkIfFieldTypeIsDateAndFormatDateFilterValue } from "./filterValueSetters";

export const getCommonChartItemStyle = (dataAxis, panelData) => {
    const chartFilterValue = getPanelDataFilterValue(panelData)
    let selectedValue, filterValueKeys, hasDrillDownFilter;

    hasDrillDownFilter = panelData?.details?.filters?.find(x => x.isDrillDown)

    if (chartFilterValue) {

        filterValueKeys = Object.keys(chartFilterValue)
        
        let filterObj = {
            filterValues: [],
            fieldItems: [],
        };

        filterValueKeys.forEach(element => {
            filterObj.filterValues.push(chartFilterValue[element].value);
            filterObj.fieldItems.push(chartFilterValue[element]?.fieldItem);
        });
        
        if (filterObj.filterValues.length == 1) {
            dataAxis = checkIfFieldTypeIsDateAndFormatDateFilterValue(filterObj.fieldItems[0], dataAxis)
            selectedValue = filterObj.filterValues.some(x => dataAxis?.split(" | ").includes(x))
        } else {
            dataAxis = dataAxis?.split(" | ");
            dataAxis?.forEach((field, index) => {
                dataAxis[index] = checkIfFieldTypeIsDateAndFormatDateFilterValue(filterObj.fieldItems[index], field)
            });
            dataAxis = dataAxis?.join(" | ");
            selectedValue = filterObj.filterValues.join(" | ") == dataAxis;
        }
    }
    return { opacity: !chartFilterValue || !filterValueKeys.length || hasDrillDownFilter ? 1 : selectedValue ? 1 : 0.4 }
};

export const setTreeMapChartItemStyle = (serie, panelData) => {
    const chartFilterValue = getPanelDataFilterValue(panelData);

    const filterFragments = Object.values(chartFilterValue ?? []).map(i => i.value);
    traverseTreemapNodesAndSetItemStyle(serie, [serie.name.toString?.()], filterFragments);
};

export const getScatterAndHeatmapCommonStyle = (dataSeries, panelData, dimensionNames) => {

    let obj = {}, filterValueParsed = {}, filterValueKeys;

    const chartFilterValue = getPanelDataFilterValue(panelData);

    if (chartFilterValue) {
        filterValueKeys = Object.keys(chartFilterValue);

        filterValueKeys.forEach(element => {
            filterValueParsed[element] = chartFilterValue[element].value
        });
    }

    dimensionNames.forEach((element, index) => {
        if (element) {
            const aggKeys = element?.split(" | ")
            const aggValues = dataSeries[index]?.split(" | ")
            aggKeys.forEach((t, i) => {
                const fieldKey = panelData?.details?.aggregation?.find(x => x.alias == t);
                if (!fieldKey) return;
                obj[fieldKey.field] = aggValues[i]
            });
        }
    });
    let isValueEqual;
    if (Object.values(filterValueParsed).length == 1) {
        isValueEqual = Object.values(obj).some((x) => Object.values(filterValueParsed).includes(x));
    } else {
        isValueEqual = deepEqual(obj, filterValueParsed)
    }


    return { opacity: !chartFilterValue || !filterValueKeys.length ? 1 : isValueEqual ? 1 : 0.1, }
}
const getCommonChartFilterValueItem = (chartFilterValue) => {
    let filterValueObj = {}

    Object.keys(chartFilterValue).forEach(key => {
        filterValueObj[key] = chartFilterValue[key].value
    });

    return filterValueObj;
}
export const getSankeyChartDataOpacity = (panelData, data) => {
    const chartFilterValue = getPanelDataFilterValue(panelData);

    if (!chartFilterValue) return;

    const newObj = {
        [data.field]: data.name
    }

    let filterValueObj = getCommonChartFilterValueItem(chartFilterValue);

    const isValueEqual = deepEqual(newObj, filterValueObj)

    return { opacity: isValueEqual ? 1 : 0.3, }
}

export const getSankeyChartLineOpacity = (panelData, l) => {
    const chartFilterValue = getPanelDataFilterValue(panelData);

    if (!chartFilterValue) return;

    let newObj = {
        [l.targetField]: l.target,
        [l.sourceField]: l.source
    }

    let filterValueObj = getCommonChartFilterValueItem(chartFilterValue);

    const isValueEqual = deepEqual(newObj, filterValueObj)

    return { opacity: isValueEqual ? 1 : 0.3, }
}

const getPanelDataFilterValue = (panelData) => {
    return panelData?.details?.[filterPropKey.FILTER_VALUE]
}

const traverseTreemapNodesAndSetItemStyle = (serie, serieNames, filterFragments, level = 1) => {
    serie.itemStyle = { opacity: 0.2 };

    if (filterFragments.every(i => serieNames.includes(i))) {
        serie.itemStyle = { opacity: 1 };
    }

    for (const element of serie.children) {
        serieNames[level] = element.name.toString?.();

        traverseTreemapNodesAndSetItemStyle(element, serieNames, filterFragments, level + 1);
    }
}