export const addNewDatasetPopupSteps = {
    NONE: "NONE",
    SELECT_TYPE: "SELECT_TYPE",
    CSV_UPLOAD: "CSV_UPLOAD",
    EXCEL_UPLOAD: "EXCEL_UPLOAD",
    SELECT_DATABASE_CONNECTION: "SELECT_DATABASE_CONNECTION",
    SQL_CREATE: "SQL_CREATE",
    SQL_EDIT: "SQL_EDIT",
    SELECT_TABLE_OR_VIEW: "SELECT_TABLE_OR_VIEW",
}

export const addNewDatasetPopupStepHeaders = {
    [addNewDatasetPopupSteps.SELECT_TYPE]: "datamodel.selectDataSourceType",
    [addNewDatasetPopupSteps.CSV_UPLOAD]: "datamodel.selectTheFileYouWantToUpload",
    [addNewDatasetPopupSteps.EXCEL_UPLOAD]: "datamodel.selectTheFileYouWantToUpload",
    [addNewDatasetPopupSteps.SELECT_DATABASE_CONNECTION]: "datamodel.selectADatabaseConnection",
    [addNewDatasetPopupSteps.SQL_CREATE]: "datamodel.writeSQL",
    [addNewDatasetPopupSteps.SELECT_TABLE_OR_VIEW]: "datamodel.Select table or view",
}