export const chartPropertiesCss = {
    FONT_FAMILY: "display: inline-block; width: 64%; white-space: nowrap;",
    FONT_SIZE: "display: inline-block; width: calc(36% - 5px); border-left: 1px solid #ccc; margin-left: 5px; padding-left: 5px;",
    FONT_COLOR: "display: inline-block; padding-left: 10px; margin-bottom: 30px;",
    FONT_WEIGHT: "display: inline-block; width: 43%; margin-top: 10px; transform: translateY(3px); margin-right: 5px",
    TITLE_ALIGN_ITEM: "display: inline-block; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204); margin-left: 7px; padding: 0 4px;",
    FONT_WEIGHT_V2: "display: inline-block; width: 85%; margin-top: 10px; transform: translateY(3px); margin-right: 5px; padding-right: 12px; border-right: 1px solid rgb(204, 204, 204);",
    FONT_SIZE_V2: "display: inline-block; width: calc(36% - 5px); border-left: 1px solid #ccc; margin-left: 5px; padding-left: 5px; margin-bottom: 25px;",
    DATA_ZOOM_START: "display: inline-block; width: 40%; margin-right: 1.5rem; margin-bottom: 1.5rem;",
    DATA_ZOOM_END: "display: inline-block; width: 40%; margin-bottom: 1.5rem;",
    TREND_COLOR: "display: inline-block;",
    TREND_ICON: "display: inline-block;"
};