<template>
  <div class="vis-settings-page">
    <div class="settings-menu">
      <div class="settings-header">
        <span class="settings-header-span">{{
          $t("admin.menuItem.labelSettings")
        }}</span>
      </div>
      <el-menu mode="vertical" :default-active="activeMenuItem">
        <el-menu-item
          v-for="(menuItem, index) in menuItems"
          :key="index"
          :index="menuItem"
          @click="selectMenuItem(menuItem)"
        >
          {{ $t(`admin.settings.${menuItem}`) }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="settings-content" v-loading="!activeMenuItem">
      <MailServer
        v-if="activeMenuItem === settingsMenuEnum.MAILSERVER"
        :selectedMailServer="selectedMailServer"
        @onMailServerValueChange="onMailServerValueChange"
      />
      <Palettes v-if="activeMenuItem === settingsMenuEnum.PALETTES" />
      <AdministrationTemplates
        v-if="activeMenuItem === settingsMenuEnum.TEMPLATES"
        :doesHaveAdminAuthority="doesHaveAdminAuthority"
      />
      <GptProvider
        v-if="activeMenuItem === settingsMenuEnum.GPTPROVIDER"
        :chatbotConfiguration="selectChatbotConfiguration"
        @onChatbotSourceChange="onChatbotSourceChange"
      />
      <DataCache
        v-if="activeMenuItem === settingsMenuEnum.DATA_CACHE"
        :selectedCacheData="selectedCacheData"
        :configurationNameTypes="configurationNameTypes"
        :isSaveLoading="isSaveConfigurationLoading"
        :isDeleteCacheAllLoading="isDeleteCacheAllLoading"
        @saveCacheConfiguration="saveCacheConfiguration"
        @handleSelectedCacheData="handleSelectedCacheData"
        @deleteCacheAll="
          deleteCacheAll({ loadingComponent: loadingComponent.DeleteCacheAll })
        "
      />
    </div>
  </div>
</template>
<script>
import MailServer from "./MailServer.vue";
import Palettes from "./Palettes.vue";
import AdministrationTemplates from "./AdministrationTemplates.vue";
import GptProvider from "./GptProvider.vue";
import DataCache from "./DataCache.vue";
import { settingMenuItems, SETTINGS_MENU_ENUM } from "./settings";
import { ACTION as ACTION_CONFIGURATIONS } from "../../store/modules/Visualize/Configurations/types";
import { ACTION as ACTION_DATA_CACHE } from "../../store/modules/Visualize/DataCache/types";
import { GETTER as GETTER_GENERAL } from "../../store/modules/Visualize/General/types";
import { mapActions, mapGetters } from "vuex";
import { configurationNameTypes } from "./settings";
import { ROLES } from "../../util/authorization";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";


export default {
  components: {
    MailServer,
    Palettes,
    AdministrationTemplates,
    GptProvider,
    DataCache
  },
  data() {
    return {
      menuItems: settingMenuItems,
      activeMenuItem: null,
      settingsMenuEnum: SETTINGS_MENU_ENUM,
      selectChatbotConfiguration: null,
      selectedMailServer: {},
      selectedCacheData: {},
      configurationNameTypes: configurationNameTypes,
      loadingComponent: LoadingComponent
    };
  },
  async mounted() {
    await this.fetchConfiguration();
    this.selectedCacheData.cacheMemoryInfo = await this.fetchCacheMemoryInfo();
    this.activeMenuItem = SETTINGS_MENU_ENUM.MAILSERVER;
  },
  computed: {
    ...mapGetters({
      userRoles: "Security/Get/Keycloak/Roles",
      loading: GETTER_GENERAL.GET_LOADING
    }),
    doesHaveAdminAuthority() {
      return this.userRoles.some((s) => [ROLES.ADMIN.key].includes(s));
    },
    isSaveConfigurationLoading() {
      return this.loading[LoadingComponent.SaveConfiguration];
    },
    isDeleteCacheAllLoading() {
      return this.loading[LoadingComponent.DeleteCacheAll];
    }
  },
  methods: {
    ...mapActions({
      fetchConfigurations: ACTION_CONFIGURATIONS.FETCH_CONFIGURATIONS,
      updateConfigurations: ACTION_CONFIGURATIONS.UPDATE_CONFIGURATION,
      updateCacheConfiguration: ACTION_DATA_CACHE.UPDATE_CACHE_CONFIGURATION,
      fetchCacheMemoryInfo: ACTION_DATA_CACHE.FETCH_MEMORY_INFO,
      deleteCacheAll: ACTION_DATA_CACHE.DELETE_CACHE_ALL
    }),
    handleSelectedCacheData({ key, value }) {
      this.selectedCacheData = {
        ...this.selectedCacheData,
        ...{ [key]: value },
      };
    },
    selectMenuItem(item) {
      this.activeMenuItem = item;
    },
    onChatbotSourceChange(selectedOption) {
      this.selectChatbotConfiguration = selectedOption;
      this.updateConfigurations({
        queryParam: configurationNameTypes.LLM_TYPE,
        bodyParam: { value: selectedOption },
      });
    },
    async onMailServerValueChange(query, value) {
      await this.updateConfigurations({
        queryParam: query,
        bodyParam: { value },
      });
      this.fetchConfiguration();
    },
    async saveCacheConfiguration({ key, value }) {
      await this.updateCacheConfiguration({
        queryParam: key,
        bodyParam: { value },
        loadingComponent: LoadingComponent.SaveConfiguration
      });
    },
    async fetchConfiguration() {
      await this.fetchConfigurations().then((response) => {
        response.forEach((element) => {
          const contextMenuObjects = {
            [configurationNameTypes.LLM_TYPE]: {
              serviceFn: (value) => (this.selectChatbotConfiguration = value),
            },
            [configurationNameTypes.MAIL_FROM]: {
              serviceFn: (value) =>
                (this.selectedMailServer.mailServerMailFrom = value),
            },
            [configurationNameTypes.MAIL_USERNAME]: {
              serviceFn: (value) =>
                (this.selectedMailServer.mailServerUsername = value),
            },
            [configurationNameTypes.MAIL_PASSWORD]: {
              serviceFn: (value) =>
                (this.selectedMailServer.mailServerPassword = value),
            },
            [configurationNameTypes.MAIL_SMTP_HOST]: {
              serviceFn: (value) =>
                (this.selectedMailServer.mailServerSMTPHost = value),
            },
            [configurationNameTypes.MAIL_SMTP_PORT]: {
              serviceFn: (value) =>
                (this.selectedMailServer.mailServerSMTPPort = value),
            },
            [configurationNameTypes.MAIL_SMTP_AUTH]: {
              serviceFn: (value) =>
                (this.selectedMailServer.mailServerSMTPAuth =
                  JSON.parse(value)),
            },
            [configurationNameTypes.IS_CACHE_CONNECTION_ACTIVE]: {
              serviceFn: (value) =>
                (this.selectedCacheData[
                  configurationNameTypes.IS_CACHE_CONNECTION_ACTIVE
                ] = JSON.parse(value)),
            },
            [configurationNameTypes.DEFAULT_CACHE_DURATION_MIN]: {
              serviceFn: (value) =>
                (this.selectedCacheData[
                  configurationNameTypes.DEFAULT_CACHE_DURATION_MIN
                ] = value),
            },
            [configurationNameTypes.CACHE_MAX_MEMORY_SIZE]: {
              serviceFn: (value) =>
                (this.selectedCacheData[
                  configurationNameTypes.CACHE_MAX_MEMORY_SIZE
                ] = value),
            },
            [configurationNameTypes.CACHE_MAX_MEMORY_POLICY]: {
              serviceFn: (value) =>
                (this.selectedCacheData[
                  configurationNameTypes.CACHE_MAX_MEMORY_POLICY
                ] = value),
            },
            [configurationNameTypes.IS_CACHE_CLEANUP_JOB_ENABLED]: {
              serviceFn: (value) =>
                (this.selectedCacheData[
                  configurationNameTypes.IS_CACHE_CLEANUP_JOB_ENABLED
                ] = JSON.parse(value)),
            },
          };

          contextMenuObjects[element?.name]?.serviceFn(element?.value);
        });
      });
    },
  },
};
</script>

<style scoped>
.vis-settings-page {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  background: var(--white);
}

.settings-menu {
  width: 200px;
}
.el-menu {
  height: 100%;
}

.settings-content {
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: 20px;
}

.settings-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding: 20px 30px 20px;
  font-size: 26px;
  font-weight: 600;
  color: #3e3e3e;
}
.settings-header-span {
  width: 108px;
  height: 36px;
}
.el-menu-item.is-active {
  background-color: #ebebeb !important;
  color: #494849;
  font-weight: 600;
}
.el-menu-item {
  height: 40px;
  line-height: 40px;
  padding-left: 40px !important;
}
</style>
