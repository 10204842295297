import { Notification } from "element-ui";
import { routerEnums } from "./Enum";
import { HOMEPAGE_SEARH_PARAMS } from "./homeDataTypes";

export const Notify = (
  message,
  type,
  title,
  dangerouslyUseHTMLString = false
) => {
  Notification({
    title,
    message,
    type,
    duration: 3000,
    customClass: "vis-notification center",
    dangerouslyUseHTMLString,
  });
};

export const checkDashboardName = (routeName) => {
  if (
    routeName == routerEnums.DASHBOARD ||
    routeName == routerEnums.DASHBOARD_EDIT ||
    routeName == routerEnums.DASHBOARD_VIEW
  ) {
    return true;
  }
  return false;
};

export const isRouteDatamodel = (routeName) => {
  if (
    routeName == routerEnums.DATAMODEL ||
    routeName == routerEnums.DATAMODEL_VIEW ||
    routeName == routerEnums.DATA
  ) {
    return true;
  }
  return false;
};

export const isRouteIngest = (routeName) => {
  if (
    routeName == routerEnums.INGEST_CREATE ||
    routeName == routerEnums.INGEST_EDIT
  ) {
    return true;
  }
  return false;
};

export const isSearchingWithDifferentKey = (val, queryData) => {
  return queryData?.find((x) => 
    (x?.key === HOMEPAGE_SEARH_PARAMS.SEARCH_KEY)).value !== val
};

export const validateEmails = (emailString) => {
  if (emailString === "") return true;

  // Define the regular expression pattern for a simple email validation
  var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  // Split the input string into individual emails
  var emails = emailString.split(";");

  // Validate each email
  for (var i = 0; i < emails.length; i++) {
    var email = emails[i].trim(); // Remove leading and trailing spaces
    if (!emailPattern.test(email)) {
      return false; // Return false if any email is not valid
    }
  }

  return true; // All emails are valid
};