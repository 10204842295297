import { ACTION } from "./types";
import { MUTATION_GENERAL } from "../General/types";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";

export const visDataCache = {
  actions: {
    [ACTION.FETCH_MEMORY_INFO]: async ({ commit }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataCache,
        serviceMethodSub: serviceMethodSub.readMemoryInfo,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: false,
      });
    },
    [ACTION.UPDATE_CACHE_CONFIGURATION]: async (
      { commit },
      { queryParam, bodyParam, loadingComponent }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataCache,
        serviceMethodSub: serviceMethodSub.updateCacheConfigurations,
        queryParam,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        loadingComponent,
      });
    },
    [ACTION.DELETE_CACHE_ALL]: async ({ commit }, { loadingComponent }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.DataCache,
        serviceMethodSub: serviceMethodSub.deleteCacheAll,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        loadingComponent
      });
    },
  },
};
