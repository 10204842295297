import { v4 as uuidv4 } from "uuid";
import i18n from "../../../i18n";
import { CustomIcon } from "../../../assets/js/custom-icons";
export const conditionalOperatorValues = {
    EQ: "=",
    NOT_EQ: "!=",
    BETWEEN: "between",
    NOT_BETWEEN: "notBetween",
    QT: ">",
    LT: "<",
    QTE: ">=",
    LTE: "<=",
    IN: "in",
    NOT_IN: "notIn"
}
export const conditionalOperatorLabels = {
    EQ: "=",
    NOT_EQ: "!=",
    BETWEEN: i18n.tc("filterComponents.between"),
    NOT_BETWEEN: i18n.tc("filterComponents.notBetween"),
    QT: ">",
    LT: "<",
    QTE: ">=",
    LTE: "<=",
    IN: "in",
    NOT_IN: "notIn"
}
export const measureOptions = () => {
    return [
        { value: conditionalOperatorValues.EQ, label: "=" },
        { value: conditionalOperatorValues.NOT_EQ, label: "!=" },
        { value: conditionalOperatorValues.BETWEEN, label: conditionalOperatorLabels.BETWEEN },
        { value: conditionalOperatorValues.NOT_BETWEEN, label: conditionalOperatorLabels.NOT_BETWEEN },
        { value: conditionalOperatorValues.QT, label: ">" },
        { value: conditionalOperatorValues.LT, label: "<" },
        { value: conditionalOperatorValues.QTE, label: ">=" },
        { value: conditionalOperatorValues.LTE, label: "<=" },
    ]
}
export const attributeOptions = () => {
    return [
        { value: conditionalOperatorValues.EQ, label: "=" },
        { value: conditionalOperatorValues.NOT_EQ, label: "!=" },
        { value: conditionalOperatorValues.IN, label: i18n.tc('operatorLocale.in') },
        { value: conditionalOperatorValues.NOT_IN, label: i18n.tc('operatorLocale.notIn') },
    ]
}

export const dateOptions = () => {
    return [
        { value: conditionalOperatorValues.EQ, label: "=" },
        { value: conditionalOperatorValues.NOT_EQ, label: "!=" },
        { value: conditionalOperatorValues.BETWEEN, label: conditionalOperatorLabels.BETWEEN },
        { value: conditionalOperatorValues.NOT_BETWEEN, label: conditionalOperatorLabels.NOT_BETWEEN },
    ]
}

export const fontSizes = [
    { label: "10", value: 10 },
    { label: "12", value: 12 },
    { label: "14", value: 14 },
    { label: "16", value: 16 },
    { label: "18", value: 18 },
    { label: "20", value: 20 },
    { label: "24", value: 24 },
    { label: "30", value: 30 },
]
export const defaultIconFormatData = () => {
    return {
        iconName: CustomIcon.CondCircle,
        iconSize: 14,
        iconColor: "",
        iconShowOption: iconFormatShowType.ICON_ONLY,
        iconPosition: "right",
        customText: ""
    }
}
export const defaultConditionData = () => {
    return {
        id: uuidv4(),
        format: {
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 500,
            fontColor: "",
            chipColor: "",
            backgroundColor: "",
        },
        iconFormat: defaultIconFormatData(),
        rules: [
            {
                id: uuidv4(),
                dataItem: "",
                operator: "",
                value1: null,
                value2: null,
            },
        ],
    }
}

export const defaultConditionalFormattingData = (fieldId, slotFieldId) => {
    return {
        fieldId: fieldId,
        slotFieldId: slotFieldId,
        formatType: conditionFormatType.FORMAT_VALUE,
        conditions: [defaultConditionData()],
    }
}

export const conditionFormatType = {
    FORMAT_VALUE: "FORMAT_VALUE",
    FORMAT_ICON: "FORMAT_ICON",
}
export const iconFormatShowType = {
    ICON_ONLY: "ICON_ONLY",
    ICON_VALUE_ONLY: "ICON_VALUE_ONLY"
}
export const iconFormatPositionValue = {
    LEFT: "left",
    RIGHT: "right"
}
export const conditionFormatTypes = [
    { value: conditionFormatType.FORMAT_VALUE, label: "conditionalFormatting.Format Value" },
    { value: conditionFormatType.FORMAT_ICON, label: "conditionalFormatting.Format Icon" },
]

export const iconList = [
    //Arrow Start
    { value: CustomIcon.CondArrowDecreasing, label: "conditionalFormatting.Arrow Decreasing" },
    { value: CustomIcon.CondArrowDown, label: "conditionalFormatting.Down Arrow" },
    { value: CustomIcon.CondArrowIncreasing, label: "conditionalFormatting.Arrow Increasing" },
    { value: CustomIcon.CondArrowUp, label: "conditionalFormatting.Up Arrow" },
    { value: CustomIcon.ArrowRight, label: "conditionalFormatting.Right Arrow" },
    //Arrow END
    { value: CustomIcon.Minus, label: "conditionalFormatting.Minus" },
    //BARS START
    { value: CustomIcon.CondBars1, label: "conditionalFormatting.Bars 1" },
    { value: CustomIcon.CondBars2, label: "conditionalFormatting.Bars 2" },
    { value: CustomIcon.CondBars3, label: "conditionalFormatting.Bars 3" },
    { value: CustomIcon.CondBars4, label: "conditionalFormatting.Bars 4" },
    //BARS END
    //CANCEL START
    { value: CustomIcon.CondCancel, label: "conditionalFormatting.Cancel" },
    { value: CustomIcon.CondCancelCircled, label: "conditionalFormatting.Cancel Circled" },
    //CANCEL END
    //CHECK START
    { value: CustomIcon.CondCheck, label: "conditionalFormatting.Check" },
    { value: CustomIcon.CondCheckCircled, label: "conditionalFormatting.Check Circled" },
    //CHECK END
    //CHEVRON START
    { value: CustomIcon.CondChevronDown, label: "conditionalFormatting.Chevron Down" },
    { value: CustomIcon.CondChevronLeft, label: "conditionalFormatting.Chevron Left" },
    { value: CustomIcon.CondChevronUp, label: "conditionalFormatting.Chevron Up" },
    //CHECRON END

    { value: CustomIcon.CondCircle, label: "conditionalFormatting.Circle" },
    { value: CustomIcon.CondDiamond, label: "conditionalFormatting.Diamond" },

    //EXCLAMATION START
    { value: CustomIcon.CondExclamation, label: "conditionalFormatting.Exclamation" },
    { value: CustomIcon.CondExclamationCicled, label: "conditionalFormatting.Exclamation Circled" },
     //EXCLAMATION END
     { value: CustomIcon.CondFlag, label: "conditionalFormatting.Flag" },
     //GRID START
    { value: CustomIcon.CondGrid1, label: "conditionalFormatting.Grid 1" },
    { value: CustomIcon.CondGrid2, label: "conditionalFormatting.Grid 2" },
    { value: CustomIcon.CondGrid3, label: "conditionalFormatting.Grid 3" },
    { value: CustomIcon.CondGrid4, label: "conditionalFormatting.Grid 4" },
     //GRID END

     //PIE START
     { value: CustomIcon.CondPie1, label: "conditionalFormatting.Pie 1" },
     { value: CustomIcon.CondPie2, label: "conditionalFormatting.Pie 2" },
     { value: CustomIcon.CondPie3, label: "conditionalFormatting.Pie 3" },
     //PIE END
    { value: CustomIcon.CondSquare, label: "conditionalFormatting.Square" },

     //TRIANGLE START
     { value: CustomIcon.CondTriangle, label: "conditionalFormatting.Triangle" },
     { value: CustomIcon.CondTriangleDown, label: "conditionalFormatting.Triangle Down" },
     { value: CustomIcon.CondTriangleUp, label: "conditionalFormatting.Triangle Up" },
     //TRIANGLE END

     // COUNT START
     { value: CustomIcon.CondCountOne, label: "conditionalFormatting.Counter One" },
     { value: CustomIcon.CondCountTwo, label: "conditionalFormatting.Counter Two" },
     { value: CustomIcon.CondCountThree, label: "conditionalFormatting.Counter Three" },
     { value: CustomIcon.CondCountFour, label: "conditionalFormatting.Counter Four" },
     { value: CustomIcon.CondCountFive, label: "conditionalFormatting.Counter Five" },
     { value: CustomIcon.CondCountSix, label: "conditionalFormatting.Counter Six" },
     { value: CustomIcon.CondCountSeven, label: "conditionalFormatting.Counter Seven" },
     { value: CustomIcon.CondCountEight, label: "conditionalFormatting.Counter Eight" },
     { value: CustomIcon.CondCountNine, label: "conditionalFormatting.Counter Nine" },
     // COUNT END
]
export const iconShowOptions = [
    { value: iconFormatShowType.ICON_ONLY, label: "conditionalFormatting.Show Icon" },
    { value: iconFormatShowType.ICON_VALUE_ONLY, label: "conditionalFormatting.Show Icon And Value" },
]

export const iconPositionOptions = [
    { value: iconFormatPositionValue.LEFT, label: "generalPages.left" },
    { value: iconFormatPositionValue.RIGHT, label: "generalPages.right" },
]