import { defaultColorPalette } from "../commons/chartOptions"
import { getItemValue } from "../commons/dashboardProperties"

export const SANKEY_CHART_DEFAULT_CONFIGURATIONS = {
    SANKEY_CHART_SHOW_VALUE_LABEL: true,
    SANKEY_CHART_SHOW_ONLY: getItemValue(),
    SANKEY_CHART_SHOW_INSIGHT: true,
    SANKEY_CHART_SHOW_SQL: false,
    SANKEY_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    SANKEY_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
    SANKEY_CHART_VALUE_LABEL_FONT_SIZE: 12,
    SANKEY_CHART_VALUE_LABEL_FONT_COLOR: "#000",
    SANKEY_CHART_HIDE_OVERLAPPED_VALUE_LABELS: true,
    SANKEY_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
    SANKEY_CHART_NODE_ALIGN: "Left",
    SANKEY_CHART_AUTO_REFRESH: 0,
    SANKEY_CHART_AUTO_REFRESH_CUSTOM: null,
    SANKEY_CHART_DYNAMIC_TITLE_VALUE: "",
    SANKEY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    SANKEY_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    SANKEY_CHART_GROUP_MULTIPLE_VALUES: true,
    SANKEY_CHART_ALLOW_DRILL_THROUGH: true,
    SANKEY_CHART_DRILL_THROUGH_DASHBOARD_LIST: [],
    SANKEY_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK: false
}