<template>
  <div class="properties-db-general-container">
    <div class="vis-mb--1">
      <label>{{ $t("generalPages.labelName") }}</label>
      <el-input v-model="generalForm.name"></el-input>
    </div>

    <div v-if="isGeneralFormChanged" class="vis-mb--1 update-button-container">
      <el-button
        @click="updateDatamodelName"
        size="small"
        class="update-button"
        >{{ $t("generalPages.update") }}</el-button
      >
    </div>
    <!-- START SWITCH OPTIONS -->
      <div
        v-for="(option, index) in switchOptions"
        :key="option.key"
        class="vis-flex--spacebeetwen-Ycenter"
        :class="{ 'vis-mb--1': index !== switchOptions.length - 1 }"
      >
        <label>{{ option.label }}</label>
        <el-switch
          :value="option.value"
          :disabled="isDataModelLocked"
          @change="(value) => option.serviceFn({
            value, 
            key: option.key 
          })"
        >
        </el-switch>
      </div>
    <!-- END SWITCH OPTIONS -->
    <CheckableInput
      inputType="text"
      :value="queryTimeoutValue"
      :disabled="!isQueryTimeoutActive"
      @handleValue="(value) => updateQueryTimeout(value)"
    />
    <div v-for="item in cacheOptions" :key="item.key">
      <div
        v-if="item.componentType === componentTypes.SWITCH"
        :class="item.parentClass"
      >
        <el-tooltip
          :content="item.tooltipContent"
          placement="top-start"
          effect="light"
          :disabled="!item.tooltipContent"
        >
          <label>{{ item.label }}</label>
        </el-tooltip>
        <el-switch
          :value="item.value"
          :disabled="item.disabled"
          @change="(value) => item.serviceFn(value)"
        >
        </el-switch>
      </div>
      <div
        v-if="item.componentType === componentTypes.INPUT_WITH_ENTER"
        :class="item.parentClass"
      >
        <el-tooltip
          :content="item.tooltipContent"
          placement="top-start"
          effect="light"
          :disabled="!item.tooltipContent"
        >
          <label>{{ item.label }}</label>
        </el-tooltip>
        <input
          type="text"
          class="vis-input"
          :value="item.value"
          :disabled="item.disabled"
          @keypress.enter="(e) => item.serviceFn(e.target.value)"
        />
      </div>
      <div
        v-if="item.componentType === componentTypes.INPUT_NUMBER"
        :class="item.parentClass"
      >
        <el-tooltip
          :content="item.tooltipContent"
          placement="top-start"
          effect="light"
          :disabled="!item.tooltipContent"
        >
          <label>{{ item.label }}</label>
        </el-tooltip>
        <input
          type="number"
          :min="item.min"
          :max="item.max"
          class="vis-input"
          :value="item.value"
          :disabled="item.disabled"
          @keypress.enter="(e) => item.serviceFn(e.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { COMPONENT_TYPES } from "./../../../commons/Enum";
import {
  tableTypeOptions,
  datamodelPropertyKeys,
  getCommonDatamodelPropertyValueByKey,
} from "../../../commons/dataModelTypes";
import CheckableInput from "../../../components/helper/CheckableInput.vue";

export default {
  props: {
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
    isDataModelLocked: {
      type: Boolean,
      default: false,
    },
    adminCacheConfigurationInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    CheckableInput
  },
  watch: {
    selectedDataModel: {
      handler(val) {
        if (val) {
          this.setGeneralForm();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      componentTypes: COMPONENT_TYPES,
      tableTypeOptions: tableTypeOptions,
      generalForm: {
        name: "",
      },
      datamodelPropertyKeys: datamodelPropertyKeys,
    };
  },
  async mounted() {
    this.setGeneralForm();
  },
  computed: {
    isGeneralFormChanged() {
      if (this.generalForm.name !== this.name) return true;
      return false;
    },
    name() {
      return this.selectedDataModel?.name ?? "";
    },
    queryTimeoutValue() {
      return this.selectedDataModel?.queryTimeout ?? "";
    },
    isQueryTimeoutActive() {
      return this.selectedDataModel?.queryTimeoutActive ?? false;
    },
    switchOptions() {
      return [
        {
          label: this.$t("Allow AI Actions"),
          key: datamodelPropertyKeys.ALLOW_AI_ACTIONS,
          value: this.getPropertiesValueByKey(datamodelPropertyKeys.ALLOW_AI_ACTIONS),
          serviceFn: (params) => this.setDatamodelPropertiesValue(params),
        },
        {
          label: this.$t("generalPages.Keep Only One Dataset Expanded"),
          key: datamodelPropertyKeys.KEEP_ONE_DATASET_EXPANDED,
          value: this.getPropertiesValueByKey(datamodelPropertyKeys.KEEP_ONE_DATASET_EXPANDED),
          serviceFn: (params) => this.setDatamodelPropertiesValue(params),
        },
        {
          label: this.$t("generalPages.Query Timeout"),
          key: datamodelPropertyKeys.IS_TIMEOUT_ACTIVE,
          value: this.isQueryTimeoutActive,
          serviceFn: (params) => this.updateQueryStatus(params),
        },
      ];
    },
    cacheOptions() {
      return [
        {
          label: this.$t("generalPages.Use Cache"),
          key: datamodelPropertyKeys.DATAMODEL_USE_CACHE,
          value: !this.adminCacheConfigurationInfo.cacheConnectionActive
            ? this.adminCacheConfigurationInfo.cacheConnectionActive
            : this.getPropertiesValueByKey(
                datamodelPropertyKeys.DATAMODEL_USE_CACHE
              ),
          disabled:
            this.isDataModelLocked ||
            !this.adminCacheConfigurationInfo.cacheConnectionActive,
          serviceFn: (value) =>
            this.setDatamodelPropertiesValue({
              value,
              key: datamodelPropertyKeys.DATAMODEL_USE_CACHE,
            }),
          componentType: this.componentTypes.SWITCH,
          parentClass: "vis-flex--spacebeetwen-Ycenter vis-mb--1",
          tooltipContent: "Use Cache"
        },
        {
          label: this.$t("generalPages.Use Default Cache Duration"),
          key: datamodelPropertyKeys.DATAMODEL_USE_DEFAULT_CACHE_DURATION,
          value: this.getPropertiesValueByKey(
            datamodelPropertyKeys.DATAMODEL_USE_DEFAULT_CACHE_DURATION
          ),
          disabled:
            this.isDataModelLocked ||
            !this.getPropertiesValueByKey(
              datamodelPropertyKeys.DATAMODEL_USE_CACHE
            ) ||
            !this.adminCacheConfigurationInfo.cacheConnectionActive,
          serviceFn: (value) =>
            this.setDatamodelPropertiesValue({
              value,
              key: datamodelPropertyKeys.DATAMODEL_USE_DEFAULT_CACHE_DURATION,
            }),
          componentType: this.componentTypes.SWITCH,
          parentClass: "vis-flex--spacebeetwen-Ycenter vis-mb--1",
          tooltipContent: "Use Default Cache Duration"
        },
        {
          label: this.$t("generalPages.Cache Duration"),
          key: datamodelPropertyKeys.DATAMODEL_CACHE_DURATION,
          value: this.getPropertiesValueByKey(
            datamodelPropertyKeys.DATAMODEL_USE_DEFAULT_CACHE_DURATION
          )
            ? this.adminCacheConfigurationInfo.defaultCacheDurationMin
            : this.getPropertiesValueByKey(
                datamodelPropertyKeys.DATAMODEL_CACHE_DURATION
              ),
          disabled:
            this.isDataModelLocked ||
            this.getPropertiesValueByKey(
              datamodelPropertyKeys.DATAMODEL_USE_DEFAULT_CACHE_DURATION
            ) ||
            !this.getPropertiesValueByKey(
              datamodelPropertyKeys.DATAMODEL_USE_CACHE
            ) ||
            !this.adminCacheConfigurationInfo.cacheConnectionActive,
          serviceFn: (value) =>
            this.setDatamodelPropertiesValue({
              value,
              key: datamodelPropertyKeys.DATAMODEL_CACHE_DURATION,
            }),
          componentType: this.componentTypes.INPUT_NUMBER,
          min: 2,
          max: 120,
          inputType: "number",
          parentClass: "vis-mb--1",
          tooltipContent: "Cache Duration"
        },
        {
          label: this.$t("generalPages.Force Cache Duration"),
          key: datamodelPropertyKeys.DATAMODEL_FORCE_CACHE_DURATION,
          value: this.getPropertiesValueByKey(
            datamodelPropertyKeys.DATAMODEL_FORCE_CACHE_DURATION
          ),
          disabled:
            this.isDataModelLocked ||
            !this.adminCacheConfigurationInfo.cacheConnectionActive ||
            !this.getPropertiesValueByKey(
              datamodelPropertyKeys.DATAMODEL_USE_CACHE
            ),
          serviceFn: (value) =>
            this.setDatamodelPropertiesValue({
              value,
              key: datamodelPropertyKeys.DATAMODEL_FORCE_CACHE_DURATION,
            }),
          componentType: this.componentTypes.SWITCH,
          parentClass: "vis-flex--spacebeetwen-Ycenter vis-mb--1",
          tooltipContent: "Force Cache Duration"
        },
      ];
    },
  },
  methods: {
    getPropertiesValueByKey(propertyKey) {
      return getCommonDatamodelPropertyValueByKey(
        this.selectedDataModel?.properties,
        propertyKey
      );
    },
    setDatamodelPropertiesValue(params) {
      this.$emit("setDatamodelPropertiesValue", params);
    },
    setGeneralForm() {
      this.generalForm = {
        name: this.name,
      };
    },
    updateDatamodelName() {
      const newName = this.generalForm.name;
      this.$emit("setDatamodelName", newName);
    },
    updateQueryTimeout(value) {
      this.$emit("setQueryTimeout", value);
    },
    updateQueryStatus(params) {
      this.$emit("setQueryStatus", params.value);
    }
  },
};
</script>

<style scoped>
.properties-db-general-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 25px;
}
.update-button {
  background-color: rgb(32, 111, 255) !important;
  color: white !important;
  float: right;
}
.update-button-container {
  width: 100% !important;
  display: inline-block;
}
</style>
