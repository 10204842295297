<template>
  <div
    v-bind:style="{
      left: obj.left + 'px',
      top: obj.top + 'px',
    }"
    class="flowchart-object flowchart-action ingest-database-node-container ingest-flowchart-node"
    data-jtk-target="true"
  >
    <div
      class="ingest-database-node"
      :class="{ 'ingest-database-node-selected': obj.nodeSelected }"
    >
      <div class="ingest-database-node-header">
        <div
          class="ingest-database-node-header-actions vis-flex--Ycenter"
          style="margin-left: auto"
        >
          <el-dropdown
            placement="bottom-start"
            size="mini"
            @command="handleCommand"
          >
            <span class="el-dropdown-link"
              ><i
                :class="`${customIcon.VerticalThreeDot} ingest-dataset-node-actions`"
                aria-hidden="true"
              >
              </i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="actionCommands.Delete">{{
                $t("datamodel.deleteDataset")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="ingest-database-node-header-content">
          <span
            :class="`${customIcon.Database} ingest-database-node-type-database ingest-database-node-type-icon`"
            aria-hidden="true"
          ></span>
          <div class="ingest-database-node-name-and-type">
            <span
              class="ingest-database-node-name"
              :class="
                obj.name.length === 0 ? 'ingest-database-node-name-empty' : ''
              "
              :title="obj.name"
              >{{ obj.name.length === 0 ? $t("ingest.Not selected") : obj.name }}</span
            ><span class="ingest-database-node-type" :title="$t('ingest.Database')">{{
              $t("ingest.Database")
            }}</span>
          </div>
        </div>
      </div>
      <i
        v-if="obj.name.length !== 0"
        :class="`${customIcon.CircleOutline} ingest-database-node-container-add-join-action ingest-add-connection-indicator-start`"
        aria-hidden="true"
        data-jtk-source="true"
        data-jtk-port-type="source"
      ></i>
    </div>
  </div>
</template>

<script>
import JsplumbVue2BaseEditableNode from "./JsplumbVue2BaseEditableNode.vue";
import { CustomIcon } from "../../../../assets/js/custom-icons";

export default {
  mixins: [JsplumbVue2BaseEditableNode],
  data() {
    return {
      customIcon: CustomIcon,
      actionCommands: {
        Delete: "delete",
      },
    };
  },
  methods: {
    handleCommand(command) {
      if (command === this.actionCommands.Delete) {
        this.onDeleteClicked();
      }
    },
    onEditClicked() {
      this.$parent.$parent.onEditClicked(this.obj.id);
    },
    onDeleteClicked() {
      this.$parent.$parent.onDeleteClicked(this.obj.id);
    },
    onShowPreview() {
      this.$parent.$parent.onShowPreview(this.obj.id);
    },
  },
};
</script>

<style>
.ingest-add-connection-indicator-start {
  color: black;
  font-size: 1.25rem;
  line-height: 20px;
  position: absolute;
  bottom: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.ingest-database-node-container:hover
  .ingest-database-node-container-add-join-action {
  display: block;
}
.ingest-database-node-container-add-join-action {
  font-size: 1.25rem;
  cursor: pointer;
  color: black !important;
  display: none;
}
.ingest-database-node-container {
  width: 200px;
  height: 92px;
  padding: 8px;
}
.ingest-database-node {
  padding: 0px;
  position: absolute;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  cursor: move;
  color: white;
  font-size: 16px;
  width: 200px;
  height: 92px;
  display: flex;
  flex-direction: column;
}
.ingest-database-node-selected {
  border: 3px solid #3d62f8 !important;
  box-sizing: border-box;
}
.ingest-dataset-node-actions {
  font-size: 18px;
  font-weight: 400;
  color: #3d62f8 !important;
  cursor: pointer;
  display: none;
}
.ingest-database-node:hover .ingest-dataset-node-actions {
  display: block;
}
.ingest-database-node-header {
  height: 90px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  background: #e8e8e8;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px;
}
.ingest-database-node-body {
  height: 30px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  column-gap: 8px;
}
.ingest-database-node:hover .ingest-database-node-actions {
  display: block;
}
.ingest-database-node:hover .ingest-database-node-add-join-action {
  display: block;
}
.ingest-database-node-footer-bottom-text {
  font: normal normal 500 12px/18px Poppins;
  letter-spacing: 0px;
  color: black;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ingest-database-node-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #181818;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ingest-database-node-type {
  font-size: 11px;
  line-height: 18px;
  color: #5e5d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ingest-database-node-type::first-letter {
  text-transform: uppercase;
}

.ingest-database-node-status {
  font-size: 8px;
  line-height: 10px;
  color: #5e5d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ingest-database-node-type-icon {
  font-size: 35px !important;
}
.ingest-database-node-type-database {
  color: #e8a309 !important;
}
.ingest-database-node-actions {
  font-size: 18px;
  font-weight: 400;
  color: #3d62f8 !important;
  cursor: pointer;
  display: none;
}
.ingest-database-node-add-join-action {
  font-size: 20px;
  cursor: pointer;
  color: #3d62f8 !important;
  display: none;
}
.ingest-database-node-header-actions {
  gap: 10px;
  position: absolute;
  justify-content: end;
  width: 100%;
  right: 10px;
}
.ingest-database-node-header-content {
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 10px;
}
.ingest-database-node-name-and-type {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 40px);
}
.ingest-database-node-name-empty {
  color: #ff0f0f;
}
</style>
