<template>
  <el-select
    :size="selectBoxSize"
    :value="value"
    :disabled="disabled"
    :placeholder="placeholder"
    :style="{ width: width }"
    @change="$emit('handleChange', $event)"
  >
    <template v-slot:prefix><i :class="value"></i></template>
    <el-option
      v-for="option in options"
      :key="option.value"
      :label="$t(option.label)"
      :value="option.value"
    >
      <i class="vis-icon-format-el-select-value" :class="option.value"></i>
      <i
        v-if="value === option.value"
        class="el-icon-check vis-icon-format-el-select-check"
      ></i>
    </el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    selectBoxSize: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
::v-deep .el-input__prefix {
  top: 5px !important;
}
.vis-icon-format-el-select-check {
  float: right;
  transform: translateY(8px);
  font-weight: 600;
}
.vis-icon-format-el-select-value {
  float: left;
  color: #606266;
  transform: translateY(5px);
}
</style>
