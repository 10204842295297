<template>
  <div class="vis-tools-box vis-right-border vis-page vis-bg-color-white">
    <el-menu class="el-menu-vertical-demo">
      <el-menu-item
        v-for="menuItem in menuItems"
        :key="menuItem.key"
        :index="menuItem.key"
        :disabled="menuItem.disabled"
        @click="$emit(menuItem.action)"
        :class="{
          'is-active-menu-item': menuItem.key === activeMenuItem,
        }"
      >
        <i
          :class="`${menuItem.icon} vis-cursor-pointer vis-ml--1 vis-mr--05 menu-icon`"
          aria-hidden="true"
        ></i>
        <span slot="title">{{ $t(`${menuItem.label}`) }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { COMPONENT_PRIVILEGES } from "../../util/authorization";
import componentAvailabilityCheck from "../../mixins/componentAvailabilityCheck";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  mixins: [componentAvailabilityCheck],
  props: {
    activeMenuItem: {
      type: String,
    },
    menuItemTypes: {
      type: Object,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
    };
  },
  computed: {
    menuItems() {
      const access = this.isAllLeftPanelAccessible;
      return [
        this.isSystemAvailable
          ? {
              key: this.menuItemTypes.system,
              label: "admin.menuItem.labelSystem",
              disabled: true,
              action: null,
              icon: this.customIcon.VisibilityEyeMaterial,
              access,
            }
          : null,
        {
          key: this.menuItemTypes.connection,
          label: "admin.menuItem.labelConnections",
          disabled: false,
          action: "connectionClicked",
          icon: this.customIcon.DatabaseMaterial,
          access:
            this.isAllLeftPanelAccessible || this.isConnectionSectionAccessible,
        },
        {
          key: this.menuItemTypes.manageUsers,
          label: "admin.menuItem.labelManageUsers",
          disabled: false,
          action: "manageUsersClicked",
          icon: this.customIcon.Group,
          access,
        },
        this.isViewLogsAvailable
          ? {
              key: this.menuItemTypes.viewLogs,
              label: "admin.menuItem.labelViewLogs",
              disabled: true,
              action: null,
              icon: this.customIcon.Preview,
              access,
            }
          : null,
        {
          key: this.menuItemTypes.environments,
          label: "admin.menuItem.labelEnvironments",
          disabled: false,
          action: "environmentsClicked",
          icon: this.customIcon.Storage,
          access,
        },
        {
          key: this.menuItemTypes.migrateContent,
          label: "admin.menuItem.labelMigrateContent",
          disabled: false,
          action: "migrateContentClicked",
          icon: this.customIcon.SwapHoriz,
          access,
        },
        {
          key: this.menuItemTypes.schedules,
          label: "admin.menuItem.Schedules",
          disabled: false,
          action: "schedulesClicked",
          icon: this.customIcon.Clock_Time,
          access,
        },
        {
          key: this.menuItemTypes.uploadImages,
          label: "admin.menuItem.Upload Images",
          disabled: false,
          action: "uploadImagesClicked",
          icon: this.customIcon.DownloadUp,
          access,
        },
        {
          key: this.menuItemTypes.settings,
          label: "admin.menuItem.labelSettings",
          disabled: false,
          action: "settingsClicked",
          icon: this.customIcon.Settings,
          access,
        },
        {
          key: this.menuItemTypes.license,
          label: "admin.menuItem.License",
          disabled: false,
          action: "licenseClicked",
          icon: this.customIcon.DocumentOutline,
          access,
        },
      ].filter((x) => x && x.access);
    },
    isSystemAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_SYSTEM_SECTION
      );
    },
    isManageUsersAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_MANAGE_USERS_SECTION
      );
    },
    isViewLogsAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_VIEW_LOGS_SECTION
      );
    },
    isAllLeftPanelAccessible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_ALL_LEFT_PANEL
      );
    },
    isConnectionSectionAccessible() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.ADMINISTRATION_PAGE_CONNECTION_SECTION
      );
    },
  },
};
</script>
<style scoped>
::v-deep .el-menu {
  border-right: none !important;
}
::v-deep .el-menu-item {
  color: #494849 !important;
  height: 40px !important;
  line-height: 40px !important;
  font-weight: 500;
}
.is-active-menu-item {
  outline: 0 !important;
  background-color: #3d62f8 !important;
}
.is-active-menu-item span,
.is-active-menu-item i
 {
  color: #ffffff !important;
}
.menu-icon {
  color: #494849 !important;
}
.vis-page {
  overflow-wrap: break-word;
}
</style>
