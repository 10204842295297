import {
  predefineColorsArr,
  fontsArr,
  fontWeightsArr,
  fontSizesArr,
  pageSizesArr,
  cardPropertiesEnum,
  dashboardGridPropertiesEnum,
  commonCardPropertiesEnum,
  tabsPropertiesEnum,
  pageSizePropertiesEnum,
  datatablePropertiesEnum,
  PIE_CHART_PROPERTIES_ENUM,
  COLUMN_CHART_PROPERTIES_ENUM,
  BAR_CHART_PROPERTIES_ENUM,
  LINE_CHART_PROPERTIES_ENUM,
  SUMMARY_CHART_PROPERTIES_ENUM,
  TEXT_EDITOR_PROPERTIES_ENUM,
  SELECT_LIST_FILTER_PROPERTIES_ENUM,
  AREA_CHART_PROPERTIES_ENUM,
  TREEMAP_PROPERTIES_ENUM,
  DATE_FILTER_PROPERTIES_ENUM,
  DROPDOWN_FILTER_PROPERTIES_ENUM,
  HEATMAP_PROPERTIES_ENUM,
  SCATTER_CHART_PROPERTIES_ENUM,
  BUBBLE_CHART_PROPERTIES_ENUM,
  COMBINATION_CHART_PROPERTIES_ENUM,
  GAUGE_CHART_PROPERTIES_ENUM,
  PIVOT_TABLE_PROPERTIES_ENUM,
  RADAR_CHART_PROPERTIES_ENUM,
  STACKED_COLUMN_CHART_PROPERTIES_ENUM,
  STACKED_BAR_CHART_PROPERTIES_ENUM,
  GEO_MAP_CHART_PROPERTIES_ENUM,
  SANKEY_CHART_PROPERTIES_ENUM,
  autoRefreshTimeValue,
  BUTTON_PROPERTIES_ENUM,
  LABEL_CHART_PROPERTIES_ENUM,
  TEXT_FILTER_PROPERTIES_ENUM,
  pageSizeValues,
  buttonSelectionValues,
  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM,
  chartPropertiesEnum
} from "../commons/dashboardProperties";
import _ from "lodash";
import { routerEnums, COMPONENT_TYPES } from "../commons/Enum";
import { chartTypes, dashboardFilterTypes, otherChartType, BOX_KEY_ENUM, onPropertiesEventOptions  } from "../commons/dashboardAndPanel";
import dashboardGridProperties from "./dashboard-properties/dashboardGridProperties"
import datatableProperties from "./chart-properties/datatableProperties.js";
import datatableColumnProperties from "./chart-properties/datatableColumnProperties.js";
import pieChartProperties from "./chart-properties/pieChartProperties.js";
import columnChartProperties from "./chart-properties/columnChartProperties.js";
import lineChartProperties from "./chart-properties/lineChartProperties.js";
import areaChartProperties from "./chart-properties/areaChartProperties.js";
import treemapProperties from "./chart-properties/treemapProperties.js";
import summaryChartProperties from "./chart-properties/summaryChartProperties.js";
import selectListFilterProperties from "./chart-properties/selectListFilterProperties";
import dateFilterProperties from "./chart-properties/dateFilterProperties";
import barChartProperties from "./chart-properties/barChartProperties";
import dropdownFilterProperties from "./chart-properties/dropdownFilterProperties";
import heatmapProperties from "./chart-properties/heatmapProperties";
import scatterChartProperties from "./chart-properties/scatterChartProperties";
import bubbleChartProperties from "./chart-properties/bubbleChartProperties";
import combinationChartProperties from "./chart-properties/combinationChartProperties";
import gaugeChartProperties from "./chart-properties/gaugeChartProperties";
import pivotTableProperties from "./chart-properties/pivotTableProperties";
import radarChartProperties from "./chart-properties/radarChartProperties.js";
import stackedColumnChartProperties from "./chart-properties/stackedColumnChartProperties.js";
import stackedBarChartProperties from "./chart-properties/stackedBarChartProperties.js";
import geoMapProperties from "./chart-properties/geoMapProperties";
import buttonProperties from "./other-properties/buttonProperties";
import labelChartProperties from "./chart-properties/labelChartProperties";
import textFilterProperties from "./chart-properties/textFilterProperties";
import { DatamodelContextDefaults, datamodelPropertyKeys, getCommonDatamodelPropertyValueByKey } from "../commons/dataModelTypes.js";
import pivotTableColumnProperties from "./chart-properties/pivotTableColumnProperties.js";
import { chartPropertiesCss } from "./chart-properties/chartPropertiesCssEnum.js";
import textEditorProperties from "./other-properties/textEditorProperties";
import sankeyChartProperties from "./chart-properties/sankeyChartProperties.js"
import selectorDataitemChartProperties from "./chart-properties/selectorDataitemChartProperties.js";
import { DASHBOARD_GRID_DEFAULT_CONFIGURATIONS } from "../mocks/dashboardGridDefaultProperties.js";
export default {
  mixins: [
    dashboardGridProperties,
    pivotTableProperties,
    pivotTableColumnProperties,
    datatableProperties,
    datatableColumnProperties,
    pieChartProperties,
    columnChartProperties,
    lineChartProperties,
    areaChartProperties,
    summaryChartProperties,
    selectListFilterProperties,
    barChartProperties,
    treemapProperties,
    dateFilterProperties,
    dropdownFilterProperties,
    heatmapProperties,
    scatterChartProperties,
    bubbleChartProperties,
    combinationChartProperties,
    gaugeChartProperties,
    radarChartProperties,
    stackedColumnChartProperties,
    stackedBarChartProperties,
    geoMapProperties,
    buttonProperties,
    labelChartProperties,
    textFilterProperties,
    textEditorProperties,
    sankeyChartProperties,
    selectorDataitemChartProperties
  ],

  props: {
    componentStyle: {
      type: Object,
    },
    //mixinde kullanılıyor
    selectedColumnBasedFormattingsProperties: {
      type: Object,
    },
    activeComponentType: {
      type: String,
    },
    selectedTemplateId: {
      type: String,
    },
    selectedColumnField: {
      type:Object
    },
    cardTemplates: {
      type: Array,
      default: () => {
        return [];
      }
    },
    dashboardTabsOptionList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    chartTabIndex: {
      type: Number,
      default: 0
    },
    datamodelId: {
      type: String,
      default: null,
    },
    datamodelProperty: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selectedDashboardTabStyle: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isConfigurationUseCacheActive: {
      type: Boolean,
      default: false
    },
    configurationDefaultCacheDurationInMinute: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      predefineColors: predefineColorsArr,
      fonts: fontsArr,
      fontWeights: fontWeightsArr,
      fontSizes: fontSizesArr,
      pageSizes: pageSizesArr,
      propTitle: this.$t("generalPages.general"),
      collapse: {
        GENERAL: this.$t("generalPages.general"),
        DATA_ZOOM: this.$t("generalPages.Data Zoom"),
        COMMON_CARD: this.$t("generalPages.commonCard"),
        TABS: this.$t("generalPages.tabs"),
        PAGESIZE: this.$t("generalPages.pageSize"),
        CACHE_OPTIONS: "CACHE_OPTIONS",
        CARD: this.$t("generalPages.card"),
        CHART: this.$t("generalPages.chart"),
        COLUMN_CHART_VALUE_LABEL: "generalPages.valueLabel",
        COLUMN_CHART_Y_AXIS: "generalPages.verticalAxis",
        COLUMN_CHART_X_AXIS: "generalPages.horizontalAxis",
        COLUMN_CHART_LEGEND: "generalPages.legend",
        COLUMN_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        COLUMN_CHART_TITLE: "generalPages.title",
        BAR_CHART_VALUE_LABEL: "generalPages.valueLabel",
        BAR_CHART_Y_AXIS: "generalPages.verticalAxis",
        BAR_CHART_X_AXIS: "generalPages.horizontalAxis",
        BAR_CHART_LEGEND: "generalPages.legend",
        BAR_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        BAR_CHART_TITLE: "generalPages.title",
        LINE_CHART_VALUE_LABEL: "generalPages.valueLabel",
        LINE_CHART_Y_AXIS: "generalPages.verticalAxis",
        LINE_CHART_X_AXIS: "generalPages.horizontalAxis",
        LINE_CHART_LEGEND: "generalPages.legend",
        LINE_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        LINE_CHART_TITLE: "generalPages.title",
        PIE_CHART_VALUE_LABEL: "generalPages.valueLabel",
        PIE_CHART_LEGEND: "generalPages.legend",
        PIE_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        PIE_CHART_TITLE: "generalPages.title",
        FILTER_SELECT_LIST_VALUE: "generalPages.value",
        FILTER_SELECT_LIST_VALUE_LABEL: "generalPages.valueLabel",
        AREA_CHART_VALUE_LABEL: "generalPages.valueLabel",
        AREA_CHART_X_AXIS: "generalPages.horizontalAxis",
        AREA_CHART_Y_AXIS: "generalPages.verticalAxis",
        AREA_CHART_LEGEND: "generalPages.legend",
        AREA_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        AREA_CHART_TITLE: "generalPages.title",
        TREEMAP_VALUE_LABEL: "generalPages.valueLabel",
        TREEMAP_LEGEND: "generalPages.legend",
        TREEMAP_AUTO_REFRESH: "generalPages.autoRefresh",
        TREEMAP_TITLE: "generalPages.title",
        HEATMAP_VALUE_LABEL: "generalPages.valueLabel",
        HEATMAP_Y_AXIS: "generalPages.verticalAxis",
        HEATMAP_X_AXIS: "generalPages.horizontalAxis",
        HEATMAP_AUTO_REFRESH: "generalPages.autoRefresh",
        HEATMAP_TITLE: "generalPages.title",
        VISUALMAP: "panelProperties.visualMap.title",
        SCATTER_CHART_VALUE_LABEL: "generalPages.valueLabel",
        SCATTER_CHART_X_AXIS: "generalPages.horizontalAxis",
        SCATTER_CHART_Y_AXIS: "generalPages.verticalAxis",
        SCATTER_CHART_LEGEND: "generalPages.legend",
        SCATTER_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        SCATTER_CHART_TITLE: "generalPages.title",
        BUBBLE_CHART_X_AXIS: "generalPages.horizontalAxis",
        BUBBLE_CHART_Y_AXIS: "generalPages.verticalAxis",
        BUBBLE_CHART_VALUE_LABEL: "generalPages.valueLabel",
        BUBBLE_CHART_LEGEND: "generalPages.legend",
        BUBBLE_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        BUBBLE_CHART_TITLE: "generalPages.title",
        COMBINATION_CHART_VALUE_LABEL: "generalPages.valueLabel",
        COMBINATION_CHART_Y_AXIS: "generalPages.verticalBarAxis",
        COMBINATION_CHART_SECOND_Y_AXIS: "generalPages.verticalLineAxis",
        COMBINATION_CHART_X_AXIS: "generalPages.horizontalAxis",
        COMBINATION_CHART_LEGEND: "generalPages.legend",
        COMBINATION_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        COMBINATION_CHART_TITLE: "generalPages.title",
        GAUGE_CHART_PROGRESS_BAR: "generalPages.progressBar",
        GAUGE_CHART_VALUE_LABEL: "generalPages.valueLabel",
        GAUGE_CHART_TITLE: "generalPages.title",
        GAUGE_CHART_SEPERATION_PERCENTAGES: "generalPages.seperationPercentages",
        GAUGE_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        RADAR_CHART_VALUE_LABEL: "generalPages.valueLabel",
        RADAR_CHART_LEGEND: "generalPages.legend",
        RADAR_CHART_AXIS_NAME: "generalPages.axisName",
        RADAR_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        RADAR_CHART_TITLE: "generalPages.title",
        STACKED_COLUMN_CHART_VALUE_LABEL: "generalPages.valueLabel",
        STACKED_COLUMN_CHART_Y_AXIS: "generalPages.verticalAxis",
        STACKED_COLUMN_CHART_X_AXIS: "generalPages.horizontalAxis",
        STACKED_COLUMN_CHART_LEGEND: "generalPages.legend",
        STACKED_COLUMN_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        STACKED_COLUMN_CHART_TITLE: "generalPages.title",
        STACKED_BAR_CHART_VALUE_LABEL: "generalPages.valueLabel",
        STACKED_BAR_CHART_Y_AXIS: "generalPages.verticalAxis",
        STACKED_BAR_CHART_X_AXIS: "generalPages.horizontalAxis",
        STACKED_BAR_CHART_LEGEND: "generalPages.legend",
        STACKED_BAR_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        STACKED_BAR_CHART_TITLE: "generalPages.title",
        MAP_SETTINGS: "panelProperties.mapSettings.title",
        VALUE_LABEL: "panelProperties.valueLabel.title",
        BORDER_SETTINGS: "panelProperties.borderSettings.title",
        PALETTE: "generalPages.palette",
        ADVANCED_BUTTON: "generalPages.advancedButton",
        DATA_TABLE_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        DATA_TABLE_CHART_INTERACTIONS: "generalPages.interactions",
        DATA_TABLE_CHART_ROWS: "generalPages.rows",
        DATA_TABLE_CHART_HEADER: "generalPages.header",
        DATA_TABLE_CHART_SUBTOTALS: "generalPages.subtotals",
        DATA_TABLE_CHART_TOTALS: "generalPages.totals",
        DATA_TABLE_CHART_SECTION: "generalPages.sections",
        CHART_EXPORT: "generalPages.export",
        CHART_DATA_TABLE_TITLE: "generalPages.title",
        PIVOT_TABLE_CHART_ROW_TITLE: "generalPages.rowTitle",
        PIVOT_TABLE_CHART_COLUMN_TITLE: "generalPages.columnTitle",
        PIVOT_TABLE_CHART_MEASURE: "generalPages.labelMeasure",
        PIVOT_TABLE_CHART_TOTAL_TITLE: "generalPages.totalTitle",
        PIVOT_TABLE_CHART_TOTAL: "generalPages.total",
        PIVOT_TABLE_AUTO_REFRESH: "generalPages.autoRefresh",
        PIVOT_TABLE_TITLE: "generalPages.title",
        SUMMARY_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        SUMMARY_CHART_TITLE: "generalPages.title",
        SUMMARY_CHART_VALUE_LABEL: "generalPages.valueLabel",
        SUMMARY_CHART_TARGET_VALUE: "generalPages.Target Value",
        SUMMARY_CHART_TARGET_VALUE_LABEL: "generalPages.Target Value Label",
        SUMMARY_CHART_TREND: "generalPages.Trend",
        GEO_MAP_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        GEO_MAP_CHART_TITLE: "generalPages.title",
        SANKEY_CHART_TITLE: "generalPages.title",
        SANKEY_CHART_VALUE_LABEL: "generalPages.valueLabel",
        SANKEY_CHART_AUTO_REFRESH: "generalPages.autoRefresh",
        SANKEY_CHART_NODE_PROPERTIES: "generalPages.Node Properties",
        CAPTION: "generalPages.caption",
        BUTTON_ACTION: "generalPages.Action",
        LABEL_CHART_TITLE: "generalPages.title",
        BUTTON_DIALOG: "Dialog",
        TEXT_EDITOR_TITLE: "generalPages.title",
        PDF_SETUP: "generalPages.Pdf Setup",
        DRILL_THROUGH: "panelProperties.Drill Through",
      },
      componentTypes: {
        BUTTON: COMPONENT_TYPES.BUTTON,
        COLOR_PICKER: COMPONENT_TYPES.COLOR_PICKER,
        INPUT: COMPONENT_TYPES.INPUT,
        INPUT_NUMBER: COMPONENT_TYPES.INPUT_NUMBER,
        SELECT_BOX: COMPONENT_TYPES.SELECT_BOX,
        CUSTOM_MULTIPLE_SELECT_BOX: COMPONENT_TYPES.CUSTOM_MULTIPLE_SELECT_BOX,
        SWITCH: COMPONENT_TYPES.SWITCH,
        INPUT_WITH_ENTER: COMPONENT_TYPES.INPUT_WITH_ENTER,
        OTHER: COMPONENT_TYPES.OTHER,
        TEXTAREA: COMPONENT_TYPES.TEXTAREA,
        SHOW_BUTTON: COMPONENT_TYPES.SHOW_BUTTON,
        DATE_RANGE: COMPONENT_TYPES.DATE_RANGE,
        CUSTOM_ICON_SELECT_BOX: COMPONENT_TYPES.CUSTOM_ICON_SELECT_BOX,
        AUTO_COMPLETE_INPUT_WITH_TABLE: COMPONENT_TYPES.AUTO_COMPLETE_INPUT_WITH_TABLE,
        TEXT_ALIGNMENT_RADIO_BUTTON: COMPONENT_TYPES.TEXT_ALIGNMENT_RADIO_BUTTON,
        ROTATE_ALIGNMENT_RADIO_BUTTON: COMPONENT_TYPES.ROTATE_ALIGNMENT_RADIO_BUTTON,
      },
      dashboardComponentTypes: {
        GENERAL: "GENERAL",
        CHART: "CHART",
        DATA_TABLE_COLUMN: "DATA_TABLE_COLUMN",
        PIVOT_TABLE_COLUMN: "PIVOT_TABLE_COLUMN",
      },
    };
  },
  computed: {
    datamodelUseCache() {
      return !this.isConfigurationUseCacheActive ? this.isConfigurationUseCacheActive : getCommonDatamodelPropertyValueByKey(this.datamodelProperty, datamodelPropertyKeys.DATAMODEL_USE_CACHE);
    },
    datamodelUseDefaultCacheDuration() {
      return getCommonDatamodelPropertyValueByKey(this.datamodelProperty, datamodelPropertyKeys.DATAMODEL_USE_DEFAULT_CACHE_DURATION);
    },
    datamodelCacheDuration() {
      return this.datamodelUseDefaultCacheDuration ? this.configurationDefaultCacheDurationInMinute : getCommonDatamodelPropertyValueByKey(this.datamodelProperty, datamodelPropertyKeys.DATAMODEL_CACHE_DURATION);
    },
    datamodelForceCacheDuration() {
      return getCommonDatamodelPropertyValueByKey(this.datamodelProperty, datamodelPropertyKeys.DATAMODEL_FORCE_CACHE_DURATION);
    },
    dashboardUseCache() {
      return !this.datamodelUseCache ? this.datamodelUseCache : this.selectedDashboardTabStyle[dashboardGridPropertiesEnum.DASHBOARD_USE_CACHE] ?? this.datamodelUseCache;
    },
    dashboardUseDefaultCacheDuration() {
      return this.selectedDashboardTabStyle[dashboardGridPropertiesEnum.DASHBOARD_USE_DEFAULT_CACHE_DURATION] ?? DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_USE_DEFAULT_CACHE_DURATION;
    },
    dashboardCacheDuration() {
      return this.dashboardUseDefaultCacheDuration ? this.datamodelCacheDuration : this.selectedDashboardTabStyle[dashboardGridPropertiesEnum.DASHBOARD_CACHE_DURATION_INPUT] ?? this.datamodelCacheDuration;
    },
    //Chart properties.js dosyalarında kullanılıyor. Örn: columnChartProperties.js, treemapProperties.js
    chartUseDefaultCacheDuration() {
      return this.componentStyle[chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION] ?? true;
    },
    chartUseCache() {
      return this.componentStyle[chartPropertiesEnum.CHART_USE_CACHE] ?? true;
    },
    //for general
    selectedTemplateProperties() {
      return (
        this.cardTemplates.find((d) => d.id === this.selectedTemplateId)
          ?.properties?.style ?? {}
      );
    },
    isRoutePanel() {
      return this.$route.name == routerEnums.PANEL;
    },


    allCardComponentsMapping() {
      return {
        [cardPropertiesEnum.HAS_TITLE]: {
          id: this.getPropertyId(
            cardPropertiesEnum.HAS_TITLE,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.showTitle"),
          value: this.componentStyle[cardPropertiesEnum.HAS_TITLE],
          property: cardPropertiesEnum.HAS_TITLE,
          componentType: this.componentTypes.SWITCH,
          flexClass: true,
        },
        [cardPropertiesEnum.TITLE_FONT_FAMILY]: {
          id: this.getPropertyId(
            cardPropertiesEnum.TITLE_FONT_FAMILY,
            this.collapse.CARD
          ),
          label: this.$t(
            "dashboardPropertiesLocale.commonCard.Title Text Format"
          ),
          cssStyle: chartPropertiesCss.FONT_FAMILY,
          value: this.componentStyle[cardPropertiesEnum.TITLE_FONT_FAMILY],
          property: cardPropertiesEnum.TITLE_FONT_FAMILY,
          ...this.getCommonSelectBoxComponentsMapping(fontsArr)
        },
        [cardPropertiesEnum.TITLE_FONT_SIZE]: {
          id: this.getPropertyId(
            cardPropertiesEnum.TITLE_FONT_SIZE,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_SIZE,
          value: this.componentStyle[cardPropertiesEnum.TITLE_FONT_SIZE],
          property: cardPropertiesEnum.TITLE_FONT_SIZE,
          ...this.getCommonSelectBoxComponentsMapping(fontSizesArr)
        },
        [cardPropertiesEnum.TITLE_FONT_COLOR]: {
          id: this.getPropertyId(
            cardPropertiesEnum.TITLE_FONT_COLOR,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_COLOR,
          value: this.componentStyle[cardPropertiesEnum.TITLE_FONT_COLOR],
          property: cardPropertiesEnum.TITLE_FONT_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
        },
        [cardPropertiesEnum.TITLE_FONT_WEIGHT]: {
          id: this.getPropertyId(
            cardPropertiesEnum.TITLE_FONT_WEIGHT,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_WEIGHT,
          value: this.componentStyle[cardPropertiesEnum.TITLE_FONT_WEIGHT],
          property: cardPropertiesEnum.TITLE_FONT_WEIGHT,
          ...this.getCommonSelectBoxComponentsMapping(fontWeightsArr)
        },
        [cardPropertiesEnum.TITLE_ALIGN_ITEM]: {
          id: this.getPropertyId(
            cardPropertiesEnum.TITLE_ALIGN_ITEM,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
          value: this.componentStyle[cardPropertiesEnum.TITLE_ALIGN_ITEM],
          property: cardPropertiesEnum.TITLE_ALIGN_ITEM,
          componentType: this.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON,
          flexClass: true,
        },

        [cardPropertiesEnum.HAS_SUBTITLE]: {
          id: this.getPropertyId(
            cardPropertiesEnum.HAS_SUBTITLE,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.showSubTitle"),
          value: this.componentStyle[cardPropertiesEnum.HAS_SUBTITLE],
          property: cardPropertiesEnum.HAS_SUBTITLE,
          componentType: this.componentTypes.SWITCH,
          flexClass: true,
        },
        [cardPropertiesEnum.DESCRIPTION_FONT_FAMILY]: {
          id: this.getPropertyId(
            cardPropertiesEnum.DESCRIPTION_FONT_FAMILY,
            this.collapse.CARD
          ),
          label: this.$t(
            "dashboardPropertiesLocale.commonCard.Subtitle Text Format"
          ),
          cssStyle: chartPropertiesCss.FONT_FAMILY,
          value:
            this.componentStyle[cardPropertiesEnum.DESCRIPTION_FONT_FAMILY],
          property: cardPropertiesEnum.DESCRIPTION_FONT_FAMILY,
          ...this.getCommonSelectBoxComponentsMapping(fontsArr)
        },
        [cardPropertiesEnum.DESCRIPTION_FONT_SIZE]: {
          id: this.getPropertyId(
            cardPropertiesEnum.DESCRIPTION_FONT_SIZE,
            this.collapse.CARD
          ),
          cssStyle: chartPropertiesCss.FONT_SIZE,
          label: "",
          value: this.componentStyle[cardPropertiesEnum.DESCRIPTION_FONT_SIZE],
          property: cardPropertiesEnum.DESCRIPTION_FONT_SIZE,
          ...this.getCommonSelectBoxComponentsMapping(fontSizesArr)
        },
        [cardPropertiesEnum.DESCRIPTION_FONT_WEIGHT]: {
          id: this.getPropertyId(
            cardPropertiesEnum.DESCRIPTION_FONT_WEIGHT,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_WEIGHT,
          value: this.componentStyle[cardPropertiesEnum.DESCRIPTION_FONT_WEIGHT],
          property: cardPropertiesEnum.DESCRIPTION_FONT_WEIGHT,
          ...this.getCommonSelectBoxComponentsMapping(fontWeightsArr)
        },
        [cardPropertiesEnum.DESCRIPTION_ALIGN_ITEM]: {
          id: this.getPropertyId(
            cardPropertiesEnum.DESCRIPTION_ALIGN_ITEM,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
          value: this.componentStyle[cardPropertiesEnum.DESCRIPTION_ALIGN_ITEM],
          property: cardPropertiesEnum.DESCRIPTION_ALIGN_ITEM,
          componentType: this.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON,
          flexClass: true,
        },
        [cardPropertiesEnum.DESCRIPTION_FONT_COLOR]: {
          id: this.getPropertyId(
            cardPropertiesEnum.DESCRIPTION_FONT_COLOR,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_COLOR,
          value: this.componentStyle[cardPropertiesEnum.DESCRIPTION_FONT_COLOR],
          property: cardPropertiesEnum.DESCRIPTION_FONT_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
        },
        [cardPropertiesEnum.HAS_FOOTNOTE]: {
          id: this.getPropertyId(
            cardPropertiesEnum.HAS_FOOTNOTE,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.showFootNote"),
          value: this.componentStyle[cardPropertiesEnum.HAS_FOOTNOTE],
          property: cardPropertiesEnum.HAS_FOOTNOTE,
          componentType: this.componentTypes.SWITCH,
          flexClass: true,
        },
        [cardPropertiesEnum.FOOTNOTE_FONT_FAMILY]: {
          id: this.getPropertyId(
            cardPropertiesEnum.FOOTNOTE_FONT_FAMILY,
            this.collapse.CARD
          ),
          label: "dashboardPropertiesLocale.commonCard.Footnote Text Format",
          cssStyle: chartPropertiesCss.FONT_FAMILY,
          value: this.componentStyle[cardPropertiesEnum.FOOTNOTE_FONT_FAMILY],
          property: cardPropertiesEnum.FOOTNOTE_FONT_FAMILY,
          ...this.getCommonSelectBoxComponentsMapping(fontsArr)
        },
        [cardPropertiesEnum.FOOTNOTE_FONT_SIZE]: {
          id: this.getPropertyId(
            cardPropertiesEnum.FOOTNOTE_FONT_SIZE,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_SIZE,
          value: this.componentStyle[cardPropertiesEnum.FOOTNOTE_FONT_SIZE],
          property: cardPropertiesEnum.FOOTNOTE_FONT_SIZE,
          ...this.getCommonSelectBoxComponentsMapping(fontSizesArr)
        },
        [cardPropertiesEnum.FOOTNOTE_FONT_WEIGHT]: {
          id: this.getPropertyId(
            cardPropertiesEnum.FOOTNOTE_FONT_WEIGHT,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_WEIGHT,
          value: this.componentStyle[cardPropertiesEnum.FOOTNOTE_FONT_WEIGHT],
          property: cardPropertiesEnum.FOOTNOTE_FONT_WEIGHT,
          ...this.getCommonSelectBoxComponentsMapping(fontWeightsArr)
        },
        [cardPropertiesEnum.FOOTNOTE_ALIGN_ITEM]: {
          id: this.getPropertyId(
            cardPropertiesEnum.FOOTNOTE_ALIGN_ITEM,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
          value: this.componentStyle[cardPropertiesEnum.FOOTNOTE_ALIGN_ITEM],
          property: cardPropertiesEnum.FOOTNOTE_ALIGN_ITEM,
          componentType: this.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON,
          flexClass: true,
        },
        [cardPropertiesEnum.FOOTNOTE_FONT_COLOR]: {
          id: this.getPropertyId(
            cardPropertiesEnum.FOOTNOTE_FONT_COLOR,
            this.collapse.CARD
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_COLOR,
          value: this.componentStyle[cardPropertiesEnum.FOOTNOTE_FONT_COLOR],
          property: cardPropertiesEnum.FOOTNOTE_FONT_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
        },

        [cardPropertiesEnum.HAS_BORDER]: {
          id: this.getPropertyId(
            cardPropertiesEnum.HAS_BORDER,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.showBorder"),
          value: this.componentStyle[cardPropertiesEnum.HAS_BORDER],
          property: cardPropertiesEnum.HAS_BORDER,
          componentType: this.componentTypes.SWITCH,
          flexClass: true,
        },
        [cardPropertiesEnum.BORDER_RADIUS]: {
          id: this.getPropertyId(
            cardPropertiesEnum.BORDER_RADIUS,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.borderRadius"),
          value: this.componentStyle[cardPropertiesEnum.BORDER_RADIUS],
          property: cardPropertiesEnum.BORDER_RADIUS,
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: false,
        },
        [cardPropertiesEnum.BORDER_COLOR]: {
          id: this.getPropertyId(
            cardPropertiesEnum.BORDER_COLOR,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.borderColor"),
          value: this.componentStyle[cardPropertiesEnum.BORDER_COLOR],
          property: cardPropertiesEnum.BORDER_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          hasBorder: this.componentStyle[cardPropertiesEnum.HAS_BORDER],
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
        },
        [cardPropertiesEnum.HAS_DROP_SHADOW]: {
          id: this.getPropertyId(
            cardPropertiesEnum.HAS_DROP_SHADOW,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.dropShadow"),
          value: this.componentStyle[cardPropertiesEnum.HAS_DROP_SHADOW],
          property: cardPropertiesEnum.HAS_DROP_SHADOW,
          componentType: this.componentTypes.SWITCH,
          flexClass: true,
        },
        [cardPropertiesEnum.BACKGROUND_COLOR]: {
          id: this.getPropertyId(
            cardPropertiesEnum.BACKGROUND_COLOR,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.general.backgroundColor"),
          value: this.componentStyle[cardPropertiesEnum.BACKGROUND_COLOR],
          property: cardPropertiesEnum.BACKGROUND_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          hasBorder: true,
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
        },
        [cardPropertiesEnum.POSITION_X]: {
          id: this.getPropertyId(
            cardPropertiesEnum.POSITION_X,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.card.xPosition"),
          value: this.componentStyle[cardPropertiesEnum.POSITION_X],
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: true,
        },
        [cardPropertiesEnum.POSITION_Y]: {
          id: this.getPropertyId(
            cardPropertiesEnum.POSITION_Y,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.card.yPosition"),
          value: this.componentStyle[cardPropertiesEnum.POSITION_Y],
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: true,
        },
        [cardPropertiesEnum.CARD_W]: {
          id: this.getPropertyId(cardPropertiesEnum.CARD_W, this.collapse.CARD),
          label: this.$t("dashboardPropertiesLocale.common.width"),
          value: this.componentStyle[cardPropertiesEnum.CARD_W],
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: true,
        },
        [cardPropertiesEnum.CARD_H]: {
          id: this.getPropertyId(cardPropertiesEnum.CARD_H, this.collapse.CARD),
          label: this.$t("dashboardPropertiesLocale.common.height"),
          value: this.componentStyle[cardPropertiesEnum.CARD_H],
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: true,
        },
        [cardPropertiesEnum.CUSTOM_ENDPOINT]: {
          id: this.getPropertyId(
            cardPropertiesEnum.CUSTOM_ENDPOINT,
            this.collapse.CARD
          ),
          label: this.$t("dashboardPropertiesLocale.commonCard.customEndpoint"),
          value: this.componentStyle[cardPropertiesEnum.CUSTOM_ENDPOINT],
          property: cardPropertiesEnum.CUSTOM_ENDPOINT,
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: false,
        },
        [cardPropertiesEnum.CARD_FILTER_ICON_SHOW]: {
            id: this.getPropertyId(
              cardPropertiesEnum.CARD_FILTER_ICON_SHOW,
              this.collapse.CARD
            ),
            label: "dashboardPropertiesLocale.commonCard.Show Filter",
            value:  this.getPropertiesWithUndefinedCheck(this.componentStyle[cardPropertiesEnum.CARD_FILTER_ICON_SHOW], true),
            property: cardPropertiesEnum.CARD_FILTER_ICON_SHOW,
            componentType: this.componentTypes.SWITCH,
            flexClass: true,
          },
        [cardPropertiesEnum.CARD_INFO_SHOW]: {
          id: this.getPropertyId(
            cardPropertiesEnum.CARD_INFO_SHOW,
            this.collapse.CARD
          ),
          label: "dashboardPropertiesLocale.commonCard.Show Info",
          value: this.componentStyle[cardPropertiesEnum.CARD_INFO_SHOW],
          property: cardPropertiesEnum.CARD_INFO_SHOW,
          componentType: this.componentTypes.SWITCH,
          flexClass: true,
        },
        [cardPropertiesEnum.CARD_INFO_TEXT]: {
          id: this.getPropertyId(
            cardPropertiesEnum.CARD_INFO_TEXT,
            this.collapse.CARD
          ),
          label: "dashboardPropertiesLocale.commonCard.Info Text",
          value: this.componentStyle[cardPropertiesEnum.CARD_INFO_TEXT],
          property: cardPropertiesEnum.CARD_INFO_TEXT,
          componentType: this.componentTypes.TEXTAREA,
          flexClass: false,
          inputType: "text",
          readOnly: false,
        },
        [cardPropertiesEnum.CARD_EXPAND_SHOW]: {
          id: this.getPropertyId(
            cardPropertiesEnum.CARD_EXPAND_SHOW,
            this.collapse.CARD
          ),
          label: "dashboardPropertiesLocale.commonCard.Show Expand in View Mode",
          value:  this.getPropertiesWithUndefinedCheck(this.componentStyle[cardPropertiesEnum.CARD_EXPAND_SHOW], true),
          property: cardPropertiesEnum.CARD_EXPAND_SHOW,
          componentType: this.componentTypes.SWITCH,
          flexClass: true,
        },
        [cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU]: {
            id: this.getPropertyId(
              cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
              this.collapse.CARD
            ),
            label: "panelProperties.exportMenu",
            value:  this.getPropertiesWithUndefinedCheck(this.componentStyle[cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU], false),
            property: cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
            componentType: this.componentTypes.SWITCH,
            flexClass: true,
        },
        [cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT]: {
            id: this.getPropertyId(
              cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
              this.collapse.CARD
            ),
            label: "panelProperties.Allow PNG Export",
            value:  this.getPropertiesWithUndefinedCheck(this.componentStyle[cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT], false),
            property: cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
            componentType: this.componentTypes.SWITCH,
            flexClass: true,
        },
        [chartPropertiesEnum.CHART_USE_CACHE]: {
            id: this.getPropertyId(
              chartPropertiesEnum.CHART_USE_CACHE,
              this.collapse.CARD
            ),
            label: "generalPages.Use Cache",
            value: !this.dashboardUseCache ? this.dashboardUseCache : this.getPropertiesWithUndefinedCheck(this.componentStyle[chartPropertiesEnum.CHART_USE_CACHE], true),
            property: chartPropertiesEnum.CHART_USE_CACHE,
            componentType: this.componentTypes.SWITCH,
            flexClass: true,
            readOnly: !this.dashboardUseCache
        },
        [chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION]: {
            id: this.getPropertyId(
              chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
              this.collapse.CARD
            ),
            label: "generalPages.Use Default Cache Duration",
            value:  this.getPropertiesWithUndefinedCheck(this.componentStyle[chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION], true),
            property: chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
            componentType: this.componentTypes.SWITCH,
            flexClass: true,
            readOnly: !this.dashboardUseCache || !this.chartUseCache
        },
        [chartPropertiesEnum.CHART_CACHE_DURATION_INPUT]: {
            id: this.getPropertyId(
              chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                this.collapse.CHART
            ),
            label: "generalPages.Cache Duration",
            value: this.chartUseDefaultCacheDuration || this.datamodelForceCacheDuration ? this.dashboardCacheDuration : this.getPropertiesWithUndefinedCheck(this.componentStyle[
              chartPropertiesEnum.CHART_CACHE_DURATION_INPUT]),
            property: chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
            componentType: this.componentTypes.INPUT_WITH_ENTER,
            flexClass: false,
            inputType: "text",
            readOnly: this.chartUseDefaultCacheDuration || !this.dashboardUseCache || !this.chartUseCache || this.datamodelForceCacheDuration,
            inputTitle: this.datamodelForceCacheDuration ? "panelProperties.The Force Cache feature is active in the data model, so no changes can be made" : ""
        },
        [chartPropertiesEnum.BUTTON_REFRESH_CACHE]: {
            label: this.$t("generalPages.Refresh Cache"),
            eventId: onPropertiesEventOptions.refreshCache,
            property: chartPropertiesEnum.BUTTON_REFRESH_CACHE,
            componentType: this.componentTypes.BUTTON,
            readOnly: !this.dashboardUseCache || !this.chartUseCache
        },
      };
    },
    dashboardPropertiesCollapses() {
      return [
        //DASHBOARD GRID - GENERAL
        ...(this.activeComponentType === this.dashboardComponentTypes.GENERAL
          ? [
            {
              title: this.$t("dashboardPropertiesLocale.title.general"),
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.dashboardGridMapping, [
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_BACKGROUND_COLOR,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_DESIGN_MODE,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_HAS_GRID_LINES,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_POSITION,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_WIDTH,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_HEIGHT,
                ])
              ),
            },
            {
              title: this.$t(
                "dashboardPropertiesLocale.title.commoncardproperties"
              ),
              name: this.collapse.COMMON_CARD,
              components: Object.values(
                _.pick(this.dashboardGridMapping, [
                  commonCardPropertiesEnum.SELECTED_TEMPLATE_ID,
                  commonCardPropertiesEnum.HAS_TITLE,
                  commonCardPropertiesEnum.TITLE_FONT_FAMILY,
                  commonCardPropertiesEnum.HAS_SUBTITLE,
                  commonCardPropertiesEnum.DESCRIPTION_FONT_FAMILY,
                  commonCardPropertiesEnum.HAS_FOOTNOTE,
                  commonCardPropertiesEnum.FOOTNOTE_FONT_FAMILY,
                  commonCardPropertiesEnum.HAS_BORDER,
                  commonCardPropertiesEnum.BORDER_RADIUS,
                  commonCardPropertiesEnum.BORDER_COLOR,
                  commonCardPropertiesEnum.HAS_DROP_SHADOW,
                  commonCardPropertiesEnum.BACKGROUND_COLOR
                ])
              ),
            },
            {
              title: this.$t("dashboardPropertiesLocale.title.tabs"),
              name: this.collapse.TABS,
              components: Object.values(
                _.pick(this.dashboardGridMapping, [
                  tabsPropertiesEnum.HAS_TABS,
                  tabsPropertiesEnum.TABS_FONT_FAMILY,
                  tabsPropertiesEnum.TABS_FONT_COLOR,
                  tabsPropertiesEnum.TABS_BACKGROUND_COLOR
                ])
              ),
            },
            {
              title: this.$t("dashboardPropertiesLocale.title.Canvas Size"),
              name: this.collapse.PAGESIZE,
              components: Object.values(
                _.pick(this.dashboardGridMapping, [
                  pageSizePropertiesEnum.PAGE_SIZE,
                ], this.componentStyle.PAGE_SIZE == pageSizeValues.CUSTOM ? [pageSizePropertiesEnum.WIDTH, pageSizePropertiesEnum.HEIGHT] : null)
              ),
            },
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.dashboardGridMapping, [
                  dashboardGridPropertiesEnum.DASHBOARD_USE_CACHE,
                  dashboardGridPropertiesEnum.DASHBOARD_USE_DEFAULT_CACHE_DURATION,
                  dashboardGridPropertiesEnum.DASHBOARD_CACHE_DURATION_INPUT,
                  dashboardGridPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
            {
              title: this.collapse.PDF_SETUP,
              name: this.collapse.PDF_SETUP,
              components: Object.values(
                _.pick(this.dashboardGridMapping, [
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_SHOW_GUIDLINES,
                  dashboardGridPropertiesEnum.DASHBOARD_GRID_PAPER_SIZE
                ])),
            },
          ]
          : []),
        //CARD PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          !this.isRoutePanel &&
          this.chartType !== chartTypes.LABEL
          ? [
            {
              title: `${this.$t("dashboardPropertiesLocale.title.general")}`,
              name: this.collapse.CARD,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.HAS_TITLE,
                  cardPropertiesEnum.HAS_SUBTITLE,
                  cardPropertiesEnum.HAS_FOOTNOTE,
                  cardPropertiesEnum.HAS_DROP_SHADOW,
                  cardPropertiesEnum.CARD_EXPAND_SHOW,
                  cardPropertiesEnum.HAS_BORDER,
                  cardPropertiesEnum.CARD_FILTER_ICON_SHOW,
                  cardPropertiesEnum.BORDER_COLOR,
                  cardPropertiesEnum.BORDER_RADIUS,
                  cardPropertiesEnum.BACKGROUND_COLOR,
                  cardPropertiesEnum.CARD_INFO_SHOW,
                  cardPropertiesEnum.CARD_INFO_TEXT,
                  cardPropertiesEnum.TITLE_FONT_FAMILY,
                  cardPropertiesEnum.TITLE_FONT_SIZE,
                  cardPropertiesEnum.TITLE_FONT_WEIGHT,
                  cardPropertiesEnum.TITLE_ALIGN_ITEM,
                  cardPropertiesEnum.TITLE_FONT_COLOR,
                  cardPropertiesEnum.DESCRIPTION_FONT_FAMILY,
                  cardPropertiesEnum.DESCRIPTION_FONT_SIZE,
                  cardPropertiesEnum.DESCRIPTION_FONT_WEIGHT,
                  cardPropertiesEnum.DESCRIPTION_ALIGN_ITEM,
                  cardPropertiesEnum.DESCRIPTION_FONT_COLOR,
                  cardPropertiesEnum.FOOTNOTE_FONT_FAMILY,
                  cardPropertiesEnum.FOOTNOTE_FONT_SIZE,
                  cardPropertiesEnum.FOOTNOTE_FONT_WEIGHT,
                  cardPropertiesEnum.FOOTNOTE_ALIGN_ITEM,
                  cardPropertiesEnum.FOOTNOTE_FONT_COLOR,
                  cardPropertiesEnum.POSITION_X,
                  cardPropertiesEnum.POSITION_Y,
                  cardPropertiesEnum.CARD_W,
                  cardPropertiesEnum.CARD_H,
                ])
              ),
            },
          ]
          : []),
        //LABEL CHART / CARD PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          !this.isRoutePanel &&
          this.chartType === chartTypes.LABEL
          ? [
            {
              title: `${this.chartType} ${this.$t(
                "dashboardPropertiesLocale.title.properties"
              )}`,
              name: this.collapse.CARD,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.HAS_TITLE,
                  cardPropertiesEnum.HAS_SUBTITLE,
                  cardPropertiesEnum.HAS_DROP_SHADOW,
                  cardPropertiesEnum.HAS_BORDER,
                  cardPropertiesEnum.BORDER_COLOR,
                  cardPropertiesEnum.BORDER_RADIUS,
                  cardPropertiesEnum.BACKGROUND_COLOR,
                  cardPropertiesEnum.TITLE_FONT_FAMILY,
                  cardPropertiesEnum.TITLE_FONT_SIZE,
                  cardPropertiesEnum.TITLE_FONT_WEIGHT,
                  cardPropertiesEnum.TITLE_ALIGN_ITEM,
                  cardPropertiesEnum.TITLE_FONT_COLOR,
                  cardPropertiesEnum.DESCRIPTION_FONT_FAMILY,
                  cardPropertiesEnum.DESCRIPTION_FONT_SIZE,
                  cardPropertiesEnum.DESCRIPTION_ALIGN_ITEM,
                  cardPropertiesEnum.DESCRIPTION_FONT_WEIGHT,
                  cardPropertiesEnum.DESCRIPTION_FONT_COLOR,
                  cardPropertiesEnum.POSITION_X,
                  cardPropertiesEnum.POSITION_Y,
                  cardPropertiesEnum.CARD_W,
                  cardPropertiesEnum.CARD_H,
                ])
              ),
            },
          ]
          : []),
        //Advanced Button / CARD PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          !this.isRoutePanel &&
          this.chartType === dashboardFilterTypes.ADVANCED_BUTTON &&
          this.chartType !== chartTypes.LABEL
          ? [
            {
              title: `${this.chartType} ${this.$t(
                "dashboardPropertiesLocale.title.properties"
              )}`,
              name: this.collapse.ADVANCED_BUTTON,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CUSTOM_ENDPOINT,
                ])
              ),
            },
          ]
          : []),
        //LINE CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_INSIGHT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_SQL,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SPLINE_MODE,
                ])
              ),
            },
          ]
          : []),
        //LINE CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //LINE CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.LINE_CHART_VALUE_LABEL,
              name: this.collapse.LINE_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_VALUE_LABEL,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_ONLY,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_VALUE_LABEL_FONT_FAMILY,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_VALUE_LABEL_FONT_SIZE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_VALUE_LABEL_FONT_WEIGHT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_VALUE_LABEL_ROTATE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_VALUE_LABEL_FONT_COLOR,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //LINE CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.LINE_CHART_X_AXIS,
              name: this.collapse.LINE_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_X_AXIS_LINE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_X_AXIS_NAME,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_NAME_TEXT,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_NAME_FONT_FAMILY,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_NAME_FONT_SIZE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_NAME_FONT_COLOR,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_X_AXIS_LABEL,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_WRAP,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_FONT_SIZE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_ROTATE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_FONT_COLOR,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LABEL_MAX_CHARACTER,
                ])
              ),
            },
          ]
          : []),
        //LINE CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.LINE_CHART_Y_AXIS,
              name: this.collapse.LINE_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_Y_AXIS_NAME,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_TEXT,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_VERTICAL_ALIGN,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_LOCATION,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_FONT_SIZE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_NAME_FONT_COLOR,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_Y_AXIS_LABEL,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                  //LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LABEL_FONT_COLOR,

                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_MIN,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_POSITION,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_SHOW_LINE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LINE_STYLE_COLOR,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_GRID_LINE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //LINE CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.LINE_CHART_LEGEND,
              name: this.collapse.LINE_CHART_LEGEND,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SHOW_LEGEND,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_LEGEND_MAX_CHARACTER,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_LEGEND_POSITION,
                  // LINE_CHART_PROPERTIES_ENUM.LINE_CHART_LEGEND_VERTICAL_ALIGN,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_LEGEND_FONT_FAMILY,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_LEGEND_FONT_SIZE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_LEGEND_FONT_WEIGHT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //LINE CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        
        //LINE CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_DATA_ZOOM_START,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_DATA_ZOOM_END,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_DATA_ZOOM_START,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_DATA_ZOOM_END,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //LINE CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //LINE CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.LINE_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.lineChartComponentsMapping, [
                    LINE_CHART_PROPERTIES_ENUM.LINE_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.LINE_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.LINE_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    LINE_CHART_PROPERTIES_ENUM.LINE_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    LINE_CHART_PROPERTIES_ENUM.LINE_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //LINE CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.LINE_CHART_AUTO_REFRESH,
              name: this.collapse.LINE_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.LINE_CHART_AUTO_REFRESH)
                    ? LINE_CHART_PROPERTIES_ENUM.LINE_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //LINE CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LINE_CHART
          ? [
            {
              title: this.collapse.LINE_CHART_TITLE,
              name: this.collapse.LINE_CHART_TITLE,
              components: Object.values(
                _.pick(this.lineChartComponentsMapping, [
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_DYNAMIC_TITLE_VALUE,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  LINE_CHART_PROPERTIES_ENUM.LINE_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //COLUMN CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_INSIGHT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //COLUMN CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),

        //COLUMN CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.COLUMN_CHART_VALUE_LABEL,
              name: this.collapse.COLUMN_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_VALUE_LABEL,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_ONLY,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_VALUE_LABEL_FONT_FAMILY,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_VALUE_LABEL_FONT_SIZE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_VALUE_LABEL_ROTATE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_VALUE_LABEL_FONT_COLOR,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS
                ])
              ),
            },
          ]
          : []),
        //COLUMN CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.COLUMN_CHART_X_AXIS,
              name: this.collapse.COLUMN_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_X_AXIS_LINE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_X_AXIS_NAME,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_NAME_TEXT,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_NAME_FONT_SIZE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_NAME_FONT_COLOR,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_X_AXIS_LABEL,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_WRAP,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_ROTATE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER,
                ])
              ),
            },
          ]
          : []),
        //COLUMN CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.COLUMN_CHART_Y_AXIS,
              name: this.collapse.COLUMN_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_Y_AXIS_NAME,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_TEXT,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_VERTICAL_ALIGN,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_LOCATION,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_Y_AXIS_LABEL,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                  //COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR,

                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_MIN,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_POSITION,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_SHOW_LINE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_GRID_LINE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //COLUMN CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.COLUMN_CHART_LEGEND,
              name: this.collapse.COLUMN_CHART_LEGEND,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_LEGEND,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_LEGEND_MAX_CHARACTER,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_LEGEND_POSITION,
                  // COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_LEGEND_VERTICAL_ALIGN,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_LEGEND_FONT_FAMILY,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_LEGEND_FONT_SIZE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_LEGEND_FONT_WEIGHT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //COLUMN CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //COLUMN CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_DATA_ZOOM_START,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_DATA_ZOOM_END,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_DATA_ZOOM_START,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_DATA_ZOOM_END,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //COLUMN CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //COLUMN CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.COLUMN_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.columnChartComponentsMapping, [
                    COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.COLUMN_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.COLUMN_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //COLUMN CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.COLUMN_CHART_AUTO_REFRESH,
              name: this.collapse.COLUMN_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.COLUMN_CHART_AUTO_REFRESH)
                    ? COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_AUTO_REFRESH_CUSTOM : null
                ])
              ),
            },
          ]
          : []),


        //COLUMN CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COLUMN_CHART
          ? [
            {
              title: this.collapse.COLUMN_CHART_TITLE,
              name: this.collapse.COLUMN_CHART_TITLE,
              components: Object.values(
                _.pick(this.columnChartComponentsMapping, [
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_DYNAMIC_TITLE_VALUE,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //BAR_CHART CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_INSIGHT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //BAR_CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //BAR_CHART CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.BAR_CHART_VALUE_LABEL,
              name: this.collapse.BAR_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_VALUE_LABEL,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_ONLY,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_FAMILY,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_SIZE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_WEIGHT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_VALUE_LABEL_FONT_COLOR,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //BAR_CHART CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.BAR_CHART_X_AXIS,
              name: this.collapse.BAR_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_NAME,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_TEXT,
                  //BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                  //BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_FAMILY,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_SIZE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_FONT_COLOR,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_X_AXIS_LABEL,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_WRAP,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_SIZE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_ROTATE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LABEL_FONT_COLOR,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOGARITHMIC_SCALE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_MIN,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_GRID_LINE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //BAR_CHART CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.BAR_CHART_Y_AXIS,
              name: this.collapse.BAR_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_NAME,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_NAME_TEXT,
                  //BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN,
                  //BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_VERTICAL_ALIGN,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_LOCATION,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_SIZE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_NAME_FONT_COLOR,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_Y_AXIS_LABEL,
                  //BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN,
                  //BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_FONT_COLOR,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER,

                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_POSITION,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_LINE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LINE_STYLE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //BAR_CHART CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.BAR_CHART_LEGEND,
              name: this.collapse.BAR_CHART_LEGEND,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SHOW_LEGEND,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_MAX_CHARACTER,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_POSITION,
                  // BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_VERTICAL_ALIGN,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_FAMILY,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_SIZE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_WEIGHT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //BAR CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //BAR_CHART CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_START,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_END,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_START,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_END,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //BAR_CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //BAR_CHART CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.BAR
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.barChartComponentsMapping, [
                    BAR_CHART_PROPERTIES_ENUM.BAR_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.BAR_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.BAR_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //BAR_CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.BAR_CHART_AUTO_REFRESH,
              name: this.collapse.BAR_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.BAR_CHART_AUTO_REFRESH)
                    ? BAR_CHART_PROPERTIES_ENUM.BAR_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //BAR CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BAR
          ? [
            {
              title: this.collapse.BAR_CHART_TITLE,
              name: this.collapse.BAR_CHART_TITLE,
              components: Object.values(
                _.pick(this.barChartComponentsMapping, [
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_VALUE,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  BAR_CHART_PROPERTIES_ENUM.BAR_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //PIE CHART CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.pieChartComponentsMapping, [
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_INSIGHT,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
          //PIE CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //PIE CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.collapse.PIE_CHART_VALUE_LABEL,
              name: this.collapse.PIE_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.pieChartComponentsMapping, [
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_DOUGHNUT,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_VALUE_LABEL,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_ONLY,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_FAMILY,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_SIZE,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_WEIGHT,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_FONT_COLOR,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_VALUE_LABEL_ALIGNTO,
                ])
              ),
            },
          ]
          : []),
        //PIE CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.collapse.PIE_CHART_LEGEND,
              name: this.collapse.PIE_CHART_LEGEND,
              components: Object.values(
                _.pick(this.pieChartComponentsMapping, [
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_LEGEND,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_MAX_CHARACTER,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_POSITION,
                  // PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_VERTICAL_ALIGN,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_FAMILY,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_SIZE,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_WEIGHT,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //PIE CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.pieChartComponentsMapping, [
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //PIE CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //PIE CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.PIE
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.pieChartComponentsMapping, [
                    PIE_CHART_PROPERTIES_ENUM.PIE_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.PIE_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.PIE_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),

        //PIE CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.collapse.PIE_CHART_AUTO_REFRESH,
              name: this.collapse.PIE_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.pieChartComponentsMapping, [
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.PIE_CHART_AUTO_REFRESH)
                    ? PIE_CHART_PROPERTIES_ENUM.PIE_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //PIE CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIE
          ? [
            {
              title: this.collapse.PIE_CHART_TITLE,
              name: this.collapse.PIE_CHART_TITLE,
              components: Object.values(
                _.pick(this.pieChartComponentsMapping, [
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_VALUE,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  PIE_CHART_PROPERTIES_ENUM.PIE_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE COLUMN BASED FORMATTING GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.DATA_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: `${this.$t("dashboardPropertiesLocale.title.general")}`,
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.datatableColumnBasedFormattingMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_NAME,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_SCREENTIP,
                  datatablePropertiesEnum.CHART_DATA_TABLE_CONDITIONAL_FORMATTING_BUTTON,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE COLUMN BASED FORMATTING ROW PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.DATA_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: `${this.$t("panelProperties.cell")}`,
              name: this.collapse.DATA_TABLE_CHART_ROWS,
              components: Object.values(
                _.pick(this.datatableColumnBasedFormattingMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FORMAT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH,
                  this?.selectedColumnField?.fieldUsageType !== DatamodelContextDefaults.USAGE_TYPES.MEASURE ?
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_MAX_CHARACTER : null,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_TEXT_WRAP,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
                  datatablePropertiesEnum.CHART_SELECT_ROW_ALIGN,
                  datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE COLUMN BASED FORMATTING HEADER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.DATA_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_HEADER,
              name: this.collapse.DATA_TABLE_CHART_HEADER,
              components: Object.values(
                _.pick(this.datatableColumnBasedFormattingMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_TEXT_WRAP,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
                  datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE COLUMN BASED FORMATTING SUBTOTAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.DATA_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE &&
          this?.selectedColumnField?.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_TOTALS,
              name: this.collapse.DATA_TABLE_CHART_TOTALS,
              components: Object.values(
                _.pick(this.datatableColumnBasedFormattingMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATION_FUNCTION,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: `${this.$t("dashboardPropertiesLocale.title.general")}`,
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PAGING,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_TEXT_WRAP,
                  datatablePropertiesEnum.CHART_SELECT_PAGE_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_GRID_LINES,
                  datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SQL,
                  datatablePropertiesEnum.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH,
                ])
              ),
            },
          ]
          : []),

          //DATA TABLE CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE INTERACTIONS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_INTERACTIONS,
              name: this.collapse.DATA_TABLE_CHART_INTERACTIONS,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_RESIZING,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_REORDERING,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SELECTION,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_FILTERING,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SORTING,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CONTEXT_MENU,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SEARCH,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE HEADER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_HEADER,
              name: this.collapse.DATA_TABLE_CHART_HEADER,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
                  datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE ROWS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_ROWS,
              name: this.collapse.DATA_TABLE_CHART_ROWS,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
                  datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ROW_HEIGHT,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE TOTALS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_TOTALS,
              name: this.collapse.DATA_TABLE_CHART_TOTALS,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL,
                  datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_FAMILY,
                  datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_WEIGHT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_COLOR,
                  datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE SUBTOTALS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_SUBTOTALS,
              name: this.collapse.DATA_TABLE_CHART_SUBTOTALS,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_POSITION,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE SECTIONS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_SECTION,
              name: this.collapse.DATA_TABLE_CHART_SECTION,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_INDENT_CELL,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_FAMILY,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_SIZE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_WEIGHT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_COLOR,
                  datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_BACKGROUND_COLOR,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXPORT_MENU,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CSV_EXPORT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PDF_EXPORT,
                ])
              ),
            },
          ]
          : []),

        //DATA TABLE DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.TABLE
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.datatableComponentsMapping, [
                    datatablePropertiesEnum.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH,
                    this.componentStyle.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH || this.componentStyle.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH === undefined ?
                    datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST : null,
                    datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
            : []),

        //DATA_TABLE  AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_AUTO_REFRESH,
              name: this.collapse.DATA_TABLE_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.CHART_DATA_TABLE_AUTO_REFRESH)
                    ? datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //DATA_TABLE OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TABLE
          ? [
            {
              title: this.collapse.CHART_DATA_TABLE_TITLE,
              name: this.collapse.CHART_DATA_TABLE_TITLE,
              components: Object.values(
                _.pick(this.datatableComponentsMapping, [
                  datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE,
                  datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  datatablePropertiesEnum.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //FILTER_TEXT CHART PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === dashboardFilterTypes.FILTER_TEXT
          ? [
            {
              title: this.$t("dashboardPropertiesLocale.title.general"),
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.textFilterComponentsMapping, [
                  TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_MANDATORY_SELECTION,
                  TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_APPLY_TO_TABS,
                  TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_SELECTION_ACTION,
                  TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_DEFAULT_SELECTION,
                  TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_UNIQUE_NAME,
                  TEXT_FILTER_PROPERTIES_ENUM.TEXT_FILTER_TAB_INDEX
                ])
              ),
            },
          ]
          : []),

        //FILTER_DATE CHART PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === dashboardFilterTypes.FILTER_DATE
          ? [
            {
              title: this.$t("dashboardPropertiesLocale.title.general"),
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.dateFilterComponentsMapping, [
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MANDATORY_SELECTION,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_ALLOW_MULTISELECT,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_APPLY_TO_TABS,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_START_DATE,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_RANGE_SELECTOR_END_DATE,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_MAX_RANGE,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_DEFAULT_SELECTION,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SELECTION_ACTION,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_SHOW_QUICK_OPTIONS,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_UNIQUE_NAME,
                  DATE_FILTER_PROPERTIES_ENUM.DATE_FILTER_TAB_INDEX
                ])
              ),
            },
          ]
          : []),
        //FILTER_SELECTLIST CHART PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === dashboardFilterTypes.FILTER_SELECT_LIST
          ? [
            {
              title: this.$t("dashboardPropertiesLocale.title.general"),
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.selectListFilterComponentsMapping, [
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_MANDATORY_SELECTION,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_ALLOW_MULTISELECT,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SHOW_SQL,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_APPLY_TO_TABS,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DIRECTION,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_DEFAULT_SELECTION,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_SELECTION_ACTION,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_UNIQUE_NAME,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_TAB_INDEX
                ])
              ),
            },
            //FILTER_SELECTLIST  VALUE NOT:DURUMA GÖRE KALDIRILACAK!
            // {
            //   title: this.collapse.FILTER_SELECT_LIST_VALUE,
            //   name: this.collapse.FILTER_SELECT_LIST_VALUE,
            //   components:
            //     Object.values(_.pick(this.selectListFilterComponentsMapping, [
            //       SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_FAMILY,
            //       SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_WEIGHT,
            //       SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_SIZE,
            //       SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_ALIGN,
            //       SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_FONT_COLOR,
            //       SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_BACKGROUND_COLOR,

            //     ])
            //     ),
            // },
            //FILTER_SELECTLIST VALUE LABEL
            {
              title: this.$t("dashboardPropertiesLocale.title.valueLabel"),
              name: this.collapse.FILTER_SELECT_LIST_VALUE_LABEL,
              components: Object.values(
                _.pick(this.selectListFilterComponentsMapping, [
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_SHOW_LABEL,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_LABEL_ALIGN,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_FAMILY,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR,
                  SELECT_LIST_FILTER_PROPERTIES_ENUM.SELECT_LIST_FILTER_TAB_INDEX
                ])
              ),
            },
          ]
          : []),

        //FILTER_DROPDOWN CHART PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === dashboardFilterTypes.FILTER_DROPDOWN
          ? [
            {
              title: this.$t("dashboardPropertiesLocale.title.general"),
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.dropdownFilterComponentsMapping, [
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_MANDATORY_SELECTION,
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_ALLOW_MULTISELECT,
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SHOW_SQL,
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_APPLY_TO_TABS,
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_DEFAULT_SELECTION,
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_SELECTION_ACTION,
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_UNIQUE_NAME,
                  DROPDOWN_FILTER_PROPERTIES_ENUM.DROPDOWN_FILTER_TAB_INDEX
                ])
              ),
            },
          ]
          : []),

          //SELECTOR_DATAITEM CHART PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SELECTOR_DATA_ITEM_CHART
          ? [
            {
              title: this.$t("dashboardPropertiesLocale.title.general"),
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.selectorDataitemChartComponentsMapping, [
                  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_UNIQUE_NAME,
                  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_MANDATORY_SELECTION,
                  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_ALLOW_MULTISELECT,
                  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_DEFAULT_VALUE,
                  SELECTOR_DATA_ITEM_CHART_PROPERTIES_ENUM.SELECTOR_DATA_ITEM_CHART_SHOW_LIST
                ])
              ),
            },
          ]
          : []),

        //SUMMARY CHART PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SUMMARY
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_INSIGHT,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_SQL,
                ])
              ),
            },
          //SUMMARY CHART CACHE PROPERTIES
          ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.SUMMARY
            ? [
              {
                title: this.$t("generalPages.Cache Options"),
                name: this.collapse.CACHE_OPTIONS,
                components: Object.values(
                  _.pick(this.allCardComponentsMapping, [
                    chartPropertiesEnum.CHART_USE_CACHE,
                    chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                    chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                    chartPropertiesEnum.BUTTON_REFRESH_CACHE
                  ])
                ),
              },
            ]
            : []),
            {
              title: `${this.$t("dashboardPropertiesLocale.title.value")}`,
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_FAMILY,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_SIZE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_WEIGHT,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_ALIGN,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_FONT_COLOR,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_SHOW_TREND_COLOR,
                ])
              ),
            },
        

            {
              title: this.collapse.SUMMARY_CHART_VALUE_LABEL,
              name: this.collapse.SUMMARY_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SHOW_VALUE_LABEL,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_FAMILY,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_SIZE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_WEIGHT,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_ALIGN,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_VALUE_LABEL_FONT_COLOR,
                ])
              ),
            },
            {
              title: this.collapse.SUMMARY_CHART_TARGET_VALUE,
              name: this.collapse.SUMMARY_CHART_TARGET_VALUE,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_FAMILY,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_SIZE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_WEIGHT,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_ALIGN,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_FONT_COLOR,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_SHOW_TREND_COLOR,
                ])
              ),
            },
            {
              title: this.collapse.SUMMARY_CHART_TARGET_VALUE_LABEL,
              name: this.collapse.SUMMARY_CHART_TARGET_VALUE_LABEL,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_SHOW_VALUE_LABEL,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_FAMILY,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_SIZE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_WEIGHT,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_ALIGN,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TARGET_VALUE_LABEL_FONT_COLOR,
                ])
              ),
            },
            
            {
              title: this.collapse.SUMMARY_CHART_AUTO_REFRESH,
              name: this.collapse.SUMMARY_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.SUMMARY_CHART_AUTO_REFRESH)
                    ? SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_AUTO_REFRESH_CUSTOM : null
                ])
              ),
            },
            {
              title: this.collapse.SUMMARY_CHART_TITLE,
              name: this.collapse.SUMMARY_CHART_TITLE,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_VALUE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
            {
              title: this.collapse.SUMMARY_CHART_TREND,
              name: this.collapse.SUMMARY_CHART_TREND,
              components: Object.values(
                _.pick(this.summaryChartComponentsMapping, [
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_TREND_ICON,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_SHOW_DIFFERENCE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_TREND_CALCULATION_TYPE,
                  SUMMARY_CHART_PROPERTIES_ENUM.SUMMARY_CHART_SEPERATION_VALUES
                ])
              ),
            },
          ]
          : []),

         //TEXT EDITOR/RICH TEXT GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === otherChartType.TEXT_EDITOR
            ? [
              {
                title: this.collapse.GENERAL,
                name: this.collapse.GENERAL,
                components: Object.values(
                  _.pick(this.textEditorComponentsMapping, [
                    TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_SHOW_IMAGE,
                    TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_COUNT,
                    TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_MAX_IMAGE_SIZE,
                  ])
                ),
              },
              {
                title: this.collapse.TEXT_EDITOR_TITLE,
                name: this.collapse.TEXT_EDITOR_TITLE,
                components: Object.values(
                  _.pick(this.textEditorComponentsMapping, [
                    TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_SHOW_VALUE,
                    TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_VALUE,
                    TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    TEXT_EDITOR_PROPERTIES_ENUM.TEXT_EDITOR_GROUP_MULTIPLE_VALUES,
                  ])
                ),
              },
            ]
            : []),

        //AREA CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_INSIGHT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_SQL,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SPLINE_MODE,
                ])
              ),
            },
          ]
          : []),
        //AREA CHART CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //AREA CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.AREA_CHART_VALUE_LABEL,
              name: this.collapse.AREA_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_VALUE_LABEL,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_ONLY,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_VALUE_LABEL_FONT_FAMILY,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_VALUE_LABEL_FONT_SIZE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_VALUE_LABEL_FONT_WEIGHT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_VALUE_LABEL_ROTATE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_VALUE_LABEL_FONT_COLOR,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //AREA CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.AREA_CHART_X_AXIS,
              name: this.collapse.AREA_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_X_AXIS_LINE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_X_AXIS_NAME,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_NAME_TEXT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_NAME_FONT_FAMILY,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_NAME_FONT_SIZE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_NAME_FONT_COLOR,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_X_AXIS_LABEL,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LABEL_WRAP,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LABEL_FONT_SIZE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LABEL_ROTATE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LABEL_FONT_COLOR,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LABEL_MAX_CHARACTER,
                ])
              ),
            },
          ]
          : []),
        //AREA CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.AREA_CHART_Y_AXIS,
              name: this.collapse.AREA_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_Y_AXIS_NAME,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_NAME_TEXT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_NAME_LOCATION,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_NAME_FONT_SIZE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_NAME_FONT_COLOR,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_Y_AXIS_LABEL,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_LABEL_FONT_COLOR,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_MIN,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_POSITION,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_SHOW_LINE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_LINE_STYLE_COLOR,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_GRID_LINE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //AREA CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.AREA_CHART_LEGEND,
              name: this.collapse.AREA_CHART_LEGEND,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SHOW_LEGEND,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_LEGEND_MAX_CHARACTER,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_LEGEND_POSITION,
                  // AREA_CHART_PROPERTIES_ENUM.AREA_CHART_LEGEND_VERTICAL_ALIGN,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_LEGEND_FONT_FAMILY,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_LEGEND_FONT_SIZE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_LEGEND_FONT_WEIGHT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //AREA CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //AREA CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_DATA_ZOOM_START,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_DATA_ZOOM_END,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_DATA_ZOOM_START,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_DATA_ZOOM_END,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //AREA CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //AREA CHART DRILL THROUGH
          ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.AREA_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.areaChartComponentsMapping, [
                    AREA_CHART_PROPERTIES_ENUM.AREA_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.AREA_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.AREA_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    AREA_CHART_PROPERTIES_ENUM.AREA_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    AREA_CHART_PROPERTIES_ENUM.AREA_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //AREA CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.AREA_CHART_AUTO_REFRESH,
              name: this.collapse.AREA_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.AREA_CHART_AUTO_REFRESH)
                    ? AREA_CHART_PROPERTIES_ENUM.AREA_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //AREA CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.AREA_CHART
          ? [
            {
              title: this.collapse.AREA_CHART_TITLE,
              name: this.collapse.AREA_CHART_TITLE,
              components: Object.values(
                _.pick(this.areaChartComponentsMapping, [
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_DYNAMIC_TITLE_VALUE,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  AREA_CHART_PROPERTIES_ENUM.AREA_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //TREEMAP CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.treemapComponentsMapping, [
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_INSIGHT,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //TREEMAP CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.treemapComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //TREEMAP VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.collapse.TREEMAP_VALUE_LABEL,
              name: this.collapse.TREEMAP_VALUE_LABEL,
              components: Object.values(
                _.pick(this.treemapComponentsMapping, [
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_VALUE_LABEL,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_ONLY,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_FAMILY,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_SIZE,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_WEIGHT,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_VALUE_LABEL_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //TREEMAP LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.collapse.TREEMAP_LEGEND,
              name: this.collapse.TREEMAP_LEGEND,
              components: Object.values(
                _.pick(this.treemapComponentsMapping, [
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_SHOW_LEGEND,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_MAX_CHARACTER,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_POSITION,
                  // TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_VERTICAL_ALIGN,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_FAMILY,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_SIZE,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_WEIGHT,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //TREEMAP PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.treemapComponentsMapping, [
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //TREEMAP CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //TREEMAP CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.TREEMAP_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.treemapComponentsMapping, [
                    TREEMAP_PROPERTIES_ENUM.TREEMAP_ALLOW_DRILL_THROUGH,
                    this.componentStyle.TREEMAP_ALLOW_DRILL_THROUGH || this.componentStyle.TREEMAP_ALLOW_DRILL_THROUGH === undefined ?
                    TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_DASHBOARD_LIST : null,
                    TREEMAP_PROPERTIES_ENUM.TREEMAP_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),

        //TREEMAP CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.collapse.TREEMAP_AUTO_REFRESH,
              name: this.collapse.TREEMAP_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.treemapComponentsMapping, [
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.TREEMAP_AUTO_REFRESH)
                    ? TREEMAP_PROPERTIES_ENUM.TREEMAP_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //TREEMAP CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.TREEMAP_CHART
          ? [
            {
              title: this.collapse.TREEMAP_TITLE,
              name: this.collapse.TREEMAP_TITLE,
              components: Object.values(
                _.pick(this.treemapComponentsMapping, [
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_SHOW_VALUE,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_VALUE,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  TREEMAP_PROPERTIES_ENUM.TREEMAP_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //HEATMAP CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_INSIGHT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //HEATMAP CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //HEATMAP VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.HEATMAP_VALUE_LABEL,
              name: this.collapse.HEATMAP_VALUE_LABEL,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VALUE_LABEL,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_ONLY,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_FAMILY,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_SIZE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_WEIGHT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VALUE_LABEL_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //HEATMAP X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.HEATMAP_X_AXIS,
              name: this.collapse.HEATMAP_X_AXIS,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LINE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_NAME,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_FAMILY,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_SIZE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_WEIGHT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_NAME_FONT_COLOR,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_X_AXIS_LABEL,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_WRAP,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_FAMILY,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_SIZE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_WEIGHT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_ROTATE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LABEL_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //HEATMAP Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.HEATMAP_Y_AXIS,
              name: this.collapse.HEATMAP_Y_AXIS,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_NAME,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_LOCATION,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_FAMILY,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_SIZE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_WEIGHT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_NAME_FONT_COLOR,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_Y_AXIS_LABEL,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_FAMILY,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_SIZE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_WEIGHT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LABEL_FONT_COLOR,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_POSITION,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_SHOW_LINE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LINE_STYLE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //HEATMAP VISUALMAP PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.VISUALMAP,
              name: this.collapse.VISUALMAP,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SHOW_VISUALMAP,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_TYPE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_POSITION,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_FAMILY,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_SIZE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_WEIGHT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_VISUALMAP_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //HEATMAP PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //HEATMAP CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //HEATMAP CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.HEATMAP_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.heatmapComponentsMapping, [
                    HEATMAP_PROPERTIES_ENUM.HEATMAP_ALLOW_DRILL_THROUGH,
                    this.componentStyle.HEATMAP_ALLOW_DRILL_THROUGH || this.componentStyle.HEATMAP_ALLOW_DRILL_THROUGH === undefined ?
                    HEATMAP_PROPERTIES_ENUM.HEATMAP_DRILL_THROUGH_DASHBOARD_LIST : null,
                    HEATMAP_PROPERTIES_ENUM.HEATMAP_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //HEATMAP CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_SHOW_DATA_ZOOM,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_DATA_ZOOM_START,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_DATA_ZOOM_END,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_LOCK_DATA_ZOOM,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_X_AXIS_DATA_ZOOM_PALETTE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_SHOW_DATA_ZOOM,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_DATA_ZOOM_START,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_DATA_ZOOM_END,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_LOCK_DATA_ZOOM,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //HEATMAP AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.HEATMAP_AUTO_REFRESH,
              name: this.collapse.HEATMAP_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.HEATMAP_AUTO_REFRESH)
                    ? HEATMAP_PROPERTIES_ENUM.HEATMAP_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //HEATMAP CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.HEATMAP_CHART
          ? [
            {
              title: this.collapse.HEATMAP_TITLE,
              name: this.collapse.HEATMAP_TITLE,
              components: Object.values(
                _.pick(this.heatmapComponentsMapping, [
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_SHOW_VALUE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_VALUE,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  HEATMAP_PROPERTIES_ENUM.HEATMAP_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //SCATTER CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_INSIGHT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //SCATTER CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //SCATTER CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.SCATTER_CHART_VALUE_LABEL,
              name: this.collapse.SCATTER_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_VALUE_LABEL,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_ONLY,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_FAMILY,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_SIZE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_ROTATE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_VALUE_LABEL_FONT_COLOR,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //SCATTER CHART CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.SCATTER_CHART_X_AXIS,
              name: this.collapse.SCATTER_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_NAME,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_SIZE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_NAME_FONT_COLOR,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_X_AXIS_LABEL,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_WRAP,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_ROTATE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_MIN,
                ])
              ),
            },
          ]
          : []),
        //SCATTER_CHART CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.SCATTER_CHART_Y_AXIS,
              name: this.collapse.SCATTER_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_NAME,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_LOCATION,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_Y_AXIS_LABEL,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_POSITION,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_SHOW_LINE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_MIN,
                ])
              ),
            },
          ]
          : []),
        //SCATTER_CHART CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.SCATTER_CHART_LEGEND,
              name: this.collapse.SCATTER_CHART_LEGEND,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SHOW_LEGEND,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_MAX_CHARACTER,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_POSITION,
                  // SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_VERTICAL_ALIGN,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_FAMILY,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_SIZE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_WEIGHT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //SCATTER PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //SCATTER CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
          : []),
        //SCATTER CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.SCATTER_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.scatterChartComponentsMapping, [
                    SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.SCATTER_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.SCATTER_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //SCATTER CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_DATA_ZOOM_START,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_DATA_ZOOM_END,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_DATA_ZOOM_START,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_DATA_ZOOM_END,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //SCATTER AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.SCATTER_CHART_AUTO_REFRESH,
              name: this.collapse.SCATTER_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.SCATTER_CHART_AUTO_REFRESH)
                    ? SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //SCATTER CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SCATTER_CHART
          ? [
            {
              title: this.collapse.SCATTER_CHART_TITLE,
              name: this.collapse.SCATTER_CHART_TITLE,
              components: Object.values(
                _.pick(this.scatterChartComponentsMapping, [
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_VALUE,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  SCATTER_CHART_PROPERTIES_ENUM.SCATTER_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //BUBBLE CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_INSIGHT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //BUBBLE CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //BUBBLE CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.BUBBLE_CHART_VALUE_LABEL,
              name: this.collapse.BUBBLE_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_VALUE_LABEL,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_ONLY,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_VALUE_LABEL_FONT_FAMILY,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_VALUE_LABEL_FONT_SIZE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_VALUE_LABEL_FONT_WEIGHT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_VALUE_LABEL_ROTATE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_VALUE_LABEL_FONT_COLOR,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //BUBBLE CHART CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.BUBBLE_CHART_X_AXIS,
              name: this.collapse.BUBBLE_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_X_AXIS_NAME,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_NAME_FONT_FAMILY,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_NAME_FONT_SIZE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_NAME_FONT_COLOR,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_X_AXIS_LABEL,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_WRAP,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_FONT_SIZE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_ROTATE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LABEL_FONT_COLOR,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LOGARITHMIC_SCALE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_MIN,
                ])
              ),
            },
          ]
          : []),
        //BUBBLE CHART CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.BUBBLE_CHART_Y_AXIS,
              name: this.collapse.BUBBLE_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_Y_AXIS_NAME,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_NAME_LOCATION,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_NAME_FONT_SIZE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_NAME_FONT_COLOR,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_Y_AXIS_LABEL,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_LABEL_FONT_COLOR,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_POSITION,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_SHOW_LINE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_LINE_STYLE_COLOR,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_MIN,
                ])
              ),
            },
          ]
          : []),
        //BUBBLE CHART CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.BUBBLE_CHART_LEGEND,
              name: this.collapse.BUBBLE_CHART_LEGEND,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_LEGEND,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_LEGEND_MAX_CHARACTER,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_LEGEND_POSITION,
                  // BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_LEGEND_VERTICAL_ALIGN,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_LEGEND_FONT_FAMILY,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_LEGEND_FONT_SIZE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_LEGEND_FONT_WEIGHT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //BUBBLE PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //BUBBLE CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
        : []),
        //BUBBLE CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.BUBBLE_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.bubbleChartComponentsMapping, [
                    BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.BUBBLE_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.BUBBLE_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //BUBBLE CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_DATA_ZOOM_START,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_DATA_ZOOM_END,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_START,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_END,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //BUBBLE AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.BUBBLE_CHART_AUTO_REFRESH,
              name: this.collapse.BUBBLE_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.BUBBLE_CHART_AUTO_REFRESH)
                    ? BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),
        //BUBBLE  OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.BUBBLE_CHART
          ? [
            {
              title: this.collapse.BUBBLE_CHART_TITLE,
              name: this.collapse.BUBBLE_CHART_TITLE,
              components: Object.values(
                _.pick(this.bubbleChartComponentsMapping, [
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_DYNAMIC_TITLE_VALUE,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //COMBINATION CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_INSIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //COMBINATION CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //COMBINATION CHART CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.COMBINATION_CHART_VALUE_LABEL,
              name: this.collapse.COMBINATION_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_VALUE_LABEL,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_ONLY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_VALUE_LABEL_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_VALUE_LABEL_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_VALUE_LABEL_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_VALUE_LABEL_ROTATE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_VALUE_LABEL_FONT_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //COMBINATION CHART CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.COMBINATION_CHART_X_AXIS,
              name: this.collapse.COMBINATION_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_X_AXIS_LINE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_X_AXIS_NAME,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_NAME_TEXT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_NAME_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_NAME_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_NAME_FONT_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_X_AXIS_LABEL,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LABEL_WRAP,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LABEL_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LABEL_ROTATE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LABEL_FONT_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LABEL_MAX_CHARACTER,
                ])
              ),
            },
          ]
          : []),
        //COMBINATION CHART CHART BAR AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.COMBINATION_CHART_Y_AXIS,
              name: this.collapse.COMBINATION_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_Y_AXIS_NAME,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_NAME_TEXT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_NAME_LOCATION,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_NAME_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_NAME_FONT_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_Y_AXIS_LABEL,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_LABEL_FONT_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_MIN,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_SHOW_LINE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_LINE_STYLE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //COMBINATION CHART CHART LINE AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.COMBINATION_CHART_SECOND_Y_AXIS,
              name: this.collapse.COMBINATION_CHART_SECOND_Y_AXIS,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_SECOND_Y_AXIS_NAME,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_NAME_TEXT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_NAME_LOCATION,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_NAME_FONT_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_SECOND_Y_AXIS_LABEL,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_LABEL_FONT_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_LOGARITHMIC_SCALE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_MIN,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_SHOW_LINE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SECOND_Y_AXIS_LINE_STYLE_COLOR,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_GRID_LINE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //COMBINATION CHART CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.COMBINATION_CHART_LEGEND,
              name: this.collapse.COMBINATION_CHART_LEGEND,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SHOW_LEGEND,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_LEGEND_MAX_CHARACTER,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_LEGEND_POSITION,
                  // COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_LEGEND_VERTICAL_ALIGN,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_LEGEND_FONT_FAMILY,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_LEGEND_FONT_SIZE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_LEGEND_FONT_WEIGHT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //COMBINATION PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //COMBINATION CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
        : []),
        //COMBINATION CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.COMBINATION_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.combinationChartComponentsMapping, [
                    COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.COMBINATION_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.COMBINATION_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //COMBINATION CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_DATA_ZOOM_START,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_DATA_ZOOM_END,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_DATA_ZOOM_START,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_DATA_ZOOM_END,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //COMBINATION CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.COMBINATION_CHART_AUTO_REFRESH,
              name: this.collapse.COMBINATION_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.COMBINATION_CHART_AUTO_REFRESH)
                    ? COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //COMBINATION CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.COMBINATION_CHART
          ? [
            {
              title: this.collapse.COMBINATION_CHART_TITLE,
              name: this.collapse.COMBINATION_CHART_TITLE,
              components: Object.values(
                _.pick(this.combinationChartComponentsMapping, [
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_DYNAMIC_TITLE_VALUE,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  COMBINATION_CHART_PROPERTIES_ENUM.COMBINATION_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //GAUGE CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.gaugeChartComponentsMapping, [
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_INSIGHT,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),

        //GAUGE CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),

        //GAUGE CHART AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.collapse.GAUGE_CHART_PROGRESS_BAR,
              name: this.collapse.GAUGE_CHART_PROGRESS_BAR,
              components: Object.values(
                _.pick(this.gaugeChartComponentsMapping, [
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_WIDTH,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_FAMILY,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_SIZE,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_WEIGHT,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_PROGRESS_BAR_LABEL_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),

        //GAUGE CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.collapse.GAUGE_CHART_TITLE,
              name: this.collapse.GAUGE_CHART_TITLE,
              components: Object.values(
                _.pick(this.gaugeChartComponentsMapping, [
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_FAMILY,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_SIZE,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_WEIGHT,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_TITLE_FONT_COLOR,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_VALUE,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //GAUGE CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.collapse.GAUGE_CHART_VALUE_LABEL,
              name: this.collapse.GAUGE_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.gaugeChartComponentsMapping, [
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_FAMILY,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_SIZE,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_WEIGHT,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_VALUE_LABEL_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //GAUGE CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
        : []),
        //GAUGE CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.GAUGE_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.gaugeChartComponentsMapping, [
                    GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.GAUGE_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.GAUGE_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),

        //GAUGE CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.collapse.GAUGE_CHART_AUTO_REFRESH,
              name: this.collapse.GAUGE_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.gaugeChartComponentsMapping, [
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.GAUGE_CHART_AUTO_REFRESH)
                    ? GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),
          
        //PIVOT TABLE GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRID_LINES,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_ROW_PAGING,
                  this.checkPropertyIsActiveOrUndefined(this.componentStyle.PIVOT_TABLE_ENABLE_ROW_PAGING) ?
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROWS_PER_PAGE : null,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_COLS_PAGING,
                  this.checkPropertyIsActiveOrUndefined(this.componentStyle.PIVOT_TABLE_ENABLE_COLS_PAGING) ?
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLS_PER_PAGE : null,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_HEIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_WIDTH,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILLED_MEMBERS,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CONTEXT_MENU,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SHOW_FIELD_LIST,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_INSIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_HEATMAP,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ENABLE_VALUE_SORTING,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SELECTION,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SHOW_SQL,
                  this.pivotTableComponentsMapping[
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SELECTION
                  ].value
                    ? PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_MODE
                    : null,
                  this.pivotTableComponentsMapping[
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_SELECTION
                  ].value
                    ? PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECTION_TYPE
                    : null,
                ])
              ),
            },

          ]
          : []),

        //PIVOT TABLE CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE COLUMN PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.PIVOT_TABLE_CHART_COLUMN_TITLE,
              name: this.collapse.PIVOT_TABLE_CHART_COLUMN_TITLE,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_FAMILY,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_SIZE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_WEIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TITLE_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE ROWS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.PIVOT_TABLE_CHART_ROW_TITLE,
              name: this.collapse.PIVOT_TABLE_CHART_ROW_TITLE,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_FAMILY,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_SIZE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_WEIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TITLE_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE MEASURE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.PIVOT_TABLE_CHART_MEASURE,
              name: this.collapse.PIVOT_TABLE_CHART_MEASURE,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_FAMILY,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_SIZE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_WEIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_MEASURE_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE TOTAL TITLE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.PIVOT_TABLE_CHART_TOTAL_TITLE,
              name: this.collapse.PIVOT_TABLE_CHART_TOTAL_TITLE,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_FAMILY,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_SIZE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_WEIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_TITLE_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE TOTAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.PIVOT_TABLE_CHART_TOTAL,
              name: this.collapse.PIVOT_TABLE_CHART_TOTAL,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTALS,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_POSITION,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SUB_TOTALS,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_FAMILY,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_SIZE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_WEIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GRAND_TOTAL_MEASURE_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXPORT_MENU,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_EXCEL_EXPORT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_CSV_EXPORT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_PDF_EXPORT,
                ])
              ),
            },
          ]
          : []),

          //PIVOT TABLE DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.DRILL_THROUGH,
              name: this.collapse.DRILL_THROUGH,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ALLOW_DRILL_THROUGH,
                  this.componentStyle.PIVOT_TABLE_ALLOW_DRILL_THROUGH || this.componentStyle.PIVOT_TABLE_ALLOW_DRILL_THROUGH === undefined ?
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_DASHBOARD_LIST : null,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.PIVOT_TABLE_AUTO_REFRESH,
              name: this.collapse.PIVOT_TABLE_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.PIVOT_TABLE_AUTO_REFRESH)
                    ? PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AUTO_REFRESH_CUSTOM : null
                ])
              ),
            },
          ]
          : []),

        //PIVOT TABLE OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.PIVOT_TABLE_TITLE,
              name: this.collapse.PIVOT_TABLE_TITLE,
              components: Object.values(
                _.pick(this.pivotTableComponentsMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_SHOW_VALUE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_VALUE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),
          
        //PIVOT TABLE COLUMN BASED FORMATTING GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.PIVOT_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: `${this.$t("dashboardPropertiesLocale.title.general")}`,
              name: this.collapse.CHART,
              components: Object.values(
                _.pick(this.pivotTableColumnBasedFormattingMapping, [
                  this?.selectedColumnField?.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE ?
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_NAME: null,
                  this?.selectedColumnField?.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE ?
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SCREENTIP: null,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_CONDITIONAL_FORMATTING_BUTTON,
                ])
              ),
            },
          ]
          : []),
          
        //PIVOT TABLE COLUMN BASED FORMATTING ROW PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.PIVOT_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE &&
          this.selectedColumnField?.boxKey !== BOX_KEY_ENUM.COLUMN.KEY &&
          this.selectedColumnField?.boxKey !== BOX_KEY_ENUM.ROW.KEY
          ? [
            {
              title: `${this.$t("panelProperties.cell")}`,
              name: this.collapse.DATA_TABLE_CHART_ROWS,
              components: Object.values(
                _.pick(this.pivotTableColumnBasedFormattingMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FORMAT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_MAX_CHARACTER,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TEXT_WRAP,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_FAMILY,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_SIZE,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_WEIGHT,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_FONT_COLOR,
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_BACKGROUND_COLOR,
                ])
              ),
            },
          ]
          : []),
        //PIVOT TABLE COLUMN BASED FORMATTING HEADER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.PIVOT_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_HEADER,
              name: this.collapse.DATA_TABLE_CHART_HEADER,
              components: Object.values(
                _.pick(
                  this.pivotTableColumnBasedFormattingMapping,
                  [
                    this?.selectedColumnField?.boxKey === BOX_KEY_ENUM.ROW.KEY ?
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_MAX_CHARACTER : null,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_FAMILY,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_SIZE,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_WEIGHT,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECT_COLUMN_ALIGN,
                    PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_COLOR,
                  ].map((x) => x)
                )
              ),
            },
          ]
          : []),

        //PIVOT TABLE COLUMN BASED FORMATTING TOTAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.PIVOT_TABLE_COLUMN &&
          this.isRoutePanel &&
          this.chartType === chartTypes.PIVOT_TABLE &&
          this?.selectedColumnField?.fieldUsageType === DatamodelContextDefaults.USAGE_TYPES.MEASURE
          ? [
            {
              title: this.collapse.DATA_TABLE_CHART_TOTALS,
              name: this.collapse.DATA_TABLE_CHART_TOTALS,
              components: Object.values(
                _.pick(this.pivotTableColumnBasedFormattingMapping, [
                  PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AGGREGATION_FUNCTION,
                ])
              ),
            },
          ]
          : []),

        //GAUGE CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GAUGE_CHART
          ? [
            {
              title: this.collapse.GAUGE_CHART_SEPERATION_PERCENTAGES,
              name: this.collapse.GAUGE_CHART_SEPERATION_PERCENTAGES,
              components: Object.values(
                _.pick(this.gaugeChartComponentsMapping, [
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_ENABLED,
                  GAUGE_CHART_PROPERTIES_ENUM.GAUGE_CHART_SEPERATION_VALUES,
                ])
              ),
            },
          ]
          : []),

        //RADAR CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.radarChartComponentsMapping, [
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_INSIGHT,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //RADAR CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //RADAR CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.RADAR_CHART_VALUE_LABEL,
              name: this.collapse.RADAR_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.radarChartComponentsMapping, [
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_VALUE_LABEL,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_ONLY,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_VALUE_LABEL_FONT_FAMILY,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_VALUE_LABEL_FONT_SIZE,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_VALUE_LABEL_FONT_WEIGHT,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_VALUE_LABEL_FONT_COLOR,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //RADAR CHART AXIS NAME PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.RADAR_CHART_AXIS_NAME,
              name: this.collapse.RADAR_CHART_AXIS_NAME,
              components: Object.values(
                _.pick(this.radarChartComponentsMapping, [
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_AXIS_NAME,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_AXIS_NAME_FONT_FAMILY,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_AXIS_NAME_FONT_SIZE,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_AXIS_NAME_FONT_WEIGHT,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_AXIS_NAME_FONT_COLOR,

                ])
              ),
            },
          ]
          : []),
        //RADAR CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.RADAR_CHART_LEGEND,
              name: this.collapse.RADAR_CHART_LEGEND,
              components: Object.values(
                _.pick(this.radarChartComponentsMapping, [
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SHOW_LEGEND,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_LEGEND_MAX_CHARACTER,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_LEGEND_POSITION,
                  // RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_LEGEND_VERTICAL_ALIGN,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_LEGEND_FONT_FAMILY,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_LEGEND_FONT_SIZE,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_LEGEND_FONT_WEIGHT,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //RADAR PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.radarChartComponentsMapping, [
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //RADAR CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
        : []),
        //RADAR CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.RADAR_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.radarChartComponentsMapping, [
                    RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.RADAR_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.RADAR_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),

        //RADAR CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.RADAR_CHART_AUTO_REFRESH,
              name: this.collapse.RADAR_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.radarChartComponentsMapping, [
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.RADAR_CHART_AUTO_REFRESH)
                    ? RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //RADAR CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.RADAR_CHART
          ? [
            {
              title: this.collapse.RADAR_CHART_TITLE,
              name: this.collapse.RADAR_CHART_TITLE,
              components: Object.values(
                _.pick(this.radarChartComponentsMapping, [
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_DYNAMIC_TITLE_VALUE,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  RADAR_CHART_PROPERTIES_ENUM.RADAR_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //STACKED_COLUMN_CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_INSIGHT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_HUNDRED_PERCENT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //STACKED_COLUMN_CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //STACKED_COLUMN_CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.STACKED_COLUMN_CHART_VALUE_LABEL,
              name: this.collapse.STACKED_COLUMN_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_VALUE_LABEL,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_ONLY,
                  !this.componentStyle.STACKED_COLUMN_CHART_SHOW_HUNDRED_PERCENT ?
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_PERCENT_TYPE : null,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_FAMILY,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_SIZE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_VALUE_LABEL_ROTATE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_VALUE_LABEL_FONT_COLOR,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //STACKED_COLUMN_CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.STACKED_COLUMN_CHART_X_AXIS,
              name: this.collapse.STACKED_COLUMN_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_X_AXIS_LINE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_X_AXIS_NAME,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_NAME_TEXT,
                  //STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN,
                  //STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_NAME_VERTICAL_ALIGN,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_SIZE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_NAME_FONT_COLOR,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_X_AXIS_LABEL,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_WRAP,
                  //STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                  //STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_ROTATE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER,
                ])
              ),
            },
          ]
          : []),
        //STACKED_COLUMN_CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.STACKED_COLUMN_CHART_Y_AXIS,
              name: this.collapse.STACKED_COLUMN_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_Y_AXIS_NAME,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_NAME_TEXT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_NAME_LOCATION,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_Y_AXIS_LABEL,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_MIN,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_POSITION,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_SHOW_LINE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_GRID_LINE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //STACKED_COLUMN_CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.STACKED_COLUMN_CHART_LEGEND,
              name: this.collapse.STACKED_COLUMN_CHART_LEGEND,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SHOW_LEGEND,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_LEGEND_MAX_CHARACTER,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_LEGEND_POSITION,
                  // STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_LEGEND_VERTICAL_ALIGN,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_LEGEND_FONT_FAMILY,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_LEGEND_FONT_SIZE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_LEGEND_FONT_WEIGHT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //STACKED COLUMN CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //STACKED_COLUMN_CHART CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
        : []),
        //STACKED_COLUMN_CHART CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.STACKED_COLUMN_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.stackedColumnChartComponentsMapping, [
                    STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.STACKED_COLUMN_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.STACKED_COLUMN_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //STACKED_COLUMN_CHART CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_START,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_END,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_START,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_END,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //STACKED COLUMN CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.STACKED_COLUMN_CHART_AUTO_REFRESH,
              name: this.collapse.STACKED_COLUMN_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.STACKED_COLUMN_CHART_AUTO_REFRESH)
                    ? STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //STACKED COLUMN CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_COLUMN_CHART
          ? [
            {
              title: this.collapse.STACKED_COLUMN_CHART_TITLE,
              name: this.collapse.STACKED_COLUMN_CHART_TITLE,
              components: Object.values(
                _.pick(this.stackedColumnChartComponentsMapping, [
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_DYNAMIC_TITLE_VALUE,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  STACKED_COLUMN_CHART_PROPERTIES_ENUM.STACKED_COLUMN__CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //STACKED_BAR_CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_INSIGHT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_HUNDRED_PERCENT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //STACKED_BAR_CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.STACKED_BAR_CHART_VALUE_LABEL,
              name: this.collapse.STACKED_BAR_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_VALUE_LABEL,
                  !this.componentStyle.STACKED_BAR_CHART_SHOW_HUNDRED_PERCENT ?
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_PERCENT_TYPE : null,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_ONLY,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_VALUE_LABEL_FONT_FAMILY,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_VALUE_LABEL_FONT_SIZE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_VALUE_LABEL_FONT_WEIGHT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_VALUE_LABEL_FONT_COLOR,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //STACKED_BAR_CHART X AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.STACKED_BAR_CHART_X_AXIS,
              name: this.collapse.STACKED_BAR_CHART_X_AXIS,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_X_AXIS_NAME,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_NAME_TEXT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_NAME_FONT_FAMILY,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_NAME_FONT_SIZE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_NAME_FONT_WEIGHT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_NAME_FONT_COLOR,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_X_AXIS_LABEL,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_WRAP,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_FAMILY,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_SIZE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_ROTATE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LABEL_FONT_COLOR,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LOGARITHMIC_SCALE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_MIN,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_GRID_LINE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //STACKED_BAR_CHART Y AXIS PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.STACKED_BAR_CHART_Y_AXIS,
              name: this.collapse.STACKED_BAR_CHART_Y_AXIS,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_Y_AXIS_NAME,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_NAME_TEXT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_NAME_LOCATION,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_FAMILY,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_SIZE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_WEIGHT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_NAME_FONT_COLOR,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_Y_AXIS_LABEL,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_SIZE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_LABEL_FONT_COLOR,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_LABEL_MAX_CHARACTER,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_POSITION,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_SHOW_LINE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_LINE_STYLE_COLOR,
                ])
              ),
            },
          ]
          : []),
        //STACKED_BAR_CHART LEGEND PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.STACKED_BAR_CHART_LEGEND,
              name: this.collapse.STACKED_BAR_CHART_LEGEND,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SHOW_LEGEND,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_LEGEND_MAX_CHARACTER,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_LEGEND_POSITION,
                  // STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_LEGEND_VERTICAL_ALIGN,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_LEGEND_FONT_FAMILY,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_LEGEND_FONT_SIZE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_LEGEND_FONT_WEIGHT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_LEGEND_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //STACKED BAR CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //STACKED_BAR_CHART CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
        : []),
        //STACKED BAR CHART CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.STACKED_BAR_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.stackedBarChartComponentsMapping, [
                    STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.STACKED_BAR_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.STACKED_BAR_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),
        //STACKED_BAR_CHART CHART DATA ZOOM PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.DATA_ZOOM,
              name: this.collapse.DATA_ZOOM,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_SHOW_DATA_ZOOM,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_START,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_END,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_LOCK_DATA_ZOOM,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_SHOW_DATA_ZOOM,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_START,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_END,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_LOCK_DATA_ZOOM,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                ])
              ),
            },
          ]
        : []),
        //STACKED BAR CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.STACKED_BAR_CHART_AUTO_REFRESH,
              name: this.collapse.STACKED_BAR_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.STACKED_BAR_CHART_AUTO_REFRESH)
                    ? STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //STACKED BAR CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.STACKED_BAR_CHART
          ? [
            {
              title: this.collapse.STACKED_BAR_CHART_TITLE,
              name: this.collapse.STACKED_BAR_CHART_TITLE,
              components: Object.values(
                _.pick(this.stackedBarChartComponentsMapping, [
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_DYNAMIC_TITLE_VALUE,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  STACKED_BAR_CHART_PROPERTIES_ENUM.STACKED_BAR_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //GEO_MAP_CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_INSIGHT,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //GEO_MAP_CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //GEO MAP CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.VALUE_LABEL,
              name: this.collapse.VALUE_LABEL,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VALUE_LABEL,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_HIDE_OVERLAPPING_LABELS,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_ONLY,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_FAMILY,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_SIZE,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_WEIGHT,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VALUE_LABEL_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //GEO MAP CHART BORDER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.BORDER_SETTINGS,
              name: this.collapse.BORDER_SETTINGS,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_BORDER_COLOR,
                ])
              ),
            },
          ]
          : []),
        //GEO MAP SETTING PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.MAP_SETTINGS,
              name: this.collapse.MAP_SETTINGS,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COUNTRY,
                ])
              ),
            },
          ]
          : []),
        //GEO MAP VISUALMAP PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.VISUALMAP,
              name: this.collapse.VISUALMAP,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SHOW_VISUALMAP,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_TYPE,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VALUES,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_HORIZONTAL_ALIGN,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_VERTICAL_ALIGN,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_FAMILY,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_SIZE,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_WEIGHT,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_VISUALMAP_FONT_COLOR,
                ])
              ),
            },
          ]
          : []),
        //GEO MAP CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),

        //GEO MAP CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.GEO_MAP_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.geoMapComponentsMapping, [
                    GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.GEO_MAP_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.GEO_MAP_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),

        //GEO MAP CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.GEO_MAP_CHART_AUTO_REFRESH,
              name: this.collapse.GEO_MAP_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.GEO_MAP_CHART_AUTO_REFRESH)
                    ? GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //GEO MAP CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.GEO_MAP_CHART
          ? [
            {
              title: this.collapse.GEO_MAP_CHART_TITLE,
              name: this.collapse.GEO_MAP_CHART_TITLE,
              components: Object.values(
                _.pick(this.geoMapComponentsMapping, [
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_VALUE,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  GEO_MAP_CHART_PROPERTIES_ENUM.GEO_MAP_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

          //SANKEY CHART GENERAL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.collapse.GENERAL,
              name: this.collapse.GENERAL,
              components: Object.values(
                _.pick(this.sankeyChartComponentsMapping, [
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_SHOW_INSIGHT,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_SHOW_SQL,
                ])
              ),
            },
          ]
          : []),
        //SANKEY CHART CACHE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.$t("generalPages.Cache Options"),
              name: this.collapse.CACHE_OPTIONS,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  chartPropertiesEnum.CHART_USE_CACHE,
                  chartPropertiesEnum.CHART_USE_DEFAULT_CACHE_DURATION,
                  chartPropertiesEnum.CHART_CACHE_DURATION_INPUT,
                  chartPropertiesEnum.BUTTON_REFRESH_CACHE
                ])
              ),
            },
          ]
          : []),
        //SANKEY CHART VALUE LABEL PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.collapse.SANKEY_CHART_VALUE_LABEL,
              name: this.collapse.SANKEY_CHART_VALUE_LABEL,
              components: Object.values(
                _.pick(this.sankeyChartComponentsMapping, [
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_SHOW_VALUE_LABEL,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_SHOW_ONLY,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_VALUE_LABEL_FONT_FAMILY,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_VALUE_LABEL_FONT_SIZE,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_VALUE_LABEL_FONT_WEIGHT,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_VALUE_LABEL_FONT_COLOR,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
                ])
              ),
            },
          ]
          : []),
        //SANKEY CHART NODE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.collapse.SANKEY_CHART_NODE_PROPERTIES,
              name: this.collapse.SANKEY_CHART_NODE_PROPERTIES,
              components: Object.values(
                _.pick(this.sankeyChartComponentsMapping, [
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_NODE_ALIGN
                ])
              ),
            },
          ]
          : []),
        
        //SANKEY CHART PALETTE PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.collapse.PALETTE,
              name: this.collapse.PALETTE,
              hasPadding: false,
              components: Object.values(
                _.pick(this.sankeyChartComponentsMapping, [
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_SELECTED_COLOR_PALETTE,
                ])
              ),
            },
          ]
          : []),
        //SANKEY CHART EXPORT PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.collapse.CHART_EXPORT,
              name: this.collapse.CHART_EXPORT,
              components: Object.values(
                _.pick(this.allCardComponentsMapping, [
                  cardPropertiesEnum.CHART_ENABLE_EXPORT_MENU,
                  cardPropertiesEnum.CHART_ENABLE_PNG_EXPORT,
                ])
              ),
            },
          ]
        : []),
        //SANKEY CHART CHART DRILL THROUGH
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
            this.isRoutePanel &&
            this.chartType === chartTypes.SANKEY_CHART
            ? [
              {
                title: this.collapse.DRILL_THROUGH,
                name: this.collapse.DRILL_THROUGH,
                components: Object.values(
                  _.pick(this.sankeyChartComponentsMapping, [
                    SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_ALLOW_DRILL_THROUGH,
                    this.componentStyle.SANKEY_CHART_ALLOW_DRILL_THROUGH || this.componentStyle.SANKEY_CHART_ALLOW_DRILL_THROUGH === undefined ?
                    SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_DRILL_THROUGH_DASHBOARD_LIST : null,
                    SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK
                  ])
                ),
              },
            ]
        : []),

        //SANKEY CHART AUTO_REFRESH PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.collapse.SANKEY_CHART_AUTO_REFRESH,
              name: this.collapse.SANKEY_CHART_AUTO_REFRESH,
              components: Object.values(
                _.pick(this.sankeyChartComponentsMapping, [
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_AUTO_REFRESH,
                  this.checkAutoRefreshValueIsCustom(this.componentStyle.SANKEY_CHART_AUTO_REFRESH)
                    ? SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_AUTO_REFRESH_CUSTOM : null

                ])
              ),
            },
          ]
          : []),

        //SANKEY CHART OTHER PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.SANKEY_CHART
          ? [
            {
              title: this.collapse.SANKEY_CHART_TITLE,
              name: this.collapse.SANKEY_CHART_TITLE,
              components: Object.values(
                _.pick(this.sankeyChartComponentsMapping, [
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_DYNAMIC_TITLE_VALUE,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  SANKEY_CHART_PROPERTIES_ENUM.SANKEY_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),

        //ADVANCED_BUTTON CAPTION PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === dashboardFilterTypes.ADVANCED_BUTTON
          ? [
            {
              title: this.collapse.CAPTION,
              name: this.collapse.CAPTION,
              components: Object.values(
                _.pick(this.buttonComponentsMapping, [
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR,
                ])
              ),
            },
          ]
          : []),

        //FILTER_BUTTON CAPTION PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === otherChartType.BUTTON
          ? [
            {
              title: this.collapse.BUTTON_ACTION,
              name: this.collapse.BUTTON_ACTION,
              components: Object.values(
                _.pick(this.buttonComponentsMapping, [
                  BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_SELECT_VALUE,
                  this.componentStyle.BUTTON_ACTION_SELECT_VALUE === buttonSelectionValues.TAB ?
                  BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_APPLY_TO_TABS : null,
                  this.componentStyle.BUTTON_ACTION_SELECT_VALUE === buttonSelectionValues.URL ?
                  BUTTON_PROPERTIES_ENUM.BUTTON_ACTION_URL_VALUE : null,
                ])
              ),
            },
            {
              title: this.collapse.CAPTION,
              name: this.collapse.CAPTION,
              components: Object.values(
                _.pick(this.buttonComponentsMapping, [
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_LABEL,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_FAMILY,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_WEIGHT,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_SIZE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BORDER_RADIUS,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_FONT_COLOR,
                  BUTTON_PROPERTIES_ENUM.BUTTON_CAPTION_BACKGROUND_COLOR,
                ])
              ),
            },
            this.componentStyle.BUTTON_ACTION_SELECT_VALUE === buttonSelectionValues.DIAOLOG ? {
              title: this.collapse.BUTTON_DIALOG,
              name: this.collapse.BUTTON_DIALOG,
              components: Object.values(
                _.pick(this.buttonComponentsMapping, [
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_TITLE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_SUB_TITLE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SHOW_BORDER,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_COLOR,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BORDER_RADIUS,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_BACKGROUND_COLOR,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUBTITLE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_WRAP_TEXT,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_COLOR,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_FAMILY,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_SIZE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_FONT_WEIGHT,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_TITLE_ALIGN,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_COLOR,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_FAMILY,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_SIZE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_FONT_WEIGHT,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_SUB_TITLE_ALIGN,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_COLOR,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_FAMILY,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_SIZE,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_FONT_WEIGHT,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CONTENT_ALIGN,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_WIDTH,
                  BUTTON_PROPERTIES_ENUM.BUTTON_DIALOG_CARD_HEIGHT,
                ])
              ),
            } : null,
          ].filter((x) => x)
          : []),

        //LABEL_CHART PROPERTIES
        ...(this.activeComponentType === this.dashboardComponentTypes.CHART &&
          this.isRoutePanel &&
          this.chartType === chartTypes.LABEL
          ? [
            {
              title: this.collapse.LABEL_CHART_TITLE,
              name: this.collapse.LABEL_CHART_TITLE,
              components: Object.values(
                _.pick(this.labelChartComponentsMapping, [
                  LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_SHOW_VALUE,
                  LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_VALUE,
                  LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                  LABEL_CHART_PROPERTIES_ENUM.LABEL_CHART_GROUP_MULTIPLE_VALUES,
                ])
              ),
            },
          ]
          : []),
      ].filter(x=> x);
    },
  },
  methods: {
    getPropertyId(property, name) {
      return property + name;
    },
    checkAutoRefreshValueIsCustom(autoRefreshValue) {
      return autoRefreshValue == autoRefreshTimeValue.custom;
    },
    getLabelByDynamicTitleShowValue(property) {
      return this.componentStyle[property] ? "panelProperties.title.dynamicTitle" : "generalPages.title";
    },
    isNoContentSelectionTheReadOnly(property) {
      return !this.componentStyle[property];
    },
    getShowInsightControl(readOnly) {
      return readOnly === "true";
    },
    getCommonSelectBoxComponentsMapping(selectValues) {
      return {
        size: "mini",
        selectValues,
        componentType: this.componentTypes.SELECT_BOX,
        flexClass: false,
      }
    },
    checkPropertyIsActiveOrUndefined(property) {
      return property === undefined || property;
    },
    getPropertiesWithUndefinedCheck (value, defaultValue) {
      return typeof value != "undefined" ? value : defaultValue
    }
  },
};
