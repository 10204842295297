import { v4 as uuidv4 } from "uuid";
import { IngestNodeTypes } from "../components/ingest/enums";

export const jobModes = {
  OVERWRITE: "OVERWRITE",
  INCREMENTAL: "INCREMENTAL",
};

export const scheduleModes = {
  MANUAL: "MANUAL",
  SCHEDULED: "SCHEDULED",
};

export const tempDefaultJobPrefix = "temp_default_job_";

export const createDefaultJob = () => {
  return {
    id: tempDefaultJobPrefix + uuidv4(),
    sql: "",
    name: "",
    description: "",
    targetConnectionId: null,
    sourceConnectionId: null,
    sourceConnectionNodeId: null,
    jobMode: jobModes.INCREMENTAL,
    scheduleMode: scheduleModes.MANUAL,
    cursorField: "",
    targetTableName: "",
    createdBy: "",
    createdTime: "",
    active: true,
    partitionBy: [],
    orderBy: [],
    scheduleField: null,

    // do not send to backend
    totalRows: 0,
    totalColumns: 0,
  };
};

export const createDefaultDatabaseSourceConnection = () => {
  return {
    id: "temp_default_database_source_" + uuidv4(),
    sourceConnectionId: null,
    nodeType: IngestNodeTypes.Database,
  };
};

export const createDefaultFTPSourceConnection = () => {
  return {
    id: "temp_default_ftp_source_" + uuidv4(),
    sourceConnectionId: null,
    nodeType: IngestNodeTypes.FTP,
  };
};

export const createDefaultSharedFolderSourceConnection = () => {
  return {
    id: "temp_default_shared_folder_source_" + uuidv4(),
    sourceConnectionId: null,
    nodeType: IngestNodeTypes.SMB,
  };
};

export const ingestData = {
  name: "",
  description: "",
  jobs: [],
  properties: {
    sources: [],
    positions: [],
  },
};

/* orderBy ve partitionBy icerisinde asagidaki sekilde columnName iceren objeleri tutar
{
    columnName: "column_name",
},
*/
