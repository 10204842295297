<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold"
        >{{ $t(chartTypeName) }}
        {{ $t("dashboard.properties.dashboardProperties") }}</span
      >
      <i
        v-if="showCloseIcon"
        @click="closeColumnBasedProperties"
        class="vis-cursor-pointer"
        style="margin-left: auto"
        :class="CustomIcon.Close"
        aria-hidden="true"
      />
    </div>
    <el-collapse v-model="activeCollapses">
      <el-collapse-item
        v-for="dashboardPropertiesCollapse in dashboardPropertiesCollapses"
        :class="{
          'vis-collapse-item': true,
          'p-0': dashboardPropertiesCollapse.hasPadding === false,
        }"
        :key="dashboardPropertiesCollapse.name"
        :title="$t(dashboardPropertiesCollapse.title)"
        :name="dashboardPropertiesCollapse.name"
        :disabled="disabledCollapseItem"
      >
        <RowBox
          v-for="dashboardPropertiesCollapseComponent in dashboardPropertiesCollapse.components"
          :key="dashboardPropertiesCollapseComponent.id"
          :spacebetween="
            spacebetween(dashboardPropertiesCollapseComponent.flexClass)
          "
          :style="dashboardPropertiesCollapseComponent.cssStyle ? dashboardPropertiesCollapseComponent.cssStyle : ''"
          :applyRowBoxStyle="!dashboardPropertiesCollapseComponent.cssStyle"
          :yCenter="yCenter(dashboardPropertiesCollapseComponent.flexClass)"
          :paddingLeft="dashboardPropertiesCollapseComponent.paddingLeftClass"
          :isShowCustomLabel="dashboardPropertiesCollapseComponent.isShowCustomLabel"
          :customLabelText="dashboardPropertiesCollapseComponent.customLabelText"
        >
          <!-- SWITCH STARTS -->
          <template
            v-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.SWITCH
            "
            v-slot:content
          >
            <label :for="dashboardPropertiesCollapseComponent.id">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>

            <el-switch
              :id="dashboardPropertiesCollapseComponent.id"
              :value="dashboardPropertiesCollapseComponent.value"
              @change="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
              :disabled="
                dashboardPropertiesCollapseComponent.readOnly ||
                isCheckDisableSwitch(
                  dashboardPropertiesCollapseComponent.property
                )
              "
            >
            </el-switch>
          </template>
          <!-- SWITCH END -->
          <!-- INPUT START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.INPUT
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <input
              class="vis-input"
              :type="dashboardPropertiesCollapseComponent.inputType"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              @keyup="
                (e) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value: e.target.value,
                  })
              "
            />
          </template>
          <!-- INPUT END -->
          <!-- INPUT WITH ENTER AND CHECKABLE START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.INPUT_WITH_ENTER
            "
            v-slot:content
          >
            <CheckableInput
              :label="$t(dashboardPropertiesCollapseComponent.label)"
              :inputType="dashboardPropertiesCollapseComponent.inputType"
              :value="dashboardPropertiesCollapseComponent.value"
              :readOnly="dashboardPropertiesCollapseComponent.readOnly"
              :inputTitle="$t(dashboardPropertiesCollapseComponent.inputTitle)"
              @handleValue="
                (value) =>
                  setComponentStyleWithEnterCheck({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
            />
          </template>
          <!-- INPUT WITH ENTER AND CHECKABLE END -->

          <!-- INPUT NUMBER START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.INPUT_NUMBER
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <input
              class="vis-input"
              type="number"
              :placeholder="$t(dashboardPropertiesCollapseComponent.placeHolder)"
              :min="dashboardPropertiesCollapseComponent.minValue"
              :max="dashboardPropertiesCollapseComponent.maxValue"
              :step="dashboardPropertiesCollapseComponent.stepValue"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              :onkeydown="dashboardPropertiesCollapseComponent.discardManualValues ? 'return false' : ''"
              @input="
                (e) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value: e.target.value,
                  })
              "
            />
          </template>
          <!-- INPUT END -->

          <!-- COLOR PICKER START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.COLOR_PICKER
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <el-color-picker
              :value="dashboardPropertiesCollapseComponent.value"
              :predefine="dashboardPropertiesCollapseComponent.predefineColors"
              :size="dashboardPropertiesCollapseComponent.size"
              @change="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
            ></el-color-picker>
          </template>
          <!-- COLOR PICKER END -->
           
          <!-- SELECT START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.SELECT_BOX
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <el-select
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              :placeholder="$t('generalPages.selectValue')"
              :clearable="dashboardPropertiesCollapseComponent.clearable"
              :class="dashboardPropertiesCollapseComponent.customWidth"
              @change="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
            >
              <el-option
                v-for="item in dashboardPropertiesCollapseComponent.selectValues"
                :key="item.value"
                :label="selectboxLabel(item)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <!-- SELECT END -->

          <!-- CUSTOM_MULTIPLE_SELECT_BOX START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.CUSTOM_MULTIPLE_SELECT_BOX
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <el-select
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              :placeholder="$t('generalPages.selectValue')"
              :clearable="dashboardPropertiesCollapseComponent.clearable"
              multiple
              collapse-tags
              @change="
                (value) =>
                setComponentStyleWithCustomMultipleSelectBox({
                      property: dashboardPropertiesCollapseComponent.property,
                      value,
                    },
                  dashboardPropertiesCollapseComponent)
              "
            >
              <el-option
                :key="selectBoxOption.ALL_ITEM"
                label="All Tabs"
                :value="selectBoxOption.ALL_ITEM"
                class="vis-elselect-option-border-bottom"
              >
              </el-option>
              <el-option
                v-for="item in dashboardPropertiesCollapseComponent.selectValues"
                :key="item.value"
                :label="selectboxLabel(item)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
          <!-- CUSTOM_MULTIPLE_SELECT_BOX END -->

          <!-- CUSTOM_ICON_SELECT_BOX START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.CUSTOM_ICON_SELECT_BOX
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <SelectboxWithIcon
              :options="dashboardPropertiesCollapseComponent.selectValues"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              width="25%"
              @handleChange="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
            />
          </template>
          <!-- CUSTOM_ICON_SELECT_BOX END -->

          <!-- TEXT ALIGNMENT START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <TextAlignmentRadioButton 
                :value="dashboardPropertiesCollapseComponent.value"
                @change="
                    (value) =>
                        setComponentStyle({
                            property: dashboardPropertiesCollapseComponent.property,
                            value,
                        })
                " 
            />
          </template>
          <!-- TEXT ALIGNMENT END -->
          <!-- ROTATE ALIGNMENT START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.ROTATE_ALIGNMENT_RADIO_BUTTON
            "
            v-slot:content
          >
            <label for="">{{
              $t(dashboardPropertiesCollapseComponent.label)
            }}</label>
            <RotateAlignmentRadioButton
                :value="dashboardPropertiesCollapseComponent.value"
                @change="
                    (value) =>
                        setComponentStyle({
                            property: dashboardPropertiesCollapseComponent.property,
                            value,
                        })
                " 
            />
          </template>
          <!-- ROTATE ALIGNMENT END -->

          <!-- OTHER START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.OTHER
            "
            v-slot:content
          >
            <component
              :is="dashboardPropertiesCollapseComponent.component"
              :value="dashboardPropertiesCollapseComponent.value"
              :trendCalculation="dashboardPropertiesCollapseComponent.trendCalculation"
              :summaryResponse="summaryResponse"
              :palettes="colorPalettes"
              @setComponentStyle="
                (value) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })
              "
              @setDashboardStyle="
                (value) =>
                  setDashboardStyle({
                    property:
                      dashboardPropertiesCollapseComponent.dashboardProperty,
                    value,
                  })
              "
            ></component>
          </template>
          <!-- OTHER END -->

          <!-- TEXTAREA START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.TEXTAREA
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <textarea
              class="vis-textarea vis-textarea-notresize"
              :value="dashboardPropertiesCollapseComponent.value"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              @keyup="
                (event) =>
                  setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value: event.target.value,
                  })
              "
              :rows="dashboardPropertiesCollapseComponent.textAreaRow || '2'"
            >
            </textarea>
          </template>
          <!-- TEXTAREA END -->

          <!-- BUTTON STARTS -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.BUTTON
            "
            v-slot:content
          >
            <el-button
              style="width: 100%"
              @click="$emit('onPropertiesEventTriggered', { eventId: dashboardPropertiesCollapseComponent.eventId })"
              :disabled="dashboardPropertiesCollapseComponent.readOnly"
              >{{ $t(dashboardPropertiesCollapseComponent.label) }}</el-button
            >
          </template>
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.SHOW_BUTTON
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <el-button
              type="primary"
              size="small"
              @click="$emit('onPropertiesEventTriggered', { eventId: dashboardPropertiesCollapseComponent.eventId })"
              >{{ $t(dashboardPropertiesCollapseComponent.placeholder) }}</el-button
            >
          </template>
          <!-- BUTTON END -->
          <!-- DATE RANGE START -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.DATE_RANGE
            "
            v-slot:content
          >
            <label>{{ $t(dashboardPropertiesCollapseComponent.label) }}</label>
            <div class="vis-flex--spacebeetwen-Ycenter">
              <el-date-picker
                :value="dashboardPropertiesCollapseComponent.value"
                :class="dashboardPropertiesCollapseComponent.className"
                :placeholder="
                  $t(dashboardPropertiesCollapseComponent.placeHolder)
                "
                format="dd/MM/yyyy"
                :picker-options="
                  pickerOptions(
                    dashboardPropertiesCollapseComponent.isPickerOptionsActive,
                    dashboardPropertiesCollapseComponent.property
                  )
                "
                @input="
                  (value) =>
                    setComponentStyle({
                      property: dashboardPropertiesCollapseComponent.property,
                      value,
                    })
                "
              >
              </el-date-picker>
            </div>
          </template>
          <!-- DATE RANGE END -->
          <template
            v-else-if="
              dashboardPropertiesCollapseComponent.componentType ===
              componentTypes.AUTO_COMPLETE_INPUT_WITH_TABLE
            "
            v-slot:content
          >
            <AutoCompleteInput
              class="vis-row-box mb-25"
              :list="autocompleteQueryList[dashboardPropertiesCollapseComponent.property]"
              @querySearchAsync="(val) => handleAutoCompleteSearchWithTable(val, dashboardPropertiesCollapseComponent)"
              @handleSelect="(val) => handleSelectAutoComplete(val, dashboardPropertiesCollapseComponent)"
              @handleAction="
                () =>
                  handleAddNewItemByAutoComplete(
                    dashboardPropertiesCollapseComponent.value,
                    dashboardPropertiesCollapseComponent.property
                  )
              "
            />
            <PropertiesTable
              :columns="dashboardPropertiesCollapseComponent.tableColumns"
              :rows="dashboardPropertiesCollapseComponent.value"
              @handleDeleteRow="
                (id) =>
                  handleDeleteTableRowById(
                    id,
                    dashboardPropertiesCollapseComponent.value,
                    dashboardPropertiesCollapseComponent.property
                  )
              "
            />
          </template>
        </RowBox>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import RowBox from "./RowBox.vue";
import dashboardProperties from "../../../mixins/dashboardProperties";
import {
  cssStylePropertiesEnum,
  selectBoxOption,
} from "../../../commons/dashboardProperties";
import { includesValues } from "../../../commons/Enum";
import { CustomIcon } from "../../../assets/js/custom-icons";
import SelectboxWithIcon from "../../helper/SelectboxWithIcon.vue";
import PropertiesTable from "../../helper/PropertiesTable.vue";
import AutoCompleteInput from "../../helper/AutoCompleteInput.vue";
import { LoadingComponent } from "../../../store/modules/Visualize/General/loadingComponentDefinitions";
import cloneDeep from "clone-deep";
import TextAlignmentRadioButton from '../../helper/TextAlignmentRadioButton.vue';
import RotateAlignmentRadioButton from '../../helper/RotateAlignmentRadioButton.vue';
import CheckableInput from "../../../components/helper/CheckableInput.vue";

export default {
  mixins: [dashboardProperties],
  props: {
    chartType: {
      type: String,
    },
    chartTypeName: {
      type: String,
    },
    colorPalettes: {
      type: Array,
    },
    customFilterType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabledCollapseItem: {
      type: Boolean,
      default: false
    },
    summaryResponse: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    RowBox,
    SelectboxWithIcon,
    PropertiesTable,
    AutoCompleteInput,
    TextAlignmentRadioButton,
    RotateAlignmentRadioButton,
    CheckableInput
  },
  watch: {
    disabledCollapseItem(val) {
      if (val) this.activeCollapses = [];
      else
        this.activeCollapses = [
          this.collapse.GENERAL,
          this.collapse.CARD,
          this.collapse.CHART,
          this.collapse.PIE_CHART_VALUE_LABEL,
        ];
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      defaultBorderStyle: cssStylePropertiesEnum.BORDER,
      selectBoxOption: selectBoxOption,
      selectedAutoCompleteObj: {},
      autocompleteQueryList: {}
    };
  },
  created() {
    //"this.collapse" mixin componenti data() içinden kullanılıyor.
    this.activeCollapses = [
      this.collapse.GENERAL,
      this.collapse.CARD,
      this.collapse.CHART,
      this.collapse.PIE_CHART_VALUE_LABEL,
    ];
  },
  computed: {
    showCloseIcon() {
      return (
        this.activeComponentType ===
          this.dashboardComponentTypes.DATA_TABLE_COLUMN ||
        this.activeComponentType ===
          this.dashboardComponentTypes.PIVOT_TABLE_COLUMN
      );
    },
  },
  methods: {
    handleDeleteTableRowById(id, value, property) {
      let clonedValue = cloneDeep(value);

      clonedValue = clonedValue.filter((x) => x.id !== id);

      this.setComponentStyle({
        value: clonedValue,
        property,
      });
    },
    handleAddNewItemByAutoComplete(value, property) {
      const clonedValue = cloneDeep(value);
      const foundItem = clonedValue?.find(x=> x.id === this.selectedAutoCompleteObj[property].id);

      //Listede aynı dashboard var ise tekrar ekleme yapılmaması için.
      if (foundItem) return;

      clonedValue.push(this.selectedAutoCompleteObj[property]);

      this.setComponentStyle({
        value: clonedValue,
        property,
      });
    },
    handleSelectAutoComplete(value, config) {
      this.selectedAutoCompleteObj[config.property] = config.getAutoCompleteItem(value);
    },
    async handleAutoCompleteSearchWithTable(value, config) {
        const queryParam = config.queryParam(value);
        const result = await config.serviceFn({
          loadingComponent: LoadingComponent.HomepageList,
          queryParam,
        });

        this.$set(this.autocompleteQueryList, config.property, config.mapperFn(result));
    },
    getPropertyValue(key) {
      const selectedKey = Object.keys(this.componentStyle).find((f) =>
        f?.includes(key)
      );

      return this.componentStyle?.[selectedKey];
    },
    pickerOptions(isPickerOptionsActive, property) {
      if (!isPickerOptionsActive) return {};

      const START_DATE = "START_DATE",
        END_DATE = "END_DATE";
      const startDate = new Date(this.getPropertyValue(START_DATE)).getTime();
      const endDate = new Date(this.getPropertyValue(END_DATE)).getTime();

      return {
        disabledDate(time) {
          const timeDate = time.getTime();

          if (startDate && !endDate) {
            return timeDate <= startDate;
          } else if (endDate && !startDate) {
            return timeDate >= endDate;
          } else if (
            (startDate &&
              timeDate <= startDate &&
              property?.includes(END_DATE)) ||
            (endDate && timeDate >= endDate && property?.includes(START_DATE))
          ) {
            return true;
          }
        },
      };
    },
    closeColumnBasedProperties() {
      this.$emit("closeColumnBasedProperties");
    },
    setDataTableOrPivotColumnBasedProperties(params) {
      if (
        [
          this.dashboardComponentTypes.DATA_TABLE_COLUMN,
          this.dashboardComponentTypes.PIVOT_TABLE_COLUMN,
        ].includes(this.activeComponentType)
      ) {
        this.$emit("setDataTableColumnBasedComponentProperties", params);

        return true;
      } else return false;
    },
    setComponentStyle(params) {
      const isChartPivotOrTable = this.setDataTableOrPivotColumnBasedProperties(params);

      if (isChartPivotOrTable) return;

      this.$emit("setComponentStyle", params);
    },
    setComponentStyleWithEnterCheck(params) {
      const isChartPivotOrTable =
        this.setDataTableOrPivotColumnBasedProperties(params);

      if (isChartPivotOrTable) return;

      this.$emit("setComponentStyle", params);
    },
    setComponentStyleWithCustomMultipleSelectBox(params, component) {
      if (!this.checkIncludesValueInArray(component.value, selectBoxOption.ALL_ITEM) && this.checkIncludesValueInArray(params.value, selectBoxOption.ALL_ITEM)) {
          params.value = params.value.filter(f=> f === selectBoxOption.ALL_ITEM);
      }
      else if (this.checkIncludesValueInArray(params.value, selectBoxOption.ALL_ITEM) && params.value.length) {
          params.value = params.value.filter(f=> f !== selectBoxOption.ALL_ITEM);
      }
      this.$emit("setComponentStyle", params);
    },
    setDashboardStyle(params) {
      this.$emit("setDashboardStyle", params);
    },

    setBorderStyle(hasBorder) {
      if (hasBorder) {
        this.$emit("setBorderStyle", this.defaultBorderStyle);
      } else {
        this.$emit("resetBorderStyle");
      }
    },
    checkIncludesValueInArray(arr, incValue){
      return arr?.includes(incValue);
    },
    spacebetween(value) {
      if (value) return "vis-flex--spacebeetwen";
      else return "";
    },
    yCenter(value) {
      if (value) return "vis-flex--Ycenter";
      else return "";
    },
    selectboxLabel(item) {
      if (item.labelNotLocale) {
        return `${item.labelNotLocale} ${this.$t(item.label)}`;
      } else {
        return this.$t(item.label);
      }
    },
    isCheckDisableSwitch(property) {
      if (Object.keys?.(this.customFilterType).length) {
        let isDisabledMultiSelect = false;
        let isDisabledMandatory = false;
        const key = this.customFilterType.key[property];

        if (property.includes(includesValues.ALLOW_MULTISELECT) && key)
          isDisabledMultiSelect = true;
        else if (property.includes(includesValues.MANDATORY) && !key)
          isDisabledMandatory = true;

        return (
          Object.keys(this.customFilterType?.key).includes(property) &&
          this.customFilterType.isDisabled &&
          !isDisabledMultiSelect &&
          !isDisabledMandatory
        );
      }
    },
  },
};
</script>

<style>
/* scoped yapınca yazılan css'ler ::v-deep kullansakta çalışmıyor. */

.vis-elselect-option-border-bottom {
  border-bottom: 1px solid #d8d8d8 !important;
}
.vis-row-box .el-select__tags {
  flex-wrap: inherit !important;
  max-width: 180px !important;
}

.nameSubtitle {
  font-size: 12px;
  color: #a1a1a1;
  line-height: normal;
  transform: translateY(-4px);
}
</style>