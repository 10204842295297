import { AXIS_TYPES_ENUM, BUBBLE_CHART_PROPERTIES_ENUM, Orientation, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { BUBBLE_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/bubbleChartDefaultProperties";
import { getScatterAndHeatmapCommonStyle } from "../chart-filter/filterChartStyles";
import { calculateGridDistances, getSelectedColorPaletteByProperty, isAxisLocationDefault, isAxisNameActive, isNameLocationDefault, formatValueAxisLabels, generateHTMLForScatterTooltip, truncateText, setShowOnlyValue, getLabelPositionByRotate, aggregationDateFormatController, colorConverter, sliderPositionHandler, xAxisWidthHandler} from "../chartOptionsHelper";
import { getAlignPositionByLegend, getOrientPositionByLegend, isChartHeightLimited, isChartWidthLimited } from "../chartViewHelper";
export const getBubbleChartOptions = (bubbleChartComputedValues, paramsFromSearch, panelData) => {
    const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
        yAxisPosition: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_POSITION,
        showYAxisLabels: bubbleChartComputedValues.BUBBLE_CHART_SHOW_Y_AXIS_LABEL,
        showXAxisLabels: bubbleChartComputedValues.BUBBLE_CHART_SHOW_X_AXIS_LABEL,
        showLegend: bubbleChartComputedValues.BUBBLE_CHART_SHOW_LEGEND,
        legendPosition: bubbleChartComputedValues.BUBBLE_CHART_LEGEND_POSITION,
        legendMaxCharacter: bubbleChartComputedValues.BUBBLE_CHART_LEGEND_MAX_CHARACTER,
        yAxisNameLocation: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_NAME_LOCATION,
        xAxisDataZoom: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_SHOW_DATA_ZOOM,
        xAxisRotate: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_ROTATE,
        yAxisDataZoom: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
        panelData
    });

    const optionObject = {
        grid:
        {
            top: gridTop + 40,
            bottom: gridBottom,
            left: gridLeft,
            right: gridRight + 40,
            containLabel: true
        },
        title: {
            text: "",
        },
        legend: {
            show: isChartWidthLimited(panelData.w) ? bubbleChartComputedValues.BUBBLE_CHART_SHOW_LEGEND : false,
            orient: getOrientPositionByLegend(bubbleChartComputedValues.BUBBLE_CHART_LEGEND_POSITION).toLowerCase(),
            left: getAlignPositionByLegend(bubbleChartComputedValues.BUBBLE_CHART_LEGEND_POSITION),
            top: getOrientPositionByLegend(bubbleChartComputedValues.BUBBLE_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "4%" : bubbleChartComputedValues.BUBBLE_CHART_LEGEND_VERTICAL_ALIGN,
            textStyle: {
                fontWeight: bubbleChartComputedValues.BUBBLE_CHART_LEGEND_FONT_WEIGHT,
                fontFamily: bubbleChartComputedValues.BUBBLE_CHART_LEGEND_FONT_FAMILY,
                fontSize: bubbleChartComputedValues.BUBBLE_CHART_LEGEND_FONT_SIZE,
                color: bubbleChartComputedValues.BUBBLE_CHART_LEGEND_FONT_COLOR,
            },
            backgroundColor: 'transparent',
            formatter: function (value) {
                return truncateText(aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats), bubbleChartComputedValues.BUBBLE_CHART_LEGEND_MAX_CHARACTER);
            },
            type: 'scroll',
        },
        tooltip: {
            show: true,
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: {
                fontFamily: 'Poppins',
                fontSize: 12
            },
            formatter: function (value) {
                const [x, y, name, pointSize, seriesName] = value?.value

                value.name = aggregationDateFormatController(name, paramsFromSearch?.aggregationFormats); // Points alanındaki objenin değerini çeker
                value.seriesName = aggregationDateFormatController(seriesName, paramsFromSearch?.aggregationFormats); // ColorBy alanındaki objenin değerini çeker

                if (
                  showOnlyTypesExceptOnlyItem.includes(
                    bubbleChartComputedValues.BUBBLE_CHART_SHOW_ONLY
                  )
                ) {
                  value.value = [x, y, pointSize];
                  return setShowOnlyValue(
                    value,
                    paramsFromSearch,
                    bubbleChartComputedValues,
                    BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_ONLY,
                    dashboardSPEnums.TOOLTIP,
                    panelData
                  );
                } else {
                    return generateHTMLForScatterTooltip(
                        value,
                        paramsFromSearch?.formatMap,
                        panelData?.details?.aggregation,
                        panelData?.details?.metrics
                    )
                }
              },
        },
        yAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartWidthLimited(panelData.w) ? bubbleChartComputedValues.BUBBLE_CHART_SHOW_Y_AXIS_LABEL : false,
                fontWeight: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LABEL_FONT_FAMILY,
                fontSize: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LABEL_FONT_SIZE,
                color: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LABEL_FONT_COLOR,
                formatter: function (value) {
                    if(typeof value === 'number') return formatValueAxisLabels(paramsFromSearch?.formatMap?.y, value);
                    else return aggregationDateFormatController(value, paramsFromSearch?.formatMap?.y);
                },
                hideOverlap: true,
            },
            type: paramsFromSearch?.axisData?.y?.type !== AXIS_TYPES_ENUM.VALUE ?
                paramsFromSearch?.axisData?.y?.type :
                bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LOGARITHMIC_SCALE ?
                    AXIS_TYPES_ENUM.LOG :
                    paramsFromSearch?.axisData?.y?.type,
            position: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_POSITION,
            axisLine: {
                show: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_SHOW_LINE,
            },
            data: paramsFromSearch?.axisData?.y?.data ?? [],
            axisTick: {
                show: false
            },
            splitLine: {
                show: true
            },
            boundaryGap: false,
            min: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_MIN,
        },
        xAxis: {
            show: paramsFromSearch?.dataSeries ? true : false,
            name: "",
            axisLabel: {
                show: isChartHeightLimited(panelData.h) ? bubbleChartComputedValues.BUBBLE_CHART_SHOW_X_AXIS_LABEL : false,
                align: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN,
                verticalAlign: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_VERTICAL_ALIGN,
                fontWeight: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_FONT_WEIGHT,
                fontFamily: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_FONT_FAMILY,
                fontSize: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_FONT_SIZE,
                color: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_FONT_COLOR,
                rotate: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_ROTATE,
                overflow: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_WRAP ? 'break' : 'none',
                width: xAxisWidthHandler(bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LABEL_WRAP),
                formatter: function (value) {
                  if(typeof value === 'number') {
                    return formatValueAxisLabels(paramsFromSearch?.formatMap?.x, value);
                  }else {
                    return aggregationDateFormatController(value, paramsFromSearch?.formatMap?.x);
                  }
                },
                hideOverlap: true,
            },
            axisTick: {
                show: false
            },
            type: paramsFromSearch?.axisData?.x?.type !== AXIS_TYPES_ENUM.VALUE ?
                paramsFromSearch?.axisData?.x?.type :
                bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LOGARITHMIC_SCALE ?
                    AXIS_TYPES_ENUM.LOG :
                    paramsFromSearch?.axisData?.x?.type,
            data: paramsFromSearch?.axisData?.x?.data ?? [],
            axisLine: {
                show: true,
            },
            splitLine: {
                show: true
            },
            boundaryGap: false,
            min: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_MIN,
        },
        dataZoom: Object.keys(paramsFromSearch).length
        ? [
            {
              type: 'slider',
              show: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_SHOW_DATA_ZOOM,
              start: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_DATA_ZOOM_START,
              end: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_DATA_ZOOM_END,
              zoomLock: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
                BUBBLE_CHART_DEFAULT_CONFIGURATIONS.BUBBLE_CHART_X_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_DATA_ZOOM_PALETTE,
                BUBBLE_CHART_DEFAULT_CONFIGURATIONS.BUBBLE_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
                BUBBLE_CHART_DEFAULT_CONFIGURATIONS.BUBBLE_CHART_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_DATA_ZOOM_PALETTE
                }
              },
              xAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_SHOW_DATA_ZOOM,
              zoomLock: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_LOCK_DATA_ZOOM,
              xAxisIndex: [0],
            },
            {
              type: 'slider',
              show: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
              left:  sliderPositionHandler(bubbleChartComputedValues.BUBBLE_CHART_SHOW_LEGEND,
                bubbleChartComputedValues.BUBBLE_CHART_LEGEND_POSITION),
              start: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_START,
              end: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_END,
              zoomLock: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              brushSelect: !bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              backgroundColor: colorConverter(bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                BUBBLE_CHART_DEFAULT_CONFIGURATIONS.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
              fillerColor: colorConverter(bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
                BUBBLE_CHART_DEFAULT_CONFIGURATIONS.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
                BUBBLE_CHART_DEFAULT_CONFIGURATIONS.BUBBLE_CHART_DATA_ZOOM_FILLER_COLOR),
              dataBackground: {
                areaStyle: {
                  color: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_DATA_ZOOM_PALETTE
                }
              },
              yAxisIndex: [0],
            },
            {
              type: 'inside',
              disabled: !bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_SHOW_DATA_ZOOM,
              zoomLock: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_LOCK_DATA_ZOOM,
              yAxisIndex: [0],
            },
          ]
        : [],
        dataset: {
            source: paramsFromSearch?.dataSeries || []
        },
        series: paramsFromSearch?.dataSeries
        ? Object?.values(paramsFromSearch.dataSeries)?.map((key, i) => {
          return {
            data: key?.map((a) => {
              return {
                value: a,
                itemStyle: getScatterAndHeatmapCommonStyle(
                  a,
                  panelData,
                  paramsFromSearch?.dimensionNames
                ),
              };
            }),
            type: "scatter",
            name:  paramsFromSearch.dataSeriesNames?.[i],
            dimensions: paramsFromSearch?.dimensionNames,
            symbolSize: bubbleChartComputedValues.BUBBLE_CHART_SYMBOL_SIZE,
            label: {
              show: isChartWidthLimited(panelData.w)
                ? bubbleChartComputedValues.BUBBLE_CHART_SHOW_VALUE_LABEL
                : false,
              position: getLabelPositionByRotate(
                bubbleChartComputedValues.BUBBLE_CHART_VALUE_LABEL_ROTATE
              ),
              rotate:
              bubbleChartComputedValues.BUBBLE_CHART_VALUE_LABEL_ROTATE,
              fontSize:
              bubbleChartComputedValues.BUBBLE_CHART_VALUE_LABEL_FONT_SIZE,
              fontFamily:
              bubbleChartComputedValues.BUBBLE_CHART_VALUE_LABEL_FONT_FAMILY,
              fontWeight:
              bubbleChartComputedValues.BUBBLE_CHART_VALUE_LABEL_FONT_WEIGHT,
              color:
              bubbleChartComputedValues.BUBBLE_CHART_VALUE_LABEL_FONT_COLOR,
              formatter: function (info) {
                const [x, y, name, pointSize] = info?.value

                info.name = aggregationDateFormatController(name, paramsFromSearch?.aggregationFormats)
                info.value = [x, y, pointSize];

                return showOnlyTypesExceptOnlyItem.includes(
                    bubbleChartComputedValues.BUBBLE_CHART_SHOW_ONLY
                )
                  ? setShowOnlyValue(
                      info,
                      paramsFromSearch,
                      bubbleChartComputedValues,
                      BUBBLE_CHART_PROPERTIES_ENUM.BUBBLE_CHART_SHOW_ONLY,
                      dashboardSPEnums.LABEL,
                      panelData
                    )
                  : info?.name;
              },
            },
            labelLayout: {
              hideOverlap:
              bubbleChartComputedValues.BUBBLE_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
            },
          };
        })
      : null,
        color: getSelectedColorPaletteByProperty(bubbleChartComputedValues.BUBBLE_CHART_SELECTED_COLOR_PALETTE)
    }

    if (
        paramsFromSearch &&
        paramsFromSearch.visualMap &&
        Object.keys(paramsFromSearch.visualMap).length > 0
    ) {
        optionObject.visualMap = {
            show: false,
            dimension: 3,
            min: paramsFromSearch.visualMap.min,
            max: paramsFromSearch.visualMap.max,
            inRange: {
                symbolSize: [10, 60]
            }
        }
    }

    return {
        options: optionObject,
        yAxis: {
            name: isAxisNameActive(paramsFromSearch, paramsFromSearch?.axisData?.y?.name),
            show: isChartWidthLimited(panelData.w) ? bubbleChartComputedValues.BUBBLE_CHART_SHOW_Y_AXIS_NAME : false,
            fontFamily: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_NAME_FONT_FAMILY,
            fontSize: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_NAME_FONT_SIZE,
            fontWeight: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_NAME_FONT_WEIGHT,
            fontColor: bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_NAME_FONT_COLOR,
            isAxisLocationDefault: isAxisLocationDefault(bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_POSITION),
            isNameLocationDefault: isNameLocationDefault(bubbleChartComputedValues.BUBBLE_CHART_Y_AXIS_NAME_LOCATION)
        },
        xAxis: {
            name: isAxisNameActive(paramsFromSearch, paramsFromSearch?.axisData?.x?.name),
            show: isChartHeightLimited(panelData.h) ? bubbleChartComputedValues.BUBBLE_CHART_SHOW_X_AXIS_NAME : false,
            fontFamily: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_NAME_FONT_FAMILY,
            fontSize: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_NAME_FONT_SIZE,
            fontWeight: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_NAME_FONT_WEIGHT,
            fontColor: bubbleChartComputedValues.BUBBLE_CHART_X_AXIS_NAME_FONT_COLOR,
        }
    }
}