<template>
  <div>
    <label>{{ label }}</label>
    <div class="vis-flex--Ycenter vis-input-enter-icons">
      <input
        ref="checkableInput"
        class="vis-input"
        v-model="tempValue"
        :type="inputType"
        :readonly="readOnly"
        :title="inputTitle"
        :disabled="disabled"
        @keyup.enter="handleValue"
        @blur="handleBlur"
      />
      <i
        v-if="value !== tempValue"
        :class="[customIcon.Checked, 'vis-cursor-pointer', 'vis-color-primary', 'vis-ml--05']"
        aria-hidden="true"
        @mousedown.prevent="handleValue"
      />
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { CustomIcon } from "../../assets/js/custom-icons";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    inputTitle: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.tempValue = cloneDeep(newVal);
      }
    }
  },
  data() {
    return {
      customIcon: CustomIcon,
      tempValue: null,
    };
  },
  mounted() {
    if (this.tempValue === null) {
      this.tempValue = cloneDeep(this.value);
    }
  },
  methods: {
    handleValue() {
      this.$emit("handleValue", this.tempValue);
     
      this.$refs.checkableInput.blur();
    },
    handleBlur() {
      this.tempValue = cloneDeep(this.value);
    },
  },
};
</script>
