export const domain = "VISUALIZE_HOME";

export const STATE = {
  SEARCH_HOMEPAGE_RESULTS: `${domain}/SEARCH_HOMEPAGE_RESULTS`,
  SEARCH_HOMEPAGE_PAGINATION: `${domain}/SEARCH_HOMEPAGE_PAGINATION`,
  FOLDER_NAME_LIST: `${domain}/FOLDER_NAME_LIST`,
  SEARCH_QUERY_DATA: `${domain}/SEARCH_QUERY_DATA`,
  LAST_SELECTED_TAB: `${domain}/LAST_SELECTED_TAB`,
};

export const GETTER = {
  GET_SEARCH_HOMEPAGE_RESULTS: `${domain}/GET_SEARCH_RESULTS`,
  GET_HOMEPAGE_PAGINATION: `${domain}/GET_HOMEPAGE_PAGINATION`,
  GET_FOLDER_NAME_LIST: `${domain}/GET_FOLDER_NAME_LIST`,
  GET_SEARCH_QUERY_DATA: `${domain}/GET_SEARCH_QUERY_DATA`,
  GET_LAST_SELECTED_TAB: `${domain}/GET_LAST_SELECTED_TAB`,
};

export const ACTION = {
  FETCH_HOMEPAGE_SEARCH: `${domain}/FETCH_HOMEPAGE_SEARCH`,
  FETCH_DASHBOARD_SEARCH: `${domain}/FETCH_DASHBOARD_SEARCH`,
};

export const MUTATION = {
  SET_SEARCH_HOMEPAGE_RESULTS: `${domain}/SET_SEARCH_HOMEPAGE_RESULTS`,
  RESET_SEARCH_HOMEPAGE_RESULTS: `${domain}/RESET_SEARCH_HOMEPAGE_RESULTS`,
  SET_FOLDER_NAME_LIST: `${domain}/SET_FOLDER_NAME_LIST`,
  SET_SEARCH_QUERY_DATA: `${domain}/SET_SEARCH_QUERY_DATA`,
  SET_LAST_SELECTED_TAB: `${domain}/SET_LAST_SELECTED_TAB`,
};
