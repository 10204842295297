import { AXIS_TYPES_ENUM, COLUMN_CHART_PROPERTIES_ENUM, Orientation, dashboardSPEnums, showOnlyTypesExceptOnlyItem } from "../../commons/dashboardProperties";
import { COLUMN_CHART_DEFAULT_CONFIGURATIONS } from "../../mocks/columnChartDefaultProperties";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import {
  calculateGridDistances,
  formatValueAxisLabels,
  getLabelPositionByRotate,
  isAxisLocationDefault,
  isAxisNameActive,
  isNameLocationDefault,
  setShowOnlyValue,
  truncateText,
  getBarColorItemStyle,
  getSelectedColorPaletteByProperty,
  aggregationDateFormatController,
  colorConverter,
  sliderPositionHandler,
  xAxisWidthHandler,
} from "../chartOptionsHelper";
import { isChartWidthLimited, isChartHeightLimited, getSeriesLabelPaddingByChartHeight, getOrientPositionByLegend, getAlignPositionByLegend } from "../chartViewHelper";
export const getColumnChartOptions = (
  columnChartComputedValues,
  paramsFromSearch,
  yAxisName,
  xAxisName,
  panelData
) => {
  const { gridLeft, gridRight, gridBottom, gridTop } = calculateGridDistances({
    yAxisPosition: columnChartComputedValues.COLUMN_CHART_Y_AXIS_POSITION,
    showYAxisLabels: columnChartComputedValues.COLUMN_CHART_SHOW_Y_AXIS_LABEL,
    showXAxisLabels: columnChartComputedValues.COLUMN_CHART_SHOW_X_AXIS_LABEL,
    showLegend: columnChartComputedValues.COLUMN_CHART_SHOW_LEGEND,
    yAxisNameLocation:
      columnChartComputedValues.COLUMN_CHART_Y_AXIS_NAME_LOCATION,
    labelRotate: columnChartComputedValues.COLUMN_CHART_VALUE_LABEL_ROTATE,
    showLabel: columnChartComputedValues.COLUMN_CHART_SHOW_VALUE_LABEL,
    legendPosition: columnChartComputedValues.COLUMN_CHART_LEGEND_POSITION,
    legendMaxCharacter: columnChartComputedValues.COLUMN_CHART_LEGEND_MAX_CHARACTER,
    showOnly: columnChartComputedValues.COLUMN_CHART_SHOW_ONLY,
    xAxisDataZoom: columnChartComputedValues.COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
    xAxisRotate: columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_ROTATE,
    yAxisDataZoom: columnChartComputedValues.COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
    panelData,
  });

  const optionObject = {
    grid: {
      top: gridTop + 20,
      bottom: gridBottom,
      left: gridLeft,
      right: gridRight,
      containLabel: true,
    },
    title: {
      text: "",
    },
    legend: {
      show: isChartWidthLimited(panelData.w)
        ? columnChartComputedValues.COLUMN_CHART_SHOW_LEGEND
        : false,
      orient:
      getOrientPositionByLegend(columnChartComputedValues.COLUMN_CHART_LEGEND_POSITION)?.toLowerCase(),
      left: getAlignPositionByLegend(columnChartComputedValues.COLUMN_CHART_LEGEND_POSITION),
      top: getOrientPositionByLegend(columnChartComputedValues.COLUMN_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "4%" : columnChartComputedValues.COLUMN_CHART_LEGEND_VERTICAL_ALIGN,
      textStyle: {
        fontWeight: columnChartComputedValues.COLUMN_CHART_LEGEND_FONT_WEIGHT,
        fontFamily: columnChartComputedValues.COLUMN_CHART_LEGEND_FONT_FAMILY,
        fontSize: columnChartComputedValues.COLUMN_CHART_LEGEND_FONT_SIZE,
        color: columnChartComputedValues.COLUMN_CHART_LEGEND_FONT_COLOR,
      },
      backgroundColor: 'transparent',
      formatter: function (value) {
        return truncateText(value, columnChartComputedValues.COLUMN_CHART_LEGEND_MAX_CHARACTER);
      },
      type: "scroll",
    },
    tooltip: {
      show: true,
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        fontFamily: "Poppins",
        fontSize: 12,
      },
      formatter: function (value) {
        value.name = aggregationDateFormatController(
          value?.name,
          paramsFromSearch?.aggregationFormats
        );

        return showOnlyTypesExceptOnlyItem.includes(
          columnChartComputedValues.COLUMN_CHART_SHOW_ONLY
        )
          ? setShowOnlyValue(
              value,
              paramsFromSearch,
              columnChartComputedValues,
              COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_ONLY,
              dashboardSPEnums.TOOLTIP,
              panelData
            )
          : value.name;
      },
    },
    xAxis: {
      show: paramsFromSearch?.dataSeries ? true : false,
      name: "",
      axisLabel: {
        show: isChartHeightLimited(panelData.h)
          ? columnChartComputedValues.COLUMN_CHART_SHOW_X_AXIS_LABEL
          : false,
        fontWeight:
          columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_FONT_WEIGHT,
        fontFamily:
          columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_FONT_FAMILY,
        fontSize: columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_FONT_SIZE,
        color: columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_FONT_COLOR,
        rotate: columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_ROTATE,
        overflow: columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_WRAP ? 'break' : 'none',
        width: xAxisWidthHandler(columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_WRAP,
          columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER),
        formatter: function (value) {
          const formattedValue = aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats);
          return truncateText(
            formattedValue,
            columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_MAX_CHARACTER,
            columnChartComputedValues.COLUMN_CHART_X_AXIS_LABEL_WRAP
          );
        },
        hideOverlap: true,
      },
      type: "category",
      axisTick: {
        show: false,
      },
      data: paramsFromSearch?.dataAxis ?? [],
      axisLine: {
        show: columnChartComputedValues.COLUMN_CHART_SHOW_X_AXIS_LINE,
      },
    },
    yAxis: {
      show: paramsFromSearch?.dataSeries,
      name: "",
      axisLabel: {
        show: isChartWidthLimited(panelData.w)
          ? columnChartComputedValues.COLUMN_CHART_SHOW_Y_AXIS_LABEL
          : false,
        hideOverlap: true,
        fontWeight:
          columnChartComputedValues.COLUMN_CHART_Y_AXIS_LABEL_FONT_WEIGHT,
        fontFamily:
          columnChartComputedValues.COLUMN_CHART_Y_AXIS_LABEL_FONT_FAMILY,
        fontSize: columnChartComputedValues.COLUMN_CHART_Y_AXIS_LABEL_FONT_SIZE,
        color: columnChartComputedValues.COLUMN_CHART_Y_AXIS_LABEL_FONT_COLOR,
        formatter:
          function (value) {
            return formatValueAxisLabels(paramsFromSearch?.metricFormats, value);
          },
      },
      type: columnChartComputedValues.COLUMN_CHART_Y_AXIS_LOGARITHMIC_SCALE
        ? AXIS_TYPES_ENUM.LOG
        : AXIS_TYPES_ENUM.VALUE,
      min: columnChartComputedValues.COLUMN_CHART_Y_AXIS_MIN,
      axisLine: {
        show: columnChartComputedValues.COLUMN_CHART_Y_AXIS_SHOW_LINE,
        lineStyle: {
          color: columnChartComputedValues.COLUMN_CHART_Y_AXIS_LINE_STYLE_COLOR,
        },
      },
      axisTick: {
        show: false,
      },
      position: columnChartComputedValues.COLUMN_CHART_Y_AXIS_POSITION,
      splitLine: {
        lineStyle: {
          color: columnChartComputedValues.COLUMN_CHART_Y_AXIS_GRID_LINE_COLOR,
        },
      },
    },
    dataZoom: Object.keys(paramsFromSearch).length
    ? [
        {
          type: 'slider',
          show: columnChartComputedValues.COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
          start: columnChartComputedValues.COLUMN_CHART_X_AXIS_DATA_ZOOM_START,
          end: columnChartComputedValues.COLUMN_CHART_X_AXIS_DATA_ZOOM_END,
          zoomLock: columnChartComputedValues.COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
          brushSelect: !columnChartComputedValues.COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
          backgroundColor: colorConverter(columnChartComputedValues.COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
            COLUMN_CHART_DEFAULT_CONFIGURATIONS.COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE),
          fillerColor: colorConverter(columnChartComputedValues.COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE,
            COLUMN_CHART_DEFAULT_CONFIGURATIONS.COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE, 
            COLUMN_CHART_DEFAULT_CONFIGURATIONS.COLUMN_CHART_DATA_ZOOM_FILLER_COLOR),
          dataBackground: {
            areaStyle: {
              color: columnChartComputedValues.COLUMN_CHART_X_AXIS_DATA_ZOOM_PALETTE
            }
          },
          filterMode: "none",
          xAxisIndex: [0],
        },
        {
          type: 'inside',
          disabled: !columnChartComputedValues.COLUMN_CHART_X_AXIS_SHOW_DATA_ZOOM,
          zoomLock: columnChartComputedValues.COLUMN_CHART_X_AXIS_LOCK_DATA_ZOOM,
          xAxisIndex: [0],
        },
        {
          type: 'slider',
          show: columnChartComputedValues.COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
          left:  sliderPositionHandler(columnChartComputedValues.COLUMN_CHART_SHOW_LEGEND,
            columnChartComputedValues.COLUMN_CHART_LEGEND_POSITION),
          start: columnChartComputedValues.COLUMN_CHART_Y_AXIS_DATA_ZOOM_START,
          end: columnChartComputedValues.COLUMN_CHART_Y_AXIS_DATA_ZOOM_END,
          zoomLock: columnChartComputedValues.COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          brushSelect: !columnChartComputedValues.COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          backgroundColor: colorConverter(columnChartComputedValues.COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
            COLUMN_CHART_DEFAULT_CONFIGURATIONS.COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE),
          fillerColor: colorConverter(columnChartComputedValues.COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE,
            COLUMN_CHART_DEFAULT_CONFIGURATIONS.COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE, 
            COLUMN_CHART_DEFAULT_CONFIGURATIONS.COLUMN_CHART_DATA_ZOOM_FILLER_COLOR),
          dataBackground: {
            areaStyle: {
              color: columnChartComputedValues.COLUMN_CHART_Y_AXIS_DATA_ZOOM_PALETTE
            }
          },
          filterMode: "none",
          yAxisIndex: [0],
        },
        {
          type: 'inside',
          disabled: !columnChartComputedValues.COLUMN_CHART_Y_AXIS_SHOW_DATA_ZOOM,
          zoomLock: columnChartComputedValues.COLUMN_CHART_Y_AXIS_LOCK_DATA_ZOOM,
          yAxisIndex: [0],
        },
      ]
    : [],
    series: paramsFromSearch?.dataSeries?.length
      ? paramsFromSearch?.dataSeries?.map((s, i) => {
        return {
          data: s?.map((a, index) => {
            return {
              value: a,
              itemStyle: {
                ...getCommonChartItemStyle(
                  paramsFromSearch?.dataAxis[index],
                  panelData
                ), ...getBarColorItemStyle(getSelectedColorPaletteByProperty(columnChartComputedValues?.COLUMN_CHART_SELECTED_COLOR_PALETTE,index ), panelData)
              },
            };
          }),
          type: "bar",
          name: paramsFromSearch?.dataSeriesNames?.[i],
          label: {
            position: getLabelPositionByRotate(
              columnChartComputedValues.COLUMN_CHART_VALUE_LABEL_ROTATE
            ),
            padding: getSeriesLabelPaddingByChartHeight(panelData.h),
            show: isChartHeightLimited(panelData.h)
              ? columnChartComputedValues.COLUMN_CHART_SHOW_VALUE_LABEL
              : false,
            fontSize:
              columnChartComputedValues.COLUMN_CHART_VALUE_LABEL_FONT_SIZE,
            fontFamily:
              columnChartComputedValues.COLUMN_CHART_VALUE_LABEL_FONT_FAMILY,
            fontWeight:
              columnChartComputedValues.COLUMN_CHART_VALUE_LABEL_FONT_WEIGHT,
            color:
              columnChartComputedValues.COLUMN_CHART_VALUE_LABEL_FONT_COLOR,
            rotate: columnChartComputedValues.COLUMN_CHART_VALUE_LABEL_ROTATE,
            formatter: function (value) {
              value.name = aggregationDateFormatController(
                value?.name,
                paramsFromSearch?.aggregationFormats
              );

              return showOnlyTypesExceptOnlyItem.includes(
                columnChartComputedValues.COLUMN_CHART_SHOW_ONLY
              )
                ? setShowOnlyValue(
                    value,
                    paramsFromSearch,
                    columnChartComputedValues,
                    COLUMN_CHART_PROPERTIES_ENUM.COLUMN_CHART_SHOW_ONLY,
                    dashboardSPEnums.LABEL,
                    panelData
                  )
                : value.name;
            },
          },
          itemStyle: {
            borderRadius: [3, 3, 0, 0],
          },
          labelLayout: {
            hideOverlap:
              columnChartComputedValues.COLUMN_CHART_HIDE_OVERLAPPED_VALUE_LABELS,
          },
        };
      })
      : null,
    color: getSelectedColorPaletteByProperty(columnChartComputedValues?.COLUMN_CHART_SELECTED_COLOR_PALETTE ),
  };

  return {
    options: optionObject,
    yAxis: {
      name: isAxisNameActive(paramsFromSearch, yAxisName),
      show: isChartWidthLimited(panelData.w)
        ? columnChartComputedValues.COLUMN_CHART_SHOW_Y_AXIS_NAME
        : false,
      fontFamily:
        columnChartComputedValues.COLUMN_CHART_Y_AXIS_NAME_FONT_FAMILY,
      fontSize: columnChartComputedValues.COLUMN_CHART_Y_AXIS_NAME_FONT_SIZE,
      fontWeight:
        columnChartComputedValues.COLUMN_CHART_Y_AXIS_NAME_FONT_WEIGHT,
      fontColor: columnChartComputedValues.COLUMN_CHART_Y_AXIS_NAME_FONT_COLOR,
      isAxisLocationDefault: isAxisLocationDefault(
        columnChartComputedValues.COLUMN_CHART_Y_AXIS_POSITION
      ),
      isNameLocationDefault: isNameLocationDefault(
        columnChartComputedValues.COLUMN_CHART_Y_AXIS_NAME_LOCATION
      ),
    },
    xAxis: {
      name: isAxisNameActive(paramsFromSearch, xAxisName),
      show: isChartHeightLimited(panelData.h)
        ? columnChartComputedValues.COLUMN_CHART_SHOW_X_AXIS_NAME
        : false,
      fontFamily:
        columnChartComputedValues.COLUMN_CHART_X_AXIS_NAME_FONT_FAMILY,
      fontSize: columnChartComputedValues.COLUMN_CHART_X_AXIS_NAME_FONT_SIZE,
      fontWeight:
        columnChartComputedValues.COLUMN_CHART_X_AXIS_NAME_FONT_WEIGHT,
      fontColor: columnChartComputedValues.COLUMN_CHART_X_AXIS_NAME_FONT_COLOR,
    },
  };
};
