/* eslint-disable */
import { ACTION, GETTER, MUTATION, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import {
  serviceMethodParent,
  serviceMethodSub,
} from "../../../../api/ApiConstants";
import { MUTATION as MUTATION_TEMP_STORAGE } from "../../temp-storage/types";
import { ACTION as ACTION_DATAMODEL } from "../DataModel/types";
import { NOTIFY_TITLE } from "../../../../commons/notifierTypes";

export const visDataset = {
  state: {
    [STATE.DATASET_FIELDS]: {},
    [STATE.DATASET_FILTER_PARAMS]: [],
    [STATE.SELECTED_DATASET_FIELDS]: [],
    [STATE.SELECTED_DATASET_JOINS]: [],
    [STATE.DATASET_IDS]: [],
  },
  getters: {
    [GETTER.GET_DATASET_BY_ID_FIELDS]: (state) => {
      return state[STATE.DATASET_FIELDS];
    },
    [GETTER.GET_DATASET_BY_ID_FILTER_PARAMS]: (state) => {
      return state[STATE.DATASET_FILTER_PARAMS];
    },
    [GETTER.GET_SELECTED_DATASET_FIELDS]: (state) => {
      return state[STATE.SELECTED_DATASET_FIELDS];
    },
    [GETTER.GET_SELECTED_DATASET_JOINS]: (state) => {
      return state[STATE.SELECTED_DATASET_JOINS];
    },
    [GETTER.GET_DATASET_IDS]: (state) => {
      return state[STATE.DATASET_IDS];
    },
  },
  actions: {
    [ACTION.FETCH_DATASET_JOIN]: async ({ commit }, datamodelId) => {
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.readDatasetJoin,
        queryParam: datamodelId,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        successMutation: MUTATION.SET_SELECTED_DATASET_JOINS,
        resultSelector: (result) => result.data,
        withLoading: true,
      });

      if (result.status === 400) {
        commit(MUTATION.SET_SELECTED_DATASET_JOINS, []);
      }
    },
    [ACTION.SAVE_DATASET_JOIN]: async ({ commit }, { bodyParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.saveDatasetJoin,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.UPDATE_DATASET_CONNECTION]: async ({commit}, { datasetId, bodyParam, loadingComponent } ) => { 
      await vuexActionHandler (
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dataset,
          serviceMethodSub: serviceMethodSub.updateDatasetConnectionById,
          queryParam: datasetId,
          bodyParam,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withNotify: true,
          withLoading: true,
          notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
          loadingComponent
        }
      )
    },
    [ACTION.UPDATE_DATASET_FIELDS_ALIAS]: async ({commit}, { datasetId, bodyParam, loadingComponent } ) => { 
      await vuexActionHandler (
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dataset,
          serviceMethodSub: serviceMethodSub.updateDatasetFieldsAlias,
          queryParam: datasetId,
          bodyParam,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withNotify: true,
          withLoading: true,
          notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
          loadingComponent
        }
      )
    },
    [ACTION.DELETE_DATASET_JOIN]: async (
      { commit },
      { bodyParam, loadingComponent }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.deleteDatasetJoin,
        bodyParam,
        loadingComponent,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },

    [ACTION.CREATE_FILTER_PARAMS]: async ({ commit }, bodyParam) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FilterParams,
        serviceMethodSub: serviceMethodSub.createFilterParams,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.UPDATE_FILTER_PARAMS]: async (
      { commit },
      { queryParam, loadingComponent, bodyParam }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FilterParams,
        serviceMethodSub: serviceMethodSub.updateFilterParams,
        queryParam,
        loadingComponent,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.DELETE_FILTER_PARAMS]: async (
      { commit },
      { queryParam, loadingComponent }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.FilterParams,
        serviceMethodSub: serviceMethodSub.deleteFilterParams,
        queryParam,
        loadingComponent,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
    [ACTION.GENERATE_DATASET_FIELDS_BY_ID]: async (
      { commit, dispatch },
      id
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.generateDatasetFieldsById,
        queryParam: id,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
      dispatch(ACTION.FETCH_DATASET_BY_ID_FIELDS, id);
    },
    [ACTION.FETCH_DATASET_BY_ID_FIELDS]: async ({ commit }, id) => {
      const result = await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.readDatasetFieldsById,
        queryParam: id,
        successMutation: MUTATION.SET_DATASET_FIELDS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
      commit(MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_DATASET_FIELDS, result);
    },
    [ACTION.UPDATE_FIELD]: async ({ commit, dispatch }, { data, dataModelId }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.updateField,
        queryParam: data.fieldId,
        bodyParam: data,
        successMutation: MUTATION_GENERAL.ADD_NOTIFY,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
      dispatch(ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID_FIELDS, dataModelId);
    },
    [ACTION.UPDATE_FIELDS_ORDER]: async ({ commit }, { bodyParam }) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.updateFieldsOrder,
        bodyParam: bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
      });
    },
    [ACTION.FETCH_DATASET_BY_ID_FILTER_PARAMS]: async (
      { commit },
      { loadingComponent, id }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.readDatasetFilterParamsById,
        queryParam: id,
        successMutation: MUTATION.SET_DATASET_FILTER_PARAMS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
      });
    },
    [ACTION.UPDATE_DATASET_BY_ID]: async (
      { commit },
      { loadingComponent, queryParam, bodyParam }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.updateDataset,
        queryParam,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
        withNotify: true,
        withErrorNotify: false,
      });
    },
    [ACTION.CREATE_DATASET]: async (
      { commit },
      { loadingComponent, bodyParam }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.createDataset,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
        withNotify: true,
      });
    },
    [ACTION.COPY_DATASET]: async (
      { commit },
      { loadingComponent, bodyParam }
    ) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.copyDataset,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
        withNotify: true,
      });
    },
    [ACTION.DELETE_DATASET]: async (
      { commit },
      { loadingComponent, queryParam }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.deleteDataset,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        loadingComponent,
        withNotify: true,
      });
    },
    [ACTION.DELETE_CALCULATION]: async (
      { commit },
      { loadingComponent, queryParam }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.deleteCalculation,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        loadingComponent,
      });
    },
    [ACTION.FETCH_CHECK_DATASET_USE_BY_ID]: async ({ commit }, queryParam) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.readCheckDatasetUseById,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result,
        withLoading: true,
      });
    },

    [ACTION.UPDATE_SORT_AND_RENAME_SETTINGS]: async ({ commit }, { datasetId, bodyParam }) => {
      return await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.sortAndRenameSettings,
        queryParam: datasetId,
        bodyParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result,
        withLoading: true,
      });
    },

    [ACTION.FETCH_DATASET_IDS]: async ({ commit }, datamodelId) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dataset,
        serviceMethodSub: serviceMethodSub.readDatasetIds,
        queryParam: datamodelId,
        successMutation: MUTATION.SET_DATASET_IDS,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
      });
    },
  },
  mutations: {
    [MUTATION.SET_DATASET_FIELDS](state, items) {
      Vue.set(state[STATE.DATASET_FIELDS], items[0].datasetId, items);
    },
    [MUTATION.RESET_DATASET_FIELDS](state, items) {
      Vue.set(state, [STATE.DATASET_FIELDS], {});
    },
    [MUTATION.SET_DATASET_FILTER_PARAMS](state, items) {
      state[STATE.DATASET_FILTER_PARAMS].push(...items);
    },
    [MUTATION.RESET_DATASET_FILTER_PARAMS](state, items) {
      Vue.set(state, [STATE.DATASET_FILTER_PARAMS], []);
    },
    [MUTATION.ADD_SELECTED_FIELD](state, payload) {
      state[STATE.SELECTED_DATASET_FIELDS].push(payload);
    },
    [MUTATION.RESET_SELECTED_FIELDS](state, payload) {
      Vue.set(state, [STATE.SELECTED_DATASET_FIELDS], payload);
    },
    [MUTATION.SET_SELECTED_DATASET_JOINS](state, payload) {
      Vue.set(state, [STATE.SELECTED_DATASET_JOINS], payload);
    },
    [MUTATION.SET_DATASET_IDS](state, payload) {
      Vue.set(state, [STATE.DATASET_IDS], payload);
    },
  },
};
