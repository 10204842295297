var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vis-new-data-model-popup"},[_c('div',{staticClass:"vis-new-data-model-popup-header vis-row-box vis-flex--spacebeetwen-Ycenter",on:{"click":function($event){return _vm.$emit('close')}}},[_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.addNewDatasetPopupStepHeaders[_vm.currentStep])))]),_c('i',{staticClass:"mdi mdi-close vis-cursor-pointer",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"vis-new-data-model-popup-body"},[(_vm.currentStep === _vm.addNewDatasetPopupSteps.SELECT_TYPE)?_c('SelectDataSourceType',{on:{"datasourceTypeSelected":_vm.setSelectedDatasourceType}}):_vm._e(),(
          _vm.currentStep === _vm.addNewDatasetPopupSteps.CSV_UPLOAD ||
          _vm.currentStep === _vm.addNewDatasetPopupSteps.EXCEL_UPLOAD
        )?_c('UploadCsvExcelFile',{attrs:{"datamodelName":_vm.datamodelName,"datamodelId":_vm.datamodelId,"initialData":_vm.initialData},on:{"handleSuccess":(params) => _vm.$emit('handleCsvExcelUpload', params),"closeDatamodelPopup":function($event){return _vm.$emit('close')}}}):(
          _vm.currentStep === _vm.addNewDatasetPopupSteps.SELECT_DATABASE_CONNECTION
        )?_c('div',[_c('SelectDatabaseConnection',{attrs:{"hide-actions":true,"hide-create-date":true,"hide-update-date":true,"filterConnectionTypeBy":_vm.availableConnectionTypes},on:{"selectConnection":_vm.selectConnection}}),_c('div',{staticClass:"select-database-connection-container-footer"},[_c('button',{staticClass:"vis-btn medium vis-mt--2 next-button",on:{"click":_vm.handleConnectionSelectedAndApproved}},[_vm._v(" "+_vm._s(_vm.$t("generalPages.next"))+" ")])])],1):(
          _vm.currentStep === _vm.addNewDatasetPopupSteps.SELECT_TABLE_OR_VIEW
        )?_c('div',{staticClass:"select-table-or-view-container"},[_c('SelectTableOrView',{attrs:{"selectedConnection":_vm.selectedConnection},on:{"back":_vm.handleSelectTableOrViewBack,"done":_vm.handleSelectTableOrViewDone,"showSql":_vm.handleShowSql}})],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }