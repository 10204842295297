import { visGeneral } from "./Visualize/General/index";
import { visDashboard } from "./Visualize/Dashboard/index";
import { visDashboardFields } from "./Visualize/DashboardFields";
import { visChartFields } from "./Visualize/ChartFields";
import { visDataModel } from "./Visualize/DataModel/index";
import { visDataset } from "./Visualize/Dataset/index";
import { visTestQuery } from "./Visualize/TestQuery/index";
import { visHome } from "./Visualize/Home/index";
import { visConnections } from "./Visualize/Connections/index";
import { visAuthorization } from "./Visualize/Authorization/index";
import { visExportMetaData } from "./Visualize/ExportMetaData/index";
import { visTempstorage } from "./temp-storage/index";
import { security } from "./security";
import { visFavorites } from "./Visualize/Favorites/index";
import { visFilter } from "./Visualize/Filter/index";
import { visColorPalette } from "./Visualize/ColorPalette/index";
import { visCalculatedColumn } from "./Visualize/CalculatedColumn/index";
import { visFile } from "./Visualize/File/index";
import { visUser } from "./Visualize/User";
import { visFolder } from "./Visualize/Folder";
import { visSchedule } from "./Visualize/Schedule";
import { visScheduleTaskTypes } from "./Visualize/TaskTypes";
import { visBulkDelete } from "./Visualize/BulkDelete";
import { visChatGpt } from "./Visualize/ChatGpt";
import { visFieldGroup } from "./Visualize/FieldGroup";
import { visEnvironment } from "./Transfer/Environments";
import { visConfiguration } from "./Visualize/Configurations";
import { visMigrateContent } from "./Transfer/MigrateContent";
import { visSSE } from "./SSE";
import { ingestDataFlow } from "./Ingest/DataFlow";
import { visTemplates } from "./Visualize/AdminTemplates";
import { visScenarioMain } from "./Scenario/Main";
import { visFieldAuthorization } from "./Visualize/FieldAuthorization";
import { visObjectLock } from "./Visualize/ObjectLock";
import { visUploadImages } from "./Visualize/UploadImages";
import { visDataCache } from "./Visualize/DataCache";
import { visAbout } from "./Visualize/About";

export default {
  visGeneral,
  security,
  visDashboard,
  visDashboardFields,
  visChartFields,
  visDataModel,
  visDataset,
  visTestQuery,
  visHome,
  visConnections,
  visAuthorization,
  visTempstorage,
  visExportMetaData,
  visFavorites,
  visFilter,
  visColorPalette,
  visCalculatedColumn,
  visFile,
  visUser,
  visFolder,
  visSchedule,
  visScheduleTaskTypes,
  visBulkDelete,
  visChatGpt,
  visFieldGroup,
  visEnvironment,
  visConfiguration,
  visMigrateContent,
  visSSE,
  ingestDataFlow,
  visTemplates,
  visScenarioMain,
  visFieldAuthorization,
  visObjectLock,
  visUploadImages,
  visDataCache,
  visAbout,
};
