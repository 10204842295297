/* eslint-disable */
import { ACTION, MUTATION, GETTER, STATE } from "./types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";

export const visConnections = {
    state: {
        [STATE.CONNECTIONS]: [],
        [STATE.CONNECTION_AUTHORIZATION]: [],
        [STATE.SELECTED_CONNECTION]: null,
        [STATE.USER_AUTHORIZATIONS]: [],
        [STATE.CONNECTION_TYPES]: [],
        [STATE.SCHEMAS]: {},
    },
    getters: {
        [GETTER.GET_CONNECTIONS]: (state) => {
            return state[STATE.CONNECTIONS];
        },
        [GETTER.GET_SELECTED_CONNECTION]: (state) => {
            return state[STATE.SELECTED_CONNECTION];
        },
        [GETTER.GET_CONNECTION_AUTHORIZATION]: (state) => {
            return state[STATE.CONNECTION_AUTHORIZATION];
        },
        [GETTER.GET_USER_AUTHORIZATIONS]: (state) => {
            return state[STATE.USER_AUTHORIZATIONS];
        },
        [GETTER.GET_CONNECTION_TYPES]: (state) => {
            return state[STATE.CONNECTION_TYPES];
        },
        [GETTER.GET_SCHEMAS]: (state) => {
            return state[STATE.SCHEMAS];
        },
    },
    actions: {
        [ACTION.FIND_BY_CONNECTION_ID]: async ({ commit }, connectionId) => {
            await vuexActionHandler(
                {
                    commit,
                    queryParam: connectionId,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.findByConnectionId,
                    successMutation: MUTATION.SET_CONNECTION_AUTHORIZATION,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true
                },
            );
        },
        [ACTION.FIND_AUTHORITIES_BY_USERNAME]: async ({ commit }, username) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.findAuthoritiesByUsername,
                    successMutation: MUTATION.SET_USER_AUTHORIZATIONS,
                    queryParam: username,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                }
            )
        },
        [ACTION.SAVE_CONNECTION_AUTHORIZATION]: async ({ commit }, { loadingComponent, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.saveConnectionAuthorization,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    loadingComponent,
                },
            );
        },       
        [ACTION.UPDATE_CONNECTION_AUTHORIZATION]: async ({ commit }, { loadingComponent, connectionId, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    queryParam: connectionId,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.updateConnectionAuthorization,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    loadingComponent,
                },
            );
        },
        [ACTION.DELETE_CONNECTION_AUTHORIZATION]: async ({ commit }, connectionId) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.deleteConnectionAuthorization,
                    queryParam: connectionId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
        },
        [ACTION.FETCH_CONNECTIONS]: async ({ commit }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.readConnections,
                    successMutation: MUTATION.SET_CONNECTIONS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true
                },
            );
        },
        [ACTION.FETCH_CONNECTION_TYPES]: async ({ commit }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.readConnectionTypes,
                    successMutation: MUTATION.SET_CONNECTION_TYPES,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                },
            );
        },
        [ACTION.CREATE_CONNECTION]: async ({ commit, dispatch }, { loadingComponent, bodyParam }) => {
            return await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.createConnection,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    loadingComponent,
                },
            );
            await dispatch(ACTION.FETCH_CONNECTIONS)
        },
        [ACTION.UPDATE_CONNECTION]: async ({ commit, dispatch }, { loadingComponent, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.updateConnection,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    loadingComponent,
                },
            );
            await dispatch(ACTION.FETCH_CONNECTIONS)
        },
        [ACTION.DELETE_CONNECTION]: async ({ commit, dispatch }, connectionId) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.deleteConnection,
                    queryParam: connectionId,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true
                },
            );
            await dispatch(ACTION.FETCH_CONNECTIONS)
        },
        [ACTION.TEST_CONNECTION]: async ({ commit }, { loadingComponent, bodyParam }) => {
            await vuexActionHandler(
                {
                    commit,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.testConnection,
                    bodyParam,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    withNotify: true,
                    loadingComponent,
                },
            );
        },
        [ACTION.FETCH_CONNECTION_BY_DASHBOARD_ID]: async ({ commit }, dashboardId) => {
            return await vuexActionHandler(
                {
                    commit,
                    queryParam: dashboardId,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.readConnectionsByDashboardId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true
                },
            );
        },
        [ACTION.FETCH_SCHEMA_BY_CONNECTION_ID]: async ({ commit }, { connectionId, loadingComponent }) => {
            return await vuexActionHandler(
                {
                    commit,
                    queryParam: connectionId,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.readSchemaByConnectionId,
                    successMutation: MUTATION.SET_SCHEMAS,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    loadingComponent
                },
            );
        },
        [ACTION.FETCH_TABLES_BY_SCHEMA_AND_CONNECTION_ID]: async ({ commit }, { connectionId, schemaName, loadingComponent }) => {
            return await vuexActionHandler(
                {
                    commit,
                    queryParam: { connectionId, schemaName },
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.readTablesBySchemaAndConnectionId,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    loadingComponent
                },
            );
        },
        [ACTION.SEARCH_TABLES_BY_CONNECTION_ID_AND_SEARCH_PARAM]: async ({ commit }, { connectionId, searchParam, loadingComponent }) => {
            return await vuexActionHandler(
                {
                    commit,
                    queryParam: { connectionId, searchParam },
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.searchTablesByConnectionIdAndSearchParam,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    loadingComponent
                },
            );
        },
        [ACTION.FETCH_FIELDS_BY_SCHEMA_AND_TABLE]: async ({ commit }, { connectionId, schema, table, loadingComponent }) => {
            return await vuexActionHandler(
                {
                    commit,
                    queryParam: `${connectionId}/schemas/${schema}/tables/${table}`,
                    serviceMethodParent: serviceMethodParent.Connection,
                    serviceMethodSub: serviceMethodSub.readFieldsBySchemaAndTable,
                    errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
                    resultSelector: (result) => result.data,
                    withLoading: true,
                    loadingComponent
                },
            );
        },
    },
    mutations: {
        [MUTATION.SET_SCHEMAS](state, items) {
            Vue.set(state, [STATE.SCHEMAS], items);
        },
        [MUTATION.SET_CONNECTIONS](state, items) {
            Vue.set(state, [STATE.CONNECTIONS], items);
        },
        [MUTATION.SET_CONNECTION_TYPES](state, items) {
            Vue.set(state, [STATE.CONNECTION_TYPES], items);
        },
        [MUTATION.SET_CONNECTION_AUTHORIZATION](state, items) {
            Vue.set(state, [STATE.CONNECTION_AUTHORIZATION], items);
        },
        [MUTATION.SET_SELECTED_CONNECTION](state, item) {
            Vue.set(state, [STATE.SELECTED_CONNECTION], item);
        },
        [MUTATION.SET_USER_AUTHORIZATIONS](state, item) {
            Vue.set(state, [STATE.USER_AUTHORIZATIONS], item);
        },
    },
};
