import { defaultColorPalette } from "../commons/chartOptions"
import { getItemPercent, legendPositionEnums } from "../commons/dashboardProperties"

export const TREEMAP_DEFAULT_CONFIGURATIONS = {
    TREEMAP_SHOW_VALUE_LABEL: true,
    TREEMAP_SHOW_ONLY: getItemPercent(),
    TREEMAP_SHOW_INSIGHT: true,
    TREEMAP_SHOW_SQL: false,
    TREEMAP_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    TREEMAP_VALUE_LABEL_FONT_WEIGHT: 500,
    TREEMAP_VALUE_LABEL_FONT_SIZE: 12,
    TREEMAP_VALUE_LABEL_FONT_COLOR: '#fff',
    TREEMAP_SHOW_LEGEND: false,
    TREEMAP_LEGEND_MAX_CHARACTER: 15,
    TREEMAP_LEGEND_POSITION: legendPositionEnums.TOP_CENTER,
    TREEMAP_LEGEND_VERTICAL_ALIGN: 'top',
    TREEMAP_LEGEND_FONT_FAMILY: "Poppins, sans-serif",
    TREEMAP_LEGEND_FONT_WEIGHT: 500,
    TREEMAP_LEGEND_FONT_SIZE: '12',
    TREEMAP_LEGEND_FONT_COLOR: '#000',
    TREEMAP_SELECTED_COLOR_PALETTE: defaultColorPalette,
    TREEMAP_AUTO_REFRESH: 0,
    TREEMAP_AUTO_REFRESH_CUSTOM: null,
    TREEMAP_DYNAMIC_TITLE_VALUE: "",
    TREEMAP_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    TREEMAP_DYNAMIC_TITLE_SHOW_VALUE: false,
    TREEMAP_GROUP_MULTIPLE_VALUES: true,
    TREEMAP_ALLOW_DRILL_THROUGH: true,
    TREEMAP_DRILL_THROUGH_DASHBOARD_LIST: [],
    TREEMAP_DRILL_THROUGH_ON_DOUBLE_CLICK: false,
}