import {
    fontSizesArr,
    fontWeightsArr,
    fontsArr,
    PIVOT_TABLE_PROPERTIES_ENUM,
    predefineColorsArr,
    alignItems,
    tableColumnFormats,
    fieldItemTypes
  } from "../../commons/dashboardProperties";
  import {
    PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS,
    getPropertiesWithUndefinedCheck,
  } from "../../mocks/pivotTableColumnsDefaultProperties";
  import { chartPropertiesCss } from "./chartPropertiesCssEnum";
  // column formatlama için olan properties mixi
  export default {
    computed: {
      pivotTableColumnBasedFormattingMapping() {
        return {
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_CONDITIONAL_FORMATTING_BUTTON]:
            {
              label: "table.conditionalFormatting",
              eventId: "openConditionalFormatting",
              property:
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_CONDITIONAL_FORMATTING_BUTTON,
              componentType: this.componentTypes.BUTTON,
            },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_FAMILY]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_FAMILY,
              this.collapse.CHART
            ),
            label: "panelProperties.Header Text Format",
            cssStyle: chartPropertiesCss.FONT_FAMILY,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_FAMILY
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_FAMILY
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_FAMILY,
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: fontsArr,
            flexClass: false,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP,
              this.collapse.CHART
            ),
            label: "panelProperties.wrapText",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_TEXT_WRAP
            ),
            componentType: this.componentTypes.SWITCH,
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_TEXT_WRAP,
            flexClass: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TEXT_WRAP]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TEXT_WRAP,
              this.collapse.CHART
            ),
            label: "panelProperties.wrapText",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TEXT_WRAP
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_TEXT_WRAP
            ),
            componentType: this.componentTypes.SWITCH,
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_TEXT_WRAP,
            flexClass: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN
            ),
            componentType: this.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON,
            selectValues: alignItems,
            property:
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SELECT_ROW_ALIGN,
            flexClass: false,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_BACKGROUND_COLOR]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_BACKGROUND_COLOR,
              this.collapse.CHART
            ),
            label: "panelProperties.cellFormat",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_BACKGROUND_COLOR
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_BACKGROUND_COLOR
            ),
            property:
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_BACKGROUND_COLOR,
            size: "mini",
            predefineColors: predefineColorsArr,
            componentType: this.componentTypes.COLOR_PICKER,
            flexClass: true,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_NAME]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_NAME,
              this.collapse.CHART
            ),
            label: "generalPages.labelName",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_NAME
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_NAME
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_NAME,
            componentType: this.componentTypes.INPUT,
            flexClass: false,
            inputType: "text",
            readOnly: false,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SCREENTIP]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SCREENTIP,
              this.collapse.CHART
            ),
            label: "generalPages.screenTip",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SCREENTIP
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_SCREENTIP
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_SCREENTIP,
            componentType: this.componentTypes.INPUT,
            flexClass: false,
            inputType: "text",
            readOnly: false,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_MAX_CHARACTER]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_MAX_CHARACTER,
              this.collapse.CHART
            ),
            label: "panelProperties.labelMaxCharacter",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_MAX_CHARACTER
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_MAX_CHARACTER
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_MAX_CHARACTER,
            componentType: this.componentTypes.INPUT_WITH_ENTER,
            flexClass: false,
            inputType: "text",
            readOnly: false,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_WEIGHT]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_WEIGHT,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.FONT_WEIGHT,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_WEIGHT
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_WEIGHT
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_WEIGHT,
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: fontWeightsArr,
            flexClass: false,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_SIZE]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_SIZE,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.FONT_SIZE,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_SIZE
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_SIZE
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_SIZE,
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: fontSizesArr,
            flexClass: false,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_COLOR]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_COLOR,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.FONT_COLOR,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_COLOR
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FONT_COLOR
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FONT_COLOR,
            size: "mini",
            predefineColors: predefineColorsArr,
            componentType: this.componentTypes.COLOR_PICKER,
            flexClass: true,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_FAMILY]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_FAMILY,
              this.collapse.CHART
            ),
            label: "panelProperties.Cell Text Format",
            cssStyle: chartPropertiesCss.FONT_FAMILY,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_FAMILY
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_FONT_FAMILY
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_FAMILY,
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: fontsArr,
            flexClass: false,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_WEIGHT]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_WEIGHT,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.FONT_WEIGHT,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_WEIGHT
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_FONT_WEIGHT
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_WEIGHT,
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: fontWeightsArr,
            flexClass: false,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_SIZE]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_SIZE,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.FONT_SIZE,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_SIZE
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_ROW_FONT_SIZE
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_ROW_FONT_SIZE,
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: fontSizesArr,
            flexClass: false,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_FONT_COLOR]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_FONT_COLOR,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.FONT_COLOR,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_FONT_COLOR
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_FONT_COLOR
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_FONT_COLOR,
            size: "mini",
            predefineColors: predefineColorsArr,
            componentType: this.componentTypes.COLOR_PICKER,
            flexClass: true,
            clearable: true,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECT_COLUMN_ALIGN]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECT_COLUMN_ALIGN,
              this.collapse.CHART
            ),
            label: "",
            cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECT_COLUMN_ALIGN
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_SELECT_COLUMN_ALIGN
            ),
            componentType: this.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON,
            selectValues: alignItems,
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_SELECT_COLUMN_ALIGN,
            flexClass: false,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH,
              this.collapse.CHART
            ),
            label: "panelProperties.cellWidth",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_WIDTH
            ),
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_WIDTH,
            componentType: this.componentTypes.INPUT_WITH_ENTER,
            flexClass: false,
            inputType: "text",
            readOnly: false,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FORMAT]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FORMAT,
              this.collapse.CHART
            ),
            label: "panelProperties.Show Value As",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FORMAT
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_COLUMN_FORMAT
            ),
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: tableColumnFormats,
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_COLUMN_FORMAT,
            flexClass: false,
          },
          [PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AGGREGATION_FUNCTION]: {
            id: this.getPropertyId(
              PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AGGREGATION_FUNCTION,
              this.collapse.CHART
            ),
            label: "generalPages.aggregationFunction",
            value: getPropertiesWithUndefinedCheck(
              this.selectedColumnBasedFormattingsProperties?.[
                PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AGGREGATION_FUNCTION
              ],
              PIVOT_TABLE_COLUMNS_DEFAULT_CONFIGURATIONS.PIVOT_TABLE_AGGREGATION_FUNCTION
            ),
            componentType: this.componentTypes.SELECT_BOX,
            selectValues: fieldItemTypes,
            property: PIVOT_TABLE_PROPERTIES_ENUM.PIVOT_TABLE_AGGREGATION_FUNCTION,
            flexClass: false,
          },
        };
      },
    },
  };