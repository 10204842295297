<template>
  <CustomPopup
    :titleText="$t('generalPages.updateField')"
    :width="280"
    :isCancelButtonActive="false"
    :submitButtonText="$t('generalPages.save')"
    footerClass="vis-field-popup-footer"
    @onSubmit="saveField()"
    @onClose="$emit('close')"
  >
    <div class="vis-field-popup-content">
      <div
        class="vis-row-box"
        v-if="this.selectedItem == selectedItemEnums.ALIAS"
      >
        <div v-if="isInfoInputActive">
          <label class="vis-form-label">{{ $t("generalPages.Dashboard Datamodel Alias") }}</label>
          <input :value="infoInputValue" class="vis-input vis-mb--1" type="text" readonly />
        </div>
        <label class="vis-form-label">{{ aliasRenameLabel }}</label>
        <input v-model="field.alias" class="vis-input" type="text" />
      </div>
      <div
        class="vis-row-box"
        v-if="this.selectedItem == selectedItemEnums.SCREEN_TIP"
      >
        <label class="vis-form-label">{{ $t("generalPages.screenTip") }}</label>
        <input v-model="field.screenTip" class="vis-input" type="text" />
      </div>
      <div
        class="vis-row-box"
        v-if="this.selectedItem == selectedItemEnums.DESCRIPTION"
      >
        <label class="vis-form-label">{{
          $t("generalPages.description")
        }}</label>
        <textarea
          v-model="field.description"
          class="vis-textarea vis-textarea-notresize vis-height-100-px vis-scroll-thin"
          type="text"
        />
      </div>
    </div>
  </CustomPopup>
</template>
<script>
import cloneDeep from "clone-deep";
import { DatamodelContextDefaults } from "../../commons/dataModelTypes";
import CustomPopup from "./CustomPopup.vue";

export default {
  components: { CustomPopup },
  props: {
    selectedField: {
      type: Object,
    },
    selectedItem: {
      type: String,
    },
    infoInputValue: {
      type: String,
      default: "",
    },
    isInfoInputActive: {
      type: Boolean,
      default: false,
    },
    aliasRenameLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      field: {},
      selectedItemEnums: {
        ALIAS: DatamodelContextDefaults.ALIAS,
        SCREEN_TIP: DatamodelContextDefaults.SCREEN_TIP,
        DESCRIPTION: DatamodelContextDefaults.DESCRIPTION,
      },
    };
  },
  mounted() {
    this.field = cloneDeep(this.selectedField);
  },
  methods: {
    saveField() {
      this.$emit("saveField", this.field);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.vis-field-popup {
  width: 280px;
}
.vis-field-popup-content,
::v-deep .vis-field-popup-footer {
  position: relative;
  width: 100%;
  padding: 10px 15px;
  margin: 0;
}

.close-icon {
  margin-left: auto;
  font-size: 1rem;
}
</style>
