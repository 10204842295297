import {
    Orientation,
    PIE_CHART_PROPERTIES_ENUM,
    dashboardSPEnums,
    showOnlyTypesExceptOnlyItem,
} from "../../commons/dashboardProperties";
import { getCommonChartItemStyle } from "../chart-filter/filterChartStyles";
import { getSelectedColorPaletteByProperty, setShowOnlyValue, getLeftValueWithLegendOrientPosition, truncateText, aggregationDateFormatController } from "../chartOptionsHelper";
import { getAlignPositionByLegend, getOrientPositionByLegend, getTooltipPositionByValueLabel, isChartWidthLimited, isPieChartValueLabelInside, isPieceSizeLimited } from "../chartViewHelper";
export const getPieChartOptions = (
    pieChartComputedValues,
    paramsFromSearch,
    panelData
) => {
    const optionObject = {
        grid: {
            top: 50,
            bottom: 50,
            left: 30,
        },
        responsive: true,
        tooltip: {
            trigger: "item",
            formatter: showOnlyTypesExceptOnlyItem.includes(
                pieChartComputedValues.PIE_CHART_SHOW_ONLY
            )
                ? function (value) {
                    value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);
                    return setShowOnlyValue(
                        value,
                        paramsFromSearch,
                        pieChartComputedValues,
                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_ONLY,
                        dashboardSPEnums.TOOLTIP,
                        panelData
                    );
                }
                : function (value) {
                    return aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);
                }  ,
            textStyle: {
                fontFamily: "Poppins",
                fontSize: 12,
            },
        },
        legend: {
            show: isChartWidthLimited(panelData.w)
                ? pieChartComputedValues.PIE_CHART_SHOW_LEGEND
                : false,
            padding: 0,
            orient: getOrientPositionByLegend(pieChartComputedValues.PIE_CHART_LEGEND_POSITION).toLowerCase(),
            left: getAlignPositionByLegend(pieChartComputedValues.PIE_CHART_LEGEND_POSITION),
            top: getOrientPositionByLegend(pieChartComputedValues.PIE_CHART_LEGEND_POSITION) === Orientation.VERTICAL ? "10%" : pieChartComputedValues.PIE_CHART_LEGEND_VERTICAL_ALIGN,
            textStyle: {
                fontWeight: pieChartComputedValues.PIE_CHART_LEGEND_FONT_WEIGHT,
                fontFamily: pieChartComputedValues.PIE_CHART_LEGEND_FONT_FAMILY,
                fontSize: pieChartComputedValues.PIE_CHART_LEGEND_FONT_SIZE,
                color: pieChartComputedValues.PIE_CHART_LEGEND_FONT_COLOR,
            },
            backgroundColor: 'transparent',
            formatter: function (value) {
                return truncateText(aggregationDateFormatController(value, paramsFromSearch?.aggregationFormats), pieChartComputedValues.PIE_CHART_LEGEND_MAX_CHARACTER);
            },
            type: "scroll",
        },
        series: paramsFromSearch?.dataSeries?.length
            ? paramsFromSearch?.dataSeries?.map((s) => {
                return {
                    data: s?.map((item, i) => {
                        return {
                            value: item,
                            name: paramsFromSearch?.dataAxis?.[i],
                            itemStyle: getCommonChartItemStyle(
                                paramsFromSearch?.dataAxis[i],
                                panelData
                            ),
                        };
                    }),
                    name: "",
                    type: "pie",
                    center: (getOrientPositionByLegend(pieChartComputedValues.PIE_CHART_LEGEND_POSITION) === Orientation.VERTICAL) ?
                    getLeftValueWithLegendOrientPosition(getAlignPositionByLegend(pieChartComputedValues.PIE_CHART_LEGEND_POSITION), ['60%', '50%'], ['40%', '50%'])
                        :
                        pieChartComputedValues.PIE_CHART_SHOW_LEGEND ? ['50%', '60%'] : ['50%', '50%'],
                    radius: pieChartComputedValues.PIE_CHART_SHOW_DOUGHNUT
                        ? isChartWidthLimited(panelData.w) ? ["40%", "60%"] : ["40%", "70%"]
                        : isChartWidthLimited(panelData.w) ? ["0%", "65%"] : ["0%", "75%"],
                    avoidLabelOverlap: true,
                    itemStyle: {
                        borderRadius: 3,
                        borderColor: "#fff",
                        borderWidth: 1,
                    },
                    label: {
                        position: isPieChartValueLabelInside(pieChartComputedValues.PIE_CHART_VALUE_LABEL_ALIGNTO) ? "inner" : "outer",
                        show: isChartWidthLimited(panelData.w)
                            ? pieChartComputedValues.PIE_CHART_SHOW_VALUE_LABEL
                            : false,
                        fontSize: pieChartComputedValues.PIE_CHART_VALUE_LABEL_FONT_SIZE,
                        fontFamily:
                            pieChartComputedValues.PIE_CHART_VALUE_LABEL_FONT_FAMILY,
                        fontWeight:
                            pieChartComputedValues.PIE_CHART_VALUE_LABEL_FONT_WEIGHT,
                        color: pieChartComputedValues.PIE_CHART_VALUE_LABEL_FONT_COLOR,
                        alignTo: getTooltipPositionByValueLabel(pieChartComputedValues.PIE_CHART_VALUE_LABEL_ALIGNTO),
                        margin: 10,
                        bleedMargin: 10,
                        formatter: showOnlyTypesExceptOnlyItem.includes(
                            pieChartComputedValues.PIE_CHART_SHOW_ONLY
                        )
                            ? function (value) {
                                if(!isPieceSizeLimited(value?.percent) || !isPieChartValueLabelInside(pieChartComputedValues.PIE_CHART_VALUE_LABEL_ALIGNTO)) {
                                    value.name = aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);

                                    return setShowOnlyValue(
                                        value,
                                        paramsFromSearch,
                                        pieChartComputedValues,
                                        PIE_CHART_PROPERTIES_ENUM.PIE_CHART_SHOW_ONLY,
                                        dashboardSPEnums.LABEL,
                                        panelData
                                    )
                                }
                                else return '';
                            }
                            :  function (value) {
                                return aggregationDateFormatController(value.name, paramsFromSearch?.aggregationFormats);
                            },
                    },
                    labelLine: {
                        show: true,
                    },
                    color: getSelectedColorPaletteByProperty(pieChartComputedValues.PIE_CHART_SELECTED_COLOR_PALETTE),
                };
            })
            : null,
    };
    return { options: optionObject };
};
