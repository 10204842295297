import { defaultColorPalette } from "../commons/chartOptions"
import { getItemPercent, legendPositionEnums } from "../commons/dashboardProperties"

export const SCATTER_CHART_DEFAULT_CONFIGURATIONS = {
    SCATTER_CHART_SHOW_VALUE_LABEL: true,
    SCATTER_CHART_SHOW_ONLY: getItemPercent(),
    SCATTER_CHART_SHOW_INSIGHT: true,
    SCATTER_CHART_SHOW_SQL: false,
    SCATTER_CHART_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    SCATTER_CHART_VALUE_LABEL_FONT_WEIGHT: 500,
    SCATTER_CHART_VALUE_LABEL_FONT_SIZE: 12,
    SCATTER_CHART_VALUE_LABEL_ROTATE: "0",
    SCATTER_CHART_VALUE_LABEL_FONT_COLOR: "#000",
    SCATTER_CHART_SHOW_LEGEND: false,
    SCATTER_CHART_LEGEND_MAX_CHARACTER: 15,
    SCATTER_CHART_LEGEND_POSITION: legendPositionEnums.TOP_CENTER,
    SCATTER_CHART_LEGEND_FONT_FAMILY: "Poppins, sans-serif",
    SCATTER_CHART_LEGEND_FONT_WEIGHT: 500,
    SCATTER_CHART_LEGEND_VERTICAL_ALIGN: "top",
    SCATTER_CHART_LEGEND_FONT_SIZE: 12,
    SCATTER_CHART_LEGEND_FONT_COLOR: "#000",
    SCATTER_CHART_SHOW_Y_AXIS_NAME: true,
    SCATTER_CHART_Y_AXIS_NAME_FONT_FAMILY: "Poppins, sans-serif",
    SCATTER_CHART_Y_AXIS_NAME_FONT_WEIGHT: 500,
    SCATTER_CHART_Y_AXIS_NAME_HORIZONTAL_ALIGN: "center",
    SCATTER_CHART_Y_AXIS_NAME_VERTICAL_ALIGN: "top",
    SCATTER_CHART_Y_AXIS_NAME_FONT_SIZE: 12,
    SCATTER_CHART_Y_AXIS_NAME_FONT_COLOR: "#000",
    SCATTER_CHART_SHOW_X_AXIS_NAME: true,
    SCATTER_CHART_X_AXIS_NAME_FONT_FAMILY: "Poppins, sans-serif",
    SCATTER_CHART_X_AXIS_NAME_FONT_WEIGHT: 500,
    SCATTER_CHART_X_AXIS_NAME_HORIZONTAL_ALIGN: "left",
    SCATTER_CHART_X_AXIS_NAME_VERTICAL_ALIGN: "top",
    SCATTER_CHART_X_AXIS_NAME_FONT_SIZE: 12,
    SCATTER_CHART_X_AXIS_NAME_FONT_COLOR: "#000",
    SCATTER_CHART_SHOW_Y_AXIS_LABEL: true,
    SCATTER_CHART_Y_AXIS_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    SCATTER_CHART_Y_AXIS_LABEL_FONT_WEIGHT: 500,
    SCATTER_CHART_Y_AXIS_LABEL_HORIZONTAL_ALIGN: "left",
    SCATTER_CHART_Y_AXIS_LABEL_VERTICAL_ALIGN: "middle",
    SCATTER_CHART_Y_AXIS_LABEL_FONT_SIZE: 12,
    SCATTER_CHART_Y_AXIS_LABEL_FONT_COLOR: "#000",
    SCATTER_CHART_SHOW_X_AXIS_LABEL: true,
    SCATTER_CHART_X_AXIS_LABEL_WRAP: false,
    SCATTER_CHART_X_AXIS_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    SCATTER_CHART_X_AXIS_LABEL_FONT_WEIGHT: 500,
    SCATTER_CHART_X_AXIS_LABEL_HORIZONTAL_ALIGN: "left",
    SCATTER_CHART_X_AXIS_LABEL_VERTICAL_ALIGN: "top",
    SCATTER_CHART_X_AXIS_LABEL_FONT_SIZE: 12,
    SCATTER_CHART_X_AXIS_LABEL_FONT_COLOR: "#000",
    SCATTER_CHART_X_AXIS_LABEL_ROTATE: "0",
    SCATTER_CHART_X_AXIS_LOGARITHMIC_SCALE: false,
    SCATTER_CHART_Y_AXIS_LOGARITHMIC_SCALE: false,
    SCATTER_CHART_X_AXIS_MIN: null,
    SCATTER_CHART_Y_AXIS_MIN: null,
    SCATTER_CHART_Y_AXIS_POSITION: "left",
    SCATTER_CHART_Y_AXIS_SHOW_LINE: false,
    SCATTER_CHART_Y_AXIS_LINE_STYLE_COLOR: "#000",
    SCATTER_CHART_Y_AXIS_NAME_LOCATION: "middle",
    SCATTER_CHART_HIDE_OVERLAPPED_VALUE_LABELS: true,
    SCATTER_CHART_SYMBOL_SIZE: 20,
    SCATTER_CHART_SELECTED_COLOR_PALETTE: defaultColorPalette,
    SCATTER_CHART_AUTO_REFRESH: 0,
    SCATTER_CHART_AUTO_REFRESH_CUSTOM: null,
    SCATTER_CHART_DYNAMIC_TITLE_VALUE: "",
    SCATTER_CHART_DYNAMIC_TITLE_NO_SELECTION_CONTENT: "",
    SCATTER_CHART_DYNAMIC_TITLE_SHOW_VALUE: false,
    SCATTER_CHART_GROUP_MULTIPLE_VALUES: true,
    SCATTER_CHART_X_AXIS_SHOW_DATA_ZOOM: false,
    SCATTER_CHART_X_AXIS_DATA_ZOOM_START: 0,
    SCATTER_CHART_X_AXIS_DATA_ZOOM_END: 100,
    SCATTER_CHART_X_AXIS_LOCK_DATA_ZOOM: false,
    SCATTER_CHART_X_AXIS_DATA_ZOOM_PALETTE: 'rgba(47,69,84,0)',
    SCATTER_CHART_DATA_ZOOM_FILLER_COLOR: 'rgba(62, 122, 201, 0.25)',
    SCATTER_CHART_Y_AXIS_SHOW_DATA_ZOOM: false,
    SCATTER_CHART_Y_AXIS_DATA_ZOOM_START: 0,
    SCATTER_CHART_Y_AXIS_DATA_ZOOM_END: 100,
    SCATTER_CHART_Y_AXIS_DATA_ZOOM_PALETTE: 'rgba(47,69,84,0)',
    SCATTER_CHART_Y_AXIS_LOCK_DATA_ZOOM: false,
    SCATTER_CHART_ALLOW_DRILL_THROUGH: true,
    SCATTER_CHART_DRILL_THROUGH_DASHBOARD_LIST: [],
    SCATTER_CHART_DRILL_THROUGH_ON_DOUBLE_CLICK: false
}