var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    { 'vis-text--center': !_vm.isChartPlaceHolder },
    { 'vis-height-100': _vm.isActiveFullHeight },
  ]},[(!_vm.isChartPlaceHolder && !_vm.isPanelTypeLabel)?_c('img',{staticClass:"vis-chart-placeHolder",attrs:{"src":_vm.getImage,"alt":"Empty Chart"}}):_vm._e(),(_vm.isYaxisNameOnTop || _vm.isSecondYaxisNameOnTop)?_c('div',{staticClass:"vis-flex--spacebeetwen"},[_c('div',{staticClass:"vis-flex-1"},[(_vm.isYaxisNameOnTop)?_c('div',{staticClass:"vis-isYaxisNameOnTop",style:(_vm.yAxisNameStyle)},[_vm._v(" "+_vm._s(_vm.chartOptions.yAxis.name)+" ")]):_vm._e()]),(_vm.isSecondYaxisNameOnTop)?_c('div',{staticClass:"vis-flex--Xright vis-flex-1"},[_c('div',{style:(_vm.yAxisNameStyle)},[_vm._v(" "+_vm._s(_vm.chartOptions.secondYAxis.name)+" ")])]):_vm._e()]):_vm._e(),(_vm.chartOptions)?_c('div',{staticClass:"vis-display-flex no-drag",class:{ 'vis-height-100': _vm.isActiveFullHeight }},[(_vm.isYaxisNameOnSide)?_c('div',{staticClass:"vis-flex--center vis-chart-yAxis-name-outer"},[_c('div',{staticClass:"vis-chart-yAxis-name",style:(_vm.yAxisNameStyle)},[_vm._v(" "+_vm._s(_vm.getYaxisName())+" ")])]):_vm._e(),_c('div',{staticClass:"vis-flex--Xcenter",staticStyle:{"flex":"1"},style:({
        height: _vm.isPanelPage
          ? `${_vm.panelHeight}px`
          : _vm.calculatedHeightByChartCardSize,
      }),attrs:{"id":_vm.panelId},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('closePopoverMenu')}}}),_c('ejs-contextmenu',{staticClass:"chart-context",attrs:{"id":_vm.contextMenuId,"items":_vm.contextMenuItems,"select":_vm.selectContextItem,"animationSettings":_vm.animationSettings,"beforeItemRender":_vm.beforeContextItemHighlight}}),(_vm.isSecondYaxisNameOnSide)?_c('div',{staticClass:"vis-flex--center vis-chart-yAxis-name-outer"},[_c('div',{staticClass:"vis-chart-yAxis-name",style:(_vm.secondYAxisNameStyle)},[_vm._v(" "+_vm._s(_vm.chartOptions.secondYAxis.name)+" ")])]):_vm._e()],1):_vm._e(),(_vm.isXaxisNameVisible)?_c('div',{staticClass:"vis-flex--center vis-chart-xAxis-name",style:(_vm.xAxisNameStyle)},[_vm._v(" "+_vm._s(_vm.getXaxisName())+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }