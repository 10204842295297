<template>
  <div>
    <!-- <div class="vis-chatGpt-banner">
      <span class="vis-font-size-0875 vis-font-medium">{{
        $t("generalPages.How can I help you today?")
      }}</span>
      <div class="vis-chartGpt-banner-iconbox">
        <i
          :class="[
            customIcon.SaveOutlined,
            { 'vis-cursor-notAllowed': !visualizeAIChartId },
          ]"
          class="vis-mr--1 vis-cursor-pointer"
          aria-hidden="true"
          @click="$emit('saveVisualizeAI')"
        ></i>
        <i
          :class="customIcon.Close"
          class="vis-cursor-pointer"
          aria-hidden="true"
          @click="$emit('closeVisualizeAI')"
        ></i>
      </div>
    </div> -->
    <div
      class="vis-chatGpt-card"
      :class="{ 'vis-visualizeAI-card-with-chart': visualizeAIChartId }"
    >
      <!-- <div v-if="!visualizeAIChartId" class="vis-visualizeAI-empty-card">
        <i aria-hidden="true" :class="customIcon.TipsAndUpdated"></i>
      </div> -->
      <div class="vis-chatGpt-card-container">
        <div class="vis-chatGpt-card-title vis-flex--spacebeetwen-Ycenter">
          <el-tabs v-model="activeTabName">
            <el-tab-pane
              :label="$t('generalPages.assistant')"
              :name="chatGptTabs.INSIGHTS"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <div class="vis-chatGpt-card-content">
          <div v-if="isActiveTabNameInsights">
            <VueChatBot
              ref="vuechatbot"
              :is-open="true"
              :messages="aiMessages"
              :options="botOptions"
              :bot-typing="isLoading"
              @msg-send="messageSendHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueChatBot } from "vue-chat-bot";
import {
  chatGptTabs,
  getCustomWordList,
  ICONS_BY_KEY,
} from "../../util/chatGptHelper";
import { floatype } from "../../util/knadh-floatype/floatype";
export default {
  components: {
    VueChatBot,
  },
  props: {
    aiMessages: {
      type: Array,
    },
    visualizeAIChartId: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      activeTabName: chatGptTabs.INSIGHTS,
      chatGptTabs: chatGptTabs,
      botOptions: {
        colorScheme: "#3d62f8",
        animation: true,
        inputPlaceholder: this.$t("generalPages.message"),
        botAvatarImg: this.getLogoImageUrl(),
      },
      onQueryData: "",
      floatText: "",
    };
  },
  mounted() {
    if (this.aiMessages?.length && !this.visualizeAIChartId) {
      this.$emit("resetAImessages");
    }

    const _this = this;

    function replaceLastOccurrence(str, target, replacement) {
      if (!str) {
        str = _this.floatText;
      }

      var index = str.lastIndexOf(target);

      if (index === -1) {
        return str; // target not found
      }

      return (
        str.substring(0, index) +
        replacement +
        str.substring(index + target.length)
      );
    }

    floatype(document.querySelector(".qkb-board-action__input"), {
      onQuery: async (val) => {
        this.onQueryData = val;

        const filteredList = this.autoCompleteWords
          .filter(
            (s) =>
              s.alias.toLowerCase().startsWith(val.toLowerCase()) ||
              s.alias.toLowerCase().includes(val.toLowerCase())
          )
          .slice(0, 10);

        return filteredList.map((w) => ({
          alias: w.alias,
          iconClassName:
            ICONS_BY_KEY?.find((x) => x?.key === w?.usageType)?.iconClassName ??
            "",
        }));
      },
      onSelect: (val) => {
        document.querySelector(".qkb-board-action__input").value =
          replaceLastOccurrence(
            document.querySelector(".qkb-board-action__input").value,
            this.onQueryData,
            val.alias
          );

        // Dispatch the event
        document.querySelector(".qkb-board-action__input").dispatchEvent(
          new Event("input", {
            bubbles: true,
            cancelable: true,
          })
        );

        return "";
      },
      onRender: (o) => {
        const icon = document.createElement("span");
        icon.className = o.iconClassName;
        icon.style = `margin-right: 8px; color:#206fff; font-size: 1rem;`;

        const div = document.createElement("div");
        div.style = `display: flex; align-items: center; font-size: 0.75rem;`;

        div.appendChild(icon);
        div.appendChild(document.createTextNode(o.alias));

        return div;
      },
    });
  },
  computed: {
    autoCompleteWords() {
      const allFieldList = Object.values(this.fields)
        .flat()
        .map((m) => {
          return {
            alias: m.alias,
            usageType: m.usageType,
          };
        });

      return [...[...new Set(allFieldList)], ...getCustomWordList()].sort();
    },
    isActiveTabNameInsights() {
      return this.activeTabName === chatGptTabs.INSIGHTS;
    },
  },
  methods: {
    getLogoImageUrl() {
      let images = require.context("../../assets/images/", false, /\.svg$/);
      return images("./" + "topbar-logo" + ".svg");
    },
    messageSendHandler(value) {
      if (document.querySelector(".floatype")) {
        this.floatText = value.text;
        return;
      }

      this.$emit("addNewChartWithChatBot", {
        agent: "user",
        type: "text",
        text: value.text,
      });
    },
  },
};
</script>

<style scoped>
::v-deep .qkb-board-content {
  overflow: hidden auto;
}
::v-deep .qkb-bot-ui {
  position: relative;
  bottom: 0;
  right: 0;
}
::v-deep .qkb-board {
  position: relative !important;
  width: 100%;
  bottom: 0;
  box-shadow: none;
  border-radius: 0;
}
::v-deep .qkb-bot-bubble,
::v-deep .qkb-board-header {
  display: none;
}

.vis-chatGpt-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.vis-chatGpt-card {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 0 20px 0;
  transform: none !important;
  background-color: #f8f9ff;
  display: flex;
  justify-content: end;
}
.vis-chatGpt-card .vue-resizable-handle {
  display: none !important;
}
.vis-chatGpt-card-title {
  position: relative;
  width: 100%;
  padding: 0 16px;
}
::v-deep .el-tabs__nav-wrap::after {
  width: 0 !important;
}
::v-deep .el-tabs__active-bar {
  background-color: var(--primary);
}
::v-deep .el-tabs__item,
::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: var(--secondary-dark-1);
}

.vis-chatGpt-card-content {
  position: relative;
  width: 100%;
  padding: 0 18px;
}
.vis-chatGpt-card-content ul,
.vis-summary-text-box {
  height: 600px;
}
.vis-chatGpt-card-content ul li span {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--secondary-dark-1);
}

.vis-chatGpt-banner {
  position: fixed;
  width: 100%;
  height: 40px;
  top: 55px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1;
}
.vis-visualizeAI-empty-card {
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vis-visualizeAI-empty-card i {
  font-size: 150px;
  color: #e3e3e3;
}
</style>
