<template>
  <div class="vis-width-100 vis-height-100 vis-position-relative">
    <jsplumb-toolkit
      ref="toolkitComponent"
      v-bind:render-params="renderParams"
      v-bind:view="view"
      id="toolkit"
      :surface-id="surfaceId"
    ></jsplumb-toolkit>
    <jsplumb-miniview :surface-id="surfaceId"></jsplumb-miniview>
  </div>
</template>

<script>
// jsplumb imports

import {
  ForceDirectedLayout,
  LassoPlugin,
  DrawingToolsPlugin,
  DotEndpoint,
  EVENT_CANVAS_CLICK,
  StraightConnector,
  EVENT_CLICK,
  EVENT_GRAPH_CLEARED,
  EVENT_NODE_MOVE_END,
  EVENT_EDGE_ADDED,
  CustomOverlay,
} from "@jsplumbtoolkit/browser-ui";
import { getSurface } from "@jsplumbtoolkit/browser-ui-vue2";
import _ from "lodash";

// local imports
import JsplumbVue2DatasetNode from "./JsplumbVue2DatasetNode.vue";
import JsplumbVue2DatabaseNode from "./JsplumbVue2DatabaseNode.vue";
import { CustomIcon } from "../../../../assets/js/custom-icons";
import IngestTransitionOverlay from "./IngestTransitionOverlay.vue";
import Vue from "vue";
import JsplumbVue2FTPNode from "./JsplumbVue2FTPNode.vue";
import JsplumbVue2SharedFolderNode from "./JsplumbVue2SharedFolderNode.vue";
import { IngestNodeTypes } from "../../enums";

let toolkitComponent;
let toolkit;

export default {
  name: "JsplumbVue2Flowchart",
  props: {
    surfaceId: {
      type: String,
      required: false,
    },
    nodesAndEdges: {
      type: Object,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      toolkitLoaded: false,
      renderParams: {
        enablePan: true,
        wheel: {
          zoom: true,
        },
        layout: {
          type: ForceDirectedLayout.type,
        },
        events: {
          [EVENT_CANVAS_CLICK]: () => {
            this.onCanvasClicked();
            toolkit.clearSelection();
          },
          [EVENT_NODE_MOVE_END]: () => {
            this.onPositionsAndLevelsUpdated();
          },
        },
        consumeRightClick: false,
        dragOptions: {
          filter: ".jtk-draw-handle, .node-action, .node-action i",
        },
        plugins: [
          DrawingToolsPlugin.type,
          {
            type: LassoPlugin.type,
            options: {
              invert: true,
            },
          },
        ],
        grid: {
          size: {
            w: 20,
            h: 20,
          },
        },
        magnetize: {
          afterDrag: true,
        },
      },
      view: {
        nodes: {
          dataset: {
            component: JsplumbVue2DatasetNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.$emit("dataset-clicked", e.obj.data);
              },
            },
          },
          [IngestNodeTypes.Database]: {
            component: JsplumbVue2DatabaseNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.$emit("database-clicked", e.obj.data);
              },
            },
          },
          [IngestNodeTypes.FTP]: {
            component: JsplumbVue2FTPNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.$emit("ftp-clicked", e.obj.data);
              },
            },
          },
          [IngestNodeTypes.SMB]: {
            component: JsplumbVue2SharedFolderNode,
            events: {
              [EVENT_CLICK]: (e) => {
                this.$emit("shared-folder-clicked", e.obj.data);
              },
            },
          },
        },
        edges: {
          default: {
            anchor: "AutoDefault",
            endpoint: {
              type: DotEndpoint.type,
              options: {
                radius: 5,
              },
            },
            connector: { type: StraightConnector.type },
            // paint style for this edge type.
            paintStyle: {
              strokeWidth: 2,
              stroke: "#000",
              outlineWidth: 3,
              outlineStroke: "transparent",
            },
            events: {
              /*click: (p) => {
              },*/
            },
          },
          datasetJoin: {
            parent: "default",
            overlays: [
              {
                type: "Arrow",
                options: {
                  width: 10,
                  length: 15,
                  location: 1,
                },
              },

              {
                type: CustomOverlay.type,
                options: {
                  create: (n) => {
                    // Create Vue component instance and return its root element
                    const component = Vue.extend(IngestTransitionOverlay);
                    const instance = new component().$mount();

                    instance.$on("onTransitionDeleteClick", () =>
                      this.handleTransitionDeleteClick(
                        n?.edge?.source?.data?.id,
                        n?.edge?.target?.data?.id
                      )
                    );

                    return instance.$el;
                  },
                },
              },
            ],
          },
        },

        ports: {
          start: {
            edgeType: "default",
          },
          source: {
            maxConnections: -1,
            edgeType: "datasetJoin",
          },
          target: {
            maxConnections: -1,
            isTarget: true,
          },
        },
      },
    };
  },
  watch: {
    nodesAndEdges: {
      deep: true,
      handler(newVal, oldVal) {
        if (_.isEqual(newVal, oldVal)) {
          return;
        }

        toolkit.clear();
      },
    },
  },
  mounted() {
    this.initializeToolkit();

    toolkit.bind(EVENT_EDGE_ADDED, (e) => {
      if (e.addedByMouse) {
        const alreadyAdded = this.isJoinAlreadyAdded(
          e.edge.source.id,
          e.edge.target.id
        );

        if (!alreadyAdded) {
          const emitData = {
            source: e.edge.source.id,
            target: e.edge.target.id,
            type: e.edge.source.data.type,
          };
          
          if (
            e.edge.source.data.type === IngestNodeTypes.SMB ||
            e.edge.source.data.type === IngestNodeTypes.FTP
          ) {
            emitData.fileIngestRequestBody = e.edge.source.data.fileIngestRequestBody;
          }

          this.$emit("addNewTransition", emitData);
        }
      }
    });
  },

  methods: {
    handleTransitionDeleteClick(sourceId, targetId) {
      this.$emit("onTransitionDeleteClick", {
        sourceId,
        targetId,
      });
    },
    isJoinAlreadyAdded(sourceId, targetId) {
      let alreadyAdded = false;
      const allEdgesExceptLastAdded = toolkit
        .getAllEdges()
        .slice(0, toolkit.getAllEdges().length - 1);

      allEdgesExceptLastAdded?.forEach((e) => {
        if (
          (e.source.id === sourceId && e.target.id === targetId) ||
          (e.source.id === targetId && e.target.id === sourceId)
        ) {
          alreadyAdded = true;
        }
      });

      return alreadyAdded;
    },
    onPositionsAndLevelsUpdated() {
      let nodesCoords = [];
      const nodes = document.getElementsByClassName("ingest-flowchart-node");
      const convertCssTextToCssObject = (cssText) => {
        const result = {};
        // Split the CSS values by semicolon to get individual property declarations
        const declarations = cssText.split(";");

        // Loop through each declaration and extract property and value
        declarations.forEach(function (declaration) {
          // Split the declaration into property and value
          const parts = declaration.trim().split(":");

          // If the declaration has both property and value, add them to the JavaScript object
          if (parts.length === 2) {
            const property = parts[0].trim();
            const value = parseFloat(parts[1].trim()); // Parse the value as a float

            result[property] = value;
          }
        });

        return result;
      };

      for (let i = 0; i < nodes.length; i++) {
        nodesCoords.push({
          id: nodes[i].getAttribute("data-jtk-managed"),
          ...convertCssTextToCssObject(nodes[i].style.cssText),
        });
      }

      this.$emit("updatePositionsAndLevels", nodesCoords);
    },
    // a wrapper around getSurface, which expects a callback, as the surface may or may not have been
    // initialised when calls are made to it.
    _getSurface(cb) {
      getSurface(this.surfaceId, cb);
    },
    onCanvasClicked() {
      this.$emit("onCanvasClicked");
    },
    onRunJob(id) {
      this.$emit("onRunJob", id);
    },
    onShowPreview(obj) {
      this.$emit("onShowPreview", obj);
    },
    onEditClicked(obj) {
      this.$emit("onEditClicked", obj);
    },
    onDeleteClicked(id) {
      this.$emit("onDeleteClicked", id);
    },
    initializeToolkit() {
      toolkitComponent = this.$refs.toolkitComponent;
      toolkit = toolkitComponent.toolkit;

      toolkit.bind(EVENT_GRAPH_CLEARED, () => {
        if (toolkit.getNodeCount() === 0) {
          this.appendToolkitData();
        }
      });

      this.loadToolkitData();
    },
    loadToolkitData() {
      try {
        if (this.nodesAndEdges)
          toolkit.load({
            type: "json",
            data: this.nodesAndEdges,
          });
      } catch (e) {
        console.log("Load error", e);
      }
    },
    appendToolkitData() {
      try {
        if (this.nodesAndEdges)
          toolkit.append({
            type: "json",
            data: this.nodesAndEdges,
          });
      } catch (e) {
        console.log("Append error", e);
      }
    },
  },
};
</script>

<style>
.connection-middle-icon {
  -webkit-transition: background-color 0.25s ease-in;
  -moz-transition: background-color 0.25s ease-in;
  transition: background-color 0.25s ease-in;
}
.connection-middle-icon {
  color: white;
  padding: 0.3em;
  cursor: pointer;
}
.dataset-node-connection-action-icon {
  color: #004de6;
  font-size: 40px !important;
  padding: 5px;
}
.connection-middle-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.connection-middle-container-side {
  width: 110px;
  display: flex;
  align-items: center;
}
.connection-middle-container-info-box {
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3d62f8;
  border-radius: 5px;
  display: none;
  position: absolute;
  top: -25px;
  padding: 0 5px;
}
.connection-middle-left {
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.connection-middle-delete {
  color: #004de6;
  font-size: 20px !important;
}
.connection-middle-right {
  color: black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.connection-middle-container:hover .connection-middle-container-info-box {
  display: flex !important;
  align-items: center;
  padding: 12px;
}

.jtk-miniview-element {
  background-color: var(--primary-lighteen-1) !important;
}

hr {
  min-width: 10px;
  height: 0;
  border: 1px solid #707070;
  display: inline-block;
  margin: 3px;
  flex: 1;
}
</style>
