<template>
  <div>
    <div class="overlay"></div>
    <div class="vis-change-dataset-connection-popup">
      <div
        class="vis-change-dataset-connection-popup-header vis-row-box vis-flex--spacebeetwen-Ycenter"
      >
        <h3>{{ $t("datamodel.Change Connection") }}</h3>
      </div>

      <div class="vis-change-dataset-connection-popup-header-description">
        <div v-if="popupDescription">
          {{ popupDescription }}
        </div>
        <div
          class="vis-change-dataset-connection-popup-header-description-name"
        >
          <span>{{ $t("datamodel.Name") }}:</span
          ><span
            class="vis-change-dataset-connection-popup-header-description-value"
            >{{ connectionsNeedToBeChangedName }}</span
          >
        </div>
        <div
          class="vis-change-dataset-connection-popup-header-description-type"
        >
          <span>{{ $t("datamodel.Type") }}:</span
          ><span
            class="vis-change-dataset-connection-popup-header-description-value"
            >{{ connectionsNeedToBeChangedType }}</span
          >
        </div>
      </div>

      <div class="vis-change-dataset-connection-popup-body">
        <SelectDatabaseConnection
          :hide-actions="true"
          :hide-create-date="true"
          :hide-update-date="true"
          :filterConnectionTypeBy="isConnectionTypeFiltered ? connectionsNeedToBeChangedType : []"
          @selectConnection="selectConnectionByIndex"
        />

        <div class="select-database-connection-container-footer">
          <button
            class="vis-btn medium vis-mt--2 exit-button"
            @click="$emit('cancel')"
          >
            {{ cancelButtonText }}
          </button>
          <button class="vis-btn medium vis-mt--2 next-button" @click="confirm">
            {{
              isCurrentConnectionLastConnectionToBeChanged
                ? $t("datamodel.Done")
                : $t("generalPages.next")
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectDatabaseConnection from "./../connection/SelectDatabaseConnection";
import { CustomIcon } from "../../assets/js/custom-icons";

export default {
  props: {
    connections: {
      type: Array,
    },
    connectionsNeedToBeChanged: {
      type: Array,
      default: () => [],
    },
    popupDescription: {
      type: String,
      default: "",
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
    isConnectionTypeFiltered: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SelectDatabaseConnection,
  },
  data() {
    return {
      connectionsChangeHolder: [],
      currentConnectionToBeChangedIndex: 0,
      CustomIcon: CustomIcon,
    };
  },
  mounted() {
    this.connectionsNeedToBeChanged.forEach((item, index) => {
      this.connectionsChangeHolder.push({
        from: item.connectionId,
        to: "",
        index,
      });
    });
  },
  computed: {
    connectionsNeedToBeChangedName() {
      return (
        this.connectionsNeedToBeChanged?.[
          this.currentConnectionToBeChangedIndex
        ]?.connection?.name || ""
      );
    },
    connectionsNeedToBeChangedType() {
      const connection =
        this.connectionsNeedToBeChanged?.[
          this.currentConnectionToBeChangedIndex
        ]?.connection?.type || "";

      if (connection) {
        return [connection];
      }

      return [];
    },
    isCurrentConnectionLastConnectionToBeChanged() {
      return (
        this.currentConnectionToBeChangedIndex + 1 ===
        this.connectionsNeedToBeChanged.length
      );
    },
  },
  methods: {
    confirm() {
      if (
        this.connectionsChangeHolder[this.currentConnectionToBeChangedIndex].to
          ?.length
      ) {
        if (this.isCurrentConnectionLastConnectionToBeChanged) {
          this.$emit("done", this.connectionsChangeHolder);
        } else {
          this.currentConnectionToBeChangedIndex++;
        }
      }
    },
    selectConnectionByIndex(newConnection) {
      this.connectionsChangeHolder[this.currentConnectionToBeChangedIndex].to =
        newConnection?.connectionId;
    },
  },
};
</script>

<style scoped>
.vis-change-dataset-connection-popup {
  z-index: 1001;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 7px;
  border: 1px solid #d8d8d8;
}

.vis-change-dataset-connection-popup-header {
  border-bottom: 1px solid #dadcdd;
  padding: 6px 24px;
}

.vis-change-dataset-connection-popup-header-description {
  margin: 15px 0 10px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vis-change-dataset-connection-popup-header-description-name,
.vis-change-dataset-connection-popup-header-description-type {
  display: flex;
  gap: 10px;
}

.vis-change-dataset-connection-popup-header-description-value {
  font-weight: 600;
}

.vis-change-dataset-connection-popup-header {
  border-bottom: 1px solid #dadcdd;
  padding: 6px 24px;
}

.vis-change-dataset-connection-popup-body {
  height: calc(100% - 50px);
}

::v-deep .select-database-connection-container-body {
  padding: 0 25px;
}

::v-deep .vgt-responsive {
  max-height: 360px !important;
  overflow-y: scroll;
}
.next-button {
  background: var(--primary-lighteen-1) 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: none;
  color: white;
  float: right;
  opacity: 1;
  margin-right: 50px;
  margin-bottom: 25px;
}
.exit-button {
  background: transparent;
  border: none;
  color: #474747;
  float: right;
  opacity: 1;
  font-weight: 600;
  margin-bottom: 25px;
}
.select-database-connection-container-footer {
  display: flex;
  justify-content: flex-end;
}
.overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1000; /* Adjust the z-index to make sure it's above other elements */
}
</style>
