import { CustomIcon } from "../assets/js/custom-icons";
import { chartList } from "../commons/dashboardAndPanel";
import { DatamodelContextDefaults } from "../commons/dataModelTypes";
import i18n from "../i18n";

export const chatGptInsightsBodyParam = {
  temperature: 0.3,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  max_tokens: 800,
  best_of: 5,
  stop: ["Human:", "AI:"],
};

export const chatGptTabs = {
  INSIGHTS: "INSIGHTS",
  SUMMARY: "SUMMARY",
};

export const getCustomWordList = () => {
  const orderList = [
    i18n.tc("generalPages.Order By"),
    i18n.tc("generalPages.ascending"),
    i18n.tc("generalPages.descending"),
  ];
  const mergedList = [...chartList.map((c) => `${i18n.tc(c.name)} ${i18n.tc('generalPages.Chart')}`), ...orderList];

  return mergedList.map((m) => {
    return {
      alias: m,
    };
  });
};

export const ICONS_BY_KEY = [
  {
    key: DatamodelContextDefaults.USAGE_TYPES.MEASURE,
    iconClassName: CustomIcon.Measure,
  },
  {
    key: DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
    iconClassName: CustomIcon.Abc,
  },
  {
    key: DatamodelContextDefaults.USAGE_TYPES.DATE,
    iconClassName: CustomIcon.Clock_Time,
  },
];