<template>
  <div>
    <div class="vis-row">
      <div class="vis-col vis-col-6">
        <label>{{ $t("generalPages.labelName") }}</label>
        <el-input
          :class="checkFilledField(scheduleGeneralUpdated.name)"
          type="text"
          v-model="scheduleGeneralUpdated.name"
        />
      </div>
    </div>
    <div class="vis-row vis-mt--05">
      <div class="vis-col vis-col-6">
        <label>{{ $t("generalPages.labelDescription") }}</label>
        <el-input
          type="textarea"
          :rows="3"
          v-model="scheduleGeneralUpdated.description"
        ></el-input>
      </div>
    </div>
    <div v-if="isFromUntilAvailable" class="vis-row vis-mt--05">
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.generalActiveFrom") }}</label>
        <el-date-picker
          :class="checkFilledField(epochTimeUpdated.activeFrom)"
          type="datetime"
          v-model="epochTimeUpdated.activeFrom"
        />
      </div>
      <div class="vis-col vis-col-3">
        <label>{{ $t("schedule.generalActiveUntil") }}</label>
        <el-date-picker
          type="datetime"
          v-model="epochTimeUpdated.activeUntil"
          :class="checkFilledField(epochTimeUpdated.activeUntil)"
        />
      </div>
    </div>
    <div v-if="showDynamicContent" class="vis-row vis-mt--05">
      <div class="vis-col vis-col-3">
        <div class="vis-display-flex">
          <label class="use-dynamic-content-label">{{
            $t("Use Dynamic Content")
          }}</label>
          <el-switch
            class="vis-ml--auto"
            :value="isDynamic"
            @change="(val) => $emit('onIsDynamicUpdated', val)"
          />
        </div>
      </div>
    </div>
    <div v-if="isDynamic" class="vis-row dataset-select-container">
      <div class="vis-col vis-col-3">
        <label>{{ $t("Dataset") }}</label>
        <el-select
          :class="checkFilledField(dynamicDatasetId)"
          :value="dynamicDatasetId"
          filterable
          @change="(val) => $emit('onDynamicContentDatasetSelected', val)"
        >
          <el-option
            v-for="option in datasets"
            :key="option.datasetId"
            :value="option.datasetId"
            :label="option.name"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- <div class="vis-row">
      <div class="vis-col vis-col-12">
        <label>{{ $t("schedule.generalEnableSchedule") }}</label>
      </div>
    </div> -->
  </div>
</template>

<script>
import cloneDeep from "clone-deep";
import {
  scheduleGeneralDefaultValue,
  epochTimeDefaultValue,
} from "./schedule.js";
export default {
  props: {
    scheduleGeneral: {
      type: Object,
      default: () => {
        return {};
      },
    },
    epochTime: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isGeneralFieldSolidity: {
      type: Boolean,
    },
    selectedDataModel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showDynamicContent: {
      type: Boolean,
      default: true,
    },
    isFromUntilAvailable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scheduleGeneralUpdated: scheduleGeneralDefaultValue,
      epochTimeUpdated: epochTimeDefaultValue,
    };
  },
  mounted() {
    this.scheduleGeneralUpdated = cloneDeep(this.scheduleGeneral);
    this.epochTimeUpdated = cloneDeep(this.epochTime);
  },
  watch: {
    scheduleGeneralUpdated: {
      deep: true,
      handler() {
        this.$emit("onScheduleUpdated", this.scheduleGeneralUpdated);
      },
    },
    epochTimeUpdated: {
      deep: true,
      handler() {
        this.$emit("onEpochTimeUpdated", this.epochTimeUpdated);
      },
    },
  },
  computed: {
    datasets() {
      return this.selectedDataModel?.datasets?.map((dataset) => ({
        datasetId: dataset.datasetId,
        name: dataset.name,
      }));
    },
    isDynamic() {
      return this.scheduleGeneralUpdated?.message?.isDynamic;
    },
    dynamicDatasetId() {
      return this.scheduleGeneralUpdated?.message?.dynamicDatasetId;
    },
  },
  methods: {
    checkFilledField(val) {
      if (val === "" && this.isGeneralFieldSolidity === false) {
        return "vis-unfilled-field";
      } else return;
    },
  },
};
</script>

<style scoped>
.use-dynamic-content-label {
  display: inline !important;
}
.dataset-select-container {
  padding-left: 10px;
  width: calc(100% - 10px);
}
</style>
