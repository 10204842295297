<template>
  <div
    class="vis-control-section vis-text-editor vis-scroll-thin vis-flex-direction--column"
    :class="{ 'vis-non-edit': !editMode }"
  >
    <div
      class="vis-text-editor"
      :class="{
        'vis-non-editable-content': !editMode,
        'popup-open': isPopupOpen,
        'vis-edit-content': editMode,
      }"
    >
      <ejs-richtexteditor
        ref="rteObj"
        id="richTextEditor"
        :value="richText"
        :change="changeRichText"
        :toolbarSettings="toolbarSettings"
        :showCharCount="false"
        :quickToolbarSettings="quickToolbarSettings"
        :enableTabKey="enableTabKey"
        :placeholder="placeholder"
        :enableXhtml="enableXhtml"
        :insertImageSettings="imageSettings"
        :beforeImageUpload="beforeImageUpload"
        :imageSelected="imageSelected"
        :disabled="!editMode"
        :fontFamily="fontFamily"
        :dialogOpen="onDialogOpen"
        :dialogClose="onDialogClose"
      ></ejs-richtexteditor>
    </div>
  </div>
</template>

<script>
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  Table,
  EmojiPicker,
  FormatPainter,
  PasteCleanup,
  List,
} from "@syncfusion/ej2-vue-richtexteditor";
import { CustomIcon } from "../../../assets/js/custom-icons";
import cloneDeep from "clone-deep";
import { Notify } from "../../../commons/helper";
import { notificationType } from "../../../commons/notificationTypes";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";

export default {
  components: {
    "ejs-richtexteditor": RichTextEditorComponent,
  },
  props: {
    panelData: {
      type: Object,
      default() {
        return {};
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [panelDataPropertiesStyle],
  data: function () {
    return {
      enableTabKey: true,
      enableXhtml: true,
      customIcon: CustomIcon,
      quickToolbarSettings: {
        table: [
          "TableHeader",
          "TableRows",
          "TableColumns",
          "TableCell",
          "-",
          "BackgroundColor",
          "TableRemove",
          "TableCellVerticalAlign",
          "Styles",
        ],
        showOnRightClick: true,
      },
      imageSettings: {
        saveFormat: "Base64",
      },
      isPopupOpen: false,
      fontFamily: {
        default: "Poppins",
        items: [
          { text: "Poppins", value: "Poppins, sans-serif" },
          { text: "Arial", value: "Arial,Helvetica,sans-serif" },
          { text: "Verdana", value: "Verdana,Geneva,sans-serif" },
          { text: "Times New Roman", value: "Times New Roman, Times, serif" },
          { text: "Calibri", value: "Calibri, sans-serif" },
        ],
      },
    };
  },
  updated() {
    this.hideStuckTooltips();
  },
  computed: {
    richText() {
      if (!this.option) return;
      const newContent = Array.isArray(this.option.response)
        ? this.extractHtmlContent(this.option.response)
        : "";

      // Mevcut RICH_TEXT içeriğini alıyoruz
      let currentRichText = this.rawRichText || "";

      // Yeni içerik ile eski içerikteki `field` div'lerini senkronize ediyoruz
      const updatedRichText = this.syncFieldsInRichText(
        currentRichText,
        newContent
      );
      return updatedRichText;
    },
    rawRichText() {
      return this.panelData?.properties.style.RICH_TEXT;
    },
    placeholder() {
      return `<div><h2>${this.$t("dashboard.Edit Text")}</h2>
                <div>
                  <br/>
                </div>
              </div>`;
    },
    toolbarSettings() {
      if (!this.editMode) {
        return { items: [] };
      }
      let items = [
        "Undo",
        "Redo",
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "SuperScript",
        "SubScript",
        "|",
        "FontName",
        "FontSize",
        "FontColor",
        "BackgroundColor",
        "|",
        "LowerCase",
        "UpperCase",
        "|",
        "Formats",
        "Alignments",
        "|",
        "NumberFormatList",
        "BulletFormatList",
        "|",
        "Outdent",
        "Indent",
        "CreateTable",
        "FormatPainter",
        "ClearFormat",
        "|",
        "CreateLink",
        "EmojiPicker",
        "|",
        "Print",
        "FullScreen",
      ];

      if (
        this.CHART_COMPUTED_VALUES &&
        this.CHART_COMPUTED_VALUES.TEXT_EDITOR_SHOW_IMAGE
      ) {
        items.splice(items.indexOf("CreateLink"), 0, "Image");
      }

      return {
        items,
      };
    },
  },
  methods: {
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    changeRichText(event) {
      const selectedPanelCloned = cloneDeep(this.panelData);
      this.$set(
        selectedPanelCloned.properties.style,
        "RICH_TEXT",
        event?.value
      );
      this.updateSelectedPanel(selectedPanelCloned);
    },
    beforeImageUpload(args) {
      const maxImageCount =
        this.CHART_COMPUTED_VALUES?.TEXT_EDITOR_MAX_IMAGE_COUNT || 0;
      const imageElements =
        this.$refs?.rteObj?.ej2Instances?.element.querySelectorAll("img");

      if (imageElements.length >= maxImageCount) {
        args.cancel = true;
        Notify(
          this.$t(
            "textEditor.You cannot exceed the maximum number of images you can add!"
          ),
          notificationType.WARNING
        );
        const dialog =
          this.$refs?.rteObj?.ej2Instances?.element.querySelector(".e-dialog");

        if (dialog && dialog?.ej2_instances[0]) {
          dialog?.ej2_instances[0].close();
        }
        return;
      }
    },
    imageSelected(args) {
      const file = args?.filesData?.[0]?.rawFile;
      const maxFileSize =
        this.CHART_COMPUTED_VALUES?.TEXT_EDITOR_MAX_IMAGE_SIZE * 1024;

      if (file?.size > maxFileSize) {
        args.cancel = true;
        Notify(
          this.$t(
            "textEditor.You cannot exceed the maximum image size you can add!"
          ),
          notificationType.WARNING
        );

        const dialog =
          this.$refs.rteObj.ej2Instances.element.querySelector(".e-dialog");
        if (dialog && dialog.ej2_instances[0]) {
          dialog.ej2_instances[0].close();
        }
        return;
      }
    },
    onDialogOpen() {
      this.isPopupOpen = true;
    },
    onDialogClose() {
      this.isPopupOpen = false;
    },
    hideStuckTooltips() {
      const tooltips = document?.querySelectorAll(".e-tooltip-wrap");
      tooltips.forEach((tooltip) => {
        if (tooltip.style.display !== "none") {
          tooltip.style.display = "none";
        }
      });
    },
    syncFieldsInRichText(currentRichText, newContent) {
      const fieldRegex =
        /<div class="([^"]+) vis-disabled-fields">([\s\S]*)<\/div>/g;
      let updatedRichText = cloneDeep(currentRichText);
      // Yeni content'ten field'ları güncelle
      let match;
      while ((match = fieldRegex.exec(newContent)) !== null) {
        const [fullMatch, fieldClass, fieldValue] = match;
        // Mevcut içerikte bu field var mı?
        const existingFieldRegex = new RegExp(
          `<div class="${fieldClass} vis-disabled-fields">([\\s\\S]*)<\\/div>`,
          "g"
        );
        const existingFieldMatch = existingFieldRegex.exec(currentRichText);
        if (existingFieldMatch) {
          // Eğer field mevcutsa, sadece değerini güncelle
          const updatedField = `<div class="${fieldClass} vis-disabled-fields">${fieldValue}</div>`;
          updatedRichText = updatedRichText.replace(
            existingFieldMatch[0],
            updatedField
          );
        } else {
          // Eğer field mevcut değilse, yeni field'ı sona ekle
          updatedRichText += ` ${fullMatch}`;
        }
      }

      const metric = this.panelData?.details?.metrics?.length
        ? this.panelData?.details.metrics[0]
        : [];
      //Mevcut olmayan field'ların değerlerini kaldır
      const currentFields = this.extractFields(currentRichText);

      currentFields.forEach((field) => {
        // panelData.details.metrics[0] içinde fieldId eşleşmesi kontrolü
        //şimdilik tek field ekleniyor daha sonrası için değiştirelcek

        if (metric.field != field) {
          // Mevcut olmayan field'ları tamamen kaldır
          const fieldToRemoveRegex = new RegExp(
            `<div class="${field} vis-disabled-fields">([\\s\\S]*)<\\/div>`,
            "g"
          );
          updatedRichText = updatedRichText.replace(fieldToRemoveRegex, "");
        }
      });
      return updatedRichText.trim();
    },
    extractFields(content) {
      const regex = /<div class="([^"]+) vis-disabled-fields">/g;
      let fields = [];
      let match;

      while ((match = regex.exec(content)) !== null) {
        fields.push(match[1]);
      }

      return fields;
    },
    extractHtmlContent(datas) {
      let combinedHtml = "";
      datas.forEach((data) => {
        combinedHtml += `<div class="${data?.field} vis-disabled-fields">${data?.value}</div>`;
      });
      return combinedHtml;
    },
  },
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      Image,
      Count,
      HtmlEditor,
      QuickToolbar,
      Table,
      EmojiPicker,
      FormatPainter,
      PasteCleanup,
      List,
    ],
  },
};
</script>
<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";

::v-deep #richTextEditor_rte-edit-view_options li {
  padding: 10px !important;
  height: 60px;
}

::v-deep .sb-header {
  z-index: 100;
}

::v-deep .sb-content.e-view.hide-header {
  top: 0 !important;
}

::v-deep .sb-header.e-view.hide-header {
  display: none;
}

::v-deep .e-upload:has(#rteCustomWordUpload) {
  display: none;
}

::v-deep .non-clickable {
  pointer-events: none;
}

::v-deep .vis-non-editable-content {
  border: none;
}

::v-deep .vis-non-editable-content .vis-disabled-fields {
  cursor: none !important;
  opacity: inherit !important;
}

::v-deep .vis-non-editable-content .e-toolbar-wrapper {
  display: none;
}

::v-deep .vis-control-section.vis-non-edit .e-toolbar-wrapper,
::v-deep .vis-control-section.vis-non-edit .e-toolbar-container {
  border-bottom: none;
}

::v-deep .e-tooltip .null {
  border-top: none !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

::v-deep .vis-control-section.vis-non-edit .e-rte-content {
  border: none;
}

::v-deep .vis-control-section.vis-non-edit .e-rte-content .e-content {
  max-height: 100%;
}

::v-deep .e-richtexteditor .e-rte-content {
  background: transparent;
}

::v-deep .vis-edit-content .e-richtexteditor .e-rte-content .e-content {
  max-height: 720px;
  overflow: auto;
  scrollbar-width: thin;
}

::v-deep .e-richtexteditor .e-rte-content .e-content {
  padding-top: 0;
}

::v-deep .vis-text-editor {
  position: relative;
  height: auto;
  max-height: 100%;
  z-index: 0;
}

::v-deep .vis-non-editable-content blockquote {
  border-left: 5px solid #ccc;
  padding-left: 10px;
  color: #666;
  font-style: italic;
}

::v-deep ul li {
  list-style: inherit !important;
}

::v-deep .e-richtexteditor ul li {
  list-style-type: inherit;
}

::v-deep .e-toolbar-wrapper .e-blockquote::before,
::v-deep .e-toolbar-container .e-blockquote::before,
::v-deep .e-rte-toolbar .e-blockquote::before,
::v-deep .e-rte-dropdown-popup .e-blockquote::before {
  content: '""';
}

::v-deep .e-rte-container {
  border: none;
}

::v-deep .e-disabled {
  opacity: initial !important;
}

::v-deep .e-richtexteditor.e-rte-tb-expand {
  border: none;
}

::v-deep .e-richtexteditor.e-rte-tb-expand .e-rte-content {
  border: none;
}

::v-deep .e-richtexteditor .e-rte-content,
::v-deep .e-richtexteditor .e-source-content {
  overflow: visible;
}

::v-deep .vis-text-editor ul {
  list-style: disc;
  padding-left: 8px;
}

::v-deep .vis-text-editor ul.square {
  list-style: square;
  padding-left: 8px;
}

::v-deep .vis-text-editor ul.circle {
  list-style: circle;
  padding-left: 8px;
}

::v-deep .popup-open {
  height: 100vh;
  overflow: auto;
}
</style>
